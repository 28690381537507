import { RECEIVE_UNENROL_USER } from 'constants/actions';

export default function reducer(
  state = {
    optOutResult: null,
    optOutErrorMsg: null
  },
  action
) {
  switch (action.type) {
    case RECEIVE_UNENROL_USER:
      return {
        ...state,
        optOutResult: action.unenrolResponse.result,
        optOutErrorMsg: action.unenrolResponse.errorMsg
      };
    default:
      return state;
  }
}
