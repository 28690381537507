import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
// import * as Yup from 'yup';
import { Base64 } from 'js-base64';

import * as actions from 'constants/actions';
import { otpGetAction } from 'actions/otp';
import { clearAllErrors } from '../utils/helpers';
import { TextStyle, Spacing, ActionButton, MessageSection, MessageInline, TextField, Icon } from '@able/react';
import './OtpForm.scss';

const OtpForm = props => {
  const { page } = props;

  const [resetOtp, setResetOtp] = React.useState(false);

  const dispatch = useDispatch();

  const otpFlow = useSelector(state => state.otpFlow);

  let currentFlow;
  let reference;
  let title;
  let message;
  let ctalabel;

  switch (otpFlow.otp.flow) {
    case 'mobile':
      currentFlow = 'SMS';
      reference = otpFlow.mobile.temp;
      title = 'Current mobile number';
      message = 'Please enter the 6 digit one-time PIN we sent to your new mobile number.';
      ctalabel = 'Verify mobile number';
      break;
    case 'sendemail':
      currentFlow = 'EMAIL';
      reference = otpFlow.email.temp;
      title = 'Current email address';
      message = 'Please enter the 6 digit one-time PIN we sent to your new email address.';
      ctalabel = 'Verify email address';
      break;
    default:
      currentFlow = '';
      reference = '';
  }
  const getOptions = {
    referenceId: reference,
    serviceType: currentFlow,
    serviceId: reference
  };

  const resendOptions = {
    ...getOptions,
    resend: true
  };

  const resendOtp = () => {
    setResetOtp(true);
    dispatch(otpGetAction(resendOptions))
      .then(() => {
        clearAllErrors({ clearAlert: false, flow: otpFlow.otp.flow });
        setResetOtp(false);
      })
      .catch(() => {
        dispatch({
          type: 'GET_OTP_RESEND',
          msg: ''
        });
        dispatch({ type: 'SET_OTP_FETCHING', fetching: false });
      });
  };

  return (
    <Formik
      initialValues={{
        otp: ''
      }}
      validationSchema ={() => Yup.object().shape({
        otp: Yup.string()
        .matches(/^[0-9]+$/,'Enter a valid one-time PIN.')
        .required().length(6,'6 digit one-time PIN is required!')
      })
      }
           
      // validate: values => {  
      //   const errors = {};

      //   // if (!values.name) {
      //   //   errors.name = 'Required';
      //   // }
      onSubmit={values => {
        const payload = {
          ...values,
          otp: values.otp
        };
        dispatch({ type: actions.SET_OTP_FETCHING, fetching: true });
        const otpRequest = {
          referenceId: reference,
          serviceType: currentFlow,
          oneTimePinValue: Base64.encode(payload.otp),
          page
        };
        props
          .apiAction(otpRequest)
          .then(() => {
            dispatch({ type: actions.SET_OTP_FETCHING, fetching: false });
            // Set the triggered flow if changed
            switch (otpFlow.otp.flow) {
              case 'mobile':
                // dispatch({ type: 'GET_MOBILE_TRIGGERED', triggered: false });
                dispatch({
                  type: actions.GET_MOBILE_TRIGGERED,
                  triggered: true
                });
                break;
              case 'sendemail':
                // dispatch({ type: 'GET_EMAIL_TRIGGERED', triggered: false });
                dispatch({
                  type: actions.GET_EMAIL_TRIGGERED,
                  triggered: true
                });
                break;
              default:
                break;
            }
            clearAllErrors({});
            props.nextAction({ clearErrors: true });
          })
          .catch(() => {
            dispatch({
              type: actions.GET_OTP_RESEND,
              msg: ''
            });
            dispatch({ type: actions.SET_OTP_FETCHING, fetching: false });
          });
      }}
    >
      {({ setFieldValue, errors, touched, handleSubmit, handleChange, values }) => (
        <Form >
          {otpFlow?.otp?.alertMsg && (
             <div>
             <Spacing top="spacing5x"/>
             <MessageSection
               variant='Attention'
               developmentUrl='./able-sprites.svg'
               description={otpFlow?.otp?.alertMsg}
               />
               <Spacing top="spacing5x"/>
            </div>
          )}
         
          <Spacing bottom="spacing1x"/>
          <TextStyle element="p" alias="Base">
          {message}
          </TextStyle>
         <Spacing bottom="spacing3x"/>

          <TextStyle element="p" alias="Label">
            {title}
          </TextStyle>

          <Spacing bottom="spacingHalf"/>

          <TextStyle element="p" alias="Base">
          {Base64.decode(reference)}
          </TextStyle>

          <Spacing bottom="spacing3x"/>

           <TextField
              label="Enter one-time PIN"
              name="otp"
              id="enter-one-time-pin"
              invalidInputText={errors.otp}
              invalid={errors.otp && touched.otp ? "true" : null}
              developmentUrl="/able-sprites.svg"
              events={{ onKeyUp: handleChange}}
              autoComplete="pin"
              type="pin"
              maxLength={6}
            />
             {!(errors.otp && touched.otp) && (values.otp === '') && (otpFlow?.otp?.error?.code === 0 ||
              otpFlow?.otp?.error?.code === 2001 ||
              otpFlow?.otp?.error?.code === 2002 ||
              otpFlow?.otp?.error?.code === 2003) && (
            <div role="status">
                      <TextStyle element="p" alias="FinePrint" className="opt-validation-error">
                            <Icon
                              size="24"
                              developmentUrl="/able-sprites.svg"
                              icon="Error"
                              screenReaderContent="Error"
                              role="img"
                              aria-label="Error"
                            />
                            {otpFlow?.otp?.errorMsg}{' '}
                          </TextStyle>
                  </div>
            )}
          {(otpFlow?.otp?.error?.code === 2004 ||
            otpFlow?.otp?.error?.code === 2005) && (
              <div role="status">
                <Spacing bottom="spacing2x"/>
              <MessageInline
                    variant="Error"
                    developmentUrl="/able-sprites.svg"
                        >
                    <React.Fragment key=".0">
                    {otpFlow?.otp?.errorMsg}
                    </React.Fragment>
                 </MessageInline>
                 </div>
          )}

          <Spacing bottom="spacing5x"/>

          <div>
          <TextStyle element="label" alias="Base">
           Didn't receive the one-time PIN?
          </TextStyle>
          </div>
        
          <div>
          <ActionButton
            variant="LowEmphasis"
            label="Resend one-time PIN"
            rel="noopener noreferrer"
            element="button"
            developmentUrl="/able-sprites.svg"
            onClick={() => resendOtp(setFieldValue)}
           />
           </div>
          <Spacing bottom="spacing3x"/>
          <ActionButton
          variant="HighEmphasis"
          label={ctalabel}
          element="button"
          type="submit"  
          rel="noopener noreferrer"
          onClick={() => handleSubmit}        
            />
        </Form>
      )}
    </Formik>
  );
};

OtpForm.propTypes = {
  page: PropTypes.string.isRequired,
  apiAction: PropTypes.func.isRequired,
  nextAction: PropTypes.func.isRequired,
  flow: PropTypes.string.isRequired
};

export default OtpForm;
