import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AlertPNG from 'assets/images/Alert.png';
import IconArrow from 'icons/IconArrow';
import { Breadcrumbs } from 'components';
import './UserRelationship.scss';

const UserRelationshipPermissions = ({ renderDownStreamErrorCode }) => (
  <Fragment>
    <div className="page container">
      <div className="user-relationship">
        <Breadcrumbs />
        <div className="icon-contain">
          <img src={AlertPNG} alt="An error icon" />
        </div>
        <div className="clear-div-m" />
        <h1 className="page-heading">
          You&#39;re not authorised to join Telstra Plus with this account
        </h1>
        <div className="clear-div-s" />
        <p>
          You do not have permission from the account owner to join Telstra Plus.
        </p>
        <div className="clear-div-xxs" />
        {renderDownStreamErrorCode()}
        <div className="clear-div-s" />
        <a
          role="button"
          aria-pressed="false"
          className="primary-cta-18 go-to-thanks"
          href="https://www.telstra.com.au/support/account-payment/give-another-person-access-to-account"
        >
          More about account permissions <IconArrow />
        </a>
        <div className="clear-div-xl" />
      </div>
    </div>
  </Fragment>
);

UserRelationshipPermissions.propTypes = {
  renderDownStreamErrorCode: PropTypes.func.isRequired
};

export default UserRelationshipPermissions;
