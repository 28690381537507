import React, { useRef, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { Icon } from '@able/react';
import KeyDown from 'utils/_wrappers/KeyDownHandler';
import keyboardTrap from './functions/keyboardTrap';
import storeCurrentBodyChildrenAriaHiddenValue from './functions/storeCurrentBodyChildrenAriaHiddenValues';

let ariaHiddenValues;

/**
 * A modal dialog is used to interrupt user's current workflow, and bring their
 * attention exclusively to a decision or an urgent information relevant to
 * their current context.
 *
 * [View the Dialog pattern documentation on Confluence](https://confluence.in.telstra.com.au/display/DCSYS/Modal+Dialog)
 *
 * @version 1.0.0
 */
const Dialog = props => {
  const {
    children,
    className,
    enterKeyPress,
    escapeKeyPress,
    description,
    developmentUrl,
    icon,
    isShowing,
    title,
    ...other
  } = props;

  const focusableEl = useRef(null);
  const dialog = useRef();
  const { body } = document;

  const enterPress = enterKeyPress ? () => enterKeyPress() : undefined;
  const escapePress = escapeKeyPress ? () => escapeKeyPress() : undefined;

  if (isShowing) {
    ariaHiddenValues = storeCurrentBodyChildrenAriaHiddenValue();
    // don't allow scrolling on body
    body.style.overflow = 'hidden';
    // remove other elements from tabindex and screen readers
    Array.from(body.children).forEach((child) => {
      const role = child.getAttribute('role')
      if (child.nodeName !== 'SCRIPT' && role !== 'dialog') {
        child.setAttribute('aria-hidden', 'true');
      }
    });
  } else {
    // allow scroll on body
    body.style.overflow = 'auto';
    // reinstate original ariaHidden value
    Array.from(body.children).forEach((child) => {
      if (
        typeof ariaHiddenValues !== 'undefined' &&
        ariaHiddenValues.has(child)
      ) {
        const value = ariaHiddenValues.get(child);
        if (value) {
          child.setAttribute('aria-hidden', value);
        } else {
          child.removeAttribute('aria-hidden');
        }
      }
    });
  }

  useLayoutEffect(() => {
    if (isShowing) {
      // focus something for accessibility
      focusableEl.current.focus();
      // Keyboard trap
      keyboardTrap(dialog.current);
    }
  }, [isShowing]);

  const renderDialog = () => (
    <div
      className={className}
      role='dialog'
      aria-modal='true'
      aria-hidden={!isShowing}
      {...other}
      ref={dialog}
      aria-describedby="dialog2_desc"
      aria-labelledby="dialog2_desc"
    >
      <KeyDown enterPress={enterPress} escapePress={escapePress}>
        <div className={`${className}__content`} id="dialog2_desc"  ref={focusableEl} tabIndex={-1}>
          {icon && (
            <div className={`${className}__icon`}>
              <Icon developmentUrl={developmentUrl} icon={icon} size="24" />
            </div>
          )}
          <h2>
            {title}
          </h2>
          {description ? <p>{description}</p> : ''}
          {children}
        </div>
      </KeyDown>
    </div>
  );

  return ReactDOM.createPortal(isShowing ? renderDialog() : '', document.body);
};

Dialog.defaultProps = {
  className: undefined,
  description: undefined,
  developmentUrl: undefined,
  isShowing: false
};

export default Dialog;
