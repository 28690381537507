import React, { useEffect, useState } from 'react';
import IconPlus from 'icons/IconPlus';
import IconMinus from 'icons/IconMinus';
import { Icon } from '@able/react';
import IconExternalLink from 'icons/IconExternalLink';
import ScrollUp from './ScrollUp';
import './Footer.scss';
/* eslint-disable */
/* The above disables eslinting for the labels as they claim to not
be associated with an input, even though they very much are associated via ID and htmlFor */
const Footer = () => {
  const [showTopButtonFlag, setShowTopButtonFlag] = useState(false);
  const [bottomHeight, setBottomHeight] = useState(0);
  const [checkFlag, setCheckFlag] = useState(false);

  const listenToScroll = () => {
    const topBtn = document.getElementById('showTopBtn');
    if (topBtn != null) {
      const bounding = topBtn.getBoundingClientRect();
      const winScroll = document.documentElement.scrollTop;

      //checks if footer is visible on screen
      if (
        !checkFlag &&
        bottomHeight === 0 &&
        winScroll > 500 &&
        bounding.bottom <
          (window.innerHeight - 148 ||
            document.documentElement.clientHeight - 148)
      ) {
        //console.log('Bottom is out of viewport', bounding.bottom);
        //console.log('document.documentElement.clientHeight', document.documentElement.clientHeight);

        setCheckFlag(true);
        setBottomHeight(bounding.bottom);
      }
      //
      else if (
        checkFlag &&
        bounding.bottom > document.documentElement.clientHeight - 148 &&
        winScroll > 500
      ) {
        //console.log('bounding.bottom >self.state.bottomHeight-->')

        setShowTopButtonFlag(true);
      } else if (
        showTopButtonFlag &&
        bounding.bottom < document.documentElement.clientHeight - 148 &&
        winScroll > 500
      ) {
        update();
      }
      //disappear scroll-to-top button
      else if (winScroll < 500 && showTopButtonFlag) {
        setShowTopButtonFlag(false);
        setBottomHeight(0);
        setCheckFlag(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, [listenToScroll]);

  const update = () => {
    if (!showTopButtonFlag) {
      setBottomHeight(0);
      setCheckFlag(false);
    } else {
      setShowTopButtonFlag(false);
      setBottomHeight(0);
      setCheckFlag(false);
    }
  };

  const populateScrollButton = () => {
    return (
      <ScrollUp
        updateFlag={update}
        flag={showTopButtonFlag}
        style={
          showTopButtonFlag
            ? { display: 'block', position: 'fixed' }
            : { display: 'none' }
        }
      />
    );
  };

  return (
    <footer className="globalFooter" id="myDiv">
      <nav aria-label="Footer">
        <div className="tcom-global-footer">
          {populateScrollButton()}
          <div className="footer-container container">
            <div className="footer-links">
              <section className="footerList">
                <div className="footer-list">
                  <h2 className="heading-text">
                    <span itemProp="name">Help</span>
                  </h2>
                  <ul
                    itemScope=""
                    itemType="http://schema.org/SiteNavigationElement"
                  >
                    <li itemProp="url">
                      <a
                        href="https://www.telstra.com.au/contact-us"
                        itemProp="name"
                      >
                        Contact us
                      </a>
                    </li>

                    <li itemProp="url">
                      <a
                        href="https://www.telstra.com.au/support"
                        itemProp="name"
                      >
                        Support
                      </a>
                    </li>

                    <li itemProp="url">
                      <a
                        href="https://crowdsupport.telstra.com.au/t5/Find-your-nearest-Telstra-Retail/ct-p/tstore"
                        itemProp="name"
                      >
                        Find a store
                      </a>
                    </li>

                    <li itemProp="url">
                      <a href="https://outages.telstra.com/" itemProp="name">
                        Check for outages
                      </a>
                    </li>

                    <li itemProp="url">
                      <a
                        href="https://www.telstra.com.au/my-account/troubleshooting-tool"
                        itemProp="name"
                      >
                        Troubleshoot an issue
                      </a>
                    </li>

                    <li itemProp="url">
                      <a
                        href="https://www.telstra.com.au/consumer-advice"
                        itemProp="name"
                      >
                        Consumer advice
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="accordion-tab">
                  <input
                    id="tab-content-footerlist"
                    type="checkbox"
                    name="tabs"
                  />
                  <label
                    className="heading-text"
                    htmlFor="tab-content-footerlist"
                  >
                    <span itemProp="name">Help</span>
                    <span
                      className="accordion-open-icon tcom-icon-12"
                      tabIndex="-1"
                      role="presentation"
                      focusable="false"
                    >
                      <IconPlus />
                    </span>
                    <span
                      className="accordion-close-icon tcom-icon-12"
                      tabIndex="-1"
                      role="presentation"
                      focusable="false"
                    >
                      <IconMinus />
                    </span>
                  </label>
                  <div className="tab-content">
                    <ul
                      itemScope=""
                      itemType="http://schema.org/SiteNavigationElement"
                    >
                      <li
                        itemProp="url"
                        id="id-347708954-b872855d-3df9-40d0-a5ce-203dcf104901"
                        data-analytics-component='{"componentInfo":{"componentID":"id-347708954-b872855d-3df9-40d0-a5ce-203dcf104901","title":"Help","linkText":"Contact us","destination":"https://www.telstra.com.au/contact-us"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="https://www.telstra.com.au/contact-us"
                          itemProp="name"
                        >
                          Contact us
                          <svg
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <use xlinkHref="/etc/designs/telstra/core/icons/tcom-icons.sprite.svg#" />
                          </svg>
                        </a>
                      </li>

                      <li
                        itemProp="url"
                        id="id-347708954-338776e9-2afc-4fd4-9bcb-cfbccc888c92"
                        data-analytics-component='{"componentInfo":{"componentID":"id-347708954-338776e9-2afc-4fd4-9bcb-cfbccc888c92","title":"Help","linkText":"Support","destination":"https://www.telstra.com.au/support"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="https://www.telstra.com.au/support"
                          itemProp="name"
                        >
                          Support
                          <svg
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <use xlinkHref="/etc/designs/telstra/core/icons/tcom-icons.sprite.svg#" />
                          </svg>
                        </a>
                      </li>

                      <li
                        itemProp="url"
                        id="id-347708954-abe09fc3-0c8f-4328-91d8-cce808c0845a"
                        data-analytics-component='{"componentInfo":{"componentID":"id-347708954-abe09fc3-0c8f-4328-91d8-cce808c0845a","title":"Help","linkText":"Find a store","destination":"https://crowdsupport.telstra.com.au/t5/Find-your-nearest-Telstra-Retail/ct-p/tstore"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="https://crowdsupport.telstra.com.au/t5/Find-your-nearest-Telstra-Retail/ct-p/tstore"
                          itemProp="name"
                        >
                          Find a store
                          <svg
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <use xlinkHref="/etc/designs/telstra/core/icons/tcom-icons.sprite.svg#" />
                          </svg>
                        </a>
                      </li>

                      <li
                        itemProp="url"
                        id="id-347708954-728a528b-c156-4e07-8722-0d8f00cb02d8"
                        data-analytics-component='{"componentInfo":{"componentID":"id-347708954-728a528b-c156-4e07-8722-0d8f00cb02d8","title":"Help","linkText":"Check for outages","destination":"https://outages.telstra.com/"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a href="https://outages.telstra.com/" itemProp="name">
                          Check for outages
                          <svg
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <use xlinkHref="/etc/designs/telstra/core/icons/tcom-icons.sprite.svg#" />
                          </svg>
                        </a>
                      </li>

                      <li
                        itemProp="url"
                        id="id-347708954-0fe6283d-95db-45f0-ac57-c1046c4369c5"
                        data-analytics-component='{"componentInfo":{"componentID":"id-347708954-0fe6283d-95db-45f0-ac57-c1046c4369c5","title":"Help","linkText":"Troubleshoot an issue","destination":"https://www.telstra.com.au/my-account/troubleshooting-tool"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="https://www.telstra.com.au/my-account/troubleshooting-tool"
                          itemProp="name"
                        >
                          Troubleshoot an issue
                          <svg
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <use xlinkHref="/etc/designs/telstra/core/icons/tcom-icons.sprite.svg#" />
                          </svg>
                        </a>
                      </li>

                      <li
                        itemProp="url"
                        id="id-347708954-30f77117-cb35-414d-8684-42360612c82a"
                        data-analytics-component='{"componentInfo":{"componentID":"id-347708954-30f77117-cb35-414d-8684-42360612c82a","title":"Help","linkText":"Consumer advice","destination":"https://www.telstra.com.au/consumer-advice"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="https://www.telstra.com.au/consumer-advice"
                          itemProp="name"
                        >
                          Consumer advice
                          <svg
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <use xlinkHref="/etc/designs/telstra/core/icons/tcom-icons.sprite.svg#" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
              <section className="footerList">
                <div className="footer-list">
                  <h2 className="heading-text">
                    <span itemProp="name">About</span>
                  </h2>
                  <ul
                    itemScope=""
                    itemType="http://schema.org/SiteNavigationElement"
                  >
                    <li itemProp="url">
                      <a
                        href="https://www.telstra.com.au/aboutus"
                        itemProp="name"
                      >
                        About us
                      </a>
                    </li>

                    <li itemProp="url">
                      <a
                        href="https://careers.telstra.com/home"
                        itemProp="name"
                      >
                        Careers
                      </a>
                    </li>

                    <li itemProp="url">
                      <a
                        href="https://www.telstra.com.au/partners-and-causes"
                        itemProp="name"
                      >
                        Partners and causes
                      </a>
                    </li>

                    <li itemProp="url">
                      <a
                        href="https://www.telstra.com.au/aboutus/investors"
                        itemProp="name"
                      >
                        Investors
                      </a>
                    </li>

                    <li itemProp="url">
                      <a
                        href="https://www.telstra.com.au/sitemap"
                        itemProp="name"
                      >
                        Sitemap
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="accordion-tab">
                  <input
                    id="tab-content-footerlist_copy"
                    type="checkbox"
                    name="tabs"
                  />
                  <label
                    className="heading-text"
                    htmlFor="tab-content-footerlist_copy"
                  >
                    <span itemProp="name">About</span>
                    <span
                      className="accordion-open-icon tcom-icon-12"
                      tabIndex="-1"
                      role="presentation"
                      focusable="false"
                    >
                      <IconPlus />
                    </span>
                    <span
                      className="accordion-close-icon tcom-icon-12"
                      tabIndex="-1"
                      role="presentation"
                      focusable="false"
                    >
                      <IconMinus />
                    </span>
                  </label>
                  <div className="tab-content">
                    <ul
                      itemScope=""
                      itemType="http://schema.org/SiteNavigationElement"
                    >
                      <li
                        itemProp="url"
                        id="id--966751622-f896efd5-877a-4aa4-bb7e-99993efbaba7"
                        data-analytics-component='{"componentInfo":{"componentID":"id--966751622-f896efd5-877a-4aa4-bb7e-99993efbaba7","title":"About","linkText":"About us","destination":"https://www.telstra.com.au/aboutus"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="https://www.telstra.com.au/aboutus"
                          itemProp="name"
                        >
                          About us
                          <svg
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <use xlinkHref="/etc/designs/telstra/core/icons/tcom-icons.sprite.svg#" />
                          </svg>
                        </a>
                      </li>

                      <li
                        itemProp="url"
                        id="id--966751622-06d53eb9-0360-4632-8b11-86609e991194"
                        data-analytics-component='{"componentInfo":{"componentID":"id--966751622-06d53eb9-0360-4632-8b11-86609e991194","title":"About","linkText":"Careers","destination":"https://careers.telstra.com/home"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="https://careers.telstra.com/home"
                          itemProp="name"
                        >
                          Careers
                          <svg
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <use xlinkHref="/etc/designs/telstra/core/icons/tcom-icons.sprite.svg#" />
                          </svg>
                        </a>
                      </li>

                      <li
                        itemProp="url"
                        id="id--966751622-1cb1bef1-b4a1-4e19-8e1d-356f2058ab08"
                        data-analytics-component='{"componentInfo":{"componentID":"id--966751622-1cb1bef1-b4a1-4e19-8e1d-356f2058ab08","title":"About","linkText":"Partners and causes","destination":"https://www.telstra.com.au/partners-and-causes"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="https://www.telstra.com.au/partners-and-causes"
                          itemProp="name"
                        >
                          Partners and causes
                          <svg
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <use xlinkHref="/etc/designs/telstra/core/icons/tcom-icons.sprite.svg#" />
                          </svg>
                        </a>
                      </li>

                      <li
                        itemProp="url"
                        id="id--966751622-ea52f623-16d0-4b11-97b8-42ddd3ca16e0"
                        data-analytics-component='{"componentInfo":{"componentID":"id--966751622-ea52f623-16d0-4b11-97b8-42ddd3ca16e0","title":"About","linkText":"Investors","destination":"https://www.telstra.com.au/aboutus/investors"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="https://www.telstra.com.au/aboutus/investors"
                          itemProp="name"
                        >
                          Investors
                          <svg
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <use xlinkHref="/etc/designs/telstra/core/icons/tcom-icons.sprite.svg#" />
                          </svg>
                        </a>
                      </li>

                      <li
                        itemProp="url"
                        id="id--966751622-31ebf275-b96e-4cd2-b26b-c646998eb2cf"
                        data-analytics-component='{"componentInfo":{"componentID":"id--966751622-31ebf275-b96e-4cd2-b26b-c646998eb2cf","title":"About","linkText":"Sitemap","destination":"https://www.telstra.com.au/sitemap"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="https://www.telstra.com.au/sitemap"
                          itemProp="name"
                        >
                          Sitemap
                          <svg
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <use xlinkHref="/etc/designs/telstra/core/icons/tcom-icons.sprite.svg#" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
              <section className="footerList">
                <div className="footer-list">
                  <h2 className="heading-text">
                    <span itemProp="name">Privacy and terms</span>
                  </h2>

                  <ul
                    itemScope=""
                    itemType="http://schema.org/SiteNavigationElement"
                  >
                    <li itemProp="url">
                      <a
                        href="https://www.telstra.com.au/help/critical-information-summaries"
                        itemProp="name"
                      >
                        Critical Information Summaries
                      </a>
                    </li>

                    <li itemProp="url">
                      <a
                        href="https://www.telstra.com.au/customer-terms"
                        itemProp="name"
                      >
                        Our customer terms
                      </a>
                    </li>

                    <li itemProp="url">
                      <a
                        href="https://www.telstra.com.au/consumer-advice/your-information"
                        itemProp="name"
                      >
                        Your information
                      </a>
                    </li>

                    <li itemProp="url">
                      <a
                        href="https://www.telstra.com.au/privacy"
                        itemProp="name"
                      >
                        Privacy
                      </a>
                    </li>

                    <li itemProp="url">
                      <a
                        href="https://www.telstra.com.au/terms-of-use"
                        itemProp="name"
                      >
                        Our website terms
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="accordion-tab">
                  <input
                    id="tab-content-footerlist_copy_copy"
                    type="checkbox"
                    name="tabs"
                  />
                  <label
                    className="heading-text"
                    htmlFor="tab-content-footerlist_copy_copy"
                  >
                    <span itemProp="name">Privacy and terms</span>
                    <span
                      className="accordion-open-icon tcom-icon-12"
                      tabIndex="-1"
                      role="presentation"
                      focusable="false"
                    >
                      <IconPlus />
                    </span>
                    <span
                      className="accordion-close-icon tcom-icon-12"
                      tabIndex="-1"
                      role="presentation"
                      focusable="false"
                    >
                      <IconMinus />
                    </span>
                  </label>
                  <div className="tab-content">
                    <ul
                      itemScope=""
                      itemType="http://schema.org/SiteNavigationElement"
                    >
                      <li
                        itemProp="url"
                        id="id--2013373926-fa9f6fcb-b9c5-4fc1-901d-c55181c926a3"
                        data-analytics-component='{"componentInfo":{"componentID":"id--2013373926-fa9f6fcb-b9c5-4fc1-901d-c55181c926a3","title":"Privacy and terms","linkText":"Critical Information Summaries","destination":"https://www.telstra.com.au/help/critical-information-summaries"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="https://www.telstra.com.au/help/critical-information-summaries"
                          itemProp="name"
                        >
                          Critical Information Summaries
                          <svg
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <use xlinkHref="/etc/designs/telstra/core/icons/tcom-icons.sprite.svg#" />
                          </svg>
                        </a>
                      </li>

                      <li
                        itemProp="url"
                        id="id--2013373926-2a0bb62e-ecbe-4bab-a34f-0494988622d8"
                        data-analytics-component='{"componentInfo":{"componentID":"id--2013373926-2a0bb62e-ecbe-4bab-a34f-0494988622d8","title":"Privacy and terms","linkText":"Our customer terms","destination":"https://www.telstra.com.au/customer-terms"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="https://www.telstra.com.au/customer-terms"
                          itemProp="name"
                        >
                          Our customer terms
                          <svg
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <use xlinkHref="/etc/designs/telstra/core/icons/tcom-icons.sprite.svg#" />
                          </svg>
                        </a>
                      </li>

                      <li
                        itemProp="url"
                        id="id--2013373926-8015b187-771b-4356-93ef-52b4f13bd11d"
                        data-analytics-component='{"componentInfo":{"componentID":"id--2013373926-8015b187-771b-4356-93ef-52b4f13bd11d","title":"Privacy and terms","linkText":"Your information","destination":"https://www.telstra.com.au/consumer-advice/your-information"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="https://www.telstra.com.au/consumer-advice/your-information"
                          itemProp="name"
                        >
                          Your information
                          <svg
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <use xlinkHref="/etc/designs/telstra/core/icons/tcom-icons.sprite.svg#" />
                          </svg>
                        </a>
                      </li>

                      <li
                        itemProp="url"
                        id="id--2013373926-78e0ed7e-ee2e-41da-a959-6ab7aa8e46bf"
                        data-analytics-component='{"componentInfo":{"componentID":"id--2013373926-78e0ed7e-ee2e-41da-a959-6ab7aa8e46bf","title":"Privacy and terms","linkText":"Privacy","destination":"https://www.telstra.com.au/privacy"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="https://www.telstra.com.au/privacy"
                          itemProp="name"
                        >
                          Privacy
                          <svg
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <use xlinkHref="/etc/designs/telstra/core/icons/tcom-icons.sprite.svg#" />
                          </svg>
                        </a>
                      </li>

                      <li
                        itemProp="url"
                        id="id--2013373926-77602124-b31c-4cb1-9ad6-3a15251951fd"
                        data-analytics-component='{"componentInfo":{"componentID":"id--2013373926-77602124-b31c-4cb1-9ad6-3a15251951fd","title":"Privacy and terms","linkText":"Our website terms","destination":"https://www.telstra.com.au/terms-of-use"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="https://www.telstra.com.au/terms-of-use"
                          itemProp="name"
                        >
                          Our website terms
                          <svg
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <use xlinkHref="/etc/designs/telstra/core/icons/tcom-icons.sprite.svg#" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
              <section className="footerList">
                <div className="footer-list">
                  <h2 className="heading-text">
                    <span itemProp="name">Other Telstra sites</span>
                  </h2>

                  <ul
                    itemScope=""
                    itemType="http://schema.org/SiteNavigationElement"
                  >
                    <li itemProp="url">
                      <a
                        href="https://www.telstrawholesale.com.au/"
                        itemProp="name"
                      >
                        Telstra Wholesale<span className="sr-only">external link</span>
                        <span
                          className="footer-icon tcom-icon-16"
                          tabIndex="-1"
                          role="presentation"
                        >
                          <IconExternalLink />
                        </span>
                      </a>
                    </li>

                    <li itemProp="url">
                      <a href="https://www.telstraglobal.com/" itemProp="name">
                        Global Enterprises &amp; Services
                      </a>
                    </li>

                    <li itemProp="url">
                      <a
                        href="http://media.telstra.com.au/home.html"
                        itemProp="name"
                      >
                        Telstra Media<span className="sr-only">external link</span>
                        <span
                          className="footer-icon tcom-icon-16"
                          tabIndex="-1"
                          role="presentation"
                        >
                          <IconExternalLink />
                        </span>
                      </a>
                    </li>

                    <li itemProp="url">
                      <a href="https://www.telstrahealth.com/" itemProp="name">
                        Telstra Health<span className="sr-only">external link</span> 
                        <span
                          className="footer-icon tcom-icon-16"
                          tabIndex="-1"
                          role="presentation"
                        >
                          <IconExternalLink />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="accordion-tab">
                  <input
                    id="tab-content-footerlist_copy_copy_1186361401"
                    type="checkbox"
                    name="tabs"
                  />
                  <label
                    className="heading-text"
                    htmlFor="tab-content-footerlist_copy_copy_1186361401"
                  >
                    <span itemProp="name">Other Telstra sites</span>
                    <span
                      className="accordion-open-icon tcom-icon-12"
                      tabIndex="-1"
                      role="presentation"
                      focusable="false"
                    >
                      <IconPlus />
                    </span>
                    <span
                      className="accordion-close-icon tcom-icon-12"
                      tabIndex="-1"
                      role="presentation"
                      focusable="false"
                    >
                      <IconMinus />
                    </span>
                  </label>
                  <div className="tab-content">
                    <ul
                      itemScope=""
                      itemType="http://schema.org/SiteNavigationElement"
                    >
                      <li
                        itemProp="url"
                        id="id-157280810-b78a5e09-ae06-490b-be7a-07251074dd62"
                        data-analytics-component='{"componentInfo":{"componentID":"id-157280810-b78a5e09-ae06-490b-be7a-07251074dd62","title":"Other Telstra sites","linkText":"Telstra Wholesale","destination":"https://www.telstrawholesale.com.au/"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="https://www.telstrawholesale.com.au/"
                          itemProp="name"
                        >
                          Telstra Wholesale<span className="sr-only">external link</span> 
                          <span
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <IconExternalLink />
                          </span>
                        </a>
                      </li>

                      <li
                        itemProp="url"
                        id="id-157280810-6230ae86-4c09-473e-851c-b991eccb2f00"
                        data-analytics-component='{"componentInfo":{"componentID":"id-157280810-6230ae86-4c09-473e-851c-b991eccb2f00","title":"Other Telstra sites","linkText":"Global Enterprises &amp;amp; Services","destination":"https://www.telstraglobal.com/"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="https://www.telstraglobal.com/"
                          itemProp="name"
                        >
                          Global Enterprises &amp; Services<span className="sr-only">external link</span>
                          <span
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <IconExternalLink />
                          </span>
                        </a>
                      </li>

                      <li
                        itemProp="url"
                        id="id-157280810-2926f1cd-4439-4bf7-8ec9-1f9a6e54b842"
                        data-analytics-component='{"componentInfo":{"componentID":"id-157280810-2926f1cd-4439-4bf7-8ec9-1f9a6e54b842","title":"Other Telstra sites","linkText":"Telstra Media","destination":"http://media.telstra.com.au/home.html"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="http://media.telstra.com.au/home.html"
                          itemProp="name"
                        >
                          Telstra Media<span className="sr-only">external link</span>
                          <span
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <IconExternalLink />
                          </span>
                        </a>
                      </li>

                      <li
                        itemProp="url"
                        id="id-157280810-14648628-c9a9-4f7b-ba3e-583ba67b5e55"
                        data-analytics-component='{"componentInfo":{"componentID":"id-157280810-14648628-c9a9-4f7b-ba3e-583ba67b5e55","title":"Other Telstra sites","linkText":"Telstra Health","destination":"https://www.telstrahealth.com/"},"category":{"primaryCategory":"global-footer-item"}}'
                      >
                        <a
                          href="https://www.telstrahealth.com/"
                          itemProp="name"
                        >
                          Telstra Health<span className="sr-only">external link</span>
                          <span
                            className="footer-icon tcom-icon-16"
                            tabIndex="-1"
                            role="presentation"
                          >
                            <IconExternalLink />
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
            </div>

            <div className="socialIcons">
              <section className="telstra-social">
                <h2 className="heading-text">Find us</h2>
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/Telstra24x7"
                      aria-label="Facebook"
                    >
                    <Icon
                      size="32"
                      developmentUrl="/able-sprites.svg"
                      icon="FacebookContained"
                      className={'footer-icon'}
                    />
                      <span>Facebook</span>
                    </a>
                  </li>

                  <li>
                    <a href="https://twitter.com/telstra" aria-label="Twitter">
                    <Icon
                      size="32"
                      developmentUrl="/able-sprites.svg"
                      icon="TwitterContained"
                      className={'footer-icon'}
                    />
                      <span>Twitter</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.instagram.com/telstra/"
                      aria-label="Instagram"
                    >
                    <Icon
                      size="32"
                      developmentUrl="/able-sprites.svg"
                      icon="InstagramContained"
                      className={'footer-icon'}
                    />
                      <span>Instagram</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.youtube.com/user/TelstraCorp"
                      aria-label="YouTube"
                    >
                      <Icon
                      size="32"
                      developmentUrl="/able-sprites.svg"
                      icon="YoutubeContained"
                      className={'footer-icon'}
                    />
                      <span>YouTube</span>
                    </a>
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;