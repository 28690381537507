export const roleChecker = (obj, userRole) => {
  const roleList = ['ACCOUNT OWNER', 'FULL AUTHORITY', 'LIMITED AUTHORITY'];
  if (!userRole) {
    return true;
  }
  if (obj) {
    const hasChecked = roleList.some(key => obj[key]);
    if (hasChecked && userRole) {
      return !!obj[userRole];
    }
    return true;
  }
  return null;
};
