import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ActionButton, Spacing, TextStyle } from '@able/react';

import './ThumbnailDesc.scss';
import PlaceHolder from 'assets/images/tickets-placeholder-250x188@2x.jpg';
import { EventPreSale } from 'components';

const ThumbnailDesc = props => {
  const { singleEventView = false, data = {}, type } = props;

  const {
    tourDescription = '',
    thumbnailImageUrl = '',
    tourName = '',
    genre = '',
    offerIndicator = ''
  } = data;

  const initialLength = 140;

  const [read, setRead] = useState(true);

  const handleClick = e => {
    if (read) {
      setRead(false);
    } else {
      setRead(true);
    }
    if (e.target && e.target.parentNode) {
      e.target.parentNode.focus();
    } else {
      document.querySelector('#event-description').focus();
    }
  };

  const readTourDesc = () => {
    if (tourDescription.length <= initialLength) {
      return (
        <TextStyle alias="Base" element="p">
          {tourDescription}
        </TextStyle>
      );
    }
    return (
      <>
        <TextStyle alias="Base" element="p">
          {read
            ? `${tourDescription.substring(0, initialLength)}..`
            : tourDescription.substring(0, tourDescription.length)}
        </TextStyle>
        <ActionButton
          element="button"
          variant="LowEmphasis"
          label={read ? 'Read more' : 'Read less'}
          onClick={e => handleClick(e)}
        />
      </>
    );
  };

  const renderThumbnailContent = () => {
    if (singleEventView) {
      return (
        <div>
          <div>{readTourDesc()}</div>
        </div>
      );
    }

    return (
      <div>
        {tourDescription.length > 0 && readTourDesc()}
        {type === 'movie' && (
          <Spacing top="spacing4x">
            <ActionButton
              variant="LowEmphasis"
              label="Select a different ticket"
              element="Link"
              to="/tickets"
            />
          </Spacing>
        )}
      </div>
    );
  };

  // Set up CSS classes
  const tnClass = clsx('thumbnail-description', type === 'movie' && 'movie');

  return (
    <div className={tnClass}>
      <div className="image-container">
        <img
          className="tour-image"
          src={thumbnailImageUrl !== '' ? thumbnailImageUrl : PlaceHolder}
          alt={tourName || ''}
        ></img>
      </div>
      <Spacing bottom="spacing4x" />
      <div className="content-wrapper">
        <TextStyle element="h1" alias="HeadingA">
          {tourName}
        </TextStyle>
        <Spacing bottom="spacing4x" />
        {type === 'events' && (
          <div className="sale-details font-details">
            {genre === 'Sports' ? (
              offerIndicator
            ) : (
              <EventPreSale eventData={data} />
            )}
          </div>
        )}

        <div
          tabIndex="-1"
          role="document"
          id="event-description"
          className={
            `${tourDescription}`.length > initialLength && read.value === true
              ? 'short-desc font-details ellipsis'
              : 'short-desc font-details'
          }
        >
          {renderThumbnailContent()}
        </div>
      </div>
    </div>
  );
};

export default ThumbnailDesc;

ThumbnailDesc.propTypes = {
  type: PropTypes.string,
  data: PropTypes.shape({}),
  singleEventView: PropTypes.bool
};

ThumbnailDesc.defaultProps = {
  type: 'events',
  data: {
    tourDescription: PropTypes.string,
    performanceVenue: PropTypes.string,
    performanceTime: PropTypes.string,
    thumbnailImageUrl: PropTypes.string,
    tourName: PropTypes.string,
    genre: PropTypes.string,
    offerIndicator: PropTypes.string
  },
  singleEventView: false
};
