// Action Types, Describe what is happening
import _ from 'lodash';
import { LOGGER } from 'utils/AppLog';
import * as actions from 'constants/actions';
import * as ERRCODES from 'constants/errorCodes.js';
import * as ERRMSGS from 'constants/errorMessages.js';

import * as Analytics from 'utils/analytics';

import getDetails from 'api/getDetails';

import { fetchDetailsPending } from 'reducers/eligibility';
import { getMaskedEmail, getMaskedMobile } from 'utils/masking';

// Action Creators
const fetchCustomerDetails = user => {
  return (dispatch, getState) => {
    if (fetchDetailsPending(getState())) {
      return Promise.resolve();
    }

    dispatch({ type: actions.SET_LOADING, loading: true });

    dispatch({
      type: actions.GET_DETAILS_PENDING
    });

    return getDetails(user)
      .then(response => {
        LOGGER.debug('Get Details Response', response);

        // SUCCESS, YOU'RE ELIGIBLE!
        if (_.has(response, 'data')) {
          // check for heritage user otherwise continue as normal
          const { isHeritageUser } = response.data.data;

          if (isHeritageUser) {
            dispatch({
              type: actions.GET_DETAILS_HERITAGE,
              isHeritageUser
            });
          } else {
            processNormalUser(response, dispatch);
          }
        }
      })
      .catch(error => {
        localStorage.removeItem('tplus-flow');
        dispatch({ type: actions.SET_FLOW, payload: null });

        if (typeof error.response === 'undefined') {
          LOGGER.debug('Technical / 401 Error');
          dispatch({
            type: actions.SET_ERROR,
            hasErrored: true,
            errorStatusCode: 401,
            errorMessage: ERRMSGS.DEFAULTMESSAGE,
            error
          });
        } else if (typeof error.response !== 'undefined') {
          checkErrorCodes(error, dispatch);
        }

        dispatch({
          type: actions.SET_HASCHECKEDELIGIBILITY,
          hasCheckedEligibility: true
        });
        dispatch({
          type: actions.SET_LOADING,
          loading: false
        });
        throw error;
      });
  };
};


const processNormalUser = (response, dispatch) => {
  LOGGER.debug('Normal Response');

  const {
    isQualified, isEnrolled, mobilePhone = null, emailId = null
  } = response.data.data;
  if (isQualified && isEnrolled) {
    Analytics.addEvent({
      eventAction: 'loyaltyMember'
    });
  }

  dispatch({
    type: actions.SET_ELIGIBLE,
    eligible: isQualified,
    getDetails: response.data
  });

  // set contact capture data
  dispatch({
    type: actions.SET_ACCOUNT_MOBILE,
    mobile: mobilePhone
  });
  dispatch({
    type: actions.SET_ACCOUNT_MASKED_MOBILE,
    maskedMobile: getMaskedMobile(mobilePhone)
  });

  dispatch({
    type: actions.SET_ACCOUNT_EMAIL,
    email: emailId
  });
  dispatch({
    type: actions.SET_ACCOUNT_MASKED_EMAIL,
    maskedEmail: getMaskedEmail(emailId)
  });

  LOGGER.debug(
    'get details cac response:',
    response.data.data.accounts
  );

  const { accounts } = response.data.data;

  // Finding suspended accounts
  const suspendedAccounts = accounts.filter(orderedCac => {
    return orderedCac.enrollmentStatus === 'SUSPENDED';
  });
  // Finding limited authority accounts`
  const limitedAuthAccounts = accounts.filter(orderedCac => {
    return orderedCac.enrollmentStatus === 'LIMITED AUTHORITY';
  });
  // run through logic to action non eligible accounts
  if (suspendedAccounts.length > 0) {
    LOGGER.debug('Customer has some suspended accounts');
    hasQualifiedOrNot(isQualified, dispatch, response, limitedAuthAccounts, accounts);
  }

  LOGGER.debug('Primary accounts are: ', accounts);
  LOGGER.debug('Primary account is: ', accounts[0]);

  const filteredAccs = accounts.filter(orderedCac => {
    return orderedCac.enrollmentStatus === 'ENROLLED';
  });

  if (filteredAccs.length === 0) {
    dispatch({ type: actions.SET_ENROLLED, enrolled: false });
  } else {
    dispatch({ type: actions.SET_ENROLLED, enrolled: isEnrolled });
  }

  dispatch({
    type: actions.SET_INITIAL_ACCOUNT,
    cac: filteredAccs[0],
    membershipIndex: 1
  });

  // Loop through accounts and retrieve accountIds
  let cacList = '';
  filteredAccs.forEach(cac => {
    cacList = `${cacList}${cac.accountId},`;
  });

  cacList = cacList.substring(0, cacList.length - 1);
  // Strip last comma from list of CACs
  LOGGER.debug('CACLIST: ', cacList);

  dispatch({
    type: actions.SET_ACCOUNTS_LIST,
    sortedAccounts: filteredAccs,
    cacList
  });
}

const hasQualifiedOrNot = (isQualified, dispatch, response, limitedAuthAccounts, accounts) => {
  if (isQualified) {
    LOGGER.debug('Customer is qualified');
    dispatch({
      type: actions.SET_ELIGIBLE,
      eligible: isQualified,
      getDetails: response.data
    });
  } else {
    LOGGER.debug('Customer is NOT qualified');
    if (limitedAuthAccounts.length === accounts.length) {
      LOGGER.debug('Customer has limited authority');
      dispatch({
        type: actions.SET_ERROR,
        errorStatusCode: 1021
      });
      dispatch({
        type: actions.SET_ELIGIBLE,
        eligible: false,
        getDetails: response.data
      });
      dispatch({ type: actions.SET_ENROLLED, enrolled: false });
      dispatch({ type: actions.SET_LOADING, loading: false });
    } else {
      LOGGER.debug(
        'Cannot determine account status, send to erro '
      );
      dispatch({
        type: actions.SET_ERROR,
        hasErrored: true,
        errorStatusCode: 1040
      });
      dispatch({
        type: actions.SET_ELIGIBLE,
        eligible: false,
        getDetails: response.data
      });

      dispatch({ type: actions.SET_ENROLLED, enrolled: false });
      dispatch({ type: actions.SET_LOADING, loading: false });
    }
  }
}

const checkErrorCodes = (error, dispatch) => {
  LOGGER.debug('4XX type error');
  const { code } = error.response.data;
  const { status } = error.response;

  const handleDefaultError = () => {
    LOGGER.debug('Technical Error');
    Analytics.setPageName(
      'Eligibility Check - No response code Technical Error'
    );
    dispatch({
      type: actions.SET_ELIGIBLE,
      eligible: false,
      getDetails: error.response.data
    });
    dispatch({
      type: actions.SET_ENROLLED,
      enrolled: false
    });
    dispatch({
      type: actions.SET_ERROR,
      hasErrored: true,
      errorStatusCode: status,
      errorMessage: ERRMSGS.DEFAULTMESSAGE,
      error
    });
  };

  if (status === ERRCODES.ERR_429_TOO_MANY_REQUESTS) {
    LOGGER.debug('Status Code 429');
    dispatch({
      type: actions.SET_ELIGIBLE,
      eligible: false
    });
    dispatch({
      type: actions.SET_ENROLLED,
      enrolled: false
    });
    dispatch({
      type: actions.SET_ERROR,
      hasErrored: true,
      errorStatusCode: status,
      errorHeader: ERRMSGS.ERR_TOO_MANY_REQUESTS_MSG.errorHeading,
      errorMessage: ERRMSGS.ERR_TOO_MANY_REQUESTS_MSG.errorText,
      error
    });
  } else if (code) {
    switch (code) {
      case ERRCODES.ERR_NO_ACTIVE_CACS:
      case ERRCODES.ERR_ASSET_USER:
      case ERRCODES.ERR_USER_REL_ACCT_EXPIRED: {
        LOGGER.debug('Status Code 1025');
        dispatch({
          type: actions.SET_ELIGIBLE,
          eligible: false,
          getDetails: error.response.data
        });
        dispatch({
          type: actions.SET_ENROLLED,
          enrolled: false
        });
        dispatch({
          type: actions.SET_ERROR,
          errorStatusCode: code,
          error
        });
        break;
      }
      case ERRCODES.ERR_NO_CONTACT_LINKED: {
        LOGGER.debug('Status Code 1020');
        dispatch({
          type: actions.SET_ELIGIBLE,
          eligible: false,
          getDetails: error.response.data
        });
        dispatch({
          type: actions.SET_ENROLLED,
          enrolled: false
        });
        dispatch({
          type: actions.SET_ERROR,
          hasErrored: true,
          errorHeader: ERRMSGS.ERR_NO_CONTACT_LINKED_MSG.errorHeading,
          errorMessage: ERRMSGS.ERR_NO_CONTACT_LINKED_MSG.errorText,
          errorStatusCode: code,
          error
        });
        break;
      }
      case ERRCODES.ERR_LA_CAC: {
        LOGGER.debug('Status Code 1021');
        dispatch({
          type: actions.SET_ELIGIBLE,
          eligible: false,
          getDetails: error.response.data
        });
        dispatch({
          type: actions.SET_ENROLLED,
          enrolled: false
        });
        dispatch({
          type: actions.SET_ERROR,
          errorStatusCode: code,
          error
        });
        break;
      }
      case ERRCODES.ERR_SOME_SMALL_BUSINESS_CACS: {
        LOGGER.debug('Status Code 1030');

        const { isQualified, isEnrolled } = error.response.data.data;

        if (isQualified) {
          LOGGER.debug('1030 Qualified');

          dispatch({
            type: actions.SET_ELIGIBLE,
            eligible: true,
            getDetails: error.response.data
          });
          dispatch({
            type: actions.SET_ENROLLED,
            enrolled: isEnrolled
          });
          LOGGER.debug(
            'get details cac response:',
            error.response.data.data.accounts
          );
          const { accounts } = error.response.data.data;

          LOGGER.debug('Primary accounts are: ', accounts);
          LOGGER.debug('Primary account is: ', accounts[0]);

          if (accounts.length === 0) {
            dispatch({
              type: actions.SET_ENROLLED,
              enrolled: false
            });
          } else {
            dispatch({
              type: actions.SET_ENROLLED,
              enrolled: isEnrolled
            });
          }

          dispatch({
            type: actions.SET_INITIAL_ACCOUNT,
            cac: accounts[0],
            membershipIndex: 1
          });

          // Loop through accounts and retrieve accountIds
          let cacList = '';
          accounts.forEach(cac => {
            cacList = `${cacList}${cac.accountId},`;
          });

          cacList = cacList.substring(0, cacList.length - 1);
          // Strip last comma from list of CACs
          LOGGER.debug('CACLIST: ', cacList);

          dispatch({
            type: actions.SET_ACCOUNTS_LIST,
            sortedAccounts: accounts,
            cacList
          });

          dispatch({
            type: actions.SET_LOADING,
            loading: false
          });
        } else {
          LOGGER.debug('1030 Failed qualification');
          dispatch({
            type: actions.SET_ELIGIBLE,
            eligible: false,
            getDetails: error.response.data
          });
          dispatch({
            type: actions.SET_ENROLLED,
            enrolled: false
          });
          dispatch({
            type: actions.SET_ERROR,
            hasErrored: true,
            errorStatusCode: code,
            error
          });
          dispatch({
            type: actions.SET_LOADING,
            loading: false
          });
        }
        break;
      }
      case ERRCODES.ERR_NO_QUALIFIED_ACC: {
        LOGGER.debug('Status Code 1070');
        dispatch({
          type: actions.SET_ELIGIBLE,
          eligible: false,
          getDetails: error.response.data
        });
        dispatch({
          type: actions.SET_ENROLLED,
          enrolled: false
        });
        dispatch({
          type: actions.SET_ERROR,
          hasErrored: true,
          errorHeader: ERRMSGS.ERR_NO_QUALIFIED_ACC.errorHeading,
          errorMessage: ERRMSGS.ERR_NO_QUALIFIED_ACC.errorText,
          errorStatusCode: code,
          error
        });
        break;
      }
      case ERRCODES.ERR_NO_ACC_UNDER_REVIEW: {
        LOGGER.debug('Status Code 1071');
        dispatch({
          type: actions.SET_ELIGIBLE,
          eligible: false,
          getDetails: error.response.data
        });
        dispatch({
          type: actions.SET_ENROLLED,
          enrolled: false
        });
        dispatch({
          type: actions.SET_ERROR,
          hasErrored: true,
          errorHeader: ERRMSGS.ERR_NO_ACC_UNDER_REVIEW.errorHeading,
          errorMessage: ERRMSGS.ERR_NO_ACC_UNDER_REVIEW.errorText,
          errorStatusCode: code,
          error
        });
        break;
      }
      case ERRCODES.ERR_ACCS_UNDER_REVIEW: {
        LOGGER.debug('Status Code 1072');
        dispatch({
          type: actions.SET_ELIGIBLE,
          eligible: false,
          getDetails: error.response.data
        });
        dispatch({
          type: actions.SET_ENROLLED,
          enrolled: false
        });
        dispatch({
          type: actions.SET_ERROR,
          hasErrored: true,
          errorHeader: ERRMSGS.ERR_ACCS_UNDER_REVIEW.errorHeading,
          errorMessage: ERRMSGS.ERR_ACCS_UNDER_REVIEW.errorText,
          errorStatusCode: code,
          error
        });
        break;
      }
      case ERRCODES.ERR_USER_MULTIPLE_CONTACT: {
        LOGGER.debug('Status Code 1073');
        dispatch({
          type: actions.SET_ELIGIBLE,
          eligible: false,
          getDetails: error.response.data
        });
        dispatch({
          type: actions.SET_ENROLLED,
          enrolled: false
        });
        dispatch({
          type: actions.SET_ERROR,
          hasErrored: true,
          errorHeader: ERRMSGS.ERR_USER_MULTIPLE_CONTACT_MSG.errorHeading,
          errorMessage: ERRMSGS.ERR_USER_MULTIPLE_CONTACT_MSG.errorText,
          errorStatusCode: code,
          error
        });
        break;
      }
      case ERRCODES.ERR_429_TOO_MANY_REQUESTS: {
        LOGGER.debug('Status Code 429');
        dispatch({
          type: actions.SET_ELIGIBLE,
          eligible: false
        });
        dispatch({
          type: actions.SET_ENROLLED,
          enrolled: false
        });
        dispatch({
          type: actions.SET_ERROR,
          hasErrored: true,
          errorStatusCode: status,
          errorHeader: ERRMSGS.ERR_TOO_MANY_REQUESTS_MSG.errorHeading,
          errorMessage: ERRMSGS.ERR_TOO_MANY_REQUESTS_MSG.errorText,
          error
        });
        break;
      }
      default:
        handleDefaultError();
    }
  } else {
    handleDefaultError();
  }
}

export default fetchCustomerDetails;