import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TextStyle, Spacing, ActionButton, TextField, Icon, MessageInline } from '@able/react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Base64 } from 'js-base64';
import { has } from 'lodash';

import * as actions from 'constants/actions';
import alertSvg from 'assets/images/enrol/alert.svg';
import { clearAllErrors } from '../utils/helpers';


const MobileForm = props => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const { page } = props;

  useEffect(() => {
    dispatch({ type: actions.SET_OTP_FLOW, flow: 'mobile' });
  }, [dispatch]);

  const accountMobile = useSelector(state => state.accounts.maskedMobile);
  const otpFlow = useSelector(state => state.otpFlow);
  
  return (
    <>
      <Formik
        initialValues={{
          mobileNumber: ''
        }}
        // Schema from
        // https://manual.limesurvey.org/Using_regular_expressions#Australian_mobile_phone_numbers_only
        validationSchema={Yup.object({
          mobileNumber: Yup.string()
            .matches(
              /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/,
              'Enter a valid mobile number'
            )
            .required('Required')
        })}
        onSubmit={values => {
          const mobileOtpRequest = {
            referenceId: Base64.encode(values.mobileNumber),
            serviceType: 'SMS',
            serviceId: Base64.encode(values.mobileNumber),
            page
          };
          dispatch({ type: actions.SET_MOBILE_FETCHING, fetching: true });
          props
            .apiAction(mobileOtpRequest)
            .then(() => {
              // We have successfully made a get otp call
              // Turn off the loading flag
              dispatch({ type: actions.SET_MOBILE_FETCHING, fetching: false });
              // Clear all errors
              clearAllErrors({ flow: 'mobile' });
              // Move to next screen
              props.nextAction({ modalType: 'otp', modalSwitch: true });
            })
            .catch(error => {
              dispatch({ type: actions.SET_MOBILE_FETCHING, fetching: false });
              if (has(error, 'response')) {
                switch (error.response.data.code) {
                  case 1005:
                    props.nextAction({
                      modalType: 'mobile',
                      modalSwitch: true
                    });
                    break;
                  case 2005:
                      props.nextAction({
                        modalType: 'mobile',
                        modalSwitch: true
                      });
                      break;  
                  case 2004:
                      props.nextAction({
                        modalType: 'mobile',
                        modalSwitch: true
                      });
                      break;      
                  default:
                    props.nextAction({});
                }
              } else {
                // We dont know whats going on with this error (could be network)
                // Redirect to error page because we dont know this type of error
                return history('/error');
              }
              return null;
            });
        }}
      >
        {(props) => {
          const doChange = (e) => {
            props.handleChange(e);
          }
          return (
            <Form>
              <Spacing bottom="spacing1x"/>
              <TextStyle element="p" alias="Base">
                We&apos;ll now send you a one-time PIN to your mobile to confirm
                your details.
              </TextStyle>

              {accountMobile && (
                <>
              <Spacing bottom="spacing5x"/>

              <TextStyle element="p" alias="Label">
                Current mobile number
              </TextStyle>

              <Spacing bottom="spacingHalf"/>

              <TextStyle element="p" alias="Base">
                {accountMobile}
              </TextStyle>
              </>
              )}

              <Spacing bottom="spacing3x"/>

              <TextField
                label="New mobile number"
                id="mobileNumber"
                name="mobileNumber"
                invalidInputText={props.errors.mobileNumber}
                invalid={props.errors.mobileNumber ? "true" : null}
                developmentUrl="/able-sprites.svg"
                events={{ onKeyUp: doChange}}
                type="tel"
                note="Eg. 0416234567"
                autoComplete="on"
              />
               {otpFlow && otpFlow.mobile && otpFlow.mobile.error && (
                <TextStyle element="p" alias="FinePrint" className="opt-validation-error">
                <Icon
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  icon="Error"
                  screenReaderContent="Error"
                  role="img"
                  aria-label="Error"
                />
                <span>{otpFlow?.mobile?.errorMsg}</span>
              </TextStyle>
              )}
              {(otpFlow?.otp?.error?.code === 2005 || otpFlow?.otp?.error?.code === 2004) && (
                <>
                <Spacing bottom="spacing2x"/>
                <div role="status">
                 <MessageInline
                    variant="Error"
                    developmentUrl="/able-sprites.svg"
                        >
                    <React.Fragment key=".0">
                    {otpFlow?.otp?.errorMsg}
                    </React.Fragment>
                 </MessageInline>
                 </div>
                 </>
              )}

              <Spacing bottom="spacing5x"/>

              <ActionButton
                variant="MediumEmphasis"
                label="Send one-time PIN"
                element="button"
                type="submit"
              />
            </Form>
          )
        }}
      </Formik>
    </>
  );
};

export default MobileForm;

MobileForm.propTypes = {
  page: PropTypes.string.isRequired,
  apiAction: PropTypes.func.isRequired,
  nextAction: PropTypes.func.isRequired
};
