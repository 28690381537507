export const TICKET_LOCAL_STORAGE = 'TICKET_LOCAL_STORAGE_FLOW';
export const TICKET_EVENT = 'TICKET_EVENT';

// movie
export const TICKET_MOVIE = 'TICKET_MOVIE';
export const TICKET_VILLAGE_GROUP = 'VILLAGE';


export const STATES = [
    {
      label: 'New South Wales',
      value: 'NSW'
    },
    {
      label: 'Queensland',
      value: 'QLD'
    },
    {
      label: 'Northern Territory',
      value: 'NT'
    },
    {
      label: 'Western Australia',
      value: 'WA'
    },
    {
      label: 'South Australia',
      value: 'SA'
    },
    {
      label: 'Victoria',
      value: 'VIC'
    },
    {
      label: 'Tasmania',
      value: 'TAS'
    }
  ];
