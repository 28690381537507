import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Column, Spacing } from '@able/react';
import {LoggerContext} from '../../rewardstore/contexts/RewardsStoreContext';
import { SESSION_STORAGE_SOURCE } from 'constants/rewardsStore';
import { RewardsProductView } from 'views';

import * as Analytics from 'utils/analytics';

// test api
import {
  fetchRewardProduct,
  resetRewardProduct,
  setRSError
} from 'actions/rewards';
import {fetchRewardProductStocks} from 'actions/productStocks';

const RewardsProductContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  const logger = useContext(LoggerContext);

  // Call product api on load
  useEffect(() => {
    // set source in query params - ARP changes
    const url = new URL(window.location);
    sessionStorage.getItem(SESSION_STORAGE_SOURCE) && url.searchParams.set('source', sessionStorage.getItem(SESSION_STORAGE_SOURCE));
    window.history.replaceState({}, '', decodeURIComponent(url));
    dispatch(fetchRewardProduct(id));
    dispatch(fetchRewardProductStocks());
    return () => {
      dispatch(resetRewardProduct());
    };
  }, [id]);

  // Set up the product state we will pass into product view
  const [product, setproduct] = useState();

  const isAuthenticated = useSelector(state => state.appStatus.authenticated);
  const loaFlowStatus = useSelector(state => state.appStatus.loaFlowStatus);
  const { status: loaStatus, opts: loaOpts } = loaFlowStatus;

  const shopProductStatus = useSelector(
    state => state.rewards.productView.status
  );

  // Grab the product state
  const shopProducts = useSelector(
    state => state.rewards.productView.product.productFamilies
  );

  useEffect(() => {
    // Get products failed so redirect to 404
    if (shopProductStatus === 'failed') {
      history('/not-found');
    }
    // No service under CAC, redirect to forbidden page
    else if(shopProductStatus === 'forbidden'){
      dispatch(setRSError('ERR_NO_SERVICE_UNDER_CAC'));
      history("/rewards/forbidden");
    }
  }, [shopProductStatus]);
  
  // Grab updated product from state and pull into local state
  useEffect(() => {
    if (shopProducts) {
      const firstProduct = shopProducts[0];
      setproduct(firstProduct);
    }
  }, [shopProducts]);

  useEffect(() => {
    logger.info("RewardsProductContainer:Loaded");
  }, []);

  useEffect(() => {
     // If no rewards available dispatch an action and redirect to error page
    if (shopProducts && !shopProducts.length > 0) {
      dispatch(setRSError('ERR_NO_REWARD_FOUND'));
      history("/rewards/explore/error");
    }
  }, [shopProductStatus, shopProducts])

  return (
       <Grid>
      <Column cols={12}>
        <RewardsProductView
          productFamilies={shopProducts}
          product={product}
          isAuthenticated={isAuthenticated}
          loaStatus={loaStatus}
          loaOpts={loaOpts}
          productFamily={product}
        />
      </Column>
    </Grid> 
  );
};

export default RewardsProductContainer;
