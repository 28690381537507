import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Base64 } from 'js-base64';
import { has } from 'lodash';
import alertSvg from 'assets/images/enrol/alert.svg';
import * as actions from 'constants/actions';
import { TextStyle, Spacing, ActionButton, TextField, Icon, MessageSection, MessageInline } from '@able/react';
import { clearAllErrors } from '../utils/helpers';

const EmailForm = props => {
  const { page } = props;

  const otpFlow = useSelector(state => state.otpFlow);

  const dispatch = useDispatch();
  const history = useNavigate();

  useEffect(() => {
    dispatch({ type: 'SET_OTP_FLOW', flow: 'sendemail' });
  }, [dispatch]);

  const accountEmail = useSelector(state => state.accounts.maskedEmail);

  return (
    <>
      <Formik
        initialValues={{
          email: ''
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email('Enter a valid email address')
            .required('Required')
        })}
        onSubmit={values => {
          dispatch({ type: actions.SET_EMAIL_FETCHING, fetching: true });
          const mobileOtpRequest = {
            referenceId: Base64.encode(values.email),
            serviceType: 'EMAIL',
            serviceId: Base64.encode(values.email),
            page
          };
          props
            .apiAction(mobileOtpRequest)
            .then(() => {
              dispatch({ type: actions.SET_EMAIL_FETCHING, fetching: false });
              clearAllErrors({ flow: 'sendemail' });
              props.nextAction({ modalType: 'otp', modalSwitch: true });
            })
            .catch(error => {
              dispatch({ type: actions.SET_EMAIL_FETCHING, fetching: false });
              if (has(error, 'response')) {
                switch (error?.response?.data?.code) {
                  case 1006:
                    props.nextAction({
                      modalType: 'sendemail',
                      modalSwitch: true
                    });
                    break;
                  case 2005:
                      props.nextAction({
                        modalType: 'sendemail',
                        modalSwitch: true
                      });
                      break;  
                  case 2004:
                      props.nextAction({
                        modalType: 'sendemail',
                        modalSwitch: true
                      });
                      break;        
                  default:
                    props.nextAction({});
                }
              } else {
                // We dont know whats going on with this error (could be network)
                // Redirect to error page because we dont know this type of error
                return history('/error');
              }
              return null;
            });
        }}
      >
        {(props) => {
          const doChange = (e) => {
            props.handleChange(e);
          }
          console.log(props);
          return(
          <Form>
            <Spacing bottom="spacing1x"/>
              <TextStyle element="p" alias="Base" label="Email" name="email" type="email">
              We&apos;ll now send you a one-time PIN to your new email address to verify it.
              </TextStyle>
              <Spacing bottom="spacing4x"/>
              <MessageInline
                    variant="Attention"
                    developmentUrl="/able-sprites.svg"
                        >
                    <React.Fragment key=".0">
                    To change your billing address, you can do so in My Telstra.
                    </React.Fragment>
                 </MessageInline>
              {accountEmail && (
                <>
              <Spacing bottom="spacing4x"/>

              <TextStyle element="p" alias="Label">
                Current email address
              </TextStyle>

              <Spacing bottom="spacingHalf"/>

              <TextStyle element="p" alias="Base">
              {accountEmail}
              </TextStyle>
              </>)}
          
            
            <Spacing bottom="spacing3x"/>

            <TextField
              label="New email address"
              name="email"
              id="email"
              invalidInputText={props.errors.email}
              invalid={props.errors.email ? "true" : null}
              developmentUrl="/able-sprites.svg"
              events={{ onKeyUp: doChange}}
              autoComplete="email"
              type="email"
            />
            {otpFlow?.email?.error && (
               <TextStyle element="p" alias="FinePrint" className="opt-validation-error">
               <Icon
                 size="24"
                 developmentUrl="/able-sprites.svg"
                 icon="Error"
                 screenReaderContent="Error"
                 role="img"
                 aria-label="Error"
               />
               <span>{otpFlow?.email?.errorMsg}</span>
             </TextStyle>
           )}
            {(otpFlow?.otp?.error?.code === 2005 || otpFlow?.otp?.error?.code === 2004) && (
              <>
              <Spacing bottom="spacing2x"/>
              <div role="status">
              <MessageInline
                  variant="Error"
                  developmentUrl="/able-sprites.svg"
                     >
                  <React.Fragment key=".0">
                  {otpFlow?.otp?.errorMsg}
                  </React.Fragment>
              </MessageInline>
              </div>
              </>
              )}
            <Spacing bottom="spacing5x"/>

            <ActionButton
              variant="MediumEmphasis"
              rel="noopener noreferrer"
              label="Send one-time PIN"
              element="button"
              type="submit"                
            />
          </Form>
          )
        }}
      </Formik>
    </>
  );
};

export default EmailForm;

EmailForm.propTypes = {
  page: PropTypes.string.isRequired,
  apiAction: PropTypes.func.isRequired,
  nextAction: PropTypes.func.isRequired
};
