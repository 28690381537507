import React, { useState, useEffect } from 'react';
import { useSelect } from 'downshift';

import Highlight from 'react-highlighter';

import IconSearch from 'icons/IconSearch';
import IconChevron from 'icons/IconChevron';
import './SportsDropDown.scss';
import IconInfoTicket from 'assets/images/alert-info.svg';

const defaultItems = [
  { id: 1, name: 'option 1' },
  { id: 2, name: 'option 2' },
  { id: 3, name: 'option 3' },
  { id: 4, name: 'option 4' },
  { id: 5, name: 'option 5' }
];

const SportsDropDown = ({
  label = '',
  initialSelectedItem = '',
  dropDownItems = defaultItems,
  textFieldName = 'name',
  searchable = false,
  searchPlaceholder = '',
  onChange
}) => {
  const [items, setItems] = useState(dropDownItems);
  const [searchInput, setSearchInput] = useState('');
  const itemToString = item => {
    if (typeof item === 'string' || item instanceof String) {
      if (item === initialSelectedItem) {
        const thisWeekLabel = `${item} (This Week)`;
        return thisWeekLabel;
      }
      return item;
    }
    return item && textFieldName ? item[textFieldName] : '';
  };
  const {
    isOpen,
    selectedItem,
    getLabelProps,
    getToggleButtonProps,
    getMenuProps,
    getItemProps
  } = useSelect({
    items,
    itemToString,
    onSelectedItemChange: onChange
  });

  useEffect(() => {
    setItems(dropDownItems);
  }, [dropDownItems]);

  useEffect(() => {
    setSearchInput('');
    setItems(dropDownItems);
  }, [isOpen]);

  const handleSearch = () => {};

  return (
    <div className="sports-dropdown-wrapper">
      <button
        type="button"
        className="dropdown-btn"
        {...getToggleButtonProps()}
      >
        <span className="sports-downshift" {...getLabelProps()}>
          {itemToString(selectedItem) || `${label}` || `${initialSelectedItem}`}
        </span>
        <span className="chevron">
          <IconChevron />
        </span>
      </button>
      <div
        {...getMenuProps({
          onKeyDown: event => {
            // your custom keyDown handler here.
            // Prevent Downshift's default 'Space', 'Shift', 0-9, a-z.
            if (
              event.keyCode === 16 ||
              event.keyCode === 32 ||
              (event.keyCode >= 48 && event.keyCode <= 90)
            ) {
              // eslint-disable-next-line no-param-reassign
              event.nativeEvent.preventDownshiftDefault = true;
            }
          }
        })}
        className="dropdown-menu"
      >
        {isOpen && (
          <>
            {searchable && items && items.length > 0 && (
              <div className="input-field">
                <input
                  className="floating__input dropdown-search"
                  placeholder={searchPlaceholder}
                  onChange={handleSearch}
                />
                <div className="search-icon">
                  <IconSearch />
                </div>
              </div>
            )}
            <ul>
              {items && items.length > 0 ? (
                items.map((item, index) => (
                  <li
                    className={`dropdown-option ${
                      selectedItem === item ? 'selected' : ''
                    }`}
                    key={`${itemToString(item)}-${index}`}
                    {...getItemProps({ item, index })}
                  >
                    <Highlight
                      search={searchInput}
                      matchStyle={{
                        fontWeight: 'bold',
                        backgroundColor: 'white'
                      }}
                    >
                      {itemToString(item)}
                    </Highlight>
                  </li>
                ))
              ) : (
                <li className="no-result">
                  <img
                    className="alert-icon"
                    src={IconInfoTicket}
                    alt="An alert icon"
                  />
                  Your selection has returned no results
                </li>
              )}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default SportsDropDown;
