import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import styles from './SubHeader.module.scss';

const SubHeader = props => {
  const isAuthenticated = useSelector(state => state.appStatus.authenticated);
  const authClass = isAuthenticated ? 'auth' : 'unauth';
  const { title, subTitle, metaTitle, metaDesc, metaKeywords } = props;

  const renderSEO = () => {
    return (
      <Helmet>
        {metaTitle && <title>{metaTitle}</title>}
        {metaDesc && <meta name="description" content={metaDesc} />}
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
      </Helmet>
    );
  };

  return (
    <>
      {(title || subTitle) && (
        <div className={styles['sub-header-holder']}>
          <div className={clsx(styles['heading-top'], styles[authClass])}>
            <h1 className={styles.title}>{title}</h1>
            <p className={styles['sub-title']}>{subTitle}</p>
          </div>
        </div>
      )}
      {renderSEO()}
    </>
  );
};

export default SubHeader;

SubHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string
};

SubHeader.defaultProps = {
  title: undefined,
  subTitle: undefined
};
