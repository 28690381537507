import React from 'react';

export default function IconChevron() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <polygon
          fill="#6D6D6D"
          fillRule="nonzero"
          points="15.41 7.41 14 6 8 12 14 18 15.41 16.59 10.83 12"
        />
        <polygon points="0 0 24 0 24 24 0 24" />
      </g>
    </svg>
  );
}
