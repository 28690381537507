import React, { useEffect, useState } from 'react';
import { Select, TextStyle, Spacing } from '@able/react';
import { useSelector } from 'react-redux';
import { getJegoComponents } from 'selectors/GetChildComponents';
import _ from 'lodash';

import './ChoiceChips.scss';

const ChoiceChips = props => {
  const {
    choiceChipHeader = '',
    choiceChipHeaderLevel = 'h2',
    choiceChipsItems = {},
    choiceChipsSelectLabel = '',
    choiceChipTabAriaLabel = '',
    choiceChipActiveId = ''
  } = props;

  const [activeChip, setActiveChip] = useState(choiceChipActiveId);

  const activeIndex = () => {
    let index = '';
    Object.keys(choiceChipsItems).forEach(i => {
      if (choiceChipsItems[i].choiceChipId === activeChip) {
        index = i;
      }
    });
    return index;
  };

  const activeComponents = useSelector(state =>
    getJegoComponents(state, props[activeIndex()])
  );

  const handleLeftRight = (button, buttons, event) => {
    const LEFT_ARROW = 'ArrowLeft';
    const RIGHT_ARROW = 'ArrowRight';
    if (event.key !== LEFT_ARROW && event.key !== RIGHT_ARROW) return;
    let currentIndex = _.indexOf(buttons, button);
    if (event.key === RIGHT_ARROW) {
      currentIndex += 1;
      if (currentIndex >= buttons.length) currentIndex = 0;
    } else {
      currentIndex -= 1;
      if (currentIndex < 0) currentIndex = buttons.length - 1;
    }
    const newFocusButton = buttons[currentIndex];
    if (newFocusButton) newFocusButton.focus();
  };

  useEffect(() => {
    const buttons = document.querySelectorAll('[data-tplus-cchips-button]');
    buttons.forEach(button => {
      button.addEventListener('keyup', event => {
        handleLeftRight(button, buttons, event);
      });
    });
  }, []);

  const setupSelectArray = () => {
    const selectArray = [];
    Object.keys(choiceChipsItems).forEach(i => {
      const { choiceChipsLabel, choiceChipId } = choiceChipsItems[i];
      const obj = {
        label: choiceChipsLabel,
        value: choiceChipId
      };
      selectArray.push(obj);
    });
    return selectArray;
  };

  const buttonList = Object.keys(choiceChipsItems).map((i, key) => {
    const { choiceChipId, choiceChipsLabel } = choiceChipsItems[i];
    const isActive = choiceChipId === activeChip;
    return (
      <button
        type="button"
        data-tplus-cchips-button
        key={key}
        className={`tplus-cchips__chip ${isActive &&
          'tplus-cchips__chip--active'}`}
        role="tab"
        aria-selected={isActive}
        aria-controls={`${choiceChipId}-content`}
        id={choiceChipId}
        tabIndex={isActive ? '0' : '-1'}
        onClick={() => setActiveChip(choiceChipId)}
      >
        {choiceChipsLabel}
      </button>
    );
  });

  return (
    <>
      {choiceChipHeader && (
        <>
          <TextStyle alias="HeadingB" element={choiceChipHeaderLevel}>
            {choiceChipHeader}
          </TextStyle>
          <Spacing bottom="spacing4x" />
        </>
      )}
      <div
        role="tablist"
        className="tplus-cchips__container"
        aria-label={choiceChipTabAriaLabel}
      >
        {buttonList}
      </div>
      <div className="tplus-cchips__select__container">
        <Select
          developmentUrl="/able-sprites.svg"
          label={choiceChipsSelectLabel}
          value={activeChip}
          options={setupSelectArray()}
          events={{
            onChange: e => {
              setActiveChip(e.currentTarget.value);
            }
          }}
        />
      </div>
      <div
        id={`${activeChip}-content`}
        role="tabpanel"
        aria-labelledby={activeChip}
        tabIndex="0"
        className="tplus-cchips__content"
      >
        {activeComponents}
      </div>
    </>
  );
};

export default ChoiceChips;
