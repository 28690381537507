// import { combineReducers } from 'redux';
// import _ from 'lodash';

import {
  GET_EVENTS,
  GET_EVENTS_CHILD,
  RESET_EVENTS_ERROR,
  SET_EVENTS_ERROR,
  FETCH_TICKETS,
  SET_EVENTS_CHILD_PAGE_GLOBAL_ERROR,
  RESET_EVENTS_CHILD_PAGE_GLOBAL_ERROR,
  SET_EVENTS_CHILD_DEFAULT
} from 'constants/actions';

const defaultEvent = {
  Arts: [{}],
  Music: [{}],
  Sports: [{}],
  childEvents: {
    details: {
      tierPriorityDetails: []
    },
    children: [{}]
  },
  fetch: null,
  error: null,
  globalTicketErrorForChildPage: false
};

export const events = (eventsState = defaultEvent, action) => {
  switch (action.type) {
    case GET_EVENTS: {
      return { ...eventsState, ...action.keys };
    }
    case GET_EVENTS_CHILD: {
      return { ...eventsState, childEvents: action.keys };
    }
    case SET_EVENTS_CHILD_DEFAULT: {
      return { ...eventsState, childEvents: defaultEvent.childEvents };
    }
    case SET_EVENTS_ERROR: {
      return { ...eventsState, error: action.error };
    }
    case RESET_EVENTS_ERROR: {
      return { ...eventsState, error: null };
    }
    case FETCH_TICKETS: {
      return { ...eventsState, fetch: action.value };
    }
    case SET_EVENTS_CHILD_PAGE_GLOBAL_ERROR: {
      return {
        ...eventsState,
        globalTicketErrorForChildPage: action.globalTicketErrorForChildPage
      };
    }
    case RESET_EVENTS_CHILD_PAGE_GLOBAL_ERROR: {
      return { ...eventsState, globalTicketErrorForChildPage: false };
    }
    default:
      return eventsState;
  }
};
