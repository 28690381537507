export const MEMBERTILECOPY =
  'Brand new customers or existing customers with an annual eligible account spend of less than $1,500';
export const SILVERTILECOPY =
  'Existing customers with an annual eligible account spend of $1,500 - $2999';
export const GOLDTILECOPY =
  'Existing customers with an annual eligible account spend of $3,000+';

export const EARNPOINTS =
  'Earn 10 points per $1 on your eligible payments. Earn bonus points when you take up special offers.';

export const REDEEMPOINTS = `Redeem rewards with your points at the <a href="/rewards/explore">Rewards Store</a>`;

export const EXTRAS = ['Telstra TV offer'];

export const TICKETS = [
  'Discounted movie tickets',
  'Discounted sports tickets',
  'Pre-sale access to concert tickets',
  'Pre-sale and discounted arts tickets',
  'Chances to win money-can’t-buy experiences'
];

export const VIPSERVICES = ['Get help and support'];

export const BUSINESSVIPSERVICES = ['Telstra Business Tech Services'];

export const SILVEREXTRAS = ['1 month Kayo, on us'];

export const SILVERVIPSERVICES = [
  'One-off tech support, even for non-Telstra tech'
];

export const GOLDEXTRAS = ['3 months Kayo, on us'];

export const LEARNABOUTTIERS =
  'There are three Telstra plus membership tiers: Member, Silver and Gold. You will have access to different membership benefits depending on your tier';

export const GOLDVIPSERVICES = [`24/7 tech support, even for non-Telstra tech`];

export const TIER_GOLD = 'GOLD';
export const TIER_SILVER = 'SILVER';
export const TIER_BUSINESS = 'BUSINESS';

export const SECTION_VIP = 'VIPSection';

export const TierInfo = {
  VIPSection: {
    GOLD: {
      promoTitle: 'Get tech support when you need it',
      promoCopy: `Get expert help when you need it with devices, home tech and software including non-Telstra products, over the phone and online with Telstra Platinum® service on us.<br><br>
      Gold tier customers with an existing Telstra Platinum subscription will receive a monthly credit for 12 months towards their existing Telstra Platinum subscription.
      `,
      buttonText: 'Go to Telstra Platinum',
      url: 'https://www.telstra.com.au/platinum-technical-support#subscription'
    },
    SILVER: {
      promoTitle: 'Let us solve your tech problem ',
      promoCopy: `Get expert help setting up email, software or a tablet including non-Telstra products, connecting your printer or a social media tutorial with a free one-off tech support session, over the phone and online with Telstra Platinum®.<br><br>
        Silver tier customers who are existing ‘Telstra Platinum Service Subscription’ customers and who contact Telstra Platinum with a Platinum related problem will receive 1 month credit towards the their existing Telstra Platinum subscription.`
    }
  },
  BUSINESS: {
    promoTitle: 'Let us solve your tech problem ',
    promoCopy: `Get expert help setting up email, software or a tablet including non-Telstra products, connecting your printer or a social media tutorial with a free one-off tech support session, over the phone and online with Telstra Platinum®.<br><br>
        Business tier customers who are existing ‘Telstra Platinum Service Subscription’ customers and who contact Telstra Platinum with a Platinum related problem will receive 1 month credit towards the their existing Telstra Platinum subscription.`
  }
};
