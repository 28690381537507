import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFocus } from 'utils/hooks/useFocus';

import { otpGetAction } from 'actions/otp';
import { TextStyle, IconButton, Spacing } from '@able/react';
import IconClose from 'icons/IconClose';
import EmailForm from 'components/OtpFlow/sidebarforms/EmailForm';

const EmailAddView = props => {
  const { page, toggleView, renderSpinner } = props;
  const dispatch = useDispatch();
  const [inputRef, setInputFocus] = useFocus();

  const otpFlow = useSelector(state => state.otpFlow);

  // Accessibility fix
  // Modal container was not getting focused upon loading.
  // This is more of a hack fix.
  useEffect(() => {
    setInputFocus();
  }, []);

  return (
    <>
      {otpFlow.email.fetching && renderSpinner()}
      {!otpFlow.email.fetching && (
        <>
          <div> 
         <TextStyle element="h2" tabIndex="-1" ref={inputRef} alias="HeadingB">
         Update your email address
          </TextStyle>
          </div>   
          <EmailForm
            page={page}
            apiAction={value => dispatch(otpGetAction(value))}
            nextAction={toggleView}
          />
        </>
      )}
      </>
  );
};

export default EmailAddView;

EmailAddView.propTypes = {
  page: PropTypes.string.isRequired,
  toggleView: PropTypes.func.isRequired,
  renderSpinner: PropTypes.func.isRequired
};
