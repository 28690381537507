/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import * as actions from 'constants/actions';
import Silentrenew from 'views/SilentRenew/SilentRenew';

import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { RouterContext } from 'contexts/RouterContext';
import { Enrolment, NotEligible, ErrorScreen, HowItWorksContainer, OptOutContainer, HeritageExplainer, Success } from 'containers';
import { PointsTransferView, PointsHistoryView, JegoPage } from 'views';
import EventChildPage from 'views/TicketsChildPages/EventChildPage';
import Buy from 'views/TicketsChildPages/Buy';
import { ProtectedRoute } from 'components';

const  RewardsApp = lazy(()=> import('rewardstore'));


const RoutesList = () => {
  // ProtectedRoute component checks for condition to show component or redirect
  // - path : path to check for routing
  // - otherPath : where to redirect if condition flag fails
  // - check : condition flag to check for
  // - component : component to load if check is valid

  const dispatch = useDispatch();
  const aemData = useSelector(state => state.aem);
  const isAuthenticated = useSelector(state => state.appStatus.authenticated);
  const [routesReady, setRoutesReady] = useState(false);
  const [childpages, setChildpages] = useState([]);
  const location = useLocation()
  const [route, setRoute] = useState({
    to: location.pathname,
    from: location.pathname
  });

  const parseChildPage = path => {
    const obj = {
      fullPath: '',
      path: '',
      auth: false
    };

    if (path) {
      pathFlow(path, obj);
    }
    return obj;
  };

  const reservedPagePush = (pageconfig, reservedPage, childpagePaths, parseChildPage, val) => {
    if (pageconfig && !reservedPage) {
      childpagePaths.push(parseChildPage(val.path));
    }
  }
  
  const pathFlow = (path, obj) => {
    const pathParts = path.split('tplus');
    if (pathParts[1]) {
      let newPath = pathParts[1];
      obj.fullPath = newPath;
      newPath = newPath.replace('/childpagesv2', '');
      if (newPath.indexOf('/auth') !== -1) {
        obj.auth = true;
        obj.path = newPath.replace('/auth', '');
      } else {
        obj.path = newPath.replace('/unauth', '');
      }
    }
  }

  const isReservedPage = path => {
    const reservedPages = ['/auth/home', '/unauth/home'];
    const pathParts = (path && path.split('tplus')) || [];
    return reservedPages.some(r => r === pathParts[1]);
  };

  const findChildPages = data => {
    const { all } = data;
    const childpagePaths = [];
    const iteratePages = pages => {
      _.forEach(pages, val => {
        const { path, pageconfig } = val;
        if (typeof val === 'object') {
          const reservedPage = isReservedPage(path);
          reservedPagePush(pageconfig, reservedPage, childpagePaths, parseChildPage, val);
          return iteratePages(val);
        }
      });
    };

    iteratePages(all);

    return childpagePaths;
  };

  useEffect(() => {
    setRoute(prev => ({ to: location.pathname, from: prev.to }));
  }, [location]);

  useEffect(() => {
    if (aemData) {
      setChildpages(findChildPages(aemData));
    }
  }, [aemData]);

  useEffect(() => {
    if (childpages.length) {
      dispatch({ type: actions.ROUTES_READY, payload: true });
      setRoutesReady(true);
    }
  }, [childpages]);

  return (
    <RouterContext.Provider value={route}>
      {routesReady && (
        <Routes>
          <Route
            exact
            path="/"
            element={
              <JegoPage
                fullPath={isAuthenticated ? '/auth/home' : '/unauth/home'}
              />
            }
          />
          <Route exact path="/howitworks" element={<HowItWorksContainer />} />
          <Route
            exact
            path="/history"
            element={
              <ProtectedRoute
                otherPath="/"
                check="authenticated"
                component={PointsHistoryView}
              />
            }
          />
          <Route
            path="/join"
            element={
              <ProtectedRoute
                otherPath="/"
                check="notenrolled"
                component={Enrolment}
              />
            }
          />
          <Route
            exact
            path="/transfer"
            element={
              <ProtectedRoute
                otherPath="/"
                check="authenticated"
                component={PointsTransferView}
              />
            }
          />
          <Route exact path="/not-eligible" element={<NotEligible />} />
          <Route exact path="/error" element={<ErrorScreen />} />
          <Route
            path="/success"
            exact
            element={
              <ProtectedRoute
                otherPath="/"
                check="authenticated"
                component={Success}
              />
            }
          />
          <Route
            exact
            path="/opt-out"
            element={
              <ProtectedRoute
                otherPath="/"
                check="enrolled"
                component={OptOutContainer}
              />
            }
          />
          <Route
            exact
            path="/explainer"
            element={
              <ProtectedRoute
                otherPath="/"
                check="authenticated"
                component={HeritageExplainer}
              />
            }
          />
          <Route
            exact
            path="/tickets/event/:genre/:id"
            element={<EventChildPage/>}
          />
          <Route exact path="/tickets/buy/:genre/:eid/:pid" element={<Buy/>} />
          <Route
            exact
            path="/points/history"
            element={<Navigate exact to="/history" />}
          />
          <Route
            exact
            path="/points/transfer"
            element={<Navigate exact to="/transfer" />}
          />
          <Route
            path="/rewards/*"
            element={<Suspense><RewardsApp appName="telstra-plus"></RewardsApp></Suspense>}
          />
          <Route path="/not-found" element={<ErrorScreen viewType="404" />} />
          <Route exact path="/silentrenew" component={Silentrenew} />
          <>
            {/* JEGO pages */}
            {childpages.map((item, index) => {
              if (isAuthenticated) {
                if (item.auth) {
                  return (
                    <Route
                      key={index}
                      exact
                      path={item.path}
                      element={<JegoPage fullPath={item.fullPath} />}
                    />
                  );
                }
              } else if (item.auth === false) {
                return (
                  <Route
                    exact
                    key={index}
                    path={item.path}
                    element={<JegoPage fullPath={item.fullPath} />}
                  />
                );
              }
            })}
          </>
          <Route path="*" element={<Navigate to="/not-found" replace />} />
        </Routes>
      )}
    </RouterContext.Provider>
  );
};

export default RoutesList;


