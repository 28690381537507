export const dependencyMatch = (
  values,
  dependencyFieldName,
  dependencyFieldValue
) => {
  // Match against multiple values with the OR operator
  if (dependencyFieldValue.indexOf('||') !== -1) {
    const depValueArr = dependencyFieldValue.split('||');
    return depValueArr.some(d => values[dependencyFieldName].includes(d));
  } // Match against multiple values with the AND operator
  if (dependencyFieldValue.indexOf('&&') !== -1) {
    const depValueArr = dependencyFieldValue.split('&&');
    let found = true;
    depValueArr.forEach(d => {
      if (!values[dependencyFieldName].includes(d)) {
        found = false;
      }
    });
    return found;
  }
  // Match against a fields string value
  if (typeof values[dependencyFieldName] === 'string') {
    return values[dependencyFieldName] === dependencyFieldValue;
  }
  // Match against a fields array
  if (values[dependencyFieldName] instanceof Array) {
    // Match against a single value in the array
    return values[dependencyFieldName].includes(dependencyFieldValue);
  }
  return false;
};
