import React from 'react';

export default function IconMinus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path
        fill="currentColor"
        d="M24 10.6H13.4V0h-2.8v10.6H0v2.8h10.6V24h2.8V13.4H24z"
      />
    </svg>
  );
}
