import { SET_REWARD_PROFILE } from 'constants/actions';

const defaultState = {};

export const profile = (state = defaultState, action) => {
  switch (action.type) {
    case SET_REWARD_PROFILE: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
};
