import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import MyAddressLookup from 'utils/forms/MyAddressLookup';
import { getMaskedAddress } from 'utils/masking';
import * as actions from 'constants/actions';
import { TextStyle, ActionButton, Spacing } from '@able/react';

import './AddressForm.scss';

const AddressForm = props => {
  const dispatch = useDispatch();
  const accountAddress = useSelector(state => state?.otpFlow?.address?.maskedAddress);

  const [addressConfirmed, setAddressConfirmed] = React.useState(false);

  return (
    <>
      <Formik
        initialValues={{
          address: '',
          addressId: ''
        }}
        validationSchema={Yup.object({
          address: Yup.string()
            .matches(/^((?!<script>).)*$/, 'Enter a valid address')
            .required('Enter a valid address')
        })}
        onSubmit={(values, formikBag) => {
          if (addressConfirmed) {
            dispatch({ type: actions.SET_ADDRESS, address: values.address });
            dispatch({
              type: actions.SET_ADDRESS_ID,
              adborid: values.addressId
            });
            dispatch({
              type: actions.SET_MASKED_ADDRESS,
              maskedAddress: getMaskedAddress(values.address)
            });
            dispatch({
              type: actions.SET_ADDRESS_TRIGGERED,
              payload: { triggered: true }
            });
            props.nextAction({ clearErrors: true });
          } else {
            formikBag.setErrors({ address: 'Enter a valid address' });
          }
        }}
      >
        {({ setFieldValue }) => (
          <div className="address-form">
            <Form>
            <Spacing bottom="spacing1x"/>
            <TextStyle element="p" alias="Base" label="Address" name="address" type="text">
                We'll use your address if we need to contact you about your order or your account.{' '}
              </TextStyle>

              {accountAddress && (
                <>
              <Spacing bottom="spacing5x"/>
               <TextStyle element="p" alias="Label">
                Current Residential Address
              </TextStyle>

              <Spacing bottom="spacingHalf"/>

              <TextStyle element="p" alias="Base">
              {accountAddress}
              </TextStyle>
              </>)}

              <Spacing bottom="spacing3x"/>
               <TextStyle element="p" alias="Label" for="address">
                New Residential Address
              </TextStyle>
              <TextStyle element="p" alias="FinePrint" colour="Subtle">
                Start typing your address then select from the list.{' '}
              </TextStyle>
              <Spacing bottom="spacingHalf"/>

              <MyAddressLookup
                label=""
                name="address"
                setAddressValue={e => setFieldValue('address', e)}
                setAddressId={e => setFieldValue('addressId', e)}
                setConfirmed={e => setAddressConfirmed(e)}
                nextAction={props.nextAction}
              />
              <Spacing bottom="spacing5x"/>

              <ActionButton
                variant="MediumEmphasis"
                rel="noopener noreferrer"
                label="Update address"
                element="button"
                type="submit"                
              />
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default AddressForm;

AddressForm.propTypes = {
  nextAction: PropTypes.func.isRequired
};
