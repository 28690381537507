/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Grid, ErrorSummary, Spacing } from '@able/react';
import { constructValidationSchema } from 'utils/forms/constructValidationSchema';
import {
  submitCampaign,
  getPreviousEntry,
  setNewCampaign
} from 'actions/forms';
import { SET_ERROR } from 'constants/actions';
import { InlineLoadingSpinner, useOtpFlow } from 'components';
import { dependencyMatch } from './functions/dependencyMatch';

import './FormContainer.scss';

const FormContainer = props => {
  const {
    children,
    campaignName,
    successPageRoute,
    noWinPageRoute,
    errorPageRoute,
    enteredWinRoute,
    enteredNoWinRoute,
    checkEntered,
    heading,
    bodyCopy,
    ctaText
  } = props;

  const dispatch = useDispatch();
  const primaryAccount = useSelector(state => state.accounts.sortedAccounts[0]);
  const campaignState = useSelector(state => state.forms[campaignName]);
  // const updateContactDetailsState = useSelector(
  //   state => state.updateContactDetails
  // );
  // const { error: contactError } = updateContactDetailsState;

  const history = useNavigate();

  const lodgeStatus = campaignState?.status;

  const {
    otpTriggered,
    otpData,
    otpValidationErrors,
    otpValidate,
    updateContactDetails
  } = useOtpFlow();

  const [otpExists, setotpExists] = useState(false);
  const [otpValidationSchema, setotpValidationSchema] = useState({});
  const [otpFieldErrors, setotpFieldErrors] = useState({});
  const [initialised, setInitialised] = useState(false);
  const [newSchema, setNewSchema] = useState({});
  const [fields, setFields] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [ableErrorSummaryArr, setableErrorSummaryArr] = useState([]);
  // const [contactErrorCode, setcontactErrorCode] = useState(false);
  // const buttonCopy = contactErrorCode ? 'Continue' : ctaText;

  const getInitialValue = type => {
    switch (type) {
      case 'formCheckboxGroup':
        return [];
      default:
        return '';
    }
  };

  const getAlldefaults = () => {
    const formDefaults = {};
    children.forEach(child => {
      const { name, type } = child.props;
      if (type === 'layoutContainer') {
        const nestedChildren = (
          (((child || {}).props || {}).children || {}).props || {}
        ).children;
        nestedChildren.forEach(nestedChild => {
          const { name: nestedName, type: nestedType } = nestedChild.props;
          formDefaults[nestedName] = getInitialValue(nestedType);
        });
      } else {
        formDefaults[name] = getInitialValue(type);
      }
    });
    return formDefaults;
  };

  const handleSubmit = values => {
    const cleanValues = {};
    _.forOwn(values, (val, key) => {
      if (val && val.length > 0) {
        cleanValues[key] = val;
      }
    });

    // Set up data to send to campaign api
    // Get data from otp hook as it will always have the current values
    // either default account or updated otp values
    let payload = {
      campaignName,
      campaignData: {
        cac: primaryAccount.accountId,
        ...cleanValues
      }
    };

    if (lodgeStatus !== 'loading') {
      if (otpExists) {
        // If contact details have been successfully updated then add these
        payload = {
          ...payload,
          campaignData: {
            ...payload.campaignData,
            ...otpData
          }
        };

        // Determine if otp values are valid
        const otpValid = otpValidate(otpValidationSchema);
        const { isValid } = otpValid;

        // Call update details if details have been changed
        if (isValid && otpTriggered) {
          return dispatch(updateContactDetails()).then(() => {
            dispatch(submitCampaign(payload));
          });
          // .catch(() => {
          //   setcontactErrorCode(true);
          // });
        }
        if (isValid) {
          return dispatch(submitCampaign(payload));
        }
      } else {
        // Call lodge campaign directly if no details updated
        return dispatch(submitCampaign(payload));
      }
    }
  };

  const formik = useFormik({
    initialValues: getAlldefaults(),
    validationSchema: Yup.object(newSchema),
    onSubmit: values => {
      return handleSubmit(values);
    }
  });

  useEffect(() => {
    dispatch(setNewCampaign(campaignName));
    if (checkEntered) {
      dispatch(getPreviousEntry(campaignName));
    }
    setInitialised(true);
  }, []);

  useEffect(() => {
    if (campaignState && initialised) {
      const {
        status,
        response,
        previousEntry: {
          response: { winningStatus: prevWinningStatus, campaignEntryStatus },
          status: previousEntryApiStatus
        }
      } = campaignState;
      if (checkEntered) {
        if (previousEntryApiStatus === 'succeeded') {
          if (campaignEntryStatus === 'true') {
            switch (prevWinningStatus) {
              case 'WON': {
                history(enteredWinRoute);
                break;
              }
              case 'NO WIN': {
                history(enteredNoWinRoute);
                break;
              }
              default:
                break;
            }
          } else {
            setShowForm(true);
          }
        }
        if (previousEntryApiStatus === 'failed') {
          if (errorPageRoute) {
            history(errorPageRoute);
          } else {
            dispatch({ type: SET_ERROR, hasErrored: true });
          }
        }
      } else {
        setShowForm(true);
      }
      if (status === 'succeeded') {
        // eslint-disable-next-line prefer-destructuring
        const winningStatus = (((response || {}).data || {}).data || {}).winningStatus;
        if (winningStatus === 'WON') {
          history(successPageRoute);
        } else {
          history(noWinPageRoute);
        }
      }
      if (status === 'failed') {
        if (errorPageRoute) {
          history(errorPageRoute);
        } else {
          dispatch({ type: SET_ERROR, hasErrored: true });
        }
      }
    }
  }, [campaignState, initialised]);

  const constructFields = formikProps => {
    const schemaObj = {};

    const prepareElement = child => {
      const { values } = formikProps;
      const {
        props: { type, name, dependencyFieldName, dependencyFieldValue },
        key
      } = child;
      const clonedElement = React.cloneElement(child, {
        key,
        className: 'tplus-form-element',
        otpErrors: otpFieldErrors
      });
      if (type === 'formContactCapture') {
        const {
          props: { emailIsRequired, mobileIsRequired, addressIsRequired }
        } = child;
        setotpExists(true);
        setotpValidationSchema({
          otpValidationTriggered: true,
          emailIsRequired,
          mobileIsRequired,
          addressIsRequired
        });
      }
      if (dependencyFieldName && dependencyFieldValue) {
        const hasMatch = dependencyMatch(
          values,
          dependencyFieldName,
          dependencyFieldValue
        );
        if (hasMatch) {
          schemaObj[name] = constructValidationSchema(child.props);
          return clonedElement;
        }
        // If a dependency field is not found, reset the fields value as it will be hidden.
        if (values[name] && values[name].length > 0) {
          formikProps.setFieldValue(name, getInitialValue(type));
        }
        return;
      }
      schemaObj[name] = constructValidationSchema(child.props);
      return clonedElement;
    };

    const formElements = [];

    children.forEach(child => {
      const { type } = child.props;
      // Find form fields inside layoutContainers
      if (type === 'layoutContainer') {
        const nestedChildren = child.props.children?.props?.children;
        if (nestedChildren) {
          const preparedChildren = [];
          nestedChildren.forEach(c => {
            const preparedChild = prepareElement(c);
            if (preparedChild) {
              preparedChildren.push(preparedChild);
            }
          });
          // If a prepared form field is found, replace the existing fields with the prepared ones.
          if (preparedChildren.length > 0) {
            const layoutComp = React.cloneElement(
              child.props.children,
              {},
              preparedChildren
            );
            const jegoComp = React.cloneElement(
              child,
              { className: 'tplus-form-element' },
              layoutComp
            );
            return formElements.push(jegoComp);
          }
          return;
        }
      }
      return formElements.push(prepareElement(child));
    });
    setNewSchema(schemaObj);
    setFields(formElements);
  };

  useEffect(() => {
    constructFields(formik);
  }, [
    formik.values,
    formik.isSubmitting,
    otpData,
    formik.errors,
    otpFieldErrors
  ]);

  useEffect(() => {
    // Start this all off when formik is starting to validate the form
    setotpFieldErrors({});
    const otpValid = otpValidate(otpValidationSchema);
    const { isValid, fieldErrors } = otpValid;
    // Grab all existing errors
    const { errors } = formik;
    let tempErrArr = [];
    // Loop through formik errors
    _.forEach(errors, (value, key) => {
      // Find corresponding child in AEM content
      const foundField = _.find(children, { props: { name: key } });
      // Find corresponding formik field object
      const thisFormikField = formik.getFieldMeta(key);
      if (foundField) {
        // eslint-disable-next-line no-shadow
        const { props } = foundField;
        // Get the info we need from AEM to create the Able error summary error object
        const { name, label } = props;
        // Create the object
        const ableErrorSummaryObj = { id: name, label }; // Taking the name from the form field and adding it as ID to the error summary component
        // If the formik field has been touched then add the
        // new object into the able error summary array
        if (thisFormikField.touched) tempErrArr.push(ableErrorSummaryObj);
      }
    });
    // add any otp errors
    if (!isValid && otpValidationErrors) {
      setotpFieldErrors(fieldErrors);
      tempErrArr = [...tempErrArr, ...otpValidationErrors];
    }
    // Update the able error summary array
    setableErrorSummaryArr(tempErrArr);
  }, [formik.isSubmitting, otpData]);

  return (
    <>
      {!showForm && <InlineLoadingSpinner />}
      {showForm && (
        <section className="tplus-form-container">
          <form onSubmit={formik.handleSubmit}>
            {(heading || bodyCopy) && (
              <div className="tplus-form-container__heading__container">
                {heading && (
                  <h2 className="tplus-form-container__heading">{heading}</h2>
                )}
                {bodyCopy && (
                  <p className="tplus-form-container__body-copy">{bodyCopy}</p>
                )}
              </div>
            )}
            {ableErrorSummaryArr.length > 0 && (
              <>
                <ErrorSummary errors={ableErrorSummaryArr} />
                <Spacing bottom="spacing4x" />
              </>
            )}
            <Grid className="row tplus-form-container__fields">
              {fields.map((child, i) => {
                if (child) {
                  return React.cloneElement(child, { ...formik, key: i });
                }
              })}
            </Grid>
            {ctaText && (
              <div>
                <button
                  type="submit"
                  className="tplus-form-container__submit button-high-emphasis"
                >
                  {ctaText}
                </button>
              </div>
            )}
          </form>
        </section>
      )}
    </>
  );
};

export default FormContainer;
