// Action Types, Describe what is happening
import * as actions from 'constants/actions';

const initialState = {
  initialised: false,
  authenticated: false,
  authPreFlight: false,
  originUrl: { pathname: '', search: '' },
  welcomeState: false,
  flow: null,
  aemPlaceholder: false,
  livechat: false,
  loaFlowStatus: { status: null, opts: {} },
  routesReady: false,
  device: null,
  fullLocationPath: '',
  getTogglesApiResponse:[],
  getTogglesFailed:false,
  getTogglesPending:false,
  getTogglesSuccess:false,
  getTogglesError:{}
};

export default function appState(state = initialState, action) {
  switch (action.type) {
    case actions.SET_INITIALISED:
      return {
        ...state,
        initialised: action.initialised
      };
    case actions.SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.authenticated
      };
    case actions.SET_AUTH_PREFLIGHT:
      return {
        ...state,
        authPreFlight: action.authPreFlight
      };
    case actions.SET_ORIGINURL:
      return {
        ...state,
        originUrl: action.originUrl
      };
    case actions.SET_WELCOME:
      return {
        ...state,
        welcomeState: action.welcomeState
      };
    case actions.SET_FLOW:
      return {
        ...state,
        flow: action.payload
      };
    case actions.SET_AEM_PLACEHOLDER:
      return {
        ...state,
        aemPlaceholder: action.payload
      };
    case actions.SET_LIVECHAT:
      return {
        ...state,
        livechat: action.payload
      };
    case actions.ROUTES_READY:
      return {
        ...state,
        routesReady: action.payload
      };
    case actions.SET_DEVICE:
      return {
        ...state,
        device: action.payload
      };
    case actions.SET_LOAFLOWSTATUS:
      return {
        ...state,
        loaFlowStatus: { ...action.payload }
      };
    case actions.SET_FULL_LOCATION_PATH:
      return {
        ...state,
        fullLocationPath: action.locationPath
      };
    case actions.GET_TOGGLES_PENDING: {
      return {
        ...state,
        getTogglesPending: action.getTogglesPending
      };
    }
    case actions.GET_TOGGLES_SUCCESS: {
      if (typeof action.apiResponse !== 'undefined') {
        return {
          ...state,
          getTogglesPending: false,
          getTogglesFailed: false,
          getTogglesSuccess: true,
          getTogglesApiResponse: action.apiResponse
        };
      }
      return {
        ...state,
        getTogglesPending: false,
        getTogglesFailed: false,
        getTogglesSuccess: true
      };
    }
    case actions.GET_TOGGLES_FAILURE: {
      return {
        ...state,
        getTogglesPending: false,
        getTogglesFailed: true,
        getTogglesSuccess: false,
        getTogglesError: action.errorObject
      }
    }
    default:
      return state;
  }
}

export const isInAuthPreFlight = state => {
  return state.appStatus.authPreFlight;
};
