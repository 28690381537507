import React, { useState, useEffect } from 'react';

import InformationDialog from 'components/InformationDialog';
import { IconButton, InlineLink, Spacing, TextStyle } from '@able/react';

import './LaDialogContainer.scss';
import {
  LIMITED_AUTHORITY,
  TELSTRA_PLUS_RS_LA_NOTIFICATION
} from 'constants/customer';
import { useSelector } from 'react-redux';

const LaDialogContainer = () => {
  const { role, pointsValue: availablePoints } = useSelector(
    state => state.accounts
  );

  const [laDialogOpen, setLaDialogOpen] = useState(false);

  useEffect(() => {
    if (role === LIMITED_AUTHORITY) {
      if (
        !localStorage.getItem(TELSTRA_PLUS_RS_LA_NOTIFICATION) &&
        role === LIMITED_AUTHORITY &&
        availablePoints
      ) {
        setLaDialogOpen(true);
        localStorage.setItem(TELSTRA_PLUS_RS_LA_NOTIFICATION, 'true');
      }
    }
  }, [role, availablePoints]);

  return (
    <InformationDialog
      isShowing={laDialogOpen}
      setHideDialog={() => setLaDialogOpen(false)}
      confirmButtonLabel="Continue"
      className="tplus-new-features-dialog"
    >
      <div className="tplus-new-features-dialog-close-container">
        <IconButton
          className="tplus-new-feature-dialog-close-button"
          icon="Close"
          size="24"
          developmentUrl="/able-sprites.svg"
          onClick={() => setLaDialogOpen(false)}
        />
      </div>
      <div className="tplus-new-features-dialog-content">
        <Spacing
          className={['tplus-multi-cac-dialog-header']}
          bottom="spacing3x"
        >
          <TextStyle alias="HeadingB" element="h1">
            You have limited authority on this account
          </TextStyle>
        </Spacing>
        <Spacing className={['tplus-multi-cac-dialog-body']} bottom="spacing3x">
          <TextStyle alias="Base" element="p">
            Because you are not the account holder or a full authority on the
            account, you will not be able to use points to redeem rewards.
          </TextStyle>
        </Spacing>
        <Spacing>
          <InlineLink>
            <TextStyle alias="Base">
              <a
                href="https://www.telstra.com.au/support/category/account-billing/manage-account/authorisation-verification-and-access-to-telstra-accounts"
                target="_blank" rel="noreferrer"
              >
                More about permissions
              </a>
            </TextStyle>
          </InlineLink>
        </Spacing>
      </div>
    </InformationDialog>
  );
};

export default LaDialogContainer;
