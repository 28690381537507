import { aemConfig } from 'oidc/oidc-configs';
import _ from 'lodash';

export const setBaseUrl = obj => {
  const newObj = obj;
  _.forOwn(newObj, (val, key) => {
    if (typeof val === 'object') {
      setBaseUrl(val);
    }
    else if (typeof val === 'string') { 
      if (/^(\/content\/dam)/.test(val)) {
        newObj[key] = `${aemConfig.baseUrl}${val}`;
      }
    }
  });
  return newObj;
}