import React from 'react';
import { addLinkClickEvent } from 'utils/analytics';

import './RewardsTile.scss';

const RewardsTile = props => {
  const {
    id,
    rel,
    brandName,
    productName,
    details,
    pointsValue,
    ctaText,
    ctaUrl,
    ctaTarget,
    ctaTrackingCode,
    imageUrl,
    imageAlt,
    parent
  } = props;

  return (
    <div
      id={id}
      onMouseEnter={() => parent?.current?.classList.add('in-focus')}
      onMouseLeave={() => parent?.current?.classList.remove('in-focus')}
      className="plus-rewards-tile"
    >
      <div className="tile-image">
        <img src={imageUrl} alt={imageAlt || ''} loading="lazy" />
      </div>
      <a
        className="plus-rewards-tile__link"
        target={ctaTarget || '_self'}
        onClick={() => addLinkClickEvent(ctaTrackingCode)}
        rel={rel || 'noopener noreferrer'}
        href={ctaUrl}
      >
        <h3>
          <span className="plus-rewards-tile__brand">{brandName}</span>
          {productName}
        </h3>
      </a>
      <p>
        <strong>{details}</strong>
      </p>
      <p>{pointsValue}</p>
      <a
        href={ctaUrl}
        target={ctaTarget || '_self'}
        rel={rel || 'noopener noreferrer'}
        aria-label={`${ctaText}, ${productName}`}
        className="button-medium-emphasis cta"
        onClick={() => addLinkClickEvent(ctaTrackingCode)}
      >
        {ctaText}
      </a>
    </div>
  );
};

export default RewardsTile;
