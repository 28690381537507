import { SET_ERROR, RESET_ERROR } from 'constants/actions';

const errorState = {
  hasErrored: false,
  errorHeader: '',
  errorMessage: '',
  errorStatusCode: '',
  error: {},
  errorLink: ''
};

export default function error(state = errorState, action) {
  switch (action.type) {
    case SET_ERROR:
      return {
        hasErrored: action.hasErrored,
        errorHeader: action.errorHeader,
        errorMessage: action.errorMessage,
        errorStatusCode: action.errorStatusCode,
        error: action.error?.response?.data,
        errorLink: action.errorLink
      };
    case RESET_ERROR:
      return {
        hasErrored: false,
        errorHeader: '',
        errorMessage: '',
        errorStatusCode: '',
        errorLink: ''
      };
    default: {
      return state;
    }
  }
}
