import { combineReducers } from 'redux';
import _ from 'lodash';

import * as actions from 'constants/actions';

const transactions = (state = [], action) => {
  switch (action.type) {
    case actions.GET_POINTS_HISTORY_SUCCESS:
      // parse response data
      if (_.has(action.response, 'data')) {
        const { accounts } = action.response.data;
        // will need to do some fancy mutations for MCAC
        // and prettify this reducer
        return accounts[0].pointsHistory.transactionSummary;
      }

      return state;
    default:
      return state;
  }
};

const pointsExpiry = (state = {}, action) => {
  switch (action.type) {
    case actions.GET_POINTS_HISTORY_SUCCESS:
      // parse response data
      if (_.has(action.response, 'data')) {
        const { accounts } = action.response.data;
        // will need to do some fancy mutations for MCAC
        // and prettify this reducer
        const { transactionSummary } = accounts[0].pointsHistory;
        let pointExpiry = [];
        if (transactionSummary.length > 0){
          pointExpiry = transactionSummary
                  .filter(transaction => transaction.transactionType.includes('Points Expiry'));
        }
        if (pointExpiry.length > 0) return pointExpiry[0];
      }

      return state;
    default:
      return state;
  }
};

const pending = (state = false, action) => {
  switch (action.type) {
    case actions.GET_POINTS_HISTORY_PENDING:
      return true;
    case actions.GET_POINTS_HISTORY_SUCCESS:
    case actions.GET_POINTS_HISTORY_FAILURE:
      return false;
    default:
      return state;
  }
};

const failure = (state = false, action) => {
  switch (action.type) {
    case actions.GET_POINTS_HISTORY_FAILURE:
      return true;
    case actions.GET_POINTS_HISTORY_SUCCESS:
    case actions.GET_POINTS_HISTORY_PENDING:
      return false;
    default:
      return state;
  }
};

// STORING API RESPONSE IN REDUX STATE TO AVOID
// POSSIBLY MAKING MULTIPLE CALLS.
// THIS IS IN MIND OF "POSSIBLE" FUTURE USECASES
const apiResponse = (state = null, action) => {
  switch (action.type) {
    case actions.GET_POINTS_HISTORY_SUCCESS:
      // parse response data
      if (_.has(action.response, 'data')) {
        const { data } = action.response;
        return { ...data };
      }

      return state;

    default:
      return state;
  }
};

const reducers = combineReducers({
  transactions,
  pointsExpiry,
  failure,
  pending,
  apiResponse
});

export default reducers;

export const fetchPointsHistoryPending = state => state.getPointsHistoryPending;
