import axios from './util/axiosHandle';
import { partnersConfig } from '../oidc/oidc-configs';

const IN_DEVELOP_MOCKS = process.env.REACT_APP_ENV === 'dev-mocks';

const getPartnerToken = (partner, user) => {
  const userStub = user ? `/${user}` : '';
  const {
    partnerId,
    partnerName,
    offerId,
    contactId,
    accountId,
    responseFormat
  } = partner;

  const { api } = partnersConfig;

  // Add stub for different partners only in local dev
  const devPartnerCallStub = IN_DEVELOP_MOCKS ? `/${partnerName}` : '';

  return axios({
    params: {
      method: 'post',
      url: api.baseUrl + api.endpoints.token + devPartnerCallStub + userStub,
      data: {
        data: {
          partnerId,
          partnerName,
          contactId,
          offerId,
          accountId,
          responseFormat,
          createdBy: 'SPA',
          lastModifiedBy: 'SPA'
        }
      }
    }
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export default getPartnerToken;
