import React, { useEffect, useState, useRef } from 'react';
import { TextStyle, Spacing } from '@able/react';

import './RewardsVariationSelector.scss'

const RewardsVariationSelector = props => {
    const {
        showSelection = true,
        variations,
        selected = 0,
        buttonColumns = {
            viewportSm: 2,
            viewportMd: 4,
            viewportLg: 3
        },
        buttonHeight = 54,
        buttonSpacing = {
            viewportSm: 8,
            viewportMd: 16,
            viewportLg: 24
        },
        onSelect,
        spacingTop = "spacing4x",
        fillRowIfLess = {
            viewportSm: false,
            viewportMd: false,
            viewportLg: false
        },
        ariaLabel
    } = props;

    const [selectedIndex, setSelectedIndex] = useState(selected);


    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [buttonMargin, setButtonMargin] = useState(0);
    const [buttonRowDensity, setButtonRowDensity] = useState(3);
    const [specificationRowWidth, setSpecificationRowWidth] = useState(1);
    const [adjustButtonHeight, setAdjustButtonHeight] = useState(false);

    useEffect(() => {
        const handleWindowResize = () => setViewportWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    useEffect(() => {
        const setLayout = (spacing, density) => {
            setButtonMargin(spacing);
            setButtonRowDensity(density);
        }

        if (viewportWidth < 768) {
            setLayout(buttonSpacing.viewportSm, fillRowIfLess.viewportSm && variations && variations.length < buttonColumns.viewportSm ? variations.length : buttonColumns.viewportSm);
        } else if (viewportWidth < 1024) {
            setLayout(buttonSpacing.viewportMd, fillRowIfLess.viewportMd && variations && variations.length < buttonColumns.viewportMd ? variations.length : buttonColumns.viewportMd);
        } else {
            setLayout(buttonSpacing.viewportLg, fillRowIfLess.viewportLg && variations && variations.length < buttonColumns.viewportLg ? variations.length : buttonColumns.viewportLg);
        }

    }, [viewportWidth]);

    useEffect(() => {
        if (!(onSelect == null)) {
            onSelect(variations[selectedIndex]);
        }
    }, [selectedIndex])

    useEffect(() => {
        if (selectedIndex >= variations.length) {
            setSelectedIndex(variations.length - 1)
        }
        setAdjustButtonHeight(variations.some(variation => variation.value.length > 32));
    }, variations)

    // useEffect(() => {
    //     if( variations.length === 1  && (typeof variations[0].name === 'string' && variations[0].name.toLowerCase() === "memory" && viewportWidth < 768)) {
    //         setSpecificationRowWidth(3)
    //     }
    // }, [])

    const handleKeyDown = (key) => {
        switch(key.keyCode) {
            case 37:
            case 38:
                selectAndFocus((selectedIndex + (variations.length - 1)) % variations.length);
                break;
            case 39:
            case 40:
                selectAndFocus((selectedIndex + 1) % variations.length);
                break;
            default:
        } 
    }

    const selectAndFocus = (index) => {
        setSelectedIndex(index);
        document.getElementById(`tplus-rewards-variations-${variations[0].name}-${variations[index].value}`).focus();
    }

    return (
        <>  
            {showSelection && 
                <Spacing top={spacingTop}>
                    <TextStyle element="span" className='tplus-rewards-variations-title'>
                        <b>{variations[0].name}:</b> {variations[selectedIndex].value}
                    </TextStyle>
                </Spacing>
            }
            <Spacing top="spacing2x">
                <div className='tplus-rewards-variations-selector' style={{width: `calc(100% + ${buttonMargin}px)`}} role='radiogroup' aria-label={`Choose a ${ariaLabel}`}>
                    {variations && variations.sort((a,b) => isNaN(parseFloat(a.value)) ? (a.value > b.value ? 1 : -1)
                                                                : (parseFloat(a.value) > parseFloat(b.value) ? 1 : -1)).map((variation, index) => {
                        let className = 'tplus-rewards-variations-selector-button' + (index === selectedIndex ? '-selected' : '');

                        
                        return (
                            <button 
                                role='radio'
                                id={`tplus-rewards-variations-${variations[0].name}-${variation.value}`}
                                className={className}
                                style={{
                                    marginRight: `${buttonMargin}px`,
                                    marginBottom: `${buttonMargin}px`,
                                    width: `calc((${specificationRowWidth}* 100% - ${buttonMargin * buttonRowDensity}px) / ${buttonRowDensity})`,
                                    height: adjustButtonHeight ? 'auto' : `${buttonHeight}px`
                                }}
                                tabIndex={index === selectedIndex ? 0 : -1}
                                aria-checked={index === selectedIndex}
                                aria-disabled={variations.length > 1 ? false : true}
                                onClick={() => setSelectedIndex(index)}
                                onKeyDown={(key) => handleKeyDown(key)}
                                data-ws-bm-name-prop={variation.value}
                                data-ws-bm-desc-prop={""}
                                >
                                    <div className='tplus-rewards-variations'>
                                        <TextStyle element="span" alias={index === selectedIndex ? 'HeadingD' : 'Base'} className='tplus-rewards-variations-value'>
                                           {variation.value.trim().split('\n').map(line => {
                                               return (
                                                <>                                                    
                                                        {line}                                                  
                                                    <br/>
                                                </>
                                               )
                                           })}
                                        </TextStyle>
                                    </div>
                            </button>
                        )
                    })}
                </div>
            </Spacing>
        </>
    )

}


export default RewardsVariationSelector;