import React, { useRef } from 'react';
import { Cta } from 'components';
import { isMobile } from 'react-device-detect';
import Slider from 'react-slick';
import { Spacing } from '@able/react';

import './LayoutContainer.scss';

const LayoutContainer = props => {
  const {
    // eslint-disable-next-line no-unused-vars
    text, // Temp fix to get around layoutContainer corrupting
    children,
    heading = '',
    headingLevel = 'h2',
    bodyCopy = '',
    fullPageWidth = '',
    fullPageWidthTrans = '',
    mobileCarousel = false,
    ctaAction = '',
    ctaVariant = 'HighEmphasis',
    ctaText = '',
    ctaUrl = '',
    ctaTarget = '',
    ctaTrackingCode = '',
    ElementTag = 'div',
    alignment,
    spacingChildrenXs = 'spacing5x',
    spacingChildrenMd = 'spacing7x',
    paddingCTABottomMd = 'spacing10x',
    paddingCTABottomXs = 'spacing7x',
  } = props;

  const settings = {
    dots: true,
    infinite: false,
    draggable: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'minitiles',
    arrows: true
  };

  const Heading = headingLevel;

  const ref = useRef();

  const containerContent = () => {
    return (
      <div className="tplus-layout-container">
        {(heading || bodyCopy) && (
          <div
            className={`tplus-layout-container__heading__container ${alignment}`}
          >
            {heading && (
              <Heading className="tplus-layout-container__heading">
                {heading}
              </Heading>
            )}
            {bodyCopy && (
              <p className="tplus-layout-container__body-copy">{bodyCopy}</p>
            )}
          </div>
        )}
        {isMobile && mobileCarousel ? (
          <div className="tplus-layout-container__carousel">
            <Slider {...settings}>
              {children.map(child => (
                <div className="tplus-layout-container__carousel-item">
                  {child}
                </div>
              ))}
            </Slider>
          </div>
        ) : (
          <ElementTag
            className={`able-Grid tplus-layout-container__content ${alignment} children-${spacingChildrenXs} children-md-${spacingChildrenMd}`}
            ref={ref}
          >
            {children.map((child, i) => {
              let ColumnTag = 'div';
              if (ElementTag === 'ul' || ElementTag === 'ol') {
                ColumnTag = 'li';
              }
              return React.cloneElement(child, {
                parent: ref,
                key: i,
                ColumnTag
              });
            })}
          </ElementTag>
        )}
        {ctaText && (
          <Spacing bottom={paddingCTABottomXs} bottomVMD={paddingCTABottomMd} >
            <div className={`tplus-layout-container__cta-container ${alignment}`}>
              <Cta
                action={ctaAction}
                trackingCode={ctaTrackingCode}
                url={ctaUrl}
                ctaProps={{
                  variant: ctaVariant,
                  label: ctaText,
                  ...(ctaTarget && { target: ctaTarget })
                }}
              />
            </div>
          </Spacing>
        )}
      </div>
    );
  };

  return (
    <>
      {(fullPageWidth || fullPageWidthTrans) ? (
        <div className="tplus-full-width-container-main">
          <div className={`tplus-full-width-container tplus-full-width-container_${fullPageWidth}`}></div>
          <div className="tplus-full-width-container-overlay">
            <Spacing bottom="spacing7x" />
            <div className="able-Grid container ">
              <div className="able-column">{containerContent()}</div>
            </div>
          </div>
        </div>
      ) : (
        <>{containerContent()}</>
      )}
    </>
  );
};

export default LayoutContainer;
