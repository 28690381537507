import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import scrollToElement from 'scroll-to-element';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile, isDesktop } from 'react-device-detect';
import queryString from 'query-string';

// Dev Components
import ScrollToTop from 'components/ScrollToTop';

// Actions
import fetchCustomerDetails from 'actions/account';
import { fetchPoints, fetchPointsHistory } from 'actions/points';
import { fetchSmbEntryDetails } from 'actions/getSmbEntryDetails';
import fetchOrders from 'actions/orders';
import fetchOffers from 'actions/offers';
import aemActions from 'actions/aem';
import { loadContent } from 'actions/experience';

import { TICKET_LOCAL_STORAGE } from 'constants/ticket';
import * as Analytics from 'utils/analytics';
import { LOGGER } from 'utils/AppLog';
import {
  setLiveChat,
  initLiveChat,
  bindLiveChat,
  setPersistentChat
} from 'utils/liveChat';
import { handleRedirectionFlow, setFlowObject } from 'actions/flow';
import { initSiteActivity } from 'utils/siteActivity';

// App-wide Presentational Components
import { HeaderContainer } from 'containers';
import { Footer, LoadingSpinner, Usabilla } from 'components';

import { getPageConfig } from 'selectors/GetConfig';

// OIDC
import {
  autoSigninCheck,
  manager,
  signInRedirect,
  completeAuthentication,
  loadUser,
  setAuthorizationToken,
  handleCaimanError
} from 'oidc/initiateOidc';

// Constants
import * as actions from 'constants/actions';
import { REDIRECT_SAVED_LOCATION_REF } from 'constants/general';
import store from '../store';

const { REACT_APP_ENV } = process.env;
const IN_DEV_WITH_MOCKS = REACT_APP_ENV === 'dev-mocks';
const IN_CI = REACT_APP_ENV === 'ci';

// Top level container
const App = props => {
  const {
    location: { hash }
  } = window;
  const { children } = props;
  const queries = queryString.parse(window.location?.search);

  const dispatch = useDispatch();
  const history = useNavigate();
  const isFirstLoad = useRef(true);
  const location = useLocation();
  
  const [initialised, _setInitialised] = useState(false);
  const initialisedRef = useRef(initialised);
  const setInitialised = data => {
    initialisedRef.current = data;
    _setInitialised(data);
  };

  const initTplusFlow = localStorage.getItem('tplus-flow');
  const initPrevLocation = localStorage.getItem(REDIRECT_SAVED_LOCATION_REF);
  // eslint-disable-next-line no-unused-vars
  const [tplusFlow, setTplusFlow] = useState(initTplusFlow);
  // eslint-disable-next-line no-unused-vars
  const [signInRedirectionFlow, setsignInRedirectionFlow] = useState(
    initPrevLocation
  );
  const [queueVerified, setQueueVerified] = useState(false);
  const [hasQueue, setHasQueue] = useState(false);

  const accounts = useSelector(state => state.accounts);
  const isLoading = useSelector(state => state.enrolment.loading);
  const isAuthenticated = useSelector(state => state.appStatus.authenticated);
  const flow = useSelector(state => state.appStatus.flow);
  const hasCheckedEligibility = useSelector(
    state => state.eligibility.hasCheckedEligibility
  );
  const livechat = useSelector(state => state.appStatus.livechat);
  const isEligible = useSelector(state => state.eligibility.eligible);
  const isEnrolled = useSelector(state => state.enrolment.enrolled);
  const errorState = useSelector(state => state.error);
  const pageConfig = useSelector(state => getPageConfig(state));
  const routesReady = useSelector(state => state.appStatus.routesReady);
  const aemPending = useSelector(state => state.aem.pending);

  const postSigninRedirect = () => {
    const redirectSavedLoc = localStorage.getItem(REDIRECT_SAVED_LOCATION_REF);
    if (redirectSavedLoc) {
      const originUrl = JSON.parse(redirectSavedLoc);
      localStorage.removeItem(REDIRECT_SAVED_LOCATION_REF);

      const queryParams = new URLSearchParams(originUrl.search);
    
      if (queryParams.has('slg')) {
        queryParams.delete('slg');
      }
      originUrl.search = queryParams.toString();

      const redirectObject = {
        pathname: originUrl.pathname,
        search: originUrl.search,
        hash: originUrl.hash
      };

      history(redirectObject, { replace: true });
    }
  };

  const queueIsEnabled = (queueit, currentPage) => {
    if (!queueit) return;
    return Object.keys(queueit).some(key => {
      return key === currentPage;
    });
  };

  useEffect(() => {
    if (window.tplusQueueVerified) {
      setQueueVerified(true);
      if (initialisedRef.current === false) {
        verifyCaimanSession();
      }
    } else {
      window.addEventListener('queueVerified', () => {
        removeEventListener('queueVerified', window);
        setQueueVerified(true);
        if (initialisedRef.current === false) {
          verifyCaimanSession();
        }
      });
    }
  }, []);

  const handleLocationChange = () => {
    const validateQueueItUser = window.QueueIt?.validateUser();
    const queuePage = queueIsEnabled(pageConfig.queueit, location.pathname);
    if (queuePage) {
      if (validateQueueItUser) {
        validateQueueItUser();
      }
      setHasQueue(true);
    } else {
      window.tplusQueueVerified = false;
      setHasQueue(false);
      setQueueVerified(false);
    };
  };

  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
      return;
    }

    handleLocationChange();
  }, [location]);

  useEffect(() => {
    const { flow: incomingFlow } = queries;
    if (incomingFlow) {
      localStorage.setItem('tplus-flow', incomingFlow);
      delete queries.flow;
      history({
        search: queryString.stringify(queries)
      });
    }
    if(queries && isAuthenticated) {
      const queryParams = new URLSearchParams(queries);
      if (queryParams.has('slg')) {
        queryParams.delete('slg');
        history({
          search: queryString.stringify(queryParams)
        });
      }
    }
  }, []);

  // Global method for loading experience api content
  window.tplus.loadContent = (id, content, analytics) => {
    let analyticsPushed = false;

    const componentAttribute = {
      category: {
        primaryCategory: analytics?.componentType
      },
      componentInfo: {
        ...analytics,
        componentID: id
      }
    };

    const campaignDataAttribute = Analytics.getCampaignDataAttribute(
      componentAttribute
    );

    const unsubscribe = store.subscribe(() => {
      const expState = store.getState().experience;
      const loadedContent = expState[id];
      if (loadedContent) {
        if (loadedContent.rendered) {
          unsubscribe();
          if (analyticsPushed === false) {
            analyticsPushed = true;
            Analytics.addExperienceEvent(componentAttribute);
          }
        }
      }
    });

    dispatch(loadContent(id, { ...content, campaignDataAttribute }));
  };

  const jumpToHash = () => {
    // const { hash } = window.location;
    if (hash) {
      scrollToElement(hash, { offset: -180 });
    }
  };

  // Close up the loading state of the app
  const closeLoading = () => {
    return dispatch({
      type: actions.SET_LOADING,
      loading: false
    });
  };

  const showUnauth = () => {
    dispatch({
      type: actions.SET_INITIALISED,
      initialised: true
    });
    dispatch({
      type: actions.SET_LOADING,
      loading: false
    });
    closeLoading();
  };

  // Make the getdetails + subsequent api calls to get user details
  const handleGetDetails = () => {
    return dispatch(fetchCustomerDetails())
      .then(response => {
        LOGGER.debug('Get details success', response);
        dispatch(fetchPoints());
        dispatch(fetchPointsHistory());
        dispatch(fetchOffers()).then(() => {
          dispatch(fetchOrders());
        });
        // App has initialised fully
        dispatch({
          type: actions.SET_INITIALISED,
          initialised: true
        });
        dispatch({
          type: actions.SET_HASCHECKEDELIGIBILITY,
          hasCheckedEligibility: true
        });
        // Observe the user activity to logout the user if Idle timeout is reached
        initSiteActivity();
        setInitialised(true);
      })
      .catch(error => {
        LOGGER.debug('Get details error', error);
        dispatch({
          type: actions.SET_LOADING,
          loading: false
        });
      });
  };

  // eslint-disable-next-line consistent-return
  const preflightCheck = () => {
    const preventRefreshCalls = pageConfig?.caiman?.preventRefreshCalls?.activated;
    const isReload = window.performance.getEntriesByType("navigation")[0].type === 'reload';
    // Capture and params and put them into an object
    // eslint-disable-next-line no-shadow
    const { code, state, error: isAuthError, flow } = queries;
    // We are coming in with no params in header
    const firstLoad = !code && !state && !isAuthError;

    // Came back from CAIMAN or in MOCKS
    // If url has
    // code (appended from caiman),
    // AND state (appended from caiman),
    // AND saved prevLocation object (we save it to localStorage before kicking off auth redirect flow)
    // OR localstorage spaenv===mock (localstorage param we set when kicking off mocked aauth flow redirect)
    // parameters
    // then that means we are coming from CAIMAN redirect

    const isAuthPreflight = code && state && !isAuthError;

    // Handle first time loading the page
    if (firstLoad) {
      if (isReload && preventRefreshCalls) {
        showUnauth();
      }
      else {
        // if in CI add GUID to sessionstorage
        if (IN_CI) {
          localStorage.setItem('ci-params', window.location.search.substring(1));
        }
        // Before redirecting set the prevLocation object
        localStorage.setItem(
          REDIRECT_SAVED_LOCATION_REF,
          JSON.stringify(window.location)
        );

        if (flow) {
          // Capture incoming flow objects
          localStorage.setItem('tplus-flow', flow);
        }
        // Make SSO call to check and get aunthenitcation details
        const querieStrings = queryString.parse(window.location?.search);
        const { slg } = querieStrings;
        const prompt = slg === '1' ? 'login' : 'none';
        signInRedirect({ prompt }).then(
          () => {
            LOGGER.debug('Sign In redirect success');
            if (IN_DEV_WITH_MOCKS || IN_CI) {
              if (flow) {
                // dispatch the flow right away as we don't need to bounce out to sso
                dispatch(setFlowObject(flow));
              }
              // Can simulate different flows coming back from CAIMAN here
              // Come back with login error - go to unauth
              // window.location.search += '&error=login_required';
              // Come back with authenticated session - load auth flow
              window.location.search += '&code=iamthecode&state=iamthestate';
            }
          },
          () => {
            LOGGER.debug('Sign In redirect failure');
          }
        );
      }
    }

    // Handle - Weve come back with a specific CAIMAN error in the url
    if (isAuthError) {
      // Handle the CAIMAN error in a promise in case we want some flow blocking (error thrown) action
      handleCaimanError(isAuthError).then(result => {
        // CAIMAN errors handled with no flow stoppage needed, continue on with auth redirect kick off if needed
        if (IN_DEV_WITH_MOCKS) {
          // Kick off all our authenticated status and getdetails in mocks

          signInRedirect().then(() => {
            // Set redux authenticated flag
            dispatch({
              type: actions.SET_AUTHENTICATED,
              authenticated: true
            });
            // Kick off getdetails etc
            handleGetDetails();
          });
        }
        // Catch any non error throwing CAIMAN errors here
        if (result === 'access_denied_caught') {
          const caimanLoaFlow = { type: 'loa', status: 'denied' };
          signInRedirect({ caiman: caimanLoaFlow });
        }
        if (result === 'continue_to_unauth') {
          // Continue on to unauth state
          // In any other case close up loading and show unauth
          showUnauth();
          postSigninRedirect();
        }
      });
    }

    // Handle coming back from CAIMAN successfully
    if (isAuthPreflight) {
      // Get rid of mock storage value
      if (IN_DEV_WITH_MOCKS) {
        localStorage.removeItem('spaenv');
      }
      // SSO OIDC event - when a user / session is authenticated and loaded then we fire this off
      return completeAuthentication()
        .then(passedUser => {
          // AUthenticated user has been loaded by OIDC
          // Pass in this found user back into our initiateOidc user instance,
          // that is the user we get access_token from to make api calls
          // only catch this for sso flows
          // Above event will fire for normal auth and silent sso as well
          setAuthorizationToken(passedUser);
          // Set redux authenticated flag
          dispatch({
            type: actions.SET_AUTHENTICATED,
            authenticated: true
          });
          // Kick off getdetails etc
          handleGetDetails();
        })
        .catch(err => {
          // No state in response will occur on local dev. That's why we need to check for dev first.
          if (err.message !== 'login_required') {
            closeLoading();
            dispatch({
              type: actions.SET_ERROR,
              hasErrored: true,
              error: err
            });

            return history('/error', { replace: true });
          }
          return null;
        });
    }
  };

  const verifyCaimanSession = () => {
    loadUser().then((user) => {
      if (user && !user.expired) {
        setAuthorizationToken(user);
        // Set redux authenticated flag
        dispatch({
          type: actions.SET_AUTHENTICATED,
          authenticated: true
        });
        // Kick off getdetails etc
        handleGetDetails();
      }
      else {
        preflightCheck();
      }
    })
  }

  useEffect(() => {
    // Check and dispatch if there are any tplus or partner flows - put them in redux in case we need them later
    // If we have some kind of tplus flow object set
    if (tplusFlow) {
      dispatch(setFlowObject(tplusFlow));
    }

    let setDevice = 'other';
    if (isMobile) {
      setDevice = 'mobile';
    }
    if (isDesktop) {
      setDevice = 'desktop';
    }

    // init analytics
    Analytics.initAnalytics(setDevice);
    // Set device for analytics

    dispatch({ type: actions.SET_LOADING, loading: true });

    // Get AEM data(s)
    dispatch(aemActions.getAemAll());
  }, []);

  useEffect(() => {
    const { queueit, caiman } = pageConfig;
    const pageHasQueue = queueIsEnabled(queueit, location?.pathname);
    // Determine if coming from caiman
    // Check first if window has search attribute and we havent authenticated in SPA/redux
    if (!aemPending) {
      if (caiman?.isDisabled?.activated) {
        showUnauth();
      } else if (!pageHasQueue) {
        verifyCaimanSession();
      }
    }
  }, [aemPending]);

  // Run these when location changes
  useEffect(() => {
    setTimeout(() => {
      jumpToHash();
    }, 500);

    if (isAuthenticated && pageConfig?.livechat?.tier?.activated) {
      setPersistentChat();
    }
  }, [location]);

  // Tickets redirect flow here, checking if we have authenticated and running tickets redirects
  useEffect(() => {
    if (isAuthenticated && isEnrolled && flow && flow.action) {
      dispatch(handleRedirectionFlow(flow));
    }
  }, [isAuthenticated, isEnrolled, flow]);

  // Init stuff here on app load and authenticated and enrolled
  // - livechat
  useEffect(() => {
    // Need to have this initialised within SPA, i.e. when it loads
    // adding a timeout so it's not immediately triggered when loading the SPA
    // i.e. before authentication redirect

    if (isAuthenticated) {
      setTimeout(() => {
        initLiveChat();
      }, 15000);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && livechat && pageConfig?.livechat?.tier?.activated) {
      // Everytime user tier changes rebind the live chat function
      bindLiveChat();
    }
    if (accounts.loyaltyTier === 'BUSINESS') {
      dispatch(fetchSmbEntryDetails());
    }
  }, [accounts.loyaltyTier]);

  // Update livechat everytime tier changes
  useEffect(() => {
    if (isAuthenticated && livechat && pageConfig?.livechat?.tier?.activated) {
      setLiveChat(accounts.loyaltyTier);
    }
  }, [isAuthenticated, accounts.loyaltyTier, livechat]);

  // We've come back from either CAIMAN flow here, hasCheckedEligibility has been changed
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const { isHeritageUser } = accounts;
    const { hasErrored } = errorState;
    // Do any user data based redirects here
    if (hasCheckedEligibility) {
      if (isHeritageUser) {
        closeLoading();
        return history('/explainer', { replace: true });
      }
      if (hasErrored) {
        closeLoading();
        return history('/error', { replace: true });
      }
      if (!isEligible) {
        closeLoading();
        return history('/not-eligible', { replace: true });
      }
      if (!isEnrolled) {
        if (flow) {
          // If flow.flow doesnt exist or it does exist but is equal to TICKET_LOCAL_STORAGE, then...
          if (!flow.flow || flow.flow === TICKET_LOCAL_STORAGE) {
            dispatch({ type: actions.SET_FLOW, payload: null });
          }
        }
        closeLoading();
        return history('/join', { replace: true });
      }
      // If coming back from CAIMAIN redirect flow AND we have run through all our eligibilitychecks
      // then run our previous location redirects here
      if (!tplusFlow) {
        closeLoading();
        postSigninRedirect();
      }
    }
  }, [
    accounts,
    hasCheckedEligibility,
    errorState,
    history,
    isEligible,
    isEnrolled,
    tplusFlow,
    signInRedirectionFlow,
    routesReady
  ]);


  // SSO OIDC event - when session expires signout the user
  manager.events.addAccessTokenExpiring(() => {
    console.log('session is about to expire');
  });

  manager.events.addUserLoaded((user) => {
    setAuthorizationToken(user);
  });

  const renderLoader = () => {
    return <LoadingSpinner />;
  };

  const renderApp = () => {
    return (
      <div className={isLoading ? 'd-none' : ''}>
        <HeaderContainer />
        <div role="main">
          <ScrollToTop>{children}</ScrollToTop>
          <Usabilla />
        </div>
        <Footer />
      </div>
    );
  };

  const render = () => {
    if (isLoading) {
      return renderLoader();
    }
    if (hasQueue) {
      if (queueVerified) {
        return renderApp();
      }
      return renderLoader();
    }
    return renderApp();
  }

  return (
    <>
     {render()}
    </>
  );
};

export default App;

App.propTypes = {
  children: PropTypes.node.isRequired
};
