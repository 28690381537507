/* eslint-disable react/no-unknown-property */
import React from 'react';
import { checkExternalLink } from 'utils';
import InteractiveElement from '_partials/InteractiveElement';
import { addLinkClickEvent } from 'utils/analytics';
import { TextStyle, Spacing, Surface } from '@able/react';

import './NavigationBox.scss';

const NavigationBox = props => {
  const {
    id = '',
    imageSource = '',
    mobileImageSource = '',
    label = '',
    headingLevel = 'h2',
    description = '',
    screenReaderOnly = false,
    cornerRadius = 'cornerRadiusNone',
    url = '',
    ctaTrackingCode = ''
  } = props;

  const urlProps = checkExternalLink(url);

  return (
    <>
      <div className="tplus-navigation-box" id={id || null}>
        <Surface
          className="tplus-navigation-box__able-container tplus-navigation-box--no-margin"
          variant="SurfaceMid"
          cornerRadius={cornerRadius}
        >
          <InteractiveElement
            className="tplus-navigation-box__link"
            {...urlProps}
            onClick={() => addLinkClickEvent(ctaTrackingCode)}
          >
            <div className="tplus-navigation-box__image-container">
              <picture focusable="false">
                <source
                  media="(max-width:767px)"
                  srcSet={mobileImageSource || imageSource}
                />
                <source media="(min-width:768px)" srcSet={imageSource} />
                <img
                  className="tplus-navigation-box__image"
                  src={imageSource}
                  alt=""
                />
              </picture>
            </div>
            <div className="tplus-navigation-box__content">
              <TextStyle
                element={description ? headingLevel : 'p'}
                alias="HeadingD"
                className="tplus-navigation-box__heading"
              >
                {label}
              </TextStyle>
              {description && (
                <>
                  <Spacing bottom="spacing1x" />
                  <TextStyle
                    element="div"
                    alias="Base"
                    className={`tplus-navigation-box__description ${
                      screenReaderOnly !== 'false'
                        ? 'tplus-navigation-box__description--screen-reader'
                        : ''
                    } `}
                  >
                    {description}
                  </TextStyle>
                </>
              )}
            </div>
          </InteractiveElement>
        </Surface>
      </div>
    </>
  );
};

export default NavigationBox;
