import {
  GET_REWARD_PRODUCT_STOCKS_PENDING,
  GET_REWARD_PRODUCT_STOCKS_SUCCESS,
  GET_REWARD_PRODUCT_STOCKS_FAILURE,
} from 'constants/actions';

const defaultState = {
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  stocks: [],
  error: null,
};

export const productStocks = (state = defaultState, action) => {
  switch (action.type) {
    case GET_REWARD_PRODUCT_STOCKS_PENDING: {
      return {
        ...state,
        status: 'loading'
      };
    }
    case GET_REWARD_PRODUCT_STOCKS_SUCCESS: {
      const {
        payload: { data : {stocks} }
      } = action;
      return {
        ...state,
        status: 'succeeded',
        stocks
      };
    }
    case GET_REWARD_PRODUCT_STOCKS_FAILURE: {
      return {
        ...state,
        status: 'failed',
        error: action.payload
      };
    }
    default:
      return state;
  }
};
