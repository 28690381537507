/* eslint-disable no-unneeded-ternary */
import { createSelector } from 'reselect';
import _ from 'lodash';

// get aem promos
const getConfig = state => state.aem.all.global.config;

// grab and sort the big promos
export const getPageConfig = createSelector([getConfig], pageConfigs => {
  const sortedConfigs = _.filter(pageConfigs, {
    key: 'toggleconfig',
    isParentActivated: 'true'
  });

  const normalizedConfigs = _.map(sortedConfigs, config => {
    let newConfig = {
      name: config.parentattribute,
      activated: config.isParentActivated === 'true' ? true : false
    };

    if (config.configattributes) {
      const newConfigChildren = _.map(config.configattributes, configChild => {
        return {
          name: configChild.childattribute,
          activated: configChild.isChildActivated === 'true' ? true : false
        };
      });

      const keyedConfigChildren = _.keyBy(newConfigChildren, 'name');

      newConfig = { ...newConfig, ...keyedConfigChildren };
    }

    return newConfig;
  });

  const keyedNormalizedConfigs = _.keyBy(normalizedConfigs, 'name');

  //   Example of what we should be sending in
  // {
  //   "contactcapture": {
  //     "name": "contactcapture",
  //     "activated": true,
  //     "email": {
  //       "name": "email",
  //       "activated": true
  //     },
  //     "mobile": {
  //       "name": "mobile",
  //       "activated": true
  //     }
  //   },
  //   "otherthing": {
  //     "name": "otherthing",
  //     "activated": true
  //   }
  // }

  return keyedNormalizedConfigs;
});
