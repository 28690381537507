/* eslint-disable */
import _ from 'lodash';

const SetEventCallback = () => {
  if (_.has(window, 'usabilla_live')) {
    window.usabilla_live('setEventCallback', function(
      category,
      action,
      label,
      value,
      eventdata
    ) {
      var type = null;
  
      // Switch for the different types of events that can occur
      switch (action) {
        // This event is raised when a user starts the feedback process, this can be by clicking the feedback button or via a Boost campaign.
        case 'Feedback:Open':
          type = 'feedback';
          break;
  
        // This event is raised when a user has successfully completed the feedback process.
        case 'Feedback:Success':
          type = 'feedback';
          break;
  
        // Event of the Campaign form that shows up
        case 'Campaign:Open':
          type = 'campaign';
          break;
  
        // This event is raised whenever a user actively closes a campaign by either clicking on the close button or the cancel link.
        case 'Campaign:Close':
          type = 'campaign';
          break;
  
        // This event is raised in addition to the Campaign Success event when a Boost campaign is successful.
        case 'Campaign:Feedback Clicked':
          type = 'campaignClose';
          break;
  
        // This event is raised whenever a campaign is successful:
        // - gives feedback while a boost campaign is active
        // - clicks the recruit button for a recruit campaign
        // - finishes a slide-out or full survey campaign
        case 'Campaign:Success':
          type = 'campaign';
          break;
  
        // This event is raised whenever a user clicks the submit button for a multi-page campaign (slideout and full survey campaigns).
        case 'Campaign:Page switch':
          type = 'campaign';
          break;
      }
  
      if (action != 'Feedback:Open') {
        return;
      }
      /**
       * Function for masking any privacy sensitive information before sending it to the server.
       * @param {*} vData
       */
      function sendReplacement(vData) {
        var data = JSON.parse(vData);
        // Concat Regex for IBAN, Dutch Phone, Dutch Cellphone, Dutch Postal Code, Email Address
        var regexConcat = /(\S+@\S+\.\S+)|(\d{3,})/gm;
        data.comment = data.comment.replace(regexConcat, '.......');
        data.url = data.url.replace(window.location.search, '');
        vData = JSON.stringify(data);
        realSend.apply(this, arguments);
      }
  
      /**
       * Send replacement request to Usabilla that contains the masked feedback.
       */
      var realSend = XMLHttpRequest.prototype.send;
      var ub_window = document.getElementById(
        'lightningjs-frame-usabilla_live_feedback'
      ).contentWindow;
      ub_window.XMLHttpRequest.prototype.send = sendReplacement;
      if (window.XDomainRequest) {
        realSend = window.XDomainRequest.prototype.send;
        ub_window.XDomainRequest.prototype.send = sendReplacement;
      }
    });
  }
}

export default SetEventCallback;
