import axios from './util/axiosHandle';
import { apiConfig as api } from '../oidc/oidc-configs';

const activateSmbApi = (smbActivation, user) => {
  const userStub = user ? `/${user}` : '';
  return axios({
    params: {
      method: 'post',
      url: api.baseUrl + api.endpoints.activateSmbPromo + userStub,
      data: {
        data: {
          cac: smbActivation
        }
      }
    }
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export default activateSmbApi;
