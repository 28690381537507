import React, { useEffect, useState } from 'react';
import {
  Picto,
  TelstraPlus56,
  Deal56,
  Cart56,
  Membership56,
  Ticket56,
  TextStyle
} from '@able/react';

import './SectionNavigation.scss';

const SectionNavigation = props => {
  const { sectionNavigationItems } = props;

  const [pictoLength, setPictoLength] = useState(1);

  useEffect(() => {
    if (sectionNavigationItems)
      setPictoLength(Object.values(sectionNavigationItems).length);
  }, [pictoLength]);

  const getPictoGram = pictoName => {
    switch (pictoName) {
      case 'TelstraPlus56':
        return <TelstraPlus56 />;
      case 'Deal56':
        return <Deal56 />;
      case 'Cart56':
        return <Cart56 />;
      case 'Membership56':
        return <Membership56 />;
      case 'Ticket56':
        return <Ticket56 />;
      default:
        return <TelstraPlus56 />;
    }
  };

  const Hyperlink = section => {
    return (
      <>
        <li className="tcom-section-navigation__content__item">
          <div className="tcom-section-navigation__content__able-container">
            <a
              href={section.section.sectionUrl}
              className="tcom-section-navigation__content__link"
            >
              <div className="tcom-section-navigation__content__container">
                {section.section.pictogramName && (
                  <div className="content-media-pictogram">
                    <Picto theme={section.section.pictogramColour}>
                      {getPictoGram(section.section.pictogramName)}
                    </Picto>
                  </div>
                )}
                <TextStyle
                  element="p"
                  alias="Base"
                  class="tcom-section-navigation__content__text"
                >
                  {section.section.sectionText}
                </TextStyle>
              </div>
            </a>
          </div>
        </li>
      </>
    );
  };

  return (
    <>
      <div data-layout="" className="tcom-layout-container padding-lg container ">
        <div className="tcom-section-navigation-container">
          <nav
            aria-label="Section Navigation"
            className="tcom-section-navigation__nav"
          >
            <ul
              className={`tcom-section-navigation__content tcom-section-navigation__content--${pictoLength}`}
            >
              {Object.values(sectionNavigationItems).map((section, key) => (
                <Hyperlink section={section} key={key}></Hyperlink>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default SectionNavigation;
