import React from 'react';
import PropTypes from 'prop-types';

const SuccessView = props => {
  const { toggleView } = props;

  return (
    <>
          <div>
         <TextStyle element="h2" alias="HeadingB">
         Success
          </TextStyle>
          </div>
      </>
  );
};

export default SuccessView;

SuccessView.propTypes = {
  toggleView: PropTypes.func.isRequired
};
