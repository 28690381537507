import React from 'react';

export default function IconUpArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="11"
      viewBox="0 0 24 24"
      transform="rotate(270)"
      fill="currentColor"
      id="icon-arrow"
    >
      <path d="M20.8 9.9L11 0 8.8 2.1l8.4 8.4H1v3h16.2l-8.4 8.4L11 24l9.8-9.9L23 12z"></path>
    </svg>
  );
}
