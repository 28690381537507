// Action Types, Describe what is happening
import _ from 'lodash';

import {
  SWITCH_ACCOUNT,
  SET_INITIAL_ACCOUNT,
  SET_INITIAL_POINTS,
  SET_ACCOUNT_MOBILE,
  SET_ACCOUNT_MASKED_MOBILE,
  SET_ACCOUNT_EMAIL,
  SET_ACCOUNT_MASKED_EMAIL,
  SET_ACCOUNTS_LIST,
  MERGE_ACCOUNTS_LIST,
  GET_DETAILS_HERITAGE,
  RESET_ACCOUNTS
} from 'constants/actions';

const activeAccountState = {
  membershipIndex: 1,
  pointsValue: '',
  onHoldPoints: '',
  sortedAccounts: [{}],
  cacList: '',
  loyaltyTier: 'NONE',
  isHeritageUser: false,
  mobile: '',
  email: ''
};

export default function account(state = activeAccountState, action) {
  let accountsWithPoints;
  let filteredAccountsWithPoints;
  switch (action.type) {
    case SWITCH_ACCOUNT:
      return {
        ...state,
        ...action.cac,
        membershipIndex: action.membershipIndex,
        pointsValue: action.pointsValue,
        onHoldPoints: action.onHoldPoints
      };
    case SET_INITIAL_ACCOUNT:
      return {
        ...state,
        ...action.cac,
        membershipIndex: action.membershipIndex
      };
    case SET_INITIAL_POINTS:
      return {
        ...state,
        pointsValue: action.pointsValue,
        onHoldPoints: action.onHoldPoints
      };
    case SET_ACCOUNT_MOBILE:
      return {
        ...state,
        mobile: action.mobile
      };
    case SET_ACCOUNT_MASKED_MOBILE:
      return {
        ...state,
        maskedMobile: action.maskedMobile
      };
    case SET_ACCOUNT_EMAIL:
      return {
        ...state,
        email: action.email
      };
    case SET_ACCOUNT_MASKED_EMAIL:
      return {
        ...state,
        maskedEmail: action.maskedEmail
      };
    case SET_ACCOUNTS_LIST:
      return {
        ...state,
        cacList: action.cacList,
        sortedAccounts: action.sortedAccounts
      };
    case MERGE_ACCOUNTS_LIST:
      accountsWithPoints = _.merge(
        [],
        state.sortedAccounts,
        action.sortedPointsAccounts
      );

      filteredAccountsWithPoints = _.filter(accountsWithPoints, {
        enrollmentStatus: 'ENROLLED'
      });

      return {
        ...state,
        sortedAccounts: filteredAccountsWithPoints
      };
    case GET_DETAILS_HERITAGE:
      return {
        ...state,
        isHeritageUser: action.isHeritageUser
      };
    case RESET_ACCOUNTS: {
      return activeAccountState;
    }
    default:
      return state;
  }
}

export const getCurrentCac = state => {
  return state.accounts.accountId;
};

export const getCacList = state => {
  return state.accounts.cacList;
};

export const getOriginUrl = state => {
  return state.appStatus.originUrl;
};
