import React, { useState, cloneElement } from 'react';

import './accordionGroup.scss';

const AccordionGroup = ({
  children,
  id = '',
  stayOpen = true,
  className = ''
}) => {
  const [opened, setOpened] = useState('');

  const handleChange = (e, item) => {
    const openedItem = e.isOpen ? item : '';
    setOpened(openedItem);
  };

  const cloneChild = (child, key) => {
    return (
      child &&
      cloneElement(child, {
        onChange: e => handleChange(e, key),
        closeItem: !stayOpen && opened !== key
      })
    );
  };

  return (
    <section className={className}>
      <div className="tplus-accordion-group" id={id}>
        <div className="tplus-accordion-group__group">
          {children instanceof Array
            ? children.map((child, key) => {
                if (child instanceof Array) {
                  return child.map(nested => <>{cloneChild(nested, key)}</>);
                }
                return <>{cloneChild(child, key)}</>;
              })
            : cloneChild(children, 1)}
        </div>
      </div>
    </section>
  );
};

export default AccordionGroup;
