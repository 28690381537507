import React, { useRef, useEffect } from 'react';

/**
 * Wrapper for capturing key down functionality.
 * Executes prop `escapePress` function when Escape key is pressed.
 * Executes prop `enterPress` function when Enter key is pressed.
 * @param props
 * @since 1.0.0
 */
function KeyDown(props) {
  const { escapePress, enterPress, children, ...other } = props;
  const container = useRef(null);

  const keydownHandler = (event) => {
    const { key } = event;
    if (key === 'Escape' && escapePress) {
      escapePress();
    }
    if (key === 'Enter' && enterPress) {
      event.preventDefault();
      enterPress();
    }
  };

  useEffect(() => {
    const currentContainer = container.current || undefined;
    currentContainer?.addEventListener('keydown', keydownHandler);
    return () => {
      currentContainer?.removeEventListener('keydown', keydownHandler);
    };
  });

  return (
    <div className='able-KeyDown' tabIndex={-1} ref={container} {...other}>
      {children}
    </div>
  );
}
export default KeyDown;
