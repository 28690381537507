/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import './Checkbox.scss';
// replace with htmr/convert
import { HtmlConverter } from 'utils/HtmlConverter';

export default class Checkbox extends React.Component {
  render() {
    const {
      id,
      type,
      hasError,
      handleChange,
      label,
      checked,
      ...inputProps
    } = this.props;

    let checkboxClassname = 'cus-checkbox';

    if (type === 'tick') checkboxClassname = 'cus-checkboxtick ';

    checkboxClassname += `
        ${type === 'tick' && 'cus-checkboxtick'}
        ${type === 'switch' && 'cus-checkboxswitch'}
        ${hasError && 'cus-checkboxhas-error'}
      `;

    const inputClassname = `
        cus-checkboxinput
        ${type === 'tick' && 'cus-checkboxtick'}
        ${type === 'switch' && 'cus-checkboxswitchinput'}
        ${hasError && 'cus-checkboxhas-errorinput'}
      `;

    return (
      <div className={checkboxClassname}>
        <input
          type="checkbox"
          className={inputClassname}
          checked={checked}
          id={id}
          {...inputProps}
          onChange={e => {
            if (handleChange)
              handleChange({
                // name: e.target.name,
                checked: e.target.checked
              });
          }}
        />
        {label && <label htmlFor={id}>{HtmlConverter(label)}</label>}
      </div>
    );
  }
}

Checkbox.propTypes = {
  hasError: PropTypes.bool,
  id: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  type: PropTypes.oneOf(['tick', 'switch']),
  label: PropTypes.string
};

Checkbox.defaultProps = {
  hasError: false,
  type: 'tick',
  label: null
};
