import React from 'react';
import { useTabState, usePanelState } from './Tabs';

const Tab = ({ className, children }) => {
  const { isActive, onClick } = useTabState();

  return (
    <button
      type="button"
      className={`${className} ${isActive ? 'active' : ''}`}
      onClick={onClick}
      aria-selected={isActive}
      role="tab"
    >
      {children}
    </button>
  );
};

const Panel = ({ children }) => {
  const isActive = usePanelState();

  return isActive ? <div>{children}</div> : null;
};

export { Tabs } from './Tabs';
export { Tab, Panel };
