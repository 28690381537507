import React from 'react';
import { HtmlConverter } from 'utils/HtmlConverter';
import { Cta } from 'components';
import { Spacing, TextStyle } from '@able/react';

// Icons
import IconNumberOne from 'icons/IconNumberOne';
import IconNumberTwo from 'icons/IconNumberTwo';
import IconNumberThree from 'icons/IconNumberThree';
import IconNumberFour from 'icons/IconNumberFour';

// CSS
import './InstructionalBox.scss';

const InstructionalBox = props => {
  const {
    active,
    heading = '',
    id,
    step1Title,
    step2Title,
    step3Title,
    step4Title,
    step1Description,
    step2Description,
    step3Description,
    step4Description,
    primaryCtaAction,
    primaryCtaText,
    primaryCtaUrl,
    primaryCtaVariant = 'LowEmphasis',
    primaryCtaTarget,
    primaryCtaTrackingCode,
    secondaryCtaText,
    secondaryCtaUrl,
    disclaimerText
  } = props;

  const getTileAmount = () => {
    if (step1Title && step2Title) {
      if (step3Title) {
        if (step4Title) {
          return 'four-tiles';
        }
        return 'three-tiles';
      }
      return 'two-tiles';
    }
    return '';
  };

  const getNumberIcon = count => {
    switch (count) {
      case 1:
        return <IconNumberOne></IconNumberOne>;
      case 2:
        return <IconNumberTwo></IconNumberTwo>;
      case 3:
        return <IconNumberThree></IconNumberThree>;
      case 4:
        return <IconNumberFour></IconNumberFour>;
      default:
        break;
    }
    return null;
  };

  const Tile = ({ title, description, count }) => {
    return (
      <li className="instructional-tile">
        <div className="stepper-container">
          {getNumberIcon(count)}
          <div className="stepper-t-line">
            <span className="t-line"></span>
          </div>
        </div>
        <div className="tile-content">
          <h3 className="tile-headline">
            <span className="sr-only">{`Step ${count}`}</span>
            {title}
          </h3>
          <p className="tile-body">{HtmlConverter(description)}</p>
        </div>
      </li>
    );
  };

  return (
    <>
      {active && (
        <section className="tplus-instructional-box" id={id}>
          {heading && (
            <>
              <TextStyle alias="HeadingB" element="h2">
                {heading}
              </TextStyle>
              <Spacing bottom="spacing3x" />
            </>
          )}
          <ol className={`instructional-box-container  yolo ${getTileAmount()}`}>
            {step1Title && (
              <Tile
                title={step1Title}
                description={step1Description}
                count={1}
              ></Tile>
            )}
            {step1Title && step2Title && (
              <Tile
                title={step2Title}
                description={step2Description}
                count={2}
              ></Tile>
            )}
            {step1Title && step2Title && step3Title && (
              <Tile
                title={step3Title}
                description={step3Description}
                count={3}
              ></Tile>
            )}
            {step1Title && step2Title && step3Title && step4Title && (
              <Tile
                title={step4Title}
                description={step4Description}
                count={4}
              ></Tile>
            )}
          </ol>
          {secondaryCtaText && (
            <div className="secondary-cta">
              <a href={secondaryCtaUrl}>{secondaryCtaText}</a>
            </div>
          )}
          {primaryCtaText && (
            <>
              <Spacing bottom="spacing4x" />
              <Cta
                action={primaryCtaAction}
                trackingCode={primaryCtaTrackingCode}
                url={primaryCtaUrl}
                ctaProps={{
                  variant: primaryCtaVariant,
                  label: primaryCtaText,
                  ...(primaryCtaTarget && { target: primaryCtaTarget })
                }}
              />
            </>
          )}
          {disclaimerText && (
            <p className="tplus-instructional-box__terms">
              {HtmlConverter(disclaimerText)}
            </p>
          )}
        </section>
      )}
    </>
  );
};

export default InstructionalBox;
