import React, { Component } from 'react';
import PropTypes from 'prop-types';

import IconMinus from 'icons/IconMinus';

import TabContent from './TabContent';
import './VerticalTabs.scss';

export default class Tab extends Component {
  constructor(props) {
    super(props);

    this.titleRef = React.createRef();
    this.state = {
      checked: !!props.expanded,
      contentClasses: props.expanded
        ? 'vertical-tab-active'
        : 'vertical-tab-hidden'
    };
  }

  componentDidMount() {
    this.openAndScrollIfHash();
  }

  componentWillUnmount() {
    clearTimeout(this.scrollTimer);
  }

  openAndScrollIfHash = () => {
    const { id } = this.props;
    const { hash } = window.location;

    if (id && hash && hash === `#${id}`) {
      this.scrollTimer = setTimeout(() => {
        this.toggleTab();
      }, 200);
    }
  };

  toggleTab = () => {
    const { checked } = this.state;
    if (checked) {
      this.titleRef.current.setAttribute('aria-expanded', false);
      this.setState({
        checked: false,
        contentClasses: ''
      });

      setTimeout(() => {
        this.setState({
          contentClasses: 'vertical-tab-hidden'
        });
      }, 500);
    } else {
      // TODO
      // get height of content element
      // .vertical-tab-content
      this.titleRef.current.setAttribute('aria-expanded', true);
      this.setState({
        checked: true,
        contentClasses: 'vertical-tab-active'
      });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  getContentSectionId = id => `${id}-vertical-content-id`;

  render() {
    const {
      title,
      description,
      internallink,
      internallinktext,
      id
    } = this.props;

    const { contentClasses, checked } = this.state;

    return (
      <li className="vertical-tab-section">
        <div className="vertical-content">
          <div className={`rewards-vertical-tab-accordion ${contentClasses}`}>
            <h3 className="tab-title-container">
              <button
                name={id}
                type="button"
                aria-expanded={checked}
                aria-controls={this.getContentSectionId(id)}
                ref={this.titleRef}
                id={id}
                onClick={this.toggleTab}
                className="vertical-tab-title"
              >
                {title}

                <span
                  className="accordion-open-icon tcom-icon-12"
                  aria-hidden="true"
                  role="presentation"
                >
                  <IconMinus />
                </span>
                <span
                  className="accordion-close-icon tcom-icon-12"
                  aria-hidden="true"
                  role="presentation"
                >
                  <IconMinus />
                </span>
              </button>
            </h3>

            <TabContent
              content={description}
              internallink={internallink}
              internallinktext={internallinktext}
              refererId={id}
              id={this.getContentSectionId(id)}
              open={checked}
            />
          </div>
        </div>
      </li>
    );
  }
}

Tab.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  internallink: PropTypes.string,
  internallinktext: PropTypes.string,
  id: PropTypes.string
};

Tab.defaultProps = {
  id: '',
  internallink: '',
  internallinktext: ''
};
