const { REACT_APP_PLUS_HOME_URL } = process.env;

export const DEFAULTMESSAGE = `We're working on the problem, please try again later.`;
export const ALREADYENROLLED = `You are already a Telstra Plus member.`;
export const REGISTRATIONERROR = `We weren't able to complete your registration. We're working on the problem, please try again later.`;

export const POINTLESSHEADING = 'You have no points at the moment';
export const POINTLESSCOPY =
  'You will earn 10 points per dollar on your next eligible bill payment or recharge. Points can take up to 7 business days to appear.';
export const POINTSERRORHEADING = 'Something went wrong';
export const POINTSERRORCOPY =
  "We aren't able to load your points history, please refresh or try again";

// Benefits Offers/Orders Error Messages

// @TODO Change URL property to be something specific

export const BENEFITSFAILED = {
  errorText:
    'We were unable to get the status of your benefit. Please reload the page.',
  buttonText: 'Reload page',
  showRefreshButton: false,
  url: null
};

export const GENERICERROR = {
  errorText: 'Something went wrong. Please try again later.',
  buttonText: 'Reload page',
  showRefreshButton: true,
  url: null
};

export const ALREADYACTIVATED = {
  errorText: 'Looks like you may have already activated this benefit.',
  buttonText: 'Learn more',
  url: `${REACT_APP_PLUS_HOME_URL}/frequently-asked-questions`
};

export const ADDSERVICE = {
  errorText: 'You need to add a service to your Telstra ID.',
  buttonText: 'Manage your Telstra ID',
  url: `https://www.my.telstra.com.au/myaccount/home`
};

export const PRIVATEBROWSING = {
  errorText: 'Turn off private browsing mode and try again.',
  buttonText: 'Learn more',
  url: `${REACT_APP_PLUS_HOME_URL}/frequently-asked-questions`
};

export const TURNOFFWIFI = {
  errorText: 'Turn off Wi-Fi and try again.',
  buttonText: 'Learn more',
  url: `${REACT_APP_PLUS_HOME_URL}/frequently-asked-questions`
};

export const ERR_NO_QUALIFIED_ACC = {
  errorHeading: 'You are unable to join Telstra Plus',
  errorText:
    'We cannot set up a Telstra Plus membership for this account. Message us using My Telstra app, or visit us in-store to discuss your account.'
};

export const ERR_NO_ASSET_USER = {
  errorHeading: 'You’re unable to join Telstra Plus',
  errorText: 'Ask your account holder to join Telstra Plus.'
};

// export const ERR_NO_SMALL_BUSINESS = {
//   errorHeading: 'We noticed a problem with your Telstra ID',
//   errorText:
//     'Your Telstra ID seems to be missing some information. Call us on 13 22 00 and mention the support code.'
// };

export const ERR_NO_ACC_UNDER_REVIEW = {
  errorHeading: 'Message us to discuss your account',
  errorText:
    'We need to confirm some details. Please message us using My Telstra app to discuss your account.'
};

export const ERR_ACCS_UNDER_REVIEW = {
  errorHeading: 'Message us to discuss your account',
  errorText:
    'We need to confirm some details. Please message us using My Telstra app to discuss your account.'
};

export const ERR_USER_MULTIPLE_CONTACT_MSG = {
  errorHeading: 'We need to confirm some details',
  errorText: 'Message us for assistance, or get help in My Telstra app.'
};

export const ERR_NO_CONTACT_LINKED_MSG = {
  errorHeading: 'Missing information',
  errorText:
    'There are some details missing from your Telstra ID. Please update your Telstra ID to continue.'
};

export const ERR_TOO_MANY_REQUESTS_MSG = {
  errorHeading: 'Something went wrong',
  errorText:
    'We’re experiencing a lot of traffic right now. Please refresh the page or try again later.'
};

// Contact capture error messages
export const ERR_CC_GENERAL_MSG = 'Something went wrong';
export const ERR_CC_TECHNICAL_MSG = 'Technical error'; // 5000
export const ERR_CC_BAD_REQUEST_MSG = 'Bad request'; // 1000
export const ERR_CC_MAX_PIN_MSG =
  'You’ve reached the maximum attempts to send a one-time PIN.'; // 2005 back to front with alert max attempts
export const ERR_CC_MESSAGE_FAILED_MSG = 'Message failed to send'; // back to otp
export const ERR_CC_INVALID_MOBILE_MSG = 'Enter a valid mobile number'; // 1005
export const ERR_CC_INVALID_EMAIL_MSG = 'Enter a valid email address'; // 1006
export const ERR_CC_OTP_NO_EXIST_MSG = 'OTP does not exist'; // 2001
export const ERR_CC_OTP_INVALID_MSG =
  'You’ve entered an invalid PIN. Please check and try again.'; // 2002;
export const ERR_CC_OTP_EXPIRED_MSG = 'Your one-time PIN has expired.'; // 2003
export const ERR_CC_MAX_OTP_ATTEMPTS_MSG =
  'You’ve entered an invalid PIN. You’ve reached the maximum number of attempts.'; // 2004

export const ERR_SET_BIRTHDAY = {
  errorHeading: 'Something went wrong',
  errorText:
    'We’re currently unable to process your request. Please try again later.'
};

export const ERR_GET_ENTRY = {
  errorHeading: 'Something went wrong',
  errorText:
    'We’re currently unable to process your request. Please try again later.'
};

export const ERR_NO_REWARD_FOUND = {
  errorHeading: 'We’ve encountered an error retrieving the product details',
  errorText:
   'Please check back in a few minutes or you can browse our current hot offers.',
  linkText: 'Browse hot offers',
  url: '/rewards/explore?offer=hotoffer'
};

export const ERR_NO_SERVICE_UNDER_CAC = {
  errorHeading: 'Unable to process your order',
  errorText: 'As you do not currently have an active service with Telstra, you will be unable to redeem in Telstra Plus. An active Telstra service is required to reinstate eligibility to redeem. Active services can be created via telstra.com.au.',
  linkText: 'Explore telstra.com.au',
  url: 'https://www.telstra.com.au'
};