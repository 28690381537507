import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import eventsAction from 'actions/events';
import { LoadingSpinner } from 'components';
import { canBuyTicket } from 'selectors/GetEventDetail';
import { buyEventTicket } from 'api/getTickets';
import { signIn } from 'oidc/initiateOidc';

const Buy = () => {
  const { genre, eid, pid } = useParams();
  const history = useNavigate();
  const isFetched = useSelector(state => state.events.fetch);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.appStatus.authenticated);
  const buyTicket = useSelector(state => canBuyTicket(state));
  const { accountId } = useSelector(state => state.accounts);
  

  useEffect(() => {
    dispatch(eventsAction(eid));
  }, [dispatch, eid]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isFetched === 'loaded' && isAuthenticated && buyTicket !== undefined) {
      if (buyTicket) {
        return dispatch(buyEventTicket(accountId, eid, pid));
      }
      // User is not allowed to buy the ticket, redirect them back to the tickets childpage.
      // This could occur when an Member user clicks to buy a ticket from unauth and the presale
      // has started for Gold members only.
      history(`/tickets/event/${genre}/${eid}`);
    }
  }, [dispatch, isAuthenticated, isFetched, buyTicket, genre, eid, pid, accountId]);

  if (!isAuthenticated) {
    signIn();
  }
  return <>{isFetched === 'loading' && <LoadingSpinner />}</>;
};

export default Buy;
