import * as actions from 'constants/actions';

const pointsState = {
  success: false,
  error: 0
};

const pointsTransferReducer = (state = pointsState, action) => {
  switch (action.type) {
    case actions.SET_TRANSFER_POINTS_SUCCESS:
      // parse response data
      return { ...state, success: action.payload };
    case actions.TRANSFER_POINTS_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default pointsTransferReducer;
