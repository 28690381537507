import React from 'react';
import { HtmlConverter } from 'utils/HtmlConverter';
import { Spacing, TextStyle } from '@able/react';

import { Cta } from 'components';

import './CenteredPromoTile.scss';

const CenteredPromoTile = props => {
  const {
    id = '',
    backgroundColor = '',
    heading = '',
    copy = '',
    primaryCtaAction = '',
    primaryCtaText = '',
    primaryCtaUrl = '',
    primaryCtaVariant = 'HighEmphasis',
    primaryCtaTarget = '',
    primaryCtaTrackingCode = '',
    secondaryCtaAction = '',
    secondaryCtaText = '',
    secondaryCtaUrl = '',
    secondaryCtaVariant = 'LowEmphasis',
    secondaryCtaTarget = '',
    secondaryCtaTrackingCode = '',
    terms = ''
  } = props;

  return (
    <>
      <section className="tplus-center-promo-tile">
        <div className={`tplus-center-promo-tile__${backgroundColor}`}>
          <Spacing id={id} top="spacing7x" bottom="spacing7x">
            <Spacing bottom="spacing3x" bottomVXS="spacing3x">
              <TextStyle alias="HeadingB" element="h2" alignment="Center">
                {heading}
              </TextStyle>
            </Spacing>
            {copy && (
              <Spacing bottom="spacing3x">
                <TextStyle alias="BaseBig" element="p" alignment="Center">
                  {HtmlConverter(copy)}
                </TextStyle>
              </Spacing>
            )}
            {(primaryCtaText || secondaryCtaText) && (
              <div className="centered">
                {primaryCtaText && (
                  <Spacing bottom="spacing3x" bottomVXS="spacing1x">
                    <Cta
                      action={primaryCtaAction}
                      trackingCode={primaryCtaTrackingCode}
                      url={primaryCtaUrl}
                      ctaProps={{
                        variant: primaryCtaVariant,
                        label: primaryCtaText,
                        ...(primaryCtaTarget && { target: primaryCtaTarget })
                      }}
                    />
                  </Spacing>
                )}
                {secondaryCtaText && (
                  <Spacing bottom="spacing3x" bottomVXS="spacing1x">
                    <Cta
                      action={secondaryCtaAction}
                      trackingCode={secondaryCtaTrackingCode}
                      url={secondaryCtaUrl}
                      ctaProps={{
                        variant: secondaryCtaVariant,
                        label: secondaryCtaText,
                        ...(secondaryCtaTarget && { target: secondaryCtaTarget })
                      }}
                    />
                  </Spacing>
                )}
              </div>
            )}
            <TextStyle alias="FinePrint" element="p" alignment="Center">
              {HtmlConverter(terms)}
            </TextStyle>
            </Spacing>
          </div>
      </section>
    </>
  );
};

export default CenteredPromoTile;
