import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { isMobile } from 'react-device-detect';
import { MiniPromoTile } from 'components';
import clsx from 'clsx';

// import './PromoGroup.scss';

import { aemConfig } from 'oidc/oidc-configs';

const settings = {
  dots: true,
  infinite: false,
  draggable: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  className: 'minitiles',
  arrows: true
};

const PromoTilesGroup = props => {
  const { promos, groupHeading, tileAmount, mobileCarousel } = props;

  const containerClass = clsx('promo-group-container', !isMobile && tileAmount);

  const renderMiniPromos = () => {
    return Object.keys(promos).map((key, index) => (
      <li>
        <MiniPromoTile
          key={index}
          id={promos[key].id}
          imagePath={aemConfig.baseUrl + promos[key].image}
          imageAltText={promos[key].imageAlt}
          promoSmallTitle={promos[key].smallTitle}
          promoTitle={promos[key].title}
          promoCopy={promos[key].copy}
          promoSubCopy={promos[key].subCopy}
          buttonText={promos[key].ctaText}
          url={promos[key].ctaUrl}
          ctaAction={promos[key].ctaAction}
          ctaTarget={promos[key].ctaTarget}
          ctaTrackingCode={promos[key].ctaTrackingCode}
          secondaryCtaText={promos[key].secondaryCtaText}
          secondaryCtaUrl={promos[key].secondaryCtaUrl}
          secondaryCtaTarget={promos[key].secondaryCtaTarget}
          secondaryCtaTrackingCode={promos[key].secondaryCtaTrackingCode}
          active={promos[key]}
          primaryCtas={promos[key].primaryCtas}
          secondaryCtas={promos[key].secondaryCtas}
        />
      </li>
    ));
  };

  const renderMobilePromos = () => {
    return <Slider {...settings}>{renderMiniPromos()}</Slider>;
  };

  const renderTiles = () => {
    if (!isMobile) {
      return <ul className="promo-group">{renderMiniPromos()}</ul>;
    }
    if (mobileCarousel) {
      return renderMobilePromos();
    }
    return <div className="promo-group">{renderMiniPromos()}</div>;
  };

  

  return (
    <section className={containerClass}>
      {promos && (
        <>
          {groupHeading && (
            <div className="promo-group-heading">
              <h2 className="heading">{groupHeading}</h2>
            </div>
          )}
          {renderTiles()}
        </>
      )}
    </section>
  );
};

export default PromoTilesGroup;

PromoTilesGroup.propTypes = {
  groupHeading: PropTypes.string,
  tileAmount: PropTypes.string
};

PromoTilesGroup.defaultProps = {
  groupHeading: undefined,
  tileAmount: 'one-two-tiles'
};
