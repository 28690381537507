import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DEFAULT_TIMZONE } from 'constants/general';
import { Grid, Column, TextStyle, Icon, Spacing, ActionButton } from '@able/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import parseZone from 'utils/parseZone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(parseZone);

import {
  RESET_EVENTS_ERROR,
  RESET_MOVIES_ERROR,
  RESET_EVENTS_CHILD_PAGE_GLOBAL_ERROR,
  SET_EVENTS_CHILD_DEFAULT
} from 'constants/actions';
import eventsAction from 'actions/events';

import alertimage from 'assets/images/Alert.png';
import { Breadcrumbs, EventSummary, LoadingSpinner, EventPreSale } from 'components';
import Helmet from 'react-helmet';

import * as Analytics from 'utils/analytics';
import MovieChildPage from './MovieChildPage/MovieChildPage';
import ThumbnailDesc from '../ThumbnailDesc';
import TicketAlertBox from '../TicketAlertBox';

import { getPageConfig } from 'selectors/GetConfig';

import './EventChildPage.scss';
import SingleEventView from '../SingleEventView/SingleEventView';

import { SportChildPage } from './SportChildPage/SportChildPage';

const EventChildPage = () => {
  const { genre, id } = useParams();
  const [presaleState, setPresaleState] = useState(false); // notStarted || startingSoon || started || ended
  const isFetched = useSelector(state => state.events.fetch);
  const movieError = useSelector(state => state.movies.error);
  const eventError = useSelector(state => state.events.error);
  const pageConfig = useSelector(state => getPageConfig(state));
  const globalTicketErrorForChildPage = useSelector(
    state => state.events.globalTicketErrorForChildPage
  );
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.appStatus.authenticated);
  const [movieThumbnail, setMovieThumbnail] = useState({});

  const workingDetail = useSelector(state => state.events.childEvents.details);
  const performances = useSelector(state => state.events.childEvents.children);
  const singleEvent = performances.length === 1;
  const hideNotifcation = isAuthenticated && singleEvent && presaleState !== 'ended';
  
  const init = () => {
    dispatch({ type: RESET_EVENTS_ERROR });
    dispatch({ type: RESET_MOVIES_ERROR });
    dispatch({
      type: RESET_EVENTS_CHILD_PAGE_GLOBAL_ERROR
    });
    dispatch({ type: SET_EVENTS_CHILD_DEFAULT });
    // call event child page api
    if (genre !== 'Movie') {
      dispatch(eventsAction(id));
    }
  };

  const queueIsEnabled = (queueit, currentPage) => {
    if (!queueit) return;
    return Object.keys(queueit).some(key => {
      return key === currentPage;
    });
  };

  useEffect(() => {
    const queuePage = queueIsEnabled(pageConfig.queueit, location.pathname);
    if (queuePage) {
      if (window.tplusQueueVerified) {
        init();
      } else {
        window.addEventListener('queueVerified', () => {
          removeEventListener('queueVerified', window);
          init();
        }, { once: true });
      }
    } else {
      init();
    }
    return () => { removeEventListener('queueVerified', window); };
  }, []);

  const calculatePresaleState = (presaleStartDate, presaleEndDate) => {
    const dayjsCurrDate = dayjs().tz(DEFAULT_TIMZONE);
    if (presaleEndDate) {
      const dayjsPresaleEndDate = dayjs(presaleEndDate).tz(DEFAULT_TIMZONE);
      if (dayjsPresaleEndDate < dayjsCurrDate) {
        return setPresaleState('ended');
      }
    }
    if (presaleStartDate) {
      const commencingWindow = 1800; // 30 minutes
      const dayjsPresaleStartDate = dayjs(presaleStartDate).tz(DEFAULT_TIMZONE);
      const diff = dayjsPresaleStartDate.diff(dayjsCurrDate, 'seconds');
      if (diff < 0) {
        setPresaleState('notStarted');
      } else if (diff < commencingWindow && diff > 0) {
        setPresaleState('startingSoon');
      } else {
        setPresaleState('started');
      }
    }
    return null;
  };

  // console.log(inPresaleCommencingWindow)

  useEffect(() => {
    if (workingDetail?.tourName) {
      if (isAuthenticated) {
        Analytics.setPageName(
          `Auth Tickets - ${genre} - ${workingDetail.tourName}`
        );
      } else if (!isAuthenticated) {
        Analytics.setPageName(
          `Unauth Tickets - ${genre} - ${workingDetail.tourName}`
        );
      }
    }
  }, [workingDetail]);

  useEffect(() => {
    const { eventSaleStartDate, eventSaleEndDate } = workingDetail;
    let timer;
    if (presaleState !== 'ended') {
      timer = setInterval(() => {
        calculatePresaleState(eventSaleStartDate, eventSaleEndDate);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [workingDetail, presaleState]);

  const renderErrorsNotification = errorType => {
    let error;
    if (errorType === 'movie') {
      error = movieError;
    } else if (errorType === 'event') {
      error = eventError;
    }

    if (error) {
      return (
        <div className="ticket-notification-wrapper">
          <TicketAlertBox
            notificationType="warning"
            notificationText={error.message}
          />
        </div>
      );
    }
    return null;
  };

  const renderEventPerformances = () => {
    // single event performance view
    if (performances.length === 1) {
      return (
        <SingleEventView
          description={workingDetail.tourDescription}
          performance={performances[0]}
        />
      );
    }

    // multiple event performances view
    switch (genre) {
      case 'Arts':
        return <EventSummary summaryData={performances} />;
      case 'Musics':
        return <EventSummary summaryData={performances} />;
      case 'Sports':
        return <SportChildPage performances={performances} />;
      default:
    }
    return <EventSummary summaryData={performances} />;
  };

  // for rendering the content based on genre
  const renderContent = () => {
    switch (genre) {
      case 'Movie':
        return (
          <>
            {renderErrorsNotification('movie')}
            <MovieChildPage
              movieId={id}
              movieThumbnail={data => setMovieThumbnail(data)}
            />
          </>
        );
      case 'Arts':
      case 'Music':
        return (
          <>
            {!hideNotifcation && (
              <EventPreSale
                eventData={workingDetail}
                viewType="notify"
                singleEvent={singleEvent}
              />
            )}
            {renderErrorsNotification('event')}
            {performances && renderEventPerformances()}
          </>
        );
      default:
        return (
          <>
            {renderErrorsNotification('event')}
            {performances && renderEventPerformances()}
          </>
        );
    }
  };

  const renderThumbnail = () => {
    // compute event thumbnail
    let eventThumbnail = workingDetail;
    if (singleEvent) {
      eventThumbnail = {
        ...workingDetail
      };
    }

    switch (genre) {
      case 'Movie':
        return <ThumbnailDesc type="movie" data={movieThumbnail} />;
      default:
        return (
          <ThumbnailDesc singleEventView={singleEvent} data={eventThumbnail} />
        );
    }
  };

  const renderFailedMessage = () => {
    return (
      <Spacing top="spacing7x" bottom="spacing10x">
        <img
          style={{ width: '80px', height: '66px' }}
          src={alertimage}
          alt="An error occured"
        />
        <Spacing bottom="spacing4x"/>
        <TextStyle alias="HeadingA" element="h1">
          Something went wrong
        </TextStyle>
        <Spacing bottom="spacing2x"/>
        <TextStyle alias="Base" element="p">
          We’re working on it. Please try again later.
        </TextStyle>
        <Spacing bottom="spacing2x"/>
        <ActionButton
          element="Link"
          label="Go back to Telstra Plus Tickets"
          to="/tickets"
          variant="LowEmphasis"
        />
      </Spacing>
    );
  };
  
  return (
    <>
      <Helmet>
        <title data-la-initdispnone="true">
          {`${workingDetail.tourName}, Telstra Plus Tickets`}
        </title>
      </Helmet>
      {isFetched === 'loading' ? (
        <LoadingSpinner />
      ) : (
        <>
          <Grid className="container">
            <Column className="child-page">
              <Breadcrumbs customPathname='/tickets'/>
              {presaleState === 'startingSoon' && (
                <div className="tplus-event-presale-banner">
                  <Icon
                    size="24"
                    developmentUrl="/able-sprites.svg"
                    icon="Refresh"
                    role="img"
                  />
                  <TextStyle element="p" alias="Base" colour="Paragraph">This page will auto-refresh. DO NOT refresh the page, it may block your access to the pre-sale</TextStyle>
                </div>
              )}
              {globalTicketErrorForChildPage && renderFailedMessage()}
              {!globalTicketErrorForChildPage && (
                <Grid className="event-page">
                  <Column bmd={4} bmdOffset={1}>{renderThumbnail()}</Column>
                  <Column bmd={5} bmdOffset={1}>{renderContent()}</Column>
                </Grid>
              )}
              <Spacing bottom="spacing10x"/>
            </Column>
          </Grid>
        </>
      )}
    </>
  );
};

export default EventChildPage;

