import { batch } from 'react-redux';

import activateSmbApi from 'api/setSmb';

import { LOGGER } from 'utils/AppLog';
import * as actions from 'constants/actions';
import { getCurrentCac } from 'reducers/activeAccount';
import { getSmbPending } from 'reducers/smb';

// Action Creators
export const activateSmb = (id, user) => {
  return (dispatch, getState) => {
    if (getSmbPending(getState())) {
      return Promise.resolve();
    }

    dispatch({
      type: actions.SET_SMB_PENDING,
      payload: true
    });

    const currentCac = getCurrentCac(getState());
    // @TO-DO change payload to an object so multiple attributes can be set
    return activateSmbApi(currentCac, user)
      .then(response => {
        LOGGER.debug('SMB promo successful activation ', response);
        batch(() => {
          dispatch({
            type: actions.SET_SMB_PENDING,
            payload: false
          });
          dispatch({
            type: actions.SET_SMB_SUCCESS,
            status: true,
            activated: true,
            id
          });
        });
      })
      .catch(error => {
        LOGGER.debug('SMB promo activation failed: ', error, error.stack);
        dispatch({
          type: actions.SET_SMB_PENDING,
          payload: false,
          id
        });
        dispatch({
          type: actions.SET_SMB_FAILURE,
          errorObject: error.response,
          status: true,
          activated: false,
          id
        });
      });
  };
};
