import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import queryString from 'query-string';
import {
  CheckboxGroup,
  Checkbox,
  RadioGroup,
  RadioButton,
  Spacing,
  TextStyle
} from '@able/react';
import useMediaQuery from 'utils/hooks/useMediaQuery';
import {
  fetchRewardCatalog,
  searchRewardCatalog,
  resetProductFilter,
  SetProductFilter,
  clearSearchQuery,
  resetRewardProductFiltersView,
  setCategoriesFilter,
  resetCategoryFilter,
  setOfferType,
  setStockFilter
} from 'actions/rewards';
import { getAttributeList } from 'selectors/GetRewards';
import { Accordion, AccordionGroup, ConfirmationDialog } from 'components';
import { addLinkClickEvent } from "../../../utils/analytics";
import { SESSION_STORAGE_SOURCE } from 'constants/rewardsStore';
import './RewardsFilter.scss';

const RewardsFilter = props => {
  const filterQuery = queryString.parse(window.location?.search);
  const { category : categoryParams, offer : offerParams, source } = filterQuery;
  const { isOpen, closeFilter, setFilterIsOpen, dialogOpen } = props;
  const dispatch = useDispatch();
  const catalogView = useSelector(state => state.rewards.catalogView);
  const catalogViewStatus = useSelector(
    state => state.rewards.catalogView.status
  );
  const attributeList = useSelector(state => getAttributeList(state));
  const isMobileView = useMediaQuery('(max-width: 780px)');
  const resetViews = useSelector(
    state => state.rewards.catalogView.resetFiltersView
  );
  const isAuthenticated = useSelector(state => state.appStatus.authenticated);

  const {
    productBrands,
    productTags: { offerTypes, productTypes },
    searchQuery,
    filters,
    stockFilter,
    categoriesFilter,
    offerTypeFilter
  } = catalogView;

  // Filter states
  const [categoriesState, setCategoriesState] = useState({});
  const [offerTypeState, setOfferTypeState] = useState('');
  const [attributeState, setAttributeState] = useState({});
  const [brandState, setBrandState] = useState([]);
  //filter reset actions
  const [brandToCheck, setBrandToCheck] = useState(null);
  const [resetFiltersRequested, setRequestedFiltersRequested] = useState(false);

  //Checked filter
  const [checkedFilter, setCheckedFilter] = useState(false);

  // Refs
  const categoryFormRef = useRef();
  const attributeFormRef = useRef();
  const defaultRadio = useRef();

  const updateCategoriesState = (prop, val) => {
    const { checked } = val.currentTarget;
    const newState = {
      ...categoriesState,
      [prop]: checked
    };
    dispatch(setCategoriesFilter(newState))
  };

  const updateAttributeState = (prop, val) => {
    const newState = {
      ...attributeState,
      [prop]: val
    };
    setAttributeState(newState);
  };

  const attributeSelected = (id, val) => {
    if (attributeState[id]) {
      return attributeState[id].some(a => a === val);
    }
  };

  const resetFilters = (onlyResetFilterView = false, brandToCheck = null) => {
    if (onlyResetFilterView) {
      setRequestedFiltersRequested(true);
      setBrandToCheck(brandToCheck);
    }
    document
      .querySelectorAll('input[type=checkbox]')
      .forEach(el => (el.checked = false)); // YUK
    if (attributeFormRef?.current) {
      attributeFormRef.current.resetForm({});
    }
    if (defaultRadio?.current) {
      defaultRadio.current.click();
    }

    setAttributeState({});
    setBrandState([]);

    if (!onlyResetFilterView) {
      dispatch(resetProductFilter());
      dispatch(setStockFilter(false));
    }
    if (attributeFormRef?.current) {
      attributeFormRef.current.setValues({});
    }
    dispatch(resetCategoryFilter());
    setCategoriesState({})
  };

  const clearFiltersFormik = () => {
    const offerType = categoryFormRef.current.values.offerType;
    categoryFormRef.current.setValues(offerType ? { offerType } : {});
  }

  const handleReset = () => {
    resetFilters();
    dispatch(fetchRewardCatalog(offerTypeState ? [offerTypeState] : null, null));
    dispatch(clearSearchQuery());
  };

  /* deselects respective checkbox in categories while closing applied filter */
  const closeCategoryFilter = list => {
    document.querySelectorAll('input[type=checkbox]').forEach(el => {
      if (list && el.name === 'categories' && list.includes(el.value)) {
        el.checked = true;
      } else if (list && el.name === 'categories' && !list.includes(el.value)) {
        el.checked = false;
      }
    });
  };

  /* deselects respective checkbox in brands while closing applied filter */
  const closeBrandFilter = list => {
    document.querySelectorAll('input[type=checkbox]').forEach(el => {
      if (list && el.name === 'brands' && list.includes(el.value)) {
        el.checked = true;
      } else if (list && el.name === 'brands' && !list.includes(el.value)) {
        el.checked = false;
      }
    });
  };

  const returnToHome = () => {
    resetFilters();
    clearFiltersFormik();
    dispatch(clearSearchQuery());
    dispatch(fetchRewardCatalog(offerTypeState ? [offerTypeState] : null, null));
  };

  const isFilterChecked = () => {
    const checkedFiltersCount = document.querySelectorAll(
      'input[type=checkbox]:checked:not([name="stock"])'
    ).length;
    checkedFiltersCount > 0 ? setCheckedFilter(true) : setCheckedFilter(false);
  };

  const fetchHotOfferId = (name) => {
    return name.toLowerCase().replace(" ","");
  }

  const isHotOffer = (name) => {
    return name.toLowerCase().startsWith("hot offer");
  }

  const cancelButtonEvents = {
    onClick: () => {
      handleReset();
    }
  };

  useEffect(() => {
    // setting selected category & offer from query params
    if(categoryParams){
      const categoryParamsList = categoryParams.split(',')
      .reduce((acc, category) =>{ return {...acc, [category]:true} } , {});
      dispatch(setCategoriesFilter(categoryParamsList));
    }
    offerParams && dispatch(setOfferType(offerParams));
    // set stock filter to false for ARP flow
    source === "agent_console" && dispatch(setStockFilter(false));
  }, [])

  useEffect(() => {
    if (searchQuery) {
      resetFilters();
    }
  }, [searchQuery]);

  useEffect(() => {
    if (resetViews != null) {
      resetFilters(true, resetViews);
      dispatch(resetRewardProductFiltersView(null));
    }
  }, [resetViews]);

  useEffect(() => {
    isFilterChecked();
  }, [categoriesState,brandState,stockFilter,attributeState]);

  useEffect(() => {
    setBrandState(filters?.brands);
  }, [filters?.brands]);

  useEffect(() => {
    setAttributeState(filters?.attributes);
  }, [filters?.attributes]);

  useEffect(() => {
    categoriesFilter && setCategoriesState(categoriesFilter);    
  }, [categoriesFilter]);

  useEffect(()=>{
    offerTypeFilter && setOfferTypeState(offerTypeFilter);
  },[offerTypeFilter])

  useEffect(() => {
    if (resetFiltersRequested && catalogViewStatus == 'succeeded') {
      document.querySelectorAll('input[type=checkbox]').forEach(el => {
        if (brandToCheck && el.name == 'brands' && el.value == brandToCheck) {
          // el.checked = true;
          el.checked = true;
        } else {
          el.checked = false;
        }
      }); // YUK

      setBrandToCheck(null);
      setRequestedFiltersRequested(false);

      if (attributeFormRef.current && attributeFormRef.current.setValues) {
        attributeFormRef.current.setValues({ brands: [brandToCheck] });
      }
      setBrandState([brandToCheck]);
    }
  }, [catalogViewStatus]);

  useEffect(()=>{
    // update address bar URL
    const selectedCategories = Object.keys(categoriesState).filter(i => categoriesState[i]);    
    const url = new URL(window.location);
    offerTypeState ? url.searchParams.set('offer', offerTypeState) : url.searchParams.delete('offer');
    selectedCategories?.length > 0 ? url.searchParams.set('category', selectedCategories) : url.searchParams.delete('category');
    sessionStorage.getItem(SESSION_STORAGE_SOURCE) && url.searchParams.set('source', sessionStorage.getItem(SESSION_STORAGE_SOURCE));
    window.history.replaceState({}, '', decodeURIComponent(url));
  }, [offerTypeState, categoriesState])

  const filterForms = () => {
    return (
      <>
        <Formik
          initialValues={{
            offerType: offerTypeState || '',
            categories: Object.keys(categoriesState).filter(
              c => categoriesState[c]
            ),
            stock:stockFilter
          }}
          innerRef={categoryFormRef}
          onSubmit={values => {
            const { offerType, categories, stock } = values;
            const offerTypeValues = offerTypeFilter ? [offerTypeFilter] : offerType ;
            const selectedCategories = Object.keys(categoriesFilter).filter(i => categoriesFilter[i]);
            const categoriesArr = selectedCategories.length > 0 ? selectedCategories : categories;
            stock !== undefined && dispatch(setStockFilter(stock));
            if (searchQuery) {
              dispatch(
                searchRewardCatalog(searchQuery, null, categories)
              );
            } else {
              dispatch(fetchRewardCatalog(offerTypeValues, categoriesArr));
            }
          }}
        >
          {formProps => (
            <>
              {!searchQuery && (
                <>
                  <RadioGroup label="Browse" name="offerType">
                    <RadioButton
                      label="All Offers"
                      name={null}
                      value="all"
                      checked={offerTypeState === "all"}
                      events={{
                        onChange: e => {
                          dispatch(setOfferType('all'))
                          formProps.setFieldValue('categories', []);
                          formProps.handleChange(e);
                          formProps.submitForm();
                          resetFilters();
                          isFilterChecked();
                          addLinkClickEvent("All Offers");
                        }
                      }}
                    />
                    {offerTypes.map((o, key) => (
                      <RadioButton
                        key={key}
                        label={o.name}
                        name={o.id}
                        value={isHotOffer(o.name) ? fetchHotOfferId(o.name) : o.id}
                        checked={(offerTypeState != "all" && offerTypeState != "byPoints") && ((offerTypeState == "" && o.description == "defaultOfferType") || (offerTypeState == o.id) || (offerTypeState == fetchHotOfferId(o.name)))}
                        events={{
                          onChange: e => {
                            dispatch(setOfferType(isHotOffer(o.name) ? fetchHotOfferId(o.name) : o.id))
                            formProps.setFieldValue('categories', []);
                            formProps.handleChange(e);
                            formProps.submitForm();
                            resetFilters();
                            isFilterChecked();
                            addLinkClickEvent(o.name);
                          }
                        }}
                      />
                    ))}
                    { isAuthenticated ? (
                      <RadioButton
                        label="Offers by points"
                        name={null}
                        value="byPoints"
                        checked={offerTypeState === "byPoints"}
                        events={{
                          onChange: e => {
                            dispatch(setOfferType('byPoints'))
                            formProps.setFieldValue('categories', []);
                            formProps.handleChange(e);
                            formProps.submitForm();
                            resetFilters();
                            isFilterChecked();
                            addLinkClickEvent("Offers by points");
                          }
                        }}
                        />
                    ) : (
                      <>
                      </>
                  )}
                  </RadioGroup>
                  <Spacing bottom="spacing3x" />
                </>
              )}
             {(checkedFilter || categoryParams ) && ( <Accordion
                id="applied-filters"
                heading="Applied filters"
                expanded={true}
              >
                <div id="applied-filter-area">
                  {productTypes.map((p, key) => {
                    return (
                      <React.Fragment>
                        {categoriesState[[p.id]] && (
                          <div className="filter-section" id="category-filter">
                            <div className="each-filter">
                              <div className="each-filter-text"> {p.name}</div>
                              <button
                                name="filterButton"
                                className="each-filter-pseudo"
                                aria-label={`Remove filter - ${p.name}`}
                                onClick={e => {
                                  updateCategoriesState(p.id, {
                                    currentTarget: { checked: false }
                                  });
                                  formProps.handleChange(e);
                                  var array = Object.keys(
                                    categoriesState
                                  ).filter(c => categoriesState[c]);
                                  var index = array.indexOf(p.id.toString());
                                  array.splice(index, 1);
                                  closeCategoryFilter(array);
                                  formProps.setFieldValue('categories', array);
                                  formProps.submitForm();
                                }}
                              ></button>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                  {Object.keys(productBrands).map(key => {
                    return (
                      <React.Fragment>
                        {brandState.length > 0 &&
                          brandState.some(
                            id => id === productBrands[key].id.toString()
                          ) && (
                            <div className="filter-section" id="brand-filter">
                              <div className="each-filter">
                                <div className="each-filter-text">
                                  {' '}
                                  {productBrands[key].name}
                                </div>
                                <button
                                  name="filterButton"
                                  className="each-filter-pseudo"
                                  aria-label={`Remove filter - ${productBrands[key].name}`}
                                  onClick={e => {
                                    formProps.handleChange(e);
                                    var array = brandState;
                                    var index = array.indexOf(
                                      productBrands[key].id.toString()
                                    );
                                    array.splice(index, 1);
                                    closeBrandFilter(array);
                                    formProps.setFieldValue('brands', array);
                                    formProps.submitForm();
                                  }}
                                ></button>
                              </div>
                            </div>
                          )}
                      </React.Fragment>
                    );
                  })}
                  {(document.getElementsByClassName('filter-section').length > 1
                  || categoryParams?.split(',')?.length > 1) 
                  && (
                    <button
                      name="clearAll"
                      className="clear-all"
                      onClick={e => {
                        resetFilters();
                        clearFiltersFormik();
                        if (searchQuery) {
                          dispatch(searchRewardCatalog(searchQuery, null, null));
                        } else {
                          dispatch(fetchRewardCatalog(offerTypeState ? [offerTypeState] : null, null));
                        }
                      }}
                    >
                      Clear all filters
                    </button>
                  )}
                </div>
              </Accordion>)}
              <Accordion
                    id="stock-filter"
                    heading="Availability"
                    expanded={true}
                  >
                    <CheckboxGroup name="stock" required={true} describedById="stock-filter-group">
                      <Checkbox
                        label="In Stock"
                        name="stock"
                        value={true}
                        checked={stockFilter}
                        events={{
                          onChange: e => {
                            formProps.handleChange(e);
                            formProps.submitForm();
                          }
                        }}
                      />
                    </CheckboxGroup>
              </Accordion>
              <AccordionGroup id="categories-filter-group">
                <Accordion
                  id="categories-filter"
                  heading="Categories"
                  expanded="true"
                >
                  <CheckboxGroup
                    name="categories"
                    id="categories-checkboxes"
                    variant="Default"
                    required={true}
                    describedById="categories-filter-group"
                  >
                    {productTypes.map((p, key) => (
                      <Checkbox
                        key={key}
                        label={p.name}
                        name={p.id}
                        value={p.id}
                        checked={categoriesState[[p.id]]}
                        events={{
                          onChange: e => {
                            updateCategoriesState(p.id, e);
                            formProps.handleChange(e);
                            formProps.submitForm();
                            isFilterChecked();
                            addLinkClickEvent(p.name);
                          }
                        }}
                      />
                    )).sort((a, b) => (productTypes[a.key].name > productTypes[b.key].name) ? 1 : -1)}
                  </CheckboxGroup>
                </Accordion>
              </AccordionGroup>
            </>
          )}
        </Formik>

        <Formik
          initialValues={{
            brands: filters?.brands,
            ...filters?.attributes
          }}
          innerRef={attributeFormRef}
          onSubmit={values => {
            const filterObj = {
              brands: [],
              attributes: {}
            };
            Object.keys(values).forEach(key => {
              const val = values[key];
              if (key === 'brands') {
                filterObj.brands = val;
              } else {
                filterObj.attributes[key] = val;
              }
            });
            dispatch(SetProductFilter(filterObj));
          }}
        >
          {formProps => {
            return (
              <Form>
                <AccordionGroup id="product-filters">
                  <Accordion
                    id="brands-filter"
                    heading="Brands"
                    expanded={brandState.length > 0}
                  >
                    <CheckboxGroup name="brands" required={true} describedById="brands-filter-group">
                      {Object.keys(productBrands).map(key => (
                        <Checkbox
                          key={key}
                          checked={
                            brandState.length &&
                            brandState.some(
                              id => id === productBrands[key].id.toString()
                            )
                          }
                          label={productBrands[key].name}
                          name={productBrands[key].name}
                          value={productBrands[key].id}
                          events={{
                            onChange: e => {
                              formProps.handleChange(e);
                              formProps.submitForm();
                              isFilterChecked();
                              addLinkClickEvent(productBrands[key].name);
                            }
                          }}
                        />
                      )).sort((a, b) => (productBrands[a.key].name > productBrands[b.key].name) ? 1 : -1)}
                    </CheckboxGroup>
                  </Accordion>                 
                  {attributeList.map((attribute, key) => {
                    const { type } = attribute;
                    return (
                      <>
                        {type !== 'HIDDEN' && (
                          <Accordion
                            key={key}
                            id={`${attribute.id}-filter`}
                            heading={attribute.name}
                            expanded={attributeState[attribute.id]?.length > 0}
                          >
                            <CheckboxGroup name={attribute.id} required={true} describedById={`${attribute.name}-filter-group`}>
                              {attribute.variations.map((v, i) => (
                                <Checkbox
                                  key={i}
                                  label={v}
                                  name={v}
                                  checked={attributeSelected(attribute.id, v)}
                                  value={v}
                                  events={{
                                    onChange: e => {
                                      formProps.handleChange(e);
                                      formProps.submitForm();
                                       isFilterChecked();
                                    }
                                  }}
                                />
                              )).sort((a, b) => isNaN(parseFloat(attribute.variations[a.key])) ? (attribute.variations[a.key] > attribute.variations[b.key] ? 1 : -1)
                                                : (parseFloat(attribute.variations[a.key]) > parseFloat(attribute.variations[b.key]) ? 1 : -1))}
                            </CheckboxGroup>
                          </Accordion>
                        )}
                      </>
                    );
                  })}
                </AccordionGroup>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  };

  return (
    <>
    {searchQuery && <>
      <Link
      to={`/rewards/explore`}
      onClick={returnToHome}
    >
      Clear search and filters
    </Link>
     <Spacing bottom="spacing3x" />
     </>}
    <div className={`tplus-rewards-filter ${searchQuery ? 'tplus-rewards-border-hide' : ''}`} aria-hidden={!isOpen}>
      {isMobileView && dialogOpen ? (
        <>
          <ConfirmationDialog
            confirmButtonLabel="Confirm"
            cancelButtonLabel="Cancel"
            isShowing={isOpen}
            setHideDialog={() => closeFilter()}
            cancelButtonEvents={cancelButtonEvents}
            setFilterIsOpen={setFilterIsOpen}
          >
            {filterForms()}
          </ConfirmationDialog>
        </>
      ) : (
        filterForms()
      )}
    </div>
    </>
  );
};

export default RewardsFilter;
