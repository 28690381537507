/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { isEmpty } from 'lodash';
import { HtmlConverter } from 'utils/HtmlConverter';
import {
  TextStyle,
  Surface,
  Spacing,
  ActionButton,
  Grid,
  Column
} from '@able/react';
import eventsAction from 'actions/events';

import { addLinkClickEvent } from 'utils/analytics';
import { NavLink as Link } from 'react-router-dom';
import { Cta } from 'components';

import { useDispatch, useSelector } from 'react-redux';
import fetchMovies from 'actions/nowShowing';
import { EventPreSale } from 'components';

import { PARTNERS } from 'constants/externalPartner';
import { buyMovieTicket } from 'api/getTickets';
import { TICKET_LOCAL_STORAGE, TICKET_MOVIE } from 'constants/ticket';
import { Base64 } from 'js-base64';

import { signIn } from 'oidc/initiateOidc';

// Uncomment when lozenge required
// import Lozenges from './Lozenges.js';
import './MultiCarousel.scss';

const MultiCarousel = props => {
  const app = useSelector(state => state.appStatus);
  const { authenticated } = app;

  const {
    heading,
    copy,
    showAll = false,
    category,
    variant,
    label,
    partner,
    desktopItems,
    desktopSlides,
    desktopPartialGutter,
    tabletItems,
    tabletSlides,
    tabletPartialGutter,
    mobileItems,
    mobileSlides,
    mobilePartialGutter,
    ctaAction,
    ctaTrackingCode,
    ctaUrl,
    ctaVariant,
    ctaText,
    ctaTarget
  } = props;

  const dispatch = useDispatch();
  const [carouselState, setCarouselState] = useState(null);

  let carousel;

  const carouselData = useSelector(state =>
    category === 'music'
      ? state.events.Music
      : category === 'sports'
      ? state.events.Sports
      : category === 'arts'
      ? state.events.Arts
      : state.nowShowing.movies
  );

  const cssClass =
    carouselData.length <= 2 ? `transform ${carouselData.length}` : '';
  const toShow = !isEmpty(carouselData[0]) ? 'display' : '';

  const removeAriaHidden = c => {
    if (!showAll) {
      const listRef = c.listRef.current;
      const listItems = listRef.querySelectorAll('li');
      // listItems.forEach(listItem => {
      //     listItem.removeAttribute('aria-hidden');
      // })
      if (listItems.length > 0) {
        const innerItems = listItems[0].children;
        if (innerItems.length > 0) {
          const innerDivs = innerItems[0].children;
          if (innerDivs.length > 0) {
            const buttonRef = c.containerRef.current;
            const buttonItems = buttonRef.querySelectorAll('button');
            buttonItems.forEach(b => {
              b.classList.add('show-arrow');
              // eslint-disable-next-line prefer-template
              // eslint-disable-next-line no-param-reassign
              b.style.top = `${innerDivs[0].offsetHeight / 2 - 22}px`;
            });
          }
        }
      }
    }
  };

  const addAriaHidden = () => {
    if (
      document.getElementsByClassName('react-multi-carousel-dot-list').length >
      0
    ) {
      document
        .getElementsByClassName('react-multi-carousel-dot-list')[0]
        .setAttribute('aria-hidden', 'true');
    }
  };

  useEffect(() => {
    category === 'nowshowing'
      ? dispatch(fetchMovies())
      : dispatch(eventsAction(-1));
  }, []);

  useEffect(() => {
    if (carouselState) {
      removeAriaHidden(carouselState);
      addAriaHidden();
    }
  }, [carouselState]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: Number(desktopItems),
      slidesToSlide: Number(desktopSlides),
      partialVisibilityGutter: Number(desktopPartialGutter)
    },
    tablet: {
      breakpoint: { max: 1024, min: 480 },
      items: Number(tabletItems),
      slidesToSlide: Number(tabletSlides),
      partialVisibilityGutter: Number(tabletPartialGutter)
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: Number(mobileItems),
      slidesToSlide: Number(mobileSlides),
      partialVisibilityGutter: Number(mobilePartialGutter)
    }
  };

  const CustomDot = ({ active, index }) => {
    const movieName = `Page ${Number(index + 1)}`;
    if (carousel) {
      carousel.containerRef.current.classList.remove('isLastSlide');
      carousel.listRef.current.classList.remove('isLastSlide');
      const liList = carousel.listRef.current.children;
      for (const item of liList) {
        item.onclick = null;
      }
      removeAriaHidden(carousel);
      addAriaHidden();
    }
    return (
      <li className={`carousel-dot${active ? '--active slick-active' : ''}`}>
        <div>{movieName}</div>
      </li>
    );
  };
  const ShowAllEvents = ({ carItem, key }) => {
    return (
      <>
        <Column vxs={12} bsm={4} bmd={4} blg={4}>
          <CarouselWrapper
            key={key}
            item={carItem}
            img={carItem.thumbnailImageUrl}
            name={carItem.tourName}
            artist={carItem.artist}
            genre={carItem.genre}
            id={carItem.id}
          />
        </Column>
      </>
    );
  };

  const absoluteToRelativeUrl = url => {
    const origin = window.location.origin;
    if (url && url.indexOf(origin) !== -1) {
      const arr = url.split(origin);
      return arr[1];
    }
    return;
  };

  const CarouselWrapper = ({
    img,
    name,
    artist,
    genre,
    id,
    item,
    isMajorPresale,
    eventPageUrl
  }) => {
    const eventPageRelativeUrl = absoluteToRelativeUrl(eventPageUrl);
    const PresaleEventLink = isMajorPresale
      ? eventPageRelativeUrl
      : `/tickets/event/${genre}/${id}`;
    return (
      <>
        <div className={`carousel-container-box_list_div ${category}`}>
          {category !== 'nowshowing' && genre && (
            <Link
              to={PresaleEventLink}
              className="carousel-container-box_img_link"
              onClick={() => addLinkClickEvent(`${name} view details`)}
            >
              <Surface
                variant="SurfaceSlight"
                className="carousel-container-box_img_container"
              >
                <img src={img} alt="" />
              </Surface>
            </Link>
          )}
          {category === 'nowshowing' && (
            <Surface
              variant="SurfaceSlight"
              className="carousel-container-box_img_container"
            >
              <img src={img} alt="" />
            </Surface>
          )}
          {name && (
            <Spacing
              top="spacing1x"
              topVXS={`${showAll ? 'spacing1x' : 'spacing2x'}`}
              topVSM={`${showAll ? 'spacing1x' : 'spacing2x'}`}
            >
              <TextStyle
                alias="Base"
                className="carousel-wrapper-name"
              >
                {name && name.toUpperCase()}
              </TextStyle>
            </Spacing>
          )}
          {artist && (
            <Spacing
              top={`${showAll ? 'spacingHalf' : 'spacing1x'}`}
              topVXS={`${showAll ? 'spacingHalf' : 'spacing2x'}`}
              topVSM={`${showAll ? 'spacingHalf' : 'spacing2x'}`}
            >
              <TextStyle alias="HeadingC">{artist}</TextStyle>
            </Spacing>
          )}
          {category !== 'nowshowing' && item.offerIndicator && (
            <Spacing
              top={`${showAll ? 'spacingHalf' : 'spacing1x'}`}
              topVXS={`${showAll ? 'spacingHalf' : 'spacing2x'}`}
              topVSM={`${showAll ? 'spacingHalf' : 'spacing2x'}`}
            >
              <div className="offer-indicator" title={item.offerIndicator}>
                <TextStyle
                  alias="Base"
                  className="carousel-presale-date"
                >
                  {item.tierPriority ? (
                    <EventPreSale eventData={item} viewType="carousel" />
                  ) : (
                    item.offerIndicator
                  )}
                </TextStyle>
              </div>
            </Spacing>
          )}
          {genre && (
            <ActionButton
              element="Link"
              variant="LowEmphasis"
              aria-label={`View details for ${name}`}
              activeclassname="isActive"
              className="button-tplus"
              to={PresaleEventLink}
              label="View tickets details"
              onClick={() => addLinkClickEvent(`${name} view details`)}
            />
          )}
          {showAll ? (
            <Spacing bottom="spacing7x"></Spacing>
          ) : (
            <Spacing
              bottom="spacing3x"
              bottomVSM="spacing2x"
              bottomVXS="spacing4x"
            ></Spacing>
          )}
        </div>
      </>
    );
  };

  const beforeChange = () => {
    carousel.containerRef.current.classList.remove('isLastSlide');
    carousel.listRef.current.classList.remove('isLastSlide');
  };

  const afterChange = (
    previousSlide,
    { currentSlide, totalItems, slidesToShow, deviceType }
  ) => {
    removeAriaHidden(carousel);
    addAriaHidden();
    if (deviceType !== 'desktop') {
      const numberOfDotsToShow =
        slidesToShow === carousel.props.slidesToShow
          ? Math.ceil(totalItems / carousel.props.slidesToSlide)
          : Math.ceil(
              (totalItems - slidesToShow) / carousel.props.slidesToSlide
            ) + 1;
      if (currentSlide === numberOfDotsToShow - 1) {
        carousel.containerRef.current.classList.add('isLastSlide');
        carousel.listRef.current.classList.add('isLastSlide');
      }
    }
  };

  const redirectToPartner = () => {
    const id =
      partner === 'event'
        ? PARTNERS.events.apiProps.partnerId
        : PARTNERS.village.apiProps.partnerId;
    if (authenticated) {
      dispatch(
        buyMovieTicket({
          partnerId: id
        })
      );
    } else {
      localStorage.setItem(
        'tplus-flow',
        Base64.encode(
          JSON.stringify({
            flow: TICKET_LOCAL_STORAGE,
            type: TICKET_MOVIE,
            action: 'buyMovieTicket',
            data: [
              {
                partnerId: id
              }
            ]
          })
        )
      );
      signIn();
    }
  };

  return (
    <>
      {toShow === 'display' && (
        <div
          className={`carousel-container-box carousel-container-box_${category}`}
        >
          <div className="carousel-container-box_head">
            <Spacing
              bottom={`${ctaText ? 'spacing3x' : 'spacing5x'}`}
              bottomVSM={`${ctaText ? 'spacing2x' : 'spacing4x'}`}
              bottomVXS={`${ctaText ? 'spacing2x' : 'spacing4x'}`}
            >
              <TextStyle element="h2" alias="HeadingB">
                {heading}
              </TextStyle>
              {copy && (
                <Spacing top={`${ctaText ? 'spacingHalf' : 'spacing1x'}`}>
                  <TextStyle alias="Base">
                    {HtmlConverter(copy)}
                  </TextStyle>
                </Spacing>
              )}
              {ctaText && (
                <Spacing top="spacingHalf">
                  <Cta
                    action={ctaAction}
                    trackingCode={ctaTrackingCode}
                    url={ctaUrl}
                    ctaProps={{
                      variant: ctaVariant,
                      className: 'button-plus member',
                      label: ctaText,
                      ...(ctaTarget && { target: ctaTarget })
                    }}
                  />
                </Spacing>
              )}
            </Spacing>
          </div>
          {!showAll && (
            <Carousel
              beforeChange={beforeChange}
              afterChange={afterChange}
              showDots
              ref={el => {
                carousel = el;
                setCarouselState(el);
              }}
              arrows={true}
              responsive={responsive}
              // removeArrowOnDeviceType={['tablet', 'smalltab', 'mobile']}
              keyBoardControl={true}
              partialVisible={true}
              forSSR
              renderDotsOutside={true}
              customDot={<CustomDot />}
              // centerMode={true}
              containerClass={`multicarousel-container multicarousel-container_${category} multicarousel-container_${cssClass}`}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {category === 'nowshowing'
                ? carouselData &&
                  carouselData
                    .slice(0, 10)
                    .map((carItem, index) => (
                      <CarouselWrapper
                        key={index}
                        img={carItem.LargePosterUrl}
                        artist={carItem.Name}
                      />
                    ))
                : carouselData &&
                  carouselData.map((carItem, index) => (
                    <CarouselWrapper
                      key={index}
                      item={carItem}
                      img={carItem.thumbnailImageUrl}
                      name={carItem.tourName}
                      artist={carItem.artist}
                      genre={carItem.genre}
                      id={carItem.id}
                      isMajorPresale={carItem.isMajorPresale}
                      eventPageUrl={carItem.eventPageUrl}
                    />
                  ))}
            </Carousel>
          )}
          {showAll && (
            <div
              className={`multicarousel-container multicarousel-container_stack multicarousel-container_${category} multicarousel-container_${cssClass}`}
            >
              <Grid>
                {carouselData &&
                  carouselData.map((carItem, index) => (
                    <ShowAllEvents carItem={carItem} key={index} />
                  ))}
              </Grid>
            </div>
          )}

          {category === 'nowshowing' && label && (
            <div className="carousel-container-box_button">
              <Spacing top="spacing4x" topVMD="spacing5x">
                <ActionButton
                  type="submit"
                  variant={variant}
                  label={label}
                  onClick={redirectToPartner}
                  element="button"
                ></ActionButton>
              </Spacing>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MultiCarousel;

MultiCarousel.defaultProps = {
  desktopItems: '3',
  desktopSlides: '3',
  desktopPartialGutter: '0',
  tabletItems: '2',
  tabletSlides: '1',
  tabletPartialGutter: '30',
  mobileItems: '1',
  mobileSlides: '1',
  mobilePartialGutter: '60'
};
