import { v4 as uuidv4 } from 'uuid';
import axios from './util/axiosHandle';
import { addressConfig as api } from '../oidc/oidc-configs';

const addressLookup = value => {
  return axios({
    incomingheaders: {
      apiKey: api.key,
      'ds-correlation-id': uuidv4()
    },
    params: {
      method: 'get',
      url: api.baseUrl + api.endpoints.lookup,
      params: { query: value }
    },

    type: 'public'
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export default addressLookup;
