import * as actions from 'constants/actions';

export const loadContent = (id, content) => {
  return dispatch => {
    dispatch({
      type: actions.SET_EXP_CONTENT,
      data: { id, content }
    });
  };
};

export const renderedContent = data => {
  return dispatch => {
    dispatch({
      type: actions.RENDER_EXP_CONTENT_SUCCESS,
      data
    });
  };
};

export const removeContent = id => {
  return dispatch => {
    dispatch({
      type: actions.REMOVE_EXP_CONTENT,
      data: id
    });
  };
};
