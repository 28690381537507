import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { signIn, signOut, checkLoa } from 'oidc/initiateOidc';
import { checkoutAgora, checkoutDavinci, agentRedeem } from 'actions/rewards';
import { useDispatch } from 'react-redux';
import { exclusiveToTiers } from 'utils/rewards/exclusiveToTiers';
import { setProduct } from "../../utils/analytics";
import { addLinkActionEvent } from 'utils/analytics';
import useMediaQuery from 'utils/hooks/useMediaQuery';
import * as Analytics from 'utils/analytics';

import {
  Grid, Column, Surface, Spacing, TextStyle, ActionButton,
  ConfirmationDialog, Divider, MessageInline, MessageSection, Icon, InlineLink
} from '@able/react';
import { Breadcrumbs } from 'components';
import { HtmlConverter } from 'utils/HtmlConverter';
import RewardsSlider from './RewardsSlider/RewardsSlider';
import RewardsColourSelector from './RewardsColourSelector/RewardsColourSelector';
import RewardsVariationSelector from './RewardsVariationSelector/RewardsVariationSelector';

import useSelectedProduct from './RewardsProductSelector/use-selected-product';

import './RewardsProductView.scss';
import { getProductDeliveryNotification, DeliveryDialog } from 'components/ProductDeliveryMessage/ProductDeliveryMessage';
import { restrictedToTiers } from 'utils/rewards/restrictedToTiers';
import ProductNotifications from 'components/ProductNotifications/ProductNotifications';
import { ASSET_ATTRIBUTES as assetAttributes } from 'constants/productAssetAttributes.js';
import InformationDialog from 'components/InformationDialog';
import { LIMITED_AUTHORITY } from 'constants/customer';
import { RS_META_TITLE, SESSION_STORAGE_SOURCE } from 'constants/rewardsStore';
import { Helmet } from 'react-helmet';
import Skeleton from 'components/Skeleton';
import { ProductFamilyType } from 'constants/productFamilyType';
import {
  isSpecsAvailable, roundDollarValue, getEligibleRedemption, getEligibilityErrorMessage,
  bypassRedemptionCheck, renderDescriptionDetail, renderLAmessage, renderMembershipMessage,
  isVasEligibleForRedemption, renderWhatYouPaySection, renderSpecsAndFeatures, renderTierLozenge
} from './RewardsProductViewUtils/RewardsProductViewUtils';


const RewardsProductView = props => {
  const { product, isAuthenticated, loaStatus, productFamilies } = props;
  const { loyaltyTier, pointsValue } = useSelector(state => state.accounts);
  const { profile } = useSelector(state => state.rewards);
  const selectedLoyaltyAccDetails = useSelector(state => state.rewards.profile.loyaltyAccount);
  const enrollmentStatus = useSelector(state => state.accounts.enrollmentStatus);
  const contactType = useSelector(state => state.accounts.role);
  const promos = useSelector(state => state.rewards.productView.product.promos);
  const subscriptionServiceAvailable = selectedLoyaltyAccDetails?.subscriptionServiceAvailable;
  const dispatch = useDispatch();

  const [verificationDialogOpen, setVerificationDialogOpen] = useState(false);
  const [isRedeemable, setIsRedeemable] = useState(false);
  const [isAgentRedeemable, setIsAgentRedeemable] = useState(false);
  const [loyaltyAccount, setLoyaltyAccount] = useState(null);
  const [eligibilityErrorMessage, setEligibilityErrorMessage] = useState(null);

  //notification related
  const [productNotifications, setProductNotifications] = useState([]);
  const [deliveryNotification, setDeliveryNotification] = useState(null);
  const [noProductNotification, setNoProductNotification] = useState(null);
  const [mfaNotification, setMFANotification] = useState(null);

  const [showDeliveryDialog, setShowDeliveryDialog] = useState(false);
  const [showMFADialog, setShowMFADialog] = useState(false);
  const [viewPortSM, setViewPortSM] = useState(2);
  const [externalProductURL, setExternalProductURL] = useState(null);

  let hasBlueTick = false;
  const [{ isValidForSegment, selectedProduct, selectedProductFound, selectedProductError, arrAttributes,
    arrAttributesAll, payTypeArr, minPrice, selectedPrice, selectedOriginalPrice,
    selectedPayType, selectedRepaymentOption, selectedPriceOptionProgress, selectedPromotion,
    highestPoints, originalHighestPoints, postageMessage, showDeliveryInformation, normalDelivery,
    defaultIndexes, outOfStock, onePlaceIneligibleNotice, stockNotification, creditsNotification,
    otherNotifications, setCriteria, setPriceOption, setPayType, setRepaymentOption, setPromotion }] = useSelectedProduct({ product, isAuthenticated });

  const isMobileView = useMediaQuery('(max-width: 780px)');

  const { name, images, currentPrice, repaymentOptions, hideOriginalPrice, attributes,
    sku, stockNotice, descriptionDetail, tags } = selectedProduct;

  const { DIGITAL_CODE, PREPAID_VOUCHER, DIGITAL_PROMO, MOBILE_PLAN, DATA_PLAN, MBB_PLAN } = ProductFamilyType;

  const { type: productType, brandName } = productFamilies?.length > 0 && productFamilies[0];

  const [currentFullImage, setCurrentFullImage] = useState(images[0]);
  const [productName, setProductName] = useState(name);
  const [validRepaymentOptions, setValidRepaymentOptions] = useState([]);
  const [eligiblePromo, setEligiblePromo] = useState(null);
  const { priceOptions } = currentPrice;
  const exclusiveTiers = exclusiveToTiers(attributes);
  const restrictedTiers = restrictedToTiers(attributes);

  // TODO - use new flag from BE
  const isHotOffer = product?.specialOffer;
  const [isPromoPricing, setIsPromoPricing] = useState(false)
  const [promotionList, setPromotionList] = useState([])

  const checkProductElegibility = (eligibleRedemption) => {
    if (!bypassRedemptionCheck(productFamilies, selectedProduct)) {

      if (eligibleRedemption &&
        eligibleRedemption.length > 0 &&
        eligibleRedemption.filter(redemption => redemption.eligibleRedemption).length > 0 &&
        (selectedProduct.sku === assetAttributes.WIFI_BOOSTER_OUTRIGHT ||
          //eligibleRedemption that is true with product that matches the selected product.
          eligibleRedemption.filter(redemption => redemption.eligibleRedemption).filter(redemption => redemption.products.filter(product => product.identity === selectedProduct.sku).length > 0).length > 0)) {
        return true;
      }

      else {
        setEligibilityErrorMessage(getEligibilityErrorMessage(eligibleRedemption, selectedProduct, assetAttributes));
        return false;
      }
    }
    return true;
  }

  const loaStepUp = () => {
    return signIn({
      url: window.location.pathname,
      loaStepUp: true
    });
  };

  const handleCheckout = () => {
    selectedPrice.ongoingDuration = (selectedRepaymentOption && selectedPayType !== 'outright') ? selectedRepaymentOption : 0;
    if (subscriptionServiceAvailable && selectedPayType !== 'outright') {
      return dispatch(checkoutDavinci(selectedProduct, selectedPrice, selectedOriginalPrice));
    }
    return dispatch(checkoutAgora(productFamilies[0], selectedProduct, selectedPrice));
  };

  const handleRedeem = () => {
    selectedPrice.ongoingDuration = (selectedRepaymentOption && selectedPayType !== 'outright') ? selectedRepaymentOption : 0;
    return dispatch(agentRedeem(productFamilies[0], selectedProduct, selectedPrice));
  };

  const selectImage = (e) => {
    setCurrentFullImage(images[e]);
  };

  // Render content for image column
  const renderImageContent = () => {
    if (selectedProductFound) {
      return (
        <>
          {isMobileView && renderTitleAndTags()}
          <Surface variant="SurfaceFlat">
            <Spacing
              top="spacing1x"
              bottom="spacing1x"
              left="spacing1x"
              right="spacing1x"
            >
              <div className="img-container">
                <div className="swiper-image-wrapper">
                  <img src={currentFullImage ? currentFullImage : images[0]} alt={""} />
                </div>
                <div className="img-carousel-container">
                  {images.length > 1 ? (
                    images.slice(0, 4).map((image, index) => <img className="swiper-slide" src={image} alt={name} onClick={e => selectImage(index)} />)
                  ) : null}
                </div>
              </div>
            </Spacing>
          </Surface>
          {isMobileView && descriptionDetail && renderDescriptionDetail(productType, descriptionDetail)}
        </>
      );
    }
    return (
      <Skeleton
        className="image-col-placeholder"
        size={{ width: '50%', height: '50%' }}
      />
    );
  };

  useEffect(() => {
    setProductName(selectedProductError ? productFamilies[0]?.name : name);
  }, [selectedProductError, name])

  useEffect(() => {
    const promoPricing = selectedProduct?.promoPrices?.find(promoPrice => promoPrice.promotion === eligiblePromo?.id);
    if (eligiblePromo && promoPricing) {
      setIsPromoPricing(true)
      setPromotion(promoPricing.promotion)
    } else {
      setIsPromoPricing(false)
      setPromotion(null)
    }
  }, [eligiblePromo])

  useEffect(() => {
    setEligiblePromo(promos?.find(promo => promo.eligibility.loyaltyTier?.includes(loyaltyTier) && promo.eligibility.device.includes(sku)))
  }, [promos, selectedProduct, loyaltyTier])

  useEffect(() => {
    const validRepaymentOptions = repaymentOptions.filter((opt) => opt != 0 && selectedPrice?.ongoingPrice / opt >= 10).map((opt) => {
      return {
        option: opt,
        monthlyValue: roundDollarValue(selectedPrice?.ongoingPrice / opt, 2)
      }
    })
    if (validRepaymentOptions.length == 0 && selectedPayType != "repayment") {
      setPayType("outright")
    }

    setValidRepaymentOptions(validRepaymentOptions)
  }, [repaymentOptions, selectedPrice])

  useEffect(() => {
    let eligibleRedemptions = getEligibleRedemption(loyaltyAccount, selectedProduct, assetAttributes);
    // getEligibleRedemption(eligibleRedemptions);
    checkProductElegibility(eligibleRedemptions);
    if (selectedProduct?.attributes) {
      selectedProduct.attributes.forEach(attribute => {
        if (attribute.name.toLowerCase() === "external product") {
          setExternalProductURL(attribute.value);
        }
      })
    }
  }, [selectedProduct])

  useEffect(() => {
    if (profile.loyaltyAccount) {
      setLoyaltyAccount(profile.loyaltyAccount);
    } else {
      setLoyaltyAccount(null);
    }

  }, [profile])

  useEffect(() => {
    switch (loaStatus) {
      case 'success':
        handleCheckout();
        break;
      case 'failed':
      case 'denied':
        setVerificationDialogOpen(true);
        break;
      default:
    }
  }, [loaStatus]);

  useEffect(() => {
    if (selectedProduct.name) {
      setProduct(selectedProduct, selectedPrice, loyaltyTier);
      Analytics.setPageName(productFamilies[0]?.name);
      addLinkActionEvent('productView');
    }
  }, [selectedProduct, selectedPrice, loyaltyTier]);

  useEffect(() => {
    if (window.innerWidth < 360) {
      setViewPortSM(1)
    }
  }, []);

  useEffect(() => {
    const { upfrontPrice } = selectedPrice || {};
    const hasEnoughPoints = pointsValue > upfrontPrice;
    const isVasEligible = isVasEligibleForRedemption(selectedProduct, selectedLoyaltyAccDetails, assetAttributes);
    const getIsRedeemable = () => {
      if (!outOfStock && hasEnoughPoints && !selectedProductError && enrollmentStatus === 'ENROLLED' && contactType !== 'LIMITED AUTHORITY' && isVasEligible) {
        if (exclusiveTiers.length > 0) {
          if (exclusiveTiers.some(e => e === loyaltyTier)) {
            return true;
          }
          return false;
        }
        return true;
      }
      return false;
    }
    // ARP changes to enable the redeem button
    const getIsAgentRedeemable = () => {
      const source = sessionStorage.getItem(SESSION_STORAGE_SOURCE);
      if (!selectedProductError && source === "agent_console") {
        return true;
      }
      return false;
    }
    setIsRedeemable(getIsRedeemable());
    setIsAgentRedeemable(getIsAgentRedeemable());

  }, [loyaltyTier, pointsValue, selectedPrice, outOfStock]);

  useEffect(() => {
    let newNotificationsList = [];

    if (otherNotifications) {
      otherNotifications.forEach(n => newNotificationsList.push(n));
    }

    if (deliveryNotification) {
      newNotificationsList.push(deliveryNotification)
    }

    if (creditsNotification && !noProductNotification) {
      newNotificationsList.push(creditsNotification)
    }

    if (stockNotification) {
      newNotificationsList.push(stockNotification)
    }

    if (noProductNotification) {
      newNotificationsList.push(noProductNotification)
    }

    if (mfaNotification) {
      newNotificationsList.push(mfaNotification)
    }

    setProductNotifications(newNotificationsList);

  }, [deliveryNotification, creditsNotification, otherNotifications, stockNotification, noProductNotification, mfaNotification]);

  useEffect(() => {
    const showMailMessage = [DIGITAL_CODE, PREPAID_VOUCHER].includes(productType) ? true : false;

    DIGITAL_PROMO !== productType ? setDeliveryNotification(getProductDeliveryNotification(showDeliveryInformation, postageMessage, normalDelivery, setShowDeliveryDialog, showMailMessage)) : setDeliveryNotification(null);
  }, [showDeliveryInformation, postageMessage, normalDelivery, productType])

  useEffect(() => {

    if (selectedProductError) {
      setNoProductNotification({
        label: "No matching product found.",
        description: "Please try another combination.",
        priority: -1000,
        type: "error"
      })
    } else {
      setNoProductNotification(null)
    }
  }, [selectedProductError])

  useEffect(() => {
    if (externalProductURL) {
      setMFANotification(null)
    } else {
      setMFANotification({
        label: "We've enhanced your security in the Rewards Store",
        description: "What is two-step verification",
        type: "membership",
        events: { onClick: () => setShowMFADialog(true) }
      })
    }
  }, [sku]);

  useEffect(() => {
    const promotions = tags ? getPromosByDescription(tags, "promotionType") : [];
    setPromotionList(promotions);
  }, [tags]);

  const getPromosByDescription = (tags, description) => {
    return tags.filter((tag) => tag?.description?.toLowerCase() === description.toLowerCase())
  }

  const renderRedeemButton = () => {
    if (isAuthenticated) {
      const loaLevel = checkLoa();
      if (isRedeemable) {
        if (externalProductURL && !onePlaceIneligibleNotice) {
          return (
            <ActionButton
              variant="HighEmphasis"
              element="button"
              label="Proceed to Redeem"
              onClick={() => { window.location.href = externalProductURL; }}
            />
          );
        }
        else if (onePlaceIneligibleNotice) {
          return null;
        }
        else if (loaLevel === 'LOA2') {
          return (
            <ActionButton
              variant="HighEmphasis"
              element="button"
              label="Checkout"
              onClick={() => handleCheckout()}
            />
          );
        }
        return (
          <ActionButton
            variant="HighEmphasis"
            element="button"
            style={{ fontSize: 20 + 'px' }}
            label="Checkout"
            onClick={() => loaStepUp()}
          />
        );
      }
      return null;
    }
    return (isAgentRedeemable ?
      <ActionButton
        variant="HighEmphasis"
        element="button"
        label="Redeem"
        onClick={() => handleRedeem()}
      /> : <ActionButton
        variant="LowEmphasis"
        element="button"
        label="Sign in with your Telstra ID"
        onClick={() => signIn()}
      />
    );
  };

  const renderPromotionsStack = () => {
    const promotionListContent = promotionList.map((val, index) => {
      const [promoName, promoDescription, promoURL] = val.name.split("|");
      return (
        <Spacing
          top="spacing3x"
          bottom={promotionList.length >= 1 && index === promotionList.length - 1 ? "spacing3x" : ""}
        >
          <Surface
            variant="SurfaceFlat"
            background="materialBaseGrey"
            className="align-left"
          >
            <Spacing
              top="spacing2x"
              right="spacing2x"
              bottom="spacing2x"
              left="spacing2x"
            >
              <TextStyle
                alias="Label"
                colour="Default"
                alignment="Left"
                className="promo-text-colour"
              >
                <Icon
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  icon="Deal"
                  screenReaderContent="icon deal"
                  className="icon-style"
                /> Offer: {promoDescription}
              </TextStyle>
              {promoURL &&
                <Spacing top="spacingHalf">
                  <InlineLink>
                    <TextStyle alias="FinePrint padding-left" element="p">
                      When you redeem this product. <a href={`https://${promoURL}`} target="_blank">See offer terms and conditions</a>
                    </TextStyle>
                  </InlineLink>
                </Spacing>}
            </Spacing>
          </Surface>
        </Spacing>
      );
    });
    return (
      <>
        <div>
          {promotionListContent}
        </div>
      </>
    );
  }

  const renderTitleAndTags = () => {
    const productAttrObject = selectedProduct && selectedProduct.attributes && selectedProduct.attributes.filter(attr => attr.value.includes('<>') && attr.name === "Product Tags");
    const blueTickAttribute = selectedProduct && selectedProduct.attributes && selectedProduct.attributes.filter(attr => attr.name === "Telstra Blue Tick" && attr.value.toLowerCase() === "yes");
    if (blueTickAttribute && blueTickAttribute.length !== 0) {
      hasBlueTick = true;
    }
    const displayProductTags = productAttrObject && productAttrObject[0] && productAttrObject[0].value.split("<>").map((val) => {
      return (
        <li>
          <div className="product-tag">
            <span>{val}</span>
          </div>
        </li>
      );
    })
    const isTagsAvailable = productAttrObject?.length > 0 || hasBlueTick;
    return (<>
      <TextStyle alias="HeadingC" element="h4" alignment="Left" className='brand-name'>
        {brandName}
      </TextStyle>
      {(name || productName) && (
        <TextStyle alias="HeadingA" element="h3" alignment="Left" className='product-name-section'>
          {selectedProductFound}{name ? name : productName}
        </TextStyle>
      )}
      { promotionList && promotionList.length > 0 && renderPromotionsStack()}
      {!isMobileView && descriptionDetail && renderDescriptionDetail(productType, descriptionDetail)}
      {isTagsAvailable &&
        <ul className="product-tags-section">
          {displayProductTags}
          {hasBlueTick && (<li className="tick-icon-style">
            <img src="https://www.telstra.com.au/etc/designs/explore/assets/BlueTick.png"
              alt="Blue Tick approval for best possible voice coverage in rural areas"></img>
          </li>)}
        </ul>
      }
      {renderTierLozenge(isTagsAvailable, exclusiveTiers)}
    </>)
  }

  // Render content for main column
  const renderContent = () => {
    if (selectedProductFound) {
      return (
        <>
          {!isMobileView && renderTitleAndTags()}

          {(!isMobileView && Object.keys(arrAttributes).length > 0) && <Divider />}

          {Object.keys(arrAttributes).length > 0 &&
            Object.keys(arrAttributes).map((key, i) => {
              if (key.toLowerCase() === "colour") {
                return (
                  <RewardsColourSelector
                    colours={arrAttributes[key]}
                    coloursRefs={arrAttributesAll.ColourImage ? arrAttributesAll.ColourImage : null}
                    onSelect={(colour) => { 
                      setCriteria(arrAttributes[key].find(obj => obj.value === colour.value), colour.name) 
                    }}
                    outOfStock={outOfStock}
                    selected={defaultIndexes[key] !== undefined ? defaultIndexes[key] : 0}
                  />)
              }
              return null;
            })
          }

          {outOfStock && <Spacing bottom="spacing2x" />}

          {isSpecsAvailable(arrAttributes) && <Divider style={isMobileView ? { width: '100vw', margin: '0px', marginLeft: "-37px" } : {}} />}

          {isSpecsAvailable(arrAttributes) &&

            <Spacing top="spacing4x" bottom="spacing1x">
              <div className="capacity-heading">
                <TextStyle alias="HeadingC" id="heading-label" element="h2" alignment="Left">
                  Choose your specifications
                </TextStyle>
              </div>

              {Object.keys(arrAttributes).length > 0 &&
                Object.keys(arrAttributes).map((key, i) => {
                  if (key.toLowerCase() === "colour" || key.toLowerCase() === "colourimage") {
                    return null;
                  }

                  return (
                    <RewardsVariationSelector
                      variations={arrAttributes[key]}
                      onSelect={(variation) => {
                        setCriteria(arrAttributes[key].find(obj => obj.value === variation.value), variation.name)
                      }}
                      selected={defaultIndexes[key] !== undefined ? defaultIndexes[key] : 0}
                      spacingTop="spacing0x"
                      fillRowIfLess={{
                        viewportSm: true
                      }}
                      ariaLabel={arrAttributes[key][0].name.toLowerCase()}
                    />
                  )
                })}
            </Spacing>
          }

          {((originalHighestPoints > 0 || isPromoPricing) && !selectedProductError) && (
            <TextStyle
              className="original-points"
              alias="FinePrint"
              element="p"
              alignment="Left"
            >
              {Intl.NumberFormat().format(isPromoPricing ? selectedProduct.currentPrice.priceOptions.slice(-1)[0].upfrontPrice : originalHighestPoints)} points
            </TextStyle>
          )}

          {(!isNaN(highestPoints) && !selectedProductError) && (
            <>
              <div className="highest-points" role="status">
                <TextStyle
                  className={`current-points ${isHotOffer && 'hot-offer'}`}
                  alias={isHotOffer ? 'HeadingD' : 'Base'}
                  element="p"
                  alignment="Left"
                >
                  {Intl.NumberFormat().format(isPromoPricing ? selectedPromotion.priceOptions.slice(-1)[0].upfrontPrice : highestPoints)} points
                </TextStyle>

              </div>
              <Spacing bottom="spacing4x" />
            </>
          )}

          {(stockNotice && !onePlaceIneligibleNotice) && (
            <>
              <MessageInline
                description={HtmlConverter(stockNotice)}
                variant="Information"
                developmentUrl="/able-sprites.svg"
              />
              <Spacing bottom="spacing4x" />
            </>
          )}

          {onePlaceIneligibleNotice && (
            <>
              <MessageInline
                description={onePlaceIneligibleNotice}
                variant="Information"
                developmentUrl="/able-sprites.svg"
              />
              <Spacing bottom="spacing4x" />
            </>
          )}

          {(!isValidForSegment && sessionStorage.getItem(SESSION_STORAGE_SOURCE) !== "agent_console") &&
          (
            <>
              <MessageSection
                titleText='This reward is currently unavailable for your account type'
                description='In the meantime, please continue browsing other rewards.'
                variant='Warning'
                developmentUrl='/able-sprites.svg'
                />
              <Spacing bottom="spacing4x" />
            </>
          )}

          {((!eligibilityErrorMessage && !outOfStock) || sessionStorage.getItem(SESSION_STORAGE_SOURCE) === "agent_console") && priceOptions.length > 1 && (
            <>
              <RewardsSlider
                isPromoPricing={isPromoPricing}
                isHotOffer={isHotOffer}
                selectedPrice={selectedPrice}
                hideOriginalPrice={hideOriginalPrice}
                selectedOriginalPrice={selectedOriginalPrice}
                minPrice={isPromoPricing ? selectedPromotion.priceOptions[0] : minPrice}
                highestPoints={isPromoPricing ? selectedPromotion.priceOptions.slice(-1)[0].upfrontPrice : highestPoints}
                forwardCallback={() => setPriceOption('incrementPriceOption')}
                backwardCallback={() => setPriceOption('decrementPriceOption')}
                selectedPriceOptionProgress={selectedPriceOptionProgress}
              />

              {validRepaymentOptions.length > 0 &&
                selectedPrice?.upfrontPrice !== highestPoints && (
                  <>
                    <div className="payment-method-section">
                      <TextStyle alias="HeadingD" className="pm-desc-label" element="h3" alignment="Left">
                        {`How would you like to pay $${selectedPrice?.ongoingPrice}?`}
                      </TextStyle>
                    </div>
                    <RewardsVariationSelector
                      showSelection={false}
                      buttonColumns={{ viewportSm: viewPortSM, viewportMd: 2, viewportLg: 2 }}
                      buttonSpacing={{ viewportSm: 16, viewportMd: 16, viewportLg: 24 }}
                      buttonHeight={76}
                      variations={
                        payTypeArr.map((payType) => {
                          return {
                            name: payType.value,
                            value: payType.label
                          }
                        })
                      }
                      onSelect={e => {
                        setPayType(e.name);
                      }}
                      ariaLabel="repayment option"
                    />
                    {selectedPayType === 'repayments' && (
                      <>
                        <div className="payment-method-section">
                          <TextStyle alias="HeadingD" className="pm-desc-label" element="h3" alignment="Left">
                            {"Select a repayment option"}
                          </TextStyle>
                        </div>

                        <RewardsVariationSelector
                          showSelection={false}
                          buttonColumns={{ viewportSm: 2, viewportMd: 2, viewportLg: 2 }}
                          buttonSpacing={{ viewportSm: 16, viewportMd: 16, viewportLg: 24 }}
                          buttonHeight={window.innerWidth < 360 ? 113 : 76}
                          variations={
                            validRepaymentOptions.map((opt) => {
                              return {
                                name: opt.option,
                                value: `${opt.option} months\n$${opt.monthlyValue} / month`
                              }
                            })
                          }
                          onSelect={e => {
                            setRepaymentOption(e)
                          }}
                          ariaLabel="repayment period"
                        />
                      </>
                    )}

                    <Divider />

                    {/* ARP changes to show message for repayment option */}
                    {(selectedPayType === "repayments" && sessionStorage.getItem(SESSION_STORAGE_SOURCE) === "agent_console") &&
                      <MessageInline
                        description={'There is no interest charged to buy a phone on repayment, but the member must continue to pay the device off in installments, if the member cancels thier payment options, they will need to pay the remaining cost of the device as a one-off payment at the time of cancellation or change'}
                        variant="Information"
                        developmentUrl="/able-sprites.svg"
                      />
                    }

                    {renderWhatYouPaySection(selectedPrice, selectedPayType, selectedRepaymentOption)}

                  </>
                )}
              {restrictedTiers.length > 0 &&
                <span>Eligible Customers Only</span>
              }
            </>
          )}

          {eligibilityErrorMessage && (
            <>
              <Surface variant="SurfaceMid">
                <Spacing
                  top="spacing2x"
                  bottom="spacing2x"
                  left="spacing2x"
                  right="spacing2x"
                >
                  {eligibilityErrorMessage}
                </Spacing>
              </Surface>
              <Spacing bottom="spacing2x" />
            </>
          )}

          {(loaStatus === 'failed' || loaStatus === 'denied') && (
            <>
              <ConfirmationDialog
                isShowing={verificationDialogOpen}
                setHideDialog={() => setVerificationDialogOpen(false)}
                title="Verification unsuccessful"
                description="You haven&#39;t completed the Two-step verification process. To retry, sign out of the Rewards Store, sign in with your Telstra ID and start your order again. Need to update your details? Go to My Telstra"
                stackButtonOnVXS
                confirmButtonLabel="Sign out"
                confirmButtonEvents={{ onClick: () => signOut() }}
                cancelButtonLabel="Continue browsing"
                developmentUrl="/able-sprites.svg"
              />
              <p>You haven&#39;t completed the Two-step verification process. To retry, sign out of the Rewards Store, sign in with your Telstra ID and start your order again. Need to update your details? Go to My Telstra</p>
            </>
          )}

          {(!isAuthenticated && sessionStorage.getItem(SESSION_STORAGE_SOURCE) !== "agent_console") && <> {renderMembershipMessage()} <Spacing bottom="spacing4x" /></>}

          {((!loaStatus || loaStatus === 'attempted') && !selectedProductError && (isValidForSegment || sessionStorage.getItem(SESSION_STORAGE_SOURCE) === "agent_console")) && (
            <>
              {contactType === LIMITED_AUTHORITY ? renderLAmessage() : renderRedeemButton()}
              <Spacing bottom="spacing4x" />
            </>
          )}

          <ProductNotifications notifications={productNotifications} />
          <DeliveryDialog isOpen={showDeliveryDialog} setIsOpen={setShowDeliveryDialog} />
          <InformationDialog
            isShowing={showMFADialog}
            confirmButtonLabel="Ok"
            setHideDialog={() => setShowMFADialog(false)}
          >
            <h2>What is Two-step verification?</h2>
            <p>Two-step verification is an extra layer of security to help confirm it's really you transacting on your account. We need to do this before you can continue your purchase. In addition to signing in with your Telstra ID, to verify you, we'll send a 6-digit code to your registered mobile number or email.</p>
            <br />
            <p>You can browse without verifying, however, you won't be able to complete your purchase. If you skip or fail, you'll need to exit the Rewards Store, login with your Telstra ID and start your order again.</p>
          </InformationDialog>
          <Spacing bottom="spacing10x" />
          <div className="tplus-tnc">View the <a id="product-tnc" target="_blank" href="https://www.telstra.com.au/plus/terms-and-conditions">Telstra Plus terms and conditions</a></div>
        </>
      );
    }

    return (
      <>
        <Skeleton />
        <Skeleton size={{ width: '100%', height: '200px' }} />
        <Skeleton size={{ width: '300px', height: '80px' }} />
      </>
    );
  };

  const renderRewardsAppSEO = () => {
    return (
      <Helmet>
        {RS_META_TITLE && <title>{RS_META_TITLE + ', ' + productName}</title>}
      </Helmet>
    );
  };

  const hasCollapsible = selectedProduct && selectedProduct.attributes && selectedProduct.attributes.filter(attr => attr.name === "Collapsible");
  const showWhatsIncluded = ((hasCollapsible && hasCollapsible.length === 1) || [DATA_PLAN, MBB_PLAN, MOBILE_PLAN, DIGITAL_CODE, DIGITAL_PROMO, PREPAID_VOUCHER].includes(productType));

  return (
    <div className='tplus-rewards-product-container'>
      {renderRewardsAppSEO()}
      <Grid className="tplus-rewards-product">
        <Column cols={12}>
          <Breadcrumbs customPathname="/rewards/explore" />
        </Column>
        <Column cols={12} bsm={12} bmd={6} blg={6} className="image-col">
          {renderImageContent()}
        </Column>
        <Column cols={12} bsm={12} bmd={6} blg={6}>
          <div className='bruh'>
            <Surface variant="SurfaceFlat">
              <Spacing
                top="spacing1x"
                bottom="spacing1x"
                left="spacing1x"
                right="spacing1x"
              >
                {renderContent()}
              </Spacing>
            </Surface>
          </div>
        </Column>
        {renderSpecsAndFeatures(isMobileView, productName, hasCollapsible, showWhatsIncluded, selectedProduct)}
        <Spacing bottom="spacing7x" />
      </Grid>
    </div>
  );
};

export default RewardsProductView;
