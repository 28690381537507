import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import convert from 'htmr';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import IconArrow from 'icons/IconArrow';
import alertSvg from 'assets/images/Alert-Small-Event-Child.svg';
import './EventSummary.scss';
import { canBuyTicket } from 'selectors/GetEventDetail';
import { DEFAULT_TIMZONE } from 'constants/general';
import * as Analytics from 'utils/analytics';
import { getGoldPresaleEndDate } from 'utils/GetGoldPresaleEndDate';
import { TextStyle } from '@able/react';
import { PresaleCountdown } from 'views/TicketsChildPages/PresaleCountdown/PresaleCountdown';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import parseZone from 'utils/parseZone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(parseZone);

const EventTile = ({ item }) => {
  const {
    id: itemId,
    performanceSaleStartDate,
    availability,
    eventPerformanceDate,
    eventPerformanceEndDate,
    venueName,
    team1Name,
    team2Name
  } = item;

  const history = useNavigate();
  const isAuthenticated = useSelector(state => state.appStatus.authenticated);
  const eventDetails = useSelector(state => state.events.childEvents.details);
  const tier = useSelector(state => state.accounts.loyaltyTier);
  const { genre, id } = useParams();

  const { tierPriorityDetails } = eventDetails;
  const startDate = dayjs(performanceSaleStartDate).tz(DEFAULT_TIMZONE);

  const preSaleEnded = useSelector(state => canBuyTicket(state, itemId));
  const error = availability === 'Exhausted' && preSaleEnded;
  const ctaError = availability === 'Exhausted' || !preSaleEnded;
  const [count, setCount] = useState(0);

  let preSaleEndTime = startDate;

  if (isAuthenticated && tier && tier !== 'GOLD') {
    preSaleEndTime = getGoldPresaleEndDate(tierPriorityDetails, startDate);
  }

  const buyTicket = performanceId => {
    Analytics.addEvent({
      eventName: `${isAuthenticated ? 'Auth' : 'Unauth'} Tickets - GetTickets`,
      eventAction: 'click',
      eventType: 'clickTrack',
      eventCategory: 'linkClick'
    });
    if (!ctaError) {
      history(`/tickets/buy/${genre}/${id}/${performanceId}`);
    }
  };

  /**
   *
   * @returns A string comprised of converted performance dates
   */
  const renderPerformanceDate = () => {
    const dateFormat = 'ddd D MMM YYYY h:mm A';
    if (eventPerformanceEndDate) {
      return `${dayjs
        .parseZone(eventPerformanceDate)
        .format(dateFormat)} to ${dayjs
        .parseZone(eventPerformanceEndDate)
        .format(dateFormat)}`;
    }
    return dayjs.parseZone(eventPerformanceDate).format(dateFormat);
  };

  /**
   *
   * @returns A react node rendering the arrow for our div/link
   */
  const renderArrow = () => {
    return ctaError ? (
      <button type="button" className={`primary-cta-18 ${ctaError} here`} aria-hidden="true" tabIndex="-1">
        <IconArrow />
      </button>
    ) : (
      <button type="button" className={`primary-cta-18 ${ctaError}`} aria-hidden="true" tabIndex="-1">
        <IconArrow />
      </button>
    );
  };

  const renderComponent = () => {
    setCount(Math.random());
  };

  useEffect(() => {
    // Refreshing Component when count getting updated.
  }, [count]);

  const preSaleEndedProps = {
    onClick: () => buyTicket(itemId),
    onKeyPress: () => buyTicket(itemId),
    role: 'link',
    tabIndex: 0
  };

  return (
    <div
      className={`event-tile ${ctaError}`}
      {...(preSaleEnded && { ...preSaleEndedProps })}
    >
      <span className="title">
        {team1Name !== '' && team1Name != null && team1Name !== '' && (
          <TextStyle alias="Label" element="span" className="team-name">
            {team1Name}&nbsp;vs&nbsp;{team2Name}
          </TextStyle>
        )}
        <TextStyle alias="Label" element="span">{convert(venueName)}</TextStyle>
        {preSaleEnded && renderArrow()}
      </span>
      <TextStyle alias="Label" element="span" className="date">{renderPerformanceDate()}</TextStyle>
      <TextStyle alias="Label" element="span" className={`availability ${error}`}>
        {error && (
          <img className="alert-icon" src={alertSvg} alt="An alert icon" />
        )}
        {!preSaleEnded ? (
          <PresaleCountdown renderComponent={renderComponent} preDate={preSaleEndTime} tier={tier} />
        ) : (
          `${availability}`
        )}
      </TextStyle>
    </div>
  );
};

EventTile.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    performanceSaleStartDate: PropTypes.string,
    availability: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    eventPerformanceDate: PropTypes.string,
    eventPerformanceEndDate: PropTypes.string,
    venueName: PropTypes.string,
    team1Name: PropTypes.string,
    team2Name: PropTypes.string
  }).isRequired
};

const EventSummary = props => {
  const { summaryData } = props;
  const summaryClass = clsx('event-Summary');

  summaryData.sort((a, b) => {
    const c = new Date(a.eventPerformanceDate);
    const d = new Date(b.eventPerformanceDate);
    return c - d;
  });

  return (
    <>
      <div className={summaryClass}>
        {summaryData &&
          summaryData.map((item, key) => <EventTile key={key} item={item} />)}
      </div>
    </>
  );
};

export default EventSummary;
