import React from 'react';
import PropTypes from 'prop-types';

import IconClose from 'icons/IconClose';
import IconArrow from 'icons/IconArrow';
import { TextStyle, IconButton, Spacing, ActionButton } from '@able/react';

const EmailChangeViewl = props => {
  const { toggleView } = props;

  return (
      <>
      <div>        
      <TextStyle element="h2" alias="HeadingB">
      You&apos;re changing your email address
      </TextStyle>
      </div>
       <Spacing bottom="spacing5x"/>
        <TextStyle element="p" alias="TextBody">
        You’re changing the email address we use to send you special offers. You
        can change your billing email address in My Account.
        </TextStyle>
      <Spacing bottom="spacing5x"/>
      <ActionButton
          rel="noopener noreferrer"
            variant="MediumEmphasis"
            label="Continue"
            element="button"
            aria-label="Continue"
            developmentUrl="/able-sprites.svg"
            onClick={() => toggleView({ modalType: 'sendemail', modalSwitch: true })}
        />
        </>
  );
};

export default EmailChangeViewl;

EmailChangeViewl.propTypes = {
  toggleModal: PropTypes.func.isRequired
};
