import React from 'react';
import { Breadcrumbs } from 'components';
import IconChevron from 'icons/IconChevron';
import { Grid, Spacing, Column, ActionButton, TextStyle } from '@able/react';
import { Helmet } from 'react-helmet';

import './OptOut.scss';

const OptOut = ({ handleOptOut }) => (
  <>
  <Helmet>
    <title>Confirmation of cancelling Telstra Plus membership</title>
  </Helmet>
  <Grid className="container">
    <Column className="opt-out page">
      <Breadcrumbs />
      <TextStyle alias="HeadingB" element="h2">
        Are you sure you want to leave?
      </TextStyle>
      <Spacing bottom="spacing4x" />

      <div className="blue-callout">
        <TextStyle alias="Base" element="p">
          If you just want to stop hearing from us, you can turn off email and
          SMS marketing communications in My Account.
        </TextStyle>
        <ActionButton
          element="a"
          variant="LowEmphasis"
          href="https://www.my.telstra.com.au/myaccount/home"
          label="Go to My Account"
        />
      </div>
      <Spacing bottom="spacing4x" />
      <TextStyle alias="Base" element="p">If you leave Telstra Plus:</TextStyle>

      <Spacing bottom="spacing2x" />

      <ul>
        <li>
          <IconChevron />
          <TextStyle alias="Base" element="p">You&#39;ll miss out on earning points</TextStyle>
        </li>
        <li>
          <IconChevron />
          <TextStyle alias="Base" element="p">
            You won&#39;t have access to membership benefits
          </TextStyle>
        </li>
        <li>
          <IconChevron />
          <TextStyle alias="Base" element="p">If you change your mind you can reactivate your account at any time,
          your points will be retained for 12 months.</TextStyle>
        </li>
        <li>
          <IconChevron />
          <TextStyle alias="Base" element="p">Any limited authority representatives may also lose their Telstra Plus
          membership.</TextStyle>
        </li>
      </ul>
      <Spacing bottom="spacing4x" />
      <ActionButton
          element="Button"
          variant="HighEmphasisDestructive"
          onClick={handleOptOut}
          label="Leave Telstra Plus"
        />
      <Spacing bottom="spacing7x" />
      <ActionButton
          element="Link"
          variant="LowEmphasis"
          to="/benefits"
          label="Cancel and remain a member"
        />
      <Spacing bottom="spacing10x" />
    </Column>
  </Grid>
  </>
);

export default OptOut;
