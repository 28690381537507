import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const getDataByPage = state => {
  const { authenticated } = state.appStatus;
  const page = window.location.pathname.substring(1);

  // Object with possible childpages from AEM, add new ones as needed
  const aemData = {
    config: {},
    promos: {},
    childpages: {},
    accordions: {},
    headers: {},
    carousels: {},
    general: {},
    episodes: {}
  };
  // small fix to solve duplicate keys when 'toggleattributes' is on page level and global level
  const generateRandkeys = globalData => {
    const globalCopy = {};
    if (globalData) {
      _.chain(globalData)
        .each((data, key) => {
          const newKey = !key.includes('_') ? `${key}_${uuidv4()}` : key;
          globalCopy[newKey] = data;
        })
        .value();
    }
    return globalCopy;
  };
  // Take in strings for page and auth state
  // go through each key in aemData and fill each tree from AE redux state as needed
  const hydrateAemData = (whichPage, authState) => {
    const hydratedAemData = _.each(aemData, (val, key) => {
      // authState is optional, we might have some page structures like Join which will only ever have 1 state
      const globalData = generateRandkeys(state.aem.all?.global?.[key]);
      if (authState) {
        const tempKey = {
          ...state.aem.all?.[whichPage]?.[authState]?.[key],
          ...globalData
        };
        return _.merge(val, tempKey);
      }
      const tempKey = {
        ...state.aem.all?.[whichPage]?.[key],
        ...globalData
      };
      return _.merge(val, tempKey);
    });
    return hydratedAemData;
  };

  //   Go through each needed path in the SPA to fill in AEM data
  switch (true) {
    // Find if path includes, to take in child pages like benefits/stan
    case page === '':
    case page === '/': {
      if (authenticated) {
        return hydrateAemData('landing', 'auth');
      }
      return hydrateAemData('landing', 'unauth');
    }
    case page.includes('ticketsgiveaway'): {
      if (authenticated) {
        return hydrateAemData('ticketsgiveaway', 'auth');
      }
      return hydrateAemData('ticketsgiveaway', 'unauth');
    }
    case page.includes('benefits'): {
      if (authenticated) {
        return hydrateAemData('benefits', 'auth');
      }
      return hydrateAemData('benefits', 'unauth');
    }
    case page.includes('points'): {
      if (authenticated) return hydrateAemData('points', 'auth');
      return hydrateAemData('points', 'unauth');
    }
    case page.includes('rewards'): {
      if (authenticated) return hydrateAemData('rewards', 'auth');
      return hydrateAemData('rewards', 'unauth');
    }
    case page.includes('tickets'): {
      if (authenticated) return hydrateAemData('tickets', 'auth');
      return hydrateAemData('tickets', 'unauth');
    }
    case page.includes('howitworks'): {
      if (authenticated) return hydrateAemData('howitworks', 'auth');
      return hydrateAemData('howitworks', 'unauth');
    }
    case page.includes('partners'): {
      if (authenticated) return hydrateAemData('partners', 'auth');
      return hydrateAemData('partners', 'unauth');
    }
    case page.includes('offers'): {
      if (authenticated) return hydrateAemData('offers', 'auth');
      return hydrateAemData('offers', 'unauth');
    }
    case page.includes('join'): {
      return hydrateAemData('join');
    }
    default:
      return aemData;
  }
};
