import React from 'react';
import { Column, TextStyle, Divider, MessageSection } from '@able/react';
import { isPlan } from 'utils/productHelper';
import { isEmpty, capitalize, toInteger } from 'lodash';
import { HtmlConverter } from 'utils/HtmlConverter';
import { AccordionGroup, Accordion } from 'components';
import { REDEMPTION_ELEGIBILITY_ERRORS as redemptionElegibilityErrors } from 'constants/redemptionElegibilityErrors.js';

// attributes exclude filter type & color
export const isSpecsAvailable = (attributes) => {
    return Object.values(attributes).flat()
        .filter(arr => arr['type'].toLowerCase() !== "filter" &&
            !['colour', 'colourimage'].includes(arr['name'].toLowerCase())).length > 0
}

export const roundDollarValue = (num, scale) => {
    if (!("" + num).includes("e")) {
        return +(Math.round(num + "e+" + scale) + "e-" + scale);
    } else {
        var arr = ("" + num).split("e");
        var sig = ""
        if (+arr[1] + scale > 0) {
            sig = "+";
        }
        var i = +arr[0] + "e" + sig + (+arr[1] + scale);
        var j = Math.round(i);
        var k = +(j + "e-" + scale);
        return k;
    }
}

export const isVasEligibleForRedemption = (selectedProduct, selectedLoyaltyAccDetails, assetAttributes) => {
    const doRedemptionCheck = selectedProduct.attributes?.find(attr => attr.name === 'Redemption Check');
    const redemptions = selectedLoyaltyAccDetails?.redemptions;
    let eligibleRedemptions = redemptions?.find(redemption => redemption.eligibleRedemption && redemption.products?.length > 0 && redemption.products[0].identity === selectedProduct.sku);
    if (isEmpty(eligibleRedemptions) && selectedProduct.sku === assetAttributes.WIFI_BOOSTER_OUTRIGHT) {
        eligibleRedemptions = redemptions?.find(redemption => !isEmpty(redemption.products) && redemption.products?.length > 0 && redemption.products[0].identity === assetAttributes.WIFI_BOOSTER_CODE);
    }

    if (doRedemptionCheck?.value) {
        if (eligibleRedemptions?.eligibleRedemption || (eligibleRedemptions?.length > 1 && eligibleRedemptions.find(redemption => redemption.eligibleRedemption))
            && (selectedProduct.sku === assetAttributes.WIFI_BOOSTER_OUTRIGHT || selectedProduct.sku === assetAttributes.WIFI_BOOSTER_CODE || selectedProduct.sku === assetAttributes.GO_BOOSTER_CODE)) {
            return true;
        }
        else {
            return false;
        }
    }
    return true;
};

export const getEligibleRedemption = (loyaltyAccount, selectedProduct, assetAttributes) => {
    let eligibleRedemption = [];
    if (loyaltyAccount && loyaltyAccount.redemptions && loyaltyAccount.redemptions.length > 0) {
        eligibleRedemption = loyaltyAccount.redemptions.filter(redemption => redemption.products && redemption.products.filter(product => product.identity === selectedProduct.sku).length > 0);

        if (eligibleRedemption.length === 0 && selectedProduct.sku === assetAttributes.WIFI_BOOSTER_OUTRIGHT) {
            eligibleRedemption = loyaltyAccount.redemptions.filter(redemption => redemption.products && redemption.products.filter(product => product.identity === assetAttributes.WIFI_BOOSTER_CODE).length > 0);
        }

        if (eligibleRedemption.length === 0) {
            eligibleRedemption = loyaltyAccount.redemptions.filter(redemption => redemption.eligibilities !== null);
        }

    }
    return eligibleRedemption;
}

export const getEligibilityErrorMessage = (eligibleRedemption, selectedProduct, assetAttributes) => {
    let eligibilityMessage = null;
    if (false) {
        //TODO: Implement logic for when product is available on another CAC.
    } else if (eligibleRedemption && eligibleRedemption.filter(eligibility => !eligibility.eligibilityRedemption).length === 1) {
        switch (eligibleRedemption[0].reasonCode) {
            case "SERVICE_NOT_ELIGIBLE":
                eligibilityMessage = selectedProduct.sku === assetAttributes.GO_BOOSTER_CODE ? redemptionElegibilityErrors.GO_SERVICE_NOT_ELIGIBLE : redemptionElegibilityErrors.SERVICE_NOT_ELIGIBLE;
                break;
            case "SERVICE_INELIGIBLE":
                eligibilityMessage = selectedProduct.sku === assetAttributes.GO_BOOSTER_CODE ? redemptionElegibilityErrors.GO_SERVICE_NOT_ELIGIBLE : redemptionElegibilityErrors.SERVICE_NOT_ELIGIBLE;
                break;
            case "SERVICE_CALL_FAILED":
                eligibilityMessage = redemptionElegibilityErrors.SERVICE_CALL_FAILED;
                break;
            case "ALREADY_SUBSCRIBED":
                eligibilityMessage = selectedProduct.sku === assetAttributes.GO_BOOSTER_CODE ? redemptionElegibilityErrors.GO_ALREADY_SUBSCRIBED : redemptionElegibilityErrors.ALREADY_SUBSCRIBED;
                break;
            case "PENDING_ORDER_FOUND":
                eligibilityMessage = redemptionElegibilityErrors.PENDING_ORDER_FOUND;
                break;
            case "MULTIPLE_SERVICES_FOUND":
                eligibilityMessage = redemptionElegibilityErrors.MULTIPLE_SERVICES_FOUND;
                break;
            default:
                eligibilityMessage = selectedProduct.sku === assetAttributes.GO_BOOSTER_CODE ? redemptionElegibilityErrors.GO_SERVICE_NOT_ELIGIBLE : redemptionElegibilityErrors.SERVICE_NOT_ELIGIBLE;
                break;
        }
    } else if (eligibleRedemption && eligibleRedemption.filter(eligibility => !eligibility.eligibilityRedemption).length > 1) {
        eligibilityMessage = selectedProduct.attributes.find(attribute => attribute.name === "Service Ineligible Copy").value.split("|")[1];
    } else {
        eligibilityMessage = selectedProduct.sku === assetAttributes.GO_BOOSTER_CODE ? redemptionElegibilityErrors.GO_SERVICE_NOT_ELIGIBLE : redemptionElegibilityErrors.SERVICE_NOT_ELIGIBLE;
    }
    return eligibilityMessage;
}

export const bypassRedemptionCheck = (productFamilies, selectedProduct) => {
    if (productFamilies && productFamilies.length > 0 && productFamilies[0].doRedemptionCheck === true) {
        return false
    }
    if (selectedProduct) {
        if (selectedProduct.doRedemptionCheck === true) {
            return false
        }
        if (selectedProduct.attributes) {
            return !selectedProduct.attributes.some(attribute => attribute.name === "Redemption Check" && attribute.value === "true")
        }
    }
    return true
}

export const renderDescriptionDetail = (productType, descriptionDetail) => {
    if (isPlan(productType)) {
        return <>
            <TextStyle alias="TextBody" element="p" alignment="Left" className='description-detail'>
                {descriptionDetail.split('.')[0]}
            </TextStyle>
            <TextStyle alias="FinePrint" element="p" alignment="Left" className='description-detail'>
                {descriptionDetail.split('.').slice(1).join('.')}
            </TextStyle>
        </>
    } else if (!isPlan(productType)) {
        return <>
            <TextStyle alias="TextBody" element="p" alignment="Left" className='description-detail'>
                {descriptionDetail.split('\n')[0]}
            </TextStyle>
            <TextStyle alias="FinePrint" element="p" alignment="Left" className='description-detail'>
                {descriptionDetail.split('\n').slice(1).join('\n')}
            </TextStyle>
        </>
    }
}

export const renderLAmessage = () => {
    return <MessageSection
        description="As you are not the account holder or a full authority representative, you cannot redeem rewards."
        variant="Information"
        developmentUrl="/able-sprites.svg"
        actionLink="https://www.telstra.com.au/support/category/account-billing/manage-account/authorisation-verification-and-access-to-telstra-accounts"
        actionText="Learn more"
    />
}

export const renderMembershipMessage = () => {
    return <MessageSection
        description="You need a Telstra Plus membership to redeem rewards."
        variant="Information"
        developmentUrl="/able-sprites.svg"
        actionLink="https://telstra.com/plus"
        actionText="Find out more"
    />
}

export const renderWhatYouPaySection = (selectedPrice, selectedPayType, selectedRepaymentOption) => {
    return <div className="what-you-pay-section">
        <TextStyle alias="HeadingC" className="label-heading" element="h3" alignment="Left">
            What you'll pay
        </TextStyle>
        <div className="what-you-pay-details">
            <div className="points-upfront">
                <div className="upfront-label">
                    Points upfront:
                </div>
                <div className="points-upfront-value">
                    {selectedPrice?.upfrontPrice}
                    <TextStyle alias="BaseBig" element="span" style={{ paddingLeft: "5px" }}>
                        pts
                    </TextStyle>
                </div>
            </div>
            {selectedPayType === 'repayments' ?
                <div className="money-upfront">
                    <div className="upfront-label">
                        Money per month:
                    </div>
                    <div className="money-upfront-value adjust-padding">
                        ${roundDollarValue(selectedPrice?.ongoingPrice / selectedRepaymentOption,2)}
                    </div>
                </div> : <div className="money-upfront">
                    <div className="upfront-label">
                        Money upfront:
                    </div>
                    <div className="money-upfront-value">
                        ${selectedPrice?.ongoingPrice}
                    </div>
                </div>}
        </div>
    </div>
}

export const renderSpecsAndFeatures = (isMobileView, productName, hasCollapsible, showWhatsIncluded, selectedProduct) => {
    return <Column cols={12}>
        {isMobileView && <Divider style={{ width: '100vw', margin: '0px', marginLeft: "-27px" }} />}
        {(productName) && (
            <TextStyle alias="HeadingD" element="h3" alignment="Left" className='specs-heading'>
                All about the {productName}.
            </TextStyle>
        )}
        {(hasCollapsible !== undefined && !showWhatsIncluded) && (
            <AccordionGroup stayOpen={true}>
                {selectedProduct?.features && <Accordion id="features" heading="Product features">
                    {HtmlConverter(selectedProduct?.features)}
                </Accordion>}
                {selectedProduct?.specifications && <Accordion id="specifications" heading="Specifications">
                    {HtmlConverter(selectedProduct?.specifications)}
                </Accordion>}
            </AccordionGroup>
        )}
        {(hasCollapsible !== undefined && showWhatsIncluded) && (
            <AccordionGroup stayOpen={true}>
                {selectedProduct?.features && <Accordion id="whats-included" heading="See what's included" expanded="true">
                    {HtmlConverter(selectedProduct?.features)}
                </Accordion>}
                {selectedProduct?.specifications && <Accordion id="tyntk" heading="Things you need to know">
                    {HtmlConverter(selectedProduct?.specifications)}
                </Accordion>}
            </AccordionGroup>
        )}
    </Column>
}

export const renderTierLozenge = (tagsAvailable, exclusiveTiers) => {
    return exclusiveTiers?.length > 0 ? exclusiveTiers.map((tier) => {
        return <div className={`tplus-rewards-product-tile__lozenge ${tier.toLowerCase()}-exclusive`}
            style={tagsAvailable ? { marginTop: '-2em' } : { marginTop: '1em' }}>
            {`${capitalize(tier)} Exclusive`}
        </div>
    })
        : null;
}
