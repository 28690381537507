import axios from './util/axiosHandle';
import { apiConfig as api } from '../oidc/oidc-configs';

const getOtp = otpDetails => {
  return axios({
    params: {
      method: 'post',
      url: api.baseUrl + api.endpoints.getOtp,
      data: {
        data: {
          referenceId: otpDetails.referenceId,
          serviceType: otpDetails.serviceType,
          serviceId: otpDetails.serviceId
        }
      }
    }
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export default getOtp;
