import React from 'react';
import { HtmlConverter } from 'utils/HtmlConverter';
import { Cta } from 'components';
import { Spacing, TextStyle, Icon } from '@able/react';

// CSS
import './InstructionalBox.scss';

const InstructionalBoxV2 = props => {
  const {
    active,
    heading = '',
    id = '',
    tiles = {},
    mainHeadingLevel = 'h3'
  } = props;

  const Tile = ({
    stepTitle,
    stepBody,
    ableIconName,
    count,
    index,
    ctaAction,
    ctaVariant,
    ctaText,
    ctaUrl,
    ctaTarget,
    ctaTrackingCode
  }) => {
    return (
      <li className="col-instructional-tile">
        <div className="instructional-tiles-container">
          <div className={`stepper-container stepper-container--${count}`}>
            <Icon
              size="32"
              role="img"
              aria-label={`Step ${index + 1}`}
              developmentUrl="/able-sprites.svg"
              icon={ableIconName}
            />
            <div className="stepper-t-line">
              <span className="t-line"></span>
            </div>
          </div>
          <div className="tile-content">
            <TextStyle alias="HeadingD" element={mainHeadingLevel}>
              {stepTitle}
            </TextStyle>
            <Spacing bottom="spacing3x" />
            <TextStyle alias="Base" element="p">
              {HtmlConverter(stepBody)}
            </TextStyle>
            <Spacing bottom="spacing1x" />
            {ctaText && (
              <div className="cta-section">
                <Cta
                  action={ctaAction}
                  trackingCode={ctaTrackingCode}
                  url={ctaUrl}
                  ctaProps={{
                    variant: ctaVariant,
                    label: ctaText,
                    target: ctaTarget
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </li>
    );
  };

  return (
    <>
      {active && (
        <section className="tplus-instructional-tiles" id={id}>
          {heading && <h2 className="heading">{heading}</h2>}
          <ol className="tplus-instructional-tiles__list">
            {tiles && Object.keys(tiles).map((key, i) => {
              const count = Object.keys(tiles).length;
              return (
                <Tile
                  key={i}
                  {...tiles[key]}
                  count={count}
                  index={i}
                />
              )

            })}
          </ol>
        </section>
      )}
    </>
  );
};

export default InstructionalBoxV2;
