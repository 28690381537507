import {
  SET_SMB_PENDING,
  SET_SMB_SUCCESS,
  SET_SMB_FAILURE,
  SET_SMB_ENTRY_RESPONSE,
  SMB_ENTRY_DETAILS_PENDING,
  SET_SMB_ENTRY_ERROR
} from 'constants/actions';

const initialSmb = {
  status: false,
  pending: false,
  activated: false,
  id: 0,
  error: null,
  entry: null,
  entryDetailsPending: false,
  entryErrored: false
};

// eslint-disable-next-line consistent-return
export const smb = (state = initialSmb, action) => {
  switch (action.type) {
    case SET_SMB_PENDING:
      return {
        ...state,
        pending: action.payload
      };
    case SET_SMB_SUCCESS:
      return {
        ...state,
        status: action.status,
        activated: action.activated,
        id: action.id
      };
    case SET_SMB_FAILURE:
      return {
        ...state,
        status: action.status,
        activated: action.activated,
        id: action.id,
        error: action.errorObject
      };
    case SET_SMB_ENTRY_RESPONSE:
      if (action.entry) {
        return {
          ...state,
          entry: action.entry
        };
      }
      break;
    case SMB_ENTRY_DETAILS_PENDING:
      return {
        ...state,
        entryDetailsPending: action.payload
      };
    case SET_SMB_ENTRY_ERROR:
      return {
          ...state,
          entryErrored: action.entryErrored
        };
    default:
      return state;
  }
};

export const getSmbPending = state => state.smb.pending;
export const getSmbEntryDetailsPending = state => state.smb.entryDetailsPending;
