import {
  RECEIVE_UNENROL_USER,
  OPTOUT_RESULT_SUCCESS,
  OPTOUT_RESULT_NOT_ENROLLED,
  OPTOUT_RESULT_FAIL,
  SET_LOADING
} from 'constants/actions';

import OptOutApi from 'api/optOutApi';
import { LOGGER } from 'utils/AppLog';

export function receiveUnenrolAction(response) {
  return {
    type: RECEIVE_UNENROL_USER,
    unenrolResponse: response
  };
}

export default function unenrolUser() {
  return dispatch => {
    dispatch({ type: SET_LOADING, loading: true });

    return OptOutApi().then(response => {
      LOGGER.debug('Unenroll response JSON', response);

      // destructuring expected API response
      const {
        status = false,
        data: { data = {}, errors = [{}] }
      } = response;

      const { individual = {} } = data;
      const { loyaltyOptIn } = individual;

      if (loyaltyOptIn != null && loyaltyOptIn === false) {
        // correct response
        dispatch(
          receiveUnenrolAction({
            result: OPTOUT_RESULT_SUCCESS,
            errorMsg: null
          })
        );
        dispatch({ type: SET_LOADING, loading: false });
      } else if (status && status === 422) {
        const { message = null } = errors[0];

        dispatch(
          receiveUnenrolAction({
            result: OPTOUT_RESULT_NOT_ENROLLED,
            errorMsg: message
          })
        );
        dispatch({ type: SET_LOADING, loading: false });
      } else {
        const { message = null } = errors[0];

        dispatch(
          receiveUnenrolAction({
            result: OPTOUT_RESULT_FAIL,
            errorMsg: message
          })
        );
        dispatch({ type: SET_LOADING, loading: false });
      }
    });
  };
}
