// Initial state
export const selectedProductInitialState = {
  productsFromFamily: [],
  selectedProduct: {
    name: '',
    images: [],
    currentPrice: {
      priceOptions: []
    },
    repaymentOptions: []
  },
  selectedProductFound: false,
  selectedProductError: false,
  payTypeArr: [
    {
      label: 'Outright',
      value: 'outright'
    },
    {
      label: 'Repayments',
      value: 'repayments'
    }
  ],
  arrAttributes: {},
  arrAttributesAll: {},
  selectedCriteria: [],
  defaultIndexes: {},
  productFamilyImage: '',
  priceOption: { value: 0 },
  minPrice: 0,
  selectedPrice: {},
  selectedOriginalPrice: null,
  selectedPayType: 'outright',
  selectedRepaymentOption: null,
  selectedPriceOptionProgress: 'Start',
  highestPoints: 0,
  originalHighestPoints: 0,
  postageMessage: "A delivery fee of $5.95 or 2500 points may apply.",
  showDeliveryInformation: true,
  normalDelivery: true,
  selectedPromotion: null,
  outOfStock: false,
  onePlaceIneligibleNotice: null,
  stockNotification: null,
  creditsNotification: null,
  otherNotifications: [],
  isValidForSegment: true
};

// Reducer function to handle incoming actions
export const selectedProductReducer = (state, action) => {
  switch (action.type) {
    case 'productsFromFamily':
      return {
        ...state,
        productsFromFamily: action.payload
      };
    case 'selectedProduct':
      return {
        ...state,
        selectedProduct: action.payload
      };
    case 'selectedProductFound':
      return {
        ...state,
        selectedProductFound: action.payload
      };
    case 'selectedProductError':
      return {
        ...state,
        selectedProductError: action.payload
      };
    case 'arrAttributes':
      return {
        ...state,
        arrAttributes: action.payload
      };
    case 'arrAttributesAll':
      return {
        ...state,
        arrAttributesAll: action.payload
      };
    case 'selectedCriteria':
      return {
        ...state,
        selectedCriteria: action.payload
      };
    case 'defaultIndexes':
      return {
        ...state,
        defaultIndexes: action.payload
      };
    case 'incrementPriceOption':
      return {
        ...state,
        priceOption: { value: Math.abs(state.priceOption.value + 1) }
      };
    case 'decrementPriceOption':
      return {
        ...state,
        priceOption: { value: (state.priceOption.value - 1) < 0 ? 0 : state.priceOption.value - 1 }
      };
    case 'setPriceOption':
      return {
        ...state,
        priceOption: { value: action.payload }
      }
    case 'selectedRepaymentOption':
      return {
        ...state,
        selectedRepaymentOption: action.payload
      };
    case 'productFamilyImage':
      return {
        ...state,
        productFamilyImage: action.payload
      };
    case 'selectedPrice':
      return {
        ...state,
        selectedPrice: action.payload
      };
    case 'selectedOriginalPrice':
      return {
        ...state,
        selectedOriginalPrice: action.payload
      };
    case 'setMinPrice':
      return {
        ...state,
        minPrice: action.payload
      };
    case 'selectedPayType':
      return {
        ...state,
        selectedPayType: action.payload
      };
    case 'selectedPriceOptionProgress':
      return {
        ...state,
        selectedPriceOptionProgress: action.payload
      };
    case 'highestPoints':
      return {
        ...state,
        highestPoints: action.payload
      };
    case 'originalHighestPoints':
      return {
        ...state,
        originalHighestPoints: action.payload
      };
    case 'setPostageMessage':
      return {
        ...state,
        postageMessage: action.payload
      };
    case 'setShowDeliveryInformation':
      return {
        ...state,
        showDeliveryInformation: action.payload
      };
    case 'setNormalDelivery':
      return {
        ...state,
        normalDelivery: action.payload
      };
    case 'setSelectedPromotion':
      return {
        ...state,
        selectedPromotion: action.payload
      }
    case 'setOutOfStock':
      return {
        ...state,
        outOfStock: action.payload
      }
    case 'setOnePlaceIneligibleNotice':
      return {
        ...state,
        onePlaceIneligibleNotice: action.payload
      }
    case 'setStockNotification':
      return {
        ...state,
        stockNotification: action.payload
      }
    case 'setCreditsNotification':
      return {
        ...state,
        creditsNotification: action.payload
      }
    case 'setOtherNotifications':
      return {
        ...state,
        otherNotifications: action.payload
      }
    case 'setValidForSegment':
      return {
        ...state,
        isValidForSegment: action.payload
      }
    default:
      throw new Error();
  }
};

//get attribute name based on atrribute property
export const getAttributeName = (propertyName, defaultProduct) => {
  if (propertyName != "") {
    let attributeName = null;
    const { attributes: productAttributes } = defaultProduct;
    if (productAttributes && productAttributes.length > 0) {
      productAttributes.forEach((attributeObj) => {
        if (attributeObj["name"].toLowerCase() === propertyName.toLowerCase()) {
          attributeName = attributeObj["value"];
        }
      })
    }
    return attributeName;
  }
}