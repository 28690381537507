import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import IconArrow from 'icons/IconArrow';
import AlertPNG from 'assets/images/Alert.png';
import { Breadcrumbs } from 'components';
import './AssetUser.scss';

const AssetUserPermissions = ({ renderDownStreamErrorCode }) => (
  <Fragment>
    <div className="page container">
      <div className="asset-user">
        <Breadcrumbs />
        <div className="icon-contain">
          <img src={AlertPNG} alt="An error icon" />
        </div>
        <div className="clear-div-m" />
        <h1 className="page-heading">You don&#39;t have permission to join Telstra Plus</h1>
        <div className="clear-div-l" />
        <p>You don&#39;t have any eligible services linked to your Telstra ID.</p>
        <p>Need help? Message us using My Telstra app.</p>
        <div className="clear-div-xxs" />
        {renderDownStreamErrorCode()}
        <div className="clear-div-s" />
        <a
          role="button"
          aria-pressed="false"
          className="primary-cta-18"
          href="https://telstra.com.au/mytelstra"
        >
          Get My Telstra App <IconArrow />
        </a><br />
        <a
          role="button"
          aria-pressed="false"
          className="primary-cta-18"
          href="https://www.telstra.com.au/plus/terms-and-conditions"
        >
          Read Telstra Plus terms and conditions <IconArrow />
        </a>
        <div className="clear-div-xl" />
      </div>
    </div>
  </Fragment>
);

AssetUserPermissions.propTypes = {
  renderDownStreamErrorCode: PropTypes.func.isRequired
};

export default AssetUserPermissions;
