import { LOGGER } from 'utils/AppLog';
import getAddress from 'api/getAddress';
import * as actions from 'constants/actions';
import { getMaskedAddress } from 'utils/masking';

const addressAction = opts => {
  return dispatch => {
    return getAddress(opts)
      .then(response => {
        LOGGER.debug('Get Address API Response', response);
        // TOFIX right now this does nothing, need to know
        // what response we will be getting and anything we need to grab
        // and fill in for success or error pages
        // otherwise this will become a boolean
        const { displayAddress, addressId } = response.data.data.address;
        dispatch({
          type: actions.SET_ADDRESS,
          address: displayAddress
        });
        dispatch({
          type: actions.SET_MASKED_ADDRESS,
          maskedAddress: getMaskedAddress(displayAddress)
        });
        dispatch({
          type: actions.SET_ADDRESS_ID,
          adborid: addressId
        });

        LOGGER.debug('Get address API Call Finished');
      })
      .catch(error => {
        throw error;
      });
  };
};

export default addressAction;
