// Utils for handle all redirection flow from external partner

import { Base64 } from 'js-base64';
import * as PARTNER from 'constants/externalPartner';
import { partnersConfig } from 'oidc/oidc-configs';
import queryString from 'query-string';

export const createPartnerStorageObject = params => {
  const { partner } = params;
  let paramsObj = null;
  if (partner) {
    switch (partner) {
      case PARTNER.EVENTS_CINEMA:
        paramsObj = Base64.encode(
          JSON.stringify({
            partner,
            action: 'buyMovieTicket',
            data: [
              {
                movieId: params.movieId ? `AHL_${params.movieId}` : null,
                cinemaId: params.cinemaId ? `AHL_${params.cinemaId}` : null,
                cinemaState: params.state ? `AHL_${params.state}` : null,
                sessionId: params.sessionId ? `AHL_${params.sessionId}` : null
              }
            ]
          })
        );
        break;
      default:
    }
    if (paramsObj) {
      localStorage.setItem('tplus-flow', paramsObj);
    }
  }
};

export const constructPartnerUrl = (partner, jwt, query) => {
  const urlParts = partnersConfig[partner];
  if (urlParts) {
    const params = queryString.stringify({
      jwt,
      ...query,
      ...urlParts.params
    });
    return `${urlParts.baseUrl}?${params}`;
  }
  return null;
};
