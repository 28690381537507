import axios from './util/axiosHandle';
import { apiConfig as api } from '../oidc/oidc-configs';

const getOffersApi = user => {
  const userStub = user ? `/${user}` : '';
  return axios({
    params: {
      method: 'get',
      url: api.baseUrl + api.endpoints.getOffers + userStub
    }
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export default getOffersApi;
