import React from 'react';
import { useSelector } from 'react-redux';

import { HeaderAuth,HeaderUnauth } from 'components';

const HeaderContainer = () => {
  const isAuthenticated = useSelector(state => state.appStatus.authenticated);

  return <>{isAuthenticated ? <HeaderAuth /> : <HeaderUnauth />}</>;
};

export default HeaderContainer;
