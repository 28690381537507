import React from 'react';
import { Surface, Spacing, TextStyle, Lozenge } from '@able/react';

import { HtmlConverter } from 'utils/HtmlConverter';
import { Cta } from 'components';
import { Stacked, Left, Right } from './layouts';

import './PromoTile.scss';

const Promotile = props => {
  const {
    id,
    contentLayout = 'stacked',
    image,
    imageAlt = '',
    bodyCopy,
    smallHeading,
    callout = '',
    subHeading = '',
    heading,
    finePrint,
    ctaAction,
    ctaVariant,
    ctaText,
    ctaUrl,
    ctaTarget,
    ctaTrackingCode,
    secondaryCtaAction,
    secondaryCtaVariant,
    secondaryCtaText,
    secondaryCtaUrl,
    secondaryCtaTarget,
    secondaryCtaTrackingCode,
    surface = 'SurfaceFlat',
    category = '', // EAPI
    img = '', // EAPI
    cta = [], // EAPI,
    body = '', // EAPI
    altText = '', // EAPI
    campaignDataAttribute = {} // EAPI
  } = props;

  // Mapped with EAPI properties
  const mappedProps = {
    body: body || bodyCopy || '',
    ctaText: (cta[0] && cta[0].ctaCopy) || ctaText || '',
    ctaUrl: (cta[0] && cta[0].ctaUrl) || ctaUrl || '',
    secondaryCtaText: (cta[1] && cta[1].ctaCopy) || secondaryCtaText || '',
    secondaryCtaUrl: (cta[1] && cta[1].ctaUrl) || secondaryCtaUrl || '',
    img: img || image || '',
    altText: altText || imageAlt || '',
    category: category || smallHeading || ''
  };

  const renderImage = () => {
    if (mappedProps.img) {
      if (mappedProps.ctaUrl) {
        return (
          <Cta
            action={ctaAction}
            trackingCode={ctaTrackingCode}
            url={mappedProps.ctaUrl}
            ctaProps={{
              noButton: true,
              'aria-label': mappedProps.ctaText,
              ...(ctaTarget && { target: ctaTarget })
            }}
          >
            <div className="tile-image">
              <img
                src={mappedProps.img}
                alt={mappedProps.altText}
                loading="lazy"
              />
            </div>
          </Cta>
        );
      }
      return (
        <div className="tile-image">
          <img src={mappedProps.img} alt={mappedProps.altText} loading="lazy" />
        </div>
      );
    }
    return null;
  };

  const renderHeader = () => {
    if (contentLayout === 'stacked') {
      return (
        <TextStyle
          alias="HeadingD"
          element="h4"
          // Accessibility fix. If a heading is not followed by copy,
          // it shouldn't be seen as a heading.
          role={!bodyCopy ? 'presentation' : null}
        >
          {heading}
        </TextStyle>
      );
    }
    return (
      <TextStyle
        alias="HeadingB"
        element="h4"
        role={!bodyCopy ? 'presentation' : null}
      >
        {heading}
      </TextStyle>
    );
  };

  const renderPrimaryCta = () => {
    return (
      <Cta
        action={ctaAction}
        trackingCode={ctaTrackingCode}
        url={mappedProps.ctaUrl}
        ctaProps={{
          variant: ctaVariant,
          label: mappedProps.ctaText,
          ...(ctaTarget && { target: ctaTarget })
        }}
      />
    );
  };

  const renderSecondaryCta = () => {
    return (
      <Cta
        action={secondaryCtaAction}
        trackingCode={secondaryCtaTrackingCode}
        url={mappedProps.secondaryCtaUrl}
        ctaProps={{
          variant: secondaryCtaVariant,
          label: mappedProps.secondaryCtaText,
          ...(secondaryCtaTarget && { target: secondaryCtaTarget })
        }}
      />
    );
  };

  const renderBody = () => {
    const anyContent =
      heading ||
      mappedProps.category ||
      mappedProps.body ||
      mappedProps.ctaText ||
      mappedProps.secondaryCtaText;
    if (anyContent) {
      return (
        <>
          {callout && (
            <Lozenge
              label={callout}
              variant="Featured"
              className="tplus-promo-tile__lozenge"
            />
          )}
          {mappedProps.category && (
            <Spacing bottom="spacing1x">
              {/* Change this back to Subheadline for prod */}
              <TextStyle alias="Tag" element="h3">
                {mappedProps.category}
              </TextStyle>
            </Spacing>
          )}
          {heading && <Spacing bottom="spacing1x">{renderHeader()}</Spacing>}
          {subHeading && (
            <Spacing bottom="spacing2x">
              <TextStyle alias="Label" element="p">
                <strong>{subHeading}</strong>
              </TextStyle>
            </Spacing>
          )}
          {mappedProps.body && (
            <>
              <div className="tplus-rich-text">
                {HtmlConverter(mappedProps.body)}
              </div>
              <Spacing bottom="spacing2x" />
            </>
          )}
          {finePrint && (
            <footer>
              <TextStyle alias="FinePrint" element="p">
                {finePrint}
              </TextStyle>
              <Spacing bottom="spacing2x" />
            </footer>
          )}
          {mappedProps.ctaText && (
            <Spacing bottom="spacing1x">{renderPrimaryCta()}</Spacing>
          )}
          {mappedProps.secondaryCtaText && renderSecondaryCta()}
        </>
      );
    }
    return null;
  };

  const renderVariant = () => {
    switch (contentLayout) {
      case 'stacked':
        return (
          <Stacked
            opts={{
              image: renderImage(),
              body: renderBody(),
              surface,
              contentLayout
            }}
          />
        );
      case 'left':
        return (
          <Left
            opts={{
              image: renderImage(),
              body: renderBody(),
              surface,
              contentLayout
            }}
          />
        );
      case 'right':
        return (
          <Right
            opts={{
              image: renderImage(),
              body: renderBody(),
              surface,
              contentLayout
            }}
          />
        );
      default:
        return (
          <Stacked
            opts={{
              image: renderImage(),
              body: renderBody(),
              surface,
              contentLayout
            }}
          />
        );
    }
  };

  return (
    <div className="tplus-promo-tile" id={id} {...campaignDataAttribute}>
      <Surface variant={surface} className="tplus-promo-tile__surface">
        {renderVariant()}
      </Surface>
    </div>
  );
};

export default Promotile;
