/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { createSelector } from 'reselect';
import { tierChecker } from 'utils/tierChecker';

const getContent = (state, page, component) => {
  const { aem } = state;
  let path = aem.all;

  if (page && component) {
    // remove any leading /
    if (page.charAt(0) === '/') {
      page = page.substring(1);
    }
    page = page.split('/');
    page.forEach(dir => {
      path = path[dir] ? path[dir] : false;
    });
    if (path) {
      return _.filter(path, pathitem => {
        return pathitem.type === component || pathitem.key === component;
      });
    }
  }
  return null;
};

const getTier = state => state.accounts.loyaltyTier;

export const getAemContent = createSelector(
  [getContent, getTier],
  (content, tier) => {
    const filtered = [];
    if (content) {
      content.forEach(cnt => {
        const validTier = tierChecker(cnt, tier);
        if (validTier) {
          filtered.push(cnt);
        }
      });
    }

    return filtered;
});