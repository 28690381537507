import axios from './util/axiosHandle';
import { ecommerceConfig} from '../oidc/oidc-configs';

const { baseUrl, endpoints } = ecommerceConfig; 

const callStocksApi = (endpoint = '') =>{
  if (endpoint) {
        return axios({
          params: {
            method: 'get',
            url: baseUrl + endpoint,
          },
          type: 'public'
        })
          .then(response => {
            return response;
          })
          .catch(error => {
            throw error;
          });    
  }
}

export const getProductStocks = () => {
  return callStocksApi(endpoints.stocks);
};