import { createSelector } from 'reselect';
import _ from 'lodash';
import { DEFAULT_TIMZONE } from 'constants/general';
import { getGoldPresaleEndDate } from 'utils/GetGoldPresaleEndDate';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const isAuthenticated = state => state.appStatus.authenticated;
const getEventDetail = (state, pid) => {
  const {
    events: {
      childEvents: { details, children }
    }
  } = state;

  if (pid) {
    const performance = _.find(children, { id: pid });
    if (performance) {
      return { ...details, performance };
    }
    return details;
  }
  return details;
};

const getTier = state => state.accounts.loyaltyTier;

export const canBuyTicket = createSelector(
  [getEventDetail, isAuthenticated, getTier],
  (eventDetail, authenticated, tier) => {
    const currDate = dayjs().tz(DEFAULT_TIMZONE);
    const {
      tierPriority,
      eventSaleStartDate,
      performance,
      tierPriorityDetails
    } = eventDetail;

    let startDate = eventSaleStartDate;

    if (performance && performance.performanceSaleStartDate) {
      startDate = performance.performanceSaleStartDate;
    }

    const isDatePassed = dayjs(startDate) <= currDate;
    let goldEndTime = '';

    if (authenticated) {
      if (tierPriority) {
        goldEndTime = getGoldPresaleEndDate(tierPriorityDetails, startDate);
      }
      return (
        (tier === 'GOLD' && isDatePassed) ||
        (!tierPriority && isDatePassed) ||
        (tierPriority && tier !== 'GOLD' && currDate >= goldEndTime)
      );
    }
    return isDatePassed;
  }
);
