import React from 'react';
import PropTypes from 'prop-types';
import { addLinkClickEvent } from 'utils/analytics';
import { ActionButton, TextStyle } from '@able/react';
import Tab from './Tab';

const VerticalTabs = ({ title, tabs, footer }) => (
  <article className="rewards-tabs-wrapper">
    {title && <TextStyle alias="HeadingB" element="h2" className="vertical-tab-heading">{title}</TextStyle>}

    <div className="vertical-tab-body">
      {tabs.map(tab => (
        <Tab
          id={tab.id}
          title={tab.title}
          description={tab.description}
          key={tab.title}
          internallink={tab.internallink}
          internallinktext={tab.internallinktext}
        />
      ))}

      {footer && footer.text && (
        <ActionButton
          variant="LowEmphasis"
          label={footer.text}
          element="a"
          onClick={() => addLinkClickEvent(footer.tracking)}
          href={footer.url}
        />
      )}
    </div>
  </article>
);

export default VerticalTabs;

VerticalTabs.propTypes = {
  title: PropTypes.string.isRequired,
  footer: PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string,
    targetNewWindow: PropTypes.string
  }),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    })
  ).isRequired
};

VerticalTabs.defaultProps = {
  footer: {
    url: '',
    text: '',
    targetNewWindow: ''
  }
};
