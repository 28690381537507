import { combineReducers } from 'redux';

import {
  GET_OFFERS_PENDING,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_FAILURE,
  GET_ORDERS_PENDING,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  CREATE_NEW_ORDER,
  SET_ORDER_PENDING,
  SET_ORDER_SUCCESS,
  SET_ORDER_FAILURE
} from 'constants/actions';

const getOrdersState = {
  orders: [{}],
  getOrdersPending: false,
  getOrdersFailed: false,
  getOrdersApiResponse: {},
  getOrdersError: {},
  getOrdersSuccess: false
};

const createOrdersState = {
  createdOrders: []
};

const offersState = {
  offerings: [],
  getOffersPending: false,
  getOffersFailed: false,
  getOffersError: {},
  getOffersApiResponse: {}
};

function createOrders(state = createOrdersState, action) {
  switch (action.type) {
    case CREATE_NEW_ORDER: {
      const newOrder = {
        pending: true,
        successful: false,
        failed: false,
        name: action.offerName,
        error: {},
        apiResponse: {}
      };
      return {
        ...state,
        createdOrders: [...state.createdOrders, newOrder]
      };
    }
    case SET_ORDER_PENDING: {
      const { offerName } = action;
      const currentOrder = state.createdOrders.map(item => {
        // Find the item with the matching id
        if (item.name === offerName) {
          // Return a new object
          return {
            ...item, // copy the existing item
            pending: !item.pending
          };
        }
        // Leave every other item unchanged
        return item;
      });

      return {
        ...state,
        createdOrders: currentOrder
      };
    }
    case SET_ORDER_SUCCESS: {
      const { offerName } = action;
      const currentOrder = state.createdOrders.map(item => {
        // Find the item with the matching id
        if (item.name === offerName) {
          // Return a new object
          return {
            ...item, // copy the existing item
            failed: false,
            pending: false,
            successful: true,
            apiResponse: action.apiResponse.data
          };
        }
        // Leave every other item unchanged
        return item;
      });

      return {
        ...state,
        createdOrders: currentOrder
      };
    }
    case SET_ORDER_FAILURE: {
      const { offerName } = action;
      const currentOrder = state.createdOrders.map(item => {
        // Find the item with the matching offerName
        if (item.name === offerName) {
          // Return a new object
          return {
            ...item, // copy the existing item
            error: action.errorObject, // add the failed API response
            failed: true,
            pending: false,
            successful: false
          };
        }
        // Leave every other item unchanged
        return item;
      });

      return {
        ...state,
        createdOrders: currentOrder
      };
    }
    default: {
      return state;
    }
  }
}

function getOffers(state = offersState, action) {
  switch (action.type) {
    case GET_OFFERS_PENDING: {
      return {
        ...state,
        getOffersPending: action.getOffersPending
      };
    }
    case GET_OFFERS_SUCCESS: {
      if (typeof action.apiResponse !== 'undefined') {
        return {
          ...state,
          getOffersPending: false,
          getOffersFailed: false,
          offerings: action.offerings.offers,
          getOffersApiResponse: action.apiResponse
        };
      }
      return {
        ...state,
        getOffersPending: false,
        getOffersFailed: false
      };
    }
    case GET_OFFERS_FAILURE: {
      return {
        ...state,
        getOffersPending: false,
        getOffersFailed: true,
        getOffersError: action.errorObject
      };
    }
    default: {
      return state;
    }
  }
}

function getOrders(state = getOrdersState, action) {
  switch (action.type) {
    case GET_ORDERS_PENDING: {
      return {
        ...state,
        getOrdersPending: action.getOrdersPending
      };
    }
    case GET_ORDERS_SUCCESS: {
      const { apiResponse } = action;

      return {
        ...state,
        getOrdersFailed: false,
        getOrdersApiResponse: apiResponse,
        getOrdersSuccess: true,
        orders: action.orders.orders
      };
    }
    case GET_ORDERS_FAILURE: {
      return {
        ...state,
        getOrdersFailed: true,
        getOrdersPending: false,
        getOrdersSuccess: false,
        getOrdersError: action.errorObject
      };
    }
    default: {
      return state;
    }
  }
}

const reducers = combineReducers({
  getOffers,
  getOrders,
  createOrders
});

export default reducers;

export const getOffersPending = state => {
  return state.getOffersPending;
};

export const getOrdersPending = state => {
  return state.getOrdersPending;
};

export const createOrderPending = state => {
  return state.createOrderPending;
};
