import React from 'react';
import { useDispatch } from 'react-redux';
import { TextStyle, Spacing, ActionButton } from '@able/react';
import { addLinkClickEvent } from 'utils/analytics';

import { fetchPoints, fetchPointsHistory } from 'actions/points';

import AlertPNG from 'assets/images/Alert.png';
import * as ERRMSGS from 'constants/errorMessages.js';

import './PointsError.scss';

const PointsErrorView = () => {
  const dispatch = useDispatch();

  const handleRetryGetPoints = () => {
    dispatch(fetchPoints());
    dispatch(fetchPointsHistory());
  };

  return (
    <div className="points-error">
      <div className="icon-contain">
        <img src={AlertPNG} alt="An error icon" />
      </div>
      <Spacing bottom="spacing2x" />
      <TextStyle alias="HeadingB" element="h1">
        {ERRMSGS.POINTSERRORHEADING}
      </TextStyle>
      <Spacing bottom="spacing4x" />
      <TextStyle alias="Base" element="p">{ERRMSGS.POINTSERRORCOPY}</TextStyle>
      <Spacing bottom="spacing4x" />
      <ActionButton
        label="Try Again"
        variant="LowEmphasis"
        events={{
          onClick: () => {
            addLinkClickEvent('Get Points Try again');
            handleRetryGetPoints();
          }
        }}
      />
    </div>
  );
};

export default PointsErrorView;
