import {
  GET_REWARD_PRODUCT_STOCKS_PENDING,
  GET_REWARD_PRODUCT_STOCKS_SUCCESS,
  GET_REWARD_PRODUCT_STOCKS_FAILURE
} from 'constants/actions';

import { getProductStocks } from 'api/productStocks';

export const fetchRewardProductStocks = () => {
  return dispatch => {
    dispatch({ type: GET_REWARD_PRODUCT_STOCKS_PENDING });
    return getProductStocks()
      .then(res => {
        dispatch({
          type: GET_REWARD_PRODUCT_STOCKS_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: GET_REWARD_PRODUCT_STOCKS_FAILURE,
          payload: error.response
        });
      });
  };
};
