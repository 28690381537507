/* eslint-disable no-unneeded-ternary */
export const tierChecker = (obj, userTier) => {
  const tierList = ['MEMBER', 'SILVER', 'GOLD', 'BUSINESS'];
  if (userTier === 'NONE') {
    return true;
  }
  if (obj) {
    const hasChecked = tierList.some(key => obj[key]);
    if (hasChecked && userTier) {
      return obj[userTier] ? true : false;
    }
    return true;
  }
  return null;
};
