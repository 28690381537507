// 503 ERROR STATUS CODES
export const ERR_ALREADY_ENROLLED = 1023;
export const ERR_LA_CAC = 1021;
export const ERR_NO_CONTACT_LINKED = 1020;
export const ERR_NO_ACTIVE_CACSSOMESMBS = 1030; // For Some Small Business Accounts weird business rule
export const ERR_SOME_SMALL_BUSINESS_CACS = 1030;
export const ERR_NO_ACTIVE_CACS = 1040;
export const ERR_NO_QUALIFIED_ACC = 1070; // no qualified accounts
export const ERR_NO_ACC_UNDER_REVIEW = 1071;
export const ERR_ACCS_UNDER_REVIEW = 1072;
export const ERR_ASSET_USER = 1022;
export const ERR_USER_REL_ACCT_EXPIRED = 1025;
export const ERR_USER_MULTIPLE_CONTACT = 1073;
export const ERR_429_TOO_MANY_REQUESTS = 429;

// Contact capture errors
export const ERR_CC_TECHNICAL = 5000;
export const ERR_CC_BAD_REQUEST = 1000;
export const ERR_CC_MAX_PIN = 2005; // back to front with alert max attempts
export const ERR_CC_MESSAGE_FAILED = 2006; // back to otp
export const ERR_CC_INVALID_MOBILE = 1005;
export const ERR_CC_INVALID_EMAIL = 1006;
export const ERR_CC_OTP_NO_EXIST = 2001;
export const ERR_CC_OTP_INVALID = 2002;
export const ERR_CC_OTP_EXPIRED = 2003;
export const ERR_CC_MAX_OTP_ATTEMPTS = 2004; // same as 2005

// Misc Error Codes
export const ERR_OP_OFFERS_404 = 1060; // server 404 error
export const ERR_OP_OFFERS_503 = 1061; // server 503 error
export const ERR_OP_ORDERS_503 = 1062; // server 503 error
export const ERR_CONNABORTED = 'ECONNABORTED';
