import { SET_MOVIES_ERROR, RESET_MOVIES_ERROR } from 'constants/actions';

const defaultEvent = {
  error: null
};

export const movies = (moviesState = defaultEvent, action) => {
  switch (action.type) {
    case SET_MOVIES_ERROR: {
      return { ...moviesState, error: action.error };
    }
    case RESET_MOVIES_ERROR: {
      return { ...moviesState, error: null };
    }
    default:
      return moviesState;
  }
};
