import huddleLogo from 'assets/images/huddle-logo.png';

// third party partner
export const EVENTS_CINEMA = 'eventcinemas';
export const VILLAGE_CINEMA = 'villagecinemas';

export const PARTNERS = {
  huddle: {
    apiProps: {
      partnerId: '1',
      partnerName: 'Huddle',
      offerId: '1',
      responseFormat: 'jwt'
    },
    logo: huddleLogo,
    ctaText: 'Get a quote on Huddle',
    displayUrl: 'Huddle.com.au',
    utmTags: {
      utm_source: 'telstraplus',
      utm_medium: 'referral',
      utm_campaign: 'telstra_points',
      utm_term: 'opp_gen'
    }
  },
  booking: {
    apiProps: {
      partnerId: '2',
      partnerName: 'booking',
      offerId: '1',
      responseFormat: 'oauth'
    },
    displayUrl: 'Booking.com.au'
  },
  events: {
    apiProps: {
      partnerId: 3
    }
  },
  village: {
    apiProps: {
      partnerId: 4
    }
  }
};
