import createActivityDetector from 'activity-detector';
import { LOGGER } from 'utils/AppLog';
import { signOut } from 'oidc/initiateOidc';

const ce = new CustomEvent('customActivity', { cancelable: true });
const siteActivity = createActivityDetector({
  activityEvents: [
    'click',
    'mousemove',
    'keydown',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'focus',
    'customActivity'
  ],
  inactivityEvents: [],
  timeToIdle: 1800000, // 30 min wait time
  autoInit: false // Dont start the activity detector yet.
});
function initSiteActivity() {
  LOGGER.info('Initialising Site Activity Object');
  siteActivity.init();
  siteActivity.on('idle', signOut);
}
function stopSiteActivity() {
  LOGGER.info('Stopping Site Activity');
  siteActivity.stop();
}
function triggerSiteActivity() {
  // LOGGER.info('Trigger Site Activity');
  window.dispatchEvent(ce);
}
export {
  siteActivity,
  initSiteActivity,
  stopSiteActivity,
  triggerSiteActivity
};
