import { connect } from 'react-redux';
import React, { Component } from 'react';

import { OptOutSuccessView, OptOutView, OptOutNotEnrolledView, OptOutIssueView } from 'views';
import { LOGGER } from 'utils/AppLog';
import * as Analytics from 'utils/analytics';
import * as actions from 'constants/actions';

import unenrolUser from 'actions/optOut';

class OptOutContainer extends Component {
  componentDidMount() {
    LOGGER.debug('OptOut Container mounted');
    Analytics.setPageName('OptOut Page');
  }

  handleOptOut = () => {
    const { dispatch } = this.props;
    LOGGER.debug('handle Opt ', this.props);
    dispatch({ type: actions.SET_LOADING, loading: true });
    dispatch(this.props.actions.unenrolUser());
  };

  render() {
    const {
      optOut: { optOutResult },
      enrolment: { enrolled }
    } = this.props;
    const { handleOptOut } = this;
    let optOutView = null;
    if (optOutResult === null) {
      // load initial views
      if (enrolled) {
        optOutView = <OptOutView handleOptOut={handleOptOut} />;
      } else {
        optOutView = <OptOutNotEnrolledView />;
      }
    } else if (optOutResult === actions.OPTOUT_RESULT_SUCCESS) {
      optOutView = <OptOutSuccessView />;
    } else if (optOutResult === actions.OPTOUT_RESULT_NOT_ENROLLED) {
      optOutView = <OptOutNotEnrolledView />;
    } else if (optOutResult === actions.OPTOUT_RESULT_FAIL) {
      optOutView = <OptOutIssueView handleOptOut={handleOptOut} />;
    }

    return (
      <>
        {optOutView}
      </>
    );
  }
}

const mapStateToProps = state => ({
  enrolment: state.enrolment,
  optOut: state.optOut,
  actions: {
    unenrolUser
  }
});

export default connect(mapStateToProps)(OptOutContainer);

OptOutContainer.defaultProps = {
  dispatch: () => {},
  optOut: {},
  actions: {},
  enrolment: {}
};
