import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import alertSvg from 'assets/images/enrol/alert.svg';

import { TextStyle, IconButton, Spacing, ActionButton, Checkbox } from '@able/react';


const AddressConfirmView = props => {
  const { toggleView } = props;
  const dispatch = useDispatch();

  const [addressConfirmed, setAddressConfirmed] = useState(false);

  const otpAddressError = useSelector(state => state.otpFlow.address.error);

  const confirmAddressCheck = () => {
    setAddressConfirmed(!addressConfirmed);
    dispatch({
      type: 'SET_ADDRESS_CONFIRM',
      confirm: !addressConfirmed
    });
  };

  const confirmAddressContact = () => {
    confirmAddressCheck();
    dispatch({
      type: 'SET_ADDRESS',
      address: 'We will contact you to confirm your address'
    });
    toggleView({ clearErrors: true });
  };

  return (
   <>
          <div>
          <TextStyle element="h2" alias="HeadingB">
          Add your residential address
          </TextStyle>
          </div>
      <Spacing bottom="spacing1x"/>
            <TextStyle element="p" alias="Base" label="Address" name="address" type="text">
            We’re unable to find your address. An agent will need to confirm your address,
             to add it in to your profile.
              </TextStyle>

      <Spacing bottom="spacing5x"/>
            <TextStyle element="p" alias="Base" label="Address" name="address" type="text">
            By clicking on 'Contact me' you agree for an agent to contact you to 
            confirm your address.
              </TextStyle>
              <Spacing bottom="spacing5x"/>
     
      <ActionButton
          variant="MediumEmphasis"
          label="Contact me"
          element="button"
          type="submit"  
          rel="noopener noreferrer"
          onClick={() => confirmAddressContact()}        
      />
    </>
  );
};

export default AddressConfirmView;

AddressConfirmView.propTypes = {
  toggleView: PropTypes.func.isRequired
};
