import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import convert from 'htmr';

import IconClose from 'icons/IconClose';
import IconArrow from 'icons/IconArrow';

import AlertPNG from 'assets/images/Alert.png';

const ErrorView = props => {
  const { toggleView } = props;

  const otpFlow = useSelector(state => state.otpFlow);

  return (
    <div className="cc-modal-content">
      <img className="alert-icon" src={AlertPNG} alt="Alert" />
      <h2 className="error-heading">
        {otpFlow?.otp?.errorMsg && convert(otpFlow?.otp?.errorMsg)}
        {otpFlow?.mobile?.errorMsg && convert(otpFlow?.mobile?.errorMsg)}
        {otpFlow?.email?.errorMsg && convert(otpFlow?.email?.errorMsg)}
      </h2>
      <button
        className="close-modal-btn"
        type="button"
        onClick={() => toggleView({ clearErrors: true })}
        aria-label="Close"
      >
        <IconClose />
      </button>
      {otpFlow?.otp?.error === 2004 ||
        (otpFlow?.otp?.error === 2003 && (
          <button
            className="try-again-btn primary-cta-18"
            type="button"
            onClick={() => toggleView('otp', true)}
          >
            Try again with a new PIN <IconArrow />
          </button>
        ))}
      {(otpFlow?.otp?.error === 0 || otpFlow?.otp?.error === '') && (
        <button
          className="try-again-btn primary-cta-18"
          type="button"
          onClick={() =>
            toggleView({
              modalType: otpFlow?.otp?.flow,
              modalSwitch: true
            })
          }
        >
          Try again <IconArrow />
        </button>
      )}
    </div>
  );
};

export default ErrorView;

ErrorView.propTypes = {
  toggleView: PropTypes.func.isRequired
};
