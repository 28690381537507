/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useField } from 'formik';
import { useFocus } from 'utils/hooks/useFocus';
import { useCombobox } from 'downshift';

import addressLookup from 'api/addressLookup';
import addressValidate from 'api/addressValidate';

import useDebounce from 'utils/hooks/use-debounce';

import './MyAddressLookup.scss';
import smallSpinner from 'assets/images/Spinner.gif';

import { InlineLink, MessageInline, Spacing, TextStyle, Icon } from '@able/react';

const MyAddressLookup = ({ label, ...props }) => {
  // TODO Fix up all these string actions (why do we need to do this? im calling reducers directly)
  const { name, setAddressValue, setAddressId, setConfirmed } = props;

  const dispatch = useDispatch();

  const [field, meta] = useField(props);

  const [inputRef, setInputFocus] = useFocus(null);

  // State and setter for search term
  const [searchTerm, setSearchTerm] = useState('');
  const [inputItems, setInputItems] = useState([]);
  const [adFetching, setAdFetching] = useState(false);

  // Now we call our hook, passing in the current searchTerm value.
  // The hook will only return the latest value (what we passed in) ...
  // ... if it's been more than 500ms since it was last called.
  // Otherwise, it will return the previous value of searchTerm.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const inputStyle = {
    background: `url(${smallSpinner}) no-repeat right /4%`
  };

  const handleNoAddress = () => {
    props.nextAction({
      modalType: 'confirmaddress',
      modalSwitch: true
    });
  };

  const fetchMatchingAddresses = value => {
    setAdFetching(true);
    addressLookup(value)
      .then(response => {
        setAdFetching(false);
        // Grab successful response,
        //  - take the first 5 responses
        // If there are no results then an array with one object and empty attributes is returned
        // Why? i dont know
        if (response.data.results[0].name !== '') {
          const addresses = response.data.results.slice(0, 5);
          // .map(result => <Highlight search={value}>{result.name}</Highlight>);
          // Create an extra array item for no address link
          addresses.push({ noAddressFound: true });
          setInputItems(addresses);
        }else{
          const addresses = [];
          addresses.push({ noAddressFound: true });
          setInputItems(addresses);
        }
      })
      .catch(() => {
        setAdFetching(false);
      });
  };

  const validateAddress = value => {
    setAdFetching(true);
    addressValidate(value)
      .then(response => {
        setAdFetching(false);
        // set adborid here
        // console.info(response.data.data);
        const { addressId } = response.data.data.unstructuredAUPost[0];
        setAddressId(addressId);
        setAddressValue(value);
        setConfirmed(true);
      })
      .catch(error => {
        setAdFetching(false);
        dispatch({
          type: 'SET_ADDRESS_ERROR',
          payload: error
        });
        handleNoAddress();
      });
  };

  // Here's where the API call happens
  // We use useEffect since this is an asynchronous action
  useEffect(
    () => {
      // Make sure we have a value (user has entered something in input)
      if (debouncedSearchTerm) {
        // Fire off our API call
        fetchMatchingAddresses(debouncedSearchTerm);
      } else {
        setInputItems([]);
      }
    },
    // This is the useEffect input array
    // Our useEffect function will only execute if this value changes ...
    // ... and thanks to our hook it will only change if the original ...
    // value (searchTerm) hasn't changed for more than 500ms.
    [debouncedSearchTerm]
  );

  useEffect(() => {
    setInputFocus();
  }, [meta.error]);

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
    inputValue
  } = useCombobox({
    items: inputItems,
    itemToString: item => (item ? item.name : ''),
    onInputValueChange: ({inputValue: inputVal}) => {
      setConfirmed(false);
      setSearchTerm(inputVal);
      // When input value is set, call api
      // if (inputValue.length > 2) {
      //   onSearchChange(inputValue);
      // } else
      if (inputValue.length < 2) {
        setInputItems([]);
        closeMenu();
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem.noAddressFound) {
        handleNoAddress();
      } else {
        validateAddress(selectedItem.name);
      }
    }
  });

  const handleScroll = e => {
    if (e.keyCode === 40) {
      const elem = document.querySelector(`.address-search-dropdown`);
      if (elem) {
        elem.scrollIntoView();
      }
    }
  };

  return (
    <div
      onKeyDown={e => handleScroll(e)}
      className="able-TextField address-input-wrapper"
      {...getComboboxProps()}
    >
      <input
        {...getInputProps({
          ...field,
          className: ``,
          placeholder: label,
          style: adFetching ? inputStyle : {}
        })}
        aria-invalid={!!meta.error}
        value={searchTerm}
        ref={inputRef}
        autoComplete="street-address"
        type="text"
        id="address"
      />
       {meta.touched && meta.error && (
        <p>
          <Icon
            size="24"
            developmentUrl="/able-sprites.svg"
            icon="Error"
            screenReaderContent="Error"
            role="img"
            aria-label="Error"
          />
          {meta.error}
        </p>
      )}
      <label
        {...getLabelProps({
          className: '',
          htmlFor: props.id || name,
          'data-content': label
        })}
      >
        <span className="hidden--visually">{label}</span>
      </label>

      <ul {...getMenuProps()} className="address-search-dropdown">
        {isOpen && (
          <>
            {inputItems.map((item, index) => (
              <>
                {index !== inputItems.length - 1 ? (
                  <li
                    className={`address-item ${
                      highlightedIndex === index ? 'selected' : ''
                    }`}
                    key={`${item}${index}`}
                    {...getItemProps({
                      item,
                      index
                    })}
                  >
                    <TextStyle element="p" alias="Base">
                    {item.name}
                    </TextStyle>
                    
                  </li>
                ) : (
                  <li
                    className={`address-item ${
                      highlightedIndex === index ? 'selected' : ''
                    }`}
                    {...getItemProps({
                      item: 'noAddress',
                      index
                    })}
                  >
                    <InlineLink>
                          <a
                          type="button"
                            rel="noopener noreferrer"
                          >
                            My address isn’t listed
                          </a>
                      </InlineLink>
                  </li>
                )}
              </>
            ))}
          </>
        )}
      </ul>
    </div>
  );
};

export default MyAddressLookup;

MyAddressLookup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setAddressValue: PropTypes.func.isRequired,
  setAddressId: PropTypes.func.isRequired,
  setConfirmed: PropTypes.func.isRequired,
  nextAction: PropTypes.func.isRequired,
  id: PropTypes.string
};

MyAddressLookup.defaultProps = {
  id: ''
};
