import { LOGGER } from 'utils/AppLog';
import getOffers from 'api/getOffers';
import { getOffersPending } from 'reducers/onePlace';
import * as actions from 'constants/actions';
import * as ERRCODES from 'constants/errorCodes.js';

const fetchOffers = user => {
  return (dispatch, getState) => {
    if (getOffersPending(getState())) {
      return Promise.resolve();
    }

    dispatch({ type: actions.GET_OFFERS_PENDING, getOffersPending: true });

    return getOffers(user)
      .then(response => {
        LOGGER.debug('Get Offers API Response', response);
        dispatch({
          type: actions.GET_OFFERS_SUCCESS,
          offerings: response.data.data,
          apiResponse: response.data
        });

        LOGGER.debug('Get Offers API Call Finished');
      })
      .catch(error => {
        switch (error.response.data.code) {
          case ERRCODES.ERR_CONNABORTED:
          case ERRCODES.ERR_OP_OFFERS_503:
            dispatch({
              type: actions.GET_OFFERS_FAILURE,
              errorObject: {
                status: 503
              }
            });
            break;
          case ERRCODES.ERR_OP_OFFERS_404:
            dispatch({
              type: actions.GET_OFFERS_FAILURE,
              errorObject: {
                status: 404
              }
            });
            break;
          default:
            LOGGER.debug('Get Offers Error-ed out', error.stack);
            dispatch({
              type: actions.GET_OFFERS_FAILURE,
              errorObject: error.response
            });
        }

        throw error;
      });
  };
};

export default fetchOffers;
