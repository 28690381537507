/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import classnames from 'classnames';
import Dialog, {
  createClickHandler,
  createCollatedEvents
} from 'components/Dialogs';
import { ActionButton } from '@able/react';
import hideDialogClickHandlerTemplate from 'components/Dialogs/functions/hideDialogClickHandler';
import IconClose from 'icons/IconClose';

/**
 * A modal dialog is used to interrupt user's current workflow, and bring their
 * attention exclusively to a decision or an urgent information relevant to
 * their current context.
 *
 * [View the Dialog pattern documentation on Confluence](https://confluence.in.telstra.com.au/display/DCSYS/Modal+Dialog)
 *
 * @version 1.0.0
 */

export const confirmationDialogTemplate = (icon, variant = '') => props => {
  const {
    isShowing,
    setFilterIsOpen,
    setHideDialog,
    className,
    stackButtonOnVXS,
    cancelButtonEvents,
    confirmButtonEvents,
    cancelButtonLabel,
    confirmButtonLabel,
    children,
    title,
    description,
    developmentUrl,
    focusOnCloseRef
  } = props;

  const stackedClass = stackButtonOnVXS ? '--stacked' : '';
  const patternClass = `able-Dialog--Confirmation${variant}${stackedClass}`;
  const classes = classnames(className, patternClass);

  const clickHandlerTemplate = dialogContentSetHideDialog => events => {
    return createClickHandler(events, dialogContentSetHideDialog);
  };

  if (typeof isShowing !== 'undefined') {
    const hideDialogClickHandler = hideDialogClickHandlerTemplate(
      setHideDialog,
      focusOnCloseRef
    );

    const cancelButtonClickHandler = clickHandlerTemplate(
      hideDialogClickHandler
    )(cancelButtonEvents);

    const collatedCancelButtonEvents = createCollatedEvents(
      cancelButtonEvents,
      cancelButtonClickHandler
    );

    const confirmButtonClickHandler = clickHandlerTemplate(
      hideDialogClickHandler
    )(confirmButtonEvents);

    const collatedConfirmButtonEvents = createCollatedEvents(
      confirmButtonEvents,
      confirmButtonClickHandler
    );

    const newProps = {
      isShowing,
      title,
      description,
      developmentUrl,
      escapeKeyPress: cancelButtonClickHandler
    };

    return (
      <Dialog {...newProps} className={classes}>
        <div className="icon-close" onClick={() => setFilterIsOpen(false)}>
            <IconClose />
        </div>
        <h2 className='dialog-heading'>
          Filter
        </h2>
        {children}
        <div className={`${patternClass}__actions`}>
          <ActionButton
            variant="MediumEmphasis"
            element="button"
            label={cancelButtonLabel}
            events={collatedCancelButtonEvents}
          />
          <ActionButton
            variant={
              variant === 'Destructive'
                ? 'HighEmphasisDestructive'
                : 'HighEmphasis'
            }
            element="button"
            label={confirmButtonLabel}
            events={collatedConfirmButtonEvents}
          />
        </div>
      </Dialog>
    );
  }
  return <></>;
};

const ConfirmationDialog = confirmationDialogTemplate();

export default ConfirmationDialog;
