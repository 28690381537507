/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/aria-proptypes */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import spinnerGIF from 'assets/images/Spinner.gif';

import styles from './LoadingSpinner.module.scss';

const InlineLoadingSpinner = ({ loadingText, smallSpinner, fullWidth, center, ariaLive }) => {
  // Set up CSS
  const spinnerClass = clsx(
    styles['inline-loading-spinner'],
    smallSpinner && styles['small-spinner'],
    fullWidth && styles['full-width'],
    center && styles['center']
  );

  return (
    <div className={spinnerClass}>
      <img src={spinnerGIF} alt="Loading spinner animation" />
      {ariaLive && <span aria-live="true">{loadingText}</span>}
      {!ariaLive && <span>{loadingText}</span>}
    </div>
  );
};

export default InlineLoadingSpinner;

InlineLoadingSpinner.propTypes = {
  loadingText: PropTypes.string,
  smallSpinner: PropTypes.bool,
  fullWidth: PropTypes.bool,
  center: PropTypes.bool,
  ariaLive: PropTypes.bool
};

InlineLoadingSpinner.defaultProps = {
  loadingText: 'Loading...',
  smallSpinner: false,
  fullWidth: false,
  center: false,
  ariaLive: false
};
