import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as Analytics from 'utils/analytics';
import AppLog from 'utils/AppLog';

import { Breadcrumbs, VerticalTabs, SubHeader, HeadingTitle } from 'components';
import { MemberTierTable } from 'views';
import { Spacing } from '@able/react';

import { getHeadingTitleState } from 'selectors/GetHeaders';
import { getVerticalTabsState } from 'selectors/GetVerticalTabs';
import { getAemContent } from 'selectors/GetAemContent';

const HowItWorksAuthView = () => {
  const log = new AppLog('How It Works Auth');
  const activeAccountTier = useSelector(state => state.accounts.loyaltyTier);
  const aemBenefitsHeader = useSelector(state => getHeadingTitleState(state));
  const [subHeaderConfig] = useSelector(state =>
    getAemContent(state, 'howitworks/auth/subheader', 'subheader')
  );
  const tier = useSelector(state => state.accounts.loyaltyTier);
  const aemVerticalTabs = useSelector(state => getVerticalTabsState(state)); // Returns array

  useEffect(() => {
    log.debug('Telstra Plus how it works authenticated view mounted');

    Analytics.setPageName('Tplus How It Works Page');
  }, [log]);

  return (
    <>
      <div className="howitworks container main">
        <Spacing bottom="spacing2x" />
        {subHeaderConfig && <SubHeader {...subHeaderConfig} />}
        {aemBenefitsHeader && (
          <>
            <Spacing bottom="spacing2x" />
            <HeadingTitle
              subheading={aemBenefitsHeader?.subheading}
              heading={aemBenefitsHeader?.heading}
              description={aemBenefitsHeader?.description}
              pageimage={aemBenefitsHeader?.images}
              primaryCtas={aemBenefitsHeader?.primaryCtas}
              secondaryCtas={aemBenefitsHeader?.secondaryCtas}
              linkCopy={aemBenefitsHeader?.linkcopy}
              linkUrl={aemBenefitsHeader?.linkurl}
              linkCopy2={aemBenefitsHeader?.linkcopy2}
              linkUrl2={aemBenefitsHeader?.linkurl2}
              terms={aemBenefitsHeader?.terms}
              activeaccount={activeAccountTier}
              active={aemBenefitsHeader}
            />
            <Spacing bottom="spacing4x" />
          </>
        )}
        <Breadcrumbs />
        <MemberTierTable tier={tier} />
        {aemVerticalTabs.length > 0 && (
          <>
            {aemVerticalTabs.map((tab, i) => {
              return (
                <section key={i}>
                  <div className="rewards-tabs-container">
                    <VerticalTabs
                      title={tab.title}
                      tabs={tab.array}
                      footer={tab.footer}
                    />
                  </div>
                </section>
              );
            })}
          </>
        )}

        <div className="clear-div-xxxl" />
      </div>
    </>
  );
};

export default HowItWorksAuthView;
