import React, { useEffect } from 'react';
import { Select } from '@able/react';
import { useFocus } from 'utils/hooks/useFocus';

const FormSelect = props => {
  const {
    label = '',
    name = '',
    options,
    handleChange,
    submitCount = 0,
    isSubmitting = false,
    isValidating = false,
    errors = {}
  } = props;

  const [inputRef, setInputFocus] = useFocus();
  const errored = errors[name] && submitCount > 0;
  const optionsArr = Object.keys(options).map(key => {
    return {
      value: options[key].value,
      label: options[key].label
    };
  });

  useEffect(() => {
    if (isSubmitting && !isValidating && Object.keys(errors)[0] === name) {
      setInputFocus();
    }
  }, [isSubmitting, isValidating, errors]);

  return (
    <div className="tplus-form-field">
      <Select
        id={name}
        name={name}
        ref={inputRef}
        label={label}
        options={optionsArr}
        events={{
          onChange: handleChange
        }}
        invalid={errored}
        invalidMessage={errors[name]}
      />
    </div>
  );
};

export default FormSelect;

FormSelect.defaultProps = {
  active: '',
  name: 'select',
  title: '',
  options: {}
};
