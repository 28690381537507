import React from 'react';
import PropTypes from 'prop-types';

import AddressForm from 'components/OtpFlow/sidebarforms/AddressForm';
import { TextStyle, IconButton, Spacing } from '@able/react';
const AddressAddView = props => {
  const { toggleView } = props;

  return (
    <>
        <div>       
          <TextStyle element="h2" alias="HeadingB">
            Update your residential address
          </TextStyle>
      </div>
      <AddressForm nextAction={toggleView} />
      </>
  );
};

export default AddressAddView;

AddressAddView.propTypes = {
  toggleView: PropTypes.func.isRequired
};
