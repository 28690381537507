import axios from 'axios';
import _ from 'lodash';

import { getAuthorizationToken } from 'oidc/initiateOidc';

// set defaults params for AXIOS
const { REACT_APP_ENV } = process.env;
const IN_DEVELOP = REACT_APP_ENV === 'dev' || REACT_APP_ENV === 'dev-mocks';
const IN_NON_PROD = REACT_APP_ENV === 'nonprod';
const IN_CI = process.env.REACT_APP_ENV === 'ci';

// sourceclear DDoS fix. Limiting to 20kb for an ajax request.
const maxContentLength = 20000;

if (IN_DEVELOP) {
  axios.defaults.timeout = 0;
} else if (IN_NON_PROD) {
  axios.defaults.timeout = 90000;
} else {
  axios.defaults.timeout = 45000;
}

// Allowing a guid to be set in the intial params for a session
// and parsing it from localstorage, for CI automated testing
const searchParams = localStorage.getItem('ci-params') || '';
localStorage.removeItem('ci-params');
// Parsing saved search params
const GUID =
  searchParams.indexOf('guid=') >= 0
    ? searchParams.split('guid=')[1].split('&')[0]
    : 'G02000253782';

const axiosHandle = ({ params = {}, incomingheaders = {}, type = '' }) => {
  // setting a conditional for authorization headers here to differentiate calls to aem server
  // calls to aem break if authorization header is set
  const authHeader =
    type === 'public'
      ? {}
      : {
          Authorization: `Bearer ${getAuthorizationToken()}`
        };

  const headers =
    (IN_DEVELOP || IN_CI) && type !== 'public'
      ? {
          GUID,
          ...authHeader,
          ...incomingheaders
        }
      : {
          ...authHeader,
          ...incomingheaders
        };

  return axios({
    ...params,
    maxContentLength,
    headers
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      if (_.has(error, 'request')) {
        if (_.has(error.request, 'res')) {
          error.request.res.destroy(); // sourceclear DDoS fix.
        }
      }
      throw error;
    });
};

export default axiosHandle;
