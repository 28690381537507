/**
 * Used in the Dialog to combine external click handlers with internal state handlers
 *
 * @param event an object containing injected event handlers
 * @param clickHandler function to combine with inject click handlers
 *
 * @returns an events object concatenating injected events and onclick
 */
export const createCollatedEvents = (events, clickHandler) => ({
  ...events,
  onClick: () => clickHandler()
});
