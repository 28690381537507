import React from 'react';
import uuid from 'uuid/v4';

export default function IconAlertError() {
  return (
    <svg
      width="85px"
      height="71px"
      viewBox="0 0 85 71"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id={`UI${uuid()}}`}
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="🖥-Opt-out---Technical-Reason---(1368)"
          transform="translate(-311.000000, -205.000000)"
        >
          <g id="Group-4" transform="translate(312.000000, 206.000000)">
            <path
              d="M41.5,1 C44.1666667,7.33333333 44.1666667,12.8333333 41.5,17.5 C37.5,24.5 21,47.5 23,57 C25,66.5 33.5586607,69 36,69 C32,69 20,69 1.24344979e-13,69 L41.5,1 Z"
              id="Path-2"
              fill="#FFBDDF"
            />
            <polygon
              id="Triangle"
              stroke="#570066"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="41.5 0 83 69 0 69"
            />
            <g
              id="Group-3"
              transform="translate(39.000000, 25.000000)"
              fill="#570066"
            >
              <rect id="Rectangle" x="1" y="0" width="2" height="24" rx="1" />
              <circle id="Oval" cx="2" cy="32" r="2" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
