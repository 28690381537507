import React from 'react';
import { Grid, Spacing, ActionButton, TextStyle } from '@able/react';
import IconAlertError from 'icons/IconAlertError';
import * as Analytics from 'utils/analytics';

const OptOutIssue = ({ handleOptOut }) => {
  Analytics.setPageName('Opt Out - Error when opting out');

  return (
    <Grid className="container">
      <div className="technical-issue page">
      <Spacing bottom="spacing7x" />
        <span className="icon-contain">
          <IconAlertError />
        </span>
        <Spacing bottom="spacing4x" />
        <TextStyle element="h1" alias="HeadingB">Sorry, something went wrong</TextStyle>
        <Spacing bottom="spacing4x" />

        <TextStyle alias="Base" element="p">
          We weren&#39;t able to cancel your Telstra Plus membership at this time,
          please try again.
        </TextStyle>
        <Spacing bottom="spacing4x" />
        <ActionButton
          element="button"
          variant="MediumEmphasis"
          label="Try again"
          onClick={handleOptOut}
        />
        <Spacing bottom="spacing2x" />
        <ActionButton
          element="Link"
          variant="LowEmphasis"
          label="Keep membership"
          to="/benefits"
          onClick={handleOptOut}
        />
        <Spacing bottom="spacing10x" />
      </div>
    </Grid>
  );
};

export default OptOutIssue;
