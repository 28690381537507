import * as actions from 'constants/actions';

const experienceState = {};

export default function experience(state = experienceState, action) {
  const { type, data } = action;
  switch (type) {
    case actions.SET_EXP_CONTENT:
      return {
        ...state,
        [data.id]: data.content
      };
    case actions.RENDER_EXP_CONTENT_SUCCESS:
      return {
        ...state,
        [data]: {
          ...state[data],
          rendered: true
        }
      };
    case actions.CLEAR_EXP_CONTENT:
      return {};
    case actions.REMOVE_EXP_CONTENT:
      // eslint-disable-next-line no-param-reassign
      delete state[data.id];
      return state;
    default: {
      return state;
    }
  }
}
