import React from 'react';

export default function IconExternalLink() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      fill="currentColor"
      width="100%"
      height="100%"
    >
      <path d="M30.1 38H10V17.9h10c.6 0 1.1-.2 1.4-.6.4-.4.6-.9.6-1.4 0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2V40c0 1.1.9 2 2 2h24.1c1.1 0 2-.9 2-2V28c0-1.1-.9-2-2-2-.6 0-1.1.2-1.4.6-.4.4-.6.9-.6 1.4v10z" />
      <path d="M42 7.6c0-.1 0-.1-.1-.2 0-.1 0-.1-.1-.2 0-.1-.1-.1-.1-.2s-.1-.1-.1-.2c-.1-.2-.3-.4-.6-.6l-.1-.1c-.1 0-.1-.1-.2-.1s-.1 0-.2-.1c-.1 0-.1 0-.2-.1H23c-1.1 0-2 .9-2 2s.9 2 2 2h12.1L24.6 20.5l-6 6c-.8.8-.8 2 0 2.8.4.5.9.7 1.4.7s1-.2 1.4-.6l6-6L38 12.8V25c0 1.1.9 2 2 2s2-.9 2-2V7.6z" />
    </svg>
  );
}
