import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { HtmlConverter } from 'utils/HtmlConverter';
import { Grid, Spacing, Column, TextStyle, ActionButton } from '@able/react';

import * as Analytics from 'utils/analytics';

import AlertPNG from 'assets/images/Alert.png';
import './TechnicalIssue.scss';

import { Breadcrumbs } from 'components';

import * as ERRCODES from 'constants/errorCodes.js';
import * as ERRMSGS from 'constants/errorMessages.js';

import {
  SET_ELIGIBLE,
  SET_ENROLLED,
  SET_LOADING,
  SET_ERROR,
  SET_ENROLFAILED
} from 'constants/actions';

import registerApi from 'api/register';

const { REACT_APP_PLUS_HOME_URL, REACT_APP_HERITAGE_SIGNIN_URL } = process.env;

const TechnicalIssue = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const enrolment = useSelector(state => state.enrolment);
  const { enrolled, registrationFailure } = enrolment;
  const error = useSelector(state => state.error);
  const {
    error: errorObject,
    errorStatusCode,
    errorLink,
    errorHeader,
    errorMessage
  } = error;
  const otpFlow = useSelector(state => state.otpFlow);
  const { otp } = otpFlow;
  const { error: otpError = null } = otp;

  const handleRetryEnrolment = () => {
    dispatch({ type: SET_LOADING, loading: true });

    registerApi()
      .then(response => {
        // Connection timed-out
        if (response.code === ERRCODES.ERR_CONNABORTED) {
          // log.debug('Request cancelled');
          dispatch({
            type: SET_ERROR,
            hasErrored: true,
            errorMessage: ERRMSGS.REGISTRATIONERROR
          });
          history('/error');
          return;
        }

        // Server Returned a 200 Response
        if (_.has(response, 'data')) {
          dispatch({
            type: SET_ENROLLED,
            enrolled: true
          });
          Analytics.addEvent({
            eventAction: 'loyaltyEnrolComplete'
          });
          history('/benefits');
        }
      })
      .then(() => {
        dispatch({
          type: SET_LOADING,
          loading: false
        });
      })
      .catch(apiError => {
        if (typeof apiError.response !== 'undefined') {
          const { code } = apiError.response.data;

          // log.debug('Server error', code);

          if (code === ERRCODES.ERR_LA_CAC) {
            // Limited Authority && No Active Service Accounts
            dispatch({
              type: SET_ELIGIBLE,
              eligible: false,
              getDetails: error.response.data
            });
            dispatch({
              type: SET_ENROLLED,
              enrolled: false
            });
            dispatch({
              type: SET_ERROR,
              hasErrored: true,
              errorStatusCode: code
            });

            history('/not-eligible');
          } else if (code === ERRCODES.ERR_ALREADY_ENROLLED) {
            // Already Enrolled
            // Setting Enrolled here will push the error screen with an already enrolled messaged
            // from the API and a Link to go to the Plus Homepage

            dispatch({
              type: SET_ENROLLED,
              enrolled: true
            });
            dispatch({
              type: SET_ERROR,
              hasErrored: true,
              errorMessage: ERRMSGS.ALREADYENROLLED
            });
            history('/error');
          } else {
            // Setting enrolFailed to true will display the error screen with a retry button
            // To retry the enrolment call

            dispatch({
              type: SET_ERROR,
              hasErrored: true,
              errorMessage: ERRMSGS.REGISTRATIONERROR
            });

            dispatch({
              type: SET_ENROLFAILED,
              enrolFailed: true
            });
            history('/error');
          }
        } else {
          // Setting enrolFailed to true will display the error screen with a retry button
          // To retry the enrolment call

          dispatch({
            type: SET_ERROR,
            hasErrored: true,
            errorMessage: ERRMSGS.REGISTRATIONERROR
          });

          dispatch({
            type: SET_ENROLFAILED,
            enrolFailed: true
          });
          history('/error');
        }
      });
  };

  const renderErrorHeader = () => {
    if (errorHeader) {
      return errorHeader;
    }

    return 'Sorry something went wrong';
  };

  const renderErrorMessage = () => {
    if (errorMessage || errorMessage === '') {
      return HtmlConverter(errorMessage);
    }
    if (enrolled) {
      return ERRMSGS.ALREADYENROLLED;
    }
    return ERRMSGS.DEFAULTMESSAGE;
  };

  const renderDownStreamErrorCode = () => {
    const { message } = errorObject;

    const errorMessageCheck = message.trim().replace(/ .*/, '');

    // Check if first word in the error message is Error
    // *Error codes will be displayed first, this accounts for scenarios where no Error code is returned.
    if (errorMessageCheck.includes('Error')) {
      return null;
    }
    // Collect all error codes up to the first whitespace and display them.
    return (
      <p>
        <small>
          <strong>Support code: </strong>
          {message.substr(0, message.indexOf(' '))}
        </small>
      </p>
    );
  };

  const renderMyTelstraLink = () => {
    return (
      <ActionButton
        element="a"
        variant="LowEmphasis"
        label="Get My Telstra app"
        href="https://www.telstra.com.au/mytelstra"
      />
    );
  };

  const renderBackToPlusLink = () => {
    switch (errorLink) {
      case 'backtoplus':
        return (
          <ActionButton
            element="Link"
            variant="MediumEmphasis"
            label="Back to Telstra Plus"
            to="/"
          />
        );
      default:
        break;
    };
    return null;
  };

  const renderImage = () => {
    return (
      <div className="icon-contain">
        <img src={AlertPNG} alt="An error has occured" />
      </div>
    );
  };

  const renderCta = () => {
    // Registration errors
    if (registrationFailure && !enrolled) {
      return (
        <>
          <div className="clear-div-s" />
          <ActionButton
            onClick={handleRetryEnrolment}
            variant="MediumEmphasis"
            element="button"
            label="Try again"
          />
          <Spacing bottom="spacing2x" />
          <ActionButton
            element="a"
            variant="LowEmphasis"
            href={REACT_APP_PLUS_HOME_URL}
            label="Cancel without joining"
          />
        </>
      );
    }

    // OTP flow errors
    if (otpError === 2004 || otpError === 2003) {
      return (
        <ActionButton
          element="Link"
          variant="LowEmphasis"
          to="/join"
          label="Try again with a new PIN"
        />
      );
    }

    if (otpError === 0) {
      return (
        <ActionButton
          element="Link"
          variant="LowEmphasis"
          to="/join"
          label="Try again"
        />
      );
    }

    // Particular errors
    if (
      errorStatusCode &&
      (errorStatusCode === 1070 ||
        errorStatusCode === 1071 ||
        errorStatusCode === 1072 ||
        errorStatusCode === 1073)
    ) {
      return renderMyTelstraLink();
    }

    if (errorStatusCode && errorStatusCode === 1020) {
      return (
        <ActionButton
          element="a"
          variant="LowEmphasis"
          label="Update Telstra ID"
          href={REACT_APP_HERITAGE_SIGNIN_URL}
        />
      );
    }

    // User hit some error
    // They are registered OR we have passed in a particular type of errorlink type
    if (enrolled || errorLink) {
      return renderBackToPlusLink();
    }

    return null;
  };

  return (
    <Grid className="container">
      <Column className="technical-issue">
        <Spacing bottom="spacing4x" />
        <Breadcrumbs />
        {renderImage()}
        <Spacing bottom="spacing4x" />
        <TextStyle alias="HeadingA" element="h1">{renderErrorHeader()}</TextStyle>
        <Spacing bottom="spacing2x" />
        <TextStyle alias="Base" element="p">{renderErrorMessage()}</TextStyle>
        {typeof errorObject?.message !== 'undefined' &&
          !_.has(errorObject?.path, 'one-time-pin') &&
          renderDownStreamErrorCode()}
        <Spacing bottom="spacing2x" />

        {renderCta()}

        <Spacing bottom="spacing10x" />
      </Column>
    </Grid>
  );
};

export default TechnicalIssue;
