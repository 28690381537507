import { SET_RS_ERROR } from 'constants/actions';

const defaultState = {
    errorKey: null,
    hasRSError: false
};

export const error = (state = defaultState, action) => {
    switch (action.type) {
        case SET_RS_ERROR: {
            return {
                ...state,
                ...action.payload
            };
        }
        default:
            return state;
    }
};