import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AppLog from 'utils/AppLog';
import * as Analytics from 'utils/analytics';

import { Breadcrumbs } from 'components';
import { Grid, Column, ActionButton, TextStyle } from '@able/react';

import PointsTable from 'components/RecentActivity/PointsTable/PointsTable';

import './PointsHistoryView.scss';

const PointsHistoryView = props => {

  useEffect(() => {

    const log = new AppLog('PointsHistoryView');
    log.debug('Points view mounted');
    Analytics.setPageName('Points - Transaction History');
    Analytics.addEvent({
      eventAction: 'loyaltyPointsHistory'
    });
  }, []);

  const transactions = useSelector(state => state.pointsHistory.transactions);
  const activeAccount = useSelector(state => state.accounts);

  const pointsCommad = parseInt(activeAccount.pointsValue).toLocaleString(
    navigator.language,
    {
      minimumFractionDigits: 0
    }
  );

  let parsedPoints = '--';
  if (!isNaN(parseInt(activeAccount.pointsValue)))
    parsedPoints = pointsCommad;

  const transactionCount = transactions.length;

  return (
    <Grid className="points-history-page container">
      <Breadcrumbs />
      <Column cols="6" offset="2">
        <div className="points-container">
          <TextStyle alias="BaseBig" element="p" className="title">Points</TextStyle>
          <TextStyle alias="BaseBig" element="p" className="points-value">{parsedPoints}</TextStyle>
          <TextStyle alias="BaseBig" element="p" className="callout">
            Your points may take approx. 10 business days to appear in your
            account
          </TextStyle>
          {activeAccount.sortedAccounts &&
            activeAccount.sortedAccounts.length > 1 && (
              <ActionButton
                element="Link"
                variant="LowEmphasis"
                to="/transfer"
                label="Transfer points to another member"
              />
            )}
        </div>
        <div className="activity-container">
          <TextStyle alias="HeadingD" element="h3">Your activity</TextStyle>
          <TextStyle alias="Base" element="p">
            It may take approx. 10 business days for your points activity to be
            updated
          </TextStyle>
          <TextStyle alias="Base" element="p">
            Showing {transactionCount} most recent transactions
          </TextStyle>

          <PointsTable transactions={transactions} />
        </div>
        <div className="clear-div-xl" />
      </Column>
    </Grid>
  );
}

export default PointsHistoryView;

PointsHistoryView.defaultProps = {
  activeAccount: {
    pointsValue: '--'
  },
  pointsHistory: {
    transactions: {}
  }
};
