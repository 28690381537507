import { UserManager } from 'oidc-client';
import { REDIRECT_SAVED_LOCATION_REF } from 'constants/general';
import queryString from 'query-string';
import { stopSiteActivity } from 'utils/siteActivity';
import * as actions from 'constants/actions';
import { oidcConfig } from './oidc-configs';

import store from '../store';

// Turn on debugging for OIDC (add Log back to imports)
// Log.logger = console;
// Log.level = Log.DEBUG;

let user = null;
const loaConfig = { ...oidcConfig, acr_values: 'LOA2' };
const IN_DEV_WITH_MOCKS = () => {
  const { REACT_APP_ENV } = process.env;

  return REACT_APP_ENV === 'dev-mocks' || REACT_APP_ENV === 'ci';
};

export const manager = new UserManager(oidcConfig); // Create updated oidc config adding the LOA2 attribute
export const managerloa2 = new UserManager(loaConfig);

export const mockSignInCallback = loaLevel => {
  if (loaLevel === 'LOA2') {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          access_token: 'mockedAccessToken',
          profile: {
            sub: '62107@gmail.com',
            jti: '6iPuugpMLVkaJR0sDP7Iw5',
            acr: 'LOA2',
            auth_time: 1619048123,
            tdi_guid: 'G02000108899',
            b2c_uid: 'b501618c-c4b1-4cab-89d6-e68021ba048d',
            'pi.sri': 'wVzXLjWakJ4PbmZRZDbHB0tj3_E..gLat'
          }
        });
      }, 500);
    });
  }
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        access_token: 'mockedAccessToken',
        profile: {
          sub: '62107@gmail.com',
          jti: '6iPuugpMLVkaJR0sDP7Iw5',
          acr: 'LOA1',
          auth_time: 1619048123,
          tdi_guid: 'G02000108899',
          b2c_uid: 'b501618c-c4b1-4cab-89d6-e68021ba048d',
          'pi.sri': 'wVzXLjWakJ4PbmZRZDbHB0tj3_E..gLat'
        }
      });
    }, 500);
  });
};

export const signIn = ({ url, loaStepUp, opts } = {}) => {
  // TODO explicitly create an object similar to window.location for now.
  const goTo = url ? { pathname: url } : window.location;
  // TODO migrate to oidc state
  localStorage.setItem(REDIRECT_SAVED_LOCATION_REF, JSON.stringify(goTo));
  localStorage.setItem('signinRoute', url);

  // Check for step up flag, if true send user to 2fa flow
  if (loaStepUp) {
    if (IN_DEV_WITH_MOCKS()) {
      // Simulates successfull silent SSO
      return mockSignInCallback('LOA2').then(passedUser => {
        user = passedUser;
        store.dispatch({
          type: actions.SET_LOAFLOWSTATUS,
          payload: { status: 'success', opts }
        });
        return user;
      });
    }

    // Clearing out all current user info then kicking off to LOA2 flow
    // This flow should result in user still coming back with LOA1 or LOA2 status
    // NB sending through an object via state attribute to pick up once we have gone through caiman, only for this step up flow
    manager.removeUser();
    manager.clearStaleState();
    stopSiteActivity();
    return managerloa2.signinRedirect({
      state: JSON.stringify({
        type: 'loa',
        status: 'started',
        prevLocation: goTo,
        opts
      })
    });
  }

  return manager.signinRedirect({
    prompt: 'login',
    state: JSON.stringify({
      prevLocation: goTo
    })
  });
};

// For unit tests
const exportFns = {
  signIn,
  manager
};

export const loadUser = () => {
  return manager.getUser();
};

export const signOut = () => {
  manager.removeUser();
  manager.clearStaleState();
  stopSiteActivity();
  window.location.assign(oidcConfig.post_logout_redirect_uri);
};

export function signInRedirect({ url, caiman, prompt } = {}) {
  // TODO explicitly create an object similar to window.location for now.
  const goTo = url
    ? {
        pathname: url
      }
    : window.location;

  if (IN_DEV_WITH_MOCKS()) {
    // Simulates successfull silent SSO
    return mockSignInCallback().then(passedUser => {
      user = passedUser;
      return user;
    });
  }
  return manager.signinRedirect({
    prompt: prompt || 'none',
    state: JSON.stringify({
      prevLocation: goTo,
      ...caiman
    })
  });
}

export function signInSilentCallback() {
  return manager.signinSilentCallback();
}

export function isLoggedIn() {
  return user != null && !user.expired;
}

export function startAuthentication() {
  exportFns.manager.clearStaleState(null).then(() => {
    const args = {};
    exportFns.manager.signinRedirect(args);
  });
}

export function completeAuthentication() {
  return new Promise((resolve, reject) => {
    // if in mocks then mock all the user data so the
    // auth flow 'just works'

    if (IN_DEV_WITH_MOCKS()) {
      return mockSignInCallback().then(passedUser => {
        user = passedUser;
        resolve(user);
      });
    }

    return exportFns.manager
      .signinRedirectCallback()
      .then(passedUser => {
        const { profile = {}, state } = passedUser;
        const { acr = '' } = profile;

        let decodedState;

        // Checking for any returned objects we sent through CAIMAN flow
        if (state) {
          decodedState = JSON.parse(state);
          const { type = '', status = '', opts = {} } = decodedState;
          // Pick up the type of flow we sent through CAIMAN

          switch (true) {
            case type === 'loa' && status === 'started' && acr === 'LOA1': {
              // We have tried to step up and failed for whatever reason
              store.dispatch({
                type: actions.SET_LOAFLOWSTATUS,
                payload: {
                  status: 'failed',
                  opts
                }
              });
              break;
            }
            case type === 'loa' && status === 'started' && acr === 'LOA2': {
              // We have tried to step up and succeeded
              store.dispatch({
                type: actions.SET_LOAFLOWSTATUS,
                payload: { status: 'success', opts }
              });
              break;
            }
            case type === 'loa' && status === 'denied' && acr === 'LOA1': {
              // User decided to skip and CAIMAN returned access_denied error
              store.dispatch({
                type: actions.SET_LOAFLOWSTATUS,
                payload: { status: 'denied', opts }
              });
              break;
            }
            default:
              break;
          }
        }
        resolve(passedUser);
      })
      .catch(error => {
        if (!IN_DEV_WITH_MOCKS()) {
          reject(error);
        }
      });
  });
}

export function setAuthorizationToken(passedUser) {
  user = passedUser;
  return user;
}

export function getAuthorizationToken() {
  return (user && user.access_token) || null;
}

// Get current user level of authority
export const checkLoa = () => {
  const { profile = {} } = user;
  const { acr = '' } = profile;
  return acr;
};

export const autoSigninCheck = () => {
  const queries = queryString.parse(window.location?.search);
  const { slg } = queries;
  const { pathname } = window.location;
  if (pathname === '/signin' || slg === '1') {
    return exportFns.signIn();
  }
  return Promise.resolve();
};

export const handleCaimanError = error => {
  return new Promise((resolve, reject) => {
    switch (error) {
      case 'login_required': {
        resolve('continue_to_unauth');
        break;
      }
      // Catch LOA error
      case 'access_denied': {
        // localStorage.setItem('tplus-caiman-error', error);
        resolve('access_denied_caught');
        break;
      }
      case 'some_specific_error_we_want_to_throw_from_caiman': {
        reject(new Error('Whoops!'));
        break;
      }
      default:
        resolve('continue_to_unauth');
        break;
    }
  });
};

export default exportFns;
