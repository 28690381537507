import React, { useEffect, useState, lazy, Suspense } from 'react';
import { TextStyle, Spacing } from '@able/react';
import { HtmlConverter } from 'utils/HtmlConverter';
// import Lottie from 'lottie-web';
const Lottie = lazy(() => import('lottie-react'));
import { useMedia } from 'utils/hooks/useMedia';
import { Timeout } from 'utils/Timeout';

import { Cta } from 'components';

import './PageHeaderV2.scss';

const PageHeaderV2 = props => {
  const {
    paddingSpacing = '',
    pageHeaderVariation = '',
    isTitleHeader = '',
    showSectionNav = '',
    productLogoDesktop = '',
    productLogoMobile = '',
    bodyCopy,
    desktopImageUrl,
    tabletImageUrl,
    tabletMiniImageUrl,
    mobileImageUrl,
    imageText,
    productLogoAltText = '',
    pageHeadingSize = '',
    pageHeading = '',
    phMediaType,
    animationContentPath,
    animationDelay = 3000,
    animationDescription,
    animationContentPathMobile,
    fragmentPath,
    messageTextContent,
    ableIconNameContent,
    ableIconSpriteSheetPath,
    linkUrlContent,
    linkTextContent,
    animationContentPathLandscape,
    animationContentPathPortrait,
    terms,
    pageTitleExpFragment,
    ctaConfig,
    brandColorOption,
    brandRoundedImageCorner = ''
  } = props;

  const breakpoint = useMedia(
    ['(min-width: 1210px)', '(min-width: 64rem)', '(min-width: 48rem)'],
    ['lg', 'md', 'sm'],
    'xs'
  );

  const [lottieDesktop, setLottieDesktop] = useState(null);
  const [lottieLandscape, setLottieLandscape] = useState(null);
  const [lottiePortrait, setLottiePortrait] = useState(null);
  const [lottieMobile, setLottieMobile] = useState(null);
  const noImage = pageHeaderVariation === 'noImage';

  const fetchLottie = bp => {
    switch (bp) {
      case 'lg': {
        if (animationContentPath && !lottieDesktop) {
          fetch(animationContentPath)
            .then(response => response.json())
            .then(data => setLottieDesktop(data));
        }
        break;
      }
      case 'md': {
        if (animationContentPathLandscape && !lottieLandscape) {
          fetch(animationContentPathLandscape)
            .then(response => response.json())
            .then(data => setLottieLandscape(data));
        }
        break;
      }
      case 'sm': {
        if (animationContentPathPortrait && !lottiePortrait) {
          fetch(animationContentPathPortrait)
            .then(response => response.json())
            .then(data => setLottiePortrait(data));
        }
        break;
      }
      default: {
        if (animationContentPathMobile && !lottieMobile) {
          fetch(animationContentPathMobile)
            .then(response => response.json())
            .then(data => setLottieMobile(data));
        }
      }
    }
  };

  useEffect(() => {
    if (animationContentPath) {
      fetchLottie(breakpoint);
    }
  }, [breakpoint, animationContentPath]);

  const setupSelectArray = () => {
    const selectArray = [];
    if (ctaConfig) {
      Object.keys(ctaConfig).forEach(i => {
        const obj = ctaConfig[i];
        selectArray.push(obj);
      });
    }
    return selectArray;
  };

  const hasTertiary = () => {
    let isTertiary = false;
    if (ctaConfig) {
      Object.keys(ctaConfig).forEach(i => {
        const { ctaVariant } = ctaConfig[i];
        if (ctaVariant === 'LowEmphasis') {
          isTertiary = true;
        }
      });
    }
    return isTertiary;
  };

  return (
    <>
      <div
        className={`tplus-npageheader-container ${
          paddingSpacing === 'small' && showSectionNav !== 'true'
            ? 'tplus-npageheader-spacing--small'
            : ''
        } ${
          paddingSpacing === 'large' && showSectionNav !== 'true'
            ? 'tplus-npageheader-spacing'
            : ''
        } ${noImage && 'noImage'} ${brandColorOption}`}
      >
        {pageTitleExpFragment && (
          <div
            className={`tplus-npageheader-wrapper__background ${
              pageHeaderVariation === 'noImage'
                ? 'tplus-npageheader-wrapper__background--noImage'
                : ''
            }`}
          >
            <div
              className={`tplus-npageheader-wrapper__title ${
                pageHeaderVariation === 'noImage'
                  ? 'tplus-npageheader-wrapper__title--noImage'
                  : ''
                } 
                        ${
                          noImage && isTitleHeader === 'true'
                            ? 'tplus-npageheader-wrapper__title--title'
                            : ''
                        }`}
              >
              <TextStyle
                element="h1"
                className={`tplus-npageheader-title__text ${
                  pageHeaderVariation !== 'noImage'
                    ? 'tplus-able__text-body-short'
                    : ''
                } ${
                  pageHeaderVariation === 'noImage' && isTitleHeader !== 'true'
                    ? 'tplus-able__text-heading-d'
                    : ''
                } ${
                  pageHeaderVariation === 'noImage' && isTitleHeader === 'true'
                    ? 'tplus-able__text-heading-a'
                    : ''
                }`}
              >
                {pageTitleExpFragment}
              </TextStyle>
            </div>
          </div>
        )}

        {((noImage && (bodyCopy || terms || ctaConfig)) || !noImage) && (
          <div
            className={`tplus-npageheader-wrapper ${
              pageHeaderVariation === 'short'
                ? 'tplus-npageheader-wrapper--short'
                : ''
            } 
                    ${
                      pageHeaderVariation === 'noImage'
                        ? 'tplus-npageheader-wrapper--noImage'
                        : ''
                    }`}
          >
            {!noImage && (
              <div className="tplus-npageheader-gradient tplus-npageheader-background-image"></div>
            )}

            <div
              className={`tplus-npageheader-wrapper__container ${
                pageHeaderVariation === 'noImage'
                  ? 'tplus-npageheader-wrapper__container--noImage'
                  : ''
              }`}
            >
              <div
                className={`tplus-npageheader-wrapper__content ${
                  pageHeaderVariation === 'noImage'
                    ? 'tplus-npageheader-wrapper__content--noImage'
                    : ''
                } 
                        ${
                          pageHeaderVariation === 'short'
                            ? 'tplus-npageheader-wrapper__content--short'
                            : ''
                        }`}
              >
                {!noImage && (productLogoDesktop || productLogoMobile) && (
                  <div className="tplus-npageheader-content__imageLogo">
                    {productLogoDesktop && (
                      <img
                        className="tplus-npageheader-imageLogo__desktop"
                        alt={productLogoAltText}
                        src={productLogoDesktop}
                      />
                    )}
                    {productLogoMobile && (
                      <img
                        className="tplus-npageheader-imageLogo__mobile"
                        alt={productLogoAltText}
                        src={productLogoMobile}
                      />
                    )}
                  </div>
                )}

                {((isTitleHeader !== 'true' && noImage) || !noImage) && (
                  <Spacing bottom="spacing2x">
                    <TextStyle
                      alias="HeadingA"
                      element="h2"
                      className={`tplus-npageheader-content__headline ${
                        pageHeadingSize === 'extralarge' && !noImage
                          ? 'tplus-npageheader-content__headline--xlarge'
                          : ''
                      }`}
                    >
                      {pageHeading}
                    </TextStyle>
                  </Spacing>
                )}
                {bodyCopy && (
                  <Spacing bottom="spacing2x">
                    <TextStyle
                      alias="BaseBig"
                      element="p"
                      className="tplus-npageheader-content__description"
                    >
                      {HtmlConverter(bodyCopy)}
                    </TextStyle>
                  </Spacing>
                )}
                {terms && (
                  <Spacing bottom="spacing2x">
                    <TextStyle
                      alias="FinePrint"
                      element="p"
                      className="tplus-npageheader-content__tnc"
                    >
                      {HtmlConverter(terms)}
                    </TextStyle>
                  </Spacing>
                )}
                <div
                  className={`tplus-npageheader-content__buttons ${
                    noImage ? 'tplus-npageheader-content__buttons--noImage' : ''
                  } 
                            ${
                              hasTertiary()
                                ? 'tplus-npageheader-content__buttons--tertiary'
                                : ''
                            }`}
                >
                  <div className="tplus-npageheader-content-cta-primary-secondary-wrapper">
                    {setupSelectArray() &&
                      setupSelectArray()
                        .filter(function(cta) {
                          return cta.ctaVariant !== 'LowEmphasis';
                        })
                        .map(cta1 => (
                          <div className={`tplus-npageheader-content-cta--${cta1.ctaVariant === 'HighEmphasis' ? 'primary' : 'secondary'}`}>
                            <Cta
                              action={cta1.ctaAction}
                              trackingCode={cta1.ctaTrackingCode}
                              url={cta1.ctaUrl}
                              ctaProps={{
                                variant: cta1.ctaVariant,
                                label: cta1.ctaText
                              }}
                            ></Cta>
                          </div>
                        ))}
                  </div>
                  {hasTertiary() && (
                    <div className="tplus-npageheader-content-cta-tertiary-wrapper test">
                      {setupSelectArray() &&
                        setupSelectArray()
                          .filter(function(cta) {
                            return cta.ctaVariant === 'LowEmphasis';
                          })
                          .map(cta1 => (
                            <div className="tplus-npageheader-content-cta--tertiary">
                              <Cta
                                action={cta1.ctaAction}
                                trackingCode={cta1.ctaTrackingCode}
                                url={cta1.ctaUrl}
                                ctaProps={{
                                  variant: cta1.ctaVariant,
                                  label: cta1.ctaText
                                }}
                              ></Cta>
                            </div>
                          ))}
                    </div>
                  )}
                </div>
              </div>

              {!noImage && (
                <div className={`tplus-npageheader-mainImage tplus-npageheader-image-corner--${brandRoundedImageCorner}`}>
                  {phMediaType === 'headerimage' && (
                    <picture>
                      <source
                        media="(min-width:1210px)"
                        srcSet={desktopImageUrl}
                      />
                      <source
                        media="(min-width:1024px) and (max-width:1210px)"
                        srcSet={tabletImageUrl}
                      />
                      <source
                        media="(min-width:768px) and (max-width:1023px)"
                        srcSet={tabletMiniImageUrl}
                      />
                      <source
                        media="(min-width:0px) and (max-width:767px)"
                        srcSet={mobileImageUrl}
                      />
                      <img src={desktopImageUrl} alt={imageText || ' '} />
                    </picture>
                  )}
                  {phMediaType === 'animation' && (
                    <div>
                      <div className="tplus-npageheader-animation">
                        {animationDescription && (
                          <p className="sr-only">{animationDescription}</p>
                        )}
                        <div className="main">
                        <Timeout delay={animationDelay}>
                            <Suspense>
                              { breakpoint === 'lg' && (
                                <Lottie animationData={lottieDesktop} loop={false} />
                              )}
                              { breakpoint === 'md' && (
                                <Lottie animationData={lottieLandscape} loop={false} />
                              )}
                              { breakpoint === 'sm' && (
                                <Lottie animationData={lottiePortrait} loop={false} />
                              )}
                              { breakpoint === 'xs' && (
                                <Lottie animationData={lottieMobile} loop={false} />
                              )}
                            </Suspense>
                          </Timeout>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        {!fragmentPath && !noImage && (
          <div className="tplus-npageheader-brandmessage--off"></div>
        )}
        {fragmentPath && !noImage && (
          <div className="tplus-npageheader-brandmessage">
            <div className="tplus-npageheader-brandmessage__wrapper">
              <svg
                className="tplus-npageheader-brandmessage__icon tplus-icon-32"
                tabIndex="-1"
                focusable="false"
                aria-hidden="true"
              >
                <use
                  href={`${ableIconSpriteSheetPath}/able-sprites.svg#${ableIconNameContent.contentFromElementName}`}
                ></use>
              </svg>
              <span className="tplus-npageheader-brandmessage__text">
                {messageTextContent.contentFromElementName}
                {linkUrlContent.contentFromElementName &&
                  linkTextContent.contentFromElementName && (
                    <a
                      className="tplus-npageheader-brandmessage__link"
                      href={linkUrlContent.contentFromElementName}
                    >
                      {linkTextContent.contentFromElementName}
                    </a>
                  )}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PageHeaderV2;
