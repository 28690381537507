import axios from './util/axiosHandle';
import { apiConfig as api } from '../oidc/oidc-configs';

const getOrdersApi = () => {
  return axios({
    params: {
      method: 'get',
      url: api.baseUrl + api.endpoints.getOrders
    }
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export default getOrdersApi;
