import React from 'react';
import { useSelector } from 'react-redux';
import { formatCurrency } from 'utils/currency';
import { formatDate } from 'utils/formatDate';
import { getButtonType } from 'utils/checkExternalLink';
import _ from 'lodash';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import {
  Grid,
  Column,
  Spacing,
  Surface,
  TextStyle,
  Icon,
  ActionButton
} from '@able/react';
import './MembershipDashboard.scss';

dayjs.extend(duration);

const MembershipDashboard = props => {
  const {
    pointsExpiryUrl = '',
    rewardsStoreUrl = '',
    howItWorksUrl = '',
    benefitsUrl = ''
  } = props;
  const loyaltyTier = useSelector(state => state.accounts.loyaltyTier);
  const pointsValue = useSelector(state => state.accounts.pointsValue);
  const pointsExpiryTrans = useSelector(
    state => state.pointsHistory.pointsExpiry
  );
  const pointsExpiry = pointsExpiryTrans.transactionDate
    ? pointsExpiryTrans
    : false;

  const accountRegisteredDate = useSelector(
    state => state.accounts.accountRegisteredDate
  );
  const amountSpent = useSelector(state => state.accounts.loyaltyRolledSum);

  const getParsedNumber = points => {
    return parseInt(points).toLocaleString(navigator.language, {
      minimumFractionDigits: 0
    });
  };

  const spendToDate = () => (
    <div className="tplus-membership-dashboard_spendtodate">
      <Icon
        icon="Payment"
        size="24"
        screenReaderContent="Payment icon"
        developmentUrl="/able-sprites.svg"
        className="tplus-membership-dashboard_spend_icon"
        tabIndex="-1"
      />
      <TextStyle alias="HeadingD" element="h3">My spend to date</TextStyle>
      <Spacing top="spacing1x" />
      <TextStyle alias="Subheading" element="span">{formatCurrency(amountSpent)}</TextStyle>
      <Spacing top="spacing2x" />
    </div>
  );

  const getExpiryRemainingDays = transactionDate => {
    const anniversaryDate = new Date(transactionDate);
    const currentDate = new Date();

    // To calculate the time difference of two dates
    const timeDiff = anniversaryDate.getTime() - currentDate.getTime();

    // To calculate the no. of days between two dates
    const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return daysLeft;
  }

  const getExpiryCopy = (daysLeft) => {
    // eslint-disable-next-line no-nested-ternary
    return daysLeft > 90 ? 'Points expiring' : (daysLeft < 31 ? `Points expiring in  ${daysLeft} days`
            : 'Points expiring soon');
  }

  const expiringPoints = () => {
    const { transactionDate = '', pointsEarned = 0 } = pointsExpiry;
    const element = getButtonType(pointsExpiryUrl);
    const daysLeft = getExpiryRemainingDays(transactionDate);
    return (
      <div className={`tplus-membership-dashboard_expiry_container ${daysLeft > 90 ? 'attention' : 'warning'}`}>
        <Spacing
          left="spacing7x"
          top="spacing1nHalf"
          bottom="spacingHalf"
          right="spacing2x"
        >
          { daysLeft > 90 ?
            <Icon
              icon="Attention"
              size="24"
              screenReaderContent="Attention icon"
              developmentUrl="/able-sprites.svg"
              className="tplus-membership-dashboard_attention_icon"
              tabindex="-1"
            />
            :
            <Icon
              icon="Warning"
              size="24"
              screenReaderContent="Warning icon"
              developmentUrl="/able-sprites.svg"
              className="tplus-membership-dashboard_warning_icon"
              tabindex="-1"
            />
          }
          <TextStyle alias="HeadingD" element="h3">
            {getExpiryCopy(daysLeft)}
          </TextStyle>
          <TextStyle alias="Subheading" element="span">
            {getParsedNumber(pointsEarned)}{' '}
            <TextStyle element="span" alias="BaseBig">
              points
            </TextStyle>
          </TextStyle>
          <Spacing top="spacing2x" />
          <TextStyle alias="Base">
            Redeem before: {formatDate(transactionDate)}
          </TextStyle>
          <Spacing top="spacing2x" />
          <TextStyle alias="FinePrint" colour="Subtle" element="p">
            Only unused points earned in your first membership year will expire at this time.
          </TextStyle>
          <ActionButton
            label="Learn about points expiry"
            element={element}
            variant="LowEmphasis"
            className="button-tplus"
            to={pointsExpiryUrl}
          />
        </Spacing>
      </div>
    );
  };

  const tierPanel = () => {
    const element = getButtonType(rewardsStoreUrl);
    return (
      <div className="tplus-membership-dashboard_tier_container tier-panel">
        <div className="tier-gradient" />
        <div className="tier-col">
          <Spacing
            top="spacing3x"
            left="spacing3x"
            right="spacing3x"
            bottom="spacing3x"
          >
            <ul>
              <li>
                <Spacing right="spacing3x">
                  <TextStyle element="h2" alias="HeadingA" className="heading">
                    {loyaltyTier && _.capitalize(loyaltyTier)}{' '}
                    <TextStyle
                      element="span"
                      alias="HeadingD"
                      className="text-style-span"
                    >
                      tier
                    </TextStyle>
                  </TextStyle>
                </Spacing>
              </li>
              <li>
                <TextStyle element="span" alias="Subheading">
                  {pointsValue ? getParsedNumber(pointsValue) : 0}{' '}
                  <TextStyle
                    element="span"
                    alias="BaseBig"
                    className="text-style-span"
                  >
                    points
                  </TextStyle>
                </TextStyle>
              </li>
            </ul>
          </Spacing>
        </div>
        <div className="button-col">
          <Spacing
            topVMD="spacing3x"
            topVSM="spacing3x"
            topVLG="spacing3x"
            left="spacing3x"
            leftVXS="spacing2x"
            right="spacing3x"
            rightVXS="spacing2x"
            bottomVXS="spacing3x"
          >
            <ActionButton
              developmentUrl="/able-sprites.svg"
              element={element}
              label="Redeem points"
              to={rewardsStoreUrl}
              variant="HighEmphasis"
              className="button-tplus"
            />
          </Spacing>
        </div>
      </div>
    );
  };

  const anniversaryDate = () => {
    let returnDate;
    if (accountRegisteredDate) {
      const currDate = dayjs();
      const registrationDate = dayjs(accountRegisteredDate);
      const diffDuration = dayjs.duration(currDate.diff(registrationDate));
      const nYears = diffDuration.years();
      returnDate = dayjs(registrationDate).add(nYears, 'years');
      if (!dayjs(returnDate).isSame(currDate, 'day')) {
        returnDate = dayjs(registrationDate).add(nYears + 1, 'years');
      }
    }
    return dayjs(returnDate).format('DD MMM YYYY');
  };

  const anniversary = () => {
    let tierStatusText = '';
    let ctaLabel = 'See how it works';
    let ctaUrl = howItWorksUrl;
    let element = getButtonType(ctaUrl);

    switch (loyaltyTier) {
      case 'SILVER':
        tierStatusText = 'Spend $3000 on services per year to unlock Gold tier.';
        break;
      case 'GOLD':
        tierStatusText = 'Spend $3000 on services per year to maintain your Gold tier status.';
        break;
      case 'BUSINESS':
        tierStatusText = 'Explore the benefits for customers with an eligible business service.';
        ctaLabel = 'See benefits';
        ctaUrl = benefitsUrl;
        element = getButtonType(ctaUrl);
        break;
      default:
        tierStatusText = 'Spend $1500 on services per year to unlock Silver tier.';
    }

    return (
      <>
        <TextStyle alias="FinePrint">
          Anniversary date: {anniversaryDate()}
        </TextStyle>
        <Spacing top="spacing2x" />
        <TextStyle alias="FinePrint" colour="Subtle" element="p">
          {tierStatusText}
        </TextStyle>
        <ActionButton
          label={ctaLabel}
          element={element}
          variant="LowEmphasis"
          className="button-tplus"
          to={ctaUrl}
        />
      </>
    );
  };

  return (
    <>
      <Surface
        variant="SurfaceMid"
        className={`tplus-membership-dashboard ${loyaltyTier}`}
      >
        <div className="tplus-membership-dashboard_main_div" role="status">
          <Grid>
            <Column cols={12}>{tierPanel()}</Column>
            <Column
              bsx={12}
              bsm={6}
              className="tplus-membership-dashboard_left_column"
            >
              {pointsExpiry ? (
                <Spacing bottomVXS="spacing3x">{expiringPoints()}</Spacing>
              ) : (
                <Spacing left="spacing7x" top="spacing3x" topVXS="spacing2x">
                  {!pointsExpiry && spendToDate()}
                </Spacing>
              )}
            </Column>
            <Column bsx={12} bsm={6}>
              <Spacing
                left={pointsExpiry ? 'spacing4x' : 'spacingHalf'}
                leftVXS="spacing7x"
                right="spacing2x"
                topVSM="spacing3x"
                topVMD="spacing3x"
                topVLG="spacing3x"
                bottom="spacing3x"
              >
                {pointsExpiry && spendToDate()}
                {anniversary()}
              </Spacing>
            </Column>
          </Grid>
        </div>
      </Surface>
    </>
  );
};

export default MembershipDashboard;
