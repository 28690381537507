import { combineReducers } from 'redux';
import _ from 'lodash';

import * as actions from 'constants/actions';

const totalPoints = (state = '--', action) => {
  switch (action.type) {
    case actions.GET_POINTS_SUCCESS:
      // parse response data
      if (_.has(action.response, 'data')) {
        const { data } = action.response;
        // will need to do some fancy mutations for MCAC
        // and prettify this reducer
        return data.loyalty.accounts[0].points.loyaltyPoints.toString();
      }

      return state;

    default:
      return state;
  }
};

const pending = (state = false, action) => {
  switch (action.type) {
    case actions.GET_POINTS_PENDING:
      return true;
    case actions.GET_POINTS_SUCCESS:
    case actions.GET_POINTS_FAILURE:
      return false;
    default:
      return state;
  }
};

const failure = (state = false, action) => {
  switch (action.type) {
    case actions.GET_POINTS_FAILURE:
      return true;
    case actions.GET_POINTS_SUCCESS:
    case actions.GET_POINTS_PENDING:
      return false;
    default:
      return state;
  }
};

// STORING API RESPONSE IN REDUX STATE TO AVOID
// POSSIBLY MAKING MULTIPLE CALLS.
// THIS IS IN MIND OF "POSSIBLE" FUTURE USECASES
const apiResponse = (state = null, action) => {
  switch (action.type) {
    case actions.GET_POINTS_SUCCESS:
      // Parse response data
      if (_.has(action.response, 'data')) {
        const { data } = action.response;
        return { ...data };
      }

      return state;

    default:
      return state;
  }
};

const reducers = combineReducers({
  totalPoints,
  pending,
  failure,
  apiResponse
});

export default reducers;

export const fetchPointsPending = state => state.getPointsPending;
