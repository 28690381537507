export const ProductFamilyType =
{
    PRODUCT: "PRODUCT",
    MOBILE_PLAN: "MOBILE_PLAN",
    MBB_PLAN: "MBB_PLAN",
    DATA_PLAN: "DATA_PLAN",
    ADDON: "ADDON",
    MOBILE: "MOBILE",
    TABLET: "TABLET",
    MBB: "MBB",
    GAMING: "GAMING",
    POSTAGE: "POSTAGE",
    DIGITAL_CODE: "DIGITAL_CODE",
    DIGITAL_PROMO: "DIGITAL_PROMO",
    PREPAID_VOUCHER: "PREPAID_VOUCHER"
}
