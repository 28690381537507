import { createSelector } from 'reselect';
import _ from 'lodash';

import { getDataByPage } from './utils/GetDataByPage';

// get member tier
const getTier = state => state.accounts.loyaltyTier;
// get aem verticaltabs
const getVerticalTabs = state => getDataByPage(state).accordions;

// grab and sort the verticaltabs
export const getVerticalTabsState = createSelector(
  [getVerticalTabs, getTier],
  (verticalTabs, tier) => {
    // get all verticaltabs
    // sort by active and match tier, should only ever match one vertical tab (accordion) by id
    const sortedVerticalTabs = _.filter(verticalTabs, {
      type: 'verticaltab',
      active: 'true',
      tier
    });

    const normalizedTabs = _.map(sortedVerticalTabs, tab => {
      const newTab = {
        id: tab.id,
        title: tab.title,
        showOnChildpage: tab.showOnChildpage
      };

      if (tab.tabs) {
        newTab.array = Object.values(tab.tabs);
      }

      if (tab.ctacopy && tab.ctaurl) {
        const footer = { text: tab.ctacopy, url: tab.ctaurl, tracking: tab.ctaTracking };
        newTab.footer = footer;
      }

      return newTab;
    });

    return normalizedTabs;
  }
);
