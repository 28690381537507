import React from 'react';
import _ from 'lodash';
import SetEventCallback from './SetEventCallback';

import './Usabilla.scss';

const Usabilla = () => {
  // Hide GetFeedback Digital Default button
  if (_.has(window, 'usabilla_live')) {
    window.usabilla_live('hide');
  }

  const handleClick = () => {
    if (_.has(window, 'usabilla_live')) {
      window.usabilla_live('click');
      SetEventCallback();
    }
  };

  return (
    <button tabIndex="0" className="tplus-websiteFeedback__button__usabilla" onClick={() => handleClick()} type="button">
      Website Feedback
    </button>
  );
};

export default Usabilla;
