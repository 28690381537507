import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { ActionButton, Spacing, Grid, Column, TextStyle, Icon, InlineLink } from '@able/react';

import enrolCustomer from 'actions/enrolment';
import updateContactDetails from 'actions/updateContactDetails';
import fetchCustomerDetails from 'actions/account';
import { fetchPoints, fetchPointsHistory } from 'actions/points';
import * as actions from 'constants/actions';
import { Checkbox, OtpFlow } from 'components';
import { clearAllErrors } from 'components/OtpFlow/utils/helpers';

import { getPageConfig } from 'selectors/GetConfig';
import { addLinkClickEvent } from 'utils/analytics';
import { Helmet } from 'react-helmet';

import 'utils/forms/formsPages.scss';
import './EnrolmentView.scss';

const EnrolmentView = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();

  const pageConfig = useSelector(state => getPageConfig(state));
  const { contactcapture: ccConfig = {} } = pageConfig;
  const {
    email: ccEmailFlag = {},
    mobile: ccMobileFlag = {},
    address: ccAddressFlag = {},
    addressLoaCheck: bdAddressLoaFlag = {},
    activated: ccActivated = true
  } = ccConfig;
  const { activated: ccEmailFlagActivated } = ccEmailFlag;
  const { activated: ccMobileFlagActivated } = ccMobileFlag;
  const { activated: ccAddressFlagActivated } = ccAddressFlag;
  const { activated: bdAddressLoaFlagActivated } = bdAddressLoaFlag;

  const otp = useSelector(state => state.otpFlow);
  const { triggered: otpTriggered } = otp;

  const updateContactDetailsState = useSelector(
    state => state.updateContactDetails
  );
  const { error: contactError } = updateContactDetailsState;
  const { code: contactErrorCode } = contactError;

  const register = () => {
    return dispatch(enrolCustomer()).then(() => {
      // clear OTP errors because we have successfully called register
      clearAllErrors({});

      // Otherwise continue loading user / success page as usual
      dispatch(fetchCustomerDetails())
        .then(() => {
          dispatch(fetchPoints());
          dispatch(fetchPointsHistory());
          dispatch({
            type: actions.SET_WELCOME,
            welcomeState: true
          });
          dispatch({
            type: actions.SET_LOADING,
            welcomeState: false
          });

          // Kick on to success page
          return history('/success');
        })
        .catch(() => {
          // localStorage.removeItem('tplus-flow');
          // dispatch({ type: actions.SET_FLOW, payload: null });
          return history('/error');
        });
    });
  };

  const handleEnrolment = () => {
    // Wipe any incoming from LOA, modal states for OTP
    dispatch({
      type: actions.SET_LOAFLOWSTATUS,
      payload: { opts: {} }
    });

    if (otpTriggered && !contactErrorCode) {
      // OTP update has been triggered so update contact details first
      // Error from update action fills in redux data, will show an error alert on OTP component
      return dispatch(updateContactDetails()).then(() => {
        // Keep going with registration if successfull
        return register();
      });
    }
    // If there has been some error with update contact OR otp has not been triggered then just keep going with registering user
    return register();
  };

  const handleClick = e => {
    e.preventDefault();
    handleEnrolment();
  };

  const buttonCopy = contactErrorCode ? 'Continue' : 'Join Telstra Plus';
  const containerClass = clsx(
    'container',
    'tplus_EnrolmentView',
    (ccEmailFlagActivated || ccMobileFlagActivated || ccAddressFlagActivated) &&
      'fields'
  );

  return (
    <>
    <Helmet>
      <title>Join Telstra Plus</title>
    </Helmet>
    <Grid className={`container forms-container ${containerClass}`}>
      <Column bmd={6}>
        <TextStyle element="h1" alias="HeadingA">
          Join Telstra Plus
        </TextStyle>
        <TextStyle element="p" alias="Base">
          Make sure we have the correct details before you continue.
        </TextStyle>
        {ccActivated && (
          <>
          <Spacing top="spacing4x"/>
          <OtpFlow
            page={location.pathname}
            email={ccEmailFlagActivated}
            emailLoa
            mobile={ccMobileFlagActivated}
            mobileLoa
            address={ccAddressFlagActivated}
            addressLoa={bdAddressLoaFlagActivated}
          />
          </>
        )}
        <Spacing bottom="spacing3x" />
            <InlineLink>
            <TextStyle alias="Base" element="label">
            By clicking on 'Join Telstra Plus',&nbsp; you agree to our &nbsp;
              <a
                href="https://www.telstra.com.au/plus/terms-and-conditions"
                rel="noreferrer"
              >
                Terms & Conditions 
              </a>
              &nbsp;and&nbsp;
              <a
                href="https://www.telstra.com.au/privacy/privacy-statement"
                rel="noopener noreferrer"
              >
                Privacy Statement
              </a>
              .
            </TextStyle>
          </InlineLink>
        <Spacing bottom="spacing5x" />
        <ActionButton
          variant="HighEmphasis"
          label={buttonCopy}
          element="button"
          events={{
            onClick: e => {
              addLinkClickEvent('enrol');
              handleClick(e);
            }
          }}
        />
        <Spacing bottom="spacing10x" />
      </Column>
    </Grid>
    </>
  );
};

export default EnrolmentView;
