import React, { useState, useEffect } from 'react';

export const Timeout = ({ delay, children }) => {

    const [isTimedOut, setIsTimedOut] = useState(false);

    useEffect(() => {

        setTimeout(() => {

            setIsTimedOut(true);

        }, delay)

    }, [])

    return (

        isTimedOut ? children : null

    )

}