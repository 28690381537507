import React from 'react';
import htmr from 'htmr';
import { Link } from 'react-router-dom';
import { addLinkClickEvent } from 'utils/analytics';

import IconArrow from 'icons/IconArrow';

const handleClickEvent = tracking => {
  if (tracking) {
    return addLinkClickEvent(tracking);
  }
  return false;
};

export const HtmlConverter = (html = '') => {
  if (html) {
    const transform = {
      iconarrow: () => <IconArrow />,
      a: node => {
        const {
          href = '',
          target = '_self',
          rel = '',
          className = '',
          referrerPolicy = '',
          children,
          tracking = ''
        } = node;
        if (href.charAt(0) === '/') {
          const to = href.replace('.html', '');
          return <Link className={className} to={to}>
            {children && children.map(child => {
                return child;
              })}
            </Link>
        }
        return (
          <a
            href={href}
            target={target}
            rel={rel.toString()}
            className={className.toString()}
            referrerPolicy={referrerPolicy}
            onClick={() => handleClickEvent(tracking)}
          >
            {children && children.map(child => {
              return child;
            })}
          </a>
        );
      }
    };
    return htmr(html, { transform });
  }
  return false;
};
