import React from 'react';

export default function IconArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      focusable="false" 
      role="presentation" 
      aria-hidden="true"
    >
      <path
        fill="currentColor"
        d="M20.8 9.9L11 0 8.8 2.1l8.4 8.4H1v3h16.2l-8.4 8.4L11 24l9.8-9.9L23 12z"
      />
    </svg>
  );
}
