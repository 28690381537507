import React from 'react';
import find from 'lodash/find';
import clsx from 'clsx';
import { createSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';

import { getAemContent } from 'selectors/GetAemContent';
import { activateSmb } from 'actions/smb';
import IconArrow from 'icons/IconArrow';
import { InlineLoadingSpinner } from 'components';
import { Icon, TextStyle } from '@able/react';
import 'styles/ctas.module.scss';
import store from '../../store';

// get smb state
const getsmbStates = state => state.smb;
// get config state
const getConfigs = state =>
  getAemContent(state, 'global/config', 'toggleconfig');
const getRole =  state => state.accounts.role;
const getTier = state => state.accounts.loyaltyTier;
const InfoIcon = () => <Icon
  size="24"
  developmentUrl="/able-sprites.svg"
  icon="Info"
  role="presentation"
/>;
export const getTTSDisplayMsg = (status, tier, childMatch) => {
  const ctaClass = childMatch ? 'button-high-emphasis' : clsx('primary-cta-18', 'cta');
  const ErrorIcon = () => <Icon
    size="24"
    developmentUrl="/able-sprites.svg"
    icon="Error"
    role="presentation"
  />;
  const GetText = props => {
    const { children } = props;
    return  (
      <TextStyle
        element="p"
        alias="Base"
        colour="Default"
        alignment="Left"
        className=""
        tabIndex="-1"
        id="fullfilled-tts"
      >
        {children}
      </TextStyle>
    );
  };

  switch (status) {
    case 'redeem':
      return (
        <button
          type="button"
          className={ctaClass}
          onClick={event => {
            event.preventDefault();
            store.dispatch(activateSmb(uuidv4() + tier));
          }}
          data-link-tracking-code="14052019:tplusspa:thanks:banner"
        >
          {childMatch ? 'Activate now' : <>Activate <IconArrow /></>} 
        </button>
      );
    case 'pending': {
      return (
        <GetText>
          {InfoIcon()} Your tier benefit will commence 2 working days after you
          activated. For urgent queries call{' '}
          <a href="tel:13 70 55">
            <u>13 70 55</u>
          </a>
          .
        </GetText>
      );
    }
    case 'complete':
      return (
        <GetText>
          {InfoIcon()} Great news! Your Telstra Business Tech Services
          Services subscription is active and ready to use. Call{' '}
          <a href="tel:13 70 55">
            <u>13 70 55</u>
          </a>
           for 24/7 support.
        </GetText>
      );
    case 'expiring soon':
      return (
        <GetText>
          {InfoIcon()} Your 3 months of Telstra Business Tech services offer
          expires soon. we’ll be in touch to let you know what happens next.
          Call{' '}
          <a href="tel:13 70 55">
            <u>13 70 55</u>
          </a>
           for support.
        </GetText>
      );
    case 'expired':
      return (
        <GetText>
          {InfoIcon()}
          {" "}
          Your 3 month offer period has now ended. Check back here again for future offers.
        </GetText>
      );
    case 'error':
      return (
        <GetText>
          {ErrorIcon()}
          {" "}
          We are not able to get the status, please check again.
        </GetText>
      );
    case 'notavailable':
      return (
        <GetText>
          {ErrorIcon()}
          {" "}
          Extras and VIP service benefits are not available for limited authority.
        </GetText>
      )
    default:
      return null;
  }
};

export const getBusinessCta = createSelector(
  [getsmbStates, getConfigs, getTier, getRole],
  (smb, config, tier, role) => {
    if (tier === 'BUSINESS') {
      const { activated, pending, error, entryDetailsPending, entry } = smb;
      const childMatch = window.location?.pathname === '/benefits' || false;
      const aemBusinessToggle = find(config, {
        parentattribute: 'smbcta'
      });
      if (role === 'LIMITED AUTHORITY') {
        return getTTSDisplayMsg('notavailable', tier, childMatch);
      }
      if (aemBusinessToggle?.isParentActivated !== 'false') {
        // Handling whether we need to show the CTA to activate or status messages
        if (!entryDetailsPending && !activated && !error && !pending) {
          // we have the response from entry details API
          if (entry && entry?.status) {
            // response from service
            return getTTSDisplayMsg(entry.status.toLowerCase(), tier, childMatch);
          }
          // It was errored, will have entryErrored object in redux
          return getTTSDisplayMsg('error', tier, childMatch);
        }
        // Same messages would be shown for SRS generation service as well
        if (activated) {
          setTimeout(() => {
            document.getElementById('fullfilled-tts').focus();
          }, 500);
          return getTTSDisplayMsg('pending', tier, childMatch);
        }
        if (pending && !error) {
          return <InlineLoadingSpinner ariaLive smallSpinner={!childMatch} loadingText="Checking..." />;
        }
        if (error) {
          return getTTSDisplayMsg('error', tier, childMatch);
        }
      }
    }
    return null;
  }
);
