import { batch } from 'react-redux';
import _ from 'lodash';
import { LOGGER } from 'utils/AppLog';
import * as actions from 'constants/actions';
import * as ERRCODES from 'constants/errorCodes.js';
import * as ERRMSGS from 'constants/errorMessages.js';
import registerApi from 'api/register';
import { push } from 'react-router-redux';
import * as Analytics from 'utils/analytics';

import { registrationPending } from 'reducers/enrolment.js';

const enrolCustomer = (opts = { flow: 'normal' }) => {
  const { flow } = opts;
  return (dispatch, getState) => {
    if (registrationPending(getState())) {
      return Promise.resolve();
    }

    Analytics.setPageName('Enrolling Now');

    batch(() => {
      dispatch({ type: actions.SET_LOADING, loading: true });
      dispatch({
        type: actions.REGISTRATION_PENDING
      });
    });

    return registerApi(opts)
      .then(response => {
        LOGGER.debug(' $$ Register Resp:', response);

        // Connection timed-out
        if (response.code === ERRCODES.ERR_CONNABORTED) {
          localStorage.removeItem('tplus-flow');
          dispatch({ type: actions.SET_FLOW, payload: null });
          LOGGER.debug(' $$ Request cancelled');
          dispatch({
            type: actions.SET_ERROR,
            hasErrored: true,
            errorMessage: ERRMSGS.REGISTRATIONERROR
          });
          dispatch(push('/error'));
          return;
        }

        // Server Returned a 200 Response
        checkIfHasData(response, flow, dispatch);
      })
      .catch(error => {
        LOGGER.debug(' $$ Caught error is: ', error);
        localStorage.removeItem('tplus-flow');
        dispatch({ type: actions.SET_FLOW, payload: null });
        // clear old reducer/otpFlow values
        // dispatch({
        //   type: GET_MOBILE_RESET
        // });
        // dispatch({
        //   type: GET_EMAIL_RESET
        // });
        // dispatch({
        //   type: GET_OTP_RESET
        // });

        // wrap this in a flow=normal condition?
        // does birthday / competition flow need to fail silently

        if (typeof error.response !== 'undefined') {
          LOGGER.debug(' $$ Enrolment response', error);

          const { code } = error.response.data;

          LOGGER.debug(' $$ Server error', code);

          checkErrorCodes(code, dispatch, error);
        } else {
          // Setting enrolFailed to true will display the error screen with a retry button
          // To retry the enrolment call

          dispatch({
            type: actions.SET_ERROR,
            hasErrored: true,
            errorMessage: ERRMSGS.REGISTRATIONERROR,
            error
          });

          dispatch({
            type: actions.SET_ENROLFAILED,
            enrolFailed: true
          });
          Analytics.setPageName('Enrolment Error');
          dispatch(push('/error'));
        }
        dispatch({ type: actions.SET_LOADING, loading: false });
        throw error;
      });
  };
};

const checkErrorCodes = (code, dispatch, error) => {
  if (code === ERRCODES.ERR_LA_CAC ||
    code === ERRCODES.ERR_USER_REL_ACCT_EXPIRED) {
    // Limited Authority && No Active Service Accounts
    dispatch({
      type: actions.SET_ELIGIBLE,
      eligible: false,
      getDetails: error.response.data
    });
    dispatch({
      type: actions.SET_ENROLLED,
      enrolled: false
    });
    dispatch({
      type: actions.SET_ERROR,
      errorStatusCode: code,
      error
    });

    Analytics.setPageName(
      'Enrolment Error - Limited Authority & no active services'
    );
    dispatch(push('/not-eligible'));
  } else if (code === ERRCODES.ERR_ASSET_USER) {
    dispatch({
      type: actions.SET_ERROR,
      errorStatusCode: code,
      error
    });
    Analytics.setPageName('Assset User Error');
    dispatch(push('/not-eligible'));
  } else if (code === ERRCODES.ERR_NO_CONTACT_LINKED) {
    dispatch({
      type: actions.SET_ERROR,
      hasErrored: true,
      errorStatusCode: code,
      errorHeader: ERRMSGS.ERR_NO_CONTACT_LINKED_MSG.errorHeading,
      errorMessage: ERRMSGS.ERR_NO_CONTACT_LINKED_MSG.errorText,
      error
    });
    Analytics.setPageName('Assset User Error');
    dispatch(push('/error'));
  } else if (code === ERRCODES.ERR_ALREADY_ENROLLED) {
    // Already Enrolled
    // Setting Enrolled here will push the error screen with an already enrolled messaged
    // from the API and a Link to go to the Plus Homepage
    dispatch({
      type: actions.SET_ENROLLED,
      enrolled: true
    });
    dispatch({
      type: actions.SET_ERROR,
      hasErrored: true,
      errorMessage: ERRMSGS.ALREADYENROLLED,
      errorStatusCode: code,
      error
    });
    Analytics.setPageName('Enrolment Error - Already Enrolled');
    dispatch(push('/error'));
  } else if (code === ERRCODES.ERR_NO_QUALIFIED_ACC) {
    LOGGER.debug('Status Code 1070');
    dispatch({
      type: actions.SET_ERROR,
      hasErrored: true,
      errorStatusCode: code,
      errorHeader: ERRMSGS.ERR_NO_QUALIFIED_ACC.errorHeading,
      errorMessage: ERRMSGS.ERR_NO_QUALIFIED_ACC.errorText,
      error
    });
    Analytics.setPageName('Enrolment Error');
    dispatch(push('/error'));
  } else if (code === ERRCODES.ERR_NO_ACC_UNDER_REVIEW) {
    LOGGER.debug('Status Code 1071');
    dispatch({
      type: actions.SET_ERROR,
      hasErrored: true,
      errorStatusCode: code,
      errorHeader: ERRMSGS.ERR_NO_ACC_UNDER_REVIEW.errorHeading,
      errorMessage: ERRMSGS.ERR_NO_ACC_UNDER_REVIEW.errorText,
      error
    });
    Analytics.setPageName('Enrolment Error - No Account Under review');
    dispatch(push('/error'));
  } else if (code === ERRCODES.ERR_ACCS_UNDER_REVIEW) {
    LOGGER.debug('Status Code 1072');
    dispatch({
      type: actions.SET_ERROR,
      hasErrored: true,
      errorStatusCode: code,
      errorHeader: ERRMSGS.ERR_ACCS_UNDER_REVIEW.errorHeading,
      errorMessage: ERRMSGS.ERR_ACCS_UNDER_REVIEW.errorText,
      error
    });
    Analytics.setPageName('Enrolment Error - No Account Under review');
    dispatch(push('/error'));
  } else {
    // Setting enrolFailed to true will display the error screen with a retry button
    // To retry the enrolment call
    dispatch({
      type: actions.SET_ERROR,
      hasErrored: true,
      errorMessage: ERRMSGS.REGISTRATIONERROR,
      errorStatusCode: code,
      error
    });

    dispatch({
      type: actions.REGISTRATION_FAILURE,
      enrolFailed: true
    });
    Analytics.setPageName(`Enrolment Error - Error code: ${code}`);
    dispatch(push('/error'));
  }
}

const checkIfHasData = (response, flow, dispatch) => {
  if (_.has(response, 'data')) {
    LOGGER.debug(' $$ Successful Enrolment Response');

    if (flow !== 'competition') {
      dispatch({
        type: actions.REGISTRATION_SUCCESS,
        registration: response.data
      });
      Analytics.addEvent({
        eventAction: 'loyaltyEnrolComplete'
      });
    }
  }
}

export default enrolCustomer;
