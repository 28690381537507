import React from 'react';
import { isVideo } from 'utils/isVideo';

import './PageTitle.scss';

const PageTitle = props => {
  const { heroImage, mobileImage, alt } = props;

  const videoElement = src => {
    return (
      <>
        {alt && <p className="sr-only">{alt}</p>}
        <video muted autoPlay width="100%">
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </>
    );
  };

  const heroDisplay = () => {
    if (isVideo(heroImage)) {
      return videoElement(heroImage);
    }
    return <img src={heroImage} alt={alt || ``} />;
  };

  const mobileDisplay = () => {
    if (isVideo(mobileImage)) {
      return videoElement(mobileImage);
    }
    return <img src={mobileImage} alt={alt || ``} />;
  };

  return (
    <>
      <div className="page-title-wrapper">
        <div className="hidden-xs">{heroDisplay()}</div>
        <div className="visible-xs">{mobileDisplay()}</div>
      </div>
    </>
  );
};

export default PageTitle;
