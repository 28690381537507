import IMG_BENEFITS_8 from 'assets/images/TPlus-benefits-header-860x360.jpg';
import IMG_BENEFITS_3 from 'assets/images/TPlus-benefits-header-664x360.jpg';
import IMG_BENEFITS_78 from 'assets/images/TPlus-benefits-header-768x340.jpg';
import IMG_BENEFITS_7 from 'assets/images/TPlus-benefits-header-767x536.jpg';

import IMG_OFFERS_8 from 'assets/images/TPlus-dashboard-header-860x360.jpg';
import IMG_OFFERS_3 from 'assets/images/TPlus-dashboard-header-664x360.jpg';
import IMG_OFFERS_78 from 'assets/images/TPlus-dashboard-header-768 x 340.jpg';
import IMG_OFFERS_7 from 'assets/images/TPlus-dashboard-header-767x536.jpg';

import BUSSINESS_IMG_BENEFITS_8 from 'assets/images/TPlus-business-benefits-header-860x360.jpg';
import BUSSINESS_IMG_BENEFITS_3 from 'assets/images/TPlus-business-benefits-header-664x360.jpg';
import BUSSINESS_IMG_BENEFITS_78 from 'assets/images/TPlus-business-benefits-header-768x340.jpg';
import BUSSINESS_IMG_BENEFITS_7 from 'assets/images/TPlus-business-benefits-header-767x536.jpg';

import IMG_POINTS_8 from 'assets/images/TPlus-points-header-860x360.jpg';
import IMG_POINTS_3 from 'assets/images/TPlus-points-header-664x360.jpg';
import IMG_POINTS_78 from 'assets/images/TPlus-points-header-768x340.jpg';
import IMG_POINTS_7 from 'assets/images/TPlus-points-header-767x536.jpg';

import BUSSINESS_IMG_POINTS_8 from 'assets/images/TPlus-business-points-header-860x360.jpg';
import BUSSINESS_IMG_POINTS_3 from 'assets/images/TPlus-business-points-header-664x360.jpg';
import BUSSINESS_IMG_POINTS_78 from 'assets/images/TPlus-business-points-header-768x340.jpg';
import BUSSINESS_IMG_POINTS_7 from 'assets/images/TPlus-business-points-header-767x536.jpg';

import IMG_TICKETS_8 from 'assets/images/tickets/TPlus-tickets-header-860x360.jpg';
import IMG_TICKETS_3 from 'assets/images/tickets/TPlus-tickets-header-664x360.jpg';
import IMG_TICKETS_78 from 'assets/images/tickets/TPlus-tickets-header-768x340.jpg';
import IMG_TICKETS_7 from 'assets/images/tickets/TPlus-tickets-header-767x536.jpg';

export const SUBHEADING_POINTS = 'Telstra Plus';
export const HEADING_POINTS = 'Earn points';
export const DESCRIPTION_POINTS =
  'Earn points when you pay your bill, or take advantage of special bonus points offers. You can redeem your points towards rewards at the Rewards Store.';
export const IMG_BENEFITS = {
  IMG: {
    IMG860: IMG_BENEFITS_8,
    IMG767: IMG_BENEFITS_7,
    IMG768: IMG_BENEFITS_78,
    IMG360: IMG_BENEFITS_3
  },
  BUSSINESSIMG: {
    IMG860: BUSSINESS_IMG_BENEFITS_8,
    IMG767: BUSSINESS_IMG_BENEFITS_7,
    IMG768: BUSSINESS_IMG_BENEFITS_78,
    IMG360: BUSSINESS_IMG_BENEFITS_3
  }
};

export const SUBHEADING_BENEFITS = 'Telstra Plus';
export const HEADING_BENEFITS = 'Enjoy benefits';
export const DESCRIPTION_BENEFITS =
  'Your Telstra Plus membership comes with access to exciting membership benefits you can use whenever you like without spending your points.';
export const IMG_POINTS = {
  IMG: {
    IMG860: IMG_POINTS_8,
    IMG767: IMG_POINTS_7,
    IMG768: IMG_POINTS_78,
    IMG360: IMG_POINTS_3
  },
  BUSSINESSIMG: {
    IMG860: BUSSINESS_IMG_POINTS_8,
    IMG767: BUSSINESS_IMG_POINTS_7,
    IMG768: BUSSINESS_IMG_POINTS_78,
    IMG360: BUSSINESS_IMG_POINTS_3
  }
};
export const SUBHEADING_OFFERS = 'Telstra Plus';
export const HEADING_OFFERS = 'Get rewarded just for being with us';
export const HEADING_LNK_COPY = 'Sign in to Join';
export const HEADING_LNK_URL = 'http://localhost:3000/join';
export const DESCRIPTION_OFFERS = '';
export const ELIGIBLE_OFFERS =
  'You must be 18+ with an eligible service. Excludes enterprise.';
export const TERMS_OFFERS = 'Read the terms and conditions';
export const TERMS_URL = 'https://www.telstra.com.au/plus/terms-and-conditions';
export const IMG_OFFERS = {
  IMG: {
    IMG860: IMG_OFFERS_8,
    IMG767: IMG_OFFERS_7,
    IMG768: IMG_OFFERS_78,
    IMG360: IMG_OFFERS_3
  }
};

export const SUBHEADING_TICKETS = 'Telstra Plus';
export const HEADING_TICKETS = 'Tickets';
export const DESCRIPTION_TICKETS =
  'Get discounted movie and sports tickets and pre-sale access to concerts and arts.';
export const IMG_TICKETS = {
  IMG: {
    IMG860: IMG_TICKETS_8,
    IMG767: IMG_TICKETS_7,
    IMG768: IMG_TICKETS_78,
    IMG360: IMG_TICKETS_3
  }
};

export const SUBINFO = {
  TABS: [
    {
      icon: 'telstraplus',
      copy: 'Telstra Plus',
      url: '/',
      authenticate: false
    },
    {
      icon: 'telstraplus',
      copy: 'Telstra Plus',
      url: '/',
      authenticate: true
    },
    {
      icon: 'offers',
      copy: 'Offers',
      url: '/offers',
      authenticate: true
    },
    {
      icon: 'offers',
      copy: 'Offers',
      url: '/offers',
      authenticate: false
    },
    {
      icon: 'store',
      copy: 'Rewards Store',
      url: '/rewards',
      authenticate: false
    },
    {
      icon: 'store',
      copy: 'Rewards Store',
      url: '/rewards',
      authenticate: true
    },
    {
      icon: 'partners',
      copy: 'Partners',
      url: '/partners',
      authenticate: true
    },
    {
      icon: 'partners',
      copy: 'Partners',
      url: '/partners',
      authenticate: false
    },
    {
      icon: 'tickets',
      copy: 'Tickets',
      url: '/tickets',
      authenticate: false
    },
    {
      icon: 'tickets',
      copy: 'Tickets',
      url: '/tickets',
      authenticate: true
    }
  ]
};

export const SPEND_BODY = {
  MEMBER_TIER_MSG: `Spend $1,500 on services before your anniversary to unlock Silver tier.`,
  SILVER_TIER_MSG: `Spend $3,000 on services before your anniversary to unlock Gold tier.`
};

export const GOLD_EXCLUSIVE = "Gold Exclusive";
export const SILVER_EXCLUSIVE = "Silver Exclusive";
export const MEMBER_EXCLUSIVE = "Member Exclusive";
export const BUSINESS_EXCLUSIVE = "Business Exclusive";
