import React from 'react';
import { ActionButton, Grid, Column, TextStyle, Spacing } from '@able/react';

import notfoundSVG from 'assets/images/illos-404.svg';

const NotFound = () => {
  return (
    <Grid className="container">
      <Spacing bottom="spacing7x" />
      <Column cols="6">
        <Spacing bottom="spacing7x" />
        <TextStyle alias="HeadingA" element="h2">404. Page not found.</TextStyle>
        <Spacing bottom="spacing1x" />
        <TextStyle alias="Base" element="p">Head back to Telstra Plus.</TextStyle>
        <Spacing bottom="spacing4x" />
        <ActionButton
          variant="LowEmphasis"
          element="Link"
          to="/"
          label="Go to Telstra Plus"
        />
      </Column>
      <Column cols="6">
        <img src={notfoundSVG} alt="Woman looking through telescope" />
      </Column>
      <Spacing bottom="spacing10x" />
    </Grid>
  );
};

export default NotFound;
