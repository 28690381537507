import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextStyle } from '@able/react';
import { HtmlConverter } from 'utils/HtmlConverter';

import IconClose from 'icons/IconClose';

import './NotificationBar.scss';

const NotificationBar = props => {
  const { copy, ctaCopy, ctaUrl, onClose } = props;
  const [showBanner, setShowBanner] = useState(true);
  const visibleClass = showBanner ? 'visible' : '';

  const hideBanner = () => {
    if (onClose) {
      onClose();
    }
    setShowBanner(false);
  };

  return (
    <>
      <div id="notification-bar" className={`notification-bar ${visibleClass}`}>
        <div className="notification-text-wrapper">
          <TextStyle
            className="notification-copy"
            alias="Base"
            element="p"
          >
            {HtmlConverter(copy)}{' '}
          </TextStyle>
          {ctaCopy && (
            <TextStyle
              className="notification-copy"
              alias="Base"
              element="p"
            >
              <a href={ctaUrl}>{ctaCopy}</a>
            </TextStyle>
          )}
        </div>
        <button
          onClick={() => hideBanner()}
          type="button"
          aria-label="Close this notification"
          className={`notification-close-btn ${visibleClass}`}
        >
          <IconClose />
        </button>
      </div>
      {showBanner && <div className="tcom-global-header__gradient"></div>}
    </>
  );
};

export default NotificationBar;

NotificationBar.propTypes = {
  copy: PropTypes.string,
  ctaCopy: PropTypes.string,
  ctaUrl: PropTypes.string,
  onClose: PropTypes.func
};
NotificationBar.defaultProps = {
  copy: '',
  ctaCopy: '',
  ctaUrl: '',
  onClose: null
};
