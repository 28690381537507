import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Grid, Column, Spacing, TextStyle } from '@able/react';
import { Helmet } from 'react-helmet';

import * as Analytics from 'utils/analytics';
import AppLog from 'utils/AppLog';

import logoSVG from 'assets/images/tplus-logo-filled.svg';
import tile from 'assets/images/success-tile@2x.png';
import './Success.scss';

import { Cta } from 'components';

import { getAemContent } from 'selectors/GetAemContent';


const Success = () => {
  const history = useNavigate();

  const flow = useSelector(state => state.appStatus.flow);
  const welcome = useSelector(state => state.appStatus.welcomeState);
  const joinSuccessCtas = useSelector(state =>
    getAemContent(state, 'join/success', 'joinSuccessCtas')
  )[0];

  useEffect(() => {
    // TODO rethink how we show the
    const log = new AppLog('Enrol Success');
    log.debug('Success Container mounted');
    Analytics.setPageName('Enrolment - Success Page');
  }, []);

  const showRouteCta = () => {
    const prevLoc = localStorage.getItem('signinRoute');
    const routes = joinSuccessCtas ? joinSuccessCtas.routes : '';
    if (prevLoc && routes) {
      // eslint-disable-next-line no-restricted-syntax
      for (const prop in routes) {
        if (routes[prop].route === prevLoc) {
          return {
            route: routes[prop].route,
            text: routes[prop].ctaText,
            url: routes[prop].ctaUrl,
            action: routes[prop].ctaAction,
            trackingCode: routes[prop].ctaTrackingCode
          };
        }
      }
    }
    return null;
  };

  const DefaultLink = () => (
    <Cta
      action="basic"
      ctaProps={{
        variant: 'LowEmphasis', // TODO Should come from AEM
        label: 'Explore your benefits',
        to: '/benefits',
        element: 'Link'
      }}
    />
  );

  const renderLinks = () => {
    const routeCta = showRouteCta();
    // Checking for incoming flow objects existence and returning any links in there
    if (_.has(flow, 'links')) {
      const flowLinks = flow.links.map(link => {
        return (
          <Cta
            action="basic"
            ctaProps={{
              variant: 'LowEmphasis', // TODO Should come from AEM
              label: link.link,
              href: link.url,
              element: 'a'
            }}
          />
        );
      });
      const combinedLinks = (
        <p className="link-container">
          <DefaultLink />
          {flowLinks}
        </p>
      );
      return combinedLinks;
    }
    if (routeCta) {
      return (
        <p className="link-container">
          <DefaultLink />

          <Cta
            action={routeCta.action}
            trackingCode={routeCta.trackingCode}
            url={routeCta.url}
            ctaProps={{
              variant: 'LowEmphasis',
              label: routeCta.text,
              element: 'a'
            }}
          />
        </p>
      );
    }
    // Otherwise just show normal
    return (
      <p className="link-container">
        <DefaultLink />

        <Cta
          action="basic"
          trackingCode="join success - browse rewards"
          url="/rewards"
          ctaProps={{
            variant: 'LowEmphasis',
            label: 'Browse the Rewards Store'
          }}
        />
      </p>
    );
  };

  const renderSuccess = () => {
    // Checking if we came from join page, if not redirect to homepage
    const styles = { backgroundImage: `url(${tile})` };

    if (welcome) {
      return (
        <>
        <Helmet>
          <title>Successfully joined Telstra Plus</title>
        </Helmet>
          <Grid className="success page container">
            <Column>
              <Spacing bottom="spacing10x" />
              <div className="success-logo">
                <img src={logoSVG} alt="Telstra Logo" />
                <Spacing bottom="spacing4x" />
              </div>
              <TextStyle alias="HeadingA" element="h1">
                Welcome to Telstra Plus &trade;
              </TextStyle>
              <TextStyle element="p" alias="headingB" className="success-copy">
                You&#39;re now set to earn points, redeem rewards and enjoy exciting
              membership benefits.
                <img src={logoSVG} alt="Telstra Logo" />
              </TextStyle>
              {renderLinks()}
            </Column>
          </Grid>
          <div className="decoration success" style={styles} />
        </>
      );
    }
    return history('/');
  };

  return <>{renderSuccess()}</>;
};

export default Success;
