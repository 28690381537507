import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as Analytics from 'utils/analytics';
import AppLog from 'utils/AppLog';

import { Breadcrumbs, VerticalTabs, HeadingTitle } from 'components';
import { MemberTierTable } from 'views';
import { Spacing } from '@able/react';

import { getHeadingTitleState } from 'selectors/GetHeaders';
import { getVerticalTabsState } from 'selectors/GetVerticalTabs';

const HowItWorksUnAuthView = () => {
  const log = new AppLog('How It Works Unauth');

  const activeAccountTier = useSelector(state => state.accounts.loyaltyTier);
  const aemBenefitsHeader = useSelector(state => getHeadingTitleState(state));
  const aemVerticalTabs = useSelector(state => getVerticalTabsState(state)); // Returns array

  useEffect(() => {
    log.debug('Telstra Plus how it works unauthenticated view mounted');

    Analytics.setPageName('Tplus How It Works Page');
  }, [log]);

  return (
    <>
      <div className="howitworks container main">
        {aemBenefitsHeader && (
          <>
            <Spacing bottom="spacing2x" />
            <HeadingTitle
              subheading={aemBenefitsHeader?.subheading}
              heading={aemBenefitsHeader?.heading}
              description={aemBenefitsHeader?.description}
              pageimage={aemBenefitsHeader?.images}
              primaryCtas={aemBenefitsHeader?.primaryCtas}
              secondaryCtas={aemBenefitsHeader?.secondaryCtas}
              linkCopy={aemBenefitsHeader?.linkcopy}
              linkUrl={aemBenefitsHeader?.linkurl}
              linkCopy2={aemBenefitsHeader?.linkcopy2}
              linkUrl2={aemBenefitsHeader?.linkurl2}
              terms={aemBenefitsHeader?.terms}
              activeaccount={activeAccountTier}
              active={aemBenefitsHeader}
            />
            <Spacing bottom="spacing4x" />
          </>
        )}
        <Breadcrumbs />
        <MemberTierTable />

        {aemVerticalTabs.length > 0 && (
          <>
            {aemVerticalTabs.map(tab => {
              return (
                <section>
                  <div className="rewards-tabs-container">
                    <VerticalTabs
                      title={tab.title}
                      tabs={tab.array}
                      footer={tab.footer}
                    />
                  </div>
                </section>
              );
            })}
          </>
        )}

        <div className="clear-div-xxxl" />
      </div>
    </>
  );
};

export default HowItWorksUnAuthView;
