import { LOGGER } from 'utils/AppLog';
import transferPointsApi from 'api/transferPoints';
import * as actions from 'constants/actions';

const transferPoints = transferPointsDetails => {
  return dispatch => {
    return transferPointsApi(transferPointsDetails)
      .then(response => {
        LOGGER.debug('Transfer Points Api', response);
        dispatch({
          type: actions.SET_TRANSFER_POINTS_SUCCESS,
          payload: true
        });
        // dispatch(push('/transfer/success'));
        // To do set the location
        // window.location = response.data.data.data.productOffers[0].url;
      })
      .catch(error => {
        LOGGER.error('Transfer Points API: ', error);
        if (error?.response?.data?.code) {
          dispatch({
            type: actions.TRANSFER_POINTS_FAILURE,
            payload: error?.response?.data?.code
          });
        } else if (error?.response?.status) {
          dispatch({
            type: actions.TRANSFER_POINTS_FAILURE,
            payload: error?.response?.status
          });
        }
      });
  };
};

export default transferPoints;
