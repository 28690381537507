/* eslint-disable no-param-reassign */
// Action Types, Describe what is happening
import {
  SET_ELIGIBLE,
  SET_HASCHECKEDELIGIBILITY,
  GET_DETAILS_PENDING
} from 'constants/actions';

const defaultState = {
  eligible: false,
  hasCheckedEligibility: false,
  fetchDetailsPending: false,
  getDetails: {
    data: {
      accounts: [
        {
          accountId: '',
          loyaltyTier: 'MEMBER'
        }
      ]
    }
  }
};

export default function appChange(state = defaultState, action) {
  switch (action.type) {
    case SET_ELIGIBLE: {
      if (action.eligible) {
        const { accounts } = action.getDetails.data;
        action.getDetails.data.accounts = accounts;
      }

      return {
        ...state,
        eligible: action.eligible,
        getDetails: action.getDetails || defaultState.getDetails
      };
    }
    case SET_HASCHECKEDELIGIBILITY:
      return {
        ...state,
        hasCheckedEligibility: true
      };
    case GET_DETAILS_PENDING:
      return {
        ...state,
        fetchDetailsPending: true
      };
    default:
      return state;
  }
}

export const fetchDetailsPending = state => {
  return state.fetchDetailsPending;
};
