/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { addLinkClickEvent } from 'utils/analytics';
import { setSearchQuery } from 'utils/analytics';
import {
  searchRewardCatalog,
  fetchQuickSearch,
  clearQuickSearch,
  fetchRewardCatalog,
  clearSearchQuery
} from 'actions/rewards';
import * as Yup from 'yup';
import useDebounce from 'utils/hooks/use-debounce';
import { useCombobox } from 'downshift';
import { getFilteredCatalog } from 'selectors/GetRewards';
import { setProductSort } from 'actions/rewards';
import { Redirect, Link } from 'react-router-dom';
import queryString from 'query-string';
import './RewardsSearch.scss';
import { useNavigate } from 'react-router-dom';
import { isHiddenProductFamily } from '../RewardsProductTileGroup/RewardsProductTileGroup';

const RewardsSearch = () => {
  const navigate = useNavigate();
  const filterQuery = queryString.parse(window.location?.search);
  const { category: categoryParams, offer: offerParams } = filterQuery;
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchClick, setSearchClick] = useState(false);
  const [resultsFiltered, setFilteredResults] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const catalogView = useSelector(state => state.rewards.catalogView);
  const productFamilies = useSelector(state => getFilteredCatalog(state).filter(isHiddenProductFamily)); // @TODO: Revert filter once GnR completes

  const {
    searchQuery,
    quickSearch: { results },
    categoriesFilter,
    offerTypeFilter
  } = catalogView;

  // @TODO: Revert use of "resultsFiltered" hook once GnR completes
  useEffect(() => {
    if (results) {
      setFilteredResults(results.filter(res => res.name && res.name != "Telstra Guns N Roses Voucher"));
    }
  }, [results])

  useEffect(() => {
    if (debouncedSearchTerm) {
      if (debouncedSearchTerm.length > 2) {
        dispatch(fetchQuickSearch(debouncedSearchTerm));
      }
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (searchQuery && !searchClick) {
      dispatch(searchRewardCatalog(searchQuery));
    } else if (!searchQuery && !searchClick) {
      const categoriesList = Object.keys(categoriesFilter).filter(i => categoriesFilter[i]);
      const selectedOfferType = offerParams ? [offerParams] : offerTypeFilter ? [offerTypeFilter] : null;
      const selectedCategories = categoryParams ? categoryParams.split(',') : categoriesList?.length > 0 ? categoriesList : null;
      dispatch(fetchRewardCatalog(selectedOfferType, selectedCategories));
    }
    setSearchTerm(searchQuery);
  }, [searchQuery, searchClick]);

  const onClickSearch = search => {
    if (search) {
      setSearchTerm(search);
      setSearchClick(true);
      dispatch(searchRewardCatalog(search));
      closeMenu();
    }
  };

  const handleItemClick = (e, search) => {
    setSearchTerm(search);
    dispatch(searchRewardCatalog(search));
    addLinkClickEvent("search");
    closeMenu();
  };

  const handleSearch = search => {
    addLinkClickEvent("internalSearch");
    setSearchQuery(search);
    setSearchTerm('');
    setSearchClick(true);
    dispatch(clearQuickSearch());
    dispatch(searchRewardCatalog(search));
    closeMenu();
  };

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
    initialHighlightedIndex = 0
  } = useCombobox({
    items: resultsFiltered,
    itemToString: item => (item ? item.name : ''),
    onInputValueChange: ({ inputValue }) => {
      if (inputValue.length < 3) {
        if (resultsFiltered.length > 0) {
          dispatch(clearQuickSearch());
        }
        closeMenu();
      }
      setSearchTerm(inputValue);
    }
  });

  const getHighlightedSuggestion = (suggestion, userInput) => {
    const escapedString = _.escapeRegExp(userInput);
    const parts = suggestion.split(new RegExp("(" + escapedString + ")", 'gi'));
    return (
      <div id="suggestionId">
        {parts.map(part =>
          part.toLowerCase() === userInput.toLowerCase() ? <b>{part}</b> : part
        )}
      </div>
    );
  };

  const [lastHighlighted, setLastHighlighted] = useState(null);

  useEffect(() => {
    highlightedIndex && resultsFiltered.length > 1 ? setLastHighlighted(resultsFiltered[highlightedIndex]) : setLastHighlighted(resultsFiltered[initialHighlightedIndex]);
  }, [highlightedIndex, resultsFiltered]);

  useEffect(() => {
    if (lastHighlighted && isOpen) {
      window.addEventListener('keydown', (event) => event.keyCode === 13 ? redirectOnEnter(`/rewards/explore/${lastHighlighted?.productFamily}`) : null, { once: true });
    }
    return () => {
      window.removeEventListener('keydown', redirectOnEnter);
    }
  }, [lastHighlighted]);

  const redirectOnEnter = (url) => {
    navigate(url);
    dispatch(searchRewardCatalog(lastHighlighted?.name));
    addLinkClickEvent("onpressenterquicksearch");
  }

  return (
    <div {...getComboboxProps()}>
      <Formik
        initialValues={{
          search: ''
        }}
        validationSchema={Yup.string()}
        onSubmit={(values, { resetForm }) => {
          const { search } = values;
          if (search.length > 2) {
            resetForm({});
            handleSearch(search);
          }
        }}
      >
        {props => (
          <>
            <Form
              className="tplus-rewards-search-container"
              onSubmit={props.handleSubmit}
            >
              <div className="able-TextField tplus-rewards-searchbox">
                <label htmlFor="search">Search the store</label>
                <input
                  {...getInputProps({
                    id: 'search',
                    label: 'Search',
                    name: 'search',
                    value: searchTerm || '',
                    onChange: props.handleChange,
                    role: 'combobox',
                  })}
                />
                <svg
                  className="icon-chevron-down"
                  role="img"
                  aria-hidden="true"
                  aria-label="Accessible Content"
                  focusable="false"
                  onClick={() => {
                    onClickSearch(searchTerm);
                    addLinkClickEvent('internalSearch');
                    setSearchQuery(searchTerm);
                  }}
                  style={{
                    left: "calc(100% - 40px)",
                    top: "44px"
                  }}
                >
                  <use href="/able-sprites.svg?v18#Search"></use>
                </svg>
                {/*<ul>{getStyledSuggestion(results,props.values.search)}</ul>*/}

                <ul
                  {...getMenuProps()}
                  className="address-search-dropdown tplus-rewards-search-dropdown"
                >
                  {isOpen &&
                    resultsFiltered
                      .filter(item =>
                        item.name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                        && !item.name
                          .toLowerCase()
                          .includes("postage")
                      )
                      .map((item, key) => (
                        <Link
                          className="tplus-rewards-product-tile__link"
                          to={`/rewards/explore/${item.productFamily}`}
                          onClick={() => {
                            dispatch(searchRewardCatalog(item?.name));
                            addLinkClickEvent("search");
                          }}
                        >
                          <li
                            id="listItem"
                            key={key}
                            {...getItemProps({ item, key })}
                            className={`address-item ${resultsFiltered.length === 1 ? 'selected' : highlightedIndex === key ? 'selected' : ''
                              }`}
                            dataId={item.name}
                            focusable="false"
                            to={`/rewards/explore/${item.productFamily}`}
                            tabIndex="0"
                            onKeyPress={e =>
                              e.keyCode == 13 && handleItemClick(e, item.name)
                            }
                          >
                            {getHighlightedSuggestion(
                              item.name,
                              props.values.search
                            )}
                          </li>
                        </Link>
                      ))}
                </ul>
              </div>
            </Form>
          </>
        )}
      </Formik>
      {searchQuery && (
        <>
          {productFamilies.length > 0 && (
            <p>
              {productFamilies.length} results found for {searchQuery}:
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default RewardsSearch;
