import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { otpGetAction } from 'actions/otp';

import { TextStyle, IconButton, Spacing } from '@able/react';
import MobileForm from 'components/OtpFlow/sidebarforms/MobileForm';


const MobileAddView = props => {
  const { page, toggleView, renderSpinner } = props;
  const dispatch = useDispatch();

  const otpFlow = useSelector(state => state.otpFlow);

  return (
    <>
      {otpFlow?.mobile?.fetching && renderSpinner()}
      {!otpFlow?.mobile?.fetching && (
        <>
          <div>
          <TextStyle element="h2" alias="HeadingB">
           Update your mobile number
          </TextStyle>
          </div>
         
          <MobileForm
            page={page}
            apiAction={value => dispatch(otpGetAction(value))}
            nextAction={toggleView}
          />
        </>
      )}
    </>
  );
};

export default MobileAddView;

MobileAddView.propTypes = {
  page: PropTypes.string.isRequired,
  toggleView: PropTypes.func.isRequired,
  renderSpinner: PropTypes.func.isRequired
};
