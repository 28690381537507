/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import React from 'react';
import { useSelector } from 'react-redux';
import { HtmlConverter } from 'utils/HtmlConverter';
import TicketAlertBox from 'views/TicketsChildPages/TicketAlertBox';
import { getGoldPresaleEndDate } from 'utils/GetGoldPresaleEndDate';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

import { TextStyle } from '@able/react';

const EventPreSale = props => {
  const {
    eventData: {
      tierPriority,
      eventSaleStartDate,
      eventSaleEndDate,
      tierPriorityDetails
    },
    singleEvent
  } = props;

  const carouselView = () => {
    const dateFormat = 'h:mm a Do MMM YYYY';
    const gold = `<b>Gold early access pre-sale starts</b> ${evtStDate.format(
      dateFormat
    )}`;
    const all = `<b>Pre-sale open to all members</b> ${allMemberTime.format(
      dateFormat
    )}`;
    const isGold = accounts.loyaltyTier === 'GOLD' ? [gold] : [all];
    const prio = isAuthenticated ? isGold : [gold, all];
    return tierPriority ? prio : [];
  };

  const singleView = () => {
    const dateFormat = 'dddd Do MMMM, YYYY [from] h:mm a';
    const tieredEvent = [
      `<b>Gold early access pre-sale from:</b> ${evtStDate.format(
        dateFormat
      )}`,
      `<b>Pre-sale open to all members from:</b> ${allMemberTime.format(
        dateFormat
      )}`,
      `<b>Pre-sale ends:</b> ${evtEndDate.format(dateFormat)}`
    ];
    const notTieredEvent = [
      `<b>Telstra Pre-sale starts:</b> ${evtStDate.format(dateFormat)}`,
      `<b>Telstra Pre-sale ends:</b> ${evtEndDate.format(dateFormat)}`
    ];
    if (presaleEnded) {
      return ['<b>Pre-sale closed</b>'];
    }
    return tierPriority ? tieredEvent : notTieredEvent;
  };

  const notifyView = () => {
    if (presaleEnded) {
      return ['Pre-sale has closed. However, tickets may still be available.'];
    }
    if (isAuthenticated) {
      return ['Pre-sale start and end dates vary by venue.'];
    }
    if (singleEvent) {
      return ['Pre-sale start and end dates might vary depending on your membership tier.'];
    }
    return ['Pre-sale start and end dates vary by venue and your membership tier.'];
  };

  const notificationWrapper = () => {
    let preSaleString = '';
    if (!infoText.length) {
      return null;
    }
    preSaleString = infoText.join('<br/>');
    return (
      <div className="ticket-notification-wrapper">
        <TicketAlertBox
          notificationType="info"
          notificationText={preSaleString}
          presaleEnded={presaleEnded}
          singleEvent={singleEvent}
        />
      </div>
    );
  };

  const defaultWrapper = () => {
    return infoText.map((info, key) => {
      return (
        <TextStyle alias="Label" element="span" className="presale-style" key={key}>
          {HtmlConverter(info)}
        </TextStyle>
      );
    });
  };

  const currDate = dayjs();
  const evtStDate = dayjs(eventSaleStartDate);
  const evtEndDate = dayjs(eventSaleEndDate);
  const allMemberTime = tierPriority ? getGoldPresaleEndDate(tierPriorityDetails, evtStDate) : evtStDate;
  const isAuthenticated = useSelector(state => state.appStatus.authenticated);
  const accounts = useSelector(state => state.accounts);
  const presaleEnded = evtEndDate < currDate;

  let infoText = '';
  switch (allMemberTime) {
    case 'carousel':
      infoText = carouselView();
      break;
    case 'notify':
      infoText = notifyView();
      break;
    default:
      infoText = singleView();
  }
  if (!eventSaleStartDate || !infoText.length) {
    return null;
  }
  return (
    <>
      {props.viewType === 'notify' ? notificationWrapper() : defaultWrapper()}
    </>
  );
};

export default EventPreSale;
