import React, { useEffect, useState, useRef } from 'react';
import { TextStyle, Spacing, MessageSection } from '@able/react';

import './RewardsColourSelector.scss'
import { RewardsColourGroups } from './RewardsColourGroups';

const RewardsColourSelector = props => {
    const {
        colours,
        coloursRefs,
        size = "41px",
        selected = 0,
        onSelect,
        outOfStock
    } = props;

    const [selectedIndex, setSelectedIndex] = useState(selected);
    const [coloursWithReferences, setColoursWithReferences] = useState(null);

    const ref = useRef(null);

    useEffect(() => {
        if (!(onSelect == null)) {
            onSelect(colours[selectedIndex]);
        }
    }, [selectedIndex])

    useEffect(() => {
        if (coloursRefs) {
            setColoursWithReferences(coloursRefs.map((ref) => getColourNameAndUrl(ref.value)));
        }
    }, [coloursRefs]);

    const handleKeyDown = (key) => {
        switch(key.keyCode) {
            case 37:
            case 38:
                selectAndFocus((selectedIndex + (colours.length - 1)) % colours.length);
                break;
            case 39:
            case 40:
                selectAndFocus((selectedIndex + 1) % colours.length);
                break;
            default:
                break;
        } 
    }

    const getDefaultColourHex = colourName => {
        const colourReference = RewardsColourGroups.find(cg => cg.names.includes(colourName.toLowerCase()))
        return colourReference?.hexCode;
    }

    const selectAndFocus = (index) => {
        setSelectedIndex(index);
        document.getElementById(`tplus-rewards-variations-colour-${colours[index].value}`).focus();
    }

    const getColourNameAndUrl = (colourStr) => {
        //proposed format for colour image url value:
        // ${COLOUR} | ${URL PATH (BASE URL www.telstra.com.au)}

        const reg = /^(?:\s*)(.*?)(?:\s*)\|(?:\s*)(.*?)(?:\s*)$/;
        const regRes = reg.exec(colourStr);

        return {
            colour: regRes[1] ? regRes[1] : null,
            colourReference: regRes[2] ? regRes[2] : null
        }
    }

    return (
        <>  
            <Spacing top="spacing4x">
                <TextStyle element="span" className='tplus-rewards-colour-title'>
                    <b>Colour:</b> {colours[selectedIndex].value}
                </TextStyle>
            </Spacing>
            <Spacing top="spacing2x">
                <div className='tplus-rewards-colour-selector' ref={ref} role='radiogroup' aria-label="Choose a colour">
                    {colours && colours.map((colour, index) => {
                        let className = 'tplus-rewards-colour-selector-circle-button' + (index === selectedIndex ? '-selected' : '');

                        let style = {
                            width: size,
                            height: size,
                            "background-repeat": "no-repeat",
                            "background-size": `${size} ${size}`
                        }

                        const colourRef = coloursWithReferences ? coloursWithReferences.find(ref => ref.colour === colour.value) : null;

                        if (colourRef) {
                            if (colourRef.colourReference.charAt(0) === "#") {
                                style.backgroundColor = colourRef.colourReference
                            }
                            else {
                                style.backgroundImage = `url("https://telstra.com.au${colourRef.colourReference}")`;
                            }
                        } else {
                            style.backgroundColor = getDefaultColourHex(colour.value) || colour.value;
                        }

                        return (
                            <button 
                                role="radio"
                                id={`tplus-rewards-variations-colour-${colour.value}`}
                                className={className}
                                style={style}
                                tabIndex={index === selectedIndex ? 0 : -1}
                                aria-checked={index === selectedIndex}
                                aria-disabled={colours.length > 1 ? false : true}
                                aria-label={colour.value}
                                onClick={() => setSelectedIndex(index)}
                                onKeyDown={(key) => handleKeyDown(key)}
                                data-ws-bm-name-prop={colour.value}
                                data-ws-bm-desc-prop={""}
                                >
                            </button>
                        )
                    })}
                </div>
                </Spacing>
                {outOfStock &&
                    <MessageSection
                        description={`${colours[selectedIndex].value} colour is out of stock.`}
                        variant="Warning"
                        developmentUrl="/able-sprites.svg"
                    />
                }
        </>
    )

}


export default RewardsColourSelector;