/**
 * 
 * @param {array} attributes A reward product's attributes
 * @returns {array} list of the products exclusive tiers found e.g. Possible values ['MEMBER', 'SILVER', 'GOLD',]
 */
export const restrictedToTiers = attributes => {
  const restrictedTiers = [];

  if (attributes && attributes instanceof Array) {
    attributes.forEach(a => {
      const {value, name } = a;
      if (
        name === 'Loyalty Tiers' &&
        ['MEMBER', 'GOLD', 'SILVER', 'BUSINESS'].includes(value)
      ) {
        if (!restrictedTiers.includes(value)) {
          restrictedTiers.push(value);
        }
      }
    });
  }

  return restrictedTiers;
};
