const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

// Takes in a transactionDate string with the format - 2019-01-01 11:30:00
// And returns a readable date - 1 Jan 2019
export const formatDate = date => {
  const d = date.split(' ')[0].split('-');
  return `${parseInt(d[2], 10)} ${months[parseInt(d[1] - 1, 10)]} ${d[0]}`;
};
