import getPartnerToken from 'api/partners';
import { PARTNERS } from 'constants/externalPartner';
import { constructPartnerUrl } from 'utils/partners';
import { history } from 'store';
import {
  GET_PARTNER_TOKEN_PENDING,
  GET_PARTNER_TOKEN_FAILURE,
  GET_PARTNER_TOKEN_SUCCESS,
  SET_ERROR,
  SET_LOADING
} from 'constants/actions';

export const getPartnerJWT = (partner, query) => {
  // eslint-disable-next-line consistent-return
  return (dispatch, getState) => {
    const state = getState();
    const { accountId } = state.accounts;
    const { contactUUID } = state.eligibility.getDetails.data;
    const partnerProps = PARTNERS[partner].apiProps;

    if (partnerProps) {
      const params = {
        ...partnerProps,
        accountId,
        contactId: contactUUID
      };
      dispatch({ type: GET_PARTNER_TOKEN_PENDING });
      return getPartnerToken(params)
        .then(response => {
          if (response && response.data) {
            const { jwt } = response.data.data;
            if (jwt) {
              const redirectUrl = constructPartnerUrl(partner, jwt, query);
              dispatch({
                type: GET_PARTNER_TOKEN_SUCCESS,
                data: {
                  partnerId: partner,
                  url: redirectUrl
                }
              });
              dispatch({ type: SET_LOADING, loading: false });
              if (redirectUrl) {
                window.location.href = redirectUrl;
              }
            }
          }
        })
        .catch(error => {
          dispatch({ type: GET_PARTNER_TOKEN_FAILURE });
          dispatch({ type: SET_LOADING, loading: false });
          dispatch({ type: SET_ERROR, error, hasErrored: true });
          history.push('/error');
        });
    }
  };
};

export const getPartnerOauth = (partner, query, path, user) => {
  // eslint-disable-next-line consistent-return
  return (dispatch, getState) => {
    const state = getState();
    const { accountId } = state.accounts;
    const { contactUUID } = state.eligibility.getDetails.data;
    const partnerProps = PARTNERS[partner].apiProps;
    // eslint-disable-next-line camelcase
    const { redirect_uri } = query;

    if (partnerProps) {
      const params = {
        ...partnerProps,
        accountId,
        contactId: contactUUID
      };
      dispatch({ type: GET_PARTNER_TOKEN_PENDING });
      return getPartnerToken(params, user)
        .then(response => {
          if (response && response.data) {
            // Grab tokenKey from 200 response to check even though we dont need to use it
            const { tokenKey } = response.data.data;
            if (tokenKey) {
              dispatch({
                type: GET_PARTNER_TOKEN_SUCCESS,
                data: {
                  partnerId: partner,
                  url: `${window.location.protocol}//${window.location.host}${path}`
                }
              });
              // dispatch({ type: SET_LOADING, loading: false });
              // Replace history with the child page, if one is sent through
              if (path) {
                window.history.replaceState(
                  null,
                  null,
                  `${window.location.protocol}//${window.location.host}${path}`
                );
              }

              // Set up our url
              const url = new URL(redirect_uri);
              // Add any params
              // url.searchParams.append('tokenValue', tokenValue);
              // Redirect to partner
              return window.location.assign(url);
            }
            // Didnt recieve tokenKey - do nothing - can add network error catches here later
            // check for network error status here
            dispatch({ type: GET_PARTNER_TOKEN_FAILURE });
            dispatch({ type: SET_LOADING, loading: false });
            dispatch({ type: SET_ERROR, error: null, hasErrored: true });
            return history.push('/error');
          }
          // Some weird case where there is no response
          dispatch({ type: GET_PARTNER_TOKEN_FAILURE });
          dispatch({ type: SET_LOADING, loading: false });
          dispatch({ type: SET_ERROR, error: null, hasErrored: true });
          return history.push('/error');
        })
        .catch(error => {
          dispatch({ type: GET_PARTNER_TOKEN_FAILURE });
          dispatch({ type: SET_LOADING, loading: false });
          dispatch({ type: SET_ERROR, error, hasErrored: true });
          history.push('/error');
        });
    }
  };
};
