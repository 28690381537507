import { useEffect, useReducer } from 'react';
import { map, chain, uniqWith, isEqual, groupBy } from 'lodash';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import { exclusiveToTiers } from 'utils/rewards/exclusiveToTiers';
import { selectedProductInitialState, selectedProductReducer, getAttributeName } from './use-selected-product-utils';
const useSelectedProduct = ({ product, isAuthenticated }) => {
  const { loyaltyTier, pointsValue } = useSelector(state => state.accounts);
  const { stocks } = useSelector(state => state.productStocks);
  const { productFamilies } = useSelector(state => state.rewards.productView.product);
  const [state, dispatch] = useReducer(selectedProductReducer, selectedProductInitialState);
  const { productsFromFamily, selectedProduct, selectedProductFound, selectedCriteria, productFamilyImage,
    priceOption, selectedPromotion, outOfStock, selectedPrice, minPrice, arrAttributesAll } = state;
  const { currentPrice = {}, originalPrice = {}, repaymentOptions, sku, attributes } = selectedProduct;
  const { priceOptions } = currentPrice;
  const originalPriceOptions = originalPrice ? originalPrice.priceOptions : null;
  const exclusiveTiers = exclusiveToTiers(attributes);
  /**
   *
   * Take a value from user selection and add to state based on array position
   *
   * @param {Integer} pos
   * @param {String} val
   */
  const setCriteria = (obj, name) => {
    if (selectedCriteria.length > 0) {
      const updatedArr = selectedCriteria.map(criteria => criteria.name === name ? obj : criteria)
      dispatch({
        type: 'selectedCriteria',
        payload: updatedArr
      });
    } else {
      dispatch({
        type: 'selectedCriteria',
        payload: [obj]
      });
    }
  };
  /**
   *
   * Take a promotion ID and apply it to the product
   *
   * @param {integer} promoID - id of promotion to be used
   */
  const setPromotion = promoID => {
    const promotion = promoID ? selectedProduct?.promoPrices.find(promotion => promotion.promotion === promoID) : null;
    dispatch({ type: 'setSelectedPromotion', payload: promotion });
  }
  /**
   *
   * Take an action object for price option and dispatch it
   *
   * @param {Object} obj - a param object
   * @param {string} type - action string
   */
  const setPriceOption = type => dispatch({ type });
  /**
   *
   *  Take an action object for pay type and dispatch it
   *
   * @param {value} string - a paytype value string (outright or repayments)
   */
  const setPayType = value => {
    dispatch({ type: 'selectedPayType', payload: value });
  };
  /**
   *
   *  Take an action object for pay type and dispatch it
   *
   * @param {value} integer - repayment term, e.g. 12, 24
   */
  const setRepaymentOption = value => {
    dispatch({ type: 'selectedRepaymentOption', payload: value.name });
  };
  /**
   *
   * Select product based on array of filters given
   *
   * @param {Array} filtersArray
   * @returns First object in resulting array
   */
  const getProduct = filtersArray => {
    // Using the passed in array of filters we find the matching product
    // returning an object in a 1 item array
    const selectedProdArray = productsFromFamily.filter(prod => {
      const attrs = prod.attributes;
      return (
        attrs.filter(attr => {
          return filtersArray.find(arr => arr.name === attr.name && arr.value === attr.value)
        }).length === filtersArray.length
      );
    });
    // No product found error
    if (selectedProdArray.length === 0) {
      dispatch({
        type: 'selectedProductError',
        payload: true
      });
      // Set up some default product object for error situation
      const errorProduct = selectedProductInitialState.selectedProduct;
      errorProduct.images = [productFamilyImage];
      return errorProduct;
    }
    dispatch({
      type: 'selectedProductFound',
      payload: true
    });
    dispatch({
      type: 'selectedProductError',
      payload: false
    });
    return selectedProdArray[0];
  };
  /**
   *
   * Set up state based data structures needed to render Product view
   *
   */
  const setupProductOptions = () => {
    if (product && stocks?.length > 0) {
      const {
        imageUrl,
        products = [],
        normalDelivery: initNormalDelivery
      } = product;
      // Set default image from product family level
      dispatch({
        type: 'productFamilyImage',
        payload: imageUrl
      });
      // Get all unique attribute types and values put them in keyed aboject with array values
      const allAttributes = chain(products)
        .map('attributes') // grab all attributes
        .flatten() // flatten the elements into a single array
      // .filter({
      //   type: 'SELECTION'
      // }) // get SELECTION type // TOCHECK is this necessary?
      // .value();
      const uniqueAttributes = uniqWith(allAttributes.filter({ type: 'SELECTION' }).value(), isEqual); // Remove duplicate attributes
      const groupedAttributes = groupBy(uniqueAttributes, 'name'); // Group attribute values under their name
      //unfiltered allAttributes
      const uniqueAttributesAll = uniqWith(allAttributes.value(), isEqual);
      const groupedAttributesAll = groupBy(uniqueAttributesAll, 'name');
      dispatch({
        type: 'arrAttributes',
        payload: groupedAttributes
      });
      dispatch({
        type: 'arrAttributesAll',
        payload: groupedAttributesAll
      });

      const inStockProducts = stocks?.filter(productObj => !productObj.outOfStock);
      const defaultProduct = products.length > 1 
                              ? (products.find(productObj => inStockProducts.some(prod => prod.sku === productObj.sku)) || products[0])
                              : products[0];

      if (products.length >= 1) {
        dispatch({
          type: 'selectedProduct',
          payload: defaultProduct
        });
        dispatch({
          type: 'productsFromFamily',
          payload: products
        });
        // Initialize default values, currently taken from first item in array
        const {
          attributes,
          currentPrice: initCurrentPrice = null,
          originalPrice: initOriginalPrice = null,
          postageMessage: initPostageMessage = 'A delivery fee of $5.95 may apply.',
          showDeliveryInformation: initShowDeliveryInformation = null
        } = defaultProduct;
        const initPriceOptions = initCurrentPrice ? initCurrentPrice.priceOptions : [];
        const initOriginalPriceOptions = initOriginalPrice ? initOriginalPrice.priceOptions : [];

        if (attributes.length > 0) {
          const dindex = {}
          Object.keys(groupedAttributes).length > 0 && Object.keys(groupedAttributes).forEach((key, i) => {
            groupedAttributes[key].sort((a, b) => isNaN(parseFloat(a.value)) ? (a.value > b.value ? 1 : -1) : (parseFloat(a.value) > parseFloat(b.value) ? 1 : -1));
            const attributeName = getAttributeName(key, defaultProduct)?.toLowerCase();
            const defaultIndex = groupedAttributes[key].findIndex(o => o.value.toLowerCase() == attributeName);
            dindex[key] = defaultIndex;
          })
          dispatch({
            type: 'defaultIndexes',
            //set default indexes for attributes to select
            payload: dindex
          });
          dispatch({
            type: 'selectedCriteria',
            //only set selectable attributes for the criteria
            payload: attributes.filter( attr => attr.type === 'SELECTION' && attr.name !== 'ColourImage')
          });
        } else {
          dispatch({
            type: 'selectedProductFound',
            payload: true
          });
        }
        if (repaymentOptions.length > 0) {
          dispatch({
            type: 'selectedRepaymentOption',
            payload: repaymentOptions[0]
          });
        }
        if (initPriceOptions.length > 0) {
          // We want to add only if theres more than one price option
          dispatch({
            type: 'selectedPrice',
            payload: initPriceOptions[0]
          });
          dispatch({
            type: 'setMinPrice',
            payload: initPriceOptions[0]
          });
        }
        if (initOriginalPriceOptions.length > 1) {
          dispatch({
            type: 'selectedOriginalPrice',
            payload: defaultProduct.originalPrice?.priceOptions[0]
          });
        }
        if (initPostageMessage.length > 0) {
          dispatch({
            type: 'setPostageMessage',
            payload: initPostageMessage
          });
        }
        if (initShowDeliveryInformation != null) {
          dispatch({
            type: 'setShowDeliveryInformation',
            payload: initShowDeliveryInformation
          });
          //default show delivery information if true?
        } else {
          dispatch({
            type: 'setShowDeliveryInformation',
            payload: true
          });
        }
        if (initNormalDelivery != null) {
          dispatch({
            type: 'setNormalDelivery',
            payload: initNormalDelivery
          });
        } else {
          dispatch({
            type: 'setNormalDelivery',
            payload: true
          });
        }
      }
    }
  };
  const selectClosestPriceOption = () => {
    const sortedPriceOptions = priceOptions
      .map((priceOption, index) => { return { priceOption, index } })
      .sort((a, b) => b.priceOption.upfrontPrice - a.priceOption.upfrontPrice);
    const targetPrice = sortedPriceOptions.find(opt => opt.priceOption.upfrontPrice < pointsValue);
    if (sortedPriceOptions.length > 0) {
      dispatch({ type: "setPriceOption", payload: targetPrice ? targetPrice.index : [...sortedPriceOptions].pop().index });
    }
  }

  const setValidForSegment = () => {
    const validForSegment = productFamilies && productFamilies.length > 0 && !productFamilies[0].ineligibleForSegment;
    dispatch({type: "setValidForSegment", payload: validForSegment})
  }

  // When product changes fire the setup function, this should only run on page load where product is passed in as prop
  useEffect(() => {
    setupProductOptions();
  }, [product, stocks]);

  useEffect(() => {
    selectClosestPriceOption();
  }, [selectedProduct]);

  useEffect(() => {
    setValidForSegment();
  }, [productFamilies])

  // Every time the selection criteria (attributes) changes we fire off the get product function
  useEffect(() => {
    if (selectedCriteria.length > 0) {
      // call getProduct with array
      const selectedProductByCriteria = getProduct(selectedCriteria);
      dispatch({
        type: 'selectedProduct',
        payload: selectedProductByCriteria
      });
      // setselectedProduct(selectedProductByCriteria);
    }
    else if (selectedProduct.id) { //for the products not having selectedCriteria details like Color,Memory etc
      dispatch({
        type: 'selectedProductFound',
        payload: true
      })
    }
  }, [selectedCriteria]);
  // Every time the user goes up and down the price option list progress bar
  useEffect(() => {
    if (selectedProductFound) {
      // const { ongoingPrice } = priceOptions[priceOption];
      if (priceOptions.length === priceOption.value) {
        priceOption.value -= 1;
      }
      dispatch({
        type: 'selectedPrice',
        payload: selectedPromotion
          ? selectedPromotion?.priceOptions[priceOption.value]
          : priceOptions[priceOption.value]
      });
      if (selectedPromotion) {
        dispatch({
          type: 'selectedOriginalPrice',
          payload: priceOptions[priceOption.value]
        });
      } else if (originalPriceOptions) {
        dispatch({
          type: 'selectedOriginalPrice',
          payload: originalPriceOptions[priceOption.value]
        });
      } else {
        dispatch({
          type: 'selectedOriginalPrice',
          payload: null
        });
      }
      if (priceOption.value === 0) {
        dispatch({
          type: 'selectedPriceOptionProgress',
          payload: 'Start'
        });
      }
      if (
        priceOption.value > 0 &&
        priceOption.value < priceOptions.length - 1
      ) {
        dispatch({
          type: 'selectedPriceOptionProgress',
          payload: 'Normal'
        });
      }
      if (priceOption.value >= priceOptions.length - 1) {
        dispatch({
          type: 'selectedPriceOptionProgress',
          payload: 'End'
        });
      }
    }
  }, [priceOption, selectedPromotion]);
  useEffect(() => {
    if (priceOptions.length > 0) {
      const [{ upfrontPrice }] = priceOptions.slice(-1);
      dispatch({
        type: 'highestPoints',
        payload: upfrontPrice
      });
      dispatch({
        type: 'setMinPrice',
        payload: priceOptions[0]
      });
    }
  }, [priceOptions]);
  useEffect(() => {
    if (originalPriceOptions && originalPriceOptions.length > 0) {
      const [{ upfrontPrice }] = originalPriceOptions.slice(-1);
      dispatch({
        type: 'originalHighestPoints',
        payload: upfrontPrice
      });
    } else {
      dispatch({
        type: 'originalHighestPoints',
        payload: null
      });
    }
  }, [originalPriceOptions]);
  useEffect(() => {
    dispatch({
      type: 'setOutOfStock',
      payload: stocks.find(productObj => sku === productObj.sku)?.outOfStock
    })
  }, [stocks, sku])
  useEffect(() => {
    if (sku && isAuthenticated) {
      const formatTiers = exclusiveTiers.map(tier => capitalize(tier)).join(' and ');
      if (formatTiers && !exclusiveTiers.includes(loyaltyTier.toUpperCase())) {
        dispatch({ type: 'setOnePlaceIneligibleNotice', payload: `This offer is only available for ${formatTiers} tier.` })
      } else {
        dispatch({ type: 'setOnePlaceIneligibleNotice', payload: null })
      }
    }
  }, [sku, loyaltyTier]);
  useEffect(() => {
    if (outOfStock) {
      dispatch({
        type: 'setStockNotification', payload: {
          label: "Out of Stock",
          description: "This product is temporarily out of stock. Please choose another product to proceed.",
          priority: -50,
          type: "warning"
        }
      });
    } else {
      dispatch({ type: 'setStockNotification', payload: null })
    }
  }, [outOfStock]);
  useEffect(() => {
    const { upfrontPrice } = selectedPrice || {};
    const enoughPoints = pointsValue > upfrontPrice;
    if (!isAuthenticated || enoughPoints || outOfStock) {
      dispatch({ type: 'setCreditsNotification', payload: null });
    } else {
      const lessThanMin = pointsValue < minPrice?.upfrontPrice;
      dispatch({
        type: 'setCreditsNotification', payload: {
          key: "creditsMessage",
          label: "Not Enough Points",
          description: `You need at least ${selectedPrice?.upfrontPrice - pointsValue} more points to redeem this reward.`,
          type: (lessThanMin ? "error" : "warning"),
          priority: -100
        }
      });
    }
  }, [pointsValue, selectedPrice, outOfStock]);
  useEffect(() => {
    const convertToNotification = notifs => {
      return notifs.map(notif => {
        const values = notif?.value?.split('|').map(val => val.trim());
        if (values) {
          return {
            label: values[0] ? values[0] : "NO LABEL PROVIDED",
            description: values[1] ? values[1] : null,
            priority: values[2] ? values[2] : 0,
            type: values[3] ? values[3] : "info"
          }
        }
        return null
      })
    }
    dispatch({
      type: 'setOtherNotifications',
      payload: arrAttributesAll.notifications ? convertToNotification(arrAttributesAll.notifications) : null
    });
  }, [arrAttributesAll.notifications]);
  return [
    {
      ...state,
      setCriteria,
      setPriceOption,
      setPayType,
      setRepaymentOption,
      setPromotion
    }
  ];
};
export default useSelectedProduct;
