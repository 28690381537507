export const genericPhrases = [
  `Sit tight, we're working on getting your details!`,
  'Just a little longer…',
  'Almost there…'
];

export const loadingPhrases = [
  'We’re setting up your membership…',
  'Just a little longer…',
  'Almost there…'
];

export const retryPhrases = [
  'Retrying...',
  'Just a little longer...',
  'Almost there...'
];

export const unenrolPhrases = [
  "You're leaving Telstra Plus...",
  'Please wait a little longer...',
  'Almost done...'
];
