import {
  GET_REWARD_CATALOG_PENDING,
  GET_REWARD_CATALOG_SUCCESS,
  GET_REWARD_CATALOG_FAILURE,
  SET_REWARD_PRODUCT_FILTER,
  RESET_REWARD_PRODUCT_FILTER,
  SET_REWARD_STOCK_FILTER,
  SET_REWARD_PRODUCT_SORT,
  GET_REWARD_QUICKSEARCH_PENDING,
  GET_REWARD_QUICKSEARCH_SUCCESS,
  GET_REWARD_QUICKSEARCH_FAILURE,
  CLEAR_REWARD_QUICKSEARCH,
  SET_REWARD_SEARCH_QUERY,
  RESET_REWARD_FILTER_VIEW,
  SET_REWARD_CATEGORIES,
  RESET_REWARD_CATEGORIES_FILTER,
  SET_REWARD_OFFER_TYPES,
  RESET_REWARD_OFFER_TYPES,
  GET_REWARD_CATALOG_NO_SERVICE
} from 'constants/actions';

import _ from 'lodash';

const defaultState = {
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  sort: 'newestToOldest',
  searchQuery: '',
  quickSearch: {
    status: 'idle',
    results: [],
    error: null
  },
  filters: {
    brands: [],
    attributes: {
      // size: ['44mm', '50mm']
    }
  },
  nextOffset: null,
  categoriesFilter: {},
  offerTypeFilter: null,
  stockFilter: true,
  productFamilies: [],
  productBrands: {},
  productTags: {
    offerTypes: [],
    productTypes: []
  },
  error: null
};

export const catalogView = (state = defaultState, action) => {
  switch (action.type) {
    case GET_REWARD_CATALOG_PENDING: {
      return {
        ...state,
        status: 'loading'
      };
    }
    case GET_REWARD_CATALOG_SUCCESS: {
      const {
        payload: { catalog, appendData }
      } = action;
      const offerTypes = [];
      const productTypes = [];
      const productBrands = _.chain(catalog.productBrands)
        .keyBy('id')
        .value();
      
      if (catalog.productTags) {
        catalog.productTags.offerTypes.forEach(tag =>  {
          if(tag["description"].toLowerCase() !== "promotiontype"){
            offerTypes.push(tag)
          }
        });
        catalog.productTags.productTypes.forEach(tag => productTypes.push(tag));
      }
      return {
        ...state,
        nextOffset: catalog.nextOffset,
        status: 'succeeded',
        productFamilies : appendData ? [...state.productFamilies, ...catalog.productFamilies] : catalog.productFamilies || [],
        productBrands : appendData ? Object.assign(state.productBrands, productBrands) : productBrands,
        productTags: {
          offerTypes,
          productTypes
        }
      };
    }
    case GET_REWARD_CATALOG_FAILURE: {
      return {
        ...state,
        status: 'failed',
        error: action.payload
      };
    }
    case SET_REWARD_PRODUCT_FILTER: {
      return {
        ...state,
        filters: action.payload
      };
    }
    case RESET_REWARD_PRODUCT_FILTER: {
      return {
        ...state,
        filters: {
          brands: [],
          attributes: {}
        }
      };
    }
    case SET_REWARD_STOCK_FILTER:{
      return {
        ...state,
        stockFilter:action.payload
      }
    }
    case SET_REWARD_PRODUCT_SORT: {
      return {
        ...state,
        sort: action.payload
      }
    }
    case SET_REWARD_SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: action.payload
      };
    }
    case SET_REWARD_CATEGORIES: {
      return {
        ...state,
        categoriesFilter: action.payload
      };
    }
    case SET_REWARD_OFFER_TYPES: {
      return {
        ...state,
        offerTypeFilter: action.payload
      };
    }
    case RESET_REWARD_OFFER_TYPES: {
      return {
        ...state,
        offerTypeFilter: null
      };
    }
    case RESET_REWARD_CATEGORIES_FILTER: {
      return {
        ...state,
        categoriesFilter: {}
      };
    }
    case GET_REWARD_QUICKSEARCH_PENDING: {
      return {
        ...state,
        quickSearch: {
          ...state.quickSearch,
          status: 'loading'
        }
      };
    }
    case GET_REWARD_QUICKSEARCH_SUCCESS: {
      return {
        ...state,
        quickSearch: {
          status: 'succeeded',
          results: action.payload
        }
      };
    }
    case GET_REWARD_QUICKSEARCH_FAILURE: {
      return {
        ...state,
        quickSearch: {
          status: 'failed',
          error: action.payload
        }
      };
    }
    case CLEAR_REWARD_QUICKSEARCH: {
      return {
        ...state,
        quickSearch: {
          status: 'idle',
          results: []
        }
      };
    }

    case RESET_REWARD_FILTER_VIEW: {
      return {
        ...state,
        resetFiltersView: action.payload
      }
    }

    case GET_REWARD_CATALOG_NO_SERVICE: {
      return {
        ...state,
        status: 'forbidden',
        error: action.payload
      };
    }

    default:
      return state;
  }
};
