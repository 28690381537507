import axios from './util/axiosHandle';
import { apiConfig as api } from '../oidc/oidc-configs';

const verifyOtp = otpDetails => {
  return axios({
    params: {
      method: 'post',
      url: api.baseUrl + api.endpoints.verifyOtp,
      data: {
        data: {
          referenceId: otpDetails.referenceId,
          serviceType: otpDetails.serviceType,
          oneTimePinValue: otpDetails.oneTimePinValue
        }
      }
    }
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export default verifyOtp;
