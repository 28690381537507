import axios from './util/axiosHandle';

import { aemConfig as aem, apiConfig as api } from '../oidc/oidc-configs';

export const getMockAemApi = () => {
  return axios({
    params: {
      method: 'get',
      url: api.baseUrl + api.endpoints.getMockAem
    },
    type: 'public'
  });
};

export const getAemApi = component => {
  let endpoint;
  switch (component) {
    case 'all':
      endpoint = aem.endpoints.all;
      break;
    case 'promos':
      endpoint = aem.endpoints.promos;
      break;
    default:
      endpoint = aem.endpoints.all;
  }
  return axios({
    params: {
      method: 'get',
      url: aem.baseUrl + endpoint
    },
    type: 'public'
  });
};
