import { LOGGER } from 'utils/AppLog';
import { getEvents, handleTicketErrors } from 'api/getTickets';
import * as actions from 'constants/actions';
import _ from 'lodash';
import { IN_DEVELOP_MOCKS } from 'constants/general';

const eventsAction = (id) => {
  return (dispatch, getState) => {
    if (getState().events?.fetch === 'loading') {
      return;
    }
    dispatch({ type: actions.FETCH_TICKETS, value: 'loading' });
    const setChildPageErrorAction = () => {
      dispatch({
        type: actions.SET_EVENTS_CHILD_PAGE_GLOBAL_ERROR,
        globalTicketErrorForChildPage: true
      });
    };
    return getEvents(id)
      .then(response => {
        // Some data successfully returns
        // Do some logic to extract what we need here
        dispatch({ type: actions.FETCH_TICKETS, value: 'loaded' });
        if (id === -1) {
          const keyedData = _.chain(response.data)
            .get('data.eventSummary', {})
            .groupBy('genre')
            .mapValues(eventData => {
              // Show the priority flagged items on top and sort the events so that latest start date comes on top
              return _.orderBy(
                eventData,
                ['carouselPositionPriorityFlag', 'eventSaleStartDate'],
                ['desc', 'desc']
              );
            })
            .value();

          dispatch({ type: actions.GET_EVENTS, keys: keyedData });
        } else {
          if (response.status === 204) {
            dispatch({ type: actions.FETCH_TICKETS, value: 'loaded' });
            dispatch({ type: actions.SET_LOADING, loading: false });
            setChildPageErrorAction();
          }
          const {
            data: {
              data: {
                // eslint-disable-next-line no-shadow
                eventSummary: { id } = { eventSummary: {} },
                eventSummary: details,
                eventPerformances: children
              } = { data: {} }
            } = { data: {} }
          } = response;

          dispatch({
            type: actions.GET_EVENTS_CHILD,
            keys: {
              id,
              details,
              children
            }
          });
        }
        LOGGER.debug('Get Events API Response', response);
      })
      .catch(error => {
        dispatch({ type: actions.FETCH_TICKETS, value: 'loaded' });
        dispatch({ type: actions.SET_LOADING, loading: false });
        if (id === -1 || (IN_DEVELOP_MOCKS && isNaN(id))) {
          dispatch(handleTicketErrors(error, 'event'));
        }
        setChildPageErrorAction();
      });
  };
};

export default eventsAction;
