import * as actions from 'constants/actions';
import store from '../../../store';

export const clearAllErrors = ({ clearAlert = true, flow = '' }) => {
  store.dispatch({
    type: actions.GET_OTP_FAILURE,
    errorObject: {
      status: '',
      msg: ''
    },
    flow
  });
  store.dispatch({
    type: actions.GET_MOBILE_FAILURE,
    errorObject: {
      status: '',
      msg: ''
    }
  });
  store.dispatch({
    type: actions.GET_EMAIL_FAILURE,
    errorObject: {
      status: '',
      msg: ''
    }
  });
  store.dispatch({
    type: actions.SET_ADDRESS_ERROR,
    payload: ''
  });
  if (clearAlert) {
    store.dispatch({
      type: actions.GET_OTP_RESEND,
      msg: ''
    });
  }
};
