import React from 'react';
import { Cta } from 'components';

import './CtaGroups.scss';

const CtaGroups = props => {
  const { primaryCtas, secondaryCtas } = props;

  const drawCta = ({
    ctaUrl,
    ctaAction,
    ctaTarget,
    ctaVariant = 'LowEmphasis',
    ctaTrackingCode,
    ctaText
  }) => {

    return (
      <Cta
        action={ctaAction}
        trackingCode={ctaTrackingCode}
        url={ctaUrl}
        ctaProps={{
          variant: ctaVariant,
          label: ctaText,
          ...(ctaTarget && { target: ctaTarget })
        }}
      />
    );
  };

  return (
    <div className="cta-groups">
      {primaryCtas && (
        <div className="primary">
          {Object.keys(primaryCtas).map(key => {
            return drawCta(primaryCtas[key]);
          })}
        </div>
      )}
      {secondaryCtas && (
        <div className="secondary">
          {Object.keys(secondaryCtas).map(key => {
            return drawCta(secondaryCtas[key]);
          })}
        </div>
      )}
    </div>
  );
};

export default CtaGroups;
