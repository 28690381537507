import { useCallback, useState } from 'react';

/**
 * Helper to be used to manage `isShowing` state of Dialogs
 *
 * @param initialValue - the current value of isShowing
 *
 * @returns {[boolean, Function]} React state property and change function
 */

function useToggle(initialValue = false) {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback(() => {
    setValue((v) => !v);
  }, []);
  return [value, toggle];
}

export default useToggle;
