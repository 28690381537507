// Action Types, Describe what is happening
import _ from 'lodash';

import { LOGGER } from 'utils/AppLog';
import * as actions from 'constants/actions';

import getPoints from 'api/getPoints';
import getPointsHistory from 'api/getPointsHistory';

import { fetchPointsPending } from 'reducers/points';
import { fetchPointsHistoryPending } from 'reducers/pointsHistory';

import { getCurrentCac, getCacList } from 'reducers/activeAccount';

import { isEnrolled } from 'reducers/enrolment';

// Action Creators
export const fetchPoints = user => {
  return (dispatch, getState) => {
    if (fetchPointsPending(getState()) || !isEnrolled(getState())) {
      return Promise.resolve();
    }

    dispatch({
      type: actions.GET_POINTS_PENDING
    });

    return getPoints(getCacList(getState()), user)
      .then(response => {
        LOGGER.debug('GetPoints Response: ', response);

        dispatch({
          type: actions.MERGE_ACCOUNTS_LIST,
          sortedPointsAccounts: response.data.loyalty.accounts
        });

        dispatch({
          type: actions.GET_POINTS_SUCCESS,
          response
        });
        // get latest points for selected CAC
        const currentState = getState();
        const currentCac = getCurrentCac(getState());
        const currentCacPoints = _.find(currentState.accounts.sortedAccounts, [
          'accountId',
          currentCac
        ]);
        LOGGER.debug('currentCacPoints: ', currentCacPoints);
        dispatch({
          type: actions.SET_INITIAL_POINTS,
          pointsValue: currentCacPoints?.points?.loyaltyPoints,
          onHoldPoints: currentCacPoints?.points?.onHoldPoints
        });
      })
      .catch(error => {
        LOGGER.error('Caught getPoints error: ', error);
        dispatch({ type: actions.GET_POINTS_FAILURE });
      });
  };
};

export const fetchPointsHistory = user => (dispatch, getState) => {
  if (fetchPointsHistoryPending(getState()) || !isEnrolled(getState())) {
    return Promise.resolve();
  }

  dispatch({ type: actions.GET_POINTS_HISTORY_PENDING });

  return getPointsHistory(getCurrentCac(getState()), user)
    .then(response => {
      LOGGER.debug('GetPointsHistory Response: ', response);
      dispatch({
        type: actions.GET_POINTS_HISTORY_SUCCESS,
        response
      });
    })
    .catch(error => {
      LOGGER.error('Caught GetPointsHistory error: ', error);
      dispatch({ type: actions.GET_POINTS_HISTORY_FAILURE });
    });
};

export const updatePoints = pointsvalue => {
  return { type: actions.GET_POINTS_SUCCESS, points: pointsvalue };
};
