import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Spacing, TextStyle } from '@able/react';
import { addLinkClickEvent } from 'utils/analytics';
import dayjs from 'dayjs';

import './RewardsProductTile.scss';

const RewardsProductTile = props => {
  const {
    id = '',
    name = '',
    brand = '',
    imageUrl = '',
    minOutright = '',
    isHotOffer = false,
    hasStock = true,
    minPay = undefined,
    minPoints = undefined,
    endDate,
    specialOffer,
    tierExclusive: {
      gold = false,
      silver = false,
      member = false,
      business = false
    },
    bsmCols = 3,
    bmdCols = 4,
    appendFromText = false,
    promoName = '',
  } = props;

  const dateFormat = 'D MMM YYYY';
  const endDateDisplay = dayjs(endDate).format(dateFormat);

  const renderLozenges = () => {
    const hasLozenge = !hasStock || gold || silver || member || business;
    return hasLozenge ? (
      <>
        <div className="tplus-rewards-product-tile__lozenge-wrap">
          {!hasStock && (
            <>
              <div className="tplus-rewards-product-tile__lozenge outofstock">
                Out of stock
              </div>
            </>
          )}
          {gold && (
            <div className="tplus-rewards-product-tile__lozenge gold-exclusive">
              Gold Exclusive
            </div>
          )}
          {silver && (
            <div className="tplus-rewards-product-tile__lozenge silver-exclusive">
              Silver Exclusive
            </div>
          )}
          {member && (
            <div className="tplus-rewards-product-tile__lozenge member-exclusive">
              Member Exclusive
            </div>
          )}
          {business && (
            <div className="tplus-rewards-product-tile__lozenge business-exclusive">
              Business Exclusive
            </div>
          )}
        </div>
        <Spacing bottom="spacing2x" />
      </>
    ) : null;
  };

  return (
    <li
      className={`tplus-rewards-product-tile able-column cols-6 bsm-cols-${bsmCols} bmd-cols-${bmdCols}`}
    >
      <div className="tplus-rewards-product-tile__container">
        {(isHotOffer || promoName) && (
          <div className="tplus-rewards-product-tile__promo--text">
            <Icon
              size="32"
              developmentUrl="/able-sprites.svg"
              icon="Deal"
              screenReaderContent="icon deal"
              className="icon-fill"
            />
            <span className="tplus-rewards-product-tile__promo--text-inner">
              {isHotOffer ? "Hot Offer" : promoName ? promoName : ''}
            </span>
          </div>
        )}
        <div
          className={`tplus-rewards-product-tile__content ${(isHotOffer || promoName)  &&
            'hot-offer'}`}
        >
          <Spacing
            top="spacing2x"
            bottom="spacing2x"
            left="spacing2x"
            right="spacing2x"
          >
            <div
              className={`tplus-rewards-product-tile__image ${
                hasStock ? '' : 'outofstock'
              }`}
            >
              <img src={imageUrl} alt = ""/>
            </div>
            <Spacing bottom="spacing2x" />
            <Link
              className="tplus-rewards-product-tile__link"
              to={`/rewards/explore/${id}`}
              onClick={() => addLinkClickEvent(`product ${name}`)}
            >
              <TextStyle
                alias="Base"
                className="tplus-rewards-product-tile__brand"
                element="h2"
              >
                {brand}
              </TextStyle>
              <TextStyle
                alias="BaseBig"
                element="h3"
                className="tplus-rewards-product-tile__name"
              >
                <strong>{name}</strong>
              </TextStyle>
            </Link>
            <Spacing bottom="spacing2x" />
            {renderLozenges()}
            <TextStyle alias="BaseBig" element="div">
              {appendFromText ? "From ":""} {Intl.NumberFormat().format(minOutright)} pts
            </TextStyle>
            {minPoints > 0 && minPay > 0 && (              
                <TextStyle alias="Base" element="div">
                  {`Or from ${Intl.NumberFormat().format(minPoints)} pts + $${Intl.NumberFormat().format(minPay)}`}
                </TextStyle>              
            )}
            {specialOffer && endDate && (
              <>
                <Spacing top="spacing2x" />
                <TextStyle alias="FinePrint" element="div">
                  Offer ends {endDateDisplay}
                </TextStyle>
              </>
            )}
          </Spacing>
        </div>
      </div>
    </li>
  );
};

export default RewardsProductTile;
