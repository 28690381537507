import React, { useEffect } from 'react';
import { CheckboxGroup, Checkbox } from '@able/react';
import { useFocus } from 'utils/hooks/useFocus';

import './FormCheckboxGroup.scss';

const FormCheckboxGroup = props => {
  const {
    label = '',
    name = '',
    checkboxes = {},
    minRequired = '0',
    handleChange,
    submitCount = 0,
    values = {},
    isSubmitting = false,
    isValidating = false,
    errors = {}
  } = props;

  const checkedItems = values[name] || [];
  const errored = errors[name] && submitCount > 0;
  const [inputRef, setInputFocus] = useFocus();

  useEffect(() => {
    if (isSubmitting && !isValidating && Object.keys(errors)[0] === name) {
      setInputFocus();
    }
  }, [isSubmitting, isValidating, errors]);

  return (
    <div className="tplus-form-field" id={name}>
      <CheckboxGroup
        developmentUrl="/able-sprites.svg"
        label={label}
        required={minRequired !== '0'}
        name={name}
        variant="Default"
        invalid={errored}
        invalidMessage={errors[name]}
      >
        {Object.keys(checkboxes).map((key, i) => {
          return (
            <Checkbox
              key={i}
              name={checkboxes[key]}
              ref={i === 0 ? inputRef : null}
              checked={checkedItems.includes(checkboxes[key].value)}
              label={checkboxes[key].label}
              value={checkboxes[key].value}
              description={checkboxes[key].description || ''}
              events={{
                onChange: handleChange
              }}
            />
          );
        })}
      </CheckboxGroup>
    </div>
  );
};

export default FormCheckboxGroup;

FormCheckboxGroup.defaultProps = {
  active: '',
  name: 'checkboxGroup',
  title: '',
  description: '',
  checkboxes: {}
};
