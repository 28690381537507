import React from 'react';
import './VerticalTabs.scss';
import IconArrow from 'icons/IconArrow';
import Tab from './Tab';
import { TextStyle } from '@able/react';

const VerticalTabs = ({ tabs, title, ctacopy, ctaUrl }) => {
  return (
    <section>
      <div className="rewards-tabs-wrapper">
        {title && <TextStyle alias="HeadingB" element="h2" className="vertical-tab-heading">{title}</TextStyle>}
        <ul className="vertical-tab-body">
          {Object.keys(tabs).map(tab => (
            <Tab
              id={tabs[tab].id}
              title={tabs[tab].title}
              description={tabs[tab].description}
              key={tabs[tab].title}
              expanded={tabs[tab].expanded}
              internallink={tabs[tab].internallink}
              internallinktext={tabs[tab].internallinktext}
            />
          ))}

          {ctacopy && ctaUrl && (
            <a
              // target={footer.targetNewWindow === 'true' ? '_blank' : null}
              className="primary-cta-18 vertical-tab-footer"
              href={ctaUrl}
            >
              {ctacopy} <IconArrow />
            </a>
          )}
        </ul>
      </div>
    </section>
  );
};

export default VerticalTabs;
