/* eslint-disable no-underscore-dangle */
// Handler Library for the Omniture Analytics stuff
import _ from 'lodash';

import { LOGGER } from 'utils/AppLog';

export const initAnalytics = env => {
  LOGGER.debug('Initialising Analytics Object');

  window.digitalData = {
    user: [],
    page: {
      attributes: {
        division: 'TD', // Division. "TD" for Telstra Digital.
        subDivision: 'TR', // Sub division. "TR" for Telstra Retail;
        subDivisionUnit: 'TR' // Site. "TR" for Telstra Retail.
      },
      pageInfo: {
        pageName: '', // insert relevant value - should be unique for every pageview
        destinationURL: '', // The URL of the current page. Typically you can map it to 'window.location.href'
        referringURL: '', // The URL of the previous page -> Typically you can map this to document.referrer
        sysEnv: env, // Whether the user is operating a desktop or mobile environment/other device environments.
        deployEnv: process.env.REACT_APP_ENV // The deployment environment the page is currently on. This value controls which database/server the data goes to. Allowed values examples: "production", "dev", "uat". Any string other than "production" means non-prod environments.
      },
      category: {
        primaryCategory: 'Telstra Plus Portal', // Forms <name of the application>
        secondaryCategory: '', // whatever goes after first slash in URL.
        pageType: 'Telstra Plus Portal' // name of the application
      },
      search: {
        query: ''
      }
    },
    product: [],
    event: [],
    version: '1.0'
  };
};

export const clearProducts = () => {
  window.digitalData.product = [];
};

export const setProduct = (product = {}, price = {}, tier) => {
  LOGGER.debug('Adding Analytics Product: ', product);

  const {
    sku = '',
    name: productName = '',
    manufacturer = '',
    orderType = '',
    rrp: basePrice = '',
    categoryName: selectedCategory = ''
  } = product;
  const { upfrontPrice: points = '', ongoingPrice: pay = '' } = price;
  const { segment: loyaltySegment = '' } = tier;
  const productObj = {
    productInfo: {
      sku,
      productName,
      manufacturer,
      orderType
    },
    price: {
      basePrice,
      points,
      pay
    },
    category: {
      primaryCategory:
        loyaltySegment === 'BUSINESS'
          ? 'small business loyalty'
          : 'consumer loyalty',
      secondaryCategory: 'loyalty',
      tertiaryCategory: selectedCategory,
      productType: 'Primary'
    }
  };
  clearProducts();
  window.digitalData.product.push(productObj);
};

export const setUser = user => {
  LOGGER.debug('Adding Analytics User: ', user);

  const {
    // username = '',
    uuid: userContactUUID = '',
    points: loyaltyPoints = '',
    tier: loyaltyTier = '',
    rcrmContactId: siebelContactID = ''
  } = user;

  const userObj = {
    profile: [
      {
        profileInfo: {
          // username, // The username that a user uses when logging in to an authenticated flow.
          // b2cUID: '', // The ID numbers will be retrieved from CAIMAN B2C UID
          // b2bUID: '', // The ID numbers will be retrieved from CAIMAN B2B UID
          userContactUUID, // Salesforce Contact UUID
          loyaltyPoints,
          loyaltyTier,
          siebelContactID
        }
      }
    ]
  };
  // we are replacing only the first user for now
  // future may have multiple
  window.digitalData.user.splice(0, 1, userObj);
};

export const setPageName = (
  page = '', // tracking code
  lastLocation // last location object from page
) => {
  LOGGER.debug('Updating Analytics PageName to: ', page);
  // Will use current values in window object
  const { location = {} } = window;
  const { href: destination = '' } = location;

  // Check if we have come from internal redirect, if null then pick up the browser referrer
  const previousLocation = lastLocation
    ? `${window.location.protocol}//${window.location.host}${lastLocation.pathname}`
    : document.referrer;

  const pageObj = {
    pageName: page,
    destinationURL: destination,
    referringURL: previousLocation
  };

  if (_.has(window.digitalData.page, 'pageInfo')) {
    window.digitalData.page.pageInfo = {
      ...window.digitalData.page.pageInfo,
      ...pageObj
    };
  }
};

export const setCategory = () => {
  // Will use current values in window object
  const { location = '' } = window;
  const pathParts = new URL(location).pathname.split('/');
  if (_.has(window.digitalData.page, 'category')) {
    window.digitalData.page.category = {
      ...window.digitalData.page.category,
      secondaryCategory: pathParts[1]
    };
  }
};

export function addEventAction({ eventAction = '' }) {
  LOGGER.debug('Adding Analytics Event Action: ', eventAction);
  const eventActionObj = {
    eventInfo: { eventAction }
  };
  window.digitalData.event.push(eventActionObj);
}

export const addLinkActionEvent = action => {
  if (action) {
    return addEventAction({
      eventAction: action
    });
  }
  return false;
};

export function addEvent({
  eventName = '',
  eventAction = '',
  eventType = '',
  eventCategory = '',
  attributes = null
}) {
  LOGGER.debug('Adding Analytics Event: ', eventName);
  const eventObj = {
    eventInfo: { eventName, eventAction, eventType, eventCategory }
  };
  if (attributes) {
    eventObj.attributes = attributes;
  }
  window.digitalData.event.push(eventObj);
}

export const addLinkClickEvent = name => {
  if (name) {
    return addEvent({
      eventName: name,
      eventAction: 'click',
      eventType: 'clickTrack',
      eventCategory: 'linkClick'
    });
  }
  return false;
};

export const getCampaignDataAttribute = componentAttribute => {
  if (
    window._tdTags &&
    window._tdTags.public &&
    window._tdTags.public.getCampaignDataAttribute
  ) {
    const attributes = window._tdTags.public.getCampaignDataAttribute(
      'tplus',
      componentAttribute,
      'personalisation'
    );
    if (attributes) {
      return {
        [attributes.campaignDataAttribute]: attributes.campaignCode
      };
    }
  }
  return null;
};

export const addExperienceEvent = componentAttribute => {
  const eventObj = {
    eventAction: 'campaignImpression',
    eventType: 'showCampaign',
    eventCategory: 'personalisation',
    eventName: '',
    attributes: {
      component: [componentAttribute]
    }
  };
  return addEvent({ ...eventObj });
};

export const setSearchQuery = queryString => {
  window.digitalData.page.search.query = queryString;
};
