import React, { cloneElement } from 'react';
import htmr from 'htmr';
import _ from 'lodash';
import { Spacing, TextStyle } from '@able/react';
import { HtmlConverter } from 'utils/HtmlConverter';

import './Table.scss';

const Table = props => {
  const {
    title,
    titleLevel = 'h2',
    text,
    caption,
    tableHeaderStyle,
    desktopVariation,
    zebraBackground
  } = props;

  // eslint-disable-next-line consistent-return
  const setupTable = (html = '') => {
    if (html) {
      const transform = {
        caption: node => {
          const { children } = node;
          return <caption className="sr-only">{children}</caption>;
        },
        table: node => {
          const { children, width, height } = node;
          const tableClass =
            tableHeaderStyle === 'columnHeader'
              ? 'tcom-table-columnHeader'
              : 'tcom-table';
          return (
            <table width={width} height={height} className={tableClass}>
              {children &&
                children.map(child => {
                  const {
                    type: { name }
                  } = child;
                  if (name === 'caption') {
                    return child;
                  }
                  if (name === 'tbody') {
                    const thead = ((child || {}).props || {}).children[0];
                    child.props.children.shift();
                    return (
                      <>
                        <thead className="tcom-table-header">{thead}</thead>
                        {child}
                      </>
                    );
                  }
                  return null;
                })}
            </table>
          );
        },
        tbody: node => {
          const { children } = node;
          return (
            <tbody
              className={`tcom-table-body ${zebraBackground &&
                'tcom-table-body--zebra'}`}
            >
              {children &&
                children.map(child => {
                  return cloneElement(child, { parent: 'tbody' });
                })}
            </tbody>
          );
        },
        tr: node => {
          const { children, parent } = node;
          const trClass = parent === 'tbody' ? 'tcom-table-body-row' : '';
          return (
            <tr className={trClass}>
              {children &&
                children.map((child, i) => {
                  const last = i === children.length - 1;
                  return cloneElement(child, {
                    parent: 'tbody',
                    index: i,
                    last
                  });
                })}
            </tr>
          );
        },
        th: node => {
          const { children, width, index, last } = node;
          let bgClass = '';
          if (desktopVariation === 'complex') {
            if (index === 0) {
              bgClass = 'tcom-table-column-row-header';
            } else if (!last) {
              bgClass = 'tcom-table-cell__sideborder';
            }
          }
          return (
            <th width={width} className={`tcom-table-header-cell ${bgClass}`}>
              {children &&
                children.map((child, i) => {
                  const returnVal = (typeof child === 'object') ? child : HtmlConverter(child);
                  return (
                    <TextStyle key={i} alias="Label">
                      {returnVal}
                    </TextStyle>
                  );
                })}
            </th>
          );
        },
        td: node => {
          let Element = 'td';
          let styleAlias = 'Base';
          let bgClass;
          const { children, parent, index, last } = node;
          const tdClass = parent === 'tbody' ? 'tcom-table-body-row__cell' : '';
          switch (tableHeaderStyle) {
            case 'columnHeader': {
              if (index === 0) {
                Element = 'th';
                styleAlias = 'Label';
                if (desktopVariation === 'simple') {
                  bgClass = 'tcom-table-body-row__cell--background';
                } else {
                  bgClass = 'tcom-table-column-row-header';
                }
              }
              break;
            }
            case 'columnRowHeader': {
              if (index === 0) {
                Element = 'th';
                styleAlias = 'Label';
                if (desktopVariation === 'complex') {
                  bgClass = 'tcom-table-column-row-header';
                }
              } else if (desktopVariation === 'complex' && !last) {
                bgClass = 'tcom-table-cell__sideborder';
              }
              break;
            }
            default: {
              Element = 'td';
            }
          }
          return (
            <Element className={`${tdClass} ${bgClass}`}>
              {children &&
                children.map((child, i) => {
                  const returnVal = (typeof child === 'object') ? child : HtmlConverter(child);
                  return (
                    <TextStyle key={i} alias={styleAlias}>
                      {returnVal}
                    </TextStyle>
                  );
                })}
            </Element>
          );
        }
      };
      return htmr(html, { transform });
    }
  };

  const setupMobileList = (tRows = [], tHeaders = []) => {
    return tRows.map((row, rowIndex) => {
      const {
        props: { children: rowChildren }
      } = row;
      let headerIndex = -1;
      return rowChildren.map((cell, i) => {
        const {
          props: { children: cellData }
        } = cell;
        const bgClass =
        rowIndex % 2 === 0 ? 'tcom-table-mobile__dd--headerAlternate' : '';
        let headerClass = '';
        if (headerIndex === tHeaders.length - 1) {
          headerIndex = -1;
        } else {
          headerIndex += 1;
        }
        if (headerIndex === 0) {
          headerClass = 'tcom-table-mobile-columnHeader__topborder';
        }
        const header = tHeaders[headerIndex]?.props?.children[0];
        return (
          <li key={i} className={`tcom-table-mobile__dd ${bgClass}`}>
            <TextStyle
              alias="Label"
              element="span"
              className={`tcom-table-mobile__dd--header tcom-table-mobile-columnHeader ${headerClass}`}
            >
              {header}
            </TextStyle>
            <TextStyle
              alias="Base"
              element="span"
              className={`tcom-table-mobile__dd--data ${headerClass}`}
            >
              {cellData}
            </TextStyle>
          </li>
        );
      });
    });
  };

  const setupMobileDl = (tRows = [], tHeaders = []) => {
    const colHeader = tHeaders[0]?.props?.children[0];
    const dHeaders = tHeaders.slice(1);
    return tRows.map(row => {
      const rowHeader = row?.props?.children[0]?.props?.children[0];
      const dd = dHeaders.map((dHeader, i) => {
        const header = dHeader?.props?.children[0];
        const data = row?.props?.children[i + 1]?.props?.children[0];
        return (
          <dd key={i} className="tcom-table-mobile__dd">
            <TextStyle
              alias="Label"
              element="span"
              className="tcom-table-mobile__dd--header"
            >
              {HtmlConverter(header)}
            </TextStyle>
            <TextStyle
              alias="Base"
              element="span"
              className="tcom-table-mobile__dd--data"
            >
              {HtmlConverter(data)}
            </TextStyle>
          </dd>
        );
      });
      return (
        <>
          <dt className="tcom-table-mobile__dt">
            <TextStyle
              alias="FinePrint"
              element="span"
              className="tcom-table-mobile__dt--header"
            >
              {HtmlConverter(colHeader)}
            </TextStyle>
            <Spacing bottom="spacingHalf" />
            <TextStyle
              alias="HeadingC"
              element="span"
              className="tcom-table-mobile__dt--data"
            >
              {HtmlConverter(rowHeader)}
            </TextStyle>
          </dt>
          {dd}
        </>
      );
    });
  };

  // eslint-disable-next-line consistent-return
  const setupMobileTable = (html = '') => {
    if (html) {
      const transform = {
        table: node => {
          const { children } = node;
          const tBody = _.find(children, { type: 'tbody' });
          const tBodyChildren = tBody?.props?.children || null;
          const Element = tableHeaderStyle === 'rowHeader' ? 'ul' : 'dl';
          let tableList = [];
          if (tBodyChildren && tBodyChildren.length > 0) {
            const tHeaders = tBody?.props?.children[0]?.props?.children; // needs defense
            const tRows = tBodyChildren.splice(1);
            if (tRows && tRows.length > 0) {
              if (tableHeaderStyle === 'rowHeader') {
                tableList = setupMobileList(tRows, tHeaders);
              } else {
                tableList = setupMobileDl(tRows, tHeaders);
              }
            }
          }
          return <Element className="tcom-table-mobile">{tableList}</Element>;
        }
      };
      return (
        <div aria-label={caption} className="tcom-table-mobile__container">
          {htmr(html, { transform })}
        </div>
      );
    }
  };

  return (
    <>
      {title && (
        <>
          <TextStyle alias="BaseBig" element={titleLevel}>
            {title}
          </TextStyle>
          <Spacing bottom="spacing3x" />
        </>
      )}
      {setupTable(text)}
      {setupMobileTable(text)}
    </>
  );
};

export default Table;
