import React from 'react';
import { Grid, Spacing, Column, ActionButton, TextStyle } from '@able/react';
import { Breadcrumbs } from 'components';
import * as Analytics from 'utils/analytics';

const NotEnrolled = () => {
  Analytics.setPageName('Opt Out - Not Enrolled');
  return (
    <Grid className="container">
      <Column>
        <Spacing bottom="spacing7x" />
        <Breadcrumbs />
        <TextStyle element="h1" alias="HeadingB">
          You don&#39;t have a Telstra Plus membership
        </TextStyle>
        <Spacing bottom="spacing2x" />
        <TextStyle element="p" alias="Base">
          You can&#39;t opt-out of Telstra Plus because you don&#39;t have a membership.
        </TextStyle>
        <TextStyle element="p" alias="Base">
          Get started with Telstra Plus and from 14 May you can start earning
          points, enjoy amazing benefits and a lot more coming soon.
        </TextStyle>
        <Spacing bottom="spacing2x" />
        <ActionButton
          element="Link"
          to="/join"
          variant="LowEmphasis"
          label="Join Telstra Plus"
        />
        <Spacing bottom="spacing2x" />
        <TextStyle element="p" alias="FinePrint">
          Eligibility criteria apply.
        </TextStyle>
        <Spacing bottom="spacing10x" />
      </Column>
    </Grid>
  );
};
export default NotEnrolled;
