import {
  SET_REWARD_PROFILE,
  GET_REWARD_CATALOG_PENDING,
  GET_REWARD_CATALOG_SUCCESS,
  GET_REWARD_CATALOG_FAILURE,
  GET_REWARD_PRODUCT_PENDING,
  GET_REWARD_PRODUCT_SUCCESS,
  GET_REWARD_PRODUCT_FAILURE,
  SET_REWARD_PRODUCT_FILTER,
  RESET_REWARD_PRODUCT_FILTER,
  SET_REWARD_STOCK_FILTER,
  SET_REWARD_PRODUCT_SORT,
  GET_REWARD_QUICKSEARCH_PENDING,
  GET_REWARD_QUICKSEARCH_SUCCESS,
  GET_REWARD_QUICKSEARCH_FAILURE,
  CLEAR_REWARD_QUICKSEARCH,
  SET_REWARD_SEARCH_QUERY,
  RESET_REWARD_PRODUCT,
  RESET_REWARD_FILTER_VIEW,
  CHECKOUT_PENDING,
  CHECKOUT_FAILURE,
  SET_REWARD_CATEGORIES,
  RESET_REWARD_CATEGORIES_FILTER,
  SET_REWARD_OFFER_TYPES,
  RESET_REWARD_OFFER_TYPES,
  SET_RS_ERROR,
  GET_REWARD_CATALOG_NO_SERVICE,
  GET_REWARD_PRODUCT_NO_SERVICE
} from 'constants/actions';

import getAddress from 'api/getAddress';

import {
  getCatalog,
  getProduct,
  searchCatalog,
  getQuickSearch,
  getEncryptionToken
} from 'api/rewards';
import { SEGMENT_SESSION_STORAGE_TOKEN_NAME, SESSION_STORAGE_SOURCE, SESSION_STORAGE_AGENT_TOKEN, SESSION_STORAGE_AGENT_TYPE, ERR_NO_SERVICE_UNDER_CAC } from 'constants/rewardsStore';

const {
  REACT_APP_REWARDS_STORE_URL_UNAUTH,
  REACT_APP_DAVINCI_CHECKOUT_URL,
  REACT_APP_REWARDS_STORE_URL_UNAUTH_ARP
} = process.env;

export const fetchRewardCatalog = (tagAnd, tagOr, pageOffset) => {
  return (dispatch, getState) => {
    const {
      appStatus: { authenticated },
      accounts,
      rewards: { profile, catalogView: { sort } }
    } = getState();
    if (!authenticated) {
      sessionStorage.removeItem(SEGMENT_SESSION_STORAGE_TOKEN_NAME);
    }
    dispatch({ type: GET_REWARD_CATALOG_PENDING });
    return getCatalog(tagAnd, tagOr, pageOffset, sort, authenticated, accounts.pointsValue, accounts.accountId, accounts.loyaltyTier, sessionStorage.getItem(SEGMENT_SESSION_STORAGE_TOKEN_NAME), profile)
      .then(res => {
        if (authenticated) {
          const segment = res?.data?.profile?.loyaltyAccount?.segment;
          if (segment) {
            sessionStorage.setItem(SEGMENT_SESSION_STORAGE_TOKEN_NAME, segment);
          }
          dispatch({
            type: SET_REWARD_PROFILE,
            payload: res?.data?.profile
          });
        }
        dispatch({ type: GET_REWARD_CATALOG_SUCCESS, payload: {catalog : res.data?.catalog, appendData : pageOffset > 0} });
      })
      .catch(error => {
        switch (error.response.data.code) {
          case ERR_NO_SERVICE_UNDER_CAC:
            dispatch({
              type: GET_REWARD_CATALOG_NO_SERVICE,
              payload: error.response
            });
            
            break;
          default:
            dispatch({
              type: GET_REWARD_CATALOG_FAILURE,
              payload: error.response
            });

        }
      });
  };
};

export const searchRewardCatalog = (query, tagAnd, tagOr) => {
  return (dispatch, getState) => {
    const {
      appStatus: { authenticated },
      rewards: { catalogView: { sort } }
    } = getState();
    dispatch({ type: SET_REWARD_SEARCH_QUERY, payload: query });
    dispatch({ type: GET_REWARD_CATALOG_PENDING });
    return searchCatalog(query, tagAnd, tagOr, sort, authenticated)
      .then(res => {
        if (authenticated) {
          dispatch({
            type: SET_REWARD_PROFILE,
            payload: res?.data?.profile
          });
        }
        dispatch({ type: GET_REWARD_CATALOG_SUCCESS, payload: res.data });
      })
      .catch(error => {
        dispatch({
          type: GET_REWARD_CATALOG_FAILURE,
          payload: error.response
        });
      });
  };
};

export const fetchRewardProduct = pf => {
  return (dispatch, getState) => {
    const {
      appStatus: { authenticated },
      accounts,
      rewards: { profile }
    } = getState();
    dispatch({ type: GET_REWARD_PRODUCT_PENDING });
    return getProduct(pf, authenticated,accounts?.loyaltyTier, accounts?.accountId, sessionStorage.getItem(SEGMENT_SESSION_STORAGE_TOKEN_NAME), profile)
      .then(res => {
        if (authenticated) {
          dispatch({
            type: SET_REWARD_PROFILE,
            payload: res?.data?.profile
          });
        }
        const segment = res?.data?.profile?.loyaltyAccount?.segment;
        if(segment && segment !== sessionStorage.getItem(SEGMENT_SESSION_STORAGE_TOKEN_NAME) )
        {
          return getProduct(pf, authenticated,accounts?.loyaltyTier, accounts?.accountId, segment, profile)
          .then(response => {
            dispatch({ type: GET_REWARD_PRODUCT_SUCCESS, payload: response.data });
            sessionStorage.setItem(SEGMENT_SESSION_STORAGE_TOKEN_NAME, segment);
           })
        } else {  
            dispatch({ type: GET_REWARD_PRODUCT_SUCCESS, payload: res.data });
        }
      })
      .catch(error => {
        switch (error.response.data.code) {
          case ERR_NO_SERVICE_UNDER_CAC:
            dispatch({
              type: GET_REWARD_PRODUCT_NO_SERVICE,
              payload: error.response
            });
            break;
          default:
            dispatch({
              type: GET_REWARD_PRODUCT_FAILURE,
              payload: error.response
            });
        }
      });
  };
};

export const fetchQuickSearch = query => {
  return (dispatch, getState) => {
    const {
      appStatus: { authenticated }
    } = getState();
    dispatch({ type: GET_REWARD_QUICKSEARCH_PENDING });
    return getQuickSearch(query, authenticated)
      .then(res => {
        const {
          data: { 
              searchResult: searchResults 
            }
        } = res;
        dispatch({
          type: GET_REWARD_QUICKSEARCH_SUCCESS,
          payload: searchResults
        });
      })
      .catch(error => {
        dispatch({
          type: GET_REWARD_QUICKSEARCH_FAILURE,
          payload: error.response
        });
      });
  };
};

export const SetProductFilter = filters => {
  return dispatch => {
    dispatch({ type: SET_REWARD_PRODUCT_FILTER, payload: filters });
  };
};

export const setCategoriesFilter = categories => {
  return dispatch => {
    dispatch({ type: SET_REWARD_CATEGORIES, payload: categories })
  }
}

export const setOfferType = offerTypeFilter => {
  return dispatch => {
    dispatch({ type: SET_REWARD_OFFER_TYPES, payload: offerTypeFilter })
  }
}

export const resetOfferType = () =>{
  return dispatch => {
    dispatch({ type: RESET_REWARD_OFFER_TYPES });
  };
}

export const resetProductFilter = () => {
  return dispatch => {
    dispatch({ type: RESET_REWARD_PRODUCT_FILTER });
  };
};

export const resetCategoryFilter = () => {
  return dispatch => {
    dispatch({ type: RESET_REWARD_CATEGORIES_FILTER });
  };
};

export const setStockFilter= (hasStock) =>{
  return dispatch =>{
    dispatch({type : SET_REWARD_STOCK_FILTER, payload:hasStock})
  }
}

export const setProductSort = criteria => {
  return dispatch => {
    dispatch({ type: SET_REWARD_PRODUCT_SORT, payload: criteria });
  };
};

export const clearSearchQuery = () => {
  return dispatch => {
    dispatch({ type: SET_REWARD_SEARCH_QUERY, payload: '' });
  };
};

export const clearQuickSearch = () => {
  return dispatch => {
    dispatch({ type: CLEAR_REWARD_QUICKSEARCH, payload: '' });
  };
};

export const resetRewardProduct = () => {
  return dispatch => {
    dispatch({ type: RESET_REWARD_PRODUCT });
  };
};

export const resetRewardProductFiltersView = defaults => {
  return dispatch => {
    dispatch({ type: RESET_REWARD_FILTER_VIEW, payload: defaults});
  };
};

export const checkoutAgora = (selectedProductFamily, selectedProduct, selectedPrice) => {
  return (dispatch, getState) => {
    const {
      eligibility: {
        getDetails: {
          data: { addressId, partySiteId, contactUUID, rcrmContactId, firstName, lastName }
        }
      },
      accounts,
      rewards: { profile: rewardsProfile, productView: rewardsProductView }
    } = getState();

      let fetchedAddress = null;
      let phoneNumber = "";

      if(accounts.mobile?.includes('+61')) {
        phoneNumber = '0' + accounts.mobile.replace('+61', '');
      }

      if (addressId || partySiteId) {
          dispatch({ type: CHECKOUT_PENDING });
          const addressDetails = getAddress({ addressId, partySiteId });
          addressDetails
              .then(res => {
                  try {
                      const {address} = res?.data?.data;
                      fetchedAddress = address;
                      const rewardsLoyaltyAccDetails = rewardsProfile.loyaltyAccount;
                      const isPostageApplicable = rewardsProductView.product.productFamilies[0].products[0].postages.length > 0 ? true : false;
                      const isNormalDelivery = rewardsProductView.product.productFamilies[0].normalDelivery;
                      const doRedemptionCheck = rewardsProductView.product.productFamilies[0].doRedemptionCheck;
                      const subscriptionServiceAvailable = rewardsLoyaltyAccDetails?.subscriptionServiceAvailable;
                      const loyaltyCon = subscriptionServiceAvailable ? "loyalty_con_dv" : "loyalty_con"
                      const customerSegment = accounts.loyaltyTier === "BUSINESS" ? "loyalty_smb" : loyaltyCon;
                      let promotionApplicable = false;
                      for (let i = 0; i < rewardsLoyaltyAccDetails?.promotions?.length; i += 1) {
                          if (rewardsLoyaltyAccDetails.promotions[i].skuCode === selectedProduct.sku) {
                              promotionApplicable = true;
                          }
                      }
                      let associatedServiceAddress = "";
                      let associatedServiceId = "";
                      let reasonCode = "";
                      for (let redemption of rewardsLoyaltyAccDetails?.redemptions) {
                          const redemptionServiceAddress = redemption.serviceAddress?.unstructured;
                          const associatedIds = redemption.products?.length > 0? redemption.products[0].associatedServiceId : null;
                          const reasonCodes = redemption.reasonCode;
                          if(!associatedServiceId.includes(associatedIds)) {
                              associatedServiceId = associatedServiceId + associatedIds + " ~ ";
                              reasonCode = reasonCode + reasonCodes + " ~ ";
                              associatedServiceAddress = associatedServiceAddress + redemptionServiceAddress + " ~ ";
                          }
                      }
                      const payload = {
                          encryptionType: "symmetric",
                          customerSegment,
                          selectedAccountId: accounts.accountId,
                          selectedAccountTier: accounts.loyaltyTier,
                          customerType: accounts.type,
                          customerFirstName: firstName,
                          customerLastName: lastName,
                          customerAccountName: accounts.accountName.replace(/^(Mr|Mrs|Miss|Ms|MR|MRS|MISS|MS|mr|mrs|miss|ms)\b/, "").trim(),
                          availablePoints: accounts.pointsValue,
                          onHoldPoints: accounts.onHoldPoints,
                          establishedAccount: rewardsLoyaltyAccDetails?.isEstablished,
                          contactUUID,
                          rcrmContactId,
                          contactType: accounts.role,
                          primary: rewardsLoyaltyAccDetails?.primary,
                          servicesAvailable: rewardsLoyaltyAccDetails?.servicesAvailable,
                          customerContactNumber: phoneNumber,
                          customerEmailAddress: accounts.email,
                          creditCheckDeclined: rewardsLoyaltyAccDetails?.creditCheckDeclined,
                          creditCheckResult: rewardsLoyaltyAccDetails?.creditCheckResult,
                          activeBans: rewardsLoyaltyAccDetails?.activeBans.join(','),
                          redemption: rewardsLoyaltyAccDetails?.redemption,
                          homeAddressId: fetchedAddress.addressId,
                          homeAddressLine1: fetchedAddress.addressLine1,
                          homeAddressLine2: fetchedAddress.addressLine2,
                          homeAddressLine3: fetchedAddress.addressLine3,
                          homeAddressSuburb: fetchedAddress.locality,
                          homeAddressState: fetchedAddress.state,
                          homeAddressPostcode: fetchedAddress.postcode,
                          homeAddressCountry: fetchedAddress.country,
                          status: accounts.enrollmentStatus,
                          isPostageChargesApplicable: isPostageApplicable,
                          isPostageWaivedForProduct: rewardsLoyaltyAccDetails?.isPostageApplicable,
                          postagePointsValue: "2500",
                          postageDollarValue: "5.95",
                          isBtlPromo: false,
                          isNormalDelivery,
                          promotionApplicable,
                          associatedServiceAddress,
                          associatedServiceId,
                          reasonCode,
                          doRedemptionCheck,
                          //associatedBAN: rewardsLoyaltyAccDetails?.redemptions[0].products[0]?.associatedBAN,
                          selectedProductSku: selectedProduct.sku,
                          selectedProductName: selectedProduct.name,
                          selectedProductRRP: selectedProduct.rrp,
                          selectedProductVoucherSource: selectedProduct.attributes?.find(el => el.name === "voucherSource")?.value,
                          selectedProductProductImage: selectedProduct.attributes?.find(el => el.name === "productImage")?.value,
                          selectedProductProductDetailHeader: selectedProduct.attributes?.find(el => el.name === "productDetailHeader")?.value,
                          selectedProductProductDetailBody: selectedProduct.attributes?.find(el => el.name === "productDetailBody")?.value,
                          selectedProductCtaTitle: selectedProduct.attributes?.find(el => el.name === "ctaTitle")?.value,
                          selectedProductCtaUrl: selectedProduct.attributes?.find(el => el.name === "ctaURL")?.value,
                          selectedProductType: selectedProductFamily.type,
                          selectedProductCategoryName: selectedProduct.categoryName,
                          selectedProductBrandName: selectedProduct.brandName,
                          SelectedProductColor: selectedProduct.attributes?.find(el => el.name === "Colour"||el.name === "colour" )?.value,
                          SelectedProductStorage: selectedProduct.attributes?.find(el => el.name === "Memory"||el.name === "memory" )?.value,
                          selectedPriceId: selectedPrice.id,
                          selectedPriceSchedule: selectedPrice.priceSchedule,
                          selectedUpfrontPrice: selectedPrice.upfrontPrice,
                          selectedUpfrontUnits: selectedPrice.upfrontUnits,
                          selectedOngoingPrice: selectedPrice.ongoingPrice,
                          selectedOngoingUnits: selectedPrice.ongoingUnits,
                          selectedOngoingDuration: selectedPrice.ongoingDuration ? selectedPrice.ongoingDuration : 0,
                          selectedPricePreferred: selectedPrice.preferred,
                          redirectURL: window.location.href
                      };
                      console.log("payload: ", payload);
                      return getEncryptionToken(payload).then(res => {
                          console.log("encryption api response: ", res.data.token);
                          window.location.href = REACT_APP_REWARDS_STORE_URL_UNAUTH + "/auth?token=" + res.data.token + "&redirectPath=consumer/rewards";
                      }).catch(error => {
                          console.log("encryption api error occured");
                      });
                  } catch (error){
                      console.log(error);
                      dispatch({
                          type: GET_REWARD_PRODUCT_FAILURE,
                          payload: error.response
                      });
                  }
              })
              .catch(err => {
                  console.log(err);
                  dispatch({ type: CHECKOUT_FAILURE, payload: err });
              });
      }
  };
};

export const checkoutDavinci = (selectedProduct, selectedPrice, selectedOriginalPrice) => {
  return (dispatch, getState) => {
    const {
      accounts: { accountId: cac, pointsValue: availablePoints }
    } = getState();

    let monthlyRo;
    if(selectedPrice.ongoingDuration != 0) {
      monthlyRo = parseFloat(selectedPrice.ongoingPrice/parseInt(selectedPrice.ongoingDuration)).toFixed(2);
    }
    const payload = {
      encryptionType: "symmetric",
      productSku: selectedProduct.sku,
      productName: selectedProduct.name,
      productCategory: selectedProduct.categoryName,
      rrp: selectedProduct.rrp,
      cac,
      availablePoints,
      pointsValue: selectedPrice.upfrontPrice,
      dollarValue: selectedPrice.ongoingPrice,
      term: selectedPrice.ongoingDuration,
      monthlyRo
    };
    if (selectedOriginalPrice?.ongoingPrice && selectedOriginalPrice?.upfrontPrice) {
      payload["origDollarValue"] = selectedOriginalPrice?.ongoingPrice;
      payload["origPointsValue"] = selectedOriginalPrice?.upfrontPrice;
    }
    console.log("payload::",payload);
    return getEncryptionToken(payload).then(response => {
      console.log("encryption api response: ", response.data.token);
       window.location.href = REACT_APP_DAVINCI_CHECKOUT_URL + "?token=" + response.data.token;
    }).catch(error => {
      console.log("encryption api error occured");
    });
  };
};

const getRedirectURLwithParams = () =>{
  const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const agentTypeParam = sessionStorage.getItem(SESSION_STORAGE_AGENT_TYPE) ? `&agentType=${sessionStorage.getItem(SESSION_STORAGE_AGENT_TYPE)}` : '';
  const params = `source=${sessionStorage.getItem(SESSION_STORAGE_SOURCE)}&token=${sessionStorage.getItem(SESSION_STORAGE_AGENT_TOKEN)}${agentTypeParam}`
  return `${url}?${params}`;
}

export const agentRedeem = (selectedProductFamily, selectedProduct, selectedPrice) => {
  return (dispatch, getState) => {
    const { rewards: { productView: rewardsProductView } } = getState();
    const isNormalDelivery = rewardsProductView.product.productFamilies[0].normalDelivery;
    const payload = {
      encryptionType: "symmetric",
      postagePointsValue: "2500",
      postageDollarValue: "5.95",
      isBtlPromo: false,
      isNormalDelivery,
      promotionApplicable: false,
      selectedProductSku: selectedProduct.sku,
      selectedProductName: selectedProduct.name,
      selectedProductRRP: selectedProduct.rrp,
      selectedProductType: selectedProductFamily.type,
      selectedPriceId: selectedPrice.id,
      selectedPriceSchedule: selectedPrice.priceSchedule,
      selectedUpfrontPrice: selectedPrice.upfrontPrice,
      selectedUpfrontUnits: selectedPrice.upfrontUnits,
      selectedOngoingPrice: selectedPrice.ongoingPrice,
      selectedOngoingUnits: selectedPrice.ongoingUnits,
      selectedOngoingDuration: selectedPrice.ongoingDuration ? selectedPrice.ongoingDuration : 0,
      selectedPricePreferred: selectedPrice.preferred,
      redirectURL: getRedirectURLwithParams()
    };
    return getEncryptionToken(payload).then(res => {
      const agentTypeParam = sessionStorage.getItem(SESSION_STORAGE_AGENT_TYPE) ? `&agentType=${sessionStorage.getItem(SESSION_STORAGE_AGENT_TYPE)}` : '';
      window.location.href = `${REACT_APP_REWARDS_STORE_URL_UNAUTH_ARP}?agentToken=${sessionStorage.getItem(SESSION_STORAGE_AGENT_TOKEN)}&cartToken=${res.data.token}${agentTypeParam}&redirectPath=consumer/rewards`;
    }).catch(error => {
      console.log("encryption api error occured");
    });
  };
};

//Create an action to trigger error
export const setRSError = (
  errorKey
) => {
  return dispatch => {
    dispatch({
      type: SET_RS_ERROR, payload: {
        hasRSError: true,
        errorKey
      }
    });
  };
};
