import axios from './util/axiosHandle';
import { partnersConfig as api } from '../oidc/oidc-configs';

const getNowShowing = () => {
  return axios({
    params: {
      method: 'get',
      url: api.eventcinemas.baseUrl + api.eventcinemas.endpoints.movies
    },
    type: 'public'
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
};

export default getNowShowing;
