import React from 'react';
import { NavLink, Link } from 'react-router-dom';

/**
 * InteractiveElement is a component partial which can render anchors, buttons, React Links & NavLink
 *
 * @link https://confluence.tools.telstra.com/display/DCSYS/Action+List
 * @version 1.0.0
 */
const InteractiveElement = props => {
  const {
    children,
    className,
    element,
    elementRef,
    events,
    externalSite,
    href,
    type,
    to,
    ...other
  } = props;

  const getRel = () => (externalSite ? 'noopener noreferrer' : undefined);

  const elementProps = {
    className,
    ref: elementRef,
    ...events,
    ...other
  };

  const targetURL = href || to || '/';

  if (element === 'a') {
    return (
      <a href={targetURL} rel={getRel()} {...elementProps}>
        {children}
      </a>
    );
  }
  if (element === 'Link') {
    return (
      <Link to={targetURL} rel={getRel()} {...elementProps}>
        {children}
      </Link>
    );
  }
  if (element === 'NavLink') {
    return (
      <NavLink to={targetURL} rel={getRel()} {...elementProps}>
        {children}
      </NavLink>
    );
  }
  return (
    // https://github.com/yannickcr/eslint-plugin-react/issues/1555
    // eslint-disable-next-line react/button-has-type
    <button type={type} {...elementProps}>
      {children}
    </button>
  );
};

export default InteractiveElement;
