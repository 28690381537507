/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFocus } from 'utils/hooks/useFocus';
import { useNavigate } from 'react-router-dom';
import { Column, Select, Grid, ActionButton, Spacing, TextStyle, Surface } from '@able/react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { HtmlConverter } from 'utils/HtmlConverter';
import { Base64 } from 'js-base64';
import { PARTNERS } from 'constants/externalPartner';
import {
  getCinemaState,
  getMovies,
  getCinemas,
  buyMovieTicket,
  handleTicketErrors
} from 'api/getTickets';
import {
  TICKET_LOCAL_STORAGE,
  TICKET_MOVIE,
  TICKET_VILLAGE_GROUP,
  STATES
} from 'constants/ticket';

import { signIn } from 'oidc/initiateOidc';

import './MovieTicketSelector.scss';

const MovieTicketSelector = props => {

  const { header, copy, preload = false } = props;

  const app = useSelector(state => state.appStatus);
  const { authenticated } = app;
  const navigate = useNavigate();

  const notPlayingMsg = 'Your selection is not available. Please select your state, then cinema, for full availability.';
  const stateMsg = 'Please select a state first';
  const [stateRef, setStateRef] = useFocus();
  const [cinemaRef, setCinemaRef] = useFocus();
  const [movieRef, setMovieRef] = useFocus();

  const [stateLoaded, setStatesLoaded] = useState(false);
  const [cinemasLoaded, setCinemasLoaded] = useState(false);
  const [moviesLoaded, setMoviesLoaded] = useState(false);

  const [movieDropdown, setMovieDropdown] = useState([]);
  const [stateDropdown, setStateDropdown] = useState([]);
  const [cinemaDropdown, setCinemaDropdown] = useState([]);

  const [stateCinemaChanged, setStateCinemaChanged] = useState(false);
  const [cinemaChanged, setCinemaChanged] = useState(false);
  const [movieChanged, setMovieChanged] = useState(false);

  const [notPlaying, setNotPlaying] = useState(false);
  const [stateRequired, setStateRequired] = useState(false);
  const [isError, setError] = useState(false);


  const [state, setState] = useState(null);
  const [cinemaId, setCinemaId] = useState(null);
  const [movieId, setMovieId] = useState(null);

  const dispatch = useDispatch();

  const checkError = () => {
    if (isError) setError(false);
  };

  const getMoviesDropdown = () => {
    if(moviesLoaded){
      return;
    }
    setMoviesLoaded(true);
    getMovies({ state, cinemaId })
      .then(res => {
        const {
          data: {
            data: { moviesList }
          } = { data: {} }
        } = res;
        const moviesArr = moviesList.map(x => ({ value: x.movieId, label: x.name }));
        setMovieDropdown(moviesArr);
        setCinemaChanged(false);
        checkError();
      })
      .catch(error => {
        setMovieDropdown([]);
        setError(true);
        dispatch(handleTicketErrors(error, 'movie'));
        setMoviesLoaded(false);
      });
  };

  const getCinemaDropdown = () => {
    state !== null &&
      getCinemas({ state, movieId })
        .then(cinemaRes => {
          const {
            data: {
              data: { cinemasList } = { data: {} }
            } = { data: {} } } = cinemaRes;
          setCinemasLoaded(true);
          setStateCinemaChanged(false);
          setMovieChanged(false);
          checkError();
          if (cinemasList && cinemasList.length > 0) {
            const cinemaArr = cinemasList.map(x => ({ value: x.id.value, label: x.name, brand: x.id.cinemaGroup }));
            setCinemaDropdown(cinemaArr);
            setNotPlaying(false);
          } else {
            setCinemaDropdown([]);
          }
        })
        .catch(error => {
          setCinemaDropdown([]);
          setError(true);
          dispatch(handleTicketErrors(error, 'movie'));
        });
  };

  const getStateDropdown = () => {
    if(stateLoaded){
      return;
    }
    setStatesLoaded(true);
    getCinemaState()
      .then(cinemaStateRes => {
        const {
          data: {
            data: { cinemaStateList } = { data: {} }
          } = { data: {} }
        } = cinemaStateRes;
        if (cinemaStateList) {
          setStatesLoaded(true);
          const stateArr = cinemaStateList.map(x => ({
            value: x, label: STATES.find(obj => {
              return obj.value === x;
            }).label
          }));
          setStateDropdown(stateArr);
          checkError();
        }
      })
      .catch(error => {
        setStateDropdown([]);
        setError(true);
        setStatesLoaded(false);
        dispatch(handleTicketErrors(error, 'movie'));
      });
  };

  useEffect(() => {
    if (!isError) {
      if (preload) {
        if (!moviesLoaded || (cinemaChanged && movieId === null)) getMoviesDropdown();
        if (!stateLoaded) { getStateDropdown(); }
      }
      if (stateCinemaChanged) { 
        getCinemaDropdown();
      }
    }
    if (state != null && movieId != null && cinemaDropdown.length === 0) setNotPlaying(true);
  }, [stateDropdown, cinemaDropdown, movieDropdown, stateCinemaChanged, 
      cinemaChanged, notPlaying, isError, stateRequired]);

  useEffect(() => {
    if (movieId && state === null) {
      setStateRequired(true);
      setStateRef();
    }
  }, [movieId, state]);

  const handleFormSubmit = formValue => {
    const filteredCinema = cinemaDropdown.find(obj => {
      return obj.value === formValue.cinema;
    });
    const movieChildPageUrl = `/tickets/event/Movie/${formValue.movie}?state=${state}&cinemaId=${formValue.cinema}&cinemaName=${filteredCinema.label}`;
    if (filteredCinema.brand !== TICKET_VILLAGE_GROUP) {
      const partnerId = PARTNERS?.events?.apiProps?.partnerId;
      if (authenticated) {
        // event cinema partner site redirection
        dispatch(
          buyMovieTicket({
            partnerId,
            movieId: formValue.movie,
            cinemaId: formValue.cinema,
            state
          })
        );
      } else {
        // save movieSessionUrl to local storage
        // kick off signin redirect from caiman
        localStorage.setItem(
          'tplus-flow',
          Base64.encode(
            JSON.stringify({
              flow: TICKET_LOCAL_STORAGE,
              type: TICKET_MOVIE,
              action: 'buyMovieTicket',
              data: [
                {
                  partnerId,
                  movieId: formValue.movie,
                  cinemaId: formValue.cinema,
                  cinemaState: state
                }
              ],
              links: [
                {
                  link: 'Continue to Movie Session',
                  url: movieChildPageUrl
                }
              ]
            })
          )
        );
        signIn();
      }
    } else {
      // go to movie session child page for village
      navigate(movieChildPageUrl);
    }
  };

  const formik = useFormik({
    initialValues: {
      state: '',
      movie: '',
      cinema: ''
    },
    validationSchema: yup.object().shape({
      state: yup.string().required('Please select a state'),
      movie: yup.string().required('Please select a movie'),
      cinema: yup.string().required('Please select a cinema')
    }),
    onSubmit: (values, actions) => handleFormSubmit(values)
  });

  useEffect(() => {
    const { isValidating, errors, isSubmitting } = formik;
    if (isSubmitting && !isValidating) {
      if (errors.state) {
        setStateRef();
      } else if (errors.cinema) {
        setCinemaRef();
      } else if (errors.movie) {
        setMovieRef();
      }
    }
  }, [formik]);

  return (
    <>
      <div className='tplus-tickets-movie-selector'>
        <Surface className='tplus-tickets-movie-selector_surface'>
          <Spacing left='spacing3x' right='spacing3x' leftVMD='spacing5x' rightVMD='spacing5x' topVMD='spacing4x' bottomVMD='spacing5x' top='spacing3x' bottom='spacing4x'>
            <Spacing bottom='spacing1x'>
              <TextStyle element='h3' alias='HeadingB'>{header}</TextStyle>
            </Spacing>
            <Spacing bottom='spacing3x'>
              <TextStyle alias='Base' color='Paragraph'>{HtmlConverter(copy)}</TextStyle>
            </Spacing>
            <form onSubmit={formik.handleSubmit}>
              <Grid>
                <Column bmd={3} bsm={4}>
                  <Spacing bottomVSM='spacing2x' bottomVXS='spacing2x'>
                    <Select
                      developmentUrl="/able-sprites.svg"
                      events={{
                        onFocus: function onFocus() { !stateLoaded && getStateDropdown(); },
                        onChange: function onChange(e) {
                          setCinemaId(null);
                          setStateRequired(false);
                          formik.values.cinema = '';
                          setState(e.target.value === '' ? null : e.target.value);
                          e.target.value === '' && setCinemaDropdown([]);
                          setStateCinemaChanged(true);
                        }
                      }}
                      id="state"
                      label="Select your state"
                      options={stateDropdown}
                      ref={stateRef}
                      invalid={formik.errors.state && true}
                      invalidMessage={stateRequired ? stateMsg : formik.errors.state}
                      required
                      {...formik.getFieldProps('state')}
                    />
                  </Spacing>
                </Column>
                <Column bmd={3} bsm={8}>
                  <Spacing bottomVXS='spacing2x'>
                    <Select
                      developmentUrl="/able-sprites.svg"
                      events={{
                        onFocus: function onFocus() {
                          (!cinemasLoaded || stateCinemaChanged || movieChanged) && getCinemaDropdown();
                        },
                        onChange: function onChange(e) {
                          setCinemaChanged(true);
                          setCinemaId(e.target.value === '' ? null : e.target.value);
                        }
                      }}
                      id="cinema"
                      label="Select cinema"
                      options={cinemaDropdown}
                      ref={cinemaRef}
                      invalid={
                        formik.touched.cinema && formik.errors.cinema && true
                      }
                      invalidMessage={notPlaying ? notPlayingMsg : formik.errors.cinema}
                      required
                      {...formik.getFieldProps('cinema')}
                    />
                  </Spacing>
                </Column>
                <Column bmd={3}>
                  <Select
                    developmentUrl="/able-sprites.svg"
                    events={{
                      onFocus: function onFocus() {
                        (!moviesLoaded || cinemaChanged) && getMoviesDropdown();
                      },
                      onChange: function onChange(e) {
                        setMovieChanged(true);
                        setMovieId(e.target.value === '' ? null : e.target.value);
                      }
                    }}
                    id="movie"
                    label="Select movie"
                    ref={movieRef}
                    options={movieDropdown}
                    invalid={
                      formik.touched.movie && formik.errors.movie && true
                    }
                    invalidMessage={formik.errors.movie}
                    required
                    {...formik.getFieldProps('movie')}
                  />
                  <Spacing bottom="spacing4x" />
                </Column>
                <Column bmd={3} className="tplus-tickets-movie-selector_button">
                  <ActionButton
                    type="submit"
                    variant="HighEmphasis"
                    label="Find session"
                    element="button"
                  />
                </Column>
              </Grid>
            </form>
          </Spacing>
        </Surface>
      </div>
    </>
  );
};

export default MovieTicketSelector;
