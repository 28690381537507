import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { HtmlConverter } from 'utils/HtmlConverter';
import { addLinkClickEvent } from 'utils/analytics';

// merges objects
export function defaults(obj, defaultObject) {
  const assignedSettings = _.merge({}, defaultObject, obj);
  return assignedSettings;
}

// insertion of dom element AFTER
export function insertAfter(refNode, nodeToInsert) {
  const sibling = refNode.nextSibling;
  if (sibling) {
    const parent = refNode.parentNode;
    parent.insertBefore(nodeToInsert, sibling);
  } else {
    const parent = refNode.parentNode;
    parent.appendChild(nodeToInsert);
  }
}

// insertion of dom element BEFORE
export function insertBefore(refNode, nodeToInsert) {
  const parent = refNode.parentNode;
  parent.insertBefore(nodeToInsert, refNode);
}

export function forEach(items, fn) {
  if (!items) return;
  if (items.forEach) {
    items.forEach(fn);
  } else {
    for (let i = 0; i < items.length; i += 1) {
      fn(items[i], i, items);
    }
  }
}

export function debounce(ms, fn) {
  let timeout;
  const debouncedFn = () => {
    clearTimeout(timeout);
    timeout = setTimeout(fn, ms);
  };
  return debouncedFn;
}

// Helper to return a A tag or Link component based on incoming link having 'http' or not
// eslint-disable-next-line react/prop-types
export function getLink(
  {
    link = '',
    linkText = '',
    linkClass = '',
    linkArrow = false,
    linkTracking = ''
  },
  arg2
) {
  // eslint-disable-next-line react/prop-types
  const newUrl = link.trim().replace(/\s/g, '');

  // If non mandatory attributes sent in, catch and create a string from there here
  let restOfAttributes = '';
  // eslint-disable-next-line func-names
  _.forEach(arg2, function(value, key) {
    // console.log(key, value);
    restOfAttributes = `${restOfAttributes} ${key}="${value}"`;
  });

  // Test if url starts with http, then return straight a tag or Link component
  if (/^(:\/\/)/.test(newUrl) || /^(f|ht)tps?:\/\//i.test(newUrl)) {
    const linkTrackingAttr = linkTracking
      ? `tracking=${linkTracking.replace(' ', '&nbsp;')}`
      : '';
    return HtmlConverter(
      `<a href=${link} 
      class="${linkClass}" ${linkTrackingAttr} ${restOfAttributes} target="_blank" rel="noopener noreferrer">
        ${linkText} ${
        linkArrow
          ? '<svg version="1.1" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5 18.1L14.4 17l5-5-5-5 1.1-1.1 6.1 6.1-6.1 6.1z"/><path class="arr02" d="M7.1 11.2h13.5v1.6H7.1z" /></svg>'
          : ''
      }
      </a>`
    );
  }
  return (
    <Link
      className={linkClass}
      to={link}
      onClick={() => addLinkClickEvent(linkTracking)}
      {...arg2}
    >
      {linkText}{' '}
      {linkArrow ? (
        <svg
          version="1.1"
          aria-hidden="true"
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M15.5 18.1L14.4 17l5-5-5-5 1.1-1.1 6.1 6.1-6.1 6.1z" />
          <path className="arr02" d="M7.1 11.2h13.5v1.6H7.1z" />
        </svg>
      ) : (
        ''
      )}
    </Link>
  );
}

// Helper to return url params as an object
export function getParams(searchString) {
  return searchString
    .slice(1)
    .split('&')
    .reduce((acc, s) => {
      const [k, v] = s.split('=');
      return Object.assign(acc, { [k]: v });
    }, {});
}

export function ctaClass(style) {
  switch (style) {
    case 'filled':
      return 'button-high-emphasis';
    case 'outlined':
      return 'button-medium-emphasis';
    default:
      return 'primary-cta-18';
  }
}
