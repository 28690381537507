import _ from 'lodash';

import { getAemApi, getMockAemApi } from 'api/getAem';
import { GET_AEM_ALL, SET_AEM_PENDING, SET_AEM_READY } from 'constants/actions';
import { IN_DEVELOP_MOCKS } from 'constants/general';

/**
 * Takes a node and procsses it recursively,
 * keeps trying to find 'childNode' key
 * creates a page key from path
 * creates an object with page as key
 * removes page and any childNodes key left in object
 *
 * @param {object} aemPage
 */
const processChildNodes = aemPage => {
  // If childNodes has children then keep going down and processing them
  if (aemPage.childNodes.length > 0) {
    // We have child pages, find the end of the path / for th 'page'
    const n = aemPage.path.lastIndexOf('/');
    // Take that page name
    const pageName = aemPage.path.substring(n + 1);
    // Process any childNodes recursively
    const children = _.chain(aemPage.childNodes)
      .map(aemChildNodesPage => {
        return processChildNodes(aemChildNodesPage);
      })
      .keyBy('page')
      .mapValues(v => _.omit(v, ['page', 'childNodes']))
      .value();
    // Take any parent properties
    const parent = _.omit(aemPage, 'childNodes');
    // Create a new object with page name and children
    return { page: pageName, ...children, ...parent };
  }
  // Otherwise just a flat container page, process it
  const n = aemPage.path.lastIndexOf('/');
  const pageName = aemPage.path.substring(n + 1);
  // Remove the path key because I dont like it

  return { page: pageName, ...aemPage };
};

/**
 * Prepare AEM data for store dispatch.
 *
 * @param {object} response API or mock server response
 * @param {function} dispatch Redux dispatch
 */
const handleResponse = (response, dispatch) => {
  // grab everything with a path attribute, this corresponds to pages in heirarchy
  const aemPages = _.filter(response.data.childNodes, 'path');

  // Create new array with somewhat normalized data
  const normalizedAem = aemPages.map(aemPage => {
    return processChildNodes(aemPage);
  });

  // Create a new object from previous array with objects keyed from page name
  const keyedAem = _.chain(normalizedAem)
    .keyBy('page')
    .mapValues(v => _.omit(v, 'page'))
    .value();

  dispatch({
    type: SET_AEM_PENDING,
    payload: false
  });

  dispatch({
    type: GET_AEM_ALL,
    payload: keyedAem
  });

  dispatch({
    type: SET_AEM_READY,
    payload: true
  });
};

const getAemAll = () => {
  return dispatch => {
    dispatch({
      type: SET_AEM_PENDING,
      payload: true
    });

    return getAemApi('all').then(
      response => {
        if (response) {
          handleResponse(response, dispatch);
        } else {
          dispatch({
            type: GET_AEM_ALL,
            payload: {}
          });
        }
      },
      error => {
        dispatch({
          type: SET_AEM_PENDING,
          payload: false
        });
        // if unable to get aem promos in development mock (i.e. mock-servers)
        // i.e. if you have not set up a local aem server
        // use dummy data from mock-server
        if (IN_DEVELOP_MOCKS) {
          // eslint-disable-next-line no-console
          console.info(
            'Failed to get real mock AEM data so falling back to json data from mock-aem.json = ',
            error
          );
          getMockAemApi().then(
            response => {
              if (response) {
                handleResponse(response, dispatch);
              } else {
                dispatch({
                  type: GET_AEM_ALL,
                  payload: {}
                });
              }
            },
            mockError => {
              throw mockError;
            }
          );
        }
      }
    );
  };
};

export default {
  getAemAll
};
