import { v4 as uuidv4 } from 'uuid';
import axios from './util/axiosHandle';
import { apiConfig as api } from '../oidc/oidc-configs';

const getDetailsApi = user => {
  const userStub = user ? `/${user}` : '';
  return axios({
    incomingheaders: {
      'ds-correlation-id': uuidv4()
      // 'ds-source-system': 'Telstra Plus SPA'
    },
    params: {
      method: 'get',
      url: api.baseUrl + api.endpoints.getDetails + userStub
    }
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export default getDetailsApi;
