export const HERITAGEFAQS = [
  {
    id: `Can I access Your Telstra Tools after the upgrade?`,
    title: `Can I access Your Telstra Tools after the upgrade?`,
    description: `<p>Yes, you can access Your Telstra Tools using the same email you used when you created your Business ID.</p>`
  },
  {
    id: 'Can I use the same email for my Telstra ID?',
    title: 'Can I use the same email for my Telstra ID?',
    description: `<p>Yes, you will sign in to your services using the same email address you used to set up your Business ID.</p>`
  }
];
