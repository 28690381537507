import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconUpArrow from 'icons/IconUpArrow';

import './Footer.scss';

const ScrollUp = props => {
  const { flag, updateFlag } = props;

  const [updateBtn, setUpdateBtn] = useState(false);

  useEffect(() => {
    setUpdateBtn(flag);
  }, [flag]);

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    updateFlag();
  };

  return (
    <div id="showTopBtn">
      <span>
        <button
          onClick={() => {
            scrollToTop();
          }}
          id="myBtn"
          type="submit"
          style={
            flag === true && updateBtn === true
              ? { position: 'fixed', bottom: '120px', zIndex: '99' }
              : { position: 'absolute' }
          }
        >
          <span className="btnText">Top</span>
          <span className="chevronAtt">
            <IconUpArrow />{' '}
          </span>
        </button>
      </span>
    </div>
  );
};
export default ScrollUp;

ScrollUp.propTypes = {
  flag: PropTypes.bool.isRequired,
  updateFlag: PropTypes.func.isRequired
};
