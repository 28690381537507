import { combineReducers } from 'redux';

import * as Actions from 'constants/actions';

const getMobilePhoneState = {
  toggled: 'off',
  number: '',
  temp: '',
  verified: false,
  verifiedDateTime: null,
  fetching: false,
  error: null,
  errorMsg: '',
  triggered: false
};

const getEmailState = {
  toggled: 'off',
  email: '',
  temp: '',
  verified: false,
  verifiedDateTime: null,
  fetching: false,
  error: null,
  errorMsg: '',
  triggered: false
};

const getAddressState = {
  address: '',
  adborid: '',
  addressConfirmationRequired: false,
  error: '',
  triggered: false
};

const otpState = {
  status: 'INACTIVE',
  flow: '',
  error: '',
  errorMsg: null,
  alertMsg: '',
  fetching: false,
  triggered: false
};

const triggerState = false;

const mandatoryErrorState = false;

function getMobilePhone(state = getMobilePhoneState, action) {
  switch (action.type) {
    case Actions.SET_MOBILE_FETCHING: {
      const { fetching } = action;

      return {
        ...state,
        fetching
      };
    }
    case Actions.GET_MOBILE_NUMBER_VERIFIED: {
      const { number, verified, verifiedDateTime } = action;
      return {
        ...state,
        number,
        verified,
        verifiedDateTime
      };
    }
    case Actions.GET_MOBILE_NUMBER_SUCCESS: {
      const { number } = action;

      return {
        ...state,
        number
      };
    }
    case Actions.GET_MOBILE_TEMP_NUMBER_SUCCESS: {
      const { temp } = action;

      return {
        ...state,
        temp
      };
    }
    case Actions.GET_MOBILE_FAILURE: {
      const {
        errorObject: { status, msg }
      } = action;

      return {
        ...state,
        error: status,
        errorMsg: msg
      };
    }
    case Actions.GET_VERIFIED_RESET: {
      return { ...state, verified: false };
    }
    case Actions.GET_MOBILE_TRIGGERED: {
      const { triggered } = action;

      return {
        ...state,
        triggered
      };
    }
    case Actions.GET_MOBILE_RESET: {
      return getMobilePhoneState;
    }
    default: {
      return state;
    }
  }
}

function getEmail(state = getEmailState, action) {
  switch (action.type) {
    case Actions.SET_EMAIL_FETCHING: {
      const { fetching } = action;

      return {
        ...state,
        fetching
      };
    }
    case Actions.GET_EMAIL_VERIFIED: {
      const { verified, email, verifiedDateTime } = action;
      return {
        ...state,
        email,
        verified,
        verifiedDateTime
      };
    }
    case Actions.GET_EMAIL_SUCCESS: {
      const { email } = action;

      return {
        ...state,
        email
      };
    }
    case Actions.GET_EMAIL_TEMP_SUCCESS: {
      const { temp } = action;

      return {
        ...state,
        temp
      };
    }
    case Actions.GET_EMAIL_FAILURE: {
      const {
        errorObject: { status, msg }
      } = action;

      return {
        ...state,
        error: status,
        errorMsg: msg
      };
    }
    case Actions.GET_VERIFIED_RESET: {
      return {
        ...state,
        verified: false
      };
    }
    case Actions.GET_EMAIL_TRIGGERED: {
      const { triggered } = action;

      return {
        ...state,
        triggered
      };
    }
    case Actions.GET_EMAIL_RESET: {
      return getEmailState;
    }
    default: {
      return state;
    }
  }
}

function getAddress(state = getAddressState, action) {
  switch (action.type) {
    case Actions.SET_ADDRESS: {
      const { address } = action;

      return {
        ...state,
        address
      };
    }
    case Actions.SET_MASKED_ADDRESS: {
      const { maskedAddress } = action;

      return {
        ...state,
        maskedAddress
      };
    }
    case Actions.SET_ADDRESS_ID: {
      const { adborid } = action;

      return {
        ...state,
        adborid
      };
    }
    case Actions.SET_ADDRESS_CONFIRM: {
      const { confirm } = action;

      return {
        ...state,
        addressConfirmationRequired: confirm
      };
    }
    case Actions.SET_ADDRESS_TRIGGERED: {
      const { payload } = action;
      return {
        ...state,
        triggered: payload.triggered
      };
    }
    case Actions.SET_ADDRESS_ERROR: {
      const { payload } = action;

      return {
        ...state,
        error: payload
      };
    }
    default:
      return state;
  }
}

function getOtp(state = otpState, action) {
  switch (action.type) {
    case Actions.SET_OTP_FETCHING: {
      const { fetching } = action;

      return {
        ...state,
        fetching
      };
    }
    case Actions.SET_OTP_FLOW: {
      const { flow } = action;

      return {
        ...state,
        flow
      };
    }
    case Actions.GET_OTP_SUCCESS: {
      const { status } = action;

      return {
        ...state,
        status
      };
    }
    case Actions.GET_OTP_FAILURE: {
      const {
        errorObject: { status, msg },
        flow
      } = action;

      return {
        ...state,
        error: status,
        errorMsg: msg,
        flow
      };
    }
    case Actions.GET_OTP_RESEND: {
      const { msg } = action;

      return {
        ...state,
        alertMsg: msg
      };
    }
    case Actions.GET_OTP_RESET: {
      return otpState;
    }
    default: {
      return state;
    }
  }
}

function getTriggered(state = triggerState, action) {
  switch (action.type) {
    case Actions.SET_VERIFIED_TRIGGERED: {
      const { triggered } = action;

      return triggered;
    }

    default: {
      return state;
    }
  }
}

function setMandatoryError(state = mandatoryErrorState, action) {
  switch (action.type) {
    case Actions.SET_MANDATORY_ERROR: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
}

const reducers = combineReducers({
  triggered: getTriggered,
  mandatoryError: setMandatoryError,
  mobile: getMobilePhone,
  email: getEmail,
  address: getAddress,
  otp: getOtp
});

export default reducers;
