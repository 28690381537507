import React from 'react';

export default function IconMinus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path fill="currentColor" d="M0 10.6h24v2.8H0z" />
    </svg>
  );
}
