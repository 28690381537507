import React, {useState, useEffect} from 'react';
import {
    Icon,
    Spacing
  } from '@able/react';
  import InformationDialog from 'components/InformationDialog';
  import './ProductDeliveryMessage.scss';

const mailMessage = "We'll send your voucher via email";

export const DeliveryDialog = props => {
    const {
        isOpen = true,
        setIsOpen = null
    } = props

    return (
        <InformationDialog
            isShowing={isOpen}
            confirmButtonLabel="Ok"
            setHideDialog={() => setIsOpen(false)}
        >
            <h2>Delivery Fees</h2>
            <Spacing left="spacing2x">
                <ul>
                    <li><p>Delivery costs $5.95 or 2,500 Telstra Plus points.</p></li>
                    <li><p>Delivery is free for Gold members.</p></li>
                </ul>
            </Spacing>
            <br/>
            <br/>
            <h2>Get free delivery on the rest of today's shop</h2>
            <p>More on your shopping list? Redeem a reward or purchase an accessory today and get free delivery on all your other reward or accessory orders until midnight.</p>
            <br/>
            <p>You must be signed in with your Telstra ID at time of order and only available on orders made by the same Telstra Plus membership.</p>
        </InformationDialog>
    )
}

export const getProductDeliveryNotification = (showDeliveryInformation, postageMessage, normalDelivery, setDialogOpen, showMailMessage) => {
    return {
        label: !normalDelivery || showMailMessage ? mailMessage : postageMessage,
        description: !showDeliveryInformation || showMailMessage ? null : "Click to see delivery prices",
        type: !normalDelivery || showMailMessage ? "mail" : "delivery",
        events: !showDeliveryInformation || showMailMessage ? null : { onClick: () => setDialogOpen(true) }
    }
}

const ProductDeliveryMessage = props => {

    const [isDialogueOpen, openDialogue] = useState(false);

    const {
        showDeliveryInformation,
        postageMessage,
        normalDelivery
    } = props;

    const icon = normalDelivery ? "Delivery" : "Mail";

    return (
        <>
        <div className="tplus-delivery-information">
            
            <div className="tplus-delivery-information-icon">
                {/* <Icon icon='Delivery' size="32" /> */}
                <svg
                    class={`icon-${icon}`}
                    role="img"
                    aria-hidden="true"
                    focusable="false"
                    width="24px"
                    height="24px"
                >
                    <use href={`/able-sprites.svg#${icon}`}></use>
                </svg>
            </div>

            <div className="tplus-delivery-information-text">
                <div className="tplus-delivery-information-text-deliveryfee">
                    <span>{normalDelivery ? postageMessage : mailMessage}</span>
                </div>
                {showDeliveryInformation &&
                <Spacing top="spacingHalf" bottom="spacingHalf">
                    <div className="tplus-delivery-information-dext-delivery-popup-text">
                        <a onClick={() => {openDialogue(true); return false}} href="#"><span>See delivery prices</span></a>
                    </div>
                </Spacing>
                
                }
            </div>   
        </div>

        <DeliveryDialog isOpen={isDialogueOpen} setIsOpen={openDialogue}/>
        </>
    );
}



export default ProductDeliveryMessage;