import React from 'react';
import { useSelector } from 'react-redux';
import { getReferenceComponent } from 'selectors/GetChildComponents';

const Include = props => {
  const { path } = props;
  const component = useSelector(state => getReferenceComponent(state, path));
  return <>{component}</>;
};

export default Include;
