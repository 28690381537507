import {
  GET_REWARD_PRODUCT_PENDING,
  GET_REWARD_PRODUCT_SUCCESS,
  GET_REWARD_PRODUCT_FAILURE,
  RESET_REWARD_PRODUCT,
  CHECKOUT_PENDING,
  // CHECKOUT_SUCCESS,
  CHECKOUT_FAILURE,
  GET_REWARD_PRODUCT_NO_SERVICE
} from 'constants/actions';

const defaultState = {
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  product: {},
  error: null,
  resetFiltersView: null,
  checkoutStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  checkoutError: null
};

export const productView = (state = defaultState, action) => {
  switch (action.type) {
    case GET_REWARD_PRODUCT_PENDING: {
      return {
        ...state,
        status: 'loading'
      };
    }
    case GET_REWARD_PRODUCT_SUCCESS: {
      const {
        payload: { catalog }
      } = action;
      return {
        ...state,
        status: 'succeeded',
        product: catalog
      };
    }
    case GET_REWARD_PRODUCT_FAILURE: {
      return {
        ...state,
        status: 'failed',
        error: action.payload
      };
    }
    case RESET_REWARD_PRODUCT: {
      return {
        ...state,
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        product: {},
        error: null
      };
    }

    case CHECKOUT_PENDING: {
      return {
        ...state,
        checkoutStatus: 'loading'
      };
    }
    case CHECKOUT_FAILURE: {
      return {
        ...state,
        checkoutStatus: 'failed',
        checkoutError: action.payload
      };
    }

    case GET_REWARD_PRODUCT_NO_SERVICE: {
      return {
        ...state,
        status: 'forbidden',
        error: action.payload
      };
    }
    default:
      return state;
  }
};
