import React from 'react';

export default function IconSearch() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <rect id="path-1" x="0" y="0" width="1368" height="177" />
        <filter
          x="-0.5%"
          y="-2.8%"
          width="101.0%"
          height="107.9%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0"
            type="matrix"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g
        id="✏️-01-UI-Designs"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Telstra-Plus-\-Member-Switch-\-Desktop"
          transform="translate(-1135.000000, -28.000000)"
        >
          <rect fill="#FFFFFF" x="0" y="0" width="1368" height="1074" />
          <g id="Rectangle">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#filter-2)"
              xlinkHref="#path-1"
            />
            <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1" />
          </g>
          <g id="TM01-Global-Header" fill="#FFFFFF">
            <rect id="Background" x="0" y="0" width="1368" height="80" />
          </g>
          <g id="Group-28" transform="translate(886.000000, 0.000000)">
            <g id="Drop-downs">
              <g id="Search" transform="translate(249.000000, 0.000000)">
                <rect id="Background" x="0" y="0" width="74" height="80" />
                <g
                  id="Button"
                  transform="translate(0.000000, 28.000000)"
                  fill="#414141"
                >
                  <g id="↳-Icon" style={{ mixBlendMode: 'multiply' }}>
                    <g
                      id="Search"
                      transform="translate(11.500000, 11.000000) scale(-1, 1) translate(-11.500000, -11.000000) translate(4.000000, 3.000000)"
                    >
                      <polygon
                        id="Stroke-3"
                        points="5 10 5.70116623 10.7129978 1.35058312 15.4604989 0.649416884 14.7475011"
                      />
                      <path
                        d="M12.4455166,10.0363654 C14.3981611,8.08372084 14.3981611,4.91702792 12.4454235,2.96429022 C10.4939319,1.01176993 7.32625102,1.01176993 5.37466631,2.96438339 C3.42202179,4.91702792 3.42202179,8.08372084 5.37466631,10.0363654 C7.32617916,11.9878782 10.4940038,11.9878782 12.4455166,10.0363654 Z M13.1526234,10.7434721 C10.8105862,13.0855093 7.00959667,13.0855093 4.66755953,10.7434721 C2.32439071,8.40030333 2.32439071,4.60044543 4.66746638,2.25736979 C7.00952481,-0.0859232621 10.8106581,-0.0859232621 13.1526234,2.25727661 C15.4957922,4.60044543 15.4957922,8.40030333 13.1526234,10.7434721 Z"
                        id="Stroke-1"
                      />
                    </g>
                  </g>
                </g>
                <g id="Padding" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
