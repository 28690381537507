import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { BrowserRouter } from 'react-router-dom';
import App from 'containers/App';
import store, { history } from 'store';
import DebugPanel from 'DebugPanel';

import RoutesList from './Routes';

// Global css
// import '@tcom/tcom-core/scss/tcom-core.scss';
import 'styles/App.scss';

const IN_DEVELOP =
  process.env.REACT_APP_ENV === 'dev' ||
  process.env.REACT_APP_ENV === 'dev-mocks';

// Create a history of your choosing (we're using a browser history in this case, imported from configureStore
// const store = configureStore();

const Root = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ConnectedRouter history={history}>
          <App><RoutesList></RoutesList></App>
          <div id="sidebar-portal" />
          {IN_DEVELOP && <DebugPanel><RoutesList></RoutesList></DebugPanel>}
        </ConnectedRouter>
      </BrowserRouter>
    </Provider>
  );
};

export default Root;
