import { GOLD_EXCLUSIVE, SILVER_EXCLUSIVE, MEMBER_EXCLUSIVE, BUSINESS_EXCLUSIVE } from 'constants/pageheader';
/**
 * 
 * @param {array} attributes A reward product's attributes
 * @returns {array} list of the products exclusive tiers found e.g. Possible values ['MEMBER', 'SILVER', 'GOLD',]
 */
export const exclusiveToTiers = attributes => {
  const exclusiveTiers = [];

  if (attributes && attributes instanceof Array) {
    attributes.forEach(a => {
      const { name, value } = a;
      if (name === GOLD_EXCLUSIVE && value === 'true') {
        if (!exclusiveTiers.some(e => e === 'GOLD')) {
          exclusiveTiers.push('GOLD');
        }
      }
      if (name === SILVER_EXCLUSIVE && value === 'true') {
        if (!exclusiveTiers.some(e => e === 'SILVER')) {
          exclusiveTiers.push('SILVER');
        }
      }
      if (name === MEMBER_EXCLUSIVE && value === 'true') {
        if (!exclusiveTiers.some(e => e === 'MEMBER')) {
          exclusiveTiers.push('MEMBER');
        }
      }
      if (name === BUSINESS_EXCLUSIVE && value === 'true') {
        if (!exclusiveTiers.some(e => e === 'BUSINESS')) {
          exclusiveTiers.push('BUSINESS');
        }
      }
    });
  }

  return exclusiveTiers;
};
