import React from 'react';
import { Spacing } from '@able/react';

const Stacked = props => {
  const { opts = {} } = props;
  const { image, body, surface } = opts;

  const imageSurfaceSpacing = body
    ? {
        bottom: 'spacing2x'
      }
    : {};

  const bodySurfaceSpacing =
    surface !== 'SurfaceFlat'
      ? {
          ...(!image && { top: 'spacing2x' }),
          left: 'spacing2x',
          right: 'spacing2x',
          bottom: 'spacing2x'
        }
      : {};

  return (
    <>
      {image && <Spacing {...imageSurfaceSpacing}>{image}</Spacing>}
      {body && <Spacing {...bodySurfaceSpacing}>{body}</Spacing>}
    </>
  );
};

export default Stacked;
