import axios from './util/axiosHandle';
import { rewardsConfig } from '../oidc/oidc-configs';

const {
  baseUrl,
  endpoints: {
    defaultView,
    productView,
    searchView,
    quickSearch,
    dvEncryptToken
  }
} = rewardsConfig;

const callRewardsApi = (params = {}, endpoint = '', authenticated) => {
  if (endpoint) {
    if (authenticated) {
      return axios({
        params: {
          method: 'get',
          url: baseUrl + endpoint,
          params
        }
      })
        .then(response => {
          return response;
        })
        .catch(error => {
          throw error;
        });
    } else {
      return axios({
        params: {
          method: 'get',
          url: baseUrl + endpoint,
          params
        },
        type: 'public'
      })
        .then(response => {
          return response;
        })
        .catch(error => {
          throw error;
        });
    }
  }
};

const callEncryptionAPI = (payload, endpoint = '') => {
  return axios({
    params: {
      method: 'post',
      url: endpoint,
      data: payload
    },
    type: 'public'
  })
    .then(response => {
      console.log('encrypt response: ', response);
      return response;
    })
    .catch(error => {
      throw error;
    });
};

const setCatalogParams = (tagAnd = [], tagOr = [], offset=null, sortBy = 'newestToOldest', query = '', pointsValue = '', accountId, loyaltyTier, segment, profile) => {
  const params = {
    segments: 'loyalty_con',
    cac: accountId,
    withProfile: "true",
    offset,
    sortBy
  };
  if (loyaltyTier === 'BUSINESS') {
    params.segments = 'loyalty_smb';
  }
  if(segment) {
    params.segments = segment
  }
  if (tagAnd instanceof Array && tagAnd.length) {
    params.tagand = tagAnd.join();
  }
  if (tagOr instanceof Array && tagOr.length) {
    params.tagor = tagOr.join();
  }
  if (query) {
    params.query = query;
  }
  if (pointsValue) {
    params.points = pointsValue;
  }
  if (profile && Object.keys(profile).length > 0) {
    params.withProfile = 'false';
  }
  return params;
};

export const getCatalog = (tagAnd = [], tagOr = [], offset, sortBy, authenticated, pointsValue = '', accountId, loyaltyTier, segment, profile) => {
  const endpoint = authenticated ? defaultView.auth : defaultView.unauth;
  const params = authenticated ? setCatalogParams(tagAnd, tagOr, offset, sortBy, '', pointsValue, accountId, loyaltyTier, segment, profile) : setCatalogParams(tagAnd, tagOr, offset, sortBy);
  return callRewardsApi(params, endpoint, authenticated);
};

export const searchCatalog = (query, tagAnd = [], tagOr = [], sortBy, authenticated) => {
  const endpoint = authenticated ? searchView.auth : searchView.unauth;
  const params = setCatalogParams(tagAnd, tagOr, null, sortBy, query);
  return callRewardsApi(params, endpoint, authenticated);
};

export const getProduct = (
  pf = '',
  authenticated = false,
  loyaltyTier,
  accountId,
  segment,
  profile
) => {
  const endpoint = authenticated ? productView.auth : productView.unauth;
  const params = {
    segment: 'loyalty_con',
    cac: accountId,
    withProfile: 'true',
    pf
  };
  if (authenticated) {
    params.loyaltyTier = loyaltyTier;
  }
  if (segment) {
    params.segment = segment;
  }
  if (loyaltyTier === 'BUSINESS') {
    params.segment = 'loyalty_smb';
  }
  if (profile && Object.keys(profile).length > 0) {
    params.withProfile = 'false';
  }
  return callRewardsApi(params, endpoint, authenticated);
};

export const getQuickSearch = (query, authenticated) => {
  const endpoint = authenticated ? quickSearch.auth : quickSearch.unauth;
  const params = setCatalogParams(null, null, null, null, query);
  return callRewardsApi(params, endpoint, authenticated);
};

export const getEncryptionToken = payload => {
  const endpoint = dvEncryptToken;
  return callEncryptionAPI(payload, endpoint);
};
