import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({
  otherPath,
  component: Component,
  otherComponent: OtherComponent,
  check,
  fullPath,
  children,
  ...rest
}) => {
  const authenticated = useSelector(state => state.appStatus.authenticated);
  const enrolled = useSelector(state => state.enrolment.enrolled);
  switch (check) {
    // If authenticated then render component, if not re-route to otherPath
    case 'authenticated': {
      if (!authenticated) {
        return <Navigate to={otherPath} replace />;
      }
      return <Component fullPath={fullPath} {...rest} />;
    }
    // If not authenticated, then render the component, otherwise, re-reoute to otherPath
    case 'notauthenticated': {
      if (!authenticated) {
        return <Component fullPath={fullPath} {...rest} />;
      }
      return <Navigate to={otherPath} replace />;
    }
    // If enrolled then render component, if not re-route to otherPath
    case 'enrolled': {
      if (enrolled) {
        return <Component fullPath={fullPath} {...rest} />;
      }
      return <Navigate to={otherPath} replace />;
    }
    // If not enrolled then if not re-route to otherPath, otherwise render component
    case 'notenrolled': {
      if (!enrolled) {
        return <Component fullPath={fullPath} {...rest} />;
      }
      return <Navigate to={otherPath} replace />;
    }
    // Render passed in component by default
    default: {
      return <Component fullPath={fullPath} {...rest} />;
    }
  }
};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  fullPath: PropTypes.string,
  otherPath: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object
  ]),
  otherComponent: PropTypes.element,
  check: PropTypes.string.isRequired
};

ProtectedRoute.defaultProps = {
  component: () => {},
  otherPath: '',
  fullPath: '',
  otherComponent: null
};
