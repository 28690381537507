import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setFullLocationPath } from 'actions/app';
import { Helmet } from 'react-helmet';

import { Grid, Column, ActionButton, Spacing } from '@able/react';
import { Breadcrumbs } from 'components';
import { getChildComponents } from 'selectors/GetChildComponents';
import * as Analytics from 'utils/analytics';
import { tierChecker } from 'utils/tierChecker';
import { roleChecker } from 'utils/roleChecker';
import { addLeadSlash, removeEndSlash } from 'utils/String';
import { useLastLocation } from 'utils/hooks/useLastLocation';

import './JegoPage.scss';

const JegoPage = ({ fullPath = '' }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const route = useLastLocation();
  const pageContent = useSelector(state => getChildComponents(state, fullPath));
  const loyaltyTier = useSelector(state => state.accounts.loyaltyTier);
  const role = useSelector(state => state.accounts.role);
  const [validTier, setValidTier] = useState(true);
  const [validRole, setValidRole] = useState(true);
  const { pageConfig, bodyContent, headerContent } = pageContent;
  const { customTierErrorRoute, customRoleErrorRoute } = pageConfig;
  const { allowFromPrevious, redirect } = pageConfig;

  useEffect(() => {
    if (allowFromPrevious) {
      const { from } = route;
      let isValid = false;
      if (from) {
        const pathname = removeEndSlash(from);
        _.forOwn(allowFromPrevious, p => {
          let { page } = p;
          if (page) {
            page = addLeadSlash(page);
            if (page === pathname) {
              isValid = true;
            }
          }
        });
      }
      if (!isValid) {
        if (redirect) {
          history(addLeadSlash(redirect));
        } else {
          history('/');
        }
      }
    }
    if (fullPath) {
      dispatch(setFullLocationPath(fullPath));
    }
  }, [route]);

  const renderHeadTags = () => {
    if (pageConfig) {
      const { description, pageTitle } = pageConfig;
      return (
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={description} />
        </Helmet>
      );
    }
    return null;
  };

  useEffect(() => {
    if (pageConfig) {
      setValidTier(tierChecker(pageConfig, loyaltyTier));
      setValidRole(roleChecker(pageConfig, role));
      if (pageConfig.trackingCode) {
        Analytics.setPageName(pageConfig.trackingCode);
      }
    }
  }, [pageContent]);

  useEffect(() => {
    if (!validTier && customTierErrorRoute) {
      history(customTierErrorRoute);
    }
  }, [validTier]);

  useEffect(() => {
    if (!validTier && customRoleErrorRoute) {
      history(customRoleErrorRoute);
    }
  }, [validRole]);

  const renderTierError = () => {
    return (
      <Grid>
        <Column>
          <Breadcrumbs />
        </Column>
        <Column className="tplus-jego-page__narrow container">
          <h1>This benefit is not available</h1>
          <p>You may have other benefits available to activate.</p>
          <ActionButton
            element="Link"
            label="Access your benefits"
            to="/"
            className="button-low-emphasis"
            variant="LowEmphasis"
          ></ActionButton>
        </Column>
      </Grid>
    );
  };

  const renderContent = () => {
    return (
      <>
        <Grid className="tplus-jego-page__main container">
          {headerContent}
          <Spacing bottom="spacing4x" />
        </Grid>
        {bodyContent && bodyContent.length > 0 && (
          <Grid className="tplus-jego-page__narrow container">
            <Column bsmOffset={1} bsm={9}>
              {bodyContent}
              <Spacing bottom="spacing4x" />
            </Column>
          </Grid>
        )}
      </>
    );
  };

  return (
    <div className="tplus-jego-page">
      {renderHeadTags()}
      {!validTier && !customTierErrorRoute && renderTierError()}
      {validTier && renderContent()}
    </div>
  );
};

export default JegoPage;

JegoPage.propTypes = {
  fullPath: PropTypes.string.isRequired
};
