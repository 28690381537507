import React from 'react';

export default function IconNumberFour() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 32 32"
      id="icon-number-four"
    >
      <title>Step 4</title>
      <path d="M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16 9.4 4 16 4zm0-1.333C8.6 2.667 2.667 8.6 2.667 16S8.6 29.333 16 29.333 29.333 23.4 29.333 16 23.4 2.667 16 2.667z"></path>
      <path d="M16.867 20.133h-4.4c-.4 0-.733-.267-.733-.667v-.267c0-.133.067-.267.067-.4l4.2-9.8c.133-.333.333-.467.667-.467.467 0 .8.267.8.733 0 .133-.067.267-.067.333l-4 9.067h3.4v-1.933c0-.4.333-.733.733-.733.467 0 .8.267.8.733v1.933h1.133c.4 0 .733.333.733.733s-.267.733-.733.733h-1.133v2.667c0 .4-.333.733-.8.733-.4 0-.733-.267-.733-.733v-2.667z"></path>
    </svg>
  );
}
