import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import { Select } from '@able/react';
import { useDispatch, useSelector } from 'react-redux';
import { setProductSort } from 'actions/rewards';

const RewardsSort = ({onOptionChange}) => {
  const dispatch = useDispatch();
  const selectRef = useRef();
  const selectedSort = useSelector(state => state.rewards.catalogView.sort);

  const optionsArr = [
    {
      value: 'newestToOldest',
      label: 'Newest to oldest'
    },
    {
      value: 'leastPoints',
      label: 'Points low to high'
    },
    {
      value: 'maxPoints',
      label: 'Points high to low'
    },
    {
      value: 'a-z',
      label: 'Name A to Z'
    },
    {
      value: 'z-a',
      label: 'Name Z to A '
    }
    // {
    //   value: 'priceLowHigh',
    //   label: 'Price (low-high)'
    // },
    // {
    //   value: 'priceHighLow',
    //   label: 'Price (high-low)'
    // }
  ];

  useEffect(() => {
    const obj = _.find(optionsArr, o => o.value === selectedSort);
    if (obj) {
      selectRef.current.value = obj.value;
    }
  }, []);

  const handleChange = e => {
    dispatch(setProductSort(e.target.value));
    onOptionChange();
  };

  return (
    <Select
      id="sort"
      name="sort"
      label="Sort by"
      ref={selectRef}
      options={optionsArr}
      events={{
        onChange: handleChange
      }}
      allowEmpty={false}
    />
  );
};

export default RewardsSort;
