import React, { useState } from 'react';
import { filter } from 'lodash';
import dayjs from 'dayjs';

import { EventSummary } from 'components';
import SportsDropDown from '../../SportsDropDown';
import { Tabs, Tab, Panel } from '../../TabsView';

export const SportChildPage = props => {
  const { performances } = props;

  const [round, setRound] = useState({ value: '', dropDowntype: '' });
  const [team, setTeam] = useState({ value: '', dropDowntype: '' });
  const [venue, setVenue] = useState({ value: '', dropDowntype: '' });
  const [dropdownState, setDropdownState] = useState('team');

  const loadDropDownData = property => {
    const dropDownData = performances.map(x => {
      return x[property];
    });
    return dropDownData;
  };
  const handleDropDownChanges = (value, type) => {
    switch (type) {
      case 'team':
        setTeam({ value: value.selectedItem, dropDowntype: type });
        setDropdownState('team');
        break;
      case 'venue':
        setVenue({ value: value.selectedItem, dropDowntype: type });
        setDropdownState('venue');
        break;
      case 'rounds':
        setRound({ value: value.selectedItem, dropDowntype: type });
        break;
      default:
        break;
    }
  };

  const sportsDropDown = loadDropDownData('round');
  const team1names = loadDropDownData('team1Name');
  const team2names = loadDropDownData('team2Name');
  const venues = loadDropDownData('venueName');
  let newvenue = Array.from(new Set(venues)).sort();
  const teams = [...team1names, ...team2names];
  let newteams = Array.from(new Set(teams)).sort();
  const sportsDropDownData = Array.from(new Set(sportsDropDown));
  const isRoundArrayNull = currentValue =>
    currentValue === null || currentValue === '';
  const sportsDropDownDataNull = sportsDropDownData.every(isRoundArrayNull);
  /* code for per selected round in the same week */
  const dates = performances.map(item => {
    return item.eventPerformanceDate;
  });
  const now = new Date();
  let closest = Infinity;
  dates.forEach(d => {
    const date = new Date(d);
    if (date >= now && (date < new Date(closest) || date < closest)) {
      closest = d;
    }
  });
  const date = dayjs().format();
  const selectedRound = filter(performances, o => {
    if (date < o.eventPerformanceDate) {
      if (dayjs(o.eventPerformanceDate).isSame(new Date(), 'week')) {
        return o.eventPerformanceDate;
      }
      return o.eventPerformanceDate === closest;
    }
  }).map(x => x.round);

  let sortedRound = '';
  let sortedTeams = '';

  if (round.value !== '') {
    sortedRound = filter(performances, o => {
      return o.round === round.value;
    });
  } else {
    sortedRound = performances;
  }

  if (dropdownState === 'team') {
    if (team.value !== '') {
      // eslint-disable-next-line consistent-return
      sortedTeams = filter(performances, o => {
        if (o.team1Name === team.value) {
          return o.team1Name;
        }
        if (o.team2Name === team.value) {
          return o.team2Name;
        }
      });
      newvenue = sortedTeams.map(x => {
        return x.venueName;
      });
    } else {
      sortedTeams = performances;
    }
  } else if (venue.value !== '') {
    sortedTeams = filter(performances, o => {
      return o.venueName === venue.value;
    });
    const team1Collection = sortedTeams.map(x => {
      return x.team1Name;
    });
    const team2Collection = sortedTeams.map(x => {
      return x.team2Name;
    });
    const collectedTeams = [...team1Collection, ...team2Collection];
    newteams = Array.from(new Set(collectedTeams));
  } else {
    sortedTeams = performances;
  }

  return (
    <Tabs>
      <div className="tabs-container">
        <div className="tab-header-wrapper" role="tablist">
          <Tab className="tab-header">Round</Tab>
          <Tab className="tab-header">Teams</Tab>
        </div>
        <div className="tab-content">
          <Panel>
            {!sportsDropDownDataNull && (
              <SportsDropDown
                label={
                  selectedRound.length < 1
                    ? 'select round'
                    : `${selectedRound[0]} (This Week)`
                }
                initialSelectedItem={selectedRound[0]}
                name="round"
                dropDownItems={sportsDropDownData}
                onChange={item => {
                  handleDropDownChanges(item, 'rounds');
                }}
              />
            )}
            <EventSummary summaryData={sortedRound} />
          </Panel>
          <Panel>
            <div className="teamContainer">
              <div className="team-dropdown">
                <SportsDropDown
                  label={newteams[0]}
                  name="team"
                  dropDownItems={newteams}
                  onChange={item => {
                    handleDropDownChanges(item, 'team');
                  }}
                />
              </div>
              <div className="venue-dropdown">
                <SportsDropDown
                  label={newvenue[0]}
                  name="venue"
                  dropDownItems={newvenue}
                  onChange={item => {
                    handleDropDownChanges(item, 'venue');
                  }}
                />
              </div>
            </div>
            <EventSummary summaryData={sortedTeams} />
          </Panel>
        </div>
      </div>
    </Tabs>
  );
};
