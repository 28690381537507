import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { addLinkClickEvent } from 'utils/analytics';
import { Icon } from '@able/react';

import './SubNav.scss';

const SubNav = props => {
  const { items } = props;
  const numberOfItems = Object.keys(items).length;
  const location = useLocation();

  const ListItem = listProps => {
    const { copy, url, icon, target } = listProps;
    const isExternalLink = /^http/.test(url);
    const selectedClass =
      location.pathname === url ? 'tplus-action-list-item__selected' : '';

    const LinkElement = () => {
      return (
        <>
          <div className="tplus-action-list-item-container">
            <Icon
              size="32"
              developmentUrl="/able-sprites.svg"
              icon={icon}
              aria-hidden
              tabIndex="-1"
              screenReaderContent={copy}
            />
            <span className="action-item-link-text">{copy}</span>
          </div>
          <div className="tplus-action-list-border-bottom"></div>
        </>
      );
    };

    return (
      <li className="tplus-action-list-item" itemProp="name">
        {isExternalLink ? (
          <a
            href={url}
            target={target || '_self'}
            itemProp="url"
            rel={target === '_blank' ? 'noopener noreferrer' : '_self'}
            onClick={() => addLinkClickEvent(`subnav - ${copy}`)}
          >
            {LinkElement()}
          </a>
        ) : (
          <Link
            to={url}
            itemProp="url"
            className={selectedClass}
            onClick={() => addLinkClickEvent(`subnav - ${copy}`)}
          >
            {LinkElement()}
          </Link>
        )}
      </li>
    );
  };

  return (
    <>
      {items && (
        <div className="tplus-action-list-container">
          <nav aria-label="Action List" className="nav-class" role="navigation">
            <ul
              itemScope
              itemType="http://schema.org/SiteNavigationElement"
              className={`tplus-action-list-content ${(numberOfItems < 5 &&
                'center-content') ||
                ''}`}
            >
              {Object.keys(items).map(key => (
                <ListItem key={key} {...items[key]} />
              ))}
            </ul>
          </nav>
        </div>
      )}
    </>
  );
};

export default SubNav;
