import React from 'react';
import { useSelector } from 'react-redux';
import { HtmlConverter } from 'utils/HtmlConverter';
import clsx from 'clsx';

import { Cta, CtaGroups } from 'components';
import placeHolderImage from 'assets/images/PlaceHolder-800x360.png';

import { aemConfig } from 'oidc/oidc-configs';
import { isVideo } from 'utils/isVideo';
import styles from './HeadingTitle.module.scss';

const HeadingTitle = props => {
  const {
    subheading,
    heading,
    description,
    pageimage,
    imagelargealt,
    linkCopy,
    linkUrl,
    linkTracking,
    linkCopy2,
    linkUrl2,
    linkTracking2,
    terms,
    active,
    primaryCtas,
    secondaryCtas
  } = props;

  const isAuthenticated = useSelector(state => state.appStatus.authenticated);

  // Set up CSS
  const titleContainerClass = clsx(
    styles['heading-title-container'],
    !isAuthenticated && styles.offset
  );

  const videoElement = (src = '', alt = '') => {
    return (
      <>
        {alt && <p className="sr-only">{alt}</p>}
        <video muted autoPlay width="100%">
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </>
    );
  };

  const renderImages = () => {
    const { imagelarge, imagemedium, imagesmall, imagemini } = pageimage;

    const images = (
      <>
        {imagelarge && (
          <>
            <source
              media="(min-width:1360px)"
              srcSet={aemConfig.baseUrl + imagelarge}
            />
            <img
              src={aemConfig.baseUrl + imagelarge}
              className="pageheader-image-container"
              alt={imagelargealt || ''}
            />
          </>
        )}
        {imagemedium && (
          <source
            media="(min-width:1024px) and (max-width:1359px)"
            srcSet={aemConfig.baseUrl + imagemedium}
          />
        )}
        {imagesmall && (
          <source
            media="(min-width:768px) and (max-width:1023px)"
            srcSet={aemConfig.baseUrl + imagesmall}
          />
        )}
        {imagemini && (
          <source
            media="(min-width:320px) and (max-width:767px)"
            srcSet={aemConfig.baseUrl + imagemini}
          />
        )}
      </>
    );
    return images;
  };

  const renderDisplay = () => {
    if (pageimage) {
      const { imagelarge } = pageimage;
      if (isVideo(imagelarge)) {
        return videoElement(aemConfig.baseUrl + imagelarge, imagelargealt);
      }
      return (
        <figure>
          <picture>{renderImages()}</picture>
        </figure>
      );
    }
    return (
      <img
        src={placeHolderImage}
        className={styles['pageheader-image-container']}
        alt=""
      />
    );
  };

  const renderLink = (copy, url, tracking) => {
    // TODO probably dont even need this if we are using getlink
    // TODO also update this in AEM to use the CTA component in future
    if (url === '{signin}') {
      return (
        <Cta
          action="signin"
          trackingCode={tracking}
          ctaProps={{
            variant: 'LowEmphasis',
            label: copy
          }}
        />
      );
    }
    return (
      <Cta
        action="spaRoute"
        trackingCode={tracking}
        url={url}
        ctaProps={{
          variant: 'LowEmphasis',
          label: copy
        }}
      />
    );
  };

  const renderCtas = () => {
    // For the backward compatibility of CTAs
    if (linkCopy && linkUrl) {
      return (
        <>
          {linkCopy && linkUrl && (
            <ul className={styles['signin-section']}>
              <li>{renderLink(linkCopy, linkUrl, linkTracking)}</li>
              {linkCopy2 && linkUrl2 && (
                <li>{renderLink(linkCopy2, linkUrl2, linkTracking2)}</li>
              )}
            </ul>
          )}
        </>
      );
    }
    // V2 heading tile will have object of CTAs
    return (
      <CtaGroups primaryCtas={primaryCtas} secondaryCtas={secondaryCtas} />
    );
  };

  return (
    <>
      {active && (
        <div className={`container ${titleContainerClass}`}>
          <div className={styles['title-base']}>
            <div className={styles['subheading-section']}>{subheading}</div>
            <h1 className={styles['heading-section']}>{heading}</h1>
            {description && (
              <p className={styles['description-section']}>{description}</p>
            )}
            {terms && (
              <div className={styles['term-section']}>
                {HtmlConverter(terms)}
              </div>
            )}
            {renderCtas()}
          </div>
          <div className={styles['pageheader-image-container']}>
            {renderDisplay()}
          </div>
        </div>
      )}
    </>
  );
};

export default HeadingTitle;
