import React, { useEffect } from 'react';
import { useFocus } from 'utils/hooks/useFocus';
import { TextArea } from '@able/react';

const FormTextArea = props => {
  const {
    name = '',
    label = '',
    className = '',
    size,
    rows,
    helpText,
    defaultValue,
    required,
    invalidInputText,
    pattern,
    inputMode,
    maxLength,
    autoComplete,
    autoFocus,
    customRegex,
    customValidationError,
    handleChange,
    errors,
    touched,
    isSubmitting,
    isValidating,
    setTouched
  } = props;

  const passedInProps = {
    name,
    label,
    className,
    size,
    rows,
    helpText,
    defaultValue,
    required,
    invalidInputText,
    pattern,
    inputMode,
    maxLength,
    autoComplete,
    autoFocus
  };

  const [inputRef, setInputFocus] = useFocus();
  const errored = errors[name] && touched[name];

  useEffect(() => {
    if (isSubmitting && !isValidating && Object.keys(errors)[0] === name) {
      setInputFocus();
    }
  }, [isSubmitting, isValidating, errors]);

  const doFocus = e => {
    if (e.target.value === ' ') {
      e.target.value = '';
    }
  };

  const doChange = e => {
    setTouched({ [name]: false });
    handleChange(e);
  };

  return (
    <div className="tplus-form-field">
      <TextArea
        id={name}
        {...passedInProps}
        defaultValue=" "
        invalidInputText={customValidationError}
        ref={inputRef}
        pattern={customRegex}
        invalid={errored}
        events={{ onKeyUp: e => doChange(e), onFocus: e => doFocus(e) }}
        developmentUrl="/able-sprites.svg"
      />
    </div>
  );
};

export default FormTextArea;
