import * as Yup from 'yup';

export const constructValidationSchema = props => {
  const {
    type,
    isRequired,
    minRequired,
    fieldType,
    customRegex,
    requiredError,
    customValidationError
  } = props;

  const dataType = compType => {
    switch (compType) {
      case 'formCheckboxGroup':
        return Yup.array();
      case 'formSelect':
      case 'formTextArea':
      case 'formTextfield':
      case 'formRadio':
        return Yup.string();
			default:
				return Yup.string();
    }
  };

	let schema = dataType(type);
	
	if (fieldType === 'mobile') {
		schema = schema.matches(
			/^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/,
			'Invalid mobile number.'
		);
	}
	if (fieldType === 'email') {
		schema = schema.email('Invalid email address');
	}
	if (fieldType === 'custom') {
		try {
			const re = new RegExp(customRegex);
			schema = schema.matches(re, customValidationError);
    } catch (e) {
      return;
    }
	}
  if (parseInt(minRequired) > 0) {
    const erroMessage =
      requiredError || `Please select at least ${minRequired}`;
		schema = schema.min(minRequired, erroMessage);
  }	
	if (isRequired) {
		schema = schema.required(requiredError);
	}

  // eslint-disable-next-line consistent-return
  return schema;
};
