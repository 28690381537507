export const ASSET_ATTRIBUTES =
{
    WIFI_BOOSTER          : "WIFI_BOOSTER",
    WIFI_BOOSTER_CODE     : "XC001016748",
    WIFI_BOOSTER_OUTRIGHT : "XC001018796",
    GO_BOOSTER_CODE       : "XC001017303",
    BINGE_GOLD            : "BINGEGOLD",
    BINGE_SILVER          : "BINGESILVER",
    KAYO_GOLD             : "KAYOGOLD",
    KAYO_SILVER           : "KAYOSILVER",
    KAYO_GOLD_3M          : "KAYOGOLD3M",
    KAYO_SILVER_3M        : "KAYOSILVER3M"
}