/**
 * Used in the Dialog to combine external click handlers with internal state handlers
 *
 * @param events an object containing injected event handlers
 * @param setHideDialog function to combine with inject click handlers
 *
 * @returns a new function which when called triggers setHideDialog as well as the injected events
 */

export const createClickHandler = (events, setHideDialog) => () => {
  if (typeof setHideDialog !== 'undefined') {
    setHideDialog();
  }
  if (typeof events !== 'undefined' && typeof events.onClick === 'function') {
    events.onClick();
  }
};
