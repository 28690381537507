import React, { Component } from 'react';

export const REDEMPTION_ELEGIBILITY_ERRORS = {
  "SERVICE_NOT_ELIGIBLE": <div><p> This offer is only available for new Wi-Fi booster connections on eligible consumer nbn broadband services.<br /><a href='https://www.telstra.com.au/internet/nbn' target='_blank'>Explore Telstra nbn plans</a></p></div>,
  "ALREADY_SUBSCRIBED": "This nbn service has an active Wi-Fi booster. This offer is limited to members who don’t have a Wi-Fi booster.",
  "PENDING_ORDER_FOUND": "This nbn service has an order being processed. We can only process one subscription order per service. You can try again as soon as your current order is complete.",
  "SERVICE_CALL_FAILED": "Something went wrong. We’re currently unable to process your request. Please try again later.",
  "MULTIPLE_SERVICES_FOUND": "This offer is currently for members with a single Telstra nbn service.",
  "REDEMPTION_ELIGIBLE_ON_ANOTHER_CAC": <p>Offer not available on this membership. Redeem using another membership.</p>,
  "GO_SERVICE_NOT_ELIGIBLE": <p>This offer is only available to new Game Optimiser subscriptions on eligible consumer nbn broadband connections. For more information refer to the <i>Things you need to know</i> section below.<br /><a href='https://www.telstra.com.au/internet/nbn' target='_blank'>Explore Telstra nbn plans</a></p>,
  "GO_ALREADY_SUBSCRIBED": "This nbn service has an active Game Optimiser subscription. This offer is only available to new subscriptions. For more information refer to the <i>Things you need to know</i> section below.",
}
