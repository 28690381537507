export const addLeadSlash = (str = '') => {
  if (str.charAt(0) !== '/') {
    return `/${str}`;
  }
  return str;
};

export const removeEndSlash = (str = '') => {
  if (str.charAt(str.length - 1) === '/') {
    return str.substring(0, str.length - 1);
  }
  return str;
}
