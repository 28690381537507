import { useRef } from 'react';

export const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    // eslint-disable-next-line no-unused-expressions
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};
