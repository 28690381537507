import { combineReducers } from 'redux';

import { catalogView } from './catalogView';
import { productView } from './productView';
import { profile } from './profile';
import { error } from './error';

const reducers = combineReducers({
  catalogView,
  productView,
  profile,
  error
});

export default reducers;
