import React from 'react';
import uuid from 'uuid/v4';
import { TextStyle } from '@able/react';
import { formatDate } from 'utils/formatDate';

import './PointsTable.scss';
import SVGDirectDebit from 'icons/SVGDirect-Debit.svg';
import SVGRedeemPoints from 'icons/SVGRedeemPoints.svg';
import SVGRefund from 'icons/SVGRefund.svg';
import SVGEarnPoints from 'icons/SVGEarnPoints.svg';

const PointsTable = props => {
  const { transactions = [] } = props;

  const RenderRowItems = transactionItem => {
    const transaction = transactionItem.transactionItem;
    const {
      transactionType,
      pointsEarned,
      transactionDate,
      transactionAmount,
      transactionDescription
    } = transaction;

    const transactionDT = new Date(transactionDate);
    const todaysDT = new Date();

    let parsedPoints = 0;
    if (transactionType === 'Redemption') {
      parsedPoints = -Math.abs(pointsEarned);
    } else if (transactionType === 'Credit Redemption') {
      parsedPoints = Math.abs(pointsEarned);
    } else {
      parsedPoints = pointsEarned;
    }

    return (
      <>
        {transaction && transactionDT < todaysDT && (
          <tr key={uuid()}>
            <td>
              <div className="info">
                {transactionType === 'Redemption' && (
                  <img alt="" className="item-icon" src={SVGRedeemPoints} />
                )}
                {transactionType === 'Credit Redemption' && (
                  <img alt="" className="item-icon" src={SVGRedeemPoints} />
                )}
                {(transactionType === 'Adjustment' ||
                  transactionType === 'Points Expired') && (
                  <img alt="" className="item-icon" src={SVGEarnPoints} />
                )}
                {transactionType === 'Payment Transaction' &&
                  transactionAmount >= 0 && (
                    <img alt="" className="item-icon" src={SVGDirectDebit} />
                  )}
                {transactionType === 'Payment Transaction' &&
                  transactionAmount < 0 && (
                    <img alt="" className="item-icon" src={SVGRefund} />
                  )}

                <TextStyle
                  alias="FinePrint"
                  element="div"
                  colour="Subtle"
                  className="item-date"
                >
                  {formatDate(transactionDate)}
                </TextStyle>
                <span className="item-label">
                  <TextStyle alias="Base" element="span">
                    {transactionAmount !== 0 &&
                      `$${Math.abs(transactionAmount)} - `}
                    {transactionDescription}
                  </TextStyle>
                </span>
              </div>
            </td>
            <td>
              <div className="info">
                <span className="item-price">
                  <TextStyle alias="Base" element="span">
                    {parsedPoints > 0 && '+'}

                    {parseInt(parsedPoints, 10).toLocaleString(
                      navigator.language,
                      {
                        minimumFractionDigits: 0
                      }
                    )}
                  </TextStyle>
                </span>
              </div>
            </td>
          </tr>
        )}
      </>
    );
  };

  return (
    <table className="tplus-points-history-table">
      <thead>
        <tr>
          <th>
            <TextStyle alias="Label" element="span">
              Description
            </TextStyle>
          </th>
          <th>
            <TextStyle alias="Label" element="span">
              Points
            </TextStyle>
          </th>
        </tr>
      </thead>
      <tbody>
        {transactions.map((transaction, key) => (
          <RenderRowItems transactionItem={transaction} key={key} />
        ))}
      </tbody>
    </table>
  );
};

export default PointsTable;
