import React from 'react';

export default function IconNumberThree() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 32 32"
      id="icon-number-three"
    >
      <title>Step 3</title>
      <path d="M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16 9.4 4 16 4zm0-1.333C8.6 2.667 2.667 8.6 2.667 16S8.6 29.333 16 29.333 29.333 23.4 29.333 16 23.4 2.667 16 2.667z"></path>
      <path d="M15.4 16.333c-.467 0-.733-.333-.733-.733s.267-.733.733-.733h.733c.333 0 .667-.067.933-.267.667-.333 1.067-1 1.067-2v-.467c0-1.267-.867-2.133-2.067-2.133h-.067c-1.133 0-2 .933-2 1.867 0 .467-.4.733-.8.733-.333 0-.8-.267-.8-.733 0-1.8 1.467-3.4 3.533-3.4h.067c2.2 0 3.667 1.667 3.667 3.667v.467c0 1.6-.8 2.533-1.733 2.933 1 .533 1.867 1.533 1.867 3.267v.867c0 2-1.733 3.733-3.733 3.733h-.133c-1.867 0-3.733-1.4-3.733-3.6 0-.467.333-.733.8-.733s.8.267.8.733c.067 1.333 1.067 2.133 2.2 2.133h.133c1.133 0 2.2-.933 2.2-2.333v-.8c0-1.067-.467-1.867-1.133-2.267-.267-.2-.667-.267-1.067-.267h-.733z"></path>
    </svg>
  );
}
