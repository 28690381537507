import React from 'react';
import { Grid, Column, Spacing } from '@able/react';

const Stacked = props => {
  const { opts = {} } = props;
  const { image, body, surface } = opts;

  const surfaceSpacing =
    surface !== 'SurfaceFlat'
      ? {
          top: 'spacing2x',
          right: 'spacing2x'
        }
      : {};

  return (
    <Grid>
      <Column bsm={7}>
        {image}
        <Spacing bottom="spacing2x" className="visible-xs" />
      </Column>
      <Column bsm={5}>
        <Spacing {...surfaceSpacing}>{body}</Spacing>
      </Column>
    </Grid>
  );
};

export default Stacked;
