import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { addLinkClickEvent } from 'utils/analytics';
import { signIn } from 'oidc/initiateOidc';
import { NotificationBar } from 'components';
import { getAemContent } from 'selectors/GetAemContent';
import { TextStyle } from '@able/react';

import logoSVG from 'assets/images/t-logo.svg';

import '../Header.scss';
import { SEGMENT_SESSION_STORAGE_TOKEN_NAME, SESSION_STORAGE_SOURCE } from 'constants/rewardsStore';

const HeaderUnauth = props => {
  const [showNotificationBar, setShowNotificationBar] = useState(false);
  const notificationBarContent = useSelector(state =>
    getAemContent(state, 'global/unauth/components', 'notificationBar')
  )[0];

  useEffect(() => {
    if (notificationBarContent && notificationBarContent.active) {
      setShowNotificationBar(true);
    }
  }, [notificationBarContent]);

  const NotificationBarOffset = () => {
    return (
      <>
        {showNotificationBar && (
          <div className="offset-div-notification-bar" />
        )}
      </>
    );
  };

  return (
    <>
      <header
        role="banner"
        className="not-enrolled-header tcom-global-navigation"
        id="tcom-navigation"
      >
        {notificationBarContent && notificationBarContent.active && (
          <NotificationBar
            {...notificationBarContent}
            onClose={() => setShowNotificationBar(false)}
          />
        )}
        <nav
            // style={{ backgroundColor: getTierColour(accounts.loyaltyTier) }}
            className={`tcom-nav-container container hidden-xs UNAUTH`}
            aria-label="User header"
          >
            <Link className="logo" to="/">
              <img src={logoSVG} alt="Telstra Logo" />
            </Link>
            <TextStyle alias="Base" element="span" className="tplus-text">
                Telstra Plus
            </TextStyle>

            <div className="tier-container">
              <div className="tier-info">
                { sessionStorage.getItem(SESSION_STORAGE_SOURCE) !== "agent_console" &&
                  <button
                    className="signout"
                    type="button"
                    onClick={() => {
                      sessionStorage.removeItem(SEGMENT_SESSION_STORAGE_TOKEN_NAME);
                      addLinkClickEvent('header login');
                      signIn();
                    }}
                    >
                      <TextStyle alias="Base" element="span">
                          {props.buttonText}
                      </TextStyle>
                    </button>
                }
              </div>
            </div>
          </nav>
      </header>
      <div className="offset-div-default" />
      {NotificationBarOffset()}
    </>
  );
};

HeaderUnauth.propTypes = {
  buttonText: PropTypes.string
};

HeaderUnauth.defaultProps = {
  buttonText: 'Sign in or join'
};

export default HeaderUnauth;
