import React from 'react';
import { HtmlConverter } from 'utils/HtmlConverter';

// TODO Refactor the tab properties when we upgrade to AEM6.5 so we could use nested multifields
const Text = props => {
  const { text } = props;

  return <section className="tplus-rich-text">{HtmlConverter(text)}</section>;
};

export default Text;
