import React from 'react';
import { useSelector } from 'react-redux';
import { getFilteredCatalog } from 'selectors/GetRewards';
import { RewardsProductTile } from 'components';
import { TextStyle } from '@able/react';
import { exclusiveToTiers } from 'utils/rewards/exclusiveToTiers';

import './RewardsProductTileGroup.scss';
import Skeleton from 'components/Skeleton';
import { chain, uniqWith, isEqual } from 'lodash';
const RewardsProductTileGroup = props => {
  const { bsmCols = 3, bmdCols = 4 } = props;
  // @TODO: Revert filter once GnR is complete
  const productFamilies = useSelector(state => getFilteredCatalog(state).filter(isHiddenProductFamily));
  const apiStatus = useSelector(state => state.rewards.catalogView.status);
  const brands = useSelector(state => state.rewards.catalogView.productBrands);

  // const apiStatus = 'idle';

  const renderProductTiles = () => {
    if (productFamilies.length > 0) {
      return productFamilies.map((pf, key) => {
        const {
          id,
          name,
          brandId = '',
          products = [],
          specialOffer,
          imageUrl,
          minPay,
          minPoints,
          minOutright
        } = pf;

        const hasStock = products.some(p => !p.outOfStock);
        const isHotOffer = pf.hotOffer;
        if (isHotOffer) {
            products.sort((a, b) => new Date(a.currentPrice.endDate).getTime() - new Date(b.currentPrice.endDate).getTime());
        }
        const endDate = products[0]?.currentPrice?.endDate;
        const brand = brands[brandId]?.name;
        const tierExclusive = {
          gold: products.some(p =>
            exclusiveToTiers(p.attributes).some(e => e === 'GOLD')
          ),
          silver: products.some(p =>
            exclusiveToTiers(p.attributes).some(e => e === 'SILVER')
          ),
          member: products.some(p =>
            exclusiveToTiers(p.attributes).some(e => e === 'MEMBER')
          ),
          business: products.some(p =>
            exclusiveToTiers(p.attributes).some(e => e === 'BUSINESS')
          )
        };

        const appendFrom = () => {
          const hasPriceOptions = products.some(p => {
            const { currentPrice: { priceOptions } } = p;
            return priceOptions?.length > 1;
          })
          const allAttributes = chain(products)
            .map('attributes') // grab all attributes
            .flatten() // flatten the elements into a single array
          const uniqueAttributes = uniqWith(allAttributes.filter({ type: 'SELECTION' }).value(), isEqual); // Remove duplicate attributes
          const hasAttributes = uniqueAttributes.length > 0;
          return products.length > 1 && !hasPriceOptions && hasAttributes;
        }   

        const getPromotionName = () => {
          let promotionName = '';
          products.map(product => {
            const { tags } = product;
            const promotionList = getPromosByDescription(tags, "promotionType");
            if (promotionList.length > 0) {
              promotionName = promotionList[0]?.["name"].split("|")[0];
            }
          });
          return promotionName;
        }

        const getPromosByDescription = (tags, description) => {
          return tags.filter((tag) => tag?.description?.toLowerCase() === description.toLowerCase())
        }

        const promoName = !isHotOffer ? getPromotionName() : '';
       
        return (
          <RewardsProductTile
            key={key}
            id={id}
            name={name}
            brand={brand}
            imageUrl={imageUrl}
            minOutright={minOutright}
            isHotOffer={isHotOffer}
            hasStock={hasStock}
            minPay={minPay}
            minPoints={minPoints}
            specialOffer={specialOffer}
            tierExclusive={tierExclusive}
            appendFromText={appendFrom()}
            endDate={endDate}
            bsmCols={bsmCols}
            bmdCols={bmdCols}
            promoName={promoName}
          />
        );
      });
    }
    return (
      <TextStyle alias="Base" element="p">
        No products were found
      </TextStyle>
    );
  };

  const renderLoader = count => {
    const loaderArray = [];
    for (let i = 0; i < count; i += 1) {
      const loader = (
        <li
          className={`tplus-rewards-tile-loader able-column cols-6 bsm-cols-${bsmCols} bmd-cols-${bmdCols}`}
        >
          <div className='tplus-skeleton-loader'>
            <Skeleton size={{ width: '100%', height: '200px' }} />
            <Skeleton size={{ width: '60px', height: '16px' }} />
            <Skeleton size={{ width: '180px', height: '30px' }} />
            <Skeleton size={{ width: '150px', height: '16px' }} />
            <Skeleton size={{ width: '130px', height: '16px' }} />
          </div>
        </li>
      );
      loaderArray.push(loader);
    }
    return loaderArray;
  };

  return (
    <ul className="able-Grid tplus-rewards-product-tile-group">
      {apiStatus === 'succeeded'
        ? renderProductTiles()
        : renderLoader(12 / bmdCols)}
    </ul>
  );
};


// @TODO: Remove once GNR complete
export const isHiddenProductFamily = pf => !pf.products.some(product => ["GNR25SILVER", "GNR50SILVER", "GNR25GOLD", "GNR50GOLD", "GNR25DIAMOND", "GNR50DIAMOND"].includes(product.sku));

export default RewardsProductTileGroup;
