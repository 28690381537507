import React from 'react';
import { signInSilentCallback } from 'oidc/initiateOidc';

const Silentrenew = () => {

  signInSilentCallback();

  return <div></div>;
};

export default Silentrenew;
