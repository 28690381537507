import * as actions from 'constants/actions';

const state = {
  success: false,
  error: { code: null }
};

export const updateContactDetails = (newState = state, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.SET_UPDATECONTACTDETAILS_SUCCESS: {
      return { ...newState, success: payload };
    }
    case actions.SET_UPDATECONTACTDETAILS_ERROR: {
      return { ...newState, error: payload };
    }
    default:
      return newState;
  }
};
