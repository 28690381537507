import * as actions from 'constants/actions';
import { getAuthorizationToken } from 'oidc/initiateOidc';
import store from '../store';

// reference : https://developers.liveperson.com/

// Setting some mysterious livechat params
export const setParams = (params, flag) => {
  return window.livePerson?.setPersistentParameters?.(params, flag);
};

// Setting some mysterious livechat params (based on tier)
const setSdes = param => {
  return window.lpTag?.sdes?.send?.(param);
};

// Function that runs when chat state is "chatting"
export const clickLiveChat = stateObj => {
  const { state } = stateObj;
  // const tier = window.digitalData.user[0].profile[0].profileInfo.loyaltyTier;
  const {
    digitalData: {
      user: [
        {
          profile: [
            {
              profileInfo: { loyaltyTier }
            }
          ]
        }
      ]
    }
  } = window;

  if (state === 'chatting') {
    if (loyaltyTier) {
      switch (loyaltyTier) {
        case 'GOLD': {
          setSdes({
            type: 'ctmrinfo',
            info: {
              ctype: 'Gold Member'
            }
          });

          break;
        }
        case 'SILVER': {
          setSdes({
            type: 'ctmrinfo',
            info: {
              ctype: 'Silver Member'
            }
          });

          break;
        }
        case 'MEMBER': {
          setSdes({
            type: 'ctmrinfo',
            info: {
              ctype: 'Member'
            }
          });

          break;
        }
        case 'BUSINESS': {
          setSdes({
            type: 'ctmrinfo',
            info: {
              ctype: 'Business Member'
            }
          });
          break;
        }
        default:
          break;
      }
    }

    window?.lpTag?.events?.unbind({
      eventName: 'state',
      appName: 'lpUnifiedWindow'
    });
  }
};

// Binding our function to livechat app
export const bindLiveChat = () => {
  return window?.lpTag?.events?.bind?.({
    eventName: 'state',
    appName: 'lpUnifiedWindow',
    func: clickLiveChat
    // context: callbackFunctionExecutionContext,
    // async: true, //default is false,
    // triggerOnce: true // default is false
  });
};

const setGetToken = () => {
  const regexToTest = new RegExp(process.env.REACT_APP_LIVECHAT_REGEX);

  if (
    Object.prototype.hasOwnProperty.call(window, 'disableAuthConversations') ||
    Array.from(document.getElementsByTagName('script')).filter(s =>
      s.src.match(regexToTest)
    ).length === 0
  ) {
    return null;
  }

  return () => {
    return getAuthorizationToken();
  };
};

//  Init livechat with default params
// eslint-disable-next-line consistent-return
export const initLiveChat = () => {
  const { location = {} } = window;
  const { pathname = '' } = location;
  switch (pathname) {
    case 'explainer': {
      window.livePerson?.init?.({
        section: ['telstra', 'telstra-plus'],
        sourceSection: ['myacc'],
        onlyTcomCSS: true,
        forceButtonCss: true,
        persistentChat: true,
        persistentSection: ['telstra', 'telstra-plus', 'consumer', 'dtq'],
        getToken: setGetToken()
      });
      window.livePerson?.setPersistentParameters?.(
        ['telstra', 'telstra-plus', 'smb', 'heritage', 'dtq'],
        true
      );
      store.dispatch({
        type: actions.SET_LIVECHAT,
        payload: true
      });
      break;
    }
    default: {
      window.livePerson?.init?.({
        section: ['telstra', 'telstra-plus'],
        sourceSection: ['myacc'],
        onlyTcomCSS: true,
        forceButtonCss: true,
        persistentChat: true,
        persistentSection: ['telstra', 'telstra-plus', 'consumer', 'dtq'],
        getToken: setGetToken()
        // callBack() {
        //   // (Optional if not passed no callback will called)function need to called in interval if event is resume or waiting
        //   console.log('CallBack called');
        // },
        // callBackInterval: 15 // interval in second (default: 1 second)
      });
      store.dispatch({
        type: actions.SET_LIVECHAT,
        payload: true
      });
      break;
    }
  }
  bindLiveChat();
};

// Check for tier and set livechat params
export const setLiveChat = tier => {
  switch (tier) {
    case 'GOLD': {
      setParams(
        ['telstra', 'telstra-plus', 'consumer', 'dtq', 'tplus-gold'],
        true
      );
      break;
    }
    case 'SILVER': {
      setParams(
        ['telstra', 'telstra-plus', 'consumer', 'dtq', 'tplus-silver'],
        true
      );
      break;
    }
    case 'MEMBER': {
      setParams(
        ['telstra', 'telstra-plus', 'consumer', 'dtq', 'tplus-member'],
        true
      );

      break;
    }
    case 'BUSINESS': {
      setParams(
        ['telstra', 'telstra-plus', 'consumer', 'dtq', 'tplus-bus-member'],
        true
      );

      break;
    }
    default:
      break;
  }
};

// Basically this shows or hides the live chat based on location
export const setPersistentChat = () => {
  const hrefContains = (path, href) => {
    return href.includes(path);
  };
  // TODO yeh can probably make this more generic if and when we want to flag more options in future

  const { location = {} } = window;
  const { href = '' } = location;

  switch (true) {
    case hrefContains('/benefits/open-up/entry', href): {
      return setParams(
        {
          persistentChat: false
        },
        false
      );
    }

    default:
      break;
  }
  return setParams(
    {
      persistentChat: true
    },
    true
  );
};
