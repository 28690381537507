import axios from './util/axiosHandle';
import { apiConfig as api } from '../oidc/oidc-configs';

const getEntryDetails = opts => {
  const {status, ...payload} = opts;
  const statusStub = status ? `/${status}` : '';

  return axios({
    params: {
      method: 'get',
      url: api.baseUrl + api.endpoints.getEntryDetails + statusStub,
      params: payload
    }
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error.response;
    });
};

export default getEntryDetails;
