import React from 'react';
import { TextStyle, Spacing } from '@able/react';

import * as ERRMSGS from 'constants/errorMessages.js';

import NoPointsImage from 'assets/images/NoPointsImg.png';

const NoPoints = () => (
  <div className="no-points">
    <TextStyle element="h2" alias="HeadingC">
      {ERRMSGS.POINTLESSHEADING}
    </TextStyle>
    <Spacing bottom="spacing2x" />
    <TextStyle alias="Base" element="p">{ERRMSGS.POINTLESSCOPY}</TextStyle>
    <Spacing bottom="spacing2x" />
    <img src={NoPointsImage} alt="Cactus and tumbleweed illustration" />
  </div>
);

export default NoPoints;
