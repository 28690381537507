import React from 'react';
import { Column, Spacing, TextStyle } from '@able/react';

import { HtmlConverter } from 'utils/HtmlConverter';
import { Cta, CtaGroups } from 'components';
import { getLink } from 'utils/utils';
import { addLinkClickEvent } from 'utils/analytics';

import './MiniPromoTile.scss';

const MiniPromoTile = props => {
  const {
    id,
    imagePath,
    imageAltText,
    promoCopy,
    promoSubCopy,
    disclaimer,
    disclaimerTerms,
    promoSmallTitle,
    promoTitle = '',
    url,
    buttonText,
    isProductTile,
    pointsAndPayCopy,
    isHotOffer,
    pointsValue,
    offerEndDate,
    active,
    pending,
    ctaAction,
    ctaTarget,
    ctaTrackingCode,
    secondaryCtaAction,
    secondaryCtaText,
    secondaryCtaUrl,
    secondaryCtaTarget,
    secondaryCtaTrackingCode,
    primaryCtas,
    secondaryCtas
  } = props;

  const getButtonLink = () => {
    return getLink({
      link: url,
      linkText: buttonText,
      linkClass: 'button-medium-emphasis',
      linkArrow: false
    });
  };

  const renderPrimaryCta = () => {
    return (
      <div className="cta-container">
        <Cta
          action={ctaAction}
          trackingCode={ctaTrackingCode}
          url={url}
          ctaProps={{
            variant: 'HighEmphasis', // TODO Should come from AEM
            label: buttonText,
            ...(ctaTarget && { target: ctaTarget })
          }}
        />
      </div>
    );
  };

  const renderSecondaryCta = () => {
    return (
      <p>
        <Cta
          action={secondaryCtaAction}
          trackingCode={secondaryCtaTrackingCode}
          url={secondaryCtaUrl}
          ctaProps={{
            variant: 'LowEmphasis', // TODO Should come from AEM
            label: secondaryCtaText,
            ...(secondaryCtaTarget && { target: secondaryCtaTarget })
          }}
        />
      </p>
    );
  };

  const renderCtas = () => {
    // For the backward compatibility of CTAs
    if (buttonText || secondaryCtaText) {
      return (
        <>
          {buttonText && renderPrimaryCta()}
          {secondaryCtaText && renderSecondaryCta()}
        </>
      );
    }
    // V2 promo tile will have object of CTAs
    return (
      <CtaGroups primaryCtas={primaryCtas} secondaryCtas={secondaryCtas} />
    );
  };

  return (
    <>
      {active && !pending && isProductTile && (
        <a
          id={id}
          target="_blank"
          rel="noopener noreferrer"
          href={url}
          onClick={() => addLinkClickEvent(ctaTrackingCode)}
          data-link-tracking-code="14052019:tplusspa:thanks:banner"
          className={`mini-promo-tile ${isProductTile && 'product-tile'}`}
        >
          <div className="tile-image">
            {isHotOffer && <span>Hot Offer</span>}
            <img src={imagePath} alt={imageAltText} loading="lazy" />
          </div>
          {promoSmallTitle && <h3>{promoSmallTitle}</h3>}
          {promoTitle && <h4>{promoTitle}</h4>}
          {pointsAndPayCopy && <h5>{pointsAndPayCopy}</h5>}
          {promoCopy && <p>{promoCopy}</p>}
          {promoSubCopy && <p className="sub-copy">{promoSubCopy}</p>}
          {disclaimer && <p>{disclaimer}</p>}
          {disclaimerTerms && <p>{disclaimerTerms}</p>}
          {pointsValue && <p>{HtmlConverter(pointsValue)}</p>}
          {offerEndDate && <span>{offerEndDate}</span>}
          {buttonText && <p>{getButtonLink()}</p>}
        </a>
      )}
      {active && !isProductTile && (
        <Column className="mini-promo-tile" id={id}>
          <Spacing bottom="spacing2x">
            {isHotOffer && <span>Hot Offer</span>}
            <img src={imagePath} alt={imageAltText || ''} loading="lazy" />
          </Spacing>
          {promoSmallTitle && (
            <TextStyle alias="HeadingD" element="h3">
              {promoSmallTitle}
            </TextStyle>
          )}
          <TextStyle alias="HeadingD" element="h3">{promoTitle}</TextStyle>
          {promoCopy && <p>{HtmlConverter(promoCopy)}</p>}
          {promoSubCopy && <p className="sub-copy">{promoSubCopy}</p>}
          {disclaimer && <p>{disclaimer}</p>}
          {disclaimerTerms && <p>{disclaimerTerms}</p>}
          {pointsValue && (
            <p>
              <strong>{pointsValue}</strong> pts
            </p>
          )}
          {pointsAndPayCopy && <p>{pointsAndPayCopy}</p>}
          {renderCtas()}
        </Column>
      )}
    </>
  );
};

export default MiniPromoTile;
