import { v4 as uuidv4 } from 'uuid';
import { apiConfig as api } from 'oidc/oidc-configs';
import { LOGGER } from 'utils/AppLog';
import axios from './util/axiosHandle';

const optOutApi = () => {
  return axios({
    incomingheaders: {
      'ds-correlation-id': uuidv4()
      // 'ds-source-system': 'Telstra Plus SPA'
    },
    params: {
      method: 'post',
      url: api.baseUrl + api.endpoints.optOut
    }
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      LOGGER.error('OptOut Error:', error);
      return error.response;
    });
};

export default optOutApi;
