import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import spinnerGIF from 'assets/images/Spinner.gif';

import {
  genericPhrases,
  loadingPhrases,
  retryPhrases,
  unenrolPhrases
} from 'constants/loadingSpinnerMessages.js';

import styles from './LoadingSpinner.module.scss';

const LoadingSpinner = () => {
  const location = useNavigate();

  const [seconds, setseconds] = useState(6);
  const [beginPhrases, setbeginPhrases] = useState(false);
  const [display, setdisplay] = useState('');
  const [count, setcount] = useState(0);

  const hasCheckedEligibility = useSelector(
    state => state.eligibility.hasCheckedEligibility
  );
  const loading = useSelector(state => state.enrolment.loading);
  const enrolFailed = useSelector(state => state.enrolment.enrolFailed);

  const chooseNextPhrase = () => {
    let phraseList = [];

    if (loading) {
      phraseList = loadingPhrases;
    }

    if (!hasCheckedEligibility) {
      phraseList = genericPhrases;
    }

    if (enrolFailed) {
      phraseList = retryPhrases;
    }

    if (location.pathname === '/opt-out') {
      phraseList = unenrolPhrases;
    }

    if (count < phraseList.length) {
      const phraseToDisplay = phraseList[count];
      setdisplay(phraseToDisplay);
      setcount(count + 1);
    } else {
      setcount(0);
    }
  };

  const tick = () => {
    if (seconds === 3) {
      setbeginPhrases(true);
    }

    if (seconds === 1) {
      chooseNextPhrase();
      setseconds(6);
    } else {
      setseconds(seconds - 1);
    }
  };

  useEffect(() => {
    const timeout = setInterval(tick, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [tick]);

  return (
    <>
      <div className={styles['plus-loader']}>
        <img src={spinnerGIF} alt="loading animation" />
      </div>
      {beginPhrases && display && (
        <div className={styles['loading-phrase']} aria-live="polite">
          <p role="alert" aria-busy="true">
            {display}
          </p>
        </div>
      )}
    </>
  );
};

export default LoadingSpinner;

LoadingSpinner.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

LoadingSpinner.defaultProps = {
  location: {
    pathname: ''
  }
};
