/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
    ActionList,
    ActionListItem,

    //default pictograms
    Information56,
    Warning56,
    Error56,
    Delivery56,
    Mail56, 
    Membership56
  } from '@able/react';
  import "./productNotifications.scss"


  const getPictoFromType = type => {

    switch (type?.toLowerCase()) {
      case "info":
        return <Information56 />
      case "warning":
        return <Warning56 />
      case "error":
        return <Error56 />
      case "delivery":
        return <Delivery56 />
      case "mail":
        return <Mail56 />
      case "membership":
        return <Membership56 />
      default:
        return <Information56 /> 
    }
  }

  const generateListItem = productNotification => {
    const picto = productNotification.picto ? productNotification.picto : getPictoFromType(productNotification.type);
    return (
      <ActionListItem element='button'
                      Picto={picto}
                      pictoTheme="palette3"
                      label={productNotification.label}
                      description={productNotification.description}
                      events={productNotification.events?productNotification.events:{ 'aria-disabled': true }}
                      className={productNotification.events ? "tplus-product-notifications-item" : "tplus-product-notifications-item-no-event"}
                      
      />
    )
  }

const ProductNotifications = props => {
  const {
    notifications = [],
    // theme = "magenta"
  } = props

  // const productNotifications = [
  //   {
  //     label: "testLabel1",
  //     description: "TestDescription1",
  //     type: "info",
  //   },
  //   {
  //     label: "testLabel2",
  //     description: "TestDescription2",
  //     type: "info",
  //   },
  // ]

  //notificationExample = {
  //     label: "string",
  //     description: "string?",
  //     priority: "num?", //lower is higher priority (default 0)
  //     type: "info | error | warning | delivery",
  //     picto: "picto Object?" //override type,
  //       events: //native js event.
  //   }


  const renderNotifications = () => {

    return (
      <ActionList variant="Hero" topDivider={true}>
        {
          notifications
            .sort((a, b) => ((a.priority ? a.priority : 0) - (b.priority ? b.priority : 0)))
            .map(notif => {
              return (
                generateListItem(notif)
              )
            })
        }
      </ActionList>
    )
  }

  return (
      <>
        {renderNotifications()}
      </>
  )
}


export default ProductNotifications;