import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import AppLog from 'utils/AppLog';

// import NotForBusinessCustomers from 'views/NotEligibleViews/NotForBusinessCustomers';
import LimitedPermissions from 'views/NotEligibleViews/LimitedPermissions';
import NoActiveServices from 'views/NotEligibleViews/NoActiveServices';

import * as Analytics from 'utils/analytics';
import * as ERRCODES from 'constants/errorCodes.js';
import AssetUserPermissions from 'views/NotEligibleViews/AssetUser';
import UserRelationshipPermissions from 'views/NotEligibleViews/UserRelationship';

class NotEligible extends Component {
  constructor() {
    super();
    this.log = new AppLog('NotEligible');
  }

  renderDownStreamErrorCode = () => {
    const {
      error: {
        error: { message }
      }
    } = this.props;

    const errorMessageCheck = message.trim().replace(/ .*/, '');

    // Check if first word in the error message is Error
    // *Error codes will be displayed first, this accounts for scenarios where no Error code is returned.
    if (errorMessageCheck.includes('Error')) {
      return null;
    }
    // Collect all error codes up to the first whitespace and display them.
    return (
      <p>
        <small>
          <strong>Support code: </strong>
          {message.substr(0, message.indexOf(' '))}
        </small>
      </p>
    );
  };

  renderIneligibilityReason = () => {
    const {
      error: { errorStatusCode }
    } = this.props;

    this.log.debug('Error status code: ', errorStatusCode);

    switch (errorStatusCode) {
      case ERRCODES.ERR_NO_ACTIVE_CACS:
        Analytics.setPageName('Not Eligible - No active services');
        return (
          <NoActiveServices
            renderDownStreamErrorCode={() => this.renderDownStreamErrorCode()}
          />
        );
      case ERRCODES.ERR_NO_ACTIVE_CACSSOMESMBS:
        Analytics.setPageName(
          'Not Eligible - No active services OR Some Small Business'
        );
        return (
          <NoActiveServices
            renderDownStreamErrorCode={() => this.renderDownStreamErrorCode()}
          />
        );
      // case ERRCODES.ERR_ONLY_SMALL_BUSINESS_CACS:
      //   Analytics.setPageName('Not Eligible - Some Small Business');
      //   return (
      //     <NotForBusinessCustomers
      //       renderDownStreamErrorCode={() => this.renderDownStreamErrorCode()}
      //     />
      //   );
      case ERRCODES.ERR_USER_REL_ACCT_EXPIRED:
        Analytics.setPageName('Not Eligible - Some Small Business');
        return (
          <UserRelationshipPermissions
            renderDownStreamErrorCode={() => this.renderDownStreamErrorCode()}
          />
        );
      case ERRCODES.ERR_LA_CAC:
        Analytics.setPageName('Not Eligible - Limited Authority');
        return (
          <LimitedPermissions
            renderDownStreamErrorCode={() => this.renderDownStreamErrorCode()}
          />
        );
      case ERRCODES.ERR_ASSET_USER:
        Analytics.setPageName('Not Eligible - Limited Authority/Asset User');
        return (
          <AssetUserPermissions
            renderDownStreamErrorCode={() => this.renderDownStreamErrorCode()}
          />
        );
      default:
        Analytics.setPageName('Not Eligible - Generic screen');
        return <Navigate to="/error" />;
    }
  };

  render() {
    return (
      <Fragment>
        <div className="page container">
          <div className="clear-div-m" />
          {this.renderIneligibilityReason()}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  error: state.error
});

NotEligible.defaultProps = {
  error: {}
};

export default connect(mapStateToProps)(NotEligible);
