import { LOGGER } from 'utils/AppLog';
import * as actions from 'constants/actions';

import getNowShowing from 'api/getNowShowing';

const fetchMovies = () => {
  return dispatch => {
    dispatch({
      type: actions.NOW_SHOWING_PENDING
    });

    return getNowShowing()
      .then(response => {
        LOGGER.debug('NowSHowing Response: ', response);

        dispatch({
          type: actions.NOW_SHOWING_SUCCESS,
          response
        });

        dispatch({
          type: actions.SET_NOW_SHOWING,
          keyData: response
        });
      })
      .catch(error => {
        LOGGER.debug('Caught nowShowing error: ', error);
        dispatch({ type: actions.NOW_SHOWING_FAILURE });
      });
  };
};

export default fetchMovies;
