// Action Types, Describe what is happening
import _ from 'lodash';

import { SET_NOW_SHOWING } from 'constants/actions';

const defaultNowShowing = {
    movies: []
}

export const nowShowing = (nowShowingState = defaultNowShowing, action) => {
    switch (action.type) {
      case SET_NOW_SHOWING:
        if (_.has(action.keyData, 'Data')) {
            const { Data } = action.keyData;
            return { movies: Data.Movies };
          }
          return { ...nowShowingState };
        default:
            return nowShowingState;
    }
};