import React from 'react';

export default function IconNumberTwo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 32 32"
      id="icon-number-two"
    >
      <title>Step 2</title>
      <path d="M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16 9.4 4 16 4zm0-1.333C8.6 2.667 2.667 8.6 2.667 16S8.6 29.333 16 29.333 29.333 23.4 29.333 16 23.4 2.667 16 2.667z"></path>
      <path d="M19 22c.4 0 .733.333.733.733s-.267.733-.733.733h-5.933c-.4 0-.733-.267-.733-.667v-.267c0-.2.067-.4.133-.533l5.067-8.2c.267-.4.467-.933.467-1.533v-.067c0-1.333-.867-2.2-2.067-2.2h-.133c-1.267 0-1.933.933-1.933 2v.133c0 .467-.4.733-.8.733s-.8-.267-.8-.733v-.133c0-1.867 1.533-3.467 3.467-3.467h.133c1.867 0 3.6 1.4 3.6 3.667v.067c0 .867-.467 1.867-.8 2.333l-4.533 7.333h4.867z"></path>
    </svg>
  );
}
