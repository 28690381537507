import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionButton } from '@able/react';
import InteractiveElement from '_partials/InteractiveElement';
import { signIn } from 'oidc/initiateOidc';
import { InlineLoadingSpinner } from 'components';
import { getPartnerJWT, getPartnerOauth } from 'actions/partners';
import { goToCompetition } from 'actions/competitions';
import { Base64 } from 'js-base64';
import { checkExternalLink } from 'utils';
import { addLinkClickEvent } from 'utils/analytics';
import { getBusinessCta } from 'selectors/utils/GetBusinessCta';
import { PARTNERS } from 'constants/externalPartner';
import { buyMovieTicket } from 'api/getTickets';

const Cta = props => {
  const { action = '', trackingCode, url, ctaProps = {}, children} = props;

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.appStatus.authenticated);
  const tokenPending = useSelector(state => state.partners.getTokenPending);
  const [ctaActioned, setCtaActioned] = useState(false);
  const businessCta = useSelector(state => getBusinessCta(state));

  const actionButtonDefaultProps = {
    ...(!ctaProps?.variant && { variant: 'HighEmphasis' }),
    ...(!ctaProps?.target && { target: '_self' })
  };
  const buttonActionProps = checkExternalLink(url, ctaProps.target);

  const renderButton = buttonProps => {
    const { noButton = false, ...other } = buttonProps;
    if (noButton) {
      return (
        <InteractiveElement {...other} {...buttonActionProps}>
          {children}
        </InteractiveElement>
      );
    }
    return (
      <ActionButton
        {...buttonProps}
        {...actionButtonDefaultProps}
        {...buttonActionProps}
        developmentUrl="/able-sprites.svg"
      />
    );
  };

  const partnerRedirectLink = callback => {
    // no more disabled state need to test what happens

    return (
      <>
        {ctaActioned && <InlineLoadingSpinner smallSpinner loadingText="" />}
        {!ctaActioned && (
          <>
            {renderButton({
              ...ctaProps,
              events: {
                onClick: e => {
                  e.preventDefault();
                  // probably have to have some kind of indicator that something is happening?
                  if (!tokenPending) {
                    if (trackingCode) addLinkClickEvent(trackingCode);
                    callback();
                  }
                }
              }
            })}
          </>
        )}
      </>
    );
  };

  const dispatchAfterSignIn = (partneraction, data, path) => {
    localStorage.setItem(
      'tplus-flow',
      Base64.encode(
        JSON.stringify({
          action: partneraction,
          data
        })
      )
    );
    signIn({ url: path });
  };


  const jwtClickAction = (partner, path, queries) => {
    if (isAuthenticated) {
      return () => {
        setCtaActioned(true);
        dispatch(getPartnerJWT(partner, queries));
      };
    }
    return () => {
      dispatchAfterSignIn('getPartnerJWT', [partner, queries], path);
    };
  };

  const partnerClickAction = (partner, path, queries) => {
    if (isAuthenticated) {
      return () => {
        setCtaActioned(true);
        dispatch(buyMovieTicket(queries));
      };
    }
    return () => {
      dispatchAfterSignIn('buyMovieTicket', [queries, partner], path);
    };
  };

  const oAuthClickAction = (partner, path, queries) => {
    if (isAuthenticated) {
      return () => {
        setCtaActioned(true);
        dispatch(getPartnerOauth(partner, queries, path));
      };
    }
    return () => {
      dispatchAfterSignIn('getPartnerOauth', [partner, queries, path], path);
    };
  };

  const competitionClickAction = (partner, path, queries) => {
    if (isAuthenticated) {
      return () => {
        setCtaActioned(true);
        dispatch(goToCompetition(partner, queries, path));
      };
    }
    return () => {
      dispatchAfterSignIn('goToCompetition', [partner, queries, path], path);
    };
  };

  const RenderCta = () => {
    // Split out action type (in case we are passing in extra pipe separated values)
    const actionArr = action.split('|');
    const [actionId, partner] = actionArr;
    switch (actionId) {
      case 'basic':
      case 'externalUrl': // decommissioned
      case 'spaRoute': {
        // decommissioned
        return renderButton({
          ...ctaProps,
          ...(trackingCode && {
            events: {
              onClick: () => addLinkClickEvent(trackingCode)
            }
          })
        });
      }
      case 'signin': {
        // have the ability to pass in a path to signin. so they can goto a different path after signing in.
        return renderButton({
          ...ctaProps,
          events: {
            onClick: e => {
              e.preventDefault();
              addLinkClickEvent('login');
              signIn(url && { url });
            }
          }
        });
      }
      case 'redirectToHuddle': {
        return partnerRedirectLink(
          jwtClickAction('huddle', '/benefits/huddle', {
            ...PARTNERS.huddle?.utmTags
          })
        );
      }
      case 'redirectToHuddleCar': {
        return partnerRedirectLink(
          jwtClickAction('huddle', '/benefits/huddle-car', {
            pc: 'hud-compare-car',
            utm_content: `{"product":"car"}`,
            ...PARTNERS.huddle?.utmTags
          })
        );
      }
      case 'redirectToHuddleHome': {
        return partnerRedirectLink(
          jwtClickAction('huddle', '/benefits/huddle-home', {
            pc: 'hud-compare-home',
            utm_content: `{"product":"home"}`,
            ...PARTNERS.huddle?.utmTags
          })
        );
      }
      case 'redirectToVillageHome': {
        return partnerRedirectLink(
          partnerClickAction('village', `/villagecinemas`, {
            partnerId: PARTNERS.village.apiProps.partnerId
          })
        );
      }
      case 'redirectToEventHome': {
        return partnerRedirectLink(
          partnerClickAction('event', '/eventcinemas', {
            partnerId: PARTNERS.events.apiProps.partnerId
          })
        );
      }
      case 'redirectToOauth': {
        // Check if there is an authored partner url here
        // The partner oauth flow requires a url to redirect to once token has been generated
        // how to handle if no partner url authored

        return partnerRedirectLink(
          oAuthClickAction(partner, `/benefits/${partner}`, {
            redirect_uri: url
          })
        );
      }
      case 'businessTechService': {
        // upgrade this
        return <>{businessCta}</>;
      }
      case 'goToCompetition': {
        // its not actually a partner, its a comp, perhaps rename this field to something more generic
        return partnerRedirectLink(
          competitionClickAction(partner, `/benefits/${partner}`, {
            redirect_uri: url
          })
        );
      }
      default:
        return null;
    }
  };

  return <>{RenderCta()}</>;
};

export default Cta;
