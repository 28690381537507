import { createSelector } from 'reselect';
import _ from 'lodash';

import { getDataByPage } from './utils/GetDataByPage';

// get member tier
const getTier = state => state.accounts.loyaltyTier;
// get aem promos
const getHeaders = state => getDataByPage(state).headers;

// grab and sort heading titles
export const getHeadingTitleState = createSelector(
  [getHeaders, getTier],
  (headers, tier) => {
    // get big promos that are active and set to position 1
    const sortedHeadingTitles = _.filter(headers, {
      type: 'headingtitle',
      active: 'true'
    });

    // Should only ever be one heading title for the page
    // If there is ever any discrepency here check for multiple activated heading titles in AEM
    const [firstHeadingTitle] = sortedHeadingTitles;

    const headingTitleArr = _.filter(firstHeadingTitle?.tiers, {
      tier
    });

    const [heading] = headingTitleArr;

    if (heading) {
      // Create list of images
      const imgArr = _.pickBy(heading, (value, key) => {
        return (
          key === 'imagelarge' ||
          key === 'imagemedium' ||
          key === 'imagesmall' ||
          key === 'imagemini'
        );
      });

      // Remove image propeties to make object cleaner
      const strippedHeader = _.omit(heading, [
        'imagelarge',
        'imagemedium',
        'imagesmall',
        'imagemini'
      ]);

      // Add newly created image object to images property
      strippedHeader.images = imgArr;

      return strippedHeader;
    }
    return heading;
  }
);
