import { Base64 } from 'js-base64';
import * as actions from 'constants/actions';
import { buyMovieTicket, buyEventTicket } from 'api/getTickets';
import { getPartnerJWT, getPartnerOauth } from 'actions/partners';
import { goToCompetition } from 'actions/competitions';

const actionList = {
  buyEventTicket,
  buyMovieTicket,
  getPartnerJWT,
  getPartnerOauth,
  goToCompetition
};

export const handleRedirectionFlow = flow => {
  return dispatch => {
    if (flow) {
      const { data, action } = flow;
      if (data && action) {
        const actionFromList = actionList[action];
        if (actionFromList) {
          dispatch(actionFromList(...data));
        }
      }
    }
  };
};

export const setFlowObject = encodedjson => {
  return dispatch => {
    localStorage.removeItem('tplus-flow');
    let flowJson;
    try {
      const encodedjson64 = Base64.decode(encodedjson);
      flowJson = JSON.parse(encodedjson64);
      dispatch({
        type: actions.SET_FLOW,
        payload: flowJson
      });
    } catch (e) {
      flowJson = undefined;
    }
  };
};
