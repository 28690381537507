import {
  GET_PARTNER_TOKEN_PENDING,
  GET_PARTNER_TOKEN_FAILURE,
  GET_PARTNER_TOKEN_SUCCESS
} from 'constants/actions'; 

const initialState = {
  getTokenPending: false,
  partnerId: "",
  url: ""
}

export const partners = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTNER_TOKEN_PENDING:
      return {
        ...state,
        getTokenPending: true
      };
    case GET_PARTNER_TOKEN_FAILURE: 
      return {
        ...state,
        getTokenPending: false
      }
    case GET_PARTNER_TOKEN_SUCCESS:
      return {
        ...state,
        getTokenPending: false,
        url: action.data.url,
        partnerId: action.data.partnerId
      }
    default:
      return state;
  }
};
