import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { SubHeader } from 'components';
import { HowItWorksAuthView, HowItWorksUnAuthView } from 'views';
import { useNavigate } from 'react-router-dom';

import { Grid } from '@able/react';

import * as Analytics from 'utils/analytics';
import { LOGGER } from 'utils/AppLog';

import { getAemContent } from 'selectors/GetAemContent';

const HowItWorksContainer = () => {
  useEffect(() => {
    LOGGER.debug('How it works Container mounted');
    Analytics.setPageName('How it works Page');
  }, []);

  const history = useNavigate();
  const aemReady = useSelector(state => state.aem.ready);
  const isAuthenticated = useSelector(state => state.appStatus.authenticated);
  const [subHeaderConfig] = useSelector(state =>
    getAemContent(state, 'howitworks/unauth/subheader', 'subheader')
  );

  useEffect(() => {
    const redirectObject = {
      pathname: '/howitworks'
    };
    history(redirectObject);
  }, []);

  return (
    <>
      {aemReady && (
        <Grid className="container">
          {!isAuthenticated && subHeaderConfig && (
            <div className="container">
              <SubHeader
                {...subHeaderConfig}
              />
            </div>
          )}
          {isAuthenticated ? <HowItWorksAuthView /> : <HowItWorksUnAuthView />}
        </Grid>
      )}
    </>
  );
};

export default HowItWorksContainer;
