import { batch } from 'react-redux';
import { LOGGER } from 'utils/AppLog';
import find from 'lodash/find';
import getEntryDetailsApi from 'api/getEntryDetails';
import * as actions from 'constants/actions';
import { getSmbEntryDetailsPending } from 'reducers/smb';
import { getAemContent } from 'selectors/GetAemContent';

export const getSmbEntryDetails = opts => {
  return (dispatch, getState) => {
    if (getSmbEntryDetailsPending(getState())) {
      LOGGER.info('API Response still pending, so not call the API');
      return Promise.resolve();
    }

    dispatch({
      type: actions.SMB_ENTRY_DETAILS_PENDING,
      payload: true
    });

    return getEntryDetailsApi(opts)
      .then(response => {
        dispatch({
          type: actions.SMB_ENTRY_DETAILS_PENDING,
          payload: false
        });
        LOGGER.debug('SMB: Get entry details API Response', response);
        const { winningStatus } = response?.data?.data;

        return dispatch({
          type: actions.SET_SMB_ENTRY_RESPONSE,
          entry: {
            status: winningStatus
          }
        });
      })
      .catch(error => {
        batch(() => {
          dispatch({
            type: actions.SMB_ENTRY_DETAILS_PENDING,
            payload: false
          });
          dispatch({
            type: actions.SET_SMB_ENTRY_ERROR,
            entryErrored: true,
            error
          });
        });
        throw error;
      });
  };
};

export const fetchSmbEntryDetails = () => {
  return (dispatch, getState) => {
    const state = getState();
    const getConfigs = getAemContent(state, 'global/config', 'toggleconfig');
    const { entryErrored } = state.smb;
    const { accountId } = state.accounts;

    const aemBusinessToggle = find(getConfigs, {
      parentattribute: 'smbcta'
    });

    // If we have enabled CTA from AEM
    if (aemBusinessToggle?.isParentActivated !== 'false' && !entryErrored) {
      dispatch(
        getSmbEntryDetails({
          'account-id': accountId,
          'campaign-name': 'SMB TTS Benefit'
        })
      );
      // If entry is in error then getBusinessCta will show an error
    }
  };
};
