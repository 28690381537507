/**
 * Returns a map of children as key and the ariaHidden value as value
 */
const storeCurrentBodyChildrenAriaHiddenValue = () => {
  const { body } = document;
  const elementsAttributesMap = new Map();
  const bodyChildren = Array.from(body.children);
  bodyChildren.forEach((child) => {
    if (child.nodeName !== 'SCRIPT') {
      const childIsAriaHidden = child.ariaHidden;
      elementsAttributesMap.set(child, childIsAriaHidden);
    }
  });
  return elementsAttributesMap;
};

export default storeCurrentBodyChildrenAriaHiddenValue;
