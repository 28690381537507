import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TextStyle, ActionButton } from '@able/react';
import { useNavigate, useParams } from 'react-router-dom';
import { HtmlConverter } from 'utils/HtmlConverter';
import dayjs from 'dayjs';
import parseZone from 'utils/parseZone';

import './SingleEventView.scss';
import * as Analytics from 'utils/analytics';
import { PresaleCountdown } from '../PresaleCountdown/PresaleCountdown';

dayjs.extend(parseZone);

const SingleEventView = ({ performance }) => {
  const [count, setCount] = useState(0);
  const history = useNavigate();
  const currDate = dayjs();
  const performanceSaleStartDate = dayjs(performance.performanceSaleStartDate);
  const preSale = performanceSaleStartDate > currDate;
  const ctaError =
    performance.availability === 'Allocation exhausted' || preSale;

  const isAuthenticated = useSelector(state => state.appStatus.authenticated);
  const { genre, id } = useParams();

  const buyTicket = performanceId => {
    Analytics.addEvent({
      eventName: `${isAuthenticated ? 'Auth' : 'Unauth'} Tickets - GetTickets`,
      eventAction: 'click',
      eventType: 'clickTrack',
      eventCategory: 'linkClick'
    });
    history(`/tickets/buy/${genre}/${id}/${performanceId}`);
  };

  const renderPerformanceDate = () => {
    if (performance.eventPerformanceEndDate) {
      return `${dayjs
        .parseZone(performance.eventPerformanceDate)
        .format('ddd D MMM Y h:mm A')} to ${dayjs
        .parseZone(performance.eventPerformanceEndDate)
        .format('ddd D MMM Y h:mm A')}`;
    }
    return dayjs.parseZone(performance.eventPerformanceDate).format('ddd D MMM Y h:mm A');
  };

  const renderComponent = () => {
    setCount(Math.random());
  };

  useEffect(() => {
    // Refreshing Component when count getting updated.
  }, [count]);

  return (
    <div className="event-Summary">
      <div className="event-tile">
        <TextStyle alias="Label" element="span" className="title">{HtmlConverter(performance.venueName)}</TextStyle>
        <TextStyle alias="Label" element="span" className="date">{renderPerformanceDate()}</TextStyle>
        <TextStyle alias="FinePrint" element="span" role="timer">
          {preSale ? (
            <PresaleCountdown
              renderComponent={renderComponent}
              preDate={performance.performanceSaleStartDate}
              tier="member"
            />
          ) : (
            `${performance.availability}`
          )}
        </TextStyle>
      </div>
      {!ctaError && (
        <ActionButton
          variant="LowEmphasis"
          element="button"
          label="Get Tickets"
          className="button-tplus"
          onClick={() => buyTicket(performance.id)}
        ></ActionButton>
      )}
    </div>
  );
};

export default SingleEventView;
