import React, { useEffect, useState } from 'react';

const Accordion = ({
  id = '',
  heading = '',
  expanded = false,
  closeItem = false,
  onChange,
  children
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = () => {
    setIsOpen(!isOpen);
    if (onChange) {
      const event = {
        isOpen: !isOpen
      };
      onChange(event);
    }
  };

  useEffect(() => {
    if (expanded) {
      setIsOpen(true);
    }
  }, [expanded]);

  useEffect(() => {
    if (closeItem) {
      setIsOpen(false);
    }
  }, [closeItem]);

  return (
    <div className="tplus-accordion-group__item">
      <div className="tplus-accordion-group__heading">
        <button
          type="button"
          onClick={() => handleChange()}
          className={`heading-16 tplus-accordion-group__item__title ${
            isOpen ? 'tplus-accordion-group__item__title--active' : ''
          }`}
          id={id}
          aria-expanded={isOpen}
          aria-controls={`${id}-content`}
        >
          <h3>{heading}</h3>
          <svg
            className="icon-chevron-down"
            role="img"
            aria-hidden="true"
            focusable="false"
          >
            <use href="/able-sprites.svg#ChevronDown"></use>
          </svg>
        </button>
      </div>
      <div
        id={`${id}-content`}
        className={`tplus-accordion-group__item__content ${
          isOpen ? 'tplus-accordion-group__item__content--visible' : ''
        }`}
        aria-hidden={!isOpen}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
