import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { HtmlConverter } from 'utils/HtmlConverter';

import IconArrow from 'icons/IconArrow';

export default class TabContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      hidden: true
    };
  }

  componentDidUpdate(prevProps) {
    this.updateHeight(prevProps);
  }

  updateHeight = prevProps => {
    const { open } = this.props;

    if (open !== prevProps.open) {
      // get scroll height
      if (open === true) {
        this.setState({
          hidden: false
        });

        setTimeout(() => {
          this.setState({
            height: this.contentEl.scrollHeight
          });
        }, 10);
      } else {
        this.setState({
          height: 0
        });

        setTimeout(() => {
          this.setState({
            hidden: true
          });
        }, 500);
      }
    }
  };

  render() {
    const {
      content,
      internallink,
      internallinktext,
      id,
      refererId
    } = this.props;
    const { height, hidden } = this.state;
    return (
      <div
        className="vertical-tab-content"
        style={{ height }}
        ref={contentEl => {
          this.contentEl = contentEl;
        }}
        role="region"
        id={id}
        aria-labelledby={refererId}
        hidden={hidden ? 'hidden' : null}
      >
        <div className="parbase">{HtmlConverter(content)}</div>
        {internallink && (
          <Link
            role="button"
            className="primary-cta-18 go-to-thanks"
            to={internallink}
          >
            {internallinktext} <IconArrow />
          </Link>
        )}
      </div>
    );
  }
}

TabContent.propTypes = {
  id: PropTypes.string.isRequired,
  refererId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  content: PropTypes.string,
  internallink: PropTypes.string,
  internallinktext: PropTypes.string
};

TabContent.defaultProps = {
  refererId: '',
  content: '',
  internallink: '',
  internallinktext: ''
};
