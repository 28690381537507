import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as Analytics from 'utils/analytics';

import EnrolmentView from 'views/EnrolmentView/EnrolmentViewV2';

// OIDC
import { signIn } from 'oidc/initiateOidc';
// import EnrolmentView from 'views/EnrolmentView/EnrolmentView';

// eslint-disable-next-line consistent-return
const Enrolment = () => {
  const isAuthenticated = useSelector(state => state.appStatus.authenticated);

  useEffect(() => {
    Analytics.setPageName('Enrolment - Join Page');
    Analytics.addEvent({
      eventAction: 'loyaltyEnrolmentView'
    });
  }, []);

  if (isAuthenticated) {
    return <EnrolmentView />;
  } else {
    return signIn();
  }
};

export default Enrolment;
