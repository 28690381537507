import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { addLinkClickEvent } from 'utils/analytics';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import './Breadcrumbs.scss';

const Breadcrumbs = ({ customPathname }) => {
  const fullLocationPath = useSelector(state => state.appStatus.fullLocationPath);
  const aem = useSelector(state => state.aem.all);

  const removeEndPath = pathname => {
    const pathArr = pathname.split('/');
    pathArr.pop();
    return pathArr.join('/');
  };

  const getPageName = (pathArr, targetLevel) => {
    // eslint-disable-next-line consistent-return
    const findPath = (obj, curLevel) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const prop in obj) {
        if (prop === pathArr[curLevel]) {
          if (curLevel === targetLevel) {
            if (obj[prop].pageconfig) {
              return obj[prop].pageconfig.pageName;
            }
          } else {
            return findPath(obj[prop], curLevel + 1);
          }
        }
      }
    };
    return findPath(aem, 0);
  };

  // Function that allows the path to use a different name, seperated by the :
  const parseName = pathItem => {
    if (pathItem.indexOf(':') !== -1) {
      const name = pathItem.split(':')[1];
      return _.startCase(name);
    }
    return _.startCase(pathItem);
  };

  const Trails = ({ pathname }) => {
    const pathArr = pathname.split('/');

    if (pathname === '/') {
      return null;
    }
    if (pathArr[0] === '') {
      pathArr.shift();
    }

    return pathArr.map((pathItem, i) => {
      if (pathItem === 'auth' || pathItem === 'unauth') {
        return;
      }
      const to = () => {
        let toStr = '';
        for (let j = 0; j <= i; j+=1) {
          if (pathArr[j] !== 'auth' && pathArr[j] !== 'unauth') {
            toStr += `/${pathArr[j].split(':')[0]}`;
          }
        }
        return toStr;
      };
      const name = getPageName(pathArr, i) || parseName(pathItem);
      // eslint-disable-next-line consistent-return
      return <ListItem key={i} to={to()} name={name} position={i + 2} />;
    });
  };

  const ListItem = ({ to, name, position }) => {
    return (
      <li
        className="tplus-breadcrumbs-item"
        itemType="http://schema.org/ListItem"
        itemProp="itemListElement"
      >
        <span className="breadcrumb-decorative-text-before" aria-hidden="true">
          &#60;
        </span>
        <Link
          to={to}
          itemProp="item"
          onClick={() => addLinkClickEvent(`breadcrumb ${name}`)}
        >
          <span itemProp="name">{name}</span>
        </Link>
        <meta itemProp="position" content={position}></meta>
        <span className="breadcrumb-decorative-text-after" aria-hidden="true">
          &gt;
        </span>
      </li>
    );
  };

  return (
    <div className="tplus-breadcrumbs">
      <nav aria-label="Breadcrumb">
        <ol
          className="tplus-breadcrumbs-list"
          itemScope
          itemType="http://schema.org/BreadcrumbList"
        >
          <ListItem to="/" name="Telstra Plus" position="1"/>
          <Trails pathname={customPathname || removeEndPath(fullLocationPath)} />
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumbs;

Breadcrumbs.propTypes = {
  customPathname: PropTypes.string
};

Breadcrumbs.defaultProps = {
  customPathname: ''
};
