import { LOGGER } from 'utils/AppLog';
import getOrders from 'api/getOrders';
import { getOrdersPending } from 'reducers/onePlace';
import * as actions from 'constants/actions';
import * as ERRCODES from 'constants/errorCodes.js';

const fetchOrders = () => {
  return (dispatch, getState) => {
    if (getOrdersPending(getState())) {
      return Promise.resolve();
    }

    dispatch({ type: actions.GET_ORDERS_PENDING, getOrdersPending: true });

    return getOrders()
      .then(response => {
        LOGGER.debug('Get Orders API Response', response);
        dispatch({
          type: actions.GET_ORDERS_SUCCESS,
          orders: response.data.data,
          getOrdersApiResponse: response
        });
      })
      .then(() => {
        LOGGER.debug('Get Orders API Call Finished');
        dispatch({ type: actions.GET_ORDERS_PENDING, getOrdersPending: false });
      })
      .catch(error => {
        switch (error.response.data.code) {
          case ERRCODES.ERR_CONNABORTED:
          case ERRCODES.ERR_OP_OFFERS_503:
            dispatch({
              type: actions.GET_ORDERS_FAILURE,
              errorObject: {
                status: 503
              }
            });
            break;
          case ERRCODES.ERR_OP_OFFERS_404:
            dispatch({
              type: actions.GET_ORDERS_FAILURE,
              errorObject: {
                status: 404
              }
            });
            break;
          default:
            LOGGER.debug('Get Offers Error-ed out', error.stack);
            dispatch({
              type: actions.GET_ORDERS_FAILURE,
              errorObject: error.response
            });
        }
      });
  };
};

export default fetchOrders;
