import React from 'react';
import { MessageInline as MessageInlineAble } from '@able/react';

const MessageInline = props => {
  const { description, variant } = props;
  return (
    <MessageInlineAble
      description={description}
      variant={variant}
      developmentUrl="/able-sprites.svg"
    />
  );
};

export default MessageInline;
