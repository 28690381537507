import { LOGGER } from 'utils/AppLog';
import setContactDetails from 'api/setContactDetails';
import * as actions from 'constants/actions';

const updateContactDetails = (opts, status) => {
  return dispatch => {
    dispatch({ type: actions.SET_LOADING, loading: true });

    return setContactDetails(opts, status)
      .then(response => {
        LOGGER.debug('Update details successful', response);
        dispatch({ type: actions.SET_LOADING, loading: false });
        // This clears any pre-existing LOA flow options like open modals etc
        // Currently LOA options picked up by OTP
        dispatch({
          type: actions.SET_LOAFLOWSTATUS,
          payload: { opts: {} }
        });
        dispatch({
          type: actions.SET_UPDATECONTACTDETAILS_ERROR,
          payload: { code: null }
        });
        dispatch({
          type: actions.SET_UPDATECONTACTDETAILS_SUCCESS,
          payload: true
        });
      })
      .catch(error => {
        dispatch({ type: actions.SET_LOADING, loading: false });
        dispatch({
          type: actions.SET_UPDATECONTACTDETAILS_ERROR,
          payload: error?.response?.data
        });
        throw error;
      });
  };
};

export default updateContactDetails;
