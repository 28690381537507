/* eslint-disable */

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _Skeleton = _interopRequireDefault(require("./Skeleton"));

require("./skeleton.css");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = _Skeleton["default"];
exports["default"] = _default;