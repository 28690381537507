import React from 'react';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { tierChecker } from 'utils/tierChecker';
import { roleChecker } from 'utils/roleChecker';
import { setBaseUrl } from 'utils/setBaseUrl';
import componentMapping from 'components/componentMapping';
import { renderedContent } from 'actions/experience';
import store from '../store';

const getTier = state => state.accounts.loyaltyTier;
const experienceContent = state => state.experience;
const getRole = state => state.accounts.role;

// grab the current page data from the store.
const getPageContent = (state, path) => {
  if (path.charAt(0) === '/') {
    // eslint-disable-next-line no-param-reassign
    path = path.substring(1);
  }
  const pathArr = path.split('/');
  if (pathArr.length > 0) {
    let returnVal = state.aem.all;
    _.forEach(pathArr, dir => {
      returnVal = returnVal[dir] ? returnVal[dir] : {};
    });
    return returnVal;
  }
  return {};
};

const getComponentByPath = (state, path) => {
  const newPath = path.replace('/content/tcom/tplus/', '').replace('/jcr:content', '');
  const newPathArr = newPath.split('/');
  const aemState = state?.aem?.all;
  let componentObj = aemState;
  let found = false;
  newPathArr.forEach((p, i) => {
    if (componentObj[p]) {
      if (i === newPathArr.length - 1) {
        found = true;
      }
      componentObj = componentObj[p];
    }
  });
  return found ? componentObj : false;
};

const ResponsiveColumn = props => {
  const {
    'cq:responsive': responsive = null,
    defaultSpacingMd = '',
    defaultSpacingXs = '',
    spacingMd = '',
    spacingXs = '',
    className = '',
    ElementTag = 'div',
    ColumnTag = 'div',
    children,
    ...other
  } = props;

  // const ColumnTag = ElementTag === 'ul' || ElementTag === 'ol' ? 'li' : 'div';

  let spacingMdClass = '';
  let spacingXsClass = '';
  let childTag = 'div';

  // Configure column classes
  // Temp workaround as Able Columns cant change element tag
  const bsm = responsive?.default?.width
    ? ` bsm-cols-${responsive?.default?.width}`
    : '';
  const bsmOffset = responsive?.default?.offset
    ? ` bsm-offset-${responsive?.default?.offset}`
    : '';
  const vxs = responsive?.phone?.width
    ? ` vxs-cols-${responsive?.phone?.width}`
    : '';
  const vxsOffset = responsive?.phone?.offset
    ? ` vxs-offset-${responsive?.phone?.offset}`
    : '';

  // Configure spacing classes
  if (spacingMd || defaultSpacingMd) {
    spacingMdClass = ` tplus-bottom-md__${spacingMd || defaultSpacingMd}`;
  }
  if (spacingXs || defaultSpacingXs) {
    spacingXsClass = ` tplus-bottom__${spacingXs || defaultSpacingXs}`;
  }
  if (ElementTag === 'ul' || ElementTag === 'ol') {
    childTag = 'li';
  }

  return (
    <ColumnTag
      className={`able-column tplus-jego-component${spacingMdClass}${spacingXsClass}${bsm}${bsmOffset}${vxs}${vxsOffset} ${className}`}
    >
      {React.cloneElement(children, { ...other, ColumnTag: childTag })}
    </ColumnTag>
  );
};

const getComponentProps = (component, expContent) => {
  // Set the correct image urls
  const props = setBaseUrl(component);
  const { id, active: activeProp } = props;
  let active = activeProp;
  // Check if there's any experience api content destined for this component.
  // It has to match the components id.
  // If it does, return those props instead.
  const expProps = id && expContent ? expContent[id] : null;
  if (expProps) {
    active = true;
    if (!expProps.rendered) {
      // Let the experience api know that the content got rendered.
      store.dispatch(renderedContent(id));
    }
  }

  return { ...props, ...expProps, active };
};

// A container has active components if:
// The container has children components with the active = true attribute,
// or if there is experience api content found with matching id.
const hasActiveComponents = (components, expContent) => {
  if (components) {
    return Object.keys(components).some(c => {
      const { id, active } = components[c];
      return active || expContent[id];
    });
  }
  return false;
};

export const getReactComponents = (components, tier, role, expContent) => {
  const sorted = [];
  _.forOwn(components, (component, key) => {
    if (typeof component === 'object') {
      const { type } = component;
      if (componentMapping[type]) {
        const {
          component: reactComp,
          properties: defaultProperties = {}
        } = componentMapping[type];
        // Check if component has any tier or role exlusivity
        const validTier = tierChecker(component, tier);
        const validRole = roleChecker(component, role);
        const props = getComponentProps(component, expContent);
        if (validTier && validRole && props.active) {
          // Check if the component is a container type
          const isContainer = type.toLowerCase().indexOf('container') !== -1;
          if (isContainer) {
            const containerComps = component['par-grid'];
            // Don't need to render the container if it contains nothing.
            // Do render the container if it has a cta configured.
            const hasActiveChildren = hasActiveComponents(
              containerComps,
              expContent
            );
            if (hasActiveChildren || component.ctaText) {
              const container = (
                <ResponsiveColumn
                  {...component}
                  {...defaultProperties}
                  key={key}
                >
                  {React.createElement(
                    reactComp,
                    { ...component, key, ...props },
                    getReactComponents(containerComps, tier, role, expContent)
                  )}
                </ResponsiveColumn>
              );
              sorted.push(container);
            }
          } 
          else if (type === 'include') {
            sorted.push(React.createElement(reactComp, { ...props, key }))
          }
          else {
            sorted.push(
              <ResponsiveColumn {...props} {...defaultProperties} key={key}>
                {React.createElement(reactComp, { ...props, key })}
              </ResponsiveColumn>
            );
          }
        }
      }
    }
  });
  return sorted;
};

const getComponents = (s, c) => c;

export const getJegoComponents = createSelector(
  [getComponents, getTier, getRole, experienceContent],
  (components, tier, role, expContent) => {
    return getReactComponents(components, tier, role, expContent);
  }
);

export const getReferenceComponent = createSelector(
  [getComponentByPath, getTier, getRole, experienceContent],
  (component, tier, role, expContent) => {
    return getReactComponents([component], tier, role, expContent);
  }
);

export const getChildComponents = createSelector(
  [getPageContent, getTier, getRole, experienceContent],
  (pageContent, tier, role, expContent) => {
    const Components = {
      headerContent: [],
      bodyContent: [],
      pageConfig: {}
    };
    _.forOwn(pageContent, (section, key) => {
      switch (key) {
        case 'pageconfig': {
          Components.pageConfig = section;
          break;
        }
        case 'headercontent': {
          // Sometimes AEM likes to add an extra par-header object layer.
          const content = section['par-header']
            ? section['par-header']
            : section;
          Components.headerContent = getReactComponents(
            content,
            tier,
            role,
            expContent
          );
          break;
        }
        case 'bodycontent': {
          const content = section['par-body'] ? section['par-body'] : section;
          Components.bodyContent = getReactComponents(
            content,
            tier,
            role,
            expContent
          );
          break;
        }
        default: {
          break;
        }
      }
    });
    return Components;
  }
);
