import React from 'react';
import { Grid, Spacing, Column, ActionButton, TextStyle } from '@able/react';
import * as Analytics from 'utils/analytics';
import { Helmet } from 'react-helmet';

const SuccessfulOptOut = () => {
  Analytics.setPageName('Opt Out - Success');
  Analytics.addEvent({
    eventAction: 'loyaltyOptOut'
  });
  return (
    <>
    <Helmet>
      <title>Your Telstra Plus membership has been cancelled</title>
    </Helmet>
    <Grid className="container">
      <Column>
        <Spacing bottom="spacing7x" />
        <TextStyle element="h1" alias="HeadingB">
          Sorry to see you go
        </TextStyle>
        <Spacing bottom="spacing4x" />
        <TextStyle element="p" alias="Base">
          Your Telstra Plus membership has been cancelled.
        </TextStyle>
        <TextStyle element="p" alias="Base">
          If you wish to reactivate your account, you can do so anytime.
        </TextStyle>
        <Spacing bottom="spacing4x" />
        <ActionButton
          element="a"
          href="https://www.telstra.com.au/"
          label="Back to Home"
          variant="LowEmphasis"
        />
        <Spacing bottom="spacing10x" />
      </Column>
    </Grid>
    </>
  );
};

export default SuccessfulOptOut;
