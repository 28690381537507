import axios from './util/axiosHandle';
import todaysDate from './util/dateHelper';
import { apiConfig as api } from '../oidc/oidc-configs';

// This is kinda cheating but meh (going for the retrieve all approach)
const LOYALTY_PROGRAM_START_DATE = '2019-03-28';

// API params:
// ?cacList=<cac>,<cac>&startDate=<startDate>&endDate=<endDate>&size=<size></size>

const getPointsHistory = (cac, user) => {
  const userStub = user ? `/${user}` : '';
  return axios({
    params: {
      method: 'get',
      params: {
        startDate: LOYALTY_PROGRAM_START_DATE,
        endDate: todaysDate,
        size: 200,
        cacList: cac
      },
      url: api.baseUrl + api.endpoints.getPointsHistory + userStub
    }
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
};

export default getPointsHistory;
