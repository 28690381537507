import axios from './util/axiosHandle';
import { apiConfig as api } from '../oidc/oidc-configs';

const lodgeCampaignEntry = (opts, user) => {
  const userStub = user ? `/${user}` : '';
  const { campaignName = '', campaignData = {} } = opts;

  return axios({
    params: {
      method: 'post',
      url: api.baseUrl + api.endpoints.lodgeCampaignEntry + userStub,
      data: {
        data: [
          {
            campaignName,
            campaignData
          }
        ]
      }
    }
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error.response;
    });
};

export default lodgeCampaignEntry;
