import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Column, Spacing, TextStyle, IconButton, ActionButton } from '@able/react';
import useToggle from 'utils/hooks/useToggle';
// import {LoggerContext} from '../../rewardstore/contexts/RewardsStoreContext';
import { getFilteredCatalog } from 'selectors/GetRewards';
// import SubHeader from 'components/Header/SubHeader';
// import HeadingTitle from 'components/HeadingTitle';
import {
  RewardsFilter,
  RewardsSearch,
  RewardsSort,
  RewardsProductTileGroup
} from 'components';

import * as Analytics from 'utils/analytics';
import { Breadcrumbs } from 'components';

import { getHeadingTitleState } from 'selectors/GetHeaders';
// import { getAemContent } from 'selectors/GetAemContent';
import { fetchRewardCatalog, searchRewardCatalog} from 'actions/rewards';
import { setRSError } from 'actions/rewards';
import { useNavigate } from 'react-router-dom';

import './RewardsContainer.scss';
import { LaDialogContainer } from 'containers';

const RewardsContainer = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const headerContent = useSelector(state => getHeadingTitleState(state)) || {};
  const isAuthenticated = useSelector(state => state.appStatus.authenticated);
  const catalogView = useSelector(state => state.rewards.catalogView);
  const productFamilies = useSelector(state => getFilteredCatalog(state));
  // const subHeadPath = isAuthenticated
  //   ? 'rewards/auth/subheader'
  //   : 'rewards/unauth/subheader';
  // const [subHeaderConfig] = useSelector(state =>
  //   getAemContent(state, subHeadPath, 'subheader')
  // );

  // const {
  //   subheading,
  //   heading,
  //   description,
  //   images,
  //   primaryCtas,
  //   secondaryCtas,
  //   terms
  // } = headerContent;

  const [filterIsOpen, setFilterIsOpen] = useToggle(true);
  const [dialogOpen, setDialogOpen] = useState(true);
  const isAuth = isAuthenticated ? 'Auth':'Unauth';
  // const logger = useContext(LoggerContext);
  const { nextOffset, offerTypeFilter, categoriesFilter, searchQuery, status:shopCatalogStatus} = catalogView;
  
  useEffect(() => {
    if (window.innerWidth < 768) {
        setFilterIsOpen(false)
    }
  }, []);

  useEffect(() => {
    Analytics.setPageName('Rewards Store Catalogue');
    Analytics.clearProducts();
  }, []);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setDialogOpen(false)
    } 
    // logger.info("RewardsContainer:Loaded");
  },[]);

  useEffect(() => {
    // If there is no service under the cac redirect to forbidden page
    if(shopCatalogStatus === 'forbidden'){
      dispatch(setRSError('ERR_NO_SERVICE_UNDER_CAC'));
      history("/rewards/forbidden");
    }
 }, [shopCatalogStatus]);

  const fetchRewardData = (pageIndex = null) =>{
    if(searchQuery){
      dispatch(searchRewardCatalog(searchQuery, null, Object.keys(categoriesFilter).filter(i => categoriesFilter[i])));
    }else{
      dispatch(fetchRewardCatalog(offerTypeFilter ? [offerTypeFilter] : null , Object.keys(categoriesFilter).filter(i => categoriesFilter[i]), pageIndex))
    }
  }

  const handleClickLoadMore = (event) => {
    event.preventDefault();
    fetchRewardData(nextOffset);
  }

  return (
    <>
      {<LaDialogContainer/>}
      {/* {subHeaderConfig && (
          <div className="container">
            <SubHeader {...subHeaderConfig} />
          </div>
        )}
        {heading && (
          <HeadingTitle
            subheading={subheading}
            heading={heading}
            description={description}
            pageimage={images}
            primaryCtas={primaryCtas}
            secondaryCtas={secondaryCtas}
            terms={terms}
            activeaccount={activeAccountTier}
            pending={aemPending}
            active={headerContent}
          />
        )} */}
      <Grid className="container" id="tplus-rewards-container">
      <Spacing bottom="spacing4x" />
        <TextStyle alias="HeadingC" element="h1" className="tplus-rewards-heading-top">
            Telstra Plus Rewards
        </TextStyle>
        <Spacing bottom="spacing1x" />   
        <Breadcrumbs customPathname="/rewards"/>    
        <Column cols={12} bsm={3} className="tplus-rewards-filter-toggle">
          <div className="tplus-rewards-filter-toggle__heading">
            <TextStyle alias="HeadingD" element="h2">
              Filter
            </TextStyle>
            <IconButton
              onClick={() => setFilterIsOpen()}
              icon={filterIsOpen ? 'Close' : 'Filter'}
              developmentUrl="/able-sprites.svg"
              screenReaderContent={`filter panel ${filterIsOpen ? 'open' : 'close'}`}
              events={{'aria-expanded': filterIsOpen ? true : false}}
            ></IconButton>
          </div>
          <Spacing bottom="spacing3x" />
          <RewardsFilter
            isOpen={filterIsOpen}
            setFilterIsOpen={setFilterIsOpen}
            closeFilter={() => setFilterIsOpen()}
            dialogOpen={dialogOpen}
          />
        </Column>
        <Column cols={12} bsm={9}>
          <Grid>
            <Column cols={12} bsm={8}>
              <RewardsSearch />
              <Spacing bottom="spacing3x" />
            </Column>
            <Column cols={12} bsm={4}>
              <RewardsSort onOptionChange = {fetchRewardData}/>
              <Spacing bottom="spacing3x" />
            </Column>
            {filterIsOpen && (
              <>
                <Spacing bottom="spacing2x" />
                <RewardsProductTileGroup bmdCols={4} bsmCols={6} />
                {/* {
                  (nextOffset !== -1 && !searchQuery && productFamilies.length > 0) &&
                  <ActionButton
                    className='tplus-rewards-load-more'
                    developmentUrl="/able-sprites.svg"
                    label={`Load more`}
                    element='button'
                    variant="LowEmphasis"
                    icon="Refresh"
                    onClick={(event) => handleClickLoadMore(event)}
                  />
                } */}
              </>
            )}
          </Grid>
        </Column>
        {!filterIsOpen && (
          <>
            <Spacing bottom="spacing2x" />
            <Column>
              <RewardsProductTileGroup bmdCols={3} bsmCols={4} />
              <Spacing bottom="spacing4x" />
              {/* {
                  (nextOffset !== -1 && !searchQuery && productFamilies.length > 0) &&
                  <ActionButton
                    className='tplus-rewards-load-more'
                    developmentUrl="/able-sprites.svg"
                    label={`Load more`}
                    element='button'
                    variant="LowEmphasis"
                    icon="Refresh"
                    onClick={(event) => handleClickLoadMore(event)}
                  />
                } */}
            </Column>
          </>
        )}
        <Spacing bottom="spacing7x" />
      </Grid>

      {/* <FeaturedBrandsContainer /> */}
      <Spacing bottom="spacing7x" />
    </>
  );
};

export default RewardsContainer;
