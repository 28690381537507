import React, { useEffect } from 'react';
import _ from 'lodash';
import { TextStyle, Sequence, Divider } from '@able/react';

import './RewardsSlider.scss';
import { PageStepper } from '@able/react';

const RewardsSlider = props => {
  const {
    isPromoPricing,
    isHotOffer,
    selectedOriginalPrice,
    selectedPrice,
    selectedPriceOptionProgress,
    minPrice,
    highestPoints,
    forwardCallback,
    backwardCallback,
    hideOriginalPrice
  } = props;
  const {upfrontPrice:selectedOriginalUpfrontPrice, ongoingPrice:selectedOriginalOngoingPrice} = selectedOriginalPrice || {};
  const {upfrontPrice:selectedUpfrontPrice, ongoingPrice:selectedOngoingPrice} = selectedPrice || {};
  const {upfrontPrice:minUpfrontPrice, ongoingPrice:minOngoingPrice} = minPrice || {};

  useEffect(() => {
    const pageStepperH1 = document.querySelector("#page-step-comp .able-PageStepper--Fluid> div > div > h1");
    const progressBarElement = document.querySelector("#page-step-comp .able-PageStepper--Fluid > div > div > div");
    if (pageStepperH1) {
      pageStepperH1.remove();
    }
    if (progressBarElement) {
      progressBarElement.setAttribute("tabindex", 0);
      progressBarElement.addEventListener("keyup", (e) => {
        if (e.keyCode === 37) {
          backwardCallback();
        } else if (e.keyCode === 39) {
          forwardCallback();
        }
      });
    }
  }, [])

  return (
    <>
      <Divider />
      <div className="tplus-rewards-slider">
        <TextStyle alias="HeadingC" element="h2" id="slider-heading" alignment="Left">
          Choose how you'll redeem
        </TextStyle>
        <TextStyle alias="Base" element="p" alignment="Left">
          Choose how many points you’d like to use to redeem this reward.
        </TextStyle>
        <TextStyle alias="Base" element="div" alignment="Left">
          Minimum is {Intl.NumberFormat().format(minUpfrontPrice)} pts + ${Intl.NumberFormat().format(minOngoingPrice)}
        </TextStyle>
        {(isHotOffer || isPromoPricing) && selectedOriginalPrice && !hideOriginalPrice && (
          <TextStyle
            className="original-price"
            alias="FinePrint"
            element="p"
            alignment="Center"
          >
            {Intl.NumberFormat().format(selectedOriginalUpfrontPrice)} points + $
            {Intl.NumberFormat().format(selectedOriginalOngoingPrice)}
          </TextStyle>
        )}
        <div className="currrent-price-section">
          <TextStyle
            className={`current-price ${(isHotOffer || isPromoPricing) && 'hot-offer'}`}
            alias={(isHotOffer || isPromoPricing) ? 'HeadingD' : 'Base'}
            element="p"
            alignment="Center"
          >
            {Intl.NumberFormat().format(selectedUpfrontPrice)} points + ${Intl.NumberFormat().format(selectedOngoingPrice)}
          </TextStyle>
        </div>
        <div className="rewards-slider" id="rewards-slider">
          <div className="able-Sequence able-Sequence--Default--Normal">
            <button type="button" className="button-plus-minus button-minus" aria-label="minus" onKeyPress={(e) => {
              if (e.keyCode === 13) {
                backwardCallback();
              }
            }}
              onClick={() => {
                backwardCallback();
              }}>
              <svg className="able-icon able-icon--24 button-minus" role="img" aria-hidden="true" focusable="false">
                <use href="/able-sprites.svg#Minus"></use>
              </svg>
            </button>
            <div id="page-step-comp" className="page-step-comp" tabIndex={0} role="slider" aria-label="Points & pay slider" aria-valuetext={selectedUpfrontPrice + " points + " + "$" + selectedOngoingPrice} aria-valuemin={minUpfrontPrice} aria-valuemax={highestPoints}  aria-valuenow={selectedUpfrontPrice}	>
              <PageStepper variant="Fluid" percentageComplete={Math.floor((selectedUpfrontPrice / highestPoints) * 100)} pageTitle="" pageTagline=" " removeTopSpacing={false}/>
            </div>
            <button type="button" className="button-plus-minus button-tplus" aria-label="plus" onKeyPress={(e) => { if (e.keyCode === 13) forwardCallback(); }} onClick={() => { forwardCallback(); }}>
              <svg className="able-icon able-icon--24 button-tplus" role="img" aria-hidden="true" focusable="false">
                <use href="/able-sprites.svg#Plus"></use>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardsSlider;
