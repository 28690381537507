import {
  SET_NEW_CAMPAIGN,
  GET_ENTRY_PENDING,
  GET_ENTRY_SUCCESS,
  GET_ENTRY_FAILURE,
  GET_PREVIOUS_ENTRY_PENDING,
  GET_PREVIOUS_ENTRY_SUCCESS,
  GET_PREVIOUS_ENTRY_FAILURE
} from 'constants/actions';

const defaultCampaign = {
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  response: {},
  error: null,
  previousEntry: {
    status: 'idle',
    response: {},
    error: null
  }
};
const defaultState = {};

export const forms = (state = defaultState, action) => {
  const { campaignName } = action;
  switch (action.type) {
    case SET_NEW_CAMPAIGN: {
      return {
        ...state,
        [campaignName]: defaultCampaign
      };
    }
    case GET_ENTRY_PENDING: {
      return {
        ...state,
        [campaignName]: {
          ...state[campaignName],
          status: 'loading'
        }
      };
    }
    case GET_ENTRY_SUCCESS: {
      return {
        ...state,
        [campaignName]: {
          ...state[campaignName],
          status: 'succeeded',
          response: action.payload
        }
      };
    }
    case GET_ENTRY_FAILURE: {
      return {
        ...state,
        [campaignName]: {
          ...state[campaignName],
          status: 'failed',
          error: action.payload
        }
      };
    }
    case GET_PREVIOUS_ENTRY_PENDING: {
      return {
        ...state,
        [campaignName]: {
          ...state[campaignName],
          previousEntry: {
            ...state[campaignName].previousEntry,
            status: 'loading'
          }
        }
      };
    }
    case GET_PREVIOUS_ENTRY_SUCCESS: {
      return {
        ...state,
        [campaignName]: {
          ...state[campaignName],
          previousEntry: {
            ...state[campaignName].previousEntry,
            status: 'succeeded',
            response: action.payload
          }
        }
      };
    }
    case GET_PREVIOUS_ENTRY_FAILURE: {
      return {
        ...state,
        [campaignName]: {
          ...state[campaignName],
          previousEntry: {
            ...state[campaignName].previousEntry,
            status: 'failed',
            error: action.payload
          }
        }
      };
    }
    default:
      return state;
  }
};
