import { push } from 'react-router-redux';

import * as actions from 'constants/actions';

export const goToCompetition = (partner, query) => {
  return (dispatch) => {
    // eslint-disable-next-line camelcase
    const { redirect_uri } = query;

    dispatch({ type: actions.SET_LOADING, loading: false });
    dispatch(push(redirect_uri));
  };
};
