/* eslint-disable prefer-template */
/* eslint-disable no-console */
import _ from 'lodash';

export default class AppLog {
  constructor(classType) {
    this.appName = 'SelfService';
    this.classType = classType;
    this.timeStyle =
      'background: gray; color: white; display: block; padding: 2px 0px; line-height: 24px; font-size: 20px;';
    this.classStyle =
      'background: gray; color: black; display: block; padding: 2px 0px; line-height: 24px; font-size: 20px;';
    this.debugStyle =
      'background: green; color: white; display: block; padding: 2px 10px; line-height: 24px; font-size: 20px;';
    this.infoStyle =
      'background: #2c74d3; color: white; display: block; padding: 2px 10px; line-height: 24px; font-size: 20px;';
    this.warnStyle =
      'background: darkorange; color: white; display: block; padding: 2px 10px; line-height: 24px; font-size: 20px;';
    this.errorStyle =
      'background: red; color: white; display: block; padding: 2px 10px; line-height: 24px; font-size: 20px;';

    this.IN_DEVELOP = false;
  //     REACT_APP_ENV === 'dev' ||
  //     REACT_APP_ENV === 'dev-mocks' ||
  //     REACT_APP_ENV === 'nonprod';
  }

  getConfig() {
    if (this.IN_DEVELOP) {
      return {
        logLevels: ['debug', 'warn', 'info', 'error'],
        LogTrace: true
      };
    }
    return {
      logLevels: ['warn', 'info', 'error']
    };
  }

  debug(param1, param2 = null) {
    if (
      this.getConfig().logLevels.indexOf &&
      this.getConfig().logLevels.indexOf('debug') > -1
    ) {
      console.log(
        '%c' +
          new Date().toLocaleTimeString('en-US') +
          '%c[' +
          this.classType +
          ']' +
          '%c' +
          param1,
        this.timeStyle,
        this.classStyle,
        this.debugStyle
      );
      if (this.getConfig().LogTrace) {
        if (!_.isNil(param2)) console.log(param2);
      }
    }
  }

  info(param1, param2) {
    if (
      this.getConfig().logLevels.indexOf &&
      this.getConfig().logLevels.indexOf('info') > -1
    ) {
      console.log(
        '%c' +
          new Date().toLocaleTimeString('en-US') +
          '%c[' +
          this.classType +
          ']' +
          '%c' +
          param1,
        this.timeStyle,
        this.classStyle,
        this.infoStyle
      );
      if (this.getConfig().LogTrace) {
        if (!_.isNil(param2)) console.log(param2);
      }
    }
  }

  warn(param1, param2) {
    if (
      this.getConfig().logLevels.indexOf &&
      this.getConfig().logLevels.indexOf('warn') > -1
    ) {
      console.log(
        '%c' +
          new Date().toLocaleTimeString('en-US') +
          '%c[' +
          this.classType +
          ']' +
          '%c' +
          param1,
        this.timeStyle,
        this.classStyle,
        this.warnStyle
      );
      if (this.getConfig().LogTrace) {
        if (!_.isNil(param2)) console.log(param2);
      }
    }
  }

  error(param1, param2) {
    if (
      this.getConfig().logLevels.indexOf &&
      this.getConfig().logLevels.indexOf('error') > -1
    ) {
      console.log(
        '%c' +
          new Date().toLocaleTimeString('en-US') +
          '%c[' +
          this.classType +
          ']' +
          '%c' +
          param1,
        this.timeStyle,
        this.classStyle,
        this.errorStyle
      );
      if (this.getConfig().LogTrace) {
        if (!_.isNil(param2)) console.log(param2);
      }
    }
  }
}

export const LOGGER = new AppLog('MainLogger');
