import { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import unionBy from 'lodash/unionBy';
import filter from 'lodash/filter';

import { SET_ADDRESS, SET_ADDRESS_ID } from 'constants/actions';
import addressAction from 'actions/address';
import updateContactDetails from 'actions/updateContactDetails';
import { otpSetMandatoryError } from 'actions/otp';

const IN_DEVELOP = process.env.REACT_APP_ENV === 'dev-mocks';

const useOtpFlow = () => {
  const reduxDispatch = useDispatch();

  const [mandatoryErrorTriggered, setmandatoryErrorTriggered] = useState(false);

  const getDetailsData = useSelector(
    state => state.eligibility.getDetails.data
  );
  const { addressId, partySiteId } = getDetailsData;
  const appStatus = useSelector(state => state.appStatus);
  const { loaFlowStatus } = appStatus;
  const { status: loaStatus } = loaFlowStatus;
  // const loaStatus = 'failed';
  const accountMobile = useSelector(state => state.accounts.mobile);
  const accountEmail = useSelector(state => state.accounts.email);
  const otp = useSelector(state => state.otpFlow);
  const {
    triggered,
    mandatoryError,
    mobile: {
      number: otpNumber,
      triggered: mobileTriggered,
      verified: mobileVerified
    },
    email: {
      email: otpEmail,
      triggered: emailTriggered,
      verified: emailVerified
    },
    address
  } = otp;

  const {
    address: otpAddress,
    adborid,
    addressConfirmationRequired,
    triggered: addressTriggered
  } = address;

  // Initial state
  const initState = {
    otpTriggered: false,
    otpBorders: {
      mobile: true,
      email: true,
      address: true
    },
    otpValidationErrors: [],
    otpData: {}
  };

  // Reducer function to handle incoming actions
  const reducer = (state, action) => {
    switch (action.type) {
      case 'triggered': {
        return {
          ...state,
          otpTriggered: action.payload
        };
      }
      case 'mobileBorder': {
        return {
          ...state,
          otpBorders: {
            ...state.otpBorders,
            mobile: action.payload
          }
        };
      }
      case 'emailBorder': {
        return {
          ...state,
          otpBorders: {
            ...state.otpBorders,
            email: action.payload
          }
        };
      }
      case 'addressBorder': {
        return {
          ...state,
          otpBorders: {
            ...state.otpBorders,
            address: action.payload
          }
        };
      }
      case 'mobileTriggered': {
        return {
          ...state,
          otpData: {
            ...state.otpData,
            isMobileNumberUpdated: action.payload
          }
        };
      }
      case 'emailTriggered': {
        return {
          ...state,
          otpData: {
            ...state.otpData,
            isEmailAddressUpdated: action.payload
          }
        };
      }
      case 'address': {
        // Check the address data if confirmation is true then blank the rest of the data
        if (addressConfirmationRequired) {
          return {
            ...state,
            otpData: {
              ...state.otpData,
              addressLine: '',
              addressId: '',
              addressConfirmationRequired
            }
          };
        }
        // If no confirmation and we have triggered an address change fill in with address data
        if (addressTriggered) {
          return {
            ...state,
            otpData: {
              ...state.otpData,
              addressLine: otpAddress,
              addressId: adborid,
              addressConfirmationRequired
            }
          };
        }
        // Otherwise just fill in with existing address data
        if (otpAddress && adborid) {
          return {
            ...state,
            otpData: {
              ...state.otpData,
              addressLine: otpAddress,
              addressId: adborid,
              addressConfirmationRequired
            }
          };
        }
        return { ...state };
      }
      case 'mobileEmpty':
      case 'emailEmpty':
      case 'addressEmpty': {
        return {
          ...state,
          otpValidationErrors: unionBy(
            [action.payload],
            state.otpValidationErrors,
            'id'
          )
        };
      }
      case 'mobileValid':
      case 'emailValid':
      case 'addressValid': {
        return {
          ...state,
          otpValidationErrors: filter(state.otpValidationErrors, err => {
            return err.id !== action.payload.id;
          })
        };
      }
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initState);

  // TODO Currently BE has no strategic solution, each campaign needs to be set up to handle things like address
  // TODO So for now address functionality is toggled off
  // On initial load of this component, if there is either address id or party site id fire off call
  // to get initial address
  useEffect(() => {
    const updateDetailsOpts = {
      addressId,
      partySiteId
    };
    // Call api to check for address via ADBOR ID (addressId)
    if (addressId || partySiteId) {
      reduxDispatch(addressAction(updateDetailsOpts))
        .then(() => {
          // The action either fills in birthday address field or not
          // console.info('ADDRESS SUCCESS');
        })
        .catch(() => {
          // console.info('ADDRESS FAILURE');
        });
    }
    if (IN_DEVELOP && !(addressId || partySiteId)) {
      reduxDispatch({
        type: SET_ADDRESS,
        address: null
      });
      reduxDispatch({
        type: SET_ADDRESS_ID,
        adborid: null
      });
    }
  }, [addressId, partySiteId]);

  // if any of the otp fields changes then run this check and set the flag
  useEffect(() => {
    if (triggered) {
      dispatch({
        type: 'triggered',
        payload: triggered
      });
    }
    if (mobileTriggered) {
      dispatch({
        type: 'mobileTriggered',
        payload: mobileTriggered
      });
      if (!state.otpData.isEmailAddressUpdated) {
        dispatch({
          type: 'emailTriggered',
          payload: false
        });
      }
    }
    if (emailTriggered) {
      dispatch({
        type: 'emailTriggered',
        payload: emailTriggered
      });
      if (!state.otpData.isMobileNumberUpdated) {
        dispatch({
          type: 'mobileTriggered',
          payload: false
        });
      }
    }
    if (address) {
      dispatch({
        type: 'address',
        payload: address
      });
    }
  }, [triggered, mobileTriggered, emailTriggered, address]);

  useEffect(() => {
    if (
      loaStatus === 'attempted' ||
      loaStatus === 'failed' ||
      loaStatus === 'denied'
    ) {
      if (!mobileVerified && !otpNumber && !accountMobile) {
        dispatch({
          type: 'mobileBorder',
          payload: false
        });
      }
      if (!emailVerified && !otpEmail && !accountEmail) {
        dispatch({
          type: 'emailBorder',
          payload: false
        });
      }
      if (!otpAddress) {
        dispatch({
          type: 'addressBorder',
          payload: false
        });
      }
    }
  }, [loaStatus]);

  useEffect(() => {
    // Set internal flag to start tracking mandatory errors
    if (mandatoryError && state.otpValidationErrors.length > 0) {
      setmandatoryErrorTriggered(true);
    }
    if (mandatoryErrorTriggered && state.otpValidationErrors.length === 0) {
      setmandatoryErrorTriggered(false);
    }
  }, [mandatoryError, state.otpValidationErrors, mandatoryErrorTriggered]);

  const otpValidate = schema => {
    // Validation rules from form
    const {
      otpValidationTriggered = false,
      emailIsRequired = 'false',
      mobileIsRequired = 'false',
      addressIsRequired = 'false'
    } = schema;

    // Set up error object to pass into ErrorSummary if needed
    if (mobileIsRequired && !mobileVerified && !otpNumber && !accountMobile) {
      dispatch({
        type: 'mobileEmpty',
        payload: {
          id: 'otp-mobilenumber',
          label: 'Mobile number'
        }
      });
    } else {
      dispatch({
        type: 'mobileValid',
        payload: {
          id: 'otp-mobilenumber'
        }
      });
    }
    if (emailIsRequired && !emailVerified && !otpEmail && !accountEmail) {
      dispatch({
        type: 'emailEmpty',
        payload: {
          id: 'otp-emailaddress',
          label: 'Email address'
        }
      });
    } else {
      dispatch({
        type: 'emailValid',
        payload: {
          id: 'otp-emailaddress'
        }
      });
    }
    if (addressIsRequired && !otpAddress) {
      dispatch({
        type: 'addressEmpty',
        payload: {
          id: 'otp-address',
          label: 'Address'
        }
      });
    } else {
      dispatch({
        type: 'addressValid',
        payload: {
          id: 'otp-address'
        }
      });
    }

    // Is otp valid
    const numberValid =
      mobileIsRequired === 'true' ? !!(accountMobile || otpNumber) : true;
    const emailValid =
      emailIsRequired === 'true' ? !!(accountEmail || otpEmail) : true;
    const addressValid = addressIsRequired === 'true' ? !!otpAddress : true;

    // Set global flag to indicate validation for OTP has been triggered, to kick off showing any errors etc
    // TODO may need to namespace this in redux per form later, in case the otp triggered state will be different for different forms
    if (otpValidationTriggered) {
      reduxDispatch(
        otpSetMandatoryError({
          toggle: !(numberValid && emailValid && addressValid)
        })
      );
    }

    return {
      isValid: numberValid && emailValid && addressValid,
      fieldErrors: {
        ...(!numberValid && { otpMobileError: 'Mobile number is required' }),
        ...(!emailValid && { otpEmailError: 'Email is required' }),
        ...(!addressValid && { otpAddressError: 'Address is required' })
      }
    };
  };

  return {
    ...state,
    otpValidate,
    updateContactDetails
  };
};

export default useOtpFlow;
