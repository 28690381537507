/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Grid, Spacing, Column, ActionButton, TextStyle } from '@able/react';
import { setPageName } from 'utils/analytics';
import { VerticalTabs } from 'components';
import { HERITAGEFAQS } from './HeritageContent.js';

const { REACT_APP_PLUS_HOME_URL, REACT_APP_HERITAGE_SIGNIN_URL } = process.env;

// eslint-disable-next-line react/prefer-stateless-function
const HeritageExplainer = () => {
  useEffect(() => {
    setPageName('explainer');
  }, []);

  const tabFooter = () => {
    return {
      text: 'See all Telstra Plus FAQs',
      url: `${REACT_APP_PLUS_HOME_URL}/frequently-asked-questions`
    };
  };

  return (
    <Grid className="container">
      <Column bsm={8}>
        <Spacing bottom="spacing7x" />
        <TextStyle alias="HeadingA" element="h1">
          Join Telstra Plus
        </TextStyle>
        <Spacing bottom="spacing2x" />
        <TextStyle alias="Base" element="p">
          By joining Telstra Plus, we&apos;ll upgrade your Business ID to a
          Telstra ID. This means you&apos;ll be able to access all your Personal
          and Business accounts using this one login.
        </TextStyle>
        <Spacing bottom="spacing2x" />
        <TextStyle alias="Base" element="p">
          If you don&apos;t want to give others access, don&apos;t share your
          Telstra ID. Sharing your Telstra ID means others will be able to see
          your personal information, including billing details and device
          information.
        </TextStyle>
        <Spacing bottom="spacing2x" />
        <ActionButton
          element="a"
          href={`${REACT_APP_HERITAGE_SIGNIN_URL}`}
          className="button-tplus"
          label="Continue"
          variant="LowEmphasis"
        />
        <Spacing bottom="spacing7x" />
        <VerticalTabs title="FAQs" tabs={HERITAGEFAQS} footer={tabFooter()} />
        <Spacing bottom="spacing10x" />
      </Column>
    </Grid>
  );
};

export default HeritageExplainer;
