import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';

import createRootReducer from 'reducers';

const IN_DEVELOP = process.env.REACT_APP_ENV === 'dev';

export const history = createBrowserHistory();

const configureStore = () => {
  if (IN_DEVELOP) {
    return createStore(
      createRootReducer(history), // root reducer with router state
      composeWithDevTools(
        applyMiddleware(
          thunk,
          routerMiddleware(history), // for dispatching history actions
          createLogger({
            collapsed: true,
            colors: { title: () => '#999' },
            titleFormatter: action => `Action: ${action.type}`
          })
        )
      )
    );
  }
  return createStore(
    createRootReducer(history),
    composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history)))
  );
};

const store = configureStore();

export default store;
