import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { otpVerifyAction } from 'actions/otp';

import OtpForm from 'components/OtpFlow/sidebarforms/OtpForm';
import { TextStyle, IconButton, Spacing } from '@able/react';

const OtpView = props => {
  const { toggleView, page, currentFlow, reference, renderSpinner } = props;
  const dispatch = useDispatch();

  const otpFlow = useSelector(state => state.otpFlow);
  let heading; 
  switch (otpFlow.otp.flow) {
    case 'mobile':
      heading = 'Verify your mobile number';
      break;
    case 'sendemail':
      heading = 'Verify your email address';
      break;
    default:
      heading = '';
  }

  return (
    <>
      {otpFlow.otp.fetching && renderSpinner('Confirming your one-time PIN')}
      {!otpFlow.otp.fetching && (
        <>
          <div>
         <TextStyle element="h2" alias="HeadingB">
            {heading}
          </TextStyle>
          </div>
          <OtpForm
            page={page}
            apiAction={value => dispatch(otpVerifyAction(value))}
            nextAction={toggleView}
            flow={currentFlow}
            reference={reference}
          />
        </>
      )}
      </>
  );
};

export default OtpView;

OtpView.propTypes = {
  page: PropTypes.string.isRequired,
  toggleView: PropTypes.func.isRequired,
  renderSpinner: PropTypes.func.isRequired,
  currentFlow: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired
};
