// Action Types, Describe what is happening
import getToggles from 'api/getToggles';
import {
  SET_INITIALISED,
  SET_AUTHENTICATED,
  SET_AUTH_PREFLIGHT,
  SET_ORIGINURL,
  SET_FULL_LOCATION_PATH,
  GET_TOGGLES_PENDING,
  GET_TOGGLES_SUCCESS,
  GET_TOGGLES_FAILURE
} from 'constants/actions';
import { LOGGER } from 'utils/AppLog';

// Action Creators
export const toggleInitialised = initialised => {
  return { type: SET_INITIALISED, initialised };
};

export const toggleAuthenticated = authenticated => {
  return { type: SET_AUTHENTICATED, authenticated };
};

export const setAuthPreFlight = isPreFlight => {
  return { type: SET_AUTH_PREFLIGHT, isPreFlight };
};

export const setOriginURL = originUrl => {
  return {
    type: SET_ORIGINURL,
    originUrl
  };
};

export const setFullLocationPath = locationPath => {
  return {
    type: SET_FULL_LOCATION_PATH,
    locationPath
  };
};

export const fetchToggles = () => {
  return async dispatch => {
    dispatch({ type: GET_TOGGLES_PENDING, getTogglesPending: true });

    try {
      const response = await getToggles();
      LOGGER.debug('Get Toggles API Response', response);
      dispatch({
        type: GET_TOGGLES_SUCCESS,
        apiResponse: response.data.toggleList,
        getTogglesPending: false
      });
      LOGGER.debug('Get Toggles API Call Finished');
    } catch (error) {
      dispatch({
        type: GET_TOGGLES_FAILURE,
        errorObject: error.response
      });
    }
  };
};
