import axios from './util/axiosHandle';
import { apiConfig as api } from '../oidc/oidc-configs';

// inputs:
// ?cacList=<cac>,<cac>

const getPoints = (cac, user) => {
  const userStub = user ? `/${user}` : '';
  return axios({
    params: {
      method: 'get',
      params: {
        cacList: cac
      },
      url: api.baseUrl + api.endpoints.getPoints + userStub
    }
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
};

export default getPoints;
