import { useState, useEffect } from "react";

export const PresaleCountdown = ({ renderComponent, preDate, tier }) => {
  const [countdown, setCountdown] = useState('');

  const calculateCountdown = () => {
    // return `Presale starts at ${moment(preDate).tz(DEFAULT_TIMZONE).format(`dddd Do MMMM,YYYY [from] hh:mm a`)}`;
    const date1 = new Date();
    const date2 = new Date(preDate);
    let delta = Math.abs(date2 - date1) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    const seconds = Math.floor(delta) % 60;

    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0){
      setTimeout(renderComponent,
        1000
      );
    }
    return `Presale starts in: ${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    setCountdown(calculateCountdown());
    const interval = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [tier]);

  return countdown;
};