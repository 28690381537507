import * as actions from 'constants/actions';

const spLogEntryState = {
  success: false,
  error: null,
  loading: false
};

const spLogEntryReducer = (state = spLogEntryState, action) => {
  switch (action.type) {
    case actions.SET_SP_LOG_ENTRY_SUCCESS:
      return { ...state, success: action.payload };
    case actions.SP_LOG_ENTRY_FAILURE:
      return { ...state, error: action.payload };
    case actions.SET_SP_LOG_ENTRY_LOADING:
      return { ...state, loading: action.loading };
    default:
      return state;
  }
};

export default spLogEntryReducer;