import axios from './util/axiosHandle';
import { apiConfig as api } from '../oidc/oidc-configs';

const setContactDetails = (opts, status) => {
  const statusStub = status ? `/${status}` : '';

  const params = {
    method: 'post',
    url: api.baseUrl + api.endpoints.updateContact + statusStub,
    data: {
      data: {}
    }
  };

  return axios({
    params
    // incomingheaders: headers
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export default setContactDetails;
