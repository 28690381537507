//TODO: Confirm colour groupings and allocations with Will Lavan
export const RewardsColourGroups = [
    {
        "name": "black",
        "hexCode": "#000000",
        "names": [
            "black",
            "graphite",
            "carbon",
            "obsidian",
            "black graphite",
            "black titanium",
            "flap leather - black",
            "aura black",
            "black leather",
            "cosmic black",
            "dark night",
            "mystic black",
            "prism crush black",
            "aramid black",
            "black with pen",
            "deep space black",
            "just black",
            "night black",
            "slate"
        ]
    },
    {
        "name": "white",
        "hexCode": "#FFFFFF",
        "names": [
            "snow",
            "white",
            "ivory white",
            "light frost",
            "cloud white"
        ]
    },
    {
        "name": "grey",
        "hexCode": "#AAAAAA",
        "names": [
            "grey",
            "space grey",
            "slate",
            "wool",
            "tranquil",
            "cosmic grey"
        ]
    },
    {
        "name": "light grey",
        "hexCode": "#D2D2D2",
        "names": [
            "light grey",
            "chalk",
            "silver",
            "stardust twinkle",
            "mystic silver",
            "silver aluminium",
            "silver with white sport band"
        ]
    },
    {
        "name": "dark grey",
        "hexCode": "#707070",
        "names": [
            "dark grey",
            "charcoal",
            "greygreen",
            "grey titanium",
            "smoke",
            "stormy sky",
            "grey aluminium",
            "shadow grey"
        ]
    },
    {
        "name": "purple",
        "hexCode": "#570266",
        "names": [
            "purple",
            "lavender",
            "purple",
            "violet",
            "lilac",
            "cloud lavendar",
            "deep unicorn",
            "rosewood",
            "ultraviolet purple"
        ]
    },
    {
        "name": "red",
        "hexCode": "#E02020",
        "names": [
            "red"
        ]
    },
    {
        "name": "dark red",
        "hexCode": "#C91800",
        "names": [
            "dark red",
            "burgundy"
        ]
    },
    {
        "name": "pink",
        "hexCode": "#FF2896",
        "names": [
            "pink",
            "floral pink",
            "rose",
            "cotton candy"
        ]
    },
    {
        "name": "orange",
        "hexCode": "#F05B02",
        "names": [
            "orange",
            "coral",
            "sunset"
        ]
    },
    {
        "name": "green",
        "hexCode": "#008A00",
        "names": [
            "green",
            "hazel"
        ]
    },
    {
        "name": "dark green",
        "hexCode": "#0A4F31",
        "names": [
            "dark green",
            "olive",
            "titanium green",
            "sequoa green",
            "sequoia green"
        ]
    },
    {
        "name": "light green",
        "hexCode": "#6EDB57",
        "names": [
            "light green",
            "mint",
            "lemongrass",
            "sage",
            "seafoam",
            "soft sage",
            "sorta sage"
        ]
    },
    {
        "name": "teal",
        "hexCode": "#008381",
        "names": [
            "teal",
            "aurora",
            "mystic green"
        ]
    },
    {
        "name": "blue",
        "hexCode": "#0064D2",
        "names": [
            "blue",
            "pacific blue",
            "blue astro green",
            "sapphire",
            "surfing blue",
            "lagoon blue"
        ]
    },
    {
        "name": "dark blue",
        "hexCode": "#001E82",
        "names": [
            "dark blue",
            "midnight",
            "midnight aluminium",
            "midnight grey",
            "titanium ocean",
            "basalt",
            "navy",
            "navy blue",
            "silicone - navy",
            "abyss blue",
            "cloud navy",
            "deep bermuda blue"
        ]
    },
    {
        "name": "light blue",
        "hexCode": "#B2E0FD",
        "names": [
            "light blue",
            "sierra blue",
            "fog",
            "light rain",
            "sky",
            "soft sage",
            "crystal"
        ]
    },
    {
        "name": "yellow",
        "hexCode": "#FFFF00",
        "names": [
            "yellow",
            "sunny"
        ]
    },
    {
        "name": "gold",
        "hexCode": "#FFD700",
        "names": [
            "gold",
            "pink gold",
            "champagne gold",
            "rose gold",
            "gold aluminium",
            "rose",
            "rosegold",
            "soft gold"
        ]
    },
    {
        "name": "bronze",
        "hexCode": "#CD7F32",
        "names": [
            "copper",
            "bronze",
            "copper rose",
            "gumnut",
            "mystic bronze"
        ]
    },
    {
        "name": "beige",
        "hexCode": "#F5F5DC",
        "names": [
            "beige",
            "cream",
            "starlight",
            "light sand",
            "soft gold",
            "starlight aluminium",
            "ivory"
        ]
    },
    {
        "name": "#964B00",
        "hexCode": "#8C593C",
        "names": [
            "brown",
            "rosewood"
        ]
    },
    {
        "name": "other",
        "hexCode": "#FFFFFF",
        "names": [
            "multi",
            "na",
            "clear",
            "aura glow"
        ]
    }
]
