// explicit actions
export const SET_INITIALISED = 'SET_INITIALISED';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_AUTH_PREFLIGHT = 'SET_AUTH_PREFLIGHT';
export const SET_ELIGIBLE = 'SET_ELIGIBLE';
export const SET_ENROLLED = 'SET_ENROLLED';
export const SET_LOADING = 'SET_LOADING';
export const SET_ENROLFAILED = 'SET_ENROLFAILED';
export const SET_HASCHECKEDELIGIBILITY = 'SET_HASCHECKEDELIGIBILITY';
export const SET_ORIGINURL = 'SET_ORIGINURL';
export const SET_WELCOME = 'SET_WELCOME';
export const SET_FLOW = 'SET_FLOW';
export const SET_AEM_PLACEHOLDER = 'SET_AEM_PLACEHOLDER';
export const SET_LIVECHAT = 'SET_LIVECHAT';
export const SET_DEVICE = 'SET_DEVICE';
export const SET_LOAFLOWSTATUS = 'SET_LOAFLOWSTATUS';
export const SET_FULL_LOCATION_PATH = 'SET_FULL_LOCATION_PATH';

// Toggle API Actions
export const GET_TOGGLES_PENDING = 'GET_TOGGLES_PENDING';
export const GET_TOGGLES_SUCCESS = 'GET_TOGGLES_SUCCESS';
export const GET_TOGGLES_FAILURE = 'GET_TOGGLES_FAILURE';

export const SET_ERROR = 'SET_ERROR';
export const RESET_ERROR = 'RESET_ERROR';

// enrolment source coming from Telstra plus tickets.
export const GET_ENROLLMENT_SOURCE = 'GET_ENROLLMENT_SOURCE';

// Offer API Actions
export const GET_OFFERS_PENDING = 'GET_OFFERS_PENDING';
export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';
export const GET_OFFERS_FAILURE = 'GET_OFFERS_FAILURE';

// Offer API Actions
export const GET_ORDERS_PENDING = 'GET_ORDERS_PENDING';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';

export const CREATE_NEW_ORDER = 'CREATE_NEW_ORDER';
export const SET_ORDER_PENDING = 'SET_ORDER_PENDING';
export const SET_ORDER_SUCCESS = 'SET_ORDER_SUCCESS';
export const SET_ORDER_FAILURE = 'SET_ORDER_FAILURE';

// Account Actions
export const SWITCH_ACCOUNT = 'SWITCH_ACCOUNT';
export const SET_INITIAL_ACCOUNT = 'SET_INITIAL_ACCOUNT';
export const SET_INITIAL_POINTS = 'SET_INITIAL_POINTS';
export const SET_ACCOUNT_MOBILE = 'SET_ACCOUNT_MOBILE';
export const SET_ACCOUNT_EMAIL = 'SET_ACCOUNT_EMAIL';
export const SET_ACCOUNT_MASKED_MOBILE = 'SET_ACCOUNT_MASKED_MOBILE';
export const SET_ACCOUNT_MASKED_EMAIL = 'SET_ACCOUNT_MASKED_EMAIL';
export const SET_ACCOUNTS_LIST = 'SET_ACCOUNTS_LIST';
export const MERGE_ACCOUNTS_LIST = 'MERGE_ACCOUNTS_LIST';
export const RESET_ACCOUNTS = 'RESET_ACCOUNTS';

// API Actions
export const GET_DETAILS_SUCCESS = 'GET_DETAILS_SUCCESS';
export const GET_DETAILS_PENDING = 'GET_DETAILS_PENDING';
export const GET_DETAILS_FAILURE = 'GET_DETAILS_FAILURE';
export const GET_DETAILS_HERITAGE = 'GET_DETAILS_HERITAGE';

export const GET_POINTS_SUCCESS = 'GET_POINTS_SUCCESS';
export const GET_POINTS_PENDING = 'GET_POINTS_PENDING';
export const GET_POINTS_FAILURE = 'GET_POINTS_FAILURE';

export const GET_POINTS_HISTORY_SUCCESS = 'GET_POINTS_HISTORY_SUCCESS';
export const GET_POINTS_HISTORY_PENDING = 'GET_POINTS_HISTORY_PENDING';
export const GET_POINTS_HISTORY_FAILURE = 'GET_POINTS_HISTORY_FAILURE';

export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_PENDING = 'REGISTRATION_PENDING';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

export const UNENROL_SUCCESS = 'UNENROL_SUCCESS';
export const UNENROL_PENDING = 'UNENROL_PENDING';
export const UNENROL_FAILURE = 'UNENROL_FAILURE';

export const OPTOUT_RESULT_SUCCESS = 'SUCCESS';
export const OPTOUT_RESULT_NOT_ENROLLED = 'NOT_ENROLLED';
export const OPTOUT_RESULT_FAIL = 'FAIL';

export const RECEIVE_UNENROL_USER = 'RECEIVE_UNENROL_USER';

// SMB actions
export const SET_SMB_PENDING = 'SET_SMB_PENDING';
export const SET_SMB_SUCCESS = 'SET_SMB_SUCCESS';
export const SET_SMB_FAILURE = 'SET_SMB_FAILURE';
export const SET_SMB_ENTRY_RESPONSE = 'SET_SMB_ENTRY_RESPONSE';
export const SET_SMB_ENTRY_ERROR = 'SET_SMB_ENTRY_ERROR';
export const SMB_ENTRY_DETAILS_PENDING = 'SMB_ENTRY_DETAILS_PENDING';

// AEM Api actions
export const GET_AEM_ALL = 'GET_AEM_ALL';
export const GET_AEM_PROMOS = 'GET_AEM_PROMOS';
export const SET_AEM_PENDING = 'SET_AEM_PENDING';
export const GET_AEM_PENDING = 'GET_AEM_PENDING';
export const SET_AEM_READY = 'SET_AEM_READY';
export const ROUTES_READY = 'ROUTES_READY';

// Contact Capture actions
export const SET_MOBILE_FETCHING = 'SET_MOBILE_FETCHING';
export const GET_MOBILE_NUMBER_VERIFIED = 'GET_MOBILE_NUMBER_VERIFIED';
export const GET_MOBILE_NUMBER_SUCCESS = 'GET_MOBILE_NUMBER_SUCCESS';
export const GET_MOBILE_TEMP_NUMBER_SUCCESS = 'GET_MOBILE_TEMP_NUMBER_SUCCESS';
export const GET_MOBILE_FAILURE = 'GET_MOBILE_FAILURE';
export const GET_MOBILE_RESET = 'GET_MOBILE_RESET';
export const GET_MOBILE_TRIGGERED = 'GET_MOBILE_TRIGGERED';
export const SET_EMAIL_FETCHING = 'SET_EMAIL_FETCHING';
export const GET_EMAIL_VERIFIED = 'GET_EMAIL_VERIFIED';
export const GET_EMAIL_SUCCESS = 'GET_EMAIL_SUCCESS';
export const GET_EMAIL_TEMP_SUCCESS = 'GET_EMAIL_TEMP_SUCCESS';
export const GET_EMAIL_FAILURE = 'GET_EMAIL_FAILURE';
export const GET_EMAIL_RESET = 'GET_EMAIL_RESET';
export const GET_EMAIL_TRIGGERED = 'GET_EMAIL_TRIGGERED';
export const SET_OTP_FETCHING = 'SET_OTP_FETCHING';
export const SET_OTP_FLOW = 'SET_OTP_FLOW';
export const GET_OTP_SUCCESS = 'GET_OTP_SUCCESS';
export const GET_OTP_FAILURE = 'GET_OTP_FAILURE';
export const GET_OTP_RESEND = 'GET_OTP_RESEND';
export const GET_VERIFIED_RESET = 'GET_VERIFIED_RESET';
export const GET_OTP_RESET = 'GET_OTP_RESET';
export const SET_VERIFIED_TRIGGERED = 'SET_VERIFIED_TRIGGERED';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_MASKED_ADDRESS = 'SET_MASKED_ADDRESS';
export const SET_ADDRESS_ID = 'SET_ADDRESS_ID';
export const SET_ADDRESS_CONFIRM = 'SET_ADDRESS_CONFIRM';
export const SET_ADDRESS_ERROR = 'SET_ADDRESS_ERROR';
export const SET_ADDRESS_TRIGGERED = 'SET_ADDRESS_TRIGGERED';
export const SET_MANDATORY_ERROR = 'SET_MANDATORY_ERROR';

// Transfer points
export const TRANSFER_POINTS_SUCCESS = 'TRANSFER_POINTS_SUCCESS';
export const TRANSFER_POINTS_FAILURE = 'TRANSFER_POINTS_FAILURE';
export const SET_TRANSFER_POINTS_SUCCESS = 'SET_TRANSFER_POINTS_SUCCESS';

// Tickets
export const GET_TICKETS_ARTS = 'GET_TICKETS_ARTS';
export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_CHILD = 'GET_EVENTS_CHILD';
export const SET_EVENTS_ERROR = 'SET_EVENTS_ERROR';
export const RESET_EVENTS_ERROR = 'RESET_EVENTS_ERROR';
export const SET_MOVIES_ERROR = 'SET_MOVIES_ERROR';
export const RESET_MOVIES_ERROR = 'RESET_MOVIES_ERROR';
export const FETCH_TICKETS = 'FETCH_TICKETS';
export const SET_EVENTS_CHILD_PAGE_GLOBAL_ERROR =
  'SET_EVENTS_CHILD_PAGE_GLOBAL_ERROR';
export const RESET_EVENTS_CHILD_PAGE_GLOBAL_ERROR =
  'RESET_EVENTS_CHILD_PAGE_GLOBAL_ERROR';
export const SET_EVENTS_CHILD_DEFAULT = 'SET_EVENTS_CHILD_DEFAULT';

// Consent Offer
export const SP_LOG_ENTRY_SUCCESS = 'SP_LOG_ENTRY_SUCCESS';
export const SET_SP_LOG_ENTRY_LOADING = 'SET_SP_LOG_ENTRY_LOADING';
export const SP_LOG_ENTRY_FAILURE = 'SP_LOG_ENTRY_FAILURE';
export const SET_SP_LOG_ENTRY_SUCCESS = 'SET_SP_LOG_ENTRY_SUCCESS';

// Partners
export const GET_PARTNER_TOKEN_PENDING = 'GET_PARTNER_TOKEN_PENDING';
export const GET_PARTNER_TOKEN_FAILURE = 'GET_PARTNER_TOKEN_FAILURE';
export const GET_PARTNER_TOKEN_SUCCESS = 'GET_PARTNER_TOKEN_SUCCESS';

// Update contact details
export const SET_UPDATECONTACTDETAILS_SUCCESS =
  'SET_UPDATECONTACTDETAILS_SUCCESS';
export const SET_UPDATECONTACTDETAILS_ERROR = 'SET_UPDATECONTACTDETAILS_ERROR';

// Authorable Forms
export const SET_NEW_CAMPAIGN = 'SET_NEW_CAMPAIGN';
export const GET_ENTRY_PENDING = 'GET_ENTRY_PENDING';
export const GET_ENTRY_SUCCESS = 'GET_ENTRY_SUCCESS';
export const GET_ENTRY_FAILURE = 'GET_ENTRY_FAILURE';
export const GET_PREVIOUS_ENTRY_PENDING = 'GET_PREVIOUS_ENTRY_PENDING';
export const GET_PREVIOUS_ENTRY_SUCCESS = 'GET_PREVIOUS_ENTRY_SUCCESS';
export const GET_PREVIOUS_ENTRY_FAILURE = 'GET_PREVIOUS_ENTRY_FAILURE';

// Experience API
export const RENDER_EXP_CONTENT_SUCCESS = 'RENDER_EXP_CONTENT_SUCCESS';
export const SET_EXP_CONTENT = 'SET_EXP_CONTENT';
export const REMOVE_EXP_CONTENT = 'REMOVE_EXP_CONTENT';
export const CLEAR_EXP_CONTENT = 'CLEAR_EXP_CONTENT';

// Rewards
export const SET_REWARD_PROFILE = 'SET_REWARD_PROFILE';
export const GET_REWARD_CATALOG_PENDING = 'GET_REWARD_CATALOG_PENDING';
export const GET_REWARD_CATALOG_SUCCESS = 'GET_REWARD_CATALOG_SUCCESS';
export const GET_REWARD_CATALOG_FAILURE = 'GET_REWARD_CATALOG_FAILURE';
export const GET_REWARD_PRODUCT_PENDING = 'GET_REWARD_PRODUCT_PENDING';
export const GET_REWARD_PRODUCT_SUCCESS = 'GET_REWARD_PRODUCT_SUCCESS';
export const GET_REWARD_PRODUCT_FAILURE = 'GET_REWARD_PRODUCT_FAILURE';
export const SET_REWARD_PRODUCT_FILTER = 'SET_REWARD_PRODUCT_FILTER';
export const RESET_REWARD_PRODUCT_FILTER = 'RESET_REWARD_PRODUCT_FILTER';
export const SET_REWARD_STOCK_FILTER = 'SET_REWARD_STOCK_FILTER';
export const SET_REWARD_PRODUCT_SORT = 'SET_REWARD_PRODUCT_SORT';
export const GET_REWARD_QUICKSEARCH_PENDING = 'GET_REWARD_QUICKSEARCH_PENDING';
export const GET_REWARD_QUICKSEARCH_SUCCESS = 'GET_REWARD_QUICKSEARCH_SUCCESS';
export const GET_REWARD_QUICKSEARCH_FAILURE = 'GET_REWARD_QUICKSEARCH_FAILURE';
export const CLEAR_REWARD_QUICKSEARCH = 'CLEAR_REWARDS_QUICKSEARCH';
export const SET_REWARD_SEARCH_QUERY = 'SET_REWARD_SEARCH_QUERY';
export const RESET_REWARD_PRODUCT = 'RESET_REWARD_PRODUCT';
export const RESET_REWARD_FILTER_VIEW = 'RESET_REWARD_FILTER_VIEW';
export const CHECKOUT_PENDING = 'CHECKOUT_PENDING';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE';
export const SET_REWARD_CATEGORIES = 'SET_REWARD_CATEGORIES';
export const RESET_REWARD_CATEGORIES_FILTER = 'RESET_REWARD_CATEGORIES_FILTER';
export const SET_REWARD_OFFER_TYPES = 'SET_REWARD_OFFER_TYPES';
export const RESET_REWARD_OFFER_TYPES = 'RESET_REWARD_OFFER_TYPES';
export const SET_RS_ERROR='SET_RS_ERROR';
export const GET_REWARD_CATALOG_NO_SERVICE='GET_REWARD_CATALOG_NO_SERVICE';
export const GET_REWARD_PRODUCT_NO_SERVICE='GET_REWARD_PRODUCT_NO_SERVICE';


// NowShowing API
export const NOW_SHOWING_SUCCESS = 'NOW_SHOWING_SUCCESS';
export const NOW_SHOWING_PENDING = 'NOW_SHOWING_PENDING';
export const NOW_SHOWING_FAILURE = 'NOW_SHOWING_FAILURE';
export const SET_NOW_SHOWING = 'SET_NOW_SHOWING';

// Product Stocks
export const GET_REWARD_PRODUCT_STOCKS_PENDING = 'GET_REWARD_PRODUCT_STOCKS_PENDING';
export const GET_REWARD_PRODUCT_STOCKS_SUCCESS = 'GET_REWARD_PRODUCT_STOCKS_SUCCESS';
export const GET_REWARD_PRODUCT_STOCKS_FAILURE = 'GET_REWARD_PRODUCT_STOCKS_FAILURE';
