import React, { useEffect } from 'react';
import { RadioGroup, RadioButton } from '@able/react';
import { useFocus } from 'utils/hooks/useFocus';

const FormCheckboxGroup = props => {
  const {
    label = '',
    name = '',
    radios = {},
    handleChange,
    submitCount = 0,
    isSubmitting = false,
    isValidating = false,
    errors = {},
    setTouched
  } = props;

  const errored = errors[name] && submitCount > 0;
  const [inputRef, setInputFocus] = useFocus();

  useEffect(() => {
    if (isSubmitting && !isValidating && Object.keys(errors)[0] === name) {
      setInputFocus();
    }
  }, [isSubmitting, isValidating, errors]);

  const onChange = e => {
    setTouched({ [name]: false });
    handleChange(e);
  };

  return (
    <div className="tplus-form-field" id={name}>
      <RadioGroup
        developmentUrl="/able-sprites.svg"
        groupLabel={label}
        name={name}
        variant="Default"
        invalid={errored}
        errorText={errors[name]}
      >
        {Object.keys(radios).map((key, i) => {
          return (
            <RadioButton
              key={i}
              name={name}
              ref={i === 0 ? inputRef : null}
              // checked={selected}
              label={radios[key].label}
              value={radios[key].value}
              events={{ onChange: e => onChange(e) }}
            />
          );
        })}
      </RadioGroup>
    </div>
  );
};

export default FormCheckboxGroup;
