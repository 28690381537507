import { createSelector } from 'reselect';
import _ from 'lodash';

const catalogView = state => state.rewards.catalogView;

const filterByAttributes = (pfList, criterias) => {
  return pfList.filter(pf => {
    const { products } = pf;
    return products.some((product, index) => {
      const { attributes: productAttributes } = product;
      let hasAllAttributes = true;
      let hasAllKeys = true;
      _.forOwn(criterias, (attr, attrKey) => {
        if (!_.isEmpty(attr)) {
          let keyFound = false;
          productAttributes.forEach(pa => {
            if (pa.id.toString() === attrKey) {
              keyFound = true;
              const foundAttribute = attr.some(a => pa.value === a || pa.value.indexOf(a) > -1);
              if (!foundAttribute) {
                hasAllAttributes = false;
              }
            }
          });
          if (!keyFound) {
            hasAllKeys = false;
          }
        }
      });
      return hasAllKeys && hasAllAttributes;
    });
  });
};

const filterByStock = pfList => {
  return pfList.filter(pf => {
    return pf.products.some(product => !product.outOfStock);
  });
};

const filterByPostage = pfList => {
  return pfList.filter(product => product.type !== "POSTAGE");
};

const filterByBrand = (pfList, pbList, criterias) => {
  const temppbList = [];
  criterias.forEach(brand => {
    if (pbList[brand]) {
      const { items: pfIds } = pbList[brand];
      temppbList.push(...pfIds);
    }
  });
  return pfList.filter(pf=>{
    return temppbList.some(pb=>{
      return pf.id === pb
    })
  })
};

export const getFilteredCatalog = createSelector([catalogView], catalog => {
  const {
    productFamilies,
    productBrands,
    filters: { brands, attributes },
    stockFilter
  } = catalog;
  const hasAttributes = Object.values(attributes) && _.flatten(Object.values(attributes))?.length > 0;
  const hasBrands = brands.length > 0;
  let filtered = productFamilies;

  if (hasBrands) {
    filtered = filterByBrand(productFamilies, productBrands, brands);
  }
  if (hasAttributes) {
    filtered = filterByAttributes(filtered, attributes);
  }
  if (stockFilter) {
    filtered = filterByStock(filtered);
  }
  filtered = filterByPostage(filtered);
  return filtered;
});

export const getAttributeList = createSelector([catalogView], catalog => {
  const { productFamilies } = catalog;
  const attributeList = [];

  productFamilies.forEach(pf => {
    const { products } = pf;
    products.forEach(product => {
      const { attributes } = product;
      attributes.forEach(attribute => {
        const { id, value, name, type } = attribute;
        const index = value.indexOf("<>");
        let values = [];
        if (index > -1) {
          values = value.split("<>");
        } else {
          values.push(value);
        }
        const addedToList = _.find(attributeList, item => item.id === id);
        if (addedToList) {
          values.forEach(val => {
            const { variations } = addedToList;
            const addedVariation = variations.some(v => v === val);
            if (!addedVariation) {
                variations.push(val);
                variations.sort();
            }
          });
        } else {
          const newAttribute = {
            id,
            name,
            type,
            variations: [values[0]]
          };
          if (values.length > 1) {
            values.splice(0,1);
            values.forEach(val => {
              if (newAttribute.variations.indexOf(val) < 0) {
                newAttribute.variations.push(val)
              }
            });
          }
          attributeList.push(newAttribute);
        }
      });
    });
  });

  return attributeList;
});
