import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HtmlConverter } from 'utils/HtmlConverter';
import _ from 'lodash';

import { Spacing, TextStyle, Lozenge, ActionButton } from '@able/react';
import { Cta } from 'components';

import './BenefitTile.scss';

const BenefitTile = props => {
  const {
    id = '',
    callout = '',
    title = '',
    heading = '',
    subHeading = '',
    body = '',
    campaignDataAttribute = {},
    finePrint = '',
    redemptionStatus = '',
    backgroundImg = '',
    backgroundImgAlt = ''
  } = props;

  // The CTA properties from AEM and EAPI differ,
  // So we need to map them correctly.
  // AEM passes an object, EAPI passes an Array...pretty annoying
  const mapCtas = ctas => {
    if (!(ctas instanceof Array)) {
      const ctasArr = _.values(ctas);
      return ctasArr.map(cta => {
        return { ...cta, ctaCopy: cta.ctaText };
      });
    }
    return ctas;
  };

  const cta = mapCtas(props.cta) || [];
  const [dynamicContent, setDynamicContent] = useState({
    redemptionStatus,
    body,
    cta
  });
  const cta1 = dynamicContent.cta[0] && cta[0].ctaCopy ? cta[0] : null;
  const cta2 = cta[1] && cta[1].ctaCopy ? cta[1] : null;
  const loyaltyTier = useSelector(state => state.accounts.loyaltyTier);
  const accountId = useSelector(state => state.accounts.accountId);

  // LPT-3449 SMB Tech benefit business logic.
  // Update the properties to show that SMB has been redeemed. Save it
  // to localstorage so we can retain the status on page refreshes.
  // Keep showing the redeemed status until experience api passes through a
  // status.
  const handleSmbTrigger = () => {
    localStorage.setItem('smbTechRedeemed', accountId);
    const updatedContent = {
      ...dynamicContent,
      cta: () => dynamicContent.cta.shift(),
      redemptionStatus: 'Redeemed',
      body:
        'Your tier benefit is currently being added to your account. For urgent queries call <a href="tel:13 70 55"><u>13 70 55</u></a>'
    };
    setDynamicContent(updatedContent);
  };

  useEffect(() => {
    const smbTechRedeemed = localStorage.getItem('smbTechRedeemed');
    if (
      cta1 &&
      cta1.type === 'smb' &&
      smbTechRedeemed === accountId &&
      !redemptionStatus
    ) {
      handleSmbTrigger();
    }
  }, []);

  const renderCtas = () => {
    return (
      <>
        {cta && cta.length ? (
          <>
            <Spacing bottom="spacing1x" />
            {/* LPT-3449 SMB tech specific CTA */}
            {cta1 && cta1.type === 'smb' && (
              <div>
                <ActionButton
                  variant="MediumEmphasis"
                  element="button"
                  label={cta1.ctaCopy}
                  onClick={() => handleSmbTrigger()}
                  aria-labelledby={`${id}-primary-cta ${id}-heading`}
                />
                <Spacing bottom="spacing2x" />
              </div>
            )}
            {cta1 && cta1.type !== 'smb' && (
              <div>
                <Cta
                  action="basic"
                  url={cta1.ctaUrl}
                  ctaProps={{
                    id: `${id}-primary-cta`,
                    label: cta1.ctaCopy,
                    variant: 'MediumEmphasis',
                    element: 'a',
                    'aria-labelledby': `${id}-primary-cta ${id}-heading`
                  }}
                />
                <Spacing bottom="spacing2x" />
              </div>
            )}
            {cta2 && (
              <div>
                <Cta
                  action="basic"
                  url={cta2.ctaUrl}
                  ctaProps={{
                    id: `${id}-secondary-cta`,
                    label: cta2.ctaCopy,
                    variant: 'LowEmphasis',
                    element: 'a',
                    'aria-labelledby': `${id}-secondary-cta ${id}-heading`
                  }}
                />
              </div>
            )}
          </>
        ) : null}
      </>
    );
  };

  const renderTierLozenge = label => {
    let variant = '';

    switch (loyaltyTier) {
      case 'SILVER':
        variant = 'NeutralEmphasis';
        break;
      case 'GOLD':
        variant = 'TPlusGold';
        break;
      case 'BUSINESS':
        variant = 'TierEmphasis';
        break;
      default:
        variant = 'TierEmphasis';
    }

    return (
      <Lozenge
        label={label}
        variant={variant}
        className="tplus-benefits-tile__tier"
      />
    );
  };

  return (
    <div className="tplus-benefits-tile" id={id} {...campaignDataAttribute}>
      {backgroundImg && (
        <div>
          <img src={backgroundImg} alt={backgroundImgAlt} loading="lazy" />
          <div
            className={`tplus-benefits-tile__gradient ${loyaltyTier.toLowerCase()}`}
          />
          <Spacing bottom="spacing2x" />
        </div>
      )}
      <div className="tplus-benefits-tile__content">
        {heading && (
          <Spacing bottom="spacing2x" className="tplus-benefits-tile__heading">
            <TextStyle alias="HeadingD" element="h3" id={`${id}-heading`}>
              {heading}
            </TextStyle>
          </Spacing>
        )}
        {callout && renderTierLozenge(callout)}
        {dynamicContent.redemptionStatus && (
          <Spacing bottom="spacing2x" className="tplus-benefits-tile__status">
            <Lozenge
              label={_.startCase(_.toLower(dynamicContent.redemptionStatus))}
              variant="Neutral"
            />
          </Spacing>
        )}
        {title && (
          <Spacing bottom="spacing2x" className="tplus-benefits-tile__title">
            <TextStyle alias="Tag" element="span">
              {title}
            </TextStyle>
          </Spacing>
        )}
        {subHeading && (
          <Spacing
            bottom="spacing2x"
            className="tplus-benefits-tile__subheading"
          >
            <TextStyle alias="Label" element="h4">
              {subHeading}
            </TextStyle>
          </Spacing>
        )}
        {dynamicContent.body && (
          <Spacing bottom="spacing2x" className="tplus-benefits-tile__body">
            <TextStyle alias="Base" element="p">
              {HtmlConverter(dynamicContent.body)}
            </TextStyle>
          </Spacing>
        )}
        {finePrint && (
          <Spacing
            bottom="spacing2x"
            className="tplus-benefits-tile__fineprint"
          >
            <TextStyle alias="FinePrint" element="p">
              {finePrint}
            </TextStyle>
          </Spacing>
        )}
      </div>
      {renderCtas()}
    </div>
  );
};

export default BenefitTile;
