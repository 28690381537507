import { createSelector } from 'reselect';
import _ from 'lodash';

const getAccount = state => state.accounts;
const getPoints = state => state.points;

export const getDefaultAccountPointsValue = createSelector(
  getAccount,
  account => {
    let newAccount;
    if (account) {
      newAccount = _.clone(account);
      if (
        !account.pointsValue ||
        (account.pointsValue && parseInt(account.pointsValue) === 0)
      ) {
        newAccount.pointsValue = '0';
      }
    }
    return newAccount;
  }
);

export const getDefaultTotalPoints = createSelector(getPoints, points => {
  let newPoints;
  if (points) {
    newPoints = points;
    if (
      !points.totalPoints ||
      (points.totalPoints && parseInt(points.totalPoints) === 0)
    ) {
      newPoints.totalPoints = '0';
    }
  }
  return newPoints;
});
