/* eslint-disable dot-notation */
import React from 'react';
import { TextStyle, Spacing } from '@able/react';
import { HtmlConverter } from 'utils/HtmlConverter';
import { Link } from 'react-router-dom';

import { Cta } from 'components';

import './PageHeader.scss';

const PageHeader = props => {
  const {
    heading = '',
    pageHeadline = '',
    bodyCopy = '',
    desktopImageUrl = '',
    tabletImageUrl = '',
    tabletMiniImageUrl = '',
    mobileImageUrl = '',
    imageText = '',
    terms = '',
    ctaConfig = {}
  } = props;

  const cta1 = (ctaConfig['item0'] && ctaConfig['item0'].ctaText) ? ctaConfig['item0'] : null;
  const cta2 = (ctaConfig['item1'] && ctaConfig['item1'].ctaText) ? ctaConfig['item1'] : null;
  const hasValidCta =
    cta1 &&
    cta1.ctaUrl &&
    (cta1.ctaAction === 'basic' ||
      cta1.ctaAction === 'spaRoute' ||
      cta1.ctaAction === 'externalUrl');
  const isInternalUrl = cta1 && /^\//.test(cta1.ctaUrl);

  const imageBackground = () => (
    <div className="tplus-pageheader-image-container tier2 tplus-pageheader-image">
      <picture>
        <source media="(min-width:1360px)" srcSet={desktopImageUrl} />
        <source media="(min-width:1024px) and (max-width:1359px)" srcSet={tabletImageUrl} />
        <source media="(min-width:768px) and (max-width:1023px)" srcSet={tabletMiniImageUrl} />
        <source media="(min-width:320px) and (max-width:767px)" srcSet={mobileImageUrl} />
        <img src={desktopImageUrl} alt={imageText} />
      </picture>
      {/* Animation SR description */}
      {/* <div data-sly-test="${properties.richMediaType == 'animation'}" data-animation-container data-animation-id="${analyticsID}-animation" data-animation-loop="${properties.animationLoop}" data-animation-content-path="${properties.animationContentPath}">
        <p class="sr-only">${properties.animationDescription @ context='unsafe'}</p>
      </div> */}
    </div>
  );

  const imageContainer = () => {
    if (hasValidCta) {
      if (isInternalUrl) {
        return (
          <Link tabIndex="-1" aria-hidden="true" to={cta1 && cta1.ctaUrl}>
            {imageBackground()}
          </Link>
        );
      }
      return (
        <a href={cta1.ctaUrl || ''} tabIndex="-1" aria-hidden="true">
          {imageBackground()}
        </a>
      );
    }
    return imageBackground();
  };

  return (
    <Spacing top="spacing1x">
      <div className="width-correct-wrapper">
        <div className="tplus-pageheader-wrapper">
          {heading && (
            <>
              <div className="tplus-page-header-brand">
                <TextStyle
                  alias="HeadingC"
                  element="h1"
                  className="tplus-pageheader-heading--top"
                >
                  {heading}
                </TextStyle>
              </div>
              <Spacing bottom="spacing1x" />
            </>
          )}
          <div className="tplus-pageheader tplus-pageheader--with-image">
            <div className="tplus-pageheader-content-container tplus-pageheader-content-container--with-image tier2">
              {pageHeadline && (
                <>
                  <TextStyle alias="HeadingA" element="h2">
                    {pageHeadline}
                  </TextStyle>
                  <Spacing bottom="spacing2x" />
                </>
              )}
              {bodyCopy && (
                <>
                  <TextStyle alias="BaseBig" element="p">
                    {HtmlConverter(bodyCopy)}
                  </TextStyle>
                  <Spacing bottom="spacing2x" />
                </>
              )}
              {terms && (
                <TextStyle alias="FinePrint" element="p" colour="Subtle">
                  {HtmlConverter(terms)}
                </TextStyle>
              )}
              {cta1 && (
                <div className="tplus-pageheader-cta-container">
                  <Spacing bottom="spacing3x" />
                  <Cta
                    action={cta1.ctaAction}
                    trackingCode={cta1.trackingCode}
                    url={cta1.ctaUrl}
                    ctaProps={{
                      variant: cta1.ctaVariant,
                      label: cta1.ctaText
                    }}
                  />
                </div>
              )}
              {cta2 && (
                <div className="tplus-pageheader-cta-container">
                  <Spacing bottom="spacing1x" />
                  <Cta
                    action={cta2.ctaAction}
                    trackingCode={cta2.trackingCode}
                    url={cta2.ctaUrl}
                    ctaProps={{
                      variant: cta2.ctaVariant,
                      label: cta2.ctaText
                    }}
                  />
                </div>
              )}
            </div>
            {imageContainer()}
          </div>
        </div>
      </div>
    </Spacing>
  );
};

export default PageHeader;
