import React, { useState } from 'react';
import { Grid, Column, TextStyle, Spacing, Icon } from '@able/react';
import convert from 'htmr';
import { isMobile } from 'react-device-detect';
import './MemberTierTable.scss';

import {
  EARNPOINTS,
  REDEEMPOINTS,
  TICKETS,
  VIPSERVICES,
} from 'constants/benefitTiles.js';

const MemberTierTable = props => {
  const { tier = 'NONE' } = props;

  const [activeTab, setactiveTab] = useState('member');

  const changeTab = tab => {
    setactiveTab(tab);
  };

  const renderMobileHeader = () => {
    switch (tier) {
      case 'MEMBER':
      case 'SILVER':
      case 'GOLD':
        return (
          <ul className="tabs">
            <li className={activeTab === 'member' ? 'activeMember' : undefined}>
              <button type="button" onClick={() => changeTab('member')}>
                Member
              </button>
            </li>
            <li className={activeTab === 'silver' ? 'activeSilver' : undefined}>
              <button type="button" onClick={() => changeTab('silver')}>
                Silver
              </button>
            </li>
            <li className={activeTab === 'gold' ? 'activeGold' : undefined}>
              <button type="button" onClick={() => changeTab('gold')}>
                Gold
              </button>
            </li>
          </ul>
        );
      case 'BUSINESS':
        return null;
      default:
        return (
          <ul className="tabs">
            <li className={activeTab === 'member' ? 'activeMember' : undefined}>
              <button type="button" onClick={() => changeTab('member')}>
                Member
              </button>
            </li>
            <li className={activeTab === 'silver' ? 'activeSilver' : undefined}>
              <button type="button" onClick={() => changeTab('silver')}>
                Silver
              </button>
            </li>
            <li className={activeTab === 'gold' ? 'activeGold' : undefined}>
              <button type="button" onClick={() => changeTab('gold')}>
                Gold
              </button>
            </li>
            <li
              className={
                activeTab === 'business' ? 'activeBusiness' : undefined
              }
            >
              <button type="button" onClick={() => changeTab('business')}>
                Business
              </button>
            </li>
          </ul>
        );
    }
  };

  const renderMobileContents = () => {
    const renderMember = () => {
      return (
        <div className="benefit-tile member">
          <div className="text-container">
            <h3 className="tile-title">Member</h3>
            <p className="tile-avg-spend">
              $0 - $1,499
              <br /> Annual spend
            </p>
            <p className="tile-copy">
              Overall annual eligible spend
              <br />
              <br />
              If you&#39;re new to Telstra with a personal service, you&#39;ll start on
              this tier.
            </p>
          </div>
          <ul className="benefits-list member">
            <li className="title-icon">
              <span className="title-span">
                <Icon
                  icon="EarnPoints"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                Earn Points
              </span>
            </li>
            <li className="able-grid">
              <Column cols={2} className="tick-icon-column">
                <Icon
                  icon="Tick"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="member-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </Column>
              <Column cols={10}>{EARNPOINTS}</Column>
            </li>
          </ul>
          <ul className="benefits-list member">
            <li className="title-icon">
              <span className="title-span">
                <Icon
                  icon="Cart"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                Redeem Points
              </span>
            </li>
            <li className="able-grid">
              <Column cols={2} className="tick-icon-column">
                <Icon
                  icon="Tick"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="member-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </Column>
              <Column cols={10}>{convert(REDEEMPOINTS)}</Column>
            </li>
            <li className="able-grid">
              <Column cols={2} className="tick-icon-column">
                <Icon
                  icon="Tick"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="member-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </Column>
              <Column cols={10}>
                Redeem your points for selected Telstra TV Box Office
                entertainment with a Telstra TV
              </Column>
            </li>
          </ul>
          <ul className="benefits-list member">
            <li className="title-icon">
              <span className="title-span">
                <Icon
                  icon="Ticket"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                Tickets
              </span>
            </li>
            {TICKETS.map((item, index) => {
              return (
                <li className="able-grid" key={index}>
                  <Column cols={2} className="tick-icon-column">
                    <Icon
                      icon="Tick"
                      size="24"
                      developmentUrl="/able-sprites.svg"
                      role="img"
                      className="member-tick"
                      screenReaderContent="Available in this membership tier"
                    />
                  </Column>
                  <Column cols={10}>{item}</Column>
                </li>
              );
            })}
          </ul>
        </div>
      );
    };

    const renderSilver = () => {
      return (
        <div className="benefit-tile silver">
          <div className="text-container">
            <h3 className="tile-title">Silver</h3>
            <p className="tile-avg-spend">
              $1,500 - $2,999
              <br /> Annual spend
            </p>
            <p className="tile-copy">Overall annual eligible spend</p>
          </div>
          <ul className="benefits-list silver">
            <li className="title-icon">
              <span className="title-span">
                <Icon
                  icon="EarnPoints"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                Earn Points
              </span>
            </li>
            <li className="able-grid">
              <Column cols={2} className="tick-icon-column">
                <Icon
                  icon="Tick"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="silver-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </Column>
              <Column cols={10}>{EARNPOINTS}</Column>
            </li>
          </ul>
          <ul className="benefits-list silver">
            <li className="title-icon">
              <span className="title-span">
                <Icon
                  icon="Cart"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                Redeem Points
              </span>
            </li>
            <li className="able-grid">
              <Column cols={2} className="tick-icon-column">
                <Icon
                  icon="Tick"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="silver-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </Column>
              <Column cols={10}>{convert(REDEEMPOINTS)}</Column>
            </li>
            <li className="able-grid">
              <Column cols={2} className="tick-icon-column">
                <Icon
                  icon="Tick"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="silver-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </Column>
              <Column cols={10}>
                Redeem your points for selected Telstra TV Box Office
                entertainment with a Telstra TV
                <br />
                <br />
                Get 25% off points required to redeem.
              </Column>
            </li>
          </ul>
          <ul className="benefits-list silver">
            <li className="title-icon">
              <span className="title-span">
                <Icon
                  icon="Ticket"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                Tickets
              </span>
            </li>
            {TICKETS.map((item, index) => {
              return (
                <li className="able-grid" key={index}>
                  <Column cols={2} className="tick-icon-column">
                    <Icon
                      icon="Tick"
                      size="24"
                      developmentUrl="/able-sprites.svg"
                      role="img"
                      className="silver-tick"
                      screenReaderContent="Available in this membership tier"
                    />
                  </Column>
                  <Column cols={10}>{item}</Column>
                </li>
              );
            })}
          </ul>
          <ul className="benefits-list silver">
            <li className="title-icon">
              <span className="title-span">
                <Icon
                  icon="VIP"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                Service Benefits
              </span>
            </li>

            <li className="able-grid">
              <Column cols={2} className="tick-icon-column">
                <Icon
                  icon="Tick"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="silver-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </Column>
              <Column cols={10}>
                Silver service when you message us via My Telstra app.
              </Column>
            </li>
          </ul>
        </div>
      );
    };

    const renderGold = () => {
      return (
        <div className="benefit-tile gold">
          <div className="text-container">
            <h3 className="tile-title">Gold</h3>
            <p className="tile-avg-spend">
              $3,000+
              <br /> Annual spend
            </p>
            <p className="tile-copy">Overall annual eligible spend</p>
          </div>
          <ul className="benefits-list gold">
            <li className="title-icon">
              <span className="title-span">
                <Icon
                  icon="EarnPoints"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                Earn Points
              </span>
            </li>
            <li className="able-grid">
              <Column cols={2} className="tick-icon-column">
                <Icon
                  icon="Tick"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="gold-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </Column>
              <Column cols={10}>{EARNPOINTS}</Column>
            </li>
          </ul>
          <ul className="benefits-list gold">
            <li className="title-icon">
              <span className="title-span">
                <Icon
                  icon="Cart"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                Redeem Points
              </span>
            </li>
            <li className="able-grid">
              <Column cols={2} className="tick-icon-column">
                <Icon
                  icon="Tick"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="gold-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </Column>
              <Column cols={10}>{convert(REDEEMPOINTS)}</Column>
            </li>
            <li className="able-grid">
              <Column cols={2} className="tick-icon-column">
                <Icon
                  icon="Tick"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="gold-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </Column>
              <Column cols={10}>
                Redeem your points for selected Telstra TV Box Office
                entertainment with a Telstra TV
                <br />
                <br />
                Get 50% off points required to redeem.
              </Column>
            </li>
          </ul>
          <ul className="benefits-list gold">
            <li className="title-icon">
              <span className="title-span">
                <Icon
                  icon="Ticket"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                Tickets
              </span>
            </li>
            {TICKETS.map((item, index) => {
              return (
                <li className="able-grid" key={index}>
                  <Column cols={2} className="tick-icon-column">
                    <Icon
                      icon="Tick"
                      size="24"
                      developmentUrl="/able-sprites.svg"
                      role="img"
                      className="gold-tick"
                      screenReaderContent="Available in this membership tier"
                    />
                  </Column>
                  <Column cols={10}>{item}</Column>
                </li>
              );
            })}
          </ul>       
          <ul className="benefits-list gold">
            <li className="title-icon">
              <span className="title-span">
                <Icon
                  icon="VIP"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                Service Benefits
              </span>
            </li>

            <li className="able-grid">
              <Column cols={2} className="tick-icon-column">
                <Icon
                  icon="Tick"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="gold-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </Column>
              <Column cols={10}>
                VIP service when you message us via My Telstra App for help with
                your tech and Telstra services.
              </Column>
            </li>
          </ul>
        </div>
      );
    };

    const renderBusiness = () => {
      return (
        <div className="benefit-tile business">
          <div className="text-container">
            <h3 className="tile-title">Business</h3>
            <p className="tile-avg-spend">
              For customers with an eligible business service. Excludes
              enterprise and corporate customers.
            </p>
          </div>
          <ul className="benefits-list business">
            <li className="title-icon">
              <span className="title-span">
                <Icon
                  icon="EarnPoints"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                Earn Points
              </span>
            </li>
            <li className="able-grid">
              <Column cols={2} className="tick-icon-column">
                <Icon
                  icon="Tick"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="member-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </Column>
              <Column cols={10}>{EARNPOINTS}</Column>
            </li>
          </ul>
          <ul className="benefits-list business">
            <li className="title-icon">
              <span className="title-span">
                <Icon
                  icon="Cart"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                Redeem Points
              </span>
            </li>
            <li className="able-grid">
              <Column cols={2} className="tick-icon-column">
                <Icon
                  icon="Tick"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="member-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </Column>
              <Column cols={10}>{convert(REDEEMPOINTS)}</Column>
            </li>
            <li className="able-grid">
              <Column cols={2} className="tick-icon-column">
                <Icon
                  icon="Tick"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="member-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </Column>
              <Column cols={10}>
                Redeem your points for selected Telstra TV Box Office
                entertainment with a Telstra TV
              </Column>
            </li>
          </ul>
          <ul className="benefits-list business">
            <li className="title-icon">
              <span className="title-span">
                <Icon
                  icon="Ticket"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                Tickets
              </span>
            </li>
            {TICKETS.map((item, index) => {
              return (
                <li className="able-grid" key={index}>
                  <Column cols={2} className="tick-icon-column">
                    <Icon
                      icon="Tick"
                      size="24"
                      developmentUrl="/able-sprites.svg"
                      role="img"
                      className="member-tick"
                      screenReaderContent="Available in this membership tier"
                    />
                  </Column>
                  <Column cols={10}>{item}</Column>
                </li>
              );
            })}
          </ul>
          {/* <ul className="benefits-list business">
            <li className="title-icon">
              <span className="title-span">
                <Icon
                  icon="VIP"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                Service Benefits
              </span>
            </li>

            <li className="able-grid">
              <Column cols={2} className="tick-icon-column">
                <Icon
                  icon="Tick"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="member-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </Column>
              <Column cols={10}>
                3 months of Telstra Business Tech Services, on us. Offer ends 7 February 2023.
              </Column>
            </li>
          </ul> */}
        </div>
      );
    };

    switch (tier) {
      case 'MEMBER':
      case 'SILVER':
      case 'GOLD':
        switch (activeTab) {
          case 'member': {
            return renderMember();
          }
          case 'silver': {
            return renderSilver();
          }
          case 'gold': {
            return renderGold();
          }
          default: {
            return null;
          }
        }
      case 'BUSINESS': {
        return renderBusiness();
      }
      default: {
        switch (activeTab) {
          case 'member': {
            return renderMember();
          }
          case 'silver': {
            return renderSilver();
          }
          case 'gold': {
            return renderGold();
          }
          case 'business': {
            return renderBusiness();
          }
          default: {
            return null;
          }
        }
      }
    }
  };

  const renderTableHeader = () => {
    const renderTierStoreRows = () => {
      return (
        <>
          <th className="member" scope="col">
            <h3 className="tile-title">Member</h3>
            <p className="tile-avg-spend">$0 - $1,499</p>
            <p className="tile-copy">Overall annual eligible spend</p>
            <p className="tile-copy">
              If you’re new to Telstra with a personal service, you’ll start on
              this tier.
            </p>
          </th>
          <th className="silver" scope="col">
            <h3 className="tile-title">Silver</h3>
            <p className="tile-avg-spend">$1,500 - $2,999</p>
            <p className="tile-copy">Overall annual eligible spend</p>
          </th>
          <th className="gold" scope="col">
            <h3 className="tile-title">Gold</h3>
            <p className="tile-avg-spend">$3,000+</p>
            <p className="tile-copy">Overall annual eligible spend</p>
          </th>
        </>
      );
    };

    const renderBusinessStoreRow = () => {
      return (
        <>
          <th className="business" scope="col">
            <h3 className="tile-title">Business</h3>
            <p className="tile-copy">
              For customers with an eligible business service. Excludes
              Enterprise and corporate customers.
            </p>
          </th>
        </>
      );
    };

    switch (tier) {
      case 'MEMBER':
      case 'SILVER':
      case 'GOLD':
        return (
          <>
            <tr>
              <th />
              {renderTierStoreRows()}
            </tr>
          </>
        );
      case 'BUSINESS':
        return (
          <>
            <tr>
              <th />
              {renderBusinessStoreRow()}
            </tr>
          </>
        );
      default:
        return (
          <>
            <tr>
              <th />
              {renderTierStoreRows()}
              {renderBusinessStoreRow()}
            </tr>
          </>
        );
    }
  };

  const renderEarnPointsRow = () => {
    switch (tier) {
      case 'MEMBER':
      case 'SILVER':
      case 'GOLD':
        return (
          <>
            <tr className="title-row">
              <th scope="rowgroup" colSpan="5">
                <Icon
                  icon="EarnPoints"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                <span className="title-span">Earn points</span>
              </th>
            </tr>
            <tr className="content-row">
              <th scope="row">{EARNPOINTS}</th>
              <td>
                <Icon
                  icon="Tick"
                  size="32"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="member-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </td>
              <td>
                <Icon
                  icon="Tick"
                  size="32"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="silver-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </td>
              <td>
                <Icon
                  icon="Tick"
                  size="32"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="gold-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </td>
            </tr>
          </>
        );
      case 'BUSINESS':
        return (
          <>
            <tr className="title-row">
              <th scope="rowgroup" colSpan={2}>
                <Icon
                  icon="EarnPoints"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                <span className="title-span">Earn points</span>
              </th>
            </tr>
            <tr className="content-row">
              <th scope="row">{EARNPOINTS}</th>
              <td>
                <Icon
                  icon="Tick"
                  size="32"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="member-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </td>
            </tr>
          </>
        );
      default:
        return (
          <>
            <tr className="title-row">
              <th scope="rowgroup" colSpan={5}>
                <Icon
                  icon="EarnPoints"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                <span className="title-span">Earn points</span>
              </th>
            </tr>
            <tr className="content-row">
              <th scope="row">{EARNPOINTS}</th>
              <td>
                <Icon
                  icon="Tick"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="member-tick"
                />
              </td>
              <td>
                <Icon
                  icon="Tick"
                  size="32"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="silver-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </td>
              <td>
                <Icon
                  icon="Tick"
                  size="32"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="gold-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </td>
              <td>
                <Icon
                  icon="Tick"
                  size="32"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="member-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </td>
            </tr>
          </>
        );
    }
  };

  const renderRedeemPointsRow = () => {
    const renderTierStoreRows = () => {
      return (
        <>
          <th scope="row">{convert(REDEEMPOINTS)}</th>
          <td>
            <Icon
              icon="Tick"
              size="32"
              developmentUrl="/able-sprites.svg"
              role="img"
              className="member-tick"
              screenReaderContent="Available in this membership tier"
            />
          </td>
          <td>
            <Icon
              icon="Tick"
              size="32"
              developmentUrl="/able-sprites.svg"
              role="img"
              className="silver-tick"
              screenReaderContent="Available in this membership tier"
            />
          </td>
          <td>
            <Icon
              icon="Tick"
              size="32"
              developmentUrl="/able-sprites.svg"
              role="img"
              className="gold-tick"
              screenReaderContent="Available in this membership tier"
            />
          </td>
        </>
      );
    };

    const renderTierTtvRows = () => {
      return (
        <>
          <th scope="row">
            Redeem your points for selected Telstra TV Box Office entertainment
            with a Telstra TV
          </th>
          <td>
            <Icon
              icon="Tick"
              size="32"
              developmentUrl="/able-sprites.svg"
              role="img"
              className="member-tick"
              screenReaderContent="Available in this membership tier"
            />
          </td>
          <td className="has-text">
            <Icon
              icon="Tick"
              size="32"
              developmentUrl="/able-sprites.svg"
              role="img"
              className="silver-tick"
              screenReaderContent="Available in this membership tier"
            />
            <p>Get 25% off points required to redeem.</p>
          </td>
          <td className="has-text">
            <Icon
              icon="Tick"
              size="32"
              developmentUrl="/able-sprites.svg"
              role="img"
              className="gold-tick"
              screenReaderContent="Available in this membership tier"
            />
            <p>Get 50% off points required to redeem.</p>
          </td>
        </>
      );
    };

    const renderBusinessStoreRow = () => {
      return (
        <>
          <td>
            <Icon
              icon="Tick"
              size="32"
              developmentUrl="/able-sprites.svg"
              role="img"
              className="member-tick"
              screenReaderContent="Available in this membership tier"
            />
          </td>
        </>
      );
    };

    switch (tier) {
      case 'MEMBER':
      case 'SILVER':
      case 'GOLD':
        return (
          <>
            <tr className="title-row">
              <th scope="row" colSpan={4}>
                <Icon
                  icon="Cart"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                <span className="title-span">Redeem Points</span>
              </th>
            </tr>
            <tr className="content-row">{renderTierStoreRows()}</tr>
            <tr className="content-row">{renderTierTtvRows()}</tr>
          </>
        );
      case 'BUSINESS':
        return (
          <>
            <tr className="title-row">
              <th scope="row" colSpan={2}>
                <Icon
                  icon="Cart"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                <span className="title-span">Redeem Points</span>
              </th>
            </tr>
            <tr className="content-row">
              <th scope="row">{convert(REDEEMPOINTS)}</th>
              {renderBusinessStoreRow()}
            </tr>
            <tr className="content-row">
              <th scope="row">
                Redeem your points for selected Telstra TV Box Office
                entertainment with a Telstra TV
              </th>
              {renderBusinessStoreRow()}
            </tr>
          </>
        );
      default:
        return (
          <>
            <tr className="title-row">
              <th scope="rowgroup" colSpan={5}>
                <Icon
                  icon="Cart"
                  size="24"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                />
                <span className="title-span">Redeem Points</span>
              </th>
            </tr>
            <tr className="content-row">
              {renderTierStoreRows()}
              {renderBusinessStoreRow()}
            </tr>
            <tr className="content-row">
              {renderTierTtvRows()}
              {renderBusinessStoreRow()}
            </tr>
          </>
        );
    }
  };

  const renderTicketsHeader = () => {
    switch (tier) {
      case 'MEMBER':
      case 'SILVER':
      case 'GOLD':
        return (
          <tr className="title-row">
            <th scope="rowgroup" colSpan={4}>
              <Icon
                icon="Ticket"
                size="24"
                developmentUrl="/able-sprites.svg"
                role="img"
              />
              <span className="title-span">Tickets</span>
            </th>
          </tr>
        );
      case 'BUSINESS':
        return (
          <tr className="title-row">
            <th scope="rowgroup" colSpan={2}>
              <Icon
                icon="Ticket"
                size="24"
                developmentUrl="/able-sprites.svg"
                role="img"
              />
              <span className="title-span">Tickets</span>
            </th>
          </tr>
        );
      default:
        return (
          <tr className="title-row">
            <th scope="rowgroup" colSpan={5}>
              <Icon
                icon="Ticket"
                size="24"
                developmentUrl="/able-sprites.svg"
                role="img"
              />
              <span className="title-span">Tickets</span>
            </th>
          </tr>
        );
    }
  };

  const renderTicketsRow = () => {
    const renderTierRows = (item) => {
      return (
        <>
          <td>
            <Icon
              icon="Tick"
              size="32"
              developmentUrl="/able-sprites.svg"
              role="img"
              className="member-tick"
              screenReaderContent="Available in this membership tier"
            />
          </td>
          <td>
            <Icon
              icon="Tick"
              size="32"
              developmentUrl="/able-sprites.svg"
              role="img"
              className="silver-tick"
              screenReaderContent="Available in this membership tier"
            />
          </td>
          <td className="has-text">
            <Icon
              icon="Tick"
              size="32"
              developmentUrl="/able-sprites.svg"
              role="img"
              className="gold-tick"
              screenReaderContent="Available in this membership tier"
            />
            {item.indexOf('concert tickets') !== -1 &&
              convert('<p>Get even earlier access to concert pre-sales.</p>')}
          </td>
        </>
      );
    };

    switch (tier) {
      case 'MEMBER':
      case 'SILVER':
      case 'GOLD':
        return TICKETS.map((item, idx) => {
          return (
            <tr key={item + idx} className="content-row">
              <th scope="row">{item}</th>
              {renderTierRows(item)}
            </tr>
          );
        });
      case 'BUSINESS':
        return TICKETS.map((item, idx) => {
          return (
            <tr key={item + idx} className="content-row">
              <th scope="row">{item}</th>
              <td>
                <Icon
                  icon="Tick"
                  size="32"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="member-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </td>
            </tr>
          );
        });
      default:
        return TICKETS.map((item, idx) => {
          return (
            <tr key={item + idx} className="content-row">
              <th scope="row">{item}</th>
              {renderTierRows(item)}
              <td>
                <Icon
                  icon="Tick"
                  size="32"
                  developmentUrl="/able-sprites.svg"
                  role="img"
                  className="member-tick"
                  screenReaderContent="Available in this membership tier"
                />
              </td>
            </tr>
          );
        });
    }
  };

  const renderVIPHeader = () => {
    switch (tier) {
      case 'BUSINESS':
        return (
          <tr className="title-row">
            <th scope="row">
              <Icon
                icon="VIP"
                size="24"
                developmentUrl="/able-sprites.svg"
                role="img"
              />
              <span className="title-span">Service Benefits</span>
            </th>
            <td />
          </tr>
        );
      default:
        return (
          <tr className="title-row">
            <th scope="rowgroup" colSpan={5}>
              <Icon
                icon="VIP"
                size="24"
                developmentUrl="/able-sprites.svg"
                role="img"
              />
              <span className="title-span">Service Benefits</span>
            </th>
          </tr>
        );
    }
  };

  const renderVIPRow = () => {
    const renderTierRows = (item) => {
      return (
        <>
          <td />
          <td className="has-text">
            <Icon
              icon="Tick"
              size="32"
              developmentUrl="/able-sprites.svg"
              role="img"
              className="silver-tick"
              screenReaderContent="Available in this membership tier"
            />

            {item.indexOf('Get help and support') !== -1 &&
              convert(
                '<p>Silver service when you message us via My Telstra app.</p>'
              )}
          </td>
          <td className="has-text">
            <Icon
              icon="Tick"
              size="32"
              developmentUrl="/able-sprites.svg"
              role="img"
              className="gold-tick"
              screenReaderContent="Available in this membership tier"
            />

            {item.indexOf('Get help and support') !== -1 &&
              convert(
                '<p>VIP service when you message us via My Telstra App for help with your tech and Telstra services.</p>'
              )}
          </td>
        </>
      );
    };

    const renderBusinessRow = () => {
      return (
        <>
          <td></td>
          {/* <td className="has-text">
            <Icon
              icon="Tick"
              size="32"
              developmentUrl="/able-sprites.svg"
              role="img"
              className="member-tick"
              screenReaderContent="Available in this membership tier"
            />
            <p>3 months of Telstra Business Tech Services on us. Offer ends 7 February 2023.</p>
          </td> */}
        </>
      );
    };

    switch (tier) {
      case 'BUSINESS':
        return VIPSERVICES.map((item, idx) => {
          return (
            <tr key={item + idx} className="content-row">
              <th scope="row">{item}</th>
              {renderBusinessRow()}
            </tr>
          );
        });
      case 'MEMBER':
      case 'SILVER':
      case 'GOLD':
        return VIPSERVICES.map((item, idx) => {
          return (
            <tr key={item + idx} className="content-row">
              <th scope="row">{item}</th>
              {renderTierRows(item)}
            </tr>
          );
        });
      default:
        return VIPSERVICES.map((item, idx) => {
          return (
            <tr key={item + idx} className="content-row">
              <th scope="row">{item}</th>
              {renderTierRows(item)}
              {renderBusinessRow()}
            </tr>
          );
        });
    }
  };

  return (
    <Grid className="table-container">
      <Column bsm={12} bmd={12} className="table-row">
        <Spacing bottom="spacing5x">
          <Spacing bottom="spacing4x">
            <TextStyle alias="HeadingB" element="h2">
              Enjoy unique benefits tailored to your tier
            </TextStyle>
          </Spacing>
          <Spacing bottom="spacing4x">
            <TextStyle alias="Base" color="Paragraph" element="p">
              Your membership tier is based on your spend with us within your
              membership year. The higher your eligible spend, the higher your
              tier level and the more benefits you can access. Available
              benefits may change or be withdrawn from time to time.
            </TextStyle>
          </Spacing>
          {!isMobile && (
            <table className={`member-tier-table ${tier}`}>
              <thead>{renderTableHeader()}</thead>
              <tbody>
                {renderEarnPointsRow(tier)}
                {renderRedeemPointsRow(tier)}
                {renderTicketsHeader(tier)}
                {renderTicketsRow(tier)}
                {renderVIPHeader(tier)}
                {renderVIPRow(tier)}
              </tbody>
            </table>
          )}
          {isMobile && (
            <Grid>
              <Column col={12} className={`mobile-member-tier-table ${tier}`}>
                {renderMobileHeader()}
                {renderMobileContents()}
              </Column>
            </Grid>
          )}
        </Spacing>
      </Column>
    </Grid>
  );
};

export default MemberTierTable;
