/* eslint-disable  jsx-a11y/click-events-have-key-events */
/* eslint-disable  jsx-a11y/no-static-element-interactions */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { map, trim, groupBy, orderBy, filter } from 'lodash';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Base64 } from 'js-base64';

import { signIn } from 'oidc/initiateOidc';
import PlaceHolder from 'assets/images/tickets-placeholder-250x188@2x.jpg';
import { TextStyle, Spacing } from '@able/react';

import {
  getMovieSessions,
  buyMovieTicket,
  handleTicketErrors
} from 'api/getTickets';
import { TICKET_LOCAL_STORAGE, TICKET_MOVIE } from 'constants/ticket';
import { VILLAGE_CINEMA, PARTNERS } from 'constants/externalPartner';
import './MovieChildPage.scss';
import IconArrow from 'icons/IconArrow';
import * as Analytics from 'utils/analytics';
import { Tabs, Tab, Panel } from '../../TabsView';


dayjs.extend(customParseFormat)

const MovieChildPage = props => {
  const { movieId, movieThumbnail } = props;
  const queryParams = queryString.parse(window.location.search);
  const { cinemaId, state, cinemaName } = queryParams;

  const app = useSelector(reduxState => reduxState.appStatus);
  const { authenticated } = app;

  const [movieSessionData, setMovieSessionData] = useState(null);
  const dispatch = useDispatch();
  const [isToday, setIsToday] = useState(false);
  // eslint-disable-next-line no-shadow
  const isAuthenticated = useSelector(state => state.appStatus.authenticated);
  // parse session date time
  // input  : Thu, 16/07/20 @ 11:00pm
  // output : [ Thu, 16/07/20 11:00pm ]
  const parseSessionDateTime = dateString => {
    return map(dateString.split(','), trim);
  };

  const getMovieSessionData = () => {
    getMovieSessions({ state, cinemaId, movieId })
      .then(res => {
        const {
          data: { data: { sessions, movie } = { data: {} } } = { data: {} }
        } = res;

        const { posterUrl = '' } = movie;
        if (sessions && sessions.length > 0) {
          // generate movie thumbnail
          movieThumbnail({
            thumbnailImageUrl: posterUrl || PlaceHolder,
            tourName: movie.name,
            tourDescription: cinemaName
          });

          // create movie session data
          const movieSessions = map(sessions, session => {
            const parseStr = parseSessionDateTime(session.startTime);
            // console.log(dayjs(parseStr[1], 'DD/MM/YY @ hh:mmA'));
            const mappedSession = {
              ...session,
              startDay: parseStr[0],
              startMoment: dayjs(parseStr[1], 'DD/MM/YY @ hh:mmA')
            };
            
            mappedSession.groupKey = `${
              mappedSession.startDay
            } ${mappedSession.startMoment.date()}`;
            return mappedSession;
          });
          const sortedSession = orderBy(movieSessions, ms => ms.startMoment, [
            'asc'
          ]);
          // if api working correctly, the first earliest date should be today
          const today = sortedSession[0].startMoment;
          const todayF = dayjs().format('YYYY-MM-DD');
          const eventSaleStartDateF = dayjs(today).format('YYYY-MM-DD');

          if (dayjs(todayF).isSame(eventSaleStartDateF)) {
            setIsToday(true);
          }
          const onWeekOld = dayjs(today).add(7, 'days');
          // only sessions within 1 week is relevant to display
          const sortedReleveantSession = filter(
            sortedSession,
            session => session.startMoment < onWeekOld
          );
          const formatMovieSessionData = groupBy(
            sortedReleveantSession,
            'groupKey'
          );
          setMovieSessionData(formatMovieSessionData);
          // console.log(formatMovieSessionData);
        }
      })
      .catch(error => {
        dispatch(handleTicketErrors(error, 'movie'));
      });
  };

  useEffect(() => {
    // load movie session
    getMovieSessionData();
  }, []);

  const handleMovieSession = session => {
    const partnerId = PARTNERS?.village?.apiProps?.partnerId;
    Analytics.addEvent({
      eventName: `${
        isAuthenticated ? 'Auth' : 'Unauth'
      } Tickets - MovieTickets`,
      eventAction: 'click',
      eventType: 'clickTrack',
      eventCategory: 'linkClick'
    });
    if (authenticated) {
      dispatch(
        buyMovieTicket({
          movieId,
          cinemaId,
          partnerId,
          state,
          sessionId: session.id.value
        })
      );
    } else {
      // save movieSessionUrl to local storage
      // kick off signin redirect from caiman
      localStorage.setItem(
        'tplus-flow',
        Base64.encode(
          JSON.stringify({
            flow: TICKET_LOCAL_STORAGE,
            type: TICKET_MOVIE,
            partner: VILLAGE_CINEMA,
            action: 'buyMovieTicket',
            data: [
              {
                movieId,
                partnerId,
                cinemaId,
                cinemaState: state,
                sessionId: session.id.value
              }
            ],
            links: []
          })
        )
      );
      signIn();
    }
  };

  return (
    <div className="movie-session-container">
      {movieSessionData && (
        <Tabs>
          <div className="tabs-container">
            <div className="tab-header-wrapper">
              {Object.keys(movieSessionData).map(
                (sessionDate, sessionDateIndex) => (
                  <Tab key={sessionDateIndex} className="tab-header">
                    {sessionDateIndex === 0 && isToday ? 'Today' : sessionDate}
                  </Tab>
                )
              )}
            </div>
            <div className="tab-content">
              {Object.keys(movieSessionData).map(
                (sessionDate, sessionDateIndex) => (
                  <Panel key={sessionDateIndex}>
                    <div className="cinema-summary">
                      <TextStyle alias="HeadingC" element="h2">
                        {cinemaName}
                      </TextStyle>
                      <Spacing bottom="spacing2x" />
                      <TextStyle alias="Base" element="p">
                        Standard ticket price $13.50*, additional charges for
                        Vmax, 3D, and GC
                      </TextStyle>
                    </div>
                    <div className="session-list">
                      {movieSessionData[sessionDate] &&
                        movieSessionData[sessionDate].map(
                          (session, sessionIndex) => {
                            return (
                              <div
                                key={sessionIndex}
                                className="session"
                                onClick={() => handleMovieSession(session)}
                              >
                                <div className="session-time-wrapper">
                                  <TextStyle alias="Label" element="span" className="session-time">
                                    {session.startMoment.format('hh:mm A')}
                                  </TextStyle>
                                  <div>
                                    <button
                                      type="button"
                                      className="primary-cta-18"
                                    >
                                      <IconArrow />
                                    </button>
                                  </div>
                                </div>
                                <TextStyle alias="Base" element="span" className="screen-type">
                                  {session.screenType}
                                </TextStyle>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </Panel>
                )
              )}
            </div>
          </div>
        </Tabs>
      )}
    </div>
  );
};

export default MovieChildPage;
