import React from 'react';

export default function IconNumberOne() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 32 32"
      id="icon-number-one"
    >
      <title>Step 1</title>
      <path d="M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16 9.4 4 16 4zm0-1.333C8.6 2.667 2.667 8.6 2.667 16S8.6 29.333 16 29.333 29.333 23.4 29.333 16 23.4 2.667 16 2.667z"></path>
      <path d="M17.467 22.8c0 .4-.333.733-.8.733s-.8-.267-.8-.733v-12l-1.333 1.733c-.133.2-.333.267-.667.267-.4 0-.733-.333-.733-.8 0-.133.067-.333.133-.467l2-2.6c.2-.267.4-.467.933-.467h.4c.4 0 .733.267.733.733v13.6z"></path>
    </svg>
  );
}
