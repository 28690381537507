import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import IconArrow from 'icons/IconArrow';
import AlertPNG from 'assets/images/Alert.png';
import { Breadcrumbs } from 'components';

import './LimitedPermissions.scss';

const LimitedPermissions = ({ renderDownStreamErrorCode }) => (
  <Fragment>
    <div className="limited-permissions">
      <Breadcrumbs />
      <div className="icon-contain">
        <img src={AlertPNG} alt="An error icon" />
      </div>
      <div className="clear-div-m" />
      <h1 className="page-heading">
        Your account holder needs to join Telstra Plus
      </h1>
      <div className="clear-div-l" />
      <p>
        You have limited authority on this account. Please ask your account holder to join Telstra Plus.
      </p>
      <div className="clear-div-xxs" />
      {renderDownStreamErrorCode()}
      <div className="clear-div-s" />
      <a
        role="button"
        aria-pressed="false"
        className="primary-cta-18"
        href="https://www.telstra.com.au/plus/terms-and-conditions"
      >
        Read the terms and conditions <IconArrow />
      </a>
      <div className="clear-div-xl" />
    </div>
  </Fragment>
);

LimitedPermissions.propTypes = {
  renderDownStreamErrorCode: PropTypes.func.isRequired
};

export default LimitedPermissions;
