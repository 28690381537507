export const SidebarStyles = isSidebarOpen => {
  return {
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'hidden',
      pointerEvents: isSidebarOpen ? 'auto' : 'none'
    },
    sidebar: {
      zIndex: 22,
      position: 'absolute',
      top: 0,
      bottom: 0,
      transition: 'transform .3s ease-out',
      WebkitTransition: '-webkit-transform .3s ease-out',
      willChange: 'transform',
      overflowY: 'auto',
      width: '420px'
    },
    content: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflowY: 'auto',
      WebkitOverflowScrolling: 'touch',
      transition: 'left .3s ease-out, right .3s ease-out'
    },
    overlay: {
      zIndex: 20,
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0,
      visibility: 'hidden',
      transition: 'opacity .3s ease-out, visibility .3s ease-out',
      backgroundColor: 'rgba(0,0,0,.3)'
    },
    dragHandle: {
      zIndex: 1,
      position: 'fixed',
      top: 0,
      bottom: 0
    }
  };
};
