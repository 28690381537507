export const getMaskedEmail = email => {
  if (!email) return email;
  const split = email.split('@');
  // eslint-disable-next-line prefer-template
  return email.substr(0,2) + new Array(split[0].length - 2).fill('*').join('') + "@" + split[1];
};

export const getMaskedMobile = mobile => {
  if (!mobile) return mobile;
  return mobile.substr(0,4) + new Array(4).fill('*').join('') + mobile.substr(-4,4);
};

export const getMaskedAddress = address => {
  if (!address) return address;
  const split = address.split(',');
  for (let i = 0; i < split.length - 1; i += 1) {
    if (i < split.length - 3) split[i] = split[i].replace(/\d/g, '*');
  }
  return split.join(',');
};
