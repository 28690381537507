import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// Import all reducers
import rewards from 'reducers/rewards';
import appStatus from './appStatus';
import accounts from './activeAccount';
import error from './error';
import enrolment from './enrolment';
import eligibility from './eligibility';
import points from './points';
import pointsHistory from './pointsHistory';
import pointsTransfer from './pointsTransfer';
import spLogEntry from './spLogEntry';
import optOut from './optOut';
import onePlace from './onePlace';
import { aem } from './aem';
import { smb } from './smb';
import otpFlow from './otpFlow';
import { events } from './events';
import { movies } from './movies';
import { partners } from './partners';
import { updateContactDetails } from './updateContactDetails';
import { forms } from './forms';
import experience from './experience';
import { nowShowing } from './nowShowing';
import { productStocks } from './productStocks';

export default history =>
  combineReducers({
    appStatus,
    accounts,
    error,
    enrolment,
    eligibility,
    points,
    pointsHistory,
    pointsTransfer,
    spLogEntry,
    optOut,
    onePlace,
    aem,
    smb,
    otpFlow,
    events,
    movies,
    partners,
    updateContactDetails,
    forms,
    experience,
    rewards,
    nowShowing,
    productStocks,
    router: connectRouter(history)
  });
