/* eslint-disable jsx-a11y/no-onchange */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  Grid,
  Column,
  TextStyle,
  Spacing,
  Select,
  TextField,
  ActionButton,
  ConfirmationDialog
} from '@able/react';

import * as Analytics from 'utils/analytics';

import * as actions from 'constants/actions';
import { fetchPoints, fetchPointsHistory } from 'actions/points';
import transferPoints from 'actions/transferPoints';

import { Breadcrumbs, InlineLoadingSpinner } from 'components';
import successImg from 'assets/images/success_illustration.svg';
import alertimage from 'assets/images/Alert.png';
import usePointsTransfer from './use-points-transfer';

const PointsTransferView = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const [pointsToTransfer, setpointsToTransfer] = useState('');
  const [fromMemberId, setfromMemberId] = useState('');
  const [toMemberId, settoMemberId] = useState('');
  const [modalOpen, setmodalOpen] = useState(false);

  const [transferInitiated, settransferInitiated] = useState(false);
  const transferSuccess = useSelector(state => state.pointsTransfer.success);
  const transferError = useSelector(state => state.pointsTransfer.error);

  const [
    { toArr, fromArr, maxAmount, allowPointsTransfer }
  ] = usePointsTransfer({
    fromMemberId,
    toMemberId
  });

  const resetForm = () => {
    setfromMemberId('');
    settoMemberId('');
    setpointsToTransfer('');
    // Reset pending flag
    settransferInitiated(false);
    // Reset Success state
    dispatch({
      type: actions.SET_TRANSFER_POINTS_SUCCESS,
      payload: false
    });
    // Reset error state
    dispatch({
      type: actions.TRANSFER_POINTS_FAILURE,
      payload: 0
    });
  };

  // On component load reset values
  useEffect(() => {
    Analytics.setPageName('Points - Transfer');
    Analytics.addEvent({
      eventAction: 'loyaltyPointsTransfer'
    });
    resetForm();
    return () => {
      resetForm();
    };
  }, []);

  // On successful transfer call points pi again
  useEffect(() => {
    if (transferSuccess) {
      dispatch(fetchPoints());
      dispatch(fetchPointsHistory());
    }
  }, [transferSuccess]);

  const handleSubmit = () => {
    return setmodalOpen(true);
  };

  const doTransfer = () => {
    const transferPointsDetails = {
      targetCac: toMemberId,
      sourceCac: fromMemberId,
      points: parseFloat(pointsToTransfer.replace(/,/g, ''))
    };
    settransferInitiated(true);
    dispatch(transferPoints(transferPointsDetails)).then(() => {
      settransferInitiated(false);
      setmodalOpen(false);
    });
  };

  const doNothing = () => {
    return false;
  };

  const renderForm = () => {
    return (
      <>
        <Breadcrumbs />
        <Grid>
          <Column bsm={12} bmd={7}>
            <Spacing bottom="spacing5x">
              <TextStyle alias="HeadingB" element="h1">
                Transfer points between your memberships
              </TextStyle>
              <Spacing bottom="spacing2x" />
              <TextStyle alias="Base" element="p">
                You can transfer points online between the legitimate Telstra
                Plus memberships you have access to, as long as you’re the
                account holder or a full authority representative.{' '}
              </TextStyle>
              <TextStyle alias="Base" element="p">
                To transfer points to a Telstra Plus membership you don’t have
                access to, call us on 13 22 00.
              </TextStyle>
            </Spacing>

            <Formik
              initialValues={{
                from: '',
                to: '',
                points: 0
              }}
              validationSchema={yup.object().shape({
                from: yup.string().required('Please select "From"'),
                to: yup.string().required('Please select "To"'),
                points: yup.number().when('from', {
                  is: from => !from,
                  then: yup.number().required('Please enter a points value'),
                  otherwise: yup
                    .number()
                    .required('Please enter a points value')
                    .positive()
                    .integer('Please enter a whole number')
                    .moreThan(0, 'Points amount must be greater than 0')
                    .test({
                      name: 'max',
                      params: { maxAmount },
                      message: `Points must be less than ${maxAmount}`,
                      test: value => value == null || value <= maxAmount
                    })
                    .test({
                      name: 'finalmax',
                      params: { maxAmount },
                      message: `Points must be less than 9999999`,
                      test: value =>
                        value == null ||
                        (value <= maxAmount && value <= 9999999)
                    })
                    .typeError('Points must be a number')
                })
              })}
              onSubmit={handleSubmit}
            >
              {formik => (
                <div className="event-selection">
                  <form onSubmit={formik.handleSubmit}>
                    <Spacing bottom="spacing4x">
                      <Select
                        developmentUrl="/able-sprites.svg"
                        id="from"
                        label="From"
                        options={fromArr}
                        events={{
                          onChange: e => setfromMemberId(e.target.value)
                        }}
                        defaultValue="0"
                        invalid={
                          formik.touched.from && formik.errors.from && true
                        }
                        invalidMessage={formik.errors.from}
                        required
                        {...formik.getFieldProps('from')}
                      />
                    </Spacing>
                    <Spacing bottom="spacing4x">
                      <Select
                        developmentUrl="/able-sprites.svg"
                        id="to"
                        label="To"
                        options={toArr}
                        events={{
                          onChange: e => settoMemberId(e.target.value)
                        }}
                        invalid={formik.touched.to && formik.errors.to && true}
                        invalidMessage={formik.errors.to}
                        required
                        {...formik.getFieldProps('to')}
                      />
                    </Spacing>
                    <Spacing bottom="spacing4x">
                      <TextField
                        label="Points to transfer"
                        id="transferAmount"
                        name="transferAmount"
                        {...(maxAmount > 0 && {
                          helpText: `Please enter an amount from 1 to ${maxAmount}`
                        })}
                        events={{
                          onInput: e => setpointsToTransfer(e.target.value)
                        }}
                        inputHelper={null}
                        inputMode="numeric"
                        invalidInputText={formik.errors.points}
                        invalid={
                          formik.touched.points && formik.errors.points && true
                        }
                        required
                        {...formik.getFieldProps('points')}
                      />
                    </Spacing>
                    <Spacing bottom="spacing4x">
                      <ActionButton
                        type="submit"
                        variant="HighEmphasis"
                        label="Transfer Points"
                        element="button"
                        to="/"
                      />

                      <ConfirmationDialog
                        title="Confirm your points transfer"
                        description={`You are transfering ${parseInt(
                          pointsToTransfer
                        ).toLocaleString(navigator.language, {
                          minimumFractionDigits: 0
                        })} points from ${fromMemberId} to ${toMemberId}.`}
                        confirmButtonLabel="Confirm transfer"
                        confirmButtonEvents={{
                          onClick: () => doTransfer()
                        }}
                        cancelButtonLabel="Cancel"
                        cancelButtonEvents={{
                          onClick: () => setmodalOpen(false)
                        }}
                        isShowing={modalOpen}
                        setHideDialog={doNothing}
                        developmentUrl="/able-sprites.svg"
                        className=""
                        stackButtonOnVXS
                      />
                    </Spacing>
                  </form>
                </div>
              )}
            </Formik>
          </Column>
        </Grid>
      </>
    );
  };

  const renderSuccess = () => {
    return (
      <>
        <Breadcrumbs link="/history" text="Back to Points History" />
        <Grid>
          <Column bsm={12} bmd={7}>
            <Spacing bottom="spacing4x">
              <img src={successImg} alt="An Point transfer success icon" />
            </Spacing>
            <Spacing bottom="spacing4x">
              <TextStyle alias="HeadingB" element="h3">
                Your points transfer was successful
              </TextStyle>
            </Spacing>
            <Spacing bottom="spacing4x">
              {allowPointsTransfer ? (
                <ActionButton
                  variant="LowEmphasis"
                  element="button"
                  label="Make another transfer"
                  onClick={e => {
                    e.preventDefault();
                    return resetForm();
                  }}
                />
              ) : (
                <ActionButton
                  variant="LowEmphasis"
                  element="Link"
                  label="Return home"
                  to="/"
                />
              )}
            </Spacing>
          </Column>
        </Grid>
      </>
    );
  };

  const renderFailedMessage = () => {
    if (typeof transferError !== 'undefined') {
      switch (transferError) {
        case 1053: {
          return (
            <>
              <Spacing bottom="spacing2x">
                <TextStyle alias="HeadingB" element="h3">
                  We’re unable to complete the transfer
                </TextStyle>
              </Spacing>
              <Spacing bottom="spacing4x">
                <TextStyle alias="Base" element="p">
                  You don’t have enough points. You can check the latest
                  transactions in your points history.
                </TextStyle>
              </Spacing>
              <Spacing bottom="spacing4x">
                <TextStyle alias="Base" element="p">
                  <ActionButton
                    variant="LowEmphasis"
                    element="a"
                    label="Back to points transfer"
                    onClick={e => {
                      e.preventDefault();
                      return resetForm();
                    }}
                  />
                </TextStyle>
              </Spacing>
            </>
          );
        }
        default: {
          return (
            <>
              <TextStyle alias="HeadingB" element="h3">
                Points transfer is currently unavailable
              </TextStyle>
            </>
          );
        }
      }
    }
    return (
      <TextStyle alias="HeadingB" element="h3">
        Sorry something went wrong
      </TextStyle>
    );
  };

  const renderError = () => {
    return (
      <>
        <Breadcrumbs link="/history" text="Back to Points History" />
        <Grid>
          <Column bsm={12} bmd={7}>
            <Spacing bottom="spacing4x">
              <img
                style={{ width: '100px' }}
                src={alertimage}
                alt="An error occured"
              />
            </Spacing>
            <Spacing bottom="spacing4x">{renderFailedMessage()}</Spacing>
          </Column>
        </Grid>
      </>
    );
  };

  // Check if user is allowed to use transfer form
  if (allowPointsTransfer === 'true') {
    return (
      <Grid className="container">
        {transferInitiated && (
          <InlineLoadingSpinner loadingText="Preparing your points transfer..." />
        )}
        {!transferInitiated && !transferSuccess && !transferError && (
          <>{renderForm()}</>
        )}
        {transferSuccess && <>{renderSuccess()}</>}
        {transferError !== 0 && <>{renderError()}</>}
        <Spacing bottom="spacing5x" />
      </Grid>
    );
  }
  if (allowPointsTransfer === 'false') {
    return <>{history('/')}</>;
  }
  return (
    <>
      <InlineLoadingSpinner loadingText="Loading points transfer form" />
    </>
  );
};

export default PointsTransferView;
