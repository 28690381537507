import axios from './util/axiosHandle';
import { rewardsConfig } from '../oidc/oidc-configs';

const {
  baseUrl,
  endpoints: { getTogglesEndPoint  }
} = rewardsConfig;

const getToggles = async () => {
  try {
    return await axios({
      params: {
        method: 'get',
        url: baseUrl + getTogglesEndPoint
      },
      type: 'public'
    });
  } catch (error) {
    //throw if error 
    throw error;
  }
};


export default getToggles;
