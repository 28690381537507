import React, { useEffect } from 'react';
import { useFocus } from 'utils/hooks/useFocus';
import { Icon } from '@able/react';

const FormTextfield = props => {
  const {
    id,
    name,
    label,
    isRequired,
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    isSubmitting,
    isValidating,
    setTouched
  } = props;

  const [inputRef, setInputFocus] = useFocus();
  const errored = errors[name] && touched[name];

  useEffect(() => {
    if (isSubmitting && !isValidating && Object.keys(errors)[0] === name) {
      setInputFocus();
    }
  }, [isSubmitting, isValidating, errors]);

  const onChange = e => {
    setTouched({ [name]: false });
    handleChange(e);
  };

  return (
    <div id={`${id}__wrapper`} className="able-TextField tplus-form-field">
      <label htmlFor={name}>{label}</label>
      <input
        aria-describedby={errored ? `${name}__error-text` : null}
        aria-invalid={errored ? 'true' : 'false'}
        aria-required={isRequired === 'true' ? 'true' : 'false'}
        name={name}
        id={name}
        ref={inputRef}
        onBlur={handleBlur}
        onChange={onChange}
        value={values[name] || ''}
      />
      <p id={`${name}__error-text`} className={errored ? 'd-block' : 'd-none'}>
        <Icon
          size="24"
          developmentUrl="/able-sprites.svg"
          icon="Error"
          screenReaderContent="Error"
          role="img"
          aria-label="Error"
        />
        {errors[name]}
      </p>
    </div>
  );
};

export default FormTextfield;
