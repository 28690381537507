import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { find, filter, every, includes, remove } from 'lodash';

const usePointsTransfer = ({ fromMemberId = '', toMemberId = '' }) => {
  const mainAccount = useSelector(state => state.accounts);
  const points = useSelector(state => state.points);
  const { apiResponse } = points;
  const accounts = useSelector(state => state.accounts.sortedAccounts);

  // Initial state
  const initial = {
    accountArr: [],
    accountArrNoZeroPoints: [],
    toArr: [],
    fromArr: [],
    maxAmount: 0,
    allowPointsTransfer: 'unset'
  };

  // Reducer function to handle incoming actions
  const reducer = (state, action) => {
    switch (action.type) {
      case 'setInitialArray': {
        return {
          ...state,
          accountArr: action.payload
        };
      }
      case 'setInitialArrayNoZeroPoints': {
        return {
          ...state,
          accountArrNoZeroPoints: action.payload
        };
      }
      case 'setToArray': {
        return {
          ...state,
          toArr: action.payload
        };
      }
      case 'setFromArray': {
        return {
          ...state,
          fromArr: action.payload
        };
      }
      case 'setMaxAmount': {
        return {
          ...state,
          maxAmount: action.payload
        };
      }
      case 'setAllowPointsTransfer': {
        return {
          ...state,
          allowPointsTransfer: action.payload
        };
      }
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initial);

  const { accountArr } = state;

  // Set up initial arrays, sorted accounts should only come in once
  useEffect(() => {
    let filteredAccounts = [];
    let filteredAccountsNoZeroPoints = [];
    // check if accounts has points object yet
    const hasPoints = find(accounts, 'points');
    // filtered by:
    // - role
    // - if they are enrolled
    // - account has more than 0 points
    if (hasPoints) {
      filteredAccounts = filter(accounts, ({ role, enrollmentStatus }) =>
        every([
          includes(['ACCOUNT OWNER', 'FULL AUTHORITY'], role),
          enrollmentStatus === 'ENROLLED'
          // points.loyaltyPoints > 0
        ])
      );
      filteredAccountsNoZeroPoints = filter(
        filteredAccounts,
        ({ points: accountPoints }) => every([accountPoints.loyaltyPoints > 0])
      );
    }

    const setupSelectOptionContent = arr => {
      return arr.map(account => {
        const transformedPoints = parseInt(account.points.loyaltyPoints)
          .toLocaleString(10)
          .toLocaleString(navigator.language, {
            minimumFractionDigits: 0
          });
        return {
          value: account.accountId,
          label: `${account.accountName} - ${account.loyaltyTier} - ${transformedPoints}pts`
        };
      });
    };

    const normalizedAccounts = setupSelectOptionContent(filteredAccounts);

    const normalizedAccountsNoZeroPoints = setupSelectOptionContent(
      filteredAccountsNoZeroPoints
    );

    dispatch({
      type: 'setInitialArray',
      payload: normalizedAccounts
    });
    dispatch({
      type: 'setToArray',
      payload: normalizedAccounts
    });
    dispatch({
      type: 'setFromArray',
      payload: normalizedAccountsNoZeroPoints
    });
    dispatch({
      type: 'setMaxAmount',
      payload: 0
    });

    if (fromMemberId !== '') {
      // Get the max amount to transfer
      const selectedFromAccount = find(accounts, { accountId: fromMemberId });
      const maxPointsToTransfer = selectedFromAccount?.points?.loyaltyPoints;
      dispatch({
        type: 'setMaxAmount',
        payload: maxPointsToTransfer
      });
      // Remove matching account from to initial array and set to array
      const workingToArr = [...accountArr];
      remove(workingToArr, {
        value: fromMemberId
      });
      dispatch({
        type: 'setToArray',
        payload: workingToArr
      });
    }

    if (toMemberId !== '') {
      // Remove matching account from to initial array and set from array
      const workingFromArr = [...accountArr];
      remove(workingFromArr, {
        value: toMemberId
      });
      dispatch({
        type: 'setFromArray',
        payload: workingFromArr
      });
    }
  }, [accounts, fromMemberId, toMemberId]);

  useEffect(() => {
    if (
      mainAccount.sortedAccounts.length > 0 &&
      mainAccount.pointsValue !== '' &&
      mainAccount.pointsValue > 0 &&
      mainAccount.enrollmentStatus === 'ENROLLED' &&
      // accountArr.length > 1 &&
      apiResponse !== null
    ) {
      if (
        mainAccount.sortedAccounts.some(
          e => e.role === 'FULL AUTHORITY' || e.role === 'ACCOUNT OWNER'
        )
      ) {
        return dispatch({
          type: 'setAllowPointsTransfer',
          payload: 'true'
        });
      }
    }
    if (apiResponse !== null) {
      return dispatch({
        type: 'setAllowPointsTransfer',
        payload: 'false'
      });
    }
    return dispatch({
      type: 'setAllowPointsTransfer',
      payload: 'unset'
    });
  }, [mainAccount]);

  return [
    {
      ...state
    }
  ];
};

export default usePointsTransfer;
