import {
  SET_ERROR,
  SET_NEW_CAMPAIGN,
  GET_ENTRY_PENDING,
  GET_ENTRY_SUCCESS,
  GET_ENTRY_FAILURE,
  GET_PREVIOUS_ENTRY_PENDING,
  GET_PREVIOUS_ENTRY_SUCCESS,
  GET_PREVIOUS_ENTRY_FAILURE
} from 'constants/actions';

import { fetchPoints, fetchPointsHistory } from 'actions/points';
import lodgeCampaignEntry from 'api/lodgeCampaignEntry';
import getEntryDetails from 'api/getEntryDetails';

export const setNewCampaign = campaignName => {
  return dispatch => {
    dispatch({ type: SET_NEW_CAMPAIGN, campaignName });
  };
};

export const submitCampaign = data => {
  return dispatch => {
    const { campaignName } = data;
    dispatch({ type: GET_ENTRY_PENDING, campaignName });
    lodgeCampaignEntry(data)
      .then(res => {
        dispatch({ type: GET_ENTRY_SUCCESS, payload: res, campaignName });
        dispatch(fetchPoints());
        dispatch(fetchPointsHistory());
      })
      .catch(err => {
        const {
          data: { code }
        } = err;
        dispatch({ type: SET_ERROR, errorStatusCode: code });
        dispatch({ type: GET_ENTRY_FAILURE, payload: err, campaignName });
      });
  };
};

export const getPreviousEntry = campaignName => {
  return dispatch => {
    dispatch({ type: GET_PREVIOUS_ENTRY_PENDING, campaignName });
    getEntryDetails({ 'campaign-name': campaignName })
      .then(res => {
        const { data } = res?.data;
        dispatch({
          type: GET_PREVIOUS_ENTRY_SUCCESS,
          payload: data,
          campaignName
        });
      })
      .catch(err => {
        const {
          data: { code }
        } = err;
        dispatch({ type: SET_ERROR, errorStatusCode: code });
        dispatch({
          type: GET_PREVIOUS_ENTRY_FAILURE,
          payload: err,
          campaignName
        });
      });
  };
};
