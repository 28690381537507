import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from 'containers/serviceWorker';
import _ from 'lodash';
import queryString from 'query-string';
import { createPartnerStorageObject } from 'utils/partners';
import Root from 'containers/Root';
import './styles/base.scss';

import { signIn, signInSilentCallback } from 'oidc/initiateOidc';

const IN_DEVELOP = process.env.REACT_APP_ENV === 'dev';
const container = document.getElementById('root');
const root = createRoot(container);
const isIframe = window.self !== window.top;

const iFrameHasParentHost = () => {
  try {
    return !!window.parent.location.host;
  } catch {
    return false;
  }
};

window.tplus = window.tplus || {};

// capture params from partner redirection
const queryParams = queryString.parse(window.location?.search);
if (_.has(queryParams, 'partner')) {
  // save partner redirection params to local storage if any
  // kick off to sign in because need to be authenticated
  createPartnerStorageObject(queryParams);
  signIn();
}

// Check if it's an iframe for silentsignin purposes
// Note: ARP loads our spa in an iframe also, so we need to double
// check the iframe parent host. SSO iframe has the same host, ARP does not.
if (isIframe && iFrameHasParentHost()) {
  signInSilentCallback();
} else {
  root.render(<Root />);
}

// Disabling CRA service workers
serviceWorker.unregister();

// Usabilla();

// Webpack Hot Module Replacement API
if (IN_DEVELOP) {
  module.hot.accept('./containers/App', () => {
    root.render();
  });
}
