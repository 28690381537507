import {
  SET_LOADING,
  SET_ENROLLED,
  SET_ENROLFAILED,
  REGISTRATION_SUCCESS,
  REGISTRATION_PENDING,
  REGISTRATION_FAILURE
} from 'constants/actions';

const registrationState = {
  enrolled: false,
  loading: true,
  registrationFailure: false,
  registrationPending: false,
  registration: {
    data: {
      accounts: [
        {
          accountId: '',
          enrolmentDatetime: '',
          enrolmentStatus: ''
        }
      ]
    }
  }
};

export default function enrolment(state = registrationState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case SET_ENROLLED:
      return {
        ...state,
        enrolled: action.enrolled
      };
    case SET_ENROLFAILED:
      return {
        ...state,
        enrolFailed: action.enrolFailed
      };
    case REGISTRATION_SUCCESS: {
      const { accounts } = action.registration.data;
      // eslint-disable-next-line no-param-reassign
      action.registration.data.accounts = accounts;

      return {
        ...state,
        enrolled: true,
        registrationPending: false,
        registration: action.registration || registrationState.registration
      };
    }
    case REGISTRATION_PENDING:
      return {
        ...state,
        registrationPending: true
      };
    case REGISTRATION_FAILURE:
      return {
        ...state,
        registrationPending: false,
        registrationFailure: true
      };
    default: {
      return state;
    }
  }
}

export const registrationFailure = state => {
  return state.enrolment.registrationFailure;
};

export const registrationPending = state => {
  return state.enrolment.registrationPending;
};

export const isEnrolled = state => {
  return state.enrolment.enrolled;
};
