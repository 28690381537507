import React from 'react';

export default function IconClose() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          d="M12.096825,13.1564415 L7.90818321,17.343948 L6.84766679,16.2831441 L11.036021,12.095925 L6.84766679,7.90870588 L7.90818321,6.84790197 L12.096825,11.0354085 L16.2854668,6.84790197 L17.3459832,7.90870588 L13.157629,12.095925 L17.3459832,16.2831441 L16.2854668,17.343948 L12.096825,13.1564415 Z"
          id="path-1"
        />
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1328.000000, -16.000000)">
          <g transform="translate(1328.000000, 16.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1" />
            </mask>
            <use id="Mask" fill="#414141" xlinkHref="#path-1" />
          </g>
        </g>
      </g>
    </svg>
  );
}
