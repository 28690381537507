import { combineReducers } from 'redux';

import { GET_AEM_ALL, SET_AEM_PENDING, SET_AEM_READY } from 'constants/actions';

// Expected child pages from AEM
// Auth/Unauth experiences main pages
const aemMainSubStates = {
  auth: {
    promos: {},
    config: {},
    childpages: {},
    accordions: {},
    carousels: {},
    headers: {}
  },
  unauth: {
    promos: {},
    config: {},
    childpages: {},
    accordions: {},
    carousels: {},
    headers: {}
  }
};

// Flat level pages
const aemFlatSubStates = {
  config: {}
};

// Expected top level pages from AEM
const aemState = {
  ready: false,
  landing: { ...aemMainSubStates },
  benefits: { ...aemMainSubStates },
  points: { ...aemMainSubStates },
  join: { ...aemFlatSubStates },
  global: { ...aemFlatSubStates }
};

const aemPendingState = true;

export const aemAll = (state = aemState, action) => {
  switch (action.type) {
    case GET_AEM_ALL: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
};

export const aemPending = (state = aemPendingState, action) => {
  switch (action.type) {
    case SET_AEM_PENDING: {
      const { payload } = action;

      return payload;
    }
    default:
      return state;
  }
};

export const aemReady = (state = false, action) => {
  switch (action.type) {
    case SET_AEM_READY: {
      const { payload } = action;
      return payload;
    }
    default:
      return state;
  }
};

export const aem = combineReducers({
  all: aemAll,
  ready: aemReady,
  pending: aemPending
});

export const getAemPending = state => state.aem.pending;
