import React, { useState, useEffect } from 'react';
import {createPortal} from 'react-dom';
import { useSelector } from 'react-redux';
import convert from 'htmr';
import { Icon, TextStyle, Spacing, ActionButton, Divider, IconButton, MessageInline, MessageSection } from '@able/react';
import Sidebar from 'react-sidebar';
import { signIn, checkLoa } from 'oidc/initiateOidc';
import FocusTrap from 'focus-trap-react';
import { useOtpFlow } from 'components';
import spinnerGIF from 'assets/images/Spinner.gif';
import EmailChangeView from './sidebarviews/EmailChangeView';
import EmailAddView from './sidebarviews/EmailAddView';
import MobileAddView from './sidebarviews/MobileAddView';
import AddressAddView from './sidebarviews/AddressAddView';
import AddressConfirmView from './sidebarviews/AddressConfirmView';
import SuccessView from './sidebarviews/SuccessView';
import ErrorView from './sidebarviews/ErrorView';
import OtpView from './sidebarviews/OtpView';
import { clearAllErrors } from './utils/helpers';
import { SidebarStyles } from './SidebarStyles';
import './OtpFlowV2.scss';
import './ViewStyles.scss';

const OtpFlowV2 = props => {
  const {
    page,
    otpErrors = {},
    mobile,
    mobileLoa,
    email,
    emailLoa,
    address,
    addressLoa,
    contactErrorCopy,
    updateMobileLabel = 'Edit mobile number',
    updateEmailLabel = 'Edit email address',
    updateHomeAddressLabel = 'Edit residential address'
  } = props;

 
  const { otpBorders } = useOtpFlow();
  // const {
  //   mobile: mobileBorder,
  //   email: emailBorder,
  //   address: addressBorder
  // } = otpBorders;
  const {
    otpMobileError = false,
    otpEmailError = false,
    otpAddressError = false
  } = otpErrors;

  const appStatus = useSelector(state => state.appStatus);
  const { loaFlowStatus } = appStatus;
  const { status: loaStatus, opts: loaOpts } = loaFlowStatus;
  // loaStatus = 'denied';

  const [modalOpen, setModalState] = useState(false);
  const [mType, setModalType] = useState('');

  const otpFlow = useSelector(state => state.otpFlow);
  const accountMobile = useSelector(state => state.accounts.maskedMobile);
  const accountEmail = useSelector(state => state.accounts.maskedEmail);

  const updateContactDetails = useSelector(state => state.updateContactDetails);
  const { error: contactError } = updateContactDetails;
  const { code: contactErrorCode } = contactError;

  let currentFlow = '';
  let reference = '';

  const loaLevel = checkLoa();

  // sidebar
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const onSetSidebarOpen = open => {
    setIsSidebarOpen(open);
    window.scrollTo(0, 0);
  };

  const openSideBar = (modalOpts, loaFlag) => {
    handleUpdate(modalOpts, loaFlag);
    setIsSidebarOpen(true);
    window.scrollTo(0, 0);
  };

  const closeSideBar = () => {
    setIsSidebarOpen(false);
  };

  const renderSideBarContent = () => {
    return (
      <>
      <Spacing
          left="spacing5x"
          right="spacing5x"
          top="spacing7x"
          bottom="spacing10x"
          >
      <div className="close-modal-btn">
      <IconButton icon="Close" developmentUrl="/able-sprites.svg" 
             screenReaderContent="Close icon"
             aria-label="close-sidebar"
             onClick={() => toggleView({ clearErrors: true })} 
             onKeyPress={() => toggleView({ clearErrors: true })}
              />
        </div>
        <Spacing bottom="spacing2x"/>
      {renderViewContent()}
      </Spacing>
      </>
    );
  };
  const renderSideBar = () => {
    // Focus Trap for accessibilitys sake
    if (isSidebarOpen) {
      document.body.style.overflowY = 'hidden';
      return (
        <FocusTrap
          focusTrapOptions={{
            returnFocusOnDeactivate: false
          }}
        >
          <div aria-hidden="false" className="sidebar-content">
            {renderSideBarContent()}
          </div>
        </FocusTrap>
      );
    }  
    document.body.style.overflowY = 'visible';
    return (
      <div className="sidebar-content" style={{ display: 'none' }}>
        {renderSideBarContent()}
      </div>
    );
  };

  
  const toggleView = ({ modalType, modalSwitch, clearErrors }) => {
    if (clearErrors) {
      clearAllErrors({});
      closeSideBar();
    }

    setModalState(modalSwitch || !modalOpen);
    setModalType(modalType);
  };

  const handleUpdate = (modalOpts, loaFlag) => {
    // Fill in signIn options from modal options
    const loaFlowOpts = { ...modalOpts };
    // Flag to check LOA level from AEM config
    if (loaFlag) {
      if (loaLevel === 'LOA2') {
        return toggleView(modalOpts);
      }
      return signIn({
        url: window.location.pathname,
        loaStepUp: true,
        opts: loaFlowOpts
      });
    }
    return toggleView(modalOpts);
  };

  const renderSpinner = msg => {
    return (
      <div className="explore__spinner__position">
        <Spacing
          left="spacing15x"
          right="spacing15x"
          top="spacing15x"
          bottom="spacing15x"
          >
     <svg className="explore__spinner" role="alert" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
     <title>{msg}</title>
    <circle className="explore__spinner__path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
       <p className="explore__spinner__text">{msg}</p>
       </Spacing>
       </div>
    );
  };

  const renderViewContent = () => {
    switch (mType) {
      case 'email': {
        return <EmailChangeView toggleView={toggleView} />;
      }
      case 'sendemail': {
        return (
          <EmailAddView
            toggleView={toggleView}
            page={page}
            renderSpinner={renderSpinner}
          />
        );
      }
      case 'mobile': {
        return (
          <MobileAddView
            toggleView={toggleView}
            page={page}
            renderSpinner={renderSpinner}
          />
        );
      }
      case 'address': {
        return <AddressAddView toggleView={toggleView} />;
      }
      case 'confirmaddress': {
        return <AddressConfirmView toggleView={toggleView} />;
      }
      case 'otp': {
        switch (otpFlow?.otp?.flow) {
          case 'mobile':
            currentFlow = 'SMS';
            reference = otpFlow?.mobile?.temp;
            break;
          case 'sendemail':
            currentFlow = 'EMAIL';
            reference = otpFlow?.email?.temp;
            break;
          default:
            currentFlow = '';
            reference = '';
        }
        return (
          <OtpView
            toggleView={toggleView}
            page={page}
            currentFlow={currentFlow}
            reference={reference}
            renderSpinner={renderSpinner}
          />
        );
      }
      case 'success': {
        return <SuccessView toggleView={toggleView} />;
      }
      case 'error': {
        return <ErrorView toggleView={toggleView} />;
      }
      default: {
        break;
      }
    }
    return null;
  };

  
  /**
   * @function otpFlowUpdateButton
   *
   * Returns an update button for OTP form
   *
   * @param {Object} modalOpts
   * @param {Boolean} loaFlag
   * @param {String} copy
   * @param {String} ariaLabel
   * @param {String} theClass
   * @returns
   */
  const renderButton = (modalOpts, loaFlag, copy, ariaLabel, icon) => {
    if (loaFlag && loaStatus !== 'started' && loaStatus !== 'failed') {
      return (
        <>
        <Spacing top="spacingHalf"/>
        <ActionButton
            variant="LowEmphasis"
            label={copy}
            element="button"
            aria-label={ariaLabel}
            developmentUrl="/able-sprites.svg"
            icon={icon}
            onClick={() => openSideBar(modalOpts, loaFlag)}
        />
        </>
      );
    }
    // We dont care about loa here
    if (!loaFlag) {
      return (
        <>
        <Spacing top="spacingHalf"/>
        <ActionButton
            variant="LowEmphasis"
            label={copy}
            element="button"
            aria-label={ariaLabel}
            developmentUrl="/able-sprites.svg"
            icon={icon}
            onClick={() => openSideBar(modalOpts, loaFlag)}
        />
        </>
      );
    }
    return '';
  };

  const renderMobileContent = () => {
    // Phone number has been verified and otpnumber exists show tick
    if (otpFlow.mobile.verified && otpFlow.mobile.number) {
      return (
        <>
        <TextStyle element="p" alias="Label">Mobile number</TextStyle>
        <Spacing bottom="spacingHalf"/>
        <TextStyle element="p" alias="Base">
        {otpFlow.mobile.number}
        </TextStyle>
        <Spacing bottom="spacing2x"/>
        <div role="status">
          <MessageInline
            variant="Success"
            developmentUrl="/able-sprites.svg"
          >
            <React.Fragment key=".0">
              Your mobile number has been updated.{' '}
            </React.Fragment>
          </MessageInline>
        </div>
        <Spacing bottom="spacing1x"/>
        {renderButton(
          { modalType: 'mobile' },
          mobileLoa,
          updateMobileLabel,
          'Update mobile number',
          'Edit'
        )}
        </>
      );
    }
    // Phone number not verified no number saved in otp or accountdetails
    if (!otpFlow.mobile.verified && !otpFlow.mobile.number && !accountMobile) {
      return (
        <>
        <TextStyle element="p" alias="Label">Mobile number</TextStyle>
          {renderButton(
            { modalType: 'mobile' },
            mobileLoa,
            'Add mobile number',
            'Add mobile number',
            'Plus'
          )}
        </>
      );
    }
    // Not verified but otp number exists from prior successful flow
    if (!otpFlow.mobile.verified && otpFlow.mobile.number) {
      
      return (
        <>
         <TextStyle element="p" alias="Label">Mobile number</TextStyle>
           <Spacing bottom="spacingHalf"/>
            <TextStyle element="p" alias="Base">
            {otpFlow.mobile.number}
            </TextStyle>
          {renderButton(
            { modalType: 'mobile' },
            mobileLoa,
            updateMobileLabel,
            'Update mobile number',
            'Edit'
          )}
        </>
      );
     };
    // Grab from account details
    return (
      <>
        <TextStyle element="p" alias="Label">Mobile number</TextStyle>
        <Spacing bottom="spacingHalf"/>
          <TextStyle element="p" alias="Base">
            {accountMobile}
          </TextStyle>
        {renderButton(
          { modalType: 'mobile' },
          mobileLoa,
          updateMobileLabel,
          'Update mobile number',
          'Edit'
        )}
      </>
    );
  };

  const renderEmailContent = () => {
    // Email is verified and email exists tick
    if (otpFlow.email.verified && otpFlow.email.email) {
      return (
        <>
        <TextStyle element="p" alias="Label">Email address</TextStyle>
        <Spacing bottom="spacingHalf"/>
        <TextStyle element="p" alias="Base">
        {otpFlow.email.email}
        </TextStyle>
        <Spacing bottom="spacing2x"/>
        <div role="status">
          <MessageInline
            variant="Success"
            developmentUrl="/able-sprites.svg"
          >
            <React.Fragment key=".0">
              Your email address has been updated.{' '}
            </React.Fragment>
          </MessageInline>
        </div>
        <Spacing bottom="spacing1x"/>
        {renderButton(
          { modalType: 'sendemail' },
          emailLoa,
          updateEmailLabel,
          'Update email number',
          'Edit'
        )}
        </>
      );
    }
    // Email not verified no email and no account email
    if (!otpFlow.email.verified && !otpFlow.email.email && !accountEmail) {
      return (
        <>
        <TextStyle element="p" alias="Label">Email address</TextStyle>
          {renderButton(
            { modalType: 'sendemail' },
            emailLoa,
            'Add email address',
            'Add email address',
            'Plus'
          )}
        </>
      );
    }
    // Not verified but email exists, coming from prior flow
    if (!otpFlow.email.verified && otpFlow.email.email) {
      return (
        <>
         <TextStyle element="p" alias="Label">Email address</TextStyle>
        <Spacing bottom="spacingHalf"/>
        <TextStyle element="p" alias="Base">
        {otpFlow.email.email}
        </TextStyle>
          {renderButton(
            { modalType: 'sendemail' },
            emailLoa,
            updateEmailLabel,
            'Update email number',
            'Edit'
          )}
          
        </>
      );
    }
    // Default to taking from account details
    return (
      <>
        <TextStyle element="p" alias="Label">Email address</TextStyle>
        <Spacing bottom="spacingHalf"/>
        <TextStyle element="p" alias="Base">
        {accountEmail}
        </TextStyle>
        {renderButton(
          { modalType: 'sendemail' },
          emailLoa,
          updateEmailLabel,
          'Update email number',
          'Edit'
        )}
      </>
    );
  };

  const renderAddressContent = () => {
    // Address exists in state and has been verified ie "triggered"
    if (otpFlow.address.maskedAddress && otpFlow.address.triggered) {
      return (
        <>
        <TextStyle element="p" alias="Label">Residential address</TextStyle>
        <Spacing bottom="spacingHalf"/>
        <TextStyle element="p" alias="Base">
        {otpFlow.address.maskedAddress}
        </TextStyle>
        <Spacing bottom="spacing2x"/>
        <div role="status">
          <MessageInline
            variant="Success"
            developmentUrl="/able-sprites.svg"
          >
            <React.Fragment key=".0">
              Your residential address has been updated.{' '}
            </React.Fragment>
          </MessageInline>
        </div>
        <Spacing bottom="spacing1x"/>
        {renderButton(
          { modalType: 'address' },
          addressLoa,
          updateHomeAddressLabel,
          'Update residential address',
          'Edit'
        )}
        </>
      );
    }
    // Address exists
    if (otpFlow.address.maskedAddress) {
      return (
        <>
         <TextStyle element="p" alias="Label">Residential address</TextStyle>
        <Spacing bottom="spacingHalf"/>
        <TextStyle element="p" alias="Base">
        {otpFlow.address.maskedAddress}
        </TextStyle>
          {renderButton(
            { modalType: 'address' },
            addressLoa,
            updateHomeAddressLabel,
            'Update Residential address',
            'Edit'
          )}
        </>
      );
    }
    return (
      <>
      <TextStyle element="p" alias="Label">Residential address</TextStyle>
        {renderButton(
          { modalType: 'address' },
          addressLoa,
          'Add residential address',
          null,
          'Plus'
        )}
      </>
    );
  };

  // If we have come back from LOA check trigger modal if needed
  useEffect(() => {
    if (loaStatus === 'success') {
      toggleView(loaOpts);
    }
  }, [loaStatus]);

  return (
    <>
      {(mobile || email || address) && (
        <div>
          {contactErrorCopy && contactErrorCode && (
            <div>
                <MessageSection
                  variant='Error'
                  developmentUrl='./able-sprites.svg'
                  description={convert(contactErrorCopy)}
                  />
                <Spacing top="spacing5x"/>
            </div>
          )}
          {!contactErrorCopy && contactErrorCode && (
            <div>
              <MessageSection
                variant="Attention"
                titleText="Need to update your details?"
                description="This is so we have the most up to date information so we can contact you."
                actionText="Go to My Telstra"
                actionLink="https://www.myservices.telstra.com.au/"
                developmentUrl='./able-sprites.svg'
              />
              <Spacing top="spacing5x"/>
            </div>
           )} 
          {(loaStatus === 'attempted' || loaStatus === 'failed') && (
            <div>
              <MessageSection
                variant="Attention"
                titleText="Your contact details haven't been verified yet."
                description="Send us a message in My Telstra to update your email and phone number."
                actionText="Message us"
                actionLink="https://open.mytelstra.app/BJRB/622a8cd"
                developmentUrl='./able-sprites.svg'
              />
              <Spacing top="spacing5x"/>
            </div>
          )}
          {loaStatus === 'denied' && (
            <div>
              <MessageSection
                variant='Attention'
                titleText="You skipped our two-step verification process."
                description="This is a security requirement to update your contact details. If you need to update your details you can do so later."
                actionText="Go to My Telstra"
                actionLink="https://www.myservices.telstra.com.au/"
                developmentUrl='./able-sprites.svg'/>
                <Spacing top="spacing5x"/>
            </div>
          )}
          <>
          
            {mobile && (
              <>
                {renderMobileContent()}
                {otpMobileError && (
                  <TextStyle element="p" alias="FinePrint" className="opt-validation-error">
                  <Icon
                    size="24"
                    developmentUrl="/able-sprites.svg"
                    icon="Error"
                    screenReaderContent="Error"
                    role="img"
                    aria-label="Error"
                  />
                  <span>You have mobile error</span>
                </TextStyle>
                )}
                <Spacing top="spacing1x"/>
               <Divider colour="dividerSubtle"/>
                </>
            )}
            {email && (
                <>
                <Spacing top="spacing2x"/>
                
                {renderEmailContent()}
                {otpEmailError && (
                   <TextStyle element="p" alias="FinePrint" className="opt-validation-error">
                   <Icon
                     size="24"
                     developmentUrl="/able-sprites.svg"
                     icon="Error"
                     screenReaderContent="Error"
                     role="img"
                     aria-label="Error"
                   />
                   <span>You have an email error</span>
                 </TextStyle>
                )}
                <Spacing top="spacing1x"/>
               <Divider colour="dividerSubtle"/>
              </>
            )}
            {address && (
              <>
              <Spacing top="spacing2x"/>
             
                {renderAddressContent()}
                {otpAddressError && (
                   <TextStyle element="p" alias="FinePrint" className="opt-validation-error">
                   <Icon
                     size="24"
                     developmentUrl="/able-sprites.svg"
                     icon="Error"
                     screenReaderContent="Error"
                     role="img"
                     aria-label="Error"
                   />
                   <span>You have an address error</span>
                 </TextStyle>
                )}
                <Spacing top="spacing1x"/>
               <Divider colour="dividerSubtle"/>
              </>
            )}
          </>{
          createPortal(
          <Sidebar
          pullRight
          sidebar={renderSideBar()}
          open={isSidebarOpen}
          onSetOpen={onSetSidebarOpen}
          styles={SidebarStyles(isSidebarOpen)}
          sidebarClassName="sidebar"
          closeTimeoutMS={400}
          isOpen={modalOpen}
          onRequestClose={() => toggleView({ clearErrors: true })}
          shouldCloseOnOverlayClick={false}
          shouldFocusAfterRender
          shouldReturnFocusAfterClose
          contentLabel="OTP flow"
          aria={{
            labelledby: 'Telstra Loyalty enroll flow',
            describedby: 'Enrollment flow to register for Telstra loyalty'
          }}
        >
          <p />
        </Sidebar>,
            document.getElementById('sidebar-portal')
          )}
         </div>
      )}
    </>
  );
};

export default OtpFlowV2;
