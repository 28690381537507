import React from 'react';
import { useSelector } from 'react-redux';
import { Spacing, TextStyle } from '@able/react';
import { Cta, InlineLoadingSpinner } from 'components';

import {
  getDefaultAccountPointsValue,
  getDefaultTotalPoints
} from 'selectors/GetDefaultPoints';

import PointsTable from './PointsTable/PointsTable';
import PointsError from './PointsError/PointsError';
import NoPoints from './NoPoints/NoPoints';

import './RecentActivity.scss';

const RecentActivity = () => {
  const activeAccount = useSelector(state =>
    getDefaultAccountPointsValue(state)
  );

  const points = useSelector(state => getDefaultTotalPoints(state));

  const pointsPending = useSelector(state => state.points.pending);
  const pointsHistory = useSelector(state => state.pointsHistory);

  const pending = pointsPending || pointsHistory.pending;

  const renderPointsView = () => {
    const { transactions = [] } = pointsHistory;
    const { failure } = points;

    const pointsTableItems = transactions.slice(0, 5);

    if (failure) {
      return <PointsError />;
    }

    const hasTransactions = transactions.length > 0;

    if (
      (activeAccount.pointsValue === '--' || activeAccount.pointsValue === 0) &&
      hasTransactions === false
    ) {
      return <NoPoints />;
    }

    const pointsActivityFragment = (
      <>
        <Spacing bottom="spacing7x" />
        <PointsTable transactions={pointsTableItems} />

        <div className="view-history-container">
          <Spacing bottom="spacing4x" />
          <Cta
            action="basic"
            trackingCode="view points history"
            url="/history"
            ctaProps={{
              variant: 'LowEmphasis',
              label: 'View points history'
            }}
          />
        </div>
      </>
    );

    return (
      <>
        <TextStyle alias="HeadingC" element="h2">
          My Recent Activity
        </TextStyle>
        {pointsActivityFragment}
      </>
    );
  };

  return (
    <>
      {pending ? (
        <InlineLoadingSpinner
          smallSpinner
          loadingText="Loading points activity..."
        />
      ) : (
        <div className="tplus-recent-activity">{renderPointsView()}</div>
      )}
    </>
  );
};

export default RecentActivity;
