import { history } from 'store';
import _ from 'lodash';
import * as actions from 'constants/actions';
import { REDIRECT_SAVED_LOCATION_REF } from 'constants/general';
import { partnersConfig } from '../oidc/oidc-configs';
import axios from './util/axiosHandle';

const { api } = partnersConfig;

// ------------------------------------
// ----------- MOVIES API -------------
// ------------------------------------
export const getMovies = ({ state = null, cinemaId = null }) => {
  return axios({
    params: {
      method: 'get',
      url: api.baseUrl + api.endpoints.movies,
      params: {
        state,
        'cinema-id': cinemaId
      }
    },
    type: 'public'
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getCinemaState = () => {
  return axios({
    params: {
      method: 'get',
      url: api.baseUrl + api.endpoints.cinemaStates
    },
    type: 'public'
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getCinemas = ({ state = null, movieId = null }) => {
  return axios({
    params: {
      method: 'get',
      url: api.baseUrl + api.endpoints.cinemas,
      params: {
        state,
        'movie-id': movieId
      }
    },
    type: 'public'
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getMovieSessions = ({
  state = null,
  movieId = null,
  cinemaId = null
}) => {
  return axios({
    params: {
      method: 'get',
      url: api.baseUrl + api.endpoints.movieSessions,
      params: {
        state,
        'cinema-id': cinemaId,
        'movie-id': movieId
      }
    },
    type: 'public'
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const handleTicketErrors = (error, ticketType) => {

  dispatch({
    type: actions.SET_LOADING,
    loading: false
  });

  return dispatch => {
    if (error?.response?.status) {
      const actionType =
        ticketType === 'movie'
          ? actions.SET_MOVIES_ERROR
          : actions.SET_EVENTS_ERROR;

      switch (error.response.status) {
        // local ticket error
        case 400:
        case 404:
          dispatch({
            type: actionType,
            error: error.response.data
          });

          history.push('/tickets');
          break;
        // global ticket error
        case 500:
        case 503:
          dispatch({
            type: actions.SET_ERROR,
            hasErrored: true,
            errorHeader: error.response.data.message,
            errorMessage: error.response.data.message,
            error
          });
          history.push('/error');
          break;
        case 403:
          dispatch({
            type: actions.SET_ERROR,
            hasErrored: true,
            errorHeader: error.response.data.message,
            errorMessage: error.response.data.message,
            error
          });
          history.push('/ineligible');
          break;
        default:
      }
    } else {
      // Uncatchable / No response errors here
      dispatch({
        type: actions.SET_EVENTS_ERROR,
        error: { message: 'An error has occured' }
      });
      history.push('/error');
    }
  };
};

export const buyMovieTicket = ({
  state = null,
  partnerId = null,
  movieId = null,
  cinemaId = null,
  sessionId = null,
  errorUrl = null
}) => {
  return (dispatch, getState) => {
    const stateObj = getState();
    const { accountId } = stateObj.accounts;
    const userStub = errorUrl ? `/${errorUrl}` : '';
    return axios({
      params: {
        method: 'post',
        url: api.baseUrl + api.endpoints.purchaseTicket + userStub,
        data: {
          data: {
            accountId,
            partnerId,
            attributes: {
              type: 'movies',
              state,
              cinemaId,
              movieId,
              sessionId
            }
          }
        }
      }
    })
      // eslint-disable-next-line consistent-return
      .then(response => {
        const {
          data: { data: { redirect_uri: redirectUri } = { data: {} } } = {
            data: {}
          }
        } = response;

        if (redirectUri) {
          const initPrevLocation = localStorage.getItem(
            REDIRECT_SAVED_LOCATION_REF
          );
          if (initPrevLocation) {
            const originUrl = JSON.parse(initPrevLocation);
            localStorage.removeItem(REDIRECT_SAVED_LOCATION_REF);
            // If previousLocation url exists then we have redirected from login
            localStorage.removeItem(REDIRECT_SAVED_LOCATION_REF);
            window.history.replaceState(
              null,
              null,
              `${originUrl.protocol}//${originUrl.host}/tickets`
            );
            return window.location.assign(redirectUri);
          }
          // Otherwise we are already logged in just kick off
          window.history.replaceState(
            null,
            null,
            `${window.location.protocol}//${window.location.host}/tickets`
          );
          return window.location.assign(redirectUri);
        }
        dispatch({
          type: actions.SET_FLOW,
          payload: null
        });
      })
      .catch(error => {
        dispatch(handleTicketErrors(error, 'movie'));
        dispatch({
          type: actions.SET_FLOW,
          payload: null
        });
      });
  };
};

// ------------------------------------
// ----------- EVENTS API -------------
// ------------------------------------
export const getEvents = (id, user) => {
  const userStub = user ? `/${user}` : '';
  const ticketUrl =
    id === -1
      ? api.baseUrl + api.endpoints.events
      : `${api.baseUrl + api.endpoints.events}/${id}`;

  return axios({
    params: {
      method: 'get',
      url: ticketUrl + userStub
    },
    type: 'public'
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const buyEventTicket = (accountId, eventId, eventPerformanceId) => {
  return (dispatch, getState) => {
    return axios({
      params: {
        method: 'post',
        url: `${api.baseUrl + api.endpoints.purchaseTicket}`,
        data: {
          data: {
            accountId,
            partnerId: '',
            attributes: {
              type: 'events',
              eventId,
              eventPerformanceId
            }
          }
        }
      }
    })
      // eslint-disable-next-line consistent-return
      .then(response => {
        const {
          data: { data: { redirect_uri: redirectUri } = { data: {} } } = {
            data: {}
          }
        } = response;

        if (redirectUri) {
          const state = getState();
          // Pulling out tier, tier priority flag from current state
          const {
            accounts: { loyaltyTier },
            events: {
              childEvents: {
                details: { tierPriority },
                children
              }
            }
          } = state;

          // Find the actual performance we are dealing with then find the ticketPartner/vendor
          const matchedPerf = _.find(children, {
            id: parseInt(eventPerformanceId)
          });

          // Pull out ticketPartner with a default set
          const { ticketPartner = null } = matchedPerf;

          const initPrevLocation = localStorage.getItem(
            REDIRECT_SAVED_LOCATION_REF
          );

          const redirectUrl = new URL(redirectUri);

          // Figure out if we need to append our ticketek specific params
          // For GOLD peeps
          redirectForGold(ticketPartner, loyaltyTier, tierPriority, redirectUrl);
          // The rest
          redirectForRest(ticketPartner, loyaltyTier, tierPriority, redirectUrl);

          if (initPrevLocation) {
            const originUrl = JSON.parse(initPrevLocation);
            // If previousLocation url exists then we have redirected from login
            window.history.replaceState(null, null, originUrl.href);
            return window.location.assign(redirectUrl);
          }
          // Otherwise we are already logged in just kick off
          return window.location.assign(redirectUrl);
        }
      })
      .catch(error => {
        dispatch(handleTicketErrors(error, 'event'));
      });
  };
};

const redirectForRest = (ticketPartner, loyaltyTier, tierPriority, redirectUrl) => {
  if ((ticketPartner === 'Ticketek' || ticketPartner === 'ticketek') &&
    (loyaltyTier === 'SILVER' ||
      loyaltyTier === 'MEMBER' ||
      loyaltyTier === 'BUSINESS') &&
    tierPriority === true) {
    redirectUrl.searchParams.append('eg', 'TELSMEM');
    redirectUrl.searchParams.append('ep', 'TELSMEM');
  }
}

const redirectForGold = (ticketPartner, loyaltyTier, tierPriority, redirectUrl) => {
  if ((ticketPartner === 'Ticketek' || ticketPartner === 'ticketek') &&
    loyaltyTier === 'GOLD' &&
    tierPriority === true) {
    redirectUrl.searchParams.append('eg', 'TELSGLD');
    redirectUrl.searchParams.append('ep', 'TELSGLD');
  }
}

