import { v4 as uuidv4 } from 'uuid';
import axios from './util/axiosHandle';
import { apiConfig as api } from '../oidc/oidc-configs';

const registerApi = () => {
  const params = {
    method: 'post',
    url: api.baseUrl + api.endpoints.enrol
  };
  const headers = {
    'ds-correlation-id': uuidv4()
    // 'ds-source-system': 'Telstra Plus SPA'
  };

  return axios({
    params,
    incomingheaders: headers
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export default registerApi;
