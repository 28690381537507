import React, { useState, useEffect } from 'react';
import { signOut } from 'oidc/initiateOidc';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from 'react-sidebar';
import FocusTrap from 'focus-trap-react';
import { NotificationBar } from 'components';

import usePointsTransfer from 'views/TelstraPlusView/PointsTransferView/use-points-transfer';
import { getAemContent } from 'selectors/GetAemContent';
import { setUser, addLinkClickEvent } from 'utils/analytics';
import { LOGGER } from 'utils/AppLog';
import { getButtonType } from 'utils/checkExternalLink';
import { HtmlConverter } from 'utils/HtmlConverter';

import IconClose from 'icons/IconClose';
import IconArrow from 'icons/IconArrow';
import { Icon, ActionButton, TextStyle, 
  Spacing, ActionList, ActionListItem, Surface, IconButton } from '@able/react';

import smallSpinner from 'assets/images/Spinner-48-Loyalty.gif';
import whiteSpinner from 'assets/images/Spinner_reversed.gif';
import tcomSVG from 'assets/images/t-logo-pacific.svg';
import logoSVG from 'assets/images/t-logo.svg';
import tile from 'assets/images/success-tile@2x.png';

// import { getPointsTransferFlag } from 'selectors/GetPointsTransferFlag';

import {
  SWITCH_ACCOUNT,
  GET_POINTS_HISTORY_PENDING,
  GET_POINTS_HISTORY_FAILURE,
  GET_POINTS_HISTORY_SUCCESS,
  CLEAR_EXP_CONTENT
} from 'constants/actions';
import getPointsHistory from 'api/getPointsHistory';

import * as actions from 'constants/actions';

import { SEGMENT_SESSION_STORAGE_TOKEN_NAME } from 'constants/rewardsStore';
import { SidebarStyles } from './SidebarStyles';

import '../Header.scss';


const HeaderAuth = props => {
  const dispatch = useDispatch();

  const { buttonText } = props;

  const [{ allowPointsTransfer }] = usePointsTransfer({});

  // State
  const [isScrolling, setIsScrolling] = useState(false);
  const [isDocked, setIsDocked] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showNotificationBar, setShowNotificationBar] = useState(false);
  const [isMultiCac, setIsMultiCac] = useState(false);

  // Redux store
  const accounts = useSelector(state => state.accounts);
  const accountId = useSelector(state => state.accounts.accountId);
  const isEnrolled = useSelector(state => state.enrolment.enrolled);
  const points = useSelector(state => state.points);
  const role = useSelector(state => state.accounts.role);
  const welcome = useSelector(state => state.appStatus.welcomeState);
  const getDetailsData = useSelector(
    state => state.eligibility.getDetails.data
  );

  const notificationBarContent = useSelector(state =>
    getAemContent(state, 'global/auth/components', 'notificationBar')
  )[0];

  const { contactUUID: uuid, rcrmContactId = '' } = getDetailsData;

  const onSetSidebarOpen = open => {
    setIsSidebarOpen(open);
    window.scrollTo(0, 0);
  };

  const openSideBar = () => {
    setIsSidebarOpen(true);
    window.scrollTo(0, 0);
  };

  const closeSideBar = () => {
    document.body.style.overflowY = 'visible';
    setIsSidebarOpen(false);
  };

  const dismissWelcome = () => {
    dispatch({
      type: actions.SET_WELCOME,
      welcomeState: false
    });
  };

 
  const switchAccount = (cac, membershipIndex, pointsValue, onHoldPoints) => {
    dispatch({ type: GET_POINTS_HISTORY_PENDING });

    dispatch({ type: CLEAR_EXP_CONTENT });

    dispatch({
      type: SWITCH_ACCOUNT,
      cac,
      membershipIndex,
      pointsValue,
      onHoldPoints
    });

    sessionStorage.removeItem(SEGMENT_SESSION_STORAGE_TOKEN_NAME);

    getPointsHistory(cac.accountId)
      .then(response => {
        dispatch({
          type: GET_POINTS_HISTORY_SUCCESS,
          response
        });
      })
      .catch(error => {
        LOGGER.debug('error', error);
        dispatch({ type: GET_POINTS_HISTORY_FAILURE });
      });
    closeSideBar();
  };

  const titleCase = str => {
    let i;
    const splitStr = str.toLowerCase().split(' ');
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < splitStr.length; i++) {
      if (splitStr[i] !== '') {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
    }
    return splitStr.join(' ');
  };

  const renderSideBarContent = () => {
    // Welcome state was triggered
    if (welcome) {
      return (
        <>
          <div className="h-bar visible-xs" />

          <button
            aria-label="Dismiss-welcome-message"
            className="close-sidebar-btn"
            type="button"
            onClick={() => dismissWelcome()}
          >
            <IconClose />
          </button>
          <h3>Welcome to Telstra Plus &trade;</h3>
          <h4>You have multiple memberships</h4>
          <p className="intro-to-mcac">
            You can select which Telstra Plus membership you’d like to view at
            any time.
          </p>
          <button
            aria-label="Choose membership"
            type="button"
            className="primary-cta-18 dismiss-btn"
            onClick={() => dismissWelcome()}
          >
            Choose membership <IconArrow />
          </button>
          <div
            className="decoration"
            style={{ backgroundImage: `url(${tile})` }}
          />
        </>
      );
    }

    const formatAccountID = id => {
      const formatedId = `${id.substr(0, 4)} ${id.substr(4, 5)} ${id.substr(
        9
      )}`;
      return formatedId;
    };

    const formattedPoints = parseInt(accounts.pointsValue).toLocaleString(
      navigator.language,
      {
        minimumFractionDigits: 0
      }
    );
    let parsedPoints = '--';

    if (!isNaN(parseInt(accounts.pointsValue))) parsedPoints = formattedPoints;
    const element = getButtonType('/rewards');

    return (
      <>
      <Surface
        variant="SurfaceFlat"
        background="materialBaseSecondary"
        className={`sidebar-top-border ${accounts.loyaltyTier}`}
        >
        <Link className="logo" to="/">
          <img src={logoSVG} alt="Telstra Logo" />
        </Link>
        <button
          className="close-sidebar-btn"
          type="button"
          aria-label="close-sidebar"
          onClick={() => closeSideBar()}
          onKeyPress={() => closeSideBar()}
        >
          <IconButton 
            icon="Close" 
            size="32"
            developmentUrl="/able-sprites.svg" />
        </button>
        </Surface>
        <div className="h-bar visible-xs">
          <Surface
              variant="SurfaceFlat"
              background="materialBaseSecondary"
              >
            <div className='mobile-account-details'>
              <Spacing top="spacing2x" />
              <TextStyle alias="HeadingB" element="span">Hi {accounts.accountName}</TextStyle>
              <div className="points-tier-span main">
                <span>
                  <TextStyle alias="Label" element="strong" className="side-tier-points">{accounts.loyaltyTier.toLowerCase()}</TextStyle>
                  <TextStyle alias="FinePrint" element="strong" className="side-tier-points left">Tier</TextStyle>
                </span>
                <span>
                  <TextStyle alias="Label" element="strong" className="side-tier-points right">{parsedPoints}</TextStyle>
                  <TextStyle alias="FinePrint" element="strong">points</TextStyle>
                </span>
              </div>
              <ActionButton 
                variant="LowEmphasis"
                element={element}
                label="Reedem Points"
                to='/rewards'
                onClick={() => closeSideBar()}
                onKeyPress={() => closeSideBar()}
              />
              <Spacing bottom="spacing2x" />
            </div>
          <div className='tier-switch-span'>
            <Spacing topVMD='spacing5x' topVLG='spacing5x' topVSM='spacing3x' topVXS='spacing3x'/>
            <TextStyle alias='HeadingB' element='span'>
              View other accounts
            </TextStyle>
            <Spacing bottom='spacing2x'/>
            <div>
            {accounts.sortedAccounts.length > 1 &&
              accounts.sortedAccounts.map((cac, idx) => {
                const pts =  !points.pending &&
                  cac.points &&
                  cac.points.loyaltyPoints &&
                  parseInt(cac.points.loyaltyPoints).toLocaleString(
                    navigator.language,
                    {
                      minimumFractionDigits: 0
                    }
                  );
                  const descrip = cac.loyaltyTier && (cac.loyaltyTier.charAt(0).toUpperCase() + cac.loyaltyTier.slice(1).toLowerCase() +
                                           "\xa0 Tier\xa0\xa0\xa0|\xa0\xa0\xa0" + pts + "\xa0 points  ");
                return (
                  <ActionList variant="Detailed" topDivider={false} >
                      <ActionListItem 
                          label= {titleCase(cac.accountName)}
                          description={descrip}
                          element="button"
                          onClick={() => {
                            switchAccount(
                              cac,
                              idx + 1,
                              cac?.points?.loyaltyPoints,
                              cac?.points?.onHoldPoints
                            );
                          }} />
                  </ActionList>
                );
              })
            }
            </div>
            <div className="call-us-desc">
              <Spacing top="spacing2x" />
              <TextStyle alias='FinePrint' colour='Subtle'>
                To talk to us about consolidating your Telstra Plus accounts call us on 13 22 00.
              </TextStyle>
            </div>
          {allowPointsTransfer ? (
            <div>
              <Spacing bottom='spacing1x' />
              <ActionButton 
                variant="LowEmphasis"
                element="Link"
                label="Transfer points between accounts"
                to="/transfer"
                onClick={() => closeSideBar()}
              /> 
            </div>
          ) : (
            ''
          )}
          <Spacing bottom='spacing4x' bottomVSM='spacing3x' bottomVXS='spacing3x' />
          <TextStyle alias="HeadingB" element="span">Take me to</TextStyle>
          <Spacing bottom='spacing1x' bottomVMD='spacing4x' bottomVLG='spacing4x'/>
          <ActionList variant="Simple" topDivider={true} >
              <ActionListItem label="Telstra.com.au" element="a" to="http://www.telstra.com.au" />
          </ActionList>
          <Spacing bottom='spacing4x'/>
          <ActionButton 
            variant="MediumEmphasis"
            element="button"
            label="Sign Out"
            className='sign-out-btn'
            onClick={() => handleSignout()}
          />
          <Spacing bottom='spacing5x' />
        </div>
        </Surface>
        </div>
      </>
    );
  };

  const renderSideBar = () => {
    // Focus Trap for accessibilitys sake
    if (isSidebarOpen) {
      document.body.style.overflowY = 'hidden';
      return (
        <FocusTrap
          focusTrapOptions={{
            returnFocusOnDeactivate: false
          }}
        >
          <div aria-hidden="false" className="sidebar-content">
            {renderSideBarContent()}
          </div>
        </FocusTrap>
      );
    }
    return (
      <div className="sidebar-content" style={{ display: 'none' }}>
        {renderSideBarContent()}
      </div>
    );
  };

  const handleSignout = () => {
    addLinkClickEvent('header logout');
    signOut();
  };

  const allowablePath = () => {
    switch (props.location.pathname) {
      case '/join':
      case '/success':
      case '/opt-out':
      case '/error':
      case '/not-eligible':
      case '/explainer': {
        return false;
      }
      default: {
        return true;
      }
    }
  };

  useEffect(() => {
    if (notificationBarContent && notificationBarContent.active) {
      setShowNotificationBar(true);
    }
  }, [notificationBarContent]);

  // If user changes CAC account
  useEffect(() => {
    const listenScrollEvent = () => {
      if (!isScrolling) {
        setIsScrolling(true);
        setTimeout(() => {
          setIsScrolling(false);
        }, 300);
      }

      if (window.scrollY > 40) {
        // 40px is the height of the tcom-bar
        setIsDocked(false);
      } else {
        setIsDocked(true);
      }
    };

    window.addEventListener('scroll', listenScrollEvent);

    if (typeof accounts.sortedAccounts !== 'undefined') {
      if (accounts.sortedAccounts.length > 1) {
        setIsMultiCac(true);
      }
    }

    const isMcac = accounts.sortedAccounts.length > 1;

    if (welcome && isMcac) {
      openSideBar();
    }

    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener('scroll', listenScrollEvent);
    };
  }, [accounts, role, welcome, isScrolling]);

  useEffect(() => {
    // set user analytics when header changes
    const user = {
      username: accounts.accountName,
      points: accounts.pointsValue,
      tier: accounts.loyaltyTier,
      rcrmContactId,
      uuid
    };
    setUser(user);
  }, [accountId, accounts.pointsValue, rcrmContactId]);

  const NotificationBarOffset = () => {
    return (
      <>
        {showNotificationBar && <div className="offset-div-notification-bar" />}
      </>
    );
  };

  const renderLoyaltyHeader = () => {
    const formattedPoints = parseInt(accounts.pointsValue).toLocaleString(
      navigator.language,
      {
        minimumFractionDigits: 0
      }
    );

    let parsedPoints = '--';

    if (!isNaN(parseInt(accounts.pointsValue))) parsedPoints = formattedPoints;

    const tier = accounts.loyaltyTier;

    return (
      <>
        <Sidebar
          pullRight
          sidebar={renderSideBar()}
          open={isSidebarOpen}
          onSetOpen={onSetSidebarOpen}
          styles={SidebarStyles(isSidebarOpen)}
          sidebarClassName="sidebar"
        >
          <p />
        </Sidebar>
        <header
          role="banner"
          className={`enrolment-header-large tcom-global-navigation ${
            isScrolling ? 'scrolling' : 'not-scrolling'
          } ${isDocked ? 'docked' : 'not-docked'} ${
            !showNotificationBar ? 'hide-notification' : 'show-notification'
          }`}
          id="tcom-navigation"
        >
          {notificationBarContent && notificationBarContent.active && (
            <NotificationBar
              {...notificationBarContent}
              onClose={() => setShowNotificationBar(false)}
            />
          )}
          <nav
            // style={{ backgroundColor: getTierColour(accounts.loyaltyTier) }}
            className={`tcom-nav-container container hidden-xs ${accounts.loyaltyTier}`}
            aria-label="User header"
          >
            <Link className="logo" to="/">
              <img src={logoSVG} alt="Telstra Logo" />
            </Link>
            <TextStyle alias="Base" element="span" className="tplus-text">
                Telstra Plus
            </TextStyle>

            <div className="tier-container">
              <div className="tier-info">
                <ul>
                  <li>
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {/* <Icon 
                        icon={`${accounts.loyaltyTier === 'BUSINESS' ? 'BusinessService' : 'Profile'}`}
                        size="28" 
                        className="able_icon_header"
                        screenReaderContent="Profile icon"
                        developmentUrl="/able-sprites.svg"
                        tabIndex="-1"
                        /> */}
                      <TextStyle alias="Base" element="strong" className="lineEllipsis right-text border">
                        Hi {accounts.accountName}
                      </TextStyle>
                    </span>
                  </li>
                  <li>
                    <span>
                      <TextStyle alias="Label" element="strong" className="left-text tier">
                        {tier.toLowerCase()}
                      </TextStyle>
                      <TextStyle alias="Base" element="p" className="right-text border">
                        Tier
                      </TextStyle>
                    </span>
                  </li>
                  <li>
                    <span>
                      {points.pending ? (
                        <strong>
                          <img
                            className="inline-spinner"
                            src={whiteSpinner}
                            alt="Loading"
                          />
                        </strong>
                      ) : (
                        <TextStyle alias="Label" element="strong" className="left-text">
                          {parsedPoints}
                        </TextStyle>
                      )}
                    </span>
                    <TextStyle alias="Base" element="p" className="right-text">
                      points
                    </TextStyle>
                  </li>
                </ul>
                {isMultiCac && (
                <button
                  className="signout multicac"
                  type="button"
                  onClick={() => openSideBar()}
                >
                  <TextStyle alias="Label" element="span" className="multicac-btn">
                      View other accounts
                  </TextStyle>
                  <Icon
                      size="24"
                      developmentUrl="/able-sprites.svg"
                      icon="ChevronDown"
                      screenReaderContent="Open Sidebar"
                      className="icon-fill"
                    />
                </button>
                )}
                {!isMultiCac && (
                <button
                  className="signout"
                  type="button"
                  label="Sign Out"
                  onClick={() => handleSignout()}
                >
                  <TextStyle alias="Label" element="span">
                      Sign out
                  </TextStyle>
                </button>
                )}
              </div>
            </div>
          </nav>

          <nav
            className={`tcom-nav-container visible-xs ${accounts.loyaltyTier} `}
            aria-label="User header">

            <Link className="logo" to="/">
              <img src={logoSVG} alt="Telstra Logo" />
            </Link>


            <div className="tier-container">
              <div className="tier-info">
                <ul>
                  <li>
                    <span style={{ whiteSpace: 'nowrap' }}>
                      <Spacing right="spacing1x">
                        <TextStyle alias="Base" element="strong" className="lineEllipsis right-text">
                          Hi {accounts.accountName}
                        </TextStyle>
                      </Spacing>
                    </span>
                  </li>
                </ul>
                <button
                    type="button"
                    className='sidebar-open'
                    aria-label="Choose another membership"
                    onClick={() => openSideBar()}
                  >
                    <Icon 
                      icon="Menu" 
                      size="44"
                      developmentUrl="/able-sprites.svg"
                      screenReaderContent="Open Sidebar" />
                  </button>
              </div>
            </div>
          </nav>
        </header>
        <div className="offset-div-default" />
        {NotificationBarOffset()}
      </>
    );
  };

  const renderLoyaltyHeaderNoSub = () => {
    return (
      <>
        <header
          role="banner"
          className="not-enrolled-header tcom-global-navigation"
          id="tcom-navigation"
        >
          <nav className="tcom-nav-container" aria-label="Global header">
            <a href="http://www.telstra.com.au">
              <img src={tcomSVG} alt="Telstra Logo" />
              <TextStyle alias="BaseBig" element="span">
                    Telstra.com
              </TextStyle>
            </a>

            <button
              className="signout"
              type="button"
              onClick={() => handleSignout()}
            >
               <TextStyle alias="Base" element="span">
                    {buttonText}
                </TextStyle>
            </button>
          </nav>
        </header>
        <div className="offset-div-default" />
      </>
    );
  };

  // if (allowablePath() && isEnrolled) {
    return renderLoyaltyHeader();
  // }
  // return renderLoyaltyHeaderNoSub();
};

export default HeaderAuth;

HeaderAuth.defaultProps = {
  buttonText: 'Sign out',
  points: {
    apiResponse: {
      loyalty: {
        accounts: [{}]
      }
    }
  },
  eligibility: {
    getDetails: {
      data: {
        accounts: [
          {
            loyaltyTier: 'MEMBER'
          }
        ]
      }
    }
  },
  enrolment: {
    enrolled: false,
    membership: {
      tier: 'Member'
    }
  },
  location: {
    pathname: ''
  }
};
