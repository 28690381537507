import configUat2 from 'env/config.uat2.json';
import configUat1 from 'env/config.uat1.json';
import configUatInside from 'env/config.uat-inside.json';
import configDev from 'env/config.dev.json';
import configDevMocks from 'env/config.dev-mocks.json';
import configCI from 'env/config.ci.json';
import configNonProd from 'env/config.nonprod.json';
import configProd from 'env/config.prod.json';
import configPreProd from 'env/config.preprod.json';

let config; // YUCK: 'let'

switch (process.env.REACT_APP_CAIMAN_CONF) {
  case 'uat2':
    config = configUat2;
    break;
  case 'uat1':
    config = configUat1;
    break;
  case 'uat-inside':
    config = configUatInside;
    break;
  case 'dev':
    config = configDev;
    break;
  case 'dev-mocks':
    config = configDevMocks;
    break;
  case 'ci':
    config = configCI;
    break;
  case 'nonprod':
    config = configNonProd;
    break;
  case 'production':
    config = configProd;
    break;
  case 'preprod':
    config = configPreProd;
    break;
  case 'prod':
    config = configProd;
    break;
  default:
    config = configProd;
    break;
}

export const apiConfig = config.api;
export const ticketsConfig = config.tickets;
export const moviesConfig = config.movies;
export const oidcConfig = config.oidc;
export const addressConfig = config.address;
export const aemConfig = config.aem;
export const eventConfig = config.events;
export const partnersConfig = config.partners;
export const oAuthConfig = config.oauth;
export const rewardsConfig = config.rewards;
export const ecommerceConfig = config.ecommerce;
