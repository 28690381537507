import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { getReactComponents } from 'selectors/GetChildComponents';

import './ErrorContainer.scss';

const ErrorContainer = props => {
  const {
    defaultView = {},
    errorViews = {},
    displayErrorCode = false
  } = props;

  const [errorViewMatch, setErrorViewMatch] = useState('');
  const code = useSelector(state => state.error.errorStatusCode);

  useEffect(() => {
    _.forOwn(errorViews, (view, key) => {
      const { errorCodes } = view;
      _.forOwn(errorCodes, c => {
        const { errorCode } = c;
        if (errorCode === code.toString()) {
          setErrorViewMatch(key);
        }
      });
    });
  }, [code]);

  return (
    <>
      {errorViewMatch ? (
        <section className="tplus-error-container">
          <div className="row">{getReactComponents(props[errorViewMatch])}</div>
          {displayErrorCode && (
            <div className="tplus-error-container__code">
              <strong>Code: </strong>
              {code}
            </div>
          )}
        </section>
      ) : (
        getReactComponents(defaultView)
      )}
    </>
  );
};

export default ErrorContainer;
