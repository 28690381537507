import { batch } from 'react-redux';
import { push } from 'react-router-redux';
import { Base64 } from 'js-base64';

import { LOGGER } from 'utils/AppLog';
import getOtp from 'api/getOtp';
import verifyOtp from 'api/verifyOtp';
import * as actions from 'constants/actions';
import * as ERRCODES from 'constants/errorCodes.js';
import * as ERRMSGS from 'constants/errorMessages.js';
import * as ALERTMSGS from 'constants/alertMessages.js';

export const otpGetAction = otpDetails => {
  return dispatch => {
    return getOtp(otpDetails)
      .then(response => {
        LOGGER.debug('Get OTP', response);

        batch(() => {
          dispatch({
            type: actions.GET_OTP_SUCCESS,
            status: response.data?.data?.oneTimePinStatus
          });

          if (otpDetails.serviceType === 'SMS') {
            dispatch({
              type: actions.GET_MOBILE_TEMP_NUMBER_SUCCESS,
              temp: otpDetails.referenceId
            });
          }
          if (otpDetails.serviceType === 'EMAIL') {
            dispatch({
              type: actions.GET_EMAIL_TEMP_SUCCESS,
              temp: otpDetails.referenceId
            });
          }
          if (otpDetails.resend) {
            dispatch({
              type: actions.GET_OTP_RESEND,
              msg: ALERTMSGS.CC_RESEND
            });
          }
        });
      })
      .catch(error => {
        // Capturing the flow here to match to the modal screen names, its nicer
        let theFlow;
        switch (otpDetails?.serviceType) {
          case 'SMS':
            theFlow = 'mobile';
            break;
          case 'EMAIL':
            theFlow = 'sendemail';
            break;
          default:
            theFlow = '';
        }

        LOGGER.debug('Get OTP Error', error);
        const {
          response: { data: code }
        } = error;
        switch (code.code) {
          case ERRCODES.ERR_CC_INVALID_MOBILE: {
            dispatch({
              type: actions.GET_MOBILE_FAILURE,
              errorObject: {
                status: code,
                msg: ERRMSGS.ERR_CC_INVALID_MOBILE_MSG
              }
            });
            break;
          }
          case ERRCODES.ERR_CC_INVALID_EMAIL: {
            dispatch({
              type: actions.GET_EMAIL_FAILURE,
              errorObject: {
                status: code,
                msg: ERRMSGS.ERR_CC_INVALID_EMAIL_MSG
              }
            });
            break;
          }
          case ERRCODES.ERR_CC_MAX_OTP_ATTEMPTS: {
            dispatch({
              type: actions.GET_OTP_FAILURE,
              errorObject: {
                status: code,
                msg: ERRMSGS.ERR_CC_MAX_OTP_ATTEMPTS_MSG
              },
              flow: theFlow
            });
            // dispatch({
            //   type: actions.SET_ERROR,
            //   hasErrored: true,
            //   errorHeader: ERRMSGS.ERR_CC_MAX_OTP_ATTEMPTS_MSG,
            //   errorMessage: '',
            //   error
            // });
            // dispatch(push(otpDetails.page));
            break;
          }
          case ERRCODES.ERR_CC_MAX_PIN: {
            dispatch({
              type: actions.GET_OTP_FAILURE,
              errorObject: {
                status: code,
                msg: ERRMSGS.ERR_CC_MAX_PIN_MSG
              },
              flow: theFlow
            });

            // dispatch({
            //   type: actions.SET_ERROR,
            //   hasErrored: true,
            //   errorHeader: ERRMSGS.ERR_CC_MAX_PIN_MSG,
            //   errorMessage: '',
            //   error
            // });
            // dispatch(push(otpDetails.page));
            break;
          }
          case ERRCODES.ERR_CC_BAD_REQUEST:
          default: {
            LOGGER.debug('Get OTP failed generically', error);

            dispatch({
              type: actions.SET_ERROR,
              hasErrored: true,
              errorHeader: ERRMSGS.ERR_CC_GENERAL_MSG,
              errorMessage: '',
              error
            });

            dispatch(push('/error'));
          }
        }

        throw error;
      });
  };
};

export const otpVerifyAction = otpDetails => {
  return dispatch => {
    return verifyOtp(otpDetails)
      .then(response => {
        LOGGER.debug('Verify OTP', response);

        const {
          data: {
            data: { oneTimePinStatus = null, verificationDateTime = null } = {}
          } = {}
        } = response;

        dispatch({
          type: actions.GET_OTP_SUCCESS,
          status: oneTimePinStatus
        });
        // Flag is triggered as soon as the user has successfully verified a field via otp
        // this is not reset fo the lifetime of the session
        dispatch({
          type: actions.SET_VERIFIED_TRIGGERED,
          triggered: true
        });
        // Set servicetype value
        if (otpDetails.serviceType === 'SMS') {
          dispatch({
            type: actions.GET_MOBILE_NUMBER_VERIFIED,
            verified: true,
            verifiedDateTime: verificationDateTime,
            number: Base64.decode(otpDetails.referenceId)
          });
        }
        if (otpDetails.serviceType === 'EMAIL') {
          dispatch({
            type: actions.GET_EMAIL_VERIFIED,
            verified: true,
            verifiedDateTime: verificationDateTime,
            email: Base64.decode(otpDetails.referenceId)
          });
        }
      })
      .catch(error => {
        // Capturing the flow here to match to the modal screen names, its nicer
        let theFlow;
        switch (otpDetails.serviceType) {
          case 'SMS':
            theFlow = 'mobile';
            break;
          case 'EMAIL':
            theFlow = 'sendemail';
            break;
          default:
            theFlow = '';
        }

        LOGGER.debug('Verify OTP Error', error);
        const {
          response: { data: code }
        } = error;
        switch (code.code) {
          case ERRCODES.ERR_CC_MAX_OTP_ATTEMPTS: {
            dispatch({
              type: actions.GET_OTP_FAILURE,
              errorObject: {
                status: code,
                msg: ERRMSGS.ERR_CC_MAX_OTP_ATTEMPTS_MSG
              },
              flow: theFlow
            });

            // dispatch({
            //   type: actions.SET_ERROR,
            //   hasErrored: true,
            //   errorHeader: ERRMSGS.ERR_CC_MAX_OTP_ATTEMPTS_MSG,
            //   errorMessage: '',
            //   error
            // });
            // dispatch(push(otpDetails.page));
            break;
          }
          case ERRCODES.ERR_CC_OTP_NO_EXIST: {
            dispatch({
              type: actions.GET_OTP_FAILURE,
              errorObject: {
                status: code,
                msg: ERRMSGS.ERR_CC_OTP_NO_EXIST_MSG
              },
              flow: theFlow
            });
            // dispatch({
            //   type: actions.SET_ERROR,
            //   hasErrored: true,
            //   errorHeader: ERRMSGS.ERR_CC_OTP_NO_EXIST_MSG,
            //   errorMessage: '',
            //   error
            // });

            // dispatch(push('/error'));
            break;
          }
          case ERRCODES.ERR_CC_MAX_PIN: {
            dispatch({
              type: actions.GET_OTP_FAILURE,
              errorObject: {
                status: code,
                msg: ERRMSGS.ERR_CC_MAX_PIN_MSG
              },
              flow: theFlow
            });

            // dispatch({
            //   type: actions.SET_ERROR,
            //   hasErrored: true,
            //   errorHeader: ERRMSGS.ERR_CC_MAX_PIN_MSG,
            //   errorMessage: '',
            //   error
            // });
            // dispatch(push(otpDetails.page));
            break;
          }
          case ERRCODES.ERR_CC_OTP_INVALID: {
            dispatch({
              type: actions.GET_OTP_FAILURE,
              errorObject: {
                status: code,
                msg: ERRMSGS.ERR_CC_OTP_INVALID_MSG
              },
              flow: theFlow
            });
            // dispatch({
            //   type: actions.SET_ERROR,
            //   hasErrored: true,
            //   errorHeader: ERRMSGS.ERR_CC_OTP_INVALID_MSG,
            //   errorMessage: '',
            //   error
            // });

            dispatch(push(otpDetails.page));
            break;
          }
          case ERRCODES.ERR_CC_OTP_EXPIRED: {
            dispatch({
              type: actions.GET_OTP_FAILURE,
              errorObject: {
                status: code,
                msg: ERRMSGS.ERR_CC_OTP_EXPIRED_MSG
              },
              flow: theFlow
            });
            // dispatch({
            //   type: actions.SET_ERROR,
            //   hasErrored: true,
            //   errorHeader: ERRMSGS.ERR_CC_OTP_EXPIRED_MSG,
            //   errorMessage: '',
            //   error
            // });

            // dispatch(push('/error'));
            break;
          }
          case ERRCODES.ERR_CC_BAD_REQUEST:
          default: {
            LOGGER.debug('Verify OTP failed generically', error);
            dispatch({
              type: actions.SET_ERROR,
              hasErrored: true,
              errorHeader: ERRMSGS.ERR_CC_GENERAL_MSG,
              errorMessage: '',
              error
            });

            dispatch(push('/error'));
          }
        }

        throw error;
      });
  };
};

export const otpSetMandatoryError = opts => {
  return dispatch => {
    const { toggle } = opts;
    dispatch({
      type: actions.SET_MANDATORY_ERROR,
      payload: toggle
    });
  };
};
