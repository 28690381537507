import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Cta } from 'components';
import { Icon, TextStyle } from '@able/react';
const Lottie = lazy(() => import('lottie-react'));
import { HtmlConverter } from 'utils/HtmlConverter';
import { Timeout } from 'utils/Timeout';
import { useMedia } from 'utils/hooks/useMedia';
import greyBox from './../../../assets/images/grey-box.png'

import './PromoBox.scss';


const PromoBox = props => {

    const {
        id,
        variation,
        categoryHeadingLevel,
        category,
        heading,
        mainHeadingLevel,
        bodyCopy,
        terms,
        richMediaType,
        richmediaDesktop,
        richmediaMobile,
        altMessage,
        calloutRadio,
        ableIconName,
        callouttext,
        animationContentPath,
        animationDescription,
        animationDelay = 3000,
        videoplayerurl,
        promosize,
        ctaConfig
    } = props;

    const [lottieDesktop, setLottieDesktop] = useState(null);
    const breakpoint = useMedia(
      ['(min-width: 64rem)', '(min-width: 48rem)'],
      ['md', 'sm'],
      'xs'
    );

    useEffect(() => {
      if(animationContentPath) {
        fetchLottie();
      }
    }, animationContentPath);

    const fetchLottie = () => {
      fetch(animationContentPath)
        .then(response => response.json())
        .then(data => setLottieDesktop(data));
    }

    const setupSelectArray = () => {
        const selectArray = [];
        if (ctaConfig) {
          Object.keys(ctaConfig).forEach(i => {
            const obj = ctaConfig[i];
            selectArray.push(obj);
          });
        }
        return selectArray;
      };
    
      const hasTertiary = () => {
        let isTertiary = false;
        if (ctaConfig) {
          Object.keys(ctaConfig).forEach(i => {
            const { ctaVariant } = ctaConfig[i];
            if (ctaVariant === 'LowEmphasis') {
              isTertiary = true;
            }
          });
        }
        return isTertiary;
      };

    return (
        <>
            <div id={id} className={`tplus-promo-container ${(variation === 'right' && 'tplus-promo-container-right tplus-promo-container-wide') || ''} ${(promosize === 'wide' && 'tplus-promo-container-wide') || ''} ${(promosize === 'half' && 'tplus-promo-container-half') || ''} ${(variation === 'stacked' && 'tplus-promo-container-half') || ''}`}>
                <div className="image-wrapper tplus-promo-image">
                    {richMediaType === 'image' && (
                        <div>
                            <picture className="lazy-load" >
                                <source className="lazy-load" media="(min-width:320px) and (max-width:1023px)" srcSet={richmediaMobile} />
                                <source className="lazy-load" media="(min-width:1024px)" srcSet={richmediaDesktop} />
                                <img src={greyBox} alt={altMessage} />
                            </picture>
                            {calloutRadio === 'true' && (
                                <div className="call-out-block">
                                    <Icon
                                        size="24"
                                        developmentUrl="/able-sprites.svg"
                                        icon={ableIconName}
                                        aria-hidden
                                        screenReaderContent={callouttext}
                                        className={`tplus-icon-24 ${ableIconName ? 'able-icon' : ''} tplus-promo-container__callout`}
                                        />
                                    <span>{callouttext}</span>
                                </div>
                            )}
                        </div>
                    )}
                    {richMediaType === 'animation' && (
                        <div>
                            {animationDescription && (<p className="sr-only">{animationDescription}</p>)}
                            <Suspense>
                              <Timeout delay={animationDelay}>
                                <Lottie animationData={lottieDesktop} loop={false} />
                              </Timeout>
                              
                            </Suspense>
                        </div>
                    )}
                    {richMediaType === 'ytsrc' && (
                        <div className="tplus-video-player">
                          <iframe
                              width="100%"
                              height={`${breakpoint === 'sm' ? '360px' : (breakpoint === 'xs' ? '300px' : '320px')}`} 
                              src={`https://www.youtube.com/embed/${videoplayerurl}`}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            />
                            {/* <ReactPlayer width="100%" height={`${breakpoint === 'sm' ? '360px' : (breakpoint === 'xs' ? '300px' : '320px')}`} 
                                        url={`https://www.youtube.com/watch?v=${videoplayerurl}`} playerVars={contt}/> */}
                        </div>
                    )}
                </div>
                <div className={`tplus-promo-content ${(variation === 'right' && 'tplus-promo-content-right') || ''}`}>
                    {category && (
                        <TextStyle element={categoryHeadingLevel} className="tplus-promo-heading-small">
                            {category}
                        </TextStyle>
                    )}
                    {heading && (
                        <TextStyle element={mainHeadingLevel} className="tplus-promo-heading">
                            {heading}
                        </TextStyle>
                    )}
                    {bodyCopy && (
                        <TextStyle element="p" alias="Base" className={`tplus-promo-body ${(promosize === 'wide' && 'body-copy-md-20 body-copy-lg-22') || ''} ${(variation === 'left' && 'body-copy-md-20 body-copy-lg-22') || ''} ${(variation === 'right' && 'body-copy-md-20 body-copy-lg-22') || ''}`}>
                            {HtmlConverter(bodyCopy)}
                        </TextStyle>
                    )}
                    {terms && (
                        <TextStyle element="p" alias="FinePrint" className="tplus-promo-terms-conditions">
                            {HtmlConverter(terms)}
                        </TextStyle>
                    )}
                    <div className="tplus-promo-links-container">
                    {setupSelectArray() &&
                      setupSelectArray()
                        .filter(function(cta) {
                          return cta.ctaVariant !== 'LowEmphasis';
                        })
                        .map(cta1 => (
                          <div className={`tplus-promo-link ${cta1.ctaVariant === 'MediumEmphasis' ? (promosize === 'half' ? 'tplus-promo-link--half' : '') : ''}`}>
                            <Cta
                              action={cta1.ctaAction}
                              trackingCode={cta1.ctaTrackingCode}
                              url={cta1.ctaUrl}
                              ctaProps={{
                                variant: cta1.ctaVariant,
                                label: cta1.ctaText,
                                className: 'button-tplus'
                              }}
                            ></Cta>
                          </div>
                        ))}
                    </div>
                    {hasTertiary() && (
                        <div className="tplus-promo-links-container tplus-promo-links-container-tertiary">
                            {setupSelectArray() &&
                        setupSelectArray()
                          .filter(function(cta) {
                            return cta.ctaVariant === 'LowEmphasis';
                          })
                          .map(cta1 => (
                            <div className={`tplus-promo-link ${promosize === 'half' ? 'tplus-promo-link--half' : ''} `} >
                              <Cta
                                action={cta1.ctaAction}
                                trackingCode={cta1.ctaTrackingCode}
                                url={cta1.ctaUrl}
                                ctaProps={{
                                  variant: cta1.ctaVariant,
                                  label: cta1.ctaText,
                                  className: 'button-tplus'
                                }}
                              ></Cta>
                            </div>
                          ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default PromoBox;