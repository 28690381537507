/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import alertSvg from 'assets/images/Alert-Small-Event-Child.svg';
import './TicketAlertBox.scss';
import { signIn } from 'oidc/initiateOidc';
import SVGTPlusAlert from 'icons/SVGTPlusAlert.svg';
import { HtmlConverter } from 'utils/HtmlConverter';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { TextStyle } from '@able/react';

const TicketAlertBox = props => {
  const {
    presaleEnded,
    notificationType,
    notificationText,
    notificationHeader,
    singleEvent
  } = props;
  const { genre } = useParams();
  const isAuthenticated = useSelector(state => state.appStatus.authenticated);
  const renderIcon = () => {
    switch (notificationType) {
      case 'warning':
        return (
          <img className="alert-icon" src={alertSvg} alt="An alert icon" />
        );
      case 'info':
        return (
          <img className="alert-icon" src={SVGTPlusAlert} alt="An alert icon" />
        );
      default:
        return null;
    }
  };
  return (
    <div
      className={
        notificationType === 'warning'
          ? 'alert-box-container warning'
          : 'alert-box-container info'
      }
    >
      <div className="alert">{renderIcon()}</div>
      <div className="alert-text">
        {notificationHeader !== undefined ? (
          <div className="header-text">{notificationHeader}</div>
        ) : null}
        <div>
        <TextStyle alias="FinePrint" element="span">{HtmlConverter(notificationText)}</TextStyle>
          {(genre === 'Sports' || genre === 'Arts' || genre === 'Music') &&
            isAuthenticated === false &&
            !presaleEnded &&
              <>
                <span className="signin" onClick={() => signIn()}>Sign in&nbsp;</span>
                {singleEvent ? (
                  <>for more details.</>
                ): (
                  <>for your specific pre-sale times.</>
                )}
              </>
          }
        </div>
        {((genre === 'Sports' || genre === 'Arts' || genre === 'Music') && (isAuthenticated === false)) &&
          <div className="signin"> <a href="#" onClick={() => signIn()}> Sign in to Telstra Plus </a></div>
        }
      </div>
    </div>
  );
};

export default TicketAlertBox;
