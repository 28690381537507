// Components
import {
  Breadcrumbs,
  LayoutContainer,
  PageHeader,
  RecentActivity,
  RewardsTile,
  PromoTile,
  MembershipDashboard,
  VerticalTabsv2,
  InstructionalBox,
  InstructionalBoxV2,
  FormContainer,
  FormCheckboxGroup,
  FormSelect,
  FormTextfield,
  FormTextArea,
  FormRadioGroup,
  FormContactCapture,
  HeadingTitle,
  MessageInline,
  MovieTicketSelector,
  MultiCarousel,
  NavigationBox,
  PageTitle,
  BenefitTile,
  CenteredPromoTile,
  PromoTilesGroup,
  SubNav,
  Table,
  Text
} from 'components';

import PageHeaderV2 from 'components/PageHeaderV2';
import PromoBox from 'components/Promos/PromoBox';
import SectionNavigation from 'components/SectionNavigation';
// recursive dependency hence not included in LPT-2242
import ChoiceChips from 'components/ChoiceChips/ChoiceChips';
// import AccordionContainer from 'components/Accordion/AccordionContainer';
import ErrorContainer from 'components/ErrorContainer/ErrorContainer';
import Include from './Include/Include';

// Define the components that can be rendered
const componentMapping = {
  layoutContainer: {
    component: LayoutContainer,
    properties: {
      defaultSpacingMd: 'spacing2x',
      defaultSpacingXs: 'spacing2x'
    }
  },
  verticaltab: {
    component: VerticalTabsv2
  },
  headingtitle: {
    component: HeadingTitle
  },
  pagetitle: {
    component: PageTitle,
    properties: {
      defaultSpacingMd: 'spacing2x',
      defaultSpacingXs: 'spacing2x'
    }
  },
  text: {
    component: Text
  },
  instructionalbox: {
    component: InstructionalBox
  },
  instructionalboxv2: {
    component: InstructionalBoxV2
  },
  centeredpromotile: {
    component: CenteredPromoTile
  },
  promoTilesGroup: {
    component: PromoTilesGroup
  },
  promoTile: {
    component: PromoTile,
    category: 'promotile'
  },
  promoBox: {
    component: PromoBox
  },
  rewardsTile: {
    component: RewardsTile
  },
  benefitTile: {
    component: BenefitTile
  },
  breadcrumbs: {
    component: Breadcrumbs,
    properties: {
      defaultSpacingMd: 'none',
      defaultSpacingXs: 'none'
    }
  },
  errorContainer: {
    component: ErrorContainer
  },
  formContainer: {
    component: FormContainer
  },
  formCheckboxGroup: {
    component: FormCheckboxGroup
  },
  formSelect: {
    component: FormSelect
  },
  formContactCapture: {
    component: FormContactCapture
  },
  formTextfield: {
    component: FormTextfield
  },
  formTextarea: {
    component: FormTextArea
  },
  formRadioGroup: {
    component: FormRadioGroup
  },
  include: {
    component: Include
  },
  membershipDashboard: {
    component: MembershipDashboard
  },
  subnav: {
    component: SubNav,
    properties: {
      defaultSpacingMd: 'spacing7x',
      defaultSpacingXs: 'spacing4x'
    }
  },
  pageHeader: {
    component: PageHeader,
    properties: {
      defaultSpacingMd: 'spacing4x',
      defaultSpacingXs: 'spacing4x'
    }
  },
  pageHeaderV2: {
    component: PageHeaderV2,
    properties: {
      defaultSpacingMd: 'spacing4x',
      defaultSpacingXs: 'spacing4x'
    }
  },
  recentActivity: {
    component: RecentActivity
  },
  movieTicketSelector: {
    component: MovieTicketSelector
  },
  messageInline: {
    component: MessageInline
  },
  navigationBox: {
    component: NavigationBox
  },
  choiceChips: {
    component: ChoiceChips
  },
  table: {
    component: Table
  },
  multiCarousel: {
    component: MultiCarousel
  },
  sectionNavigation: {
    component: SectionNavigation
  },
  promoBox: {
    component: PromoBox
  }
};

export default componentMapping;
