import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import TechnicalIssue from 'views/ErrorViews/TechnicalIssue';
import NotFound from 'views/ErrorViews/NotFound';

const ErrorScreen = props => {
  const history = useNavigate();

  const { viewType } = props;

  const hasErrored = useSelector(state => state.error.hasErrored);

  if (hasErrored) {
    return (
      <>
        <TechnicalIssue />
      </>
    );
  }
  if (viewType === '404') {
    return <NotFound />;
  }
  history('/');
  return null;
};

export default ErrorScreen;
