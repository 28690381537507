const checkExternalLink = (url, target) => {
  const isInternalLink = /^\//.test(url);
  const externalSite = target === '_blank';

  if (isInternalLink) return { element: 'Link', to: url };
  return { element: 'a', href: url, externalSite };
};

export default checkExternalLink;

export const getButtonType = url => {
  return /^\//.test(url) ? 'Link' : 'a';
};
