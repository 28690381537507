import React from 'react';
import { OtpFlow } from 'components';

import './FormContactCapture.scss';

const FormContactCapture = props => {
  return (
    <div className="tplus-form-field">
      <OtpFlow {...props} />
    </div>
  );
};

export default FormContactCapture;

FormContactCapture.defaultProps = {
  active: '',
  name: 'select',
  title: '',
  options: {}
};
