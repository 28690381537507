import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, VerticalTabs } from 'components';
import './NoActiveServices.scss';

const NoActiveServices = ({ renderDownStreamErrorCode }) => (
  <Fragment>
    <div className="no-active-services">
      <Breadcrumbs />
      <h1 className="page-heading">We&#39;re unable to find any active services</h1>
      <div className="clear-div-l" />
      <p>We&#39;re unable to find any active Telstra services.</p>
      <div className="clear-div-m" />
      <div className="rewards-tabs-container">
        <VerticalTabs
          tabs={[
            {
              title: 'Have you recently ordered a new service?',
              description: `If you have recently ordered a new service, please allow up to 24 hours for your new service to become active and try again.`
            },
            {
              title: 'Have you recently cancelled your service?',
              description:
                'You need an active, eligible Telstra service and a Telstra ID to join Telstra Plus.'
            }
          ]}
        />
      </div>
      {renderDownStreamErrorCode()}
      <div className="clear-div-xxxl" />
    </div>
  </Fragment>
);

NoActiveServices.propTypes = {
  renderDownStreamErrorCode: PropTypes.func.isRequired
};

export default NoActiveServices;
